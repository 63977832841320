import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';

import styles from './styles';

const ConfigureGraphic = ({ classes }) => {
  const styles = {
    styles: buildStyles({
      trailColor: 'var(--gray-200)',
      pathColor: 'var(--indigo-500)',
    }),
    strokeWidth: 10,
  };
  return (
    <div className="flex items-center space-x-2">
      <div className={classes.firstDonut}>
        <CircularProgressbar {...styles} value={0} />
      </div>
      <div className="flex items-center px-3 py-4 bg-gray-100 border border-gray-200 rounded">
        <div
          className={classnames(classes.donut, 'transform translate-x-2 z-10')}
        >
          <CircularProgressbar {...styles} value={75} />
        </div>
        <div
          className={classnames(classes.donut, 'transform -translate-x-2 z-0')}
        >
          <CircularProgressbar {...styles} value={25} />
        </div>
      </div>
      <div className={classes.lastDonut}>
        <CircularProgressbar {...styles} value={0} />
      </div>
    </div>
  );
};

export default withStyles(styles)(ConfigureGraphic);
