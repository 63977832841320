import { CKContainerProps } from 'clearkit';
import { createContext, FC, useContext } from 'react';

import { useEntitlements } from '~/hooks/account';

import { CRMPresenceContext } from './useCRMPresence';

type CRMPresenceProps = CKContainerProps & {
  domain: string;
};

type CRMPresenceComponentProps = CKContainerProps;

type CRMPresenceComposition = {
  WhenPresent: FC<CRMPresenceComponentProps>;
  WhenNoAccess: FC<CRMPresenceComponentProps>;
  WhenNotConnected: FC<CRMPresenceComponentProps>;
  WhenNotPresent: FC<CRMPresenceComponentProps>;
  WhenLoading: FC<CRMPresenceComponentProps>;
};

const CRMDomainPresenceContext = createContext({
  hasAccessAddHubSpot: false,
  isHubSpotConnected: false,
  isPresent: false,
  isLoading: false,
});

export const CRMPresence: FC<CRMPresenceProps> & CRMPresenceComposition = ({
  children,
  domain,
}) => {
  const { hasAccessAddHubSpot } = useEntitlements();

  const { isDomainPresent, isHubSpotConnected, isLoading } = useContext(
    CRMPresenceContext,
  );
  const isPresent = isDomainPresent(domain);

  return (
    <CRMDomainPresenceContext.Provider
      value={{
        hasAccessAddHubSpot,
        isHubSpotConnected,
        isPresent,
        isLoading,
      }}
    >
      {children}
    </CRMDomainPresenceContext.Provider>
  );
};

const WhenPresent = ({ children }: CRMPresenceComponentProps) => {
  const { hasAccessAddHubSpot, isLoading, isPresent } = useContext(
    CRMDomainPresenceContext,
  );

  return hasAccessAddHubSpot && isPresent && !isLoading ? (
    <>{children}</>
  ) : null;
};
CRMPresence.WhenPresent = WhenPresent;
CRMPresence.WhenPresent.displayName = 'CRMPresence.WhenPresent';

const WhenNoAccess = ({ children }: CRMPresenceComponentProps) => {
  const { hasAccessAddHubSpot, isLoading } = useContext(
    CRMDomainPresenceContext,
  );

  return !hasAccessAddHubSpot && !isLoading ? <>{children}</> : null;
};

CRMPresence.WhenNoAccess = WhenNoAccess;
CRMPresence.WhenNoAccess.displayName = 'CRMPresence.WhenNoAccess';

const WhenNotConnected = ({ children }: CRMPresenceComponentProps) => {
  const { isHubSpotConnected, isPresent, isLoading } = useContext(
    CRMDomainPresenceContext,
  );

  return !isPresent && !isHubSpotConnected && !isLoading ? (
    <>{children}</>
  ) : null;
};

CRMPresence.WhenNotConnected = WhenNotConnected;
CRMPresence.WhenNotConnected.displayName = 'CRMPresence.WhenNotConnected';

const WhenNotPresent = ({ children }: CRMPresenceComponentProps) => {
  const {
    hasAccessAddHubSpot,
    isHubSpotConnected,
    isLoading,
    isPresent,
  } = useContext(CRMDomainPresenceContext);

  return hasAccessAddHubSpot &&
    isHubSpotConnected &&
    !isPresent &&
    !isLoading ? (
    <>{children}</>
  ) : null;
};

CRMPresence.WhenNotPresent = WhenNotPresent;
CRMPresence.WhenNotPresent.displayName = 'CRMPresence.WhenNotPresent';

const WhenLoading = ({ children }: CRMPresenceComponentProps) => {
  const { hasAccessAddHubSpot, isLoading } = useContext(
    CRMDomainPresenceContext,
  );

  return hasAccessAddHubSpot && isLoading ? <>{children}</> : null;
};
CRMPresence.WhenLoading = WhenLoading;
CRMPresence.WhenLoading.displayName = 'CRMPresence.WhenLoading';
