export default () => ({
  audience: {
    alignItems: 'center',
    background: '#fff',
    borderRadius: 6,
    display: 'flex',
    minHeight: 70,
    overflow: 'hidden',
  },

  audienceTile: {
    flex: '0 0 auto',
    margin: '0 0 0 18px',
  },

  audienceDetailsWrapper: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '9px 20px',
    minWidth: 0,
  },

  audienceNameWrapper: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    flexShrink: 1,
    minWidth: 20,
    marginRight: 20,
  },

  audienceName: {
    color: '#27282a',
    fontSize: 16,
    fontWeight: 500,
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '& svg': {
      display: 'inline-block',
      position: 'relative',
      top: -2,
      marginRight: 8,
    },
  },

  audienceDescription: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: '#596168',
    fontSize: 14,
    maxWidth: '100%',
    margin: '2px 10px 0 0',
    '& strong': {
      fontWeight: 600,
    },
  },

  audienceButton: {
    flex: '0 0 auto',
    margin: '0 0 0 auto',
    maxHeight: 32,
    height: 32,
    minHeight: 32,
    fontSize: 14,
  },
});
