import { SalesforceServices as SalesforceIcon } from '@clearkit/icons';

const SalesforceField = ({ label, children }) => (
  <div className="items-center w-full mb-2 grid grid-cols-3 gap-4">
    <div className="flex items-center gap-2">
      <SalesforceIcon width={18} />
      {label}
    </div>
    <div className="col-span-2">{children}</div>
  </div>
);

SalesforceField.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default SalesforceField;
