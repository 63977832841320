import { CKBox, CKChartArea, CKChartLineTooltipProps } from 'clearkit';
import { capitalize } from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';

import { ChartEmptyOverlay } from '~/components/ChartEmptyOverlay';
import GraphqlError from '~/components/GraphqlError';
import LoadingArea from '~/components/LoadingArea';
import {
  SubscriptionBillingTermEnum,
  UsageBreakdownGranularityEnum,
  useUsageBreakdownQuery,
} from '~/generated/graphql';

import {
  backendDateFormat,
  tooltipTitleFormatter,
  xAxisFormatter,
  yAxisFormatter,
} from '../utils/formatHelpers';
import { gridYLinesBuilder } from '../utils/gridLinesHelper';
import { formatBottomAxisValues } from './chartUtils';
import { CreditBreakdownChartTooltip } from './CreditBreakdownChartTooltip';

const formatGraphTitle = (title: string) => {
  if (title.toLocaleLowerCase() === 'person company') {
    return 'Combined';
  } else {
    return capitalize(title);
  }
};

export type usageMetricGraphCollectionType = Record<string, unknown>;
export type CumulativeUsageChartProps = {
  meteredUnit: string;
  usageLimit?: number | null | undefined;
  startDate: any;
  endDate: any;
  usageTerm: SubscriptionBillingTermEnum;
  title?: string | null;
  usageMetricGraphCollection: usageMetricGraphCollectionType;
  hasLegend?: boolean;
  chartColors?: string[] | string;
  granularity?: UsageBreakdownGranularityEnum;
  creditBreakdownChart?: boolean;
};

export default function MultiSeriesCumulativeUsageChart({
  usageLimit,
  startDate,
  endDate,
  usageTerm,
  usageMetricGraphCollection,
  title,
  hasLegend = true,
  chartColors = ['dataVizA', 'dataVizE', 'dataVizD'],
  granularity = UsageBreakdownGranularityEnum.Month,
  creditBreakdownChart = false,
}: CumulativeUsageChartProps) {
  const now = moment(moment.now()).add(1, 'day');

  const { data, loading, error, refetch } = useUsageBreakdownQuery({
    variables: {
      usageTerm: usageTerm,
      startDate: startDate,
      endDate: endDate,
      usageMetricGraph: usageMetricGraphCollection,
      granularity: granularity,
    },
  });

  const usageGraphValues = Object.values(data?.usageBreakdown || {}).flatMap(
    (d) => d,
  );
  const trendingMaxY = useMemo(() => {
    return Math.max(
      ...(usageGraphValues || []).map((d: any) =>
        creditBreakdownChart ? d.cumulativeCredits : d.cumulative,
      ),
    );
  }, [data?.usageBreakdown]);

  if (error || (!data?.usageBreakdown && !loading)) {
    return <GraphqlError error={error} refetch={refetch} />;
  }

  if (loading) {
    return <LoadingArea loading />;
  }

  const chartData: Array<{ id: string; data: any[] }> = [];
  Object.keys(data?.usageBreakdown).forEach((key) => {
    const seriesData = data?.usageBreakdown[key];
    if (!chartData.find((x) => x.id === key)) {
      chartData.push({
        id: formatGraphTitle(key),
        data: [
          ...seriesData.map((d: any) => {
            const startDate = moment(d.startDate, backendDateFormat);
            let usage: null | number = null;

            if (now.isAfter(startDate, granularity)) {
              usage = creditBreakdownChart ? d.cumulativeCredits : d.cumulative;
            }
            // Tweak x value formatting based on usage breakdown granularity. Daily title is just todays date where as monthly is displayed as
            // beginning of usage period - end of usage period
            const xValue =
              granularity == UsageBreakdownGranularityEnum.Day
                ? startDate
                : startDate.add(1, granularity);
            return {
              x: xValue.format(backendDateFormat),
              y: moment(d.startDate, backendDateFormat).isBefore(now)
                ? usage
                : null,
            };
          }),
        ],
      });
    }
  });

  const maxValue = Math.max(trendingMaxY, (usageLimit || 0) * 1.1);
  const gridYLines = gridYLinesBuilder(maxValue);
  const bottomAxisValues = formatBottomAxisValues({ chartData, usageTerm });

  return (
    <CKBox className="w-full p-4 pt-2 h-85" variant="card">
      <ChartEmptyOverlay showEmptyState={trendingMaxY === 0}>
        <CKChartArea
          axisBottom={{
            tickValues: bottomAxisValues,
          }}
          axisLeft={{
            tickValues: gridYLines,
          }}
          chartColors={chartColors as any}
          className="w-full h-full"
          data={chartData}
          enableArea
          enableGridY
          gridYValues={gridYLines}
          hasLegend={hasLegend}
          title={title || 'Cumulative Usage'}
          tooltipComponent={
            creditBreakdownChart
              ? (props: CKChartLineTooltipProps) => (
                  <CreditBreakdownChartTooltip
                    {...props}
                    usageMetricGraphCollection={usageMetricGraphCollection}
                  />
                )
              : undefined
          }
          toolTipTitleFormat={(value: any) =>
            tooltipTitleFormatter(value, granularity)
          }
          toolTipValueFormat={(value: string | number) => value}
          xValueFormat={xAxisFormatter}
          yScale={{
            type: 'linear',
            max: maxValue * 1.25,
          }}
          yValueFormat={yAxisFormatter}
        />
      </ChartEmptyOverlay>
    </CKBox>
  );
}
