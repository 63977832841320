import { lowerCase, upperFirst } from 'lodash';

export const errorList = (errors) =>
  errors
    .reduce((list, error) => {
      const fieldErrors = error.messages.map(
        (message) => `${upperFirst(lowerCase(error.field))}: ${message}`,
      );
      return [...list, ...fieldErrors];
    }, [])
    .join(', ')
    .concat('.');
