import { Error } from '@clearkit/icons';
import { CKLayoutGridAuto } from 'clearkit';

import { UpgradePlanBanner } from '~/components/CTABanner/UpgradePlanBanner';
import GraphqlError from '~/components/GraphqlError';
import LoadingArea from '~/components/LoadingArea';
import { UpgradeAccountFormButton } from '~/components/UpgradeAccountFormButton';
import {
  AccountFlagEnum,
  SubscriptionBillingTermEnum,
  UsageBreakdownGranularityEnum,
  useUsageDashboardFeaturesListQuery,
} from '~/generated/graphql';
import { useFeatureFlag } from '~/hooks/account';
import { useCreditUsage } from '~/hooks/account/useCreditUsage';

import { BillingUsageMeterCard } from '../BillingUsageMeters/Show';
import MultiSeriesCumulativeUsageChart from '../Charts/MultiSeriesCumulativeUsageChart';
import { ApiCreditUsageTile } from '../CreditUsageTile/ApiCreditUsageTile';
import {
  CreditUsageProductType,
  ShouldDisplayCreditUsageTile,
} from '../CreditUsageTile/helpers';
import { MetricSubsetCreditUsageTile } from '../CreditUsageTile/MetricSubsetCreditUsageTile';
export function CreditsBasedUsageLayout() {
  const {
    data,
    loading,
    error,
    refetch,
  } = useUsageDashboardFeaturesListQuery();

  const { planId } = useCreditUsage();

  const showApiUsage = useFeatureFlag(
    AccountFlagEnum.AllowPlatformDisplayFreeTierApiUsage,
  ).enabled;

  if (error) {
    return <GraphqlError error={error} loading={loading} refetch={refetch} />;
  }

  if (loading || !data) {
    return <LoadingArea loading={loading} />;
  }

  const relevantProductFeature = data.productFeatures.find(
    (item) => item.id === planId,
  );

  if (!relevantProductFeature) {
    return <div> No usage available</div>;
  }

  const remainingCredits = relevantProductFeature.usageLimit
    ? Number(relevantProductFeature?.usageLimit) -
      Number(
        relevantProductFeature?.currentPeriodTotalUsage?.total?.credits || 0,
      )
    : 0;

  const isCustomerOutOfCredits = remainingCredits <= 0;

  return (
    <div className="space-y-6">
      {isCustomerOutOfCredits ? (
        <UpgradePlanBanner>
          <UpgradePlanBanner.Icon>
            <Error className="fill-gradient-br-yellow" />
          </UpgradePlanBanner.Icon>
          <UpgradePlanBanner.Header>
            <p className="font-medium text-md">Add credits</p>
          </UpgradePlanBanner.Header>
          <UpgradePlanBanner.Body>
            <p className="text-sm font-normal text-gray-500 leading-5">
              You’re currently at or above your credit limit. Get in touch to
              increase your limits and to avoid any interruption in your
              Clearbit service.
            </p>
          </UpgradePlanBanner.Body>
          <UpgradePlanBanner.CTA>
            <UpgradeAccountFormButton variant="bold" variantColor="blue">
              Talk to us
            </UpgradeAccountFormButton>
          </UpgradePlanBanner.CTA>
        </UpgradePlanBanner>
      ) : null}
      <CKLayoutGridAuto className="w-full" gap="1.5rem">
        <BillingUsageMeterCard
          currentPeriodEndDate={relevantProductFeature.currentPeriodEndDate}
          currentPeriodStartDate={relevantProductFeature.currentPeriodStartDate}
          currentPeriodTotalUsage={
            relevantProductFeature?.currentPeriodTotalUsage
          }
          detailedCard
          documentationUrl={relevantProductFeature?.documentationUrl}
          id={relevantProductFeature.id}
          includeDetailLink={false}
          meteredUnit="credits"
          name="Credits"
          usageLimit={relevantProductFeature.usageLimit}
          usageTerm={relevantProductFeature.usageTerm}
        />
        {Object.keys(relevantProductFeature.usageMetricGraph).map((product) => {
          if (ShouldDisplayCreditUsageTile(product)) {
            return (
              <MetricSubsetCreditUsageTile
                key={product}
                modifier={
                  relevantProductFeature.usageMetricGraph[product].modifier
                }
                planId={relevantProductFeature.id}
                product={product as CreditUsageProductType}
                usage={relevantProductFeature?.currentPeriodTotalUsage[product]}
              />
            );
          }
        })}
        {showApiUsage ? (
          <ApiCreditUsageTile
            currentPeriodTotalUsage={
              relevantProductFeature?.currentPeriodTotalUsage
            }
            planId={relevantProductFeature.id}
            usageMetricGraph={relevantProductFeature.usageMetricGraph}
          />
        ) : null}
      </CKLayoutGridAuto>
      <MultiSeriesCumulativeUsageChart
        chartColors={['dataVizA', 'dataVizE', 'dataVizD', 'dataVizC']}
        creditBreakdownChart
        endDate={relevantProductFeature.currentPeriodEndDate}
        granularity={
          relevantProductFeature.id === 'business_tier'
            ? UsageBreakdownGranularityEnum.Month
            : UsageBreakdownGranularityEnum.Day
        }
        hasLegend={false}
        meteredUnit={relevantProductFeature.meteredUnit}
        startDate={relevantProductFeature.currentPeriodStartDate}
        title="Current Period Usage"
        usageMetricGraphCollection={relevantProductFeature.usageMetricGraph}
        usageTerm={SubscriptionBillingTermEnum.Monthly}
      />
    </div>
  );
}
