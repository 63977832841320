import { CompaniesNav, Team } from '@clearkit/icons';
import { CKButton, CKLayoutHorizontal } from 'clearkit';

import { FilterChip } from '~/components/Filters/FilterChip';
import { ProspectFilters } from '~/generated/graphql';

type FilterPreviewProps = {
  isOpen?: boolean;
  onEditFilters: () => void;
  personQuery?: any;
  prospectFilters: ProspectFilters;
  companyQuery?: any;
};

export const FilterPreview = ({
  companyQuery,
  isOpen = true,
  onEditFilters,
  prospectFilters,
  personQuery,
}: FilterPreviewProps) => {
  const hasPersonQuery = personQuery && Object.keys(personQuery).length > 0;
  const hasCompanyQuery = companyQuery && Object.keys(companyQuery).length > 0;

  const IconCompany = () => (
    <FilterChip.Icon>
      <CompaniesNav className="w-3 h-3" />
    </FilterChip.Icon>
  );

  const IconPerson = () => (
    <FilterChip.Icon>
      <Team className="w-4 h-4" />
    </FilterChip.Icon>
  );
  return isOpen && (hasPersonQuery || hasCompanyQuery) ? (
    <CKLayoutHorizontal alignItems="start" wrap="nowrap">
      <CKLayoutHorizontal className="pt-1" gap=".5rem" justifyContent="start">
        <FilterChip
          filters={personQuery.titles}
          options={prospectFilters?.title}
          title="Title"
        >
          <IconPerson />
        </FilterChip>
        <FilterChip filters={personQuery.names} title="Names">
          <IconPerson />
        </FilterChip>
        <FilterChip
          filters={personQuery.roles}
          options={prospectFilters?.role}
          title="Roles"
        >
          <IconPerson />
        </FilterChip>
        <FilterChip
          filters={personQuery.seniorities}
          options={prospectFilters?.seniority}
          title="Seniorities"
        >
          <IconPerson />
        </FilterChip>
        <FilterChip
          filters={personQuery.countries}
          options={prospectFilters?.countries}
          title="Countries"
        >
          <IconPerson />
        </FilterChip>
        <FilterChip filters={personQuery.states} title="States / Regions">
          <IconPerson />
        </FilterChip>
        <FilterChip filters={personQuery.cities} title="Cities">
          <IconPerson />
        </FilterChip>
        <FilterChip filters={companyQuery.domains} title="Domains">
          <IconCompany />
        </FilterChip>
        <FilterChip
          filters={companyQuery.employeesRanges}
          title="Employee Ranges"
        >
          <IconCompany />
        </FilterChip>
        <FilterChip filters={companyQuery.industries} title="Industries">
          <IconCompany />
        </FilterChip>
        <FilterChip
          filters={companyQuery.companyCountries}
          options={prospectFilters?.countries}
          title="Countries"
        >
          <IconCompany />
        </FilterChip>
        <FilterChip
          filters={companyQuery.companyStates}
          title="States / Regions"
        >
          <IconCompany />
        </FilterChip>
        <FilterChip filters={companyQuery.companyCities} title="Cities" />
        <FilterChip
          filters={companyQuery.companyTags}
          options={prospectFilters?.companyTags}
          title="Tags"
        >
          <IconCompany />
        </FilterChip>
        <FilterChip
          filters={companyQuery.companyTech}
          options={prospectFilters?.companyTech}
          title="Technologies"
        >
          <IconCompany />
        </FilterChip>
      </CKLayoutHorizontal>
      <CKButton
        className="shrink-0"
        onClick={onEditFilters}
        size="small"
        variant="simple"
        variantColor="blue"
      >
        Edit filters
      </CKButton>
    </CKLayoutHorizontal>
  ) : null;
};
