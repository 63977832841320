const styles = () => ({
  subheading: {
    color: '#596168',
    flex: '0 0 auto',
    fontSize: 15,
    fontWeight: 500,
    margin: '0 0 7px',
  },

  triggerSubtext: {
    color: '#596168',
    fontSize: 14,
  },

  field: {
    margin: '10px 0',
  },

  connectLinkHeader: {
    fontSize: 22,
    fontWeight: 500,
  },

  connectLink: {
    fontSize: 18,
    display: 'block',
    margin: '14px 0',
    '&>a': {
      display: 'inline-block',
    },
  },

  formField: {
    marginBottom: 20,
  },
});

export default styles;
