import { AddBlock as AddIcon } from '@clearkit/icons';
import { CKButton, CKTooltip } from 'clearkit';
import { Link } from 'react-router-dom';

import paths from '~/lib/paths';
import { readableSingularSegmentType } from '~/lib/segments';

import styles from './styles.style';

function NewAudienceButton({ classes, className, type, disabled }) {
  if (disabled) {
    return (
      <CKTooltip
        placement="right-start"
        tooltip={
          'Must configure prerequisite integrations before you can create audiences'
        }
        tooltipMaxWidth="25ch"
      >
        <BaseButton
          classes={classes}
          className={className}
          disabled={disabled}
          type={type}
        />
      </CKTooltip>
    );
  } else {
    return (
      <BaseButton
        classes={classes}
        className={className}
        disabled={disabled}
        type={type}
      />
    );
  }
}

function BaseButton({ className, type, classes, disabled }) {
  return (
    <CKButton
      as={Link}
      className={classnames(className, classes.newAudienceButton)}
      isDisabled={disabled}
      leftIcon={<AddIcon className={classes.newAudienceButtonIcon} />}
      to={paths.segmentNew(type)}
      variant="bold"
      variantColor="blue"
    >
      New {readableSingularSegmentType(type)} audience
    </CKButton>
  );
}

NewAudienceButton.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  type: PropTypes.oneOf(['Company', 'Person']).isRequired,
};

export default withStyles(styles)(NewAudienceButton);
