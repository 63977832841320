import { CKBox, CKButton, CKSearch } from 'clearkit';
import { useState } from 'react';

import { routes } from '~/components/app/appMainRoutes';
import EnrichmentLogsList from '~/components/EnrichmentLogsList';
import ConnectorLayout from '~/components/layouts/ConnectorLayout';
import Tile from '~/images/Tiles/Enrichment.svg';
import { readableType } from '~/lib/dataEnrichment';
import { connectorLocations } from '~/lib/segmentAnalytics';

import { IntegrationTabs } from '../IntegrationTabs';
import { Integration } from '../Show/FieldMappingForm/types';
import Placeholder from './Placeholder';

function EnrichmentLogsPage({
  enrichmentLogsType,
}: {
  enrichmentLogsType: Integration;
}) {
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState(false);
  const [
    showEnrichmentLogsPlaceholder,
    setShowEnrichmentLogsPlaceholder,
  ] = useState(false);
  // Enrichment Logs are the last tab (based on zero indexed array)
  const enrichmentLogReadableType = readableType(enrichmentLogsType);

  return (
    <div className="w-full bg-gray-50">
      <ConnectorLayout
        breadcrumb={[
          {
            title: 'Integrations',
            path: routes.integrations,
          },
        ]}
        connectorLocation={connectorLocations.enrichment}
        integration={enrichmentLogsType}
        subtitle={`Sync Clearbit data to ${enrichmentLogReadableType}`}
        tabs={
          <IntegrationTabs integration={enrichmentLogsType} tabName="logs" />
        }
        tile={<Tile />}
      >
        {showEnrichmentLogsPlaceholder ? (
          <Placeholder
            className="mx-auto my-32"
            enrichmentType={enrichmentLogsType}
          />
        ) : (
          <div className="w-full px-10 py-6 mx-auto">
            <EnrichmentLogsSearchForm
              defaultValue={searchQuery}
              enrichmentLogsType={enrichmentLogsType}
              error={error}
              onChange={setSearchQuery}
            />

            <EnrichmentLogsList
              enrichmentLogsType={enrichmentLogsType}
              key={searchQuery}
              searchQuery={searchQuery}
              setError={setError}
              setShowPlaceholder={setShowEnrichmentLogsPlaceholder}
            />
          </div>
        )}
      </ConnectorLayout>
    </div>
  );
}

type EnrichmentLogsSearchFormProps = {
  defaultValue: string;
  enrichmentLogsType: Integration;
  error: boolean;
  onChange: (value: string) => void;
};

function EnrichmentLogsSearchForm({
  defaultValue,
  enrichmentLogsType,
  error,
  onChange,
}: EnrichmentLogsSearchFormProps) {
  const [value, setValue] = useState(defaultValue);

  const isDisabled = !!error || !value;
  const enrichmentLogReadableType = readableType(enrichmentLogsType);

  return (
    <CKBox>
      <form className="flex items-center p-4">
        <label
          className="pr-6 mr-6 text-base font-medium text-gray-900 border-r border-gray-300"
          htmlFor="query"
        >
          Search {enrichmentLogReadableType} enrichment logs
        </label>

        <CKSearch
          className="flex-1 mr-6"
          onChange={setValue}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();

              onChange(value);
            }
          }}
          placeholder={`Enter a ${enrichmentLogReadableType} ID, email address or domain`}
          value={value || ''}
        />

        <CKButton
          isDisabled={isDisabled}
          onClick={() => onChange(value)}
          type="button"
          variant="bold"
          variantColor="blue"
        >
          Search
        </CKButton>
      </form>
    </CKBox>
  );
}

export default EnrichmentLogsPage;
