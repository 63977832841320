import { NoResultsPeople } from '@clearkit/icons';
import { CKContainerProps } from 'clearkit';

import { EmptyState } from '../ProspectList/EmptyState';

export function ExportsHistoryEmptyState(props: CKContainerProps) {
  return (
    <div className="w-full h-full">
      <EmptyState {...props}>
        <EmptyState.Icon>
          <NoResultsPeople />
        </EmptyState.Icon>
        <EmptyState.Title>No export history</EmptyState.Title>
        <EmptyState.Description className="max-w-[40ch]">
          Exports from Prospector to CSV or to your CRM will appear here.
        </EmptyState.Description>
      </EmptyState>
    </div>
  );
}
