const styles = () => ({
  formLabelRoot: {
    color: 'rgb(89, 97, 104)',
    fontSize: 15,
    fontWeight: 'normal',
  },

  inputText: {
    color: 'rgb(39, 40, 42)',
    fontSize: 16,
    fontWeight: 'normal',
  },

  inputRoot: {},
});

export default styles;
