import { View } from '@clearkit/icons';
import { CKBox } from 'clearkit';

import { useCRMLabel } from '../hooks/useCRMLabel';
import { HomeBannerHeader, HomeBannerHeaderProps } from './HomeBannerHeader';

export const HomeBannerConnectCRM = ({ ...rest }: HomeBannerHeaderProps) => {
  const { crmLabel } = useCRMLabel();

  return (
    <HomeBannerHeader {...rest}>
      <HomeBannerHeader.Left>
        <CKBox className="p-2">
          <View className="fill-gradient-br-x" />
        </CKBox>
      </HomeBannerHeader.Left>
      <HomeBannerHeader.Message>
        Connect {crmLabel} to see how Clearbit can transform your data.
      </HomeBannerHeader.Message>
    </HomeBannerHeader>
  );
};
