import fetch from 'unfetch';

function calculateEndpoint(hostname = window.location.hostname) {
  const endpoint = 'https://app.clearbit.com';

  const localDevelopment = 'localhost' === hostname;
  const webLocal = 'web.local' === hostname;
  const xBranch = /^clearbit-x-pr-\d+\.dev\.clearbit\.com$/.test(hostname);
  if (localDevelopment || webLocal || xBranch) {
    return '';
  }

  const development = /dev\.clearbit\.com$/.test(hostname);
  if (development) {
    return 'https://clearbit-x.dev.clearbit.com';
  }

  const staging = /staging\.clearbit\.com$/.test(hostname);
  if (staging) {
    return 'https://app.staging.clearbit.com';
  }

  return endpoint;
}

const endpoint = calculateEndpoint();

function queryParams(params: Record<string, string>) {
  return Object.keys(params)
    .map(
      (key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]),
    )
    .join('&');
}

async function request(options: Record<string, any>) {
  const url = endpoint + options.path;

  const response = await fetch(url, {
    method: options.type,
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(options.json),
  });

  const body = await response.text();

  // Handle responses that return an empty body ahead of time, since
  // response.json() will throw an error.
  if (body === '') {
    if (response.ok) {
      return Promise.resolve({ status: 'ok', body });
    } else {
      return Promise.reject({ response, status: response.status });
    }
  }

  const json = await response.json();

  if (response.ok) {
    if (json.error) {
      return Promise.reject(json);
    }

    return json;
  } else {
    // Note (wade):
    // This should be uncommented but will currently break cypress
    // if (response.status === 401) {
    //   authUser(json.error);
    // }

    json.response = response;
    json.status = response.status;
    return Promise.reject(json);
  }
}

export default request;
export { calculateEndpoint, endpoint, queryParams };
