import { Clearbit, DirectionForward } from '@clearkit/icons';
import { CKBox } from 'clearkit';
import capitalize from 'lodash/capitalize';
import moment from 'moment';

import { sourceIcon, statuses } from '~/lib/dataEnrichment';

const TIMESTAMP_FORMAT = 'MMM Do YYYY, h:mm a';

export default function EnrichmentLogStep({ enrichmentLogStep }) {
  const [isOpen, setIsOpen] = useState(true);

  const iconClassName = isOpen
    ? 'select-none cursor-pointer text-blue-500 transform rotate-90'
    : 'select-none cursor-pointer text-gray-400';

  const tagClassName = classnames(
    'ml-6 inline-flex whitespace-nowrap items-center font-normal text-xs text-white leading-5 rounded-sm px-2',
    {
      'bg-blue-300': enrichmentLogStep.status === statuses.complete,
      'bg-gray-400': enrichmentLogStep.status === statuses.pending,
      'bg-gray-500': enrichmentLogStep.status === statuses.skipped,
      'bg-red-500': enrichmentLogStep.status === statuses.error,
      'bg-yellow-500': enrichmentLogStep.status === statuses.info,
    },
  );

  const isEnrichStep = enrichmentLogStep.name.match(/enrich/i);
  const isNotEnrichStep = !isEnrichStep;
  const hasChanges = Boolean(enrichmentLogStep.changes.length);

  const formattedTimestamp = moment(enrichmentLogStep.timestamp).format(
    TIMESTAMP_FORMAT,
  );

  function attrIcon() {
    if (isEnrichStep) {
      return <Clearbit className="text-blue-400" width={20} />;
    } else {
      return sourceIcon(enrichmentLogStep.name, 20);
    }
  }

  function stepToColumnSpanClass() {
    if (isEnrichStep) {
      return 'col-span-2';
    } else {
      return 'col-span-1';
    }
  }

  function EnrichmentLogStepChange({ stepChange }) {
    return (
      <>
        <div className="flex items-center flex-1 overflow-auto bg-gray-100 rounded px-2.5">
          {attrIcon()}
          <div className="pl-2 text-base font-medium text-gray-600 truncate">
            {stepChange.fieldName}
          </div>
        </div>

        {isNotEnrichStep && (
          <div className="py-px text-base font-medium text-gray-600 truncate bg-gray-100 rounded px-2.5 leading-8">
            {stepChange.from}
          </div>
        )}

        <div
          className={classnames(
            'py-px text-base font-medium text-gray-600 truncate bg-gray-100 rounded px-2.5 leading-8',
            stepToColumnSpanClass(),
          )}
        >
          {stepChange.to}
        </div>
      </>
    );
  }

  return (
    <CKBox variant={CKBox.Variant['tinted-frame']}>
      <div className="flex items-center p-4">
        {hasChanges && (
          <DirectionForward
            className={iconClassName}
            onClick={() => {
              const newIsOpen = !isOpen;

              setIsOpen(newIsOpen);
            }}
          />
        )}

        <span className={tagClassName}>{enrichmentLogStep.name}</span>

        <span className="ml-6 text-gray-700 text-md">
          {capitalize(enrichmentLogStep.status)}
        </span>

        <time
          className="ml-auto text-sm font-normal text-gray-500"
          dateTime={enrichmentLogStep.timestamp}
        >
          {formattedTimestamp}
        </time>
      </div>

      {enrichmentLogStep.message != capitalize(enrichmentLogStep.status) && (
        <div className="flex p-4 pt-0 text-gray-500 text-md">
          {enrichmentLogStep.message}
        </div>
      )}

      {isOpen && hasChanges && (
        <div className="py-4 mx-4 border-t border-gray-200">
          <div className="mb-1 grid grid-cols-3 gap-x-6">
            <span className="text-sm font-semibold text-gray-500">
              Attribute
            </span>

            {isNotEnrichStep && (
              <span className="text-sm font-semibold text-gray-500">
                Original Value
              </span>
            )}

            <span className="text-sm font-semibold text-gray-500">
              New Value
            </span>
          </div>

          <div className="grid grid-cols-3 gap-x-6 gap-y-3">
            {enrichmentLogStep.changes.map((enrichmentLogStepChange, key) => (
              <EnrichmentLogStepChange
                key={`${enrichmentLogStep.name}-${key}`}
                stepChange={enrichmentLogStepChange}
              />
            ))}
          </div>
        </div>
      )}
    </CKBox>
  );
}
