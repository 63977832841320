import { useEntitlements } from '~/hooks/account';
import useAccountConnector from '~/pages/configurations/Show/useAccountConnector';

export const useEnrichmentOnboarding = () => {
  const {
    accountConnector,
    loading: loadingAccountConnector,
    refetch: refetchAccountConnector,
  } = useAccountConnector('hubspot');

  const {
    hasAccessEnrichmentHubspotEditing,
    loading: loadingEntitlements,
  } = useEntitlements();

  const isHubSpotConnected =
    accountConnector && accountConnector.status === 'active';

  const isOnboarding =
    !isHubSpotConnected || !hasAccessEnrichmentHubspotEditing;

  const loading = loadingEntitlements || loadingAccountConnector;

  const handleRefetch = () => {
    refetchAccountConnector();
  };

  return {
    hasAccessEnrichmentHubspotEditing,
    isOnboarding,
    isHubSpotConnected,
    loading,
    refetch: handleRefetch,
  };
};
