const styles = () => ({
  inputRoot: {
    flex: '1 1 auto',
  },

  clearIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 21,
    top: 13,
  },

  calendarRoot: {
    background: 'whitesmoke',
    boxShadow: '0 1px 1px #dedede',
    borderRadius: '5px',
    userSelect: 'none',
    position: 'relative',
    width: 300,
  },

  monthControls: {
    overflow: 'hidden',
    textAlign: 'center',
    color: '#414953',
    height: 45,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    padding: '0 10px',
    backgroundImage: 'linear-gradient(180deg, #FCFEFF 0%, #F9FBFC 100%)',
  },

  monthButton: {
    float: 'left',
    fontSize: '17px',
    color: '#414953',
    outline: 'none',
    position: 'relative',
    zIndex: '1',
    cursor: 'pointer',
    width: 19,
    height: 19,
    background: '#9BADBC',
    borderRadius: 9.5,
    border: '1px solid #FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    '& svg': {
      width: 5,
      height: 9,
    },
  },

  monthLabel: {
    fontSize: 14,
    fontWeight: 500,
    color: '#596168',
    textAlign: 'center',
  },

  monthDates: {
    overflow: 'hidden',
  },

  date: {
    margin: 0,
    font: 'inherit',
    float: 'left',
    width: '14.285714286%',
    textAlign: 'center',
    fontSize: '15px',
    background: '#fff',
    border: 'none',
    borderRight: '1px solid #E1E9F0',
    borderTop: '1px solid #E1E9F0',
    boxSizing: 'border-box',
    padding: '11px',
    cursor: 'pointer',
    outline: 'none',
    color: '#596168',
    '&:hover': {
      transition: 'all .3s ease',
      background: '#f5f5f5',
    },
  },

  dateDisabled: {
    color: '#ccc',
    cursor: 'auto',
    '&:hover': {
      background: '#fff',
    },
  },

  dateOutOfMonth: {
    color: '#fff',
    '&:hover': {
      background: '#f5f5f5',
      color: '#000',
    },
  },

  dateSelected: {
    borderColor: '#2B9FFD',
    background: '#2B9FFD',
    color: '#fff',
    '&:hover': {
      background: '#2B9FFD',
      color: '#fff',
    },
  },
});

export default styles;
