import { useState } from 'react';

import { authConnector } from '~/lib/client';

export default function useAuthenticateHubspot({
  onSuccess,
}: {
  onSuccess?: () => void;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isConnected, setIsConnected] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const isMismatchError = error === 'hubspot_account_mismatch';
  const errorMessage = isMismatchError
    ? 'You are trying to connect to a different HubSpot account than the one you signed up with. Connect to HubSpot using the same account you signed up with.'
    : 'There was a problem connecting to HubSpot. Try again.';

  function connect() {
    setIsLoading(true);
    setError(null);

    authConnector(
      'hubspot',
      () => {
        setIsLoading(false);
        setIsConnected(true);

        if (onSuccess) {
          onSuccess();
        }
      },
      (errorData) => {
        setError(errorData.message);
        setIsLoading(false);
      },
    );
  }

  return {
    isLoading,
    isConnected,
    error,
    errorMessage,
    connect,
  };
}
