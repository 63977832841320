import { CKButton, CKModal } from 'clearkit';

import { ButtonContainer } from '~/components/unified/ButtonContainer';

import { useProspector } from '../useProspector';

export function DeleteProspectList() {
  const [state, send] = useProspector();

  const isOpen = state.matches('confirmingDelete') || state.matches('deleting');

  const handleCancel = () => send('CANCEL');

  return (
    <CKModal hasHideOnEscape isVisible={isOpen} onToggle={handleCancel}>
      <CKModal.Header>
        <CKModal.Heading>Delete list</CKModal.Heading>
      </CKModal.Header>
      <CKModal.Body className="p-8">
        <p className="text-base">
          Are you sure you want to delete <strong>{state.context.name}</strong>{' '}
          and its filters?
        </p>
      </CKModal.Body>
      <CKModal.Footer>
        <ButtonContainer>
          <CKButton
            isDisabled={state.matches('deleting')}
            onClick={handleCancel}
          >
            Dismiss
          </CKButton>
          <CKButton
            isDisabled={state.matches('deleting')}
            onClick={() => send('DELETE')}
            variant="bold"
            variantColor="red"
          >
            Yes, delete list
          </CKButton>
        </ButtonContainer>
      </CKModal.Footer>
    </CKModal>
  );
}
