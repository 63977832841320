import { Caret, GoExternal, Hubspot, Salesforce } from '@clearkit/icons';
import { CKActionMenu, CKButton, CKPopover } from 'clearkit';

import HubspotDealMenuItem from '~/components/ProfileHeaderSalesforceHubspotMenuItem/hubspotDeal';
import SalesforceOpportunityListItem from '~/components/ProfileHeaderSalesforceHubspotMenuItem/salesforceOpportunity';

const OpportunitiesLeadsCallout = ({
  className,
  salesforceOpportunities = [],
  hubspotDeals = [],
  hubspotOwners = [],
  salesforceAccountConnector = {},
}) => {
  const visibleDeals = hubspotDeals.slice(0, 3);
  const moreDeals = hubspotDeals.slice(3);
  const visibleOpportunities = salesforceOpportunities.slice(0, 3);
  const moreOpportunities = salesforceOpportunities.slice(3);

  return (
    <>
      {(visibleOpportunities?.length > 0 || hubspotDeals?.length > 0) && (
        <div className={classnames(className, 'flex-shrink-0 text-gray-500')}>
          <div className="w-full mt-2 ck-box ck-box--card">
            {visibleOpportunities.length > 0 && (
              <div
                className={classnames(
                  'p-2 border-gray-700 border-ck-box-separator',
                  {
                    'border-b':
                      hubspotDeals?.length > 0 && visibleOpportunities?.length,
                  },
                )}
              >
                <>
                  <span className="block px-2 py-1 text-xs font-medium">
                    {salesforceOpportunities.length} open Salesforce{' '}
                    {salesforceOpportunities.length === 1
                      ? 'Opportunity'
                      : 'Opportunities'}
                  </span>
                  {visibleOpportunities.map((opportunity) => (
                    <a
                      className="block px-2 py-1 text-gray-500 rounded hover:bg-gray-0 transition-colors duration-200"
                      href={`${salesforceAccountConnector?.externalInstanceUrl}/${opportunity?.externalId}`}
                      key={opportunity?.externalId}
                      rel="noopener noreferrer"
                      target="blank"
                    >
                      <span className="flex flex-row items-center flex-grow">
                        <div
                          className="flex items-center justify-center flex-shrink-0 w-8 h-8 mr-2 rounded-full text-salesforce"
                          style={{
                            background: 'rgb(232, 244, 250)',
                          }}
                        >
                          <Salesforce />
                        </div>
                        <div className="flex flex-col pr-2 truncate">
                          <span className="text-sm text-gray-900 truncate">
                            {opportunity.name}
                          </span>
                          <span className="text-xs">
                            Owned by{' '}
                            <span className="text-gray-900">
                              {opportunity.owner?.name || 'Unknown User'}
                            </span>
                          </span>
                        </div>
                        <GoExternal className="flex-shrink-0 ml-auto text-salesforce" />
                      </span>
                    </a>
                  ))}

                  {moreOpportunities.length > 0 && (
                    <CKPopover
                      aria-label="Popover"
                      className="z-10 overflow-y-auto popover-max-height"
                      gutter={4}
                      placement="bottom-end"
                      popover={
                        <CKActionMenu
                          aria-label="Default Menu"
                          hideOnClickOutside={false}
                          visible
                        >
                          {moreOpportunities.map((opportunity) => (
                            <SalesforceOpportunityListItem
                              key={opportunity.externalId}
                              salesforceAccountConnector={
                                salesforceAccountConnector
                              }
                              salesforceOpportunity={opportunity}
                            />
                          ))}
                        </CKActionMenu>
                      }
                    >
                      <CKButton
                        className="text-xs"
                        rightIcon={<Caret />}
                        variant="tertiary"
                        variantColor="blue"
                      >
                        Show {moreOpportunities.length} more opportunit
                        {moreOpportunities.length === 1 ? 'y' : 'ies'}
                      </CKButton>
                    </CKPopover>
                  )}
                </>
              </div>
            )}

            {hubspotDeals.length > 0 && (
              <div className="p-2">
                <>
                  <span className="block px-2 py-1 text-xs font-medium">
                    {hubspotDeals.length} open Hubspot{' '}
                    {hubspotDeals.length === 1 ? 'Deal' : 'Deals'}
                  </span>
                  {visibleDeals.map((deal) => {
                    const owner = hubspotOwners?.find(
                      (owner) => owner.id === deal.ownerId,
                    );

                    return (
                      <a
                        className="block px-2 py-1 text-gray-500 rounded hover:bg-gray-0 transition-colors duration-200"
                        href={`https://app.hubspot.com/contacts/${deal.hubspotCompany?.externalAccountId}/deal/${deal.id}`}
                        key={deal.id}
                        rel="noopener noreferrer"
                        target="blank"
                      >
                        <span className="flex flex-row items-center flex-grow">
                          <div
                            className="flex items-center justify-center w-8 h-8 mr-2 rounded-full text-hubspot"
                            style={{ background: 'rgb(253, 243, 240)' }}
                          >
                            <Hubspot />
                          </div>
                          <div className="flex flex-col pr-2">
                            <span className="text-sm text-gray-900">
                              {deal?.name}
                            </span>
                            <span className="text-xs">
                              Owned by{' '}
                              <span className="text-gray-900">
                                {owner?.ownerName || 'Unknown Owner'}
                              </span>
                            </span>
                          </div>

                          <GoExternal className="flex-shrink-0 ml-auto text-hubspot" />
                        </span>
                      </a>
                    );
                  })}

                  {moreDeals.length > 0 && (
                    <CKPopover
                      aria-label="Popover"
                      className="z-10 overflow-y-auto popover-max-height"
                      gutter={4}
                      placement="bottom-end"
                      popover={
                        <CKActionMenu
                          aria-label="Default Menu"
                          hideOnClickOutside={false}
                          visible
                        >
                          {moreDeals?.map((deal) => (
                            <HubspotDealMenuItem
                              deal={deal}
                              owner={hubspotOwners?.find(
                                (owner) => owner.id === deal.ownerId,
                              )}
                            />
                          ))}
                        </CKActionMenu>
                      }
                    >
                      <CKButton
                        className="text-xs text-hubspot"
                        rightIcon={<Caret />}
                        variant="tertiary"
                        variantColor="blue"
                      >
                        Show {moreDeals.length} more deal
                        {moreDeals.length === 1 ? '' : 's'}
                      </CKButton>
                    </CKPopover>
                  )}
                </>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

OpportunitiesLeadsCallout.propTypes = {
  className: PropTypes.string,
  salesforceOpportunities: PropTypes.array,
  hubspotDeals: PropTypes.array,
  hubspotOwners: PropTypes.array,
  salesforceAccountConnector: PropTypes.shape({
    externalInstanceUrl: PropTypes.string,
  }),
};

export default OpportunitiesLeadsCallout;
