import { Error } from '@clearkit/icons';

import { UpgradePlanBanner } from '../CTABanner/UpgradePlanBanner';
import { UpgradeAccountFormButton } from '../UpgradeAccountFormButton';
import { useCurrentPlan } from './useCurrentPlan';

export const NoUpgradeCTA = () => {
  const { canAccountUpgrade } = useCurrentPlan();

  if (canAccountUpgrade) {
    return null;
  }

  return (
    <UpgradePlanBanner>
      <UpgradePlanBanner.Icon>
        <Error className="fill-gradient-br-yellow" />
      </UpgradePlanBanner.Icon>
      <UpgradePlanBanner.Header>
        Credit upgrades unavailable for your account
      </UpgradePlanBanner.Header>
      <UpgradePlanBanner.Body>
        As of January 2024, self-serve upgrades to paid, higher credit tiers for
        Clearbit Prospecting are no longer supported. To upgrade to a paid plan
        for Clearbit Enrichment for HubSpot, contact us.
      </UpgradePlanBanner.Body>
      <UpgradePlanBanner.CTA>
        <UpgradeAccountFormButton variant="bold" variantColor="blue">
          Contact us
        </UpgradeAccountFormButton>
      </UpgradePlanBanner.CTA>
    </UpgradePlanBanner>
  );
};
