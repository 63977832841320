import { SubscriptionBillingTermEnum } from '~/generated/graphql';

import { ChartData, ChartDatum } from './types';

type BottomAxisValuesProps = {
  chartData: ChartData[];
  granularity?: number;
  usageTerm?: SubscriptionBillingTermEnum;
};

const DEFAULT_GRANULARITY = 3;
const ANNUAL_GRANULARITY = 14;
const LARGE_DATASET_THRESHOLD = 100;

export const formatBottomAxisValues = ({
  chartData,
  usageTerm,
  granularity = DEFAULT_GRANULARITY,
}: BottomAxisValuesProps) => {
  let rollupWindow = (_item: ChartDatum, index: number) =>
    index % granularity === 0;

  const isLargeDataset = chartData[0]?.data.length > LARGE_DATASET_THRESHOLD;
  if (usageTerm === SubscriptionBillingTermEnum.Yearly || isLargeDataset) {
    rollupWindow = (_item: ChartDatum, index: number) =>
      index % ANNUAL_GRANULARITY === 1;
  }

  return chartData[0]?.data
    .filter(rollupWindow)
    .map((item: ChartDatum) => item.x);
};
