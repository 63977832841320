import { useMutation, useQuery } from '@apollo/client/react/hooks';
import { AddHollow, SpinnerIndicators } from '@clearkit/icons';
import { CKBox, CKButton, CKLayoutHorizontal, CKTooltip } from 'clearkit';
import { useEffect, useMemo } from 'react';
import { Prompt } from 'react-router-dom';

import FormSection from '~/components/configuration/FormSection';
import { MappingRowContainer } from '~/components/MappingRowContainer';
import ToastContainer from '~/containers/Toasts';
import { useEntitlements } from '~/hooks/account';
import useNavigationEditGuard from '~/hooks/useNavigationEditGuard';
import { configurationNameForType } from '~/lib/configuration';

import attributesQuery from './attributes.graphql';
import fieldMappingsQuery from './fieldMappings.graphql';
import FormFieldMappingRow from './FormFieldMappingRow';
import upsertFieldMappingsMutation from './upsertFieldMappings.graphql';

const STUB_MAPPING = {
  id: null,
  sourceAttribute: null,
  targetAttribute: null,
};

const INTEGRATION_ENRICHMENT_TYPE_MAPPING = {
  hubspot: 'hubspot_contact',
  pardot: 'pardot_prospect',
  marketo: 'marketo_form_lead',
};

export default function FormFieldMappings({ integration }) {
  const configurationName = configurationNameForType(integration);
  const [fieldMappings, setFieldMappings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mappingsAreDirty, setMappingsAreDirty] = useState(false);
  const [showInvalidFields, setShowInvalidFields] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const { hasAccessEnrichmentHubspot } = useEntitlements();

  useNavigationEditGuard(() => mappingsAreDirty);

  const handleMappingChange = (index) => (changes) => {
    const newFieldMappings = [...fieldMappings];
    newFieldMappings[index] = {
      ...newFieldMappings[index],
      ...changes,
    };
    setFieldMappings(newFieldMappings);
    setMappingsAreDirty(true);
  };

  const addMapping = () => {
    setFieldMappings([...fieldMappings, STUB_MAPPING]);
    setMappingsAreDirty(true);
  };

  const removeMapping = (indexToRemove) => () => {
    const isPersisted = Boolean(fieldMappings[indexToRemove].id);

    if (!isPersisted) {
      return filterMappings(indexToRemove);
    }

    if (getUserConfirmation()) {
      setMappingsAreDirty(true);
      return filterMappings(indexToRemove);
    }
  };

  const getUserConfirmation = () =>
    window.confirm('Are you sure you want to remove this field mapping ?');
  const filterMappings = (indexToRemove) =>
    setFieldMappings(fieldMappings.filter((_, i) => i !== indexToRemove));

  const {
    data: fieldMappingData = {},
    loading: mappingsLoading,
    refetch: refetchFieldMappings,
  } = useQuery(fieldMappingsQuery, {
    displayName: 'fieldMappingsQuery',
    fetchPolicy: 'no-cache',
    variables: {
      integration,
    },
    notifyOnNetworkStatusChange: true,
  });

  const targetEnrichmentAttributesType =
    INTEGRATION_ENRICHMENT_TYPE_MAPPING[integration];

  const attributesQueryVariables = {
    refreshAttributes: false,
    targetTypes: [targetEnrichmentAttributesType],
    excludePersonPhone: hasAccessEnrichmentHubspot,
  };

  const {
    data: attributesData = {},
    loading: attributesLoading,
    refetch,
  } = useQuery(attributesQuery, {
    displayName: 'attributesQuery',
    fetchPolicy: 'no-cache',
    variables: attributesQueryVariables,
  });

  const onClickRefreshAttributes = () => {
    return refetch({ refreshAttributes: true });
  };

  const [updateFieldMapping, { loading: saveMappingsLoading }] = useMutation(
    upsertFieldMappingsMutation,
  );

  const saveFieldMappings = async () => {
    try {
      await updateFieldMapping({
        variables: {
          input: {
            integration: integration,
            fieldMappings: fieldMappings,
          },
        },
      });
      setMappingsAreDirty(false);

      ToastContainer.addSuccess(
        `${configurationName} Form Field Mappings Updated`,
      );

      return refetchFieldMappings();
    } catch (e) {
      ToastContainer.addError('There was a problem saving your field mappings');
    }
  };

  const savedFieldMappings = fieldMappingData?.formFieldMapping?.fieldMappings;

  const updateErrors = (key, value) => {
    const errors = fieldErrors;
    if (value === undefined) {
      delete errors[key];
      setFieldErrors(errors);
    } else if (errors[key] === undefined) {
      errors[key] = [value];
      setFieldErrors(errors);
    } else {
      errors[key].push(value);
      setFieldErrors(errors);
    }
  };

  const findDuplicates = (array) => {
    return array.filter((value, index, arry) => {
      if (arry.indexOf(value) !== index) return value;
    }, []);
  };

  const areFieldMappingsInvalid = useMemo(() => {
    const nullFields = fieldMappings
      .map((fm) => [fm.targetAttribute, fm.sourceAttribute])
      .flat()
      .includes(null);

    const targetMappings = fieldMappings.map((fm) => fm.targetAttribute);
    const targetFieldDuplicates = findDuplicates(targetMappings);
    if (targetFieldDuplicates.length > 0) {
      targetFieldDuplicates.forEach((targetField) => {
        updateErrors(targetField);
        updateErrors(targetField, 'duplicate field');
      });
    } else {
      setFieldErrors({});
    }

    return nullFields || targetFieldDuplicates.length > 0;
  }, [fieldMappings]);

  useEffect(() => {
    if (savedFieldMappings?.length > 0) {
      setFieldMappings([...savedFieldMappings]);
    }
  }, [savedFieldMappings]);

  useEffect(() => {
    setLoading(attributesLoading || mappingsLoading);
  }, [attributesLoading, mappingsLoading]);

  const SaveButton = () => {
    return (
      <CKButton
        isDisabled={areFieldMappingsInvalid}
        isLoading={saveMappingsLoading || loading}
        onClick={saveFieldMappings}
        variant="bold"
        variantColor="blue"
      >
        Save mappings
      </CKButton>
    );
  };

  return (
    <div className="divide-y divide-gray-200">
      <Prompt
        message={'You have unsaved changes, are you sure you want to leave?'}
        when={mappingsAreDirty}
      />

      <FormSection
        className="mb-9"
        description={
          <p>
            Map Clearbit person and company attributes to {configurationName}{' '}
            form fields.
          </p>
        }
        title={`${configurationName} form fields`}
      >
        {loading || !fieldMappingData || !attributesData ? (
          <CKBox className="h-64 min-w-[30rem]">
            <div className="flex items-center justify-center w-full h-full p-4">
              <SpinnerIndicators className="w-12 h-12" />
            </div>
          </CKBox>
        ) : (
          <CKBox className="min-w-[30rem]">
            <MappingRowContainer className="px-6 py-4 font-medium text-gray-700 border-b border-gray-200">
              <div className="font-medium">Clearbit attributes</div>
              <div className="font-medium">{configurationName} form fields</div>
            </MappingRowContainer>
            <div className="px-6">
              <div className="my-4 text-gray-600 space-y-6">
                {fieldMappings.map((mapping, index) => {
                  const rowKey = `${mapping.targetAttribute ||
                    'empty'}:${mapping.sourceAttribute || 'empty'}-${index}`;

                  return (
                    <FormFieldMappingRow
                      index={index}
                      key={rowKey}
                      mapping={mapping}
                      onChange={handleMappingChange(index)}
                      onClickRefreshAttributes={onClickRefreshAttributes}
                      onRemove={removeMapping(index)}
                      service={configurationName}
                      showError={showInvalidFields}
                      sourceAttributes={attributesData.sourceAttributes}
                      sourceErrors={fieldErrors[mapping.sourceAttribute]}
                      targetAttributes={attributesData.targetAttributes}
                      targetErrors={fieldErrors[mapping.targetAttribute]}
                      type={integration}
                    />
                  );
                })}
              </div>
            </div>
            <CKBox.Footer className="px-6 py-3">
              <CKLayoutHorizontal>
                <CKButton
                  className="mr-4"
                  leftIcon={<AddHollow />}
                  onClick={addMapping}
                  variantColor="blue"
                >
                  Add mapping
                </CKButton>
                {areFieldMappingsInvalid ? (
                  <CKTooltip tooltip="Some field mappings are invalid">
                    <div
                      onMouseEnter={() => setShowInvalidFields(true)}
                      onMouseLeave={() => setShowInvalidFields(false)}
                    >
                      <SaveButton />
                    </div>
                  </CKTooltip>
                ) : (
                  <SaveButton />
                )}
              </CKLayoutHorizontal>
            </CKBox.Footer>
          </CKBox>
        )}
      </FormSection>
    </div>
  );
}
