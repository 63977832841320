import { CKSkeleton } from 'clearkit';

import { ProspectorSidebar } from './ProspectorSidebar';

type SidebarBreadcrumbsProps = {
  loading?: boolean;
  title?: string;
};

export const SidebarBreadcrumbs = ({
  loading,
  title,
}: SidebarBreadcrumbsProps) => {
  return (
    <ProspectorSidebar.Breadcrumbs>
      {loading ? (
        <CKSkeleton className="w-full h-3 shrink-1 grow" />
      ) : (
        <span title={title}>{title}</span>
      )}
    </ProspectorSidebar.Breadcrumbs>
  );
};
SidebarBreadcrumbs.displayName = 'ProspectorSidebar.Breadcrumbs';
