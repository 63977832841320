import { CKSkeleton } from 'clearkit';

const loadingItems = Array.from(Array(20));

export const LoadingListItem = () => (
  <CKSkeleton
    className="rounded @item/list:h-[4.03125rem] h-[9.78125rem]"
    isLoading
  />
);

export const LoadingList = () => (
  <>
    {loadingItems.map((_, index) => (
      <LoadingListItem key={index} />
    ))}
  </>
);
