import isArray from 'lodash/isArray';
import map from 'lodash/map';

import NestedMultiSelect from '~/components/NestedMultiselect';
import { SearchContext } from '~/components/SearchBuilder/utils/searchUtils';

import { getOptionLabel, getOptionValue } from './utils';

const ValueMultiSelect = ({
  className,
  options,
  value,
  onChange,
  classNamePrefix,
  menuIsOpen,
  attribute,
}) => {
  // If the options provided are an object, rather than an array, they're
  // {key=>value} rather than [{label: value}] - convert them to an array.
  // In the future we might want lots of multiple options here instead
  if (!isArray(options)) {
    options = map(options, (label, value) => ({ value, label }));
  }

  const { disabled, salesforceUsers } = useContext(SearchContext);

  if (attribute?.details?.salesforceObjectType === 'user') {
    options = map(salesforceUsers, ({ id, name }) => ({
      value: id,
      label: name,
    }));
  }

  // TODO(elliott): This multi-select isn't actually nested, but we want the checkbox
  // styles in a new checkbox-style multi-select.
  return (
    <NestedMultiSelect
      className={className}
      classNamePrefix={classNamePrefix}
      disabled={disabled}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      menuIsOpen={menuIsOpen}
      onChange={onChange}
      options={options}
      title="Choose options"
      value={isArray(value) ? value : [value]}
    />
  );
};

ValueMultiSelect.defaultProps = {
  onChange: () => {},
  value: [],
};

ValueMultiSelect.propTypes = {
  attribute: PropTypes.shape({
    details: PropTypes.shape({
      salesforceObjectType: PropTypes.oneOf(['user']),
    }),
  }),
  classNamePrefix: PropTypes.string,
  menuIsOpen: PropTypes.bool,
  className: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object,
        PropTypes.array,
      ]),
    ),
  ]),
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onChange: PropTypes.func,
};

export default ValueMultiSelect;
