import {
  Companies,
  Facebook,
  Github as GitHub,
  Linkedin,
  Person,
  Twitter,
} from '@clearkit/icons';
import startCase from 'lodash/startCase';

import Group from './Group';

const PersonEnrichment = ({ person, enrichment, search }) => {
  const attribute = (label) => ({ label, value: valueForAttribute(label) });
  const valueForAttribute = (attribute) => {
    return enrichment?.find(({ label }) => label === attribute)?.value;
  };

  return (
    <>
      <Group
        icon={Person}
        search={search}
        sections={[
          [
            attribute('Full Name'),
            attribute('Given Name'),
            attribute('Family Name'),
            { label: 'Email', value: person.email },
            attribute('Email Provider'),
          ],
          [attribute('Phone')],
          [attribute('Site'), attribute('Avatar')],
          [attribute('Bio')],
        ]}
        title="Person"
      />

      <Group
        icon={Companies}
        search={search}
        sections={[
          [
            attribute('Employment Name'),
            attribute('Employment City'),
            {
              label: 'Role',
              value: startCase(valueForAttribute('Employment Role')),
            },
            {
              label: 'Sub Role',
              value: startCase(valueForAttribute('Employment Sub Role')),
            },
            {
              label: 'Employment Seniority',
              value: startCase(valueForAttribute('Employment Seniority')),
            },
            {
              label: 'Website',
              value: valueForAttribute('Employment Domain'),
            },
          ],
        ]}
        title="Employment"
      />

      <Group
        icon={Person}
        search={search}
        sections={[
          ['Location', 'Time Zone', 'UTC Offset'].map(attribute),
          [
            'Street Number',
            'Street Name',
            'City',
            'State',
            'State Code',
            'Postal Code',
            'Country',
            'Country Code',
          ].map(attribute),
          ['Latitude', 'Longitude'].map(attribute),
        ]}
        title="Location"
      />

      <Group
        icon={Twitter}
        search={search}
        sections={[
          [
            'Twitter Handle',
            'Twitter Id',
            'Twitter Followers',
            'Twitter Following',
          ].map(attribute),
        ]}
        title="Twitter"
        type={'twitter'}
      />
      <Group
        icon={Facebook}
        search={search}
        sections={[['Facebook Handle'].map(attribute)]}
        title="Facebook"
        type={'facebook'}
      />
      <Group
        icon={Linkedin}
        search={search}
        sections={[['Linkedin Handle'].map(attribute)]}
        title="Linkedin"
        type={'linkedin'}
      />
      <Group
        icon={GitHub}
        search={search}
        sections={[['GitHub Handle', 'GitHub Followers'].map(attribute)]}
        title="GitHub"
        type={'github'}
      />
    </>
  );
};

PersonEnrichment.propTypes = {
  enrichment: PropTypes.array,
  search: PropTypes.string,
  person: PropTypes.shape({
    email: PropTypes.string,
  }),
};

export default PersonEnrichment;
