import classnames from 'classnames';
import { CKBadge, CKContainerProps, CKTooltip } from 'clearkit';

type PillProps = CKContainerProps & {
  color?: 'blue' | 'orange' | 'green';
  tooltipMessage?: React.ReactNode;
};

const Pill = ({
  className,
  children,
  color,
  tooltipMessage,
  ...rest
}: PillProps) => {
  const colorClasses = {
    blue: 'bg-blue-500',
    orange: 'bg-yellow-400',
    green: 'bg-green-400',
  };

  const allClasses = classnames(
    'flex items-center',
    colorClasses[color as keyof typeof colorClasses],
    className,
  );

  const Contents = () => (
    <CKBadge className={allClasses} variant="rounded" {...rest}>
      {children}
    </CKBadge>
  );

  return tooltipMessage ? (
    <CKTooltip tooltip={tooltipMessage}>
      <Contents />
    </CKTooltip>
  ) : (
    <Contents />
  );
};

export default Pill;
