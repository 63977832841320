import { CKBox } from 'clearkit';

import { readableObjectTypeName, readableType } from '~/lib/dataEnrichment';

import EmptyState from '../EmptyState.svg';
import EmptyMappingStateHubspot from './EmptyMappingStateHubspot.svg';
import EmptyMappingStateSalesforce from './EmptyMappingStateSalesforce.svg';

const EmptyFieldMappingFields = ({ integration, objectType }) => {
  return (
    <>
      <CKBox
        className="flex flex-col items-center p-10"
        variant={CKBox.Variant['tinted-frame']}
      >
        <EmptyStateIcon integration={integration} />
        <p className="text-base font-medium text-center text-gray-800 mt-7">
          Let's get {objectType.name} field mappings set up
        </p>
        <p className="max-w-md mt-2 text-sm text-center text-gray-600">
          To sync enriched data to {readableObjectTypeName(objectType.name)} in
          your {readableType(integration)} CRM, you’ll need to set up field
          mappings. Add your first mapping to get started.
        </p>
      </CKBox>
    </>
  );
};

function EmptyStateIcon({ integration }) {
  switch (integration) {
    case 'salesforce':
      return <EmptyMappingStateSalesforce />;
    case 'hubspot':
      return <EmptyMappingStateHubspot />;
    default:
      return <EmptyState />;
  }
}

export default EmptyFieldMappingFields;
