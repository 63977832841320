import { CompaniesNav, NoResults } from '@clearkit/icons';
import classNames from 'classnames';
import {
  CKBox,
  CKIconBox,
  CKLayoutHorizontal,
  CKLayoutVertical,
  CKSkeleton,
} from 'clearkit';

import { CRMPresenceProvider } from '~/components/CRMPresence/CRMPresenceProvider';
import { ItemCount } from '~/components/ListItem/ItemCount';
import { PageLayout } from '~/components/PageLayout';
import {
  MAX_COUNT_HIDE,
  MAX_COUNT_HIDE_LABEL,
} from '~/components/prospector/useProspector/constants';

import { PLACEHOLDER_COMPANY, SAMPLE_COMPANIES } from '../constants';
import { UseTargetMarket } from '../hooks/useTargetMarket';
import { TargetMarketsCompany } from '../types';
import { CompanyItem } from './CompanyItem';
import { EmptyList } from './EmptyList';

type ListProps = {
  companies: TargetMarketsCompany[];
  isLoading: boolean;
};

const List = ({ isLoading, companies }: ListProps) => {
  const domains = companies.map((company) => company.domain);

  if (!isLoading && companies.length === 0) {
    return (
      <CKLayoutVertical
        alignItems="center"
        className="h-1 min-h-full"
        justifyContent="center"
      >
        <CKBox className="p-8 text-center min-w-[20rem] gap-4">
          <CKIconBox className="self-center">
            <NoResults className="fill-gradient-br-blue" />
          </CKIconBox>
          <div>
            <h3 className="mb-1 font-medium">No companies found</h3>
            <p className="text-sm">
              Try updating your filters to be less specific
            </p>
          </div>
        </CKBox>
      </CKLayoutVertical>
    );
  }

  return (
    <CRMPresenceProvider domains={domains}>
      <div className="space-y-6 @container/list">
        {companies.map((company, index) => (
          <CompanyItem
            company={company}
            key={`${company.domain}${index.toString()}`}
            loading={isLoading}
            number={index + 1}
          />
        ))}
      </div>
    </CRMPresenceProvider>
  );
};

const CompaniesList = ({
  isEditing,
  search,
  selectedTargetMarket,
  isLoading: targetMarketLoading,
  targetMarketCompanies,
  targetMarketCompaniesLoading,
}: UseTargetMarket) => {
  const isLoading = !!targetMarketLoading || !!targetMarketCompaniesLoading;
  const showPlaceholder = !search;
  const total = targetMarketCompanies?.targetMarketCompanies?.total || 0;

  const companies: TargetMarketsCompany[] = showPlaceholder
    ? SAMPLE_COMPANIES
    : isLoading
    ? Array(20).fill(PLACEHOLDER_COMPANY)
    : targetMarketCompanies?.targetMarketCompanies?.companies || [];

  return (
    <PageLayout className="@container/list">
      <PageLayout.HeaderWrapper className="py-3 pb-3 xl:py-3">
        <CKLayoutHorizontal className="min-h-[1.325rem]">
          {selectedTargetMarket || isEditing ? (
            <CKSkeleton className="w-32 h-[1.325rem]" isLoading={isLoading}>
              <ItemCount
                end={total}
                hideCountAbove={MAX_COUNT_HIDE}
                hideCountLabel={MAX_COUNT_HIDE_LABEL}
                label={total === 1 ? 'company' : 'companies'}
              >
                <CompaniesNav height={18} width={18} />
              </ItemCount>
            </CKSkeleton>
          ) : null}
        </CKLayoutHorizontal>
      </PageLayout.HeaderWrapper>
      <PageLayout.Body>
        <PageLayout.Content
          className={classNames('relative', {
            'overflow-hidden': showPlaceholder,
            'h-1 min-h-full': companies.length === 0,
          })}
        >
          <EmptyList isVisible={showPlaceholder} />
          <List companies={companies} isLoading={isLoading} />
        </PageLayout.Content>
      </PageLayout.Body>
    </PageLayout>
  );
};

export default CompaniesList;
