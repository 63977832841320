import { SpinnerIndicators } from '@clearkit/icons';
import { CKLayoutVertical } from 'clearkit';
import { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { useCurrentAccount } from '~/hooks/account';
import { isAuthRoute } from '~/lib/client';

import { routes } from './appMainRoutes';

function Gatekeeper({ history }: RouteComponentProps) {
  const { account } = useCurrentAccount();
  const { pathname } = history.location;

  useEffect(() => {
    if (!account) {
      return;
    }

    if (
      pathname.startsWith('/settings/configuration') ||
      pathname.startsWith(routes.getStarted)
    ) {
      return;
    }

    if (pathname === '/no-access') {
      history.push('/');
      return;
    }
  }, [account]);

  if (account || isAuthRoute()) {
    return null;
  }

  return (
    <CKLayoutVertical
      alignItems="center"
      className="absolute inset-0 text-white bg-white z-[1000]"
      justifyContent="center"
    >
      <SpinnerIndicators className="w-12 h-12" />
    </CKLayoutVertical>
  );
}

export default withRouter(Gatekeeper);
