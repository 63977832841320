import { Container } from 'unstated';

class ToastContainer extends Container {
  state = {
    toasts: [],
  };

  id = 0;

  addError = (heading, description) => {
    const toast = { variant: 'error', description, heading };
    this.addItem(toast);
  };

  addWarning = (heading, description) => {
    const toast = { variant: 'warning', description, heading };
    this.addItem(toast);
  };

  addMessage = (heading, description) => {
    const toast = { variant: 'info', description, heading };
    this.addItem(toast);
  };

  addSuccess = (heading, description) => {
    const toast = { variant: 'success', description, heading };
    this.addItem(toast);
  };

  removeToast = (id) => {
    const toasts = this.state.toasts.filter((n) => n.id !== id);
    this.setState({ toasts });
  };

  addItem = (details) => {
    const id = this.id++;
    const toast = { id, ...details };
    const toasts = [...this.state.toasts, toast];
    this.setState({ toasts });

    setTimeout(() => {
      const toasts = this.state.toasts.filter((toast) => toast.id !== id);

      this.setState({ toasts });
    }, 4000);
  };
}

const container = new ToastContainer();

export default container;
