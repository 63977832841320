const styles = () => ({
  headline: {
    color: '#596168',
    fontSize: 15,
    margin: '0 auto',
    maxWidth: 480,
    textAlign: 'center',
    fontWeight: 500,
  },

  typeToggle: {
    margin: '8px 0 28px',
  },

  link: {
    textDecoration: 'underline',
  },

  break: {
    border: '0.5px solid #e1e9f0',
    width: 480,
    margin: '23px auto 23px',
  },

  description: {
    color: '#596168',
    fontSize: 15,
    maxWidth: 400,
    textAlign: 'center',
    margin: '0 auto',
  },

  buttons: {
    textAlign: 'center',

    '& button': {
      display: 'inline-block',
      margin: '0px 8px',
    },
  },
});

export default styles;
