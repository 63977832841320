import classnames from 'classnames';
import { CKCompanyLogo, CKCompanyLogoProps } from 'clearkit';
import { FC } from 'react';

type CompanyLogoProps = CKCompanyLogoProps;

const CompanyLogo: FC<CompanyLogoProps> = ({
  className,
  cornerRadius = 100,
  ...rest
}) => (
  <CKCompanyLogo
    className={classnames(className, 'basis-auto grow-0 shrink-0')}
    cornerRadius={cornerRadius}
    {...rest}
  />
);

export default CompanyLogo;
