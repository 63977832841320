import { AddBlock, DirectionForward, Trash, Unknown } from '@clearkit/icons';
import {
  AttributeHierarchyItem,
  CKAttributeSelector,
  CKBox,
  CKButton,
  CKLayoutGrid,
  CKTooltip,
} from 'clearkit';
import capitalize from 'lodash/capitalize';

import { EnrichmentAttribute } from '~/generated/graphql';
import {
  attributeForFinder,
  backendSupportedDataType,
  findAttribute,
  readableType,
  sourceIcon,
} from '~/lib/dataEnrichment';
import { isTypeMismatch } from '~/lib/isTypeMismatch';

import {
  ConditionalOverwriteSelect,
  FieldMappingGroup as FieldMappingGroupType,
} from './ConditionalOverwriteSelect';
import ServiceIcon from './ServiceIcon';

type SelectedAttribute = EnrichmentAttribute | undefined | null;
type FieldMappingGroupProps = {
  enableConditionalOverwrite: boolean;
  integration: string;
  group: FieldMappingGroupType;
  type: string;
  objectType: any;
  onChange: (index: number) => (value: any) => void;
  onGroupChange: (value: any) => void;
  onRemove: () => void;
  onRemoveMapping: (index: number) => () => void;
  sourceAttributes: EnrichmentAttribute[];
  targetAttributes: EnrichmentAttribute[];
  addMapping: (value: any) => void;
  showError: boolean;
  errors: Record<string, string>;
};

export default function FieldMappingGroup({
  enableConditionalOverwrite,
  integration,
  group,
  type,
  objectType,
  onChange,
  onGroupChange,
  onRemove,
  onRemoveMapping,
  sourceAttributes,
  targetAttributes,
  addMapping,
  showError,
  errors,
}: FieldMappingGroupProps) {
  const inErrorState = (attribute: string) => {
    return !!errors[attribute] || (!attribute && showError);
  };

  return (
    <CKBox className="items-start py-4 grid" variant="tinted-frame">
      <CKLayoutGrid className="pb-2" gridTemplateColumns="2fr 1fr 3rem">
        <p className="flex items-center pl-6 text-base font-medium text-gray-600">
          Address Group
          <CKTooltip
            placement="top"
            tooltip="
                Address groups allow multiple mappings to respect the same
                overwrite setting. This is most often used for linking multiple
                address components (i.e. state and country) to ensure they
                are always written together. Groups will only be written if all
                fields contain a value."
          >
            <div className="ml-2 text-gray-300 outline-none">
              <Unknown height="16" width="16" />
            </div>
          </CKTooltip>
        </p>
        <div className="pl-8 pr-4">
          <ConditionalOverwriteSelect
            enableConditionalOverwrite={enableConditionalOverwrite}
            integration={integration}
            mapping={group}
            onChange={() =>
              onGroupChange({
                conditionalOverwrite: !group.conditionalOverwrite,
              })
            }
          />
        </div>
        <CKButton
          className="mr-5"
          onClick={onRemove}
          size="small"
          variant="tertiary"
        >
          <Trash className="text-gray-600" />
        </CKButton>
      </CKLayoutGrid>
      <hr />
      <div className="w-full px-6 mt-4 space-y-4">
        {group.fieldMappings.map((mapping) => {
          const selectedSourceAttribute = sourceAttributes
            ? findAttribute(
                attributeForFinder(mapping, 'source'),
                sourceAttributes,
              )
            : null;
          const selectedTargetAttribute = targetAttributes
            ? findAttribute(
                {
                  value: mapping.targetField,
                  objectType: objectType.name,
                  sourceType: type,
                },
                targetAttributes,
              )
            : null;

          const rowKey = `${mapping.targetField ||
            'empty'}:${mapping.sourceField || 'empty'}-${mapping.index}`;

          return (
            <CKLayoutGrid
              className="items-center"
              gridTemplateColumns="1fr 16px 1fr minmax(140px, min-content) minmax(80px,min-content)"
              key={rowKey}
            >
              <CKAttributeSelector
                attributes={sourceAttributes as AttributeHierarchyItem[]}
                icon={<ServiceIcon type={selectedSourceAttribute} />}
                isTypeMismatch={(attribute) =>
                  isTypeMismatch(attribute, selectedTargetAttribute)
                }
                onSelectedItemChange={(selected: SelectedAttribute) => {
                  onChange(mapping.index!)({
                    sourceField: selected?.value,
                    sourceObjectType: selected?.meta?.objectType,
                    sourceType: selected?.meta?.sourceType,
                  });
                }}
                placeholder="Find an attribute"
                selectedAttribute={selectedSourceAttribute || {}}
                variant={
                  inErrorState(mapping.sourceField) ? 'error' : undefined
                }
              />
              <DirectionForward className="text-gray" />
              <CKAttributeSelector
                attributes={targetAttributes as AttributeHierarchyItem[]}
                icon={sourceIcon(type)}
                isTypeMismatch={(attribute) =>
                  isTypeMismatch(selectedSourceAttribute, attribute)
                }
                onSelectedItemChange={(selected: SelectedAttribute) =>
                  onChange(mapping.index!)({
                    targetField: selected?.value,
                    dataType: backendSupportedDataType(
                      selected?.meta?.dataType,
                    ),
                  })
                }
                placeholder={`Choose a ${readableType(type)} ${capitalize(
                  objectType.name,
                )} attribute`}
                selectedAttribute={selectedTargetAttribute || {}}
                variant={
                  inErrorState(mapping.targetField) ? 'error' : undefined
                }
              />
              <div>&nbsp;</div>
              <div className="pt-1 text-right">
                <CKButton
                  onClick={onRemoveMapping(mapping.index!)}
                  variant="tertiary"
                >
                  <Trash className="text-gray-600" />
                </CKButton>
              </div>
            </CKLayoutGrid>
          );
        })}
      </div>
      <div className="mt-4 ml-6 justify-self-start">
        <CKButton
          leftIcon={<AddBlock />}
          onClick={() =>
            addMapping({
              groupId: group.groupId,
              conditionalOverwrite: group.conditionalOverwrite,
            })
          }
          variant="tertiary"
          variantColor="blue"
        >
          Add mapping to address group
        </CKButton>
      </div>
    </CKBox>
  );
}
