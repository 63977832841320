import { CKBox, CKLayoutHorizontal, CKLayoutHorizontalProps } from 'clearkit';
import { ReactNode } from 'react';

import { useCreditUsage } from '~/hooks/account/useCreditUsage';
import { EMPTY_LABEL } from '~/lib/constants';

import { useCurrentPlan } from '../billing/useCurrentPlan';
import { formatDate } from '../billing/utils';
import { MeterBox } from '../home/MeterBox';

type ValueProps = CKLayoutHorizontalProps & {
  label: ReactNode;
};

const Value = ({ children, label, ...rest }: ValueProps) => {
  return (
    <CKLayoutHorizontal {...rest}>
      <span className="font-medium">{label}</span>
      {children}
    </CKLayoutHorizontal>
  );
};

export const OverviewCard = () => {
  const { credits, creditsUsed } = useCreditUsage();
  const { currentPlan } = useCurrentPlan();

  return (
    <CKBox className="p-6 text-base text-gray-800 divide-y divide-gray-200 space-y-6">
      <header className="space-y-2">
        <h3 className="font-medium text-md">
          Credit usage & subscription detail
        </h3>
        <p className="text-sm">
          <a
            className="ck-link"
            href="https://help.clearbit.com/hc/en-us/articles/22910775621399-Clearbit-by-HubSpot-Understanding-Credits-and-Usage"
          >
            Learn more about credits
          </a>
          , or{' '}
          <a
            className="ck-link"
            href="https://help.clearbit.com/hc/en-us/requests/new"
          >
            contact us
          </a>{' '}
          with any questions regarding your account's usage.
        </p>
      </header>
      <CKLayoutHorizontal className="pt-6" gap="1.5rem" justifyContent="start">
        <MeterBox max={credits} size={40} value={creditsUsed} />
        <div className="space-y-2 grow">
          <Value label="Credits available">
            {credits.toLocaleString('en-us')} credits
          </Value>
          <Value label="Credits used">
            {creditsUsed.toLocaleString('en-us')} credits
          </Value>
        </div>
      </CKLayoutHorizontal>
      <div className="pt-6 space-y-2">
        <Value label="Subscription period">Annual</Value>
        <Value label="Renewal date">
          {currentPlan?.renewDate
            ? formatDate(currentPlan?.renewDate)
            : EMPTY_LABEL}
        </Value>
      </div>
    </CKBox>
  );
};
