import { useQuery } from '@apollo/client';
import { CKDrawer } from 'clearkit';
import { useState } from 'react';

import { SALESFORCE_RESOURCE_TYPE } from '~/components/SearchBuilder/utils/constants';
import SalesforceLogo from '~/images/destinations/Salesforce.svg';
import { destinationTypeSalesforceCreateAccount } from '~/lib/destinations';
import { readableSingularSegmentType } from '~/lib/segments';
import Disconnected from '~/pages/destinations/components/Disconnected';
import FormHeader from '~/pages/destinations/components/FormHeader';
import TriggerType from '~/pages/destinations/components/TriggerType';

import TraitConfiguration from '../components/TraitConfiguration';
import query from './query.graphql';

function CreateExternalRecordsAccountSalesforce({
  errors,
  refetch,
  accountConnectors,
  destination,
  _destinationOptions,
  segment,
  FormFooter,
}) {
  const accountConnector = accountConnectors.salesforce;
  const isNew = !destination;
  const initialConfig = isNew ? {} : destination.config;
  const humanizedSegmentType = readableSingularSegmentType(segment.type);
  const nameTraits = ['Company Name', 'Company Domain'];
  const mappedTraitLabels = {
    OwnerId: 'Account Owner',
    Type: 'Account Type',
  };

  const initialState = {
    traits: initialConfig.traits || [],
    triggerType: isNew ? 'segment_enter' : destination.triggerType,
  };

  const [traits, setTraits] = useState(initialState.traits);
  const [triggerType, setTriggerType] = useState(initialState.triggerType);
  const { data, loading } = useQuery(query);

  if (!accountConnector || accountConnector?.status !== 'active') {
    return (
      <Disconnected
        FormFooter={FormFooter}
        Logo={SalesforceLogo}
        onSuccess={refetch}
        service="salesforce"
      />
    );
  }

  if (loading) {
    return null;
  }

  return (
    <>
      <FormHeader
        customTitle={'Create new accounts in Salesforce'}
        destinationFlowDescription={'Configuration Destination'}
        destinationName="Salesforce"
        destinationType={destinationTypeSalesforceCreateAccount}
        segmentCollection={segment.collection.name}
        segmentName={segment.name}
      />

      <CKDrawer.Body className="p-6 bg-gray-0">
        <h2 className="mb-1 text-base font-medium text-gray-900">Trigger</h2>
        <h3 className="max-w-full mt-2 overflow-hidden text-sm text-gray-600 text-ellipsis whitespace-nowrap">
          We will only create a new account if the website domain doesn't exist
          in your Salesforce.{' '}
          <a
            className="ck-link"
            href="https://help.clearbit.com/hc/en-us/articles/360023453694"
            rel="noopener noreferrer"
            target="_blank"
          >
            Learn more
          </a>
        </h3>

        <TriggerType
          errors={errors}
          handleChange={(_, val) => setTriggerType(val)}
          isDisabled
          triggerType={triggerType}
          triggerTypes={[
            {
              value: 'segment_enter',
              label: (
                <span className="text-gray-600">
                  When a {humanizedSegmentType} <strong>enters</strong> the
                  audience
                </span>
              ),
            },
          ]}
        />

        <TraitConfiguration
          companyAttributes={data.companyAttributes}
          errors={errors}
          mappedTraitLabels={mappedTraitLabels}
          nameTraits={nameTraits}
          salesforceResourceType={SALESFORCE_RESOURCE_TYPE.ACCOUNT}
          salesforceTraits={data.salesforceTraits}
          setTraits={setTraits}
          traits={traits}
          unsettableValues={[
            {
              trait: 'Website',
              value: 'Company Domain',
            },
          ]}
        />
      </CKDrawer.Body>
      <FormFooter
        destinationConfig={{
          config: {
            traits,
          },
          triggerType,
        }}
      />
    </>
  );
}

CreateExternalRecordsAccountSalesforce.propTypes = {
  destination: PropTypes.object,
  destinationOptions: PropTypes.object,
  errors: PropTypes.array.isRequired,
  refetch: PropTypes.func.isRequired,
  accountConnectors: PropTypes.shape({
    salesforce: PropTypes.object.isRequired,
  }),
  segment: PropTypes.object,
};

export default CreateExternalRecordsAccountSalesforce;
