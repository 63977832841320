import { useMutation, useQuery } from '@apollo/client';
import { Info } from '@clearkit/icons';
import { CKBannerNext, CKButton, CKTextField } from 'clearkit';
import { useState } from 'react';

import GraphqlError from '~/components/GraphqlError';
import LoadingArea from '~/components/LoadingArea';
import history from '~/lib/history';
import paths from '~/lib/paths';

import mutation from './mutation.graphql';
import query from './query.graphql';
import styles from './styles';

// TODO: where do we want to link here?
// const LEARN_MORE_LINK = 'https://google.com';
const G2_TOKEN_PAGE_LINK =
  'https://my.g2.com/clearbit-data-activation-platform/api_users/new';

const G2ConnectionForm = ({ classes }) => {
  const { data, loading, error, refetch } = useQuery(query);
  const [apiKey, setApiKey] = useState('');
  const [updateErrors, setUpdateErrors] = useState({});

  const [upsertG2Connector, { loading: upsertLoading }] = useMutation(mutation);

  async function G2Connector() {
    setUpdateErrors({});

    const { data: mutationData } = await upsertG2Connector({
      variables: {
        input: { apiToken: apiKey },
      },
    });

    let { errors } = mutationData.upsertG2Connector;

    if (errors.length > 0) {
      const errorObj = {};

      errors.forEach(({ field, messages }) => {
        errorObj[field] = messages;
      });

      setUpdateErrors(errorObj);
    } else {
      history.push(paths.configuration('g2'));
    }
  }

  if (error) {
    return <GraphqlError error={error} loading={loading} refetch={refetch} />;
  }

  if (loading && !data) return <LoadingArea loading />;

  return (
    <div className={classes.contentContainer}>
      <div className={classes.description}>
        <span>
          Connect your G2 account and bring powerful intent data into Clearbit
          to create powerful intent-based audiences.
        </span>
      </div>
      <hr className={classes.break} />
      {updateErrors?.api_token ? (
        <CKBannerNext className="mb-6" variant="error">
          <CKBannerNext.Icon>
            <Info />
          </CKBannerNext.Icon>
          <CKBannerNext.Body>{updateErrors?.api_token}</CKBannerNext.Body>
        </CKBannerNext>
      ) : null}
      <p className={classes.headline}>Enter your G2 API token</p>
      <div className={` ${classes.description}`}>
        <span className="mt-1.5">
          A G2-generated API token is necessary for connecting to the G2 API. If
          you don’t have a G2 API token yet,{' '}
          <a
            className={classes.link}
            href={G2_TOKEN_PAGE_LINK}
            rel="noopener noreferrer"
            target="_blank"
          >
            start here.
          </a>{' '}
        </span>
      </div>
      <div className={classes.textField}>
        <CKTextField
          className="flex-none flex-grow"
          onChange={(e) => setApiKey(e.target.value)}
          value={apiKey}
        />
      </div>
      <div className={classes.buttons}>
        <CKButton
          className={classes.connectButton}
          isDisabled={apiKey.length === 0 || upsertLoading}
          isLoading={upsertLoading}
          onClick={G2Connector}
          style={{ width: '133px' }}
          variant="bold"
          variantColor="blue"
        >
          Connect to G2
        </CKButton>
      </div>
    </div>
  );
};

export default withStyles(styles)(G2ConnectionForm);
