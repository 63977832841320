import { GoExternal, Hubspot, Salesforce } from '@clearkit/icons';
import isArray from 'lodash/isArray';
import startCase from 'lodash/startCase';
import Linkify from 'react-linkify';

import theme from '~/components/app/theme';

import styles from './styles';

const ExternalLink = ({ children, ...rest }) => (
  <a rel="noopener noreferrer" target="_blank" {...rest}>
    {children}
  </a>
);

ExternalLink.propTypes = {
  children: PropTypes.node,
};

const Value = ({ value, label, classes }) => {
  if (!value) return <>&mdash;</>;

  if (value.__typename === 'SalesforceOpportunity') {
    return (
      <ExternalLink
        className="flex flex-row w-full px-2 py-1 mb-2 ck-tag"
        href={`${value.accountConnector?.externalInstanceUrl}/${value.externalId}`}
        style={{ backgroundColor: theme.palette.salesforce[500] }}
        target="blank"
      >
        <Salesforce className="mr-2" />
        Salesforce Opportunity - {value['name']}
        <GoExternal className="ml-2" />
      </ExternalLink>
    );
  }
  if (value.__typename === 'HubspotDeal') {
    return (
      <ExternalLink
        className="flex flex-row w-full px-2 py-1 mb-2 ck-tag"
        href={`https://app.hubspot.com/contacts/${value.hubspotAccountId}/deal/${value.id}`}
        style={{ backgroundColor: theme.palette.hubspot[500] }}
      >
        <Hubspot className="mr-2" />
        Hubspot Deal - {value['name']}
        <GoExternal className="ml-2" />
      </ExternalLink>
    );
  }

  if (label === 'Linkedin Handle') {
    return (
      <ExternalLink href={`https://linkedin.com/${value}`}>
        {value.replace('company/', '')}
      </ExternalLink>
    );
  }

  if (label === 'Twitter Handle') {
    return (
      <ExternalLink href={`https://twitter.com/${value}`}>
        @{value}
      </ExternalLink>
    );
  }

  if (label === 'Facebook Handle') {
    return (
      <ExternalLink href={`https://facebook.com/${value}`}>
        {value}
      </ExternalLink>
    );
  }

  if (label === 'GitHub Handle') {
    return (
      <ExternalLink href={`https://github.com/${value}`}>{value}</ExternalLink>
    );
  }

  if (isArray(value)) {
    return (
      <div className="pt-1">
        {value.map((aValue) => {
          if (typeof aValue !== 'string') {
            return <StyledValue key={aValue} label={label} value={aValue} />;
          }

          let text = aValue;
          if (label === 'Tech Categories') text = startCase(text);

          return (
            <div className="my-1 mr-2 ck-tag" key={aValue}>
              {text}
            </div>
          );
        })}
      </div>
    );
  }

  if (typeof value === 'string') {
    const isAvatar =
      value.match('pbs.twimg.com') || value.match('secure.gravatar.com');

    if (isAvatar) {
      return <img alt="Avatar" height={48} src={value} />;
    }

    return (
      <Linkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <ExternalLink href={decoratedHref} key={key}>
            {decoratedText}
          </ExternalLink>
        )}
      >
        {value}
      </Linkify>
    );
  }

  if (typeof value === 'object' && value.url) {
    return <img alt="thumbnail" height={48} src={value.url} />;
  }

  if (typeof value === 'object' && value.title && value.value) {
    return (
      <span>
        {value.title}:{' '}
        <ExternalLink href={value.value}>{value.value}</ExternalLink>
      </span>
    );
  }

  if (typeof value === 'object') {
    return Object.keys(value).map((val) => (
      <div className={classes.pill} key={val}>
        {val}: <StyledValue label={label} value={value[val]} />
      </div>
    ));
  }

  if (value && value.toString) {
    return value.toString();
  }

  return <>&mdash;</>;
};

Value.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string,
  value: PropTypes.any,
};

const StyledValue = withStyles(styles)(Value);

export default StyledValue;
