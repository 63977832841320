import {
  AccountFlagEnum,
  ProductFeatureEnum,
  useUsageDashboardFeaturesListQuery,
} from '~/generated/graphql';
import useFeatureFlag from '~/hooks/account/useFeatureFlag';

const POLL_INTERVAL = 60000;

export const useCreditUsage = () => {
  const { data, loading, error } = useUsageDashboardFeaturesListQuery({
    pollInterval: POLL_INTERVAL,
  });

  const freeTierDefaultCredits = 500;

  const hasUsageFlag = useFeatureFlag(AccountFlagEnum.XShowUsageOnPlatform)
    .enabled;

  const productFeature = data?.productFeatures.find(
    (item) =>
      item.id === ProductFeatureEnum.FreeTier ||
      item.id === ProductFeatureEnum.PaidTier ||
      item.id === ProductFeatureEnum.BusinessTier ||
      item.id === ProductFeatureEnum.HubspotFreeTier ||
      item.id === ProductFeatureEnum.HubspotPaidTier ||
      item.id === ProductFeatureEnum.HubspotPlatformFree ||
      item.id === ProductFeatureEnum.HubspotPlatformPaid,
  );

  const isHubspotAccount =
    productFeature?.id === ProductFeatureEnum.HubspotFreeTier ||
    productFeature?.id === ProductFeatureEnum.HubspotPaidTier;
  const isFreeAccount =
    productFeature?.id === ProductFeatureEnum.FreeTier ||
    productFeature?.id === ProductFeatureEnum.HubspotFreeTier;
  const isPaidAccount =
    productFeature?.id === ProductFeatureEnum.PaidTier ||
    productFeature?.id === ProductFeatureEnum.HubspotPaidTier;

  const credits = productFeature?.usageLimit as number;
  const creditsUsed =
    productFeature?.currentPeriodTotalUsage.total?.credits || 0;
  const creditsRemaining =
    credits - creditsUsed < 0 ? 0 : credits - creditsUsed;

  const hasCredits = !!credits;
  const hasUsage = (hasUsageFlag || hasCredits) && !error;

  const creditsPerProspect =
    productFeature?.usageMetricGraph['contacts created']?.modifier;

  const planId = productFeature?.id;
  const endDate = productFeature?.currentPeriodEndDate;

  return {
    credits,
    creditsUsed,
    creditsRemaining,
    creditsPerProspect,
    endDate,
    freeTierDefaultCredits,
    hasCredits,
    hasUsage,
    isFreeAccount,
    isHubspotAccount,
    isPaidAccount,
    loading,
    planId,
  };
};
