// Disable CSS animations while resizing the window.
// This massively improves window-resize performance,
// because resizing causes reflows that conflict
// with the CSS animations!

// See index.css for the related .resize-animation-stopper class.

export default () => {
  let resizeTimer;
  window.addEventListener('resize', () => {
    clearTimeout(resizeTimer);
    if (!document.body.classList.value.includes('resize-animation-stopper')) {
      document.body.classList.add('resize-animation-stopper');
    }
    resizeTimer = setTimeout(() => {
      document.body.classList.remove('resize-animation-stopper');
    }, 400);
  });
};
