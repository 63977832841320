
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SegmentDestinations"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Segment"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"audiences"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SegmentDestinationsPageAudience"},"directives":[]}]}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"destinations"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"description"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"type"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"config"},"arguments":[],"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"DestinationsEnabledSwitchDestination"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"type"},"arguments":[],"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SegmentDestinationsPreloadCount"},"directives":[]}]}}],"loc":{"start":0,"end":475}};
    doc.loc.source = {"body":"#import \"./Audience/fragments.graphql\"\n#import \"~/pages/destinations/components/EnabledSwitch/fragments.graphql\"\n#import \"~/pages/destinations/List/preloadFragment.graphql\"\n\nfragment SegmentDestinations on Segment {\n  id\n  audiences {\n    edges {\n      node {\n        ...SegmentDestinationsPageAudience\n      }\n    }\n  }\n  destinations {\n    id\n    description\n    type\n    config\n    ...DestinationsEnabledSwitchDestination\n  }\n  type\n  ...SegmentDestinationsPreloadCount\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./Audience/fragments.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("~/pages/destinations/components/EnabledSwitch/fragments.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("~/pages/destinations/List/preloadFragment.graphql").definitions));


      module.exports = doc;
    
