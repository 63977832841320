const styles = (theme) => ({
  root: {
    background: theme.body.backgroundGrey,
    padding: '20px 40px',
    height: '100%',

    '& strong': {
      fontWeight: 500,
    },
  },

  infoView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > div': {
      height: 37,
      display: 'flex',
      alignItems: 'center',
      '& a': {
        color: theme.palette.text.link,
        fontWeight: 500,
        fontSize: 14,
      },
      '& svg': {
        marginRight: 10,
      },
    },
  },

  box: {
    background: '#FFFFFF',
    border: '1px solid #E1E9F0',
    boxShadow: '0 1px 2px 0 rgba(0,0,0,0.05)',
    borderRadius: 6,
    marginBottom: 30,
  },

  sectionTitle: {
    fontSize: 14,
    marginBottom: 30,
    '& p:first-child': {
      fontSize: 15,
      fontWeight: 500,
    },
    '& a': {
      textDecoration: 'underline',
      color: theme.palette.text.link,
    },
  },

  importError: {
    borderRadius: 7,
    boxShadow:
      'inset 0px 0px 0px 1px rgba(246, 161, 148, 0.4), 0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
    backgroundColor: '#fdf3f1',
    padding: '12px 17px 13px',
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,

    '& svg': {
      marginRight: 17,
    },
  },

  errorTitle: {
    color: '#b11e0e',
    fontSize: 15,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  errorSubtitle: {
    color: '#b11e0e',
    fontSize: 14,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  errorDetail: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },

  option: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '& svg': {
      flexShrink: 0,
    },
  },

  newOptionValue: {
    color: '#148cfc',
    display: 'flex',
    alignItems: 'center',
  },

  newOptionMenu: {
    color: '#148cfc',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  optionValue: {
    display: 'flex',
    alignItems: 'center',
  },

  optionMenu: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& svg': {
      position: 'relative',
      top: -1,
    },
  },

  traitIconBlue: {
    marginRight: 8,
    width: 16,
    height: 12,

    '& path': {
      fill: '#148cfc',
    },
  },

  newAdornment: {
    marginLeft: 8,
  },

  optionValuePreview: {
    fontFamily: 'Monospace',
    background: '#FAFBFC',
    boxShadow: 'inset 0 0 0 1px #E1E9F0',
    borderRadius: 5,
    padding: '3px 4px 2px',
    color: '#9BADBC',
    marginLeft: 5,
    position: 'relative',
    fontSize: 13,
  },

  boxSection: {
    padding: 30,
    borderBottom: '1px solid #E1E9F0',
    '&:last-child': {
      borderBottom: 'none',
    },
    '& p': {
      margin: 0,
    },
    position: 'relative',
  },

  segmentDetails: {
    background: 'rgb(250, 251, 252)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& svg': {
      margin: '0 15px 0 0',
    },
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },

  boxSectionFull: {
    padding: 30,
    width: '100%',
    borderRight: '1px solid #E1E9F0',
    '&:last-child': {
      borderRight: 'none',
    },
  },

  boxSectionHalf: {
    padding: 30,
    width: '50%',
    borderRight: '1px solid #E1E9F0',
    '&:last-child': {
      borderRight: 'none',
    },
    position: 'relative',
  },

  fieldLabel: {
    paddingBottom: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    '& svg': {
      marginRight: 10,
    },
  },

  boxSectionHorizontal: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #E1E9F0',
    '&:last-child': {
      borderBottom: 'none',
    },
  },

  checkbox: {
    padding: 0,
  },

  column: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
    alignItems: 'center',
  },

  columnHeader: {
    paddingBottom: 10,
  },

  arrowRight: {
    margin: '0px 20px',
  },

  csvColumns: {
    width: 'calc(50% + 26px)',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },

  titleValue: {
    padding: '5px 8px',
    width: 'calc(50% - 10px)',
    height: 36,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid #E1E9F0',
    overflow: 'hidden',

    '& span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '100%',
    },

    '&:first-of-type': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      borderRight: 'none',
    },

    '&:last-of-type': {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      background: '#FAFBFC',
      fontFamily: 'Monospace',
    },
  },

  loadingBar: {
    marginRight: 16,
  },

  importDetail: {
    display: 'flex',
    flexDirection: 'column',
    color: '#7b8b99',
    fontSize: 14,
  },

  importingStatusContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  rowsProcessed: {
    fontSize: 13,
  },

  traitSelector: {
    width: '50%',
  },

  submitButton: {
    margin: '0 0 0 10px',
    minWidth: 160,
  },

  attributeSelector: {
    flexGrow: '1',
    width: 'calc(50% - 26px)',
  },

  circularProgress: {
    color: '#7b8b99',
  },

  error: {
    color: theme.palette.text.error,
    position: 'absolute',
    marginTop: 7,
  },

  disabledOverlay: {
    opacity: '0.5',
  },

  warningBanner: {
    color: '#c98000',
    backgroundColor: '#fefbf1',
    borderRadius: 6,
    border: '1px solid #fde08e',
    padding: '9px 13px',
    fontSize: 14,
    marginTop: 12,
    display: 'flex',
    flexDirection: 'row',
  },

  iconContainer: {
    marginRight: 9,
  },
});

export default styles;
