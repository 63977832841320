// eslint-disable-next-line clearbit-x/no-tree-shaking-imports
import { fade } from '@material-ui/core/styles/colorManipulator';

export default (theme) => ({
  salesforceButton: {
    '& span': {
      color: theme.palette.salesforce[500],
    },

    '&:focus': {
      boxShadow: `0 0 0 3px ${fade(theme.palette.salesforce[500], 0.1)}`,

      '&::before': {
        boxShadow: `0 0 0 1px ${fade(theme.palette.salesforce[500], 0.8)},
          0 1px 2px 1px ${fade(theme.palette.salesforce[500], 0)} !important`,
      },
    },

    '&::before': {
      background: `${fade(theme.palette.salesforce[500], 0.03)} !important`,
      boxShadow: `0 0 0 1px ${fade(theme.palette.salesforce[500], 0.2)},
        0 1px 2px 1px ${fade(theme.palette.salesforce[500], 0.1)} !important`,
    },

    '&:hover::before': {
      background: `${fade(theme.palette.salesforce[500], 0.01)} !important`,
      boxShadow: `0 0 0 1px ${fade(theme.palette.salesforce[500], 0.2)},
        0 1px 2px 1px ${fade(theme.palette.salesforce[500], 0.2)} !important`,
    },

    '&:active::before': {
      background: `${fade(theme.palette.salesforce[500], 0.05)} !important`,
      boxShadow: `0 0 0 1px ${fade(theme.palette.salesforce[500], 0.5)},
        0 1px 2px 1px ${fade(theme.palette.salesforce[500], 0)} !important`,
    },
  },

  hubspotButton: {
    '& span': {
      color: theme.palette.hubspot[500],
    },

    '&:focus': {
      boxShadow: `0 0 0 3px ${fade(theme.palette.hubspot[500], 0.1)}`,

      '&::before': {
        boxShadow: `0 0 0 1px ${fade(theme.palette.hubspot[500], 0.8)},
          0 1px 2px 1px ${fade(theme.palette.hubspot[500], 0)} !important`,
      },
    },

    '&::before': {
      background: `${fade(theme.palette.hubspot[500], 0.03)} !important`,
      boxShadow: `0 0 0 1px ${fade(theme.palette.hubspot[500], 0.2)},
        0 1px 2px 1px ${fade(theme.palette.hubspot[500], 0.1)} !important`,
    },

    '&:hover::before': {
      background: `${fade(theme.palette.hubspot[500], 0.01)} !important`,
      boxShadow: `0 0 0 1px ${fade(theme.palette.hubspot[500], 0.2)},
        0 1px 2px 1px ${fade(theme.palette.hubspot[500], 0.2)} !important`,
    },

    '&:active::before': {
      background: `${fade(theme.palette.hubspot[500], 0.05)} !important`,
      boxShadow: `0 0 0 1px ${fade(theme.palette.hubspot[500], 0.5)},
        0 1px 2px 1px ${fade(theme.palette.hubspot[500], 0)} !important`,
    },
  },
});
