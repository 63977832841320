import {
  ClearbitServices as Clearbit,
  FacebookServices as Facebook,
  G2Services as G2,
  GoogleAdsServices as GoogleAdsIcon,
  GoogleAnalyticsServices as GoogleAnalytics,
  GoogleServices as Google,
  GoogleTagmanagerServices as GoogleTagManager,
  HubspotServices as Hubspot,
  JavascriptServices as Javascript,
  MarketoServices as Marketo,
  SalesforceServices as Salesforce,
  SegmentServices as Segment,
  SlackServices as Slack,
} from '@clearkit/icons';

const logos = {
  clearbit: Clearbit,
  facebook: Facebook,
  google: GoogleAnalytics,
  google_ads: GoogleAdsIcon,
  google_analytics: GoogleAnalytics,
  google_conversion: Google,
  google_tag_manager: GoogleTagManager,
  marketo: Marketo,
  hubspot: Hubspot,
  javascript: Javascript,
  salesforce: Salesforce,
  salesforcesandbox: Salesforce,
  pardot: Salesforce,
  segment: Segment,
  slack: Slack,
  g2: G2,
};

export type LogoService = keyof typeof logos;

export type ConfigurationLogoProps = {
  type: LogoService;
  className?: string;
  style?: React.CSSProperties;
};

export default function ConfigurationLogo({
  type,
  ...props
}: ConfigurationLogoProps) {
  const Component = logos[type];

  if (!Component) {
    return null;
  }

  return <Component {...props} />;
}
