import { Go, SubscriptionNav, UsageAltNav } from '@clearkit/icons';
import { CKActionMenu, CKNavigationBarMenu, CKSkeleton } from 'clearkit';
import { Link } from 'react-router-dom';

import { AccountFlagEnum } from '~/generated/graphql';
import { useEntitlements, useFeatureFlag } from '~/hooks/account';
import { useCreditUsage } from '~/hooks/account/useCreditUsage';

import { routes } from '../app/appMainRoutes';
import { Metric } from '../home/HomeSidebar';
import { MeterBox } from '../home/MeterBox';
import { getPlanDisplayName } from './util';

export const UsageMenu = () => {
  const {
    credits,
    creditsUsed,
    freeTierDefaultCredits,
    hasUsage,
    isPaidAccount,
    hasCredits,
    loading,
    planId,
  } = useCreditUsage();

  const { hasAccessPlans } = useEntitlements();

  const GoIcon = () => <Go className="text-blue group-hover:text-white" />;
  const LastInvoiceFailed = useFeatureFlag(AccountFlagEnum.LastInvoiceFailed)
    .enabled;

  if (!hasCredits) {
    return null;
  }

  const displayCredits =
    isPaidAccount && LastInvoiceFailed ? freeTierDefaultCredits : credits;

  const UsageMeter = () => (
    <MeterBox
      className="ck-nav__iconBox"
      max={displayCredits}
      size={24}
      strokeWidth="0.1875rem"
      value={creditsUsed}
    />
  );
  UsageMeter.displayName = 'CKNavigationBarMenu.Icon';

  const isPlanLinkDisabled = !hasAccessPlans;

  return (
    <CKNavigationBarMenu popoverPlacement="top-start">
      <UsageMeter />
      <CKNavigationBarMenu.Content>
        {hasUsage || loading ? (
          <div className="truncate space-y-1">
            <CKSkeleton
              className="h-6 w-[14ch] ck-skeleton--white"
              isLoading={loading}
              label="loading"
            >
              <CKNavigationBarMenu.Heading>
                <Metric
                  value={
                    hasCredits ? displayCredits - creditsUsed : 'Unlimited'
                  }
                />
                credits remaining
              </CKNavigationBarMenu.Heading>
            </CKSkeleton>
            <CKSkeleton
              className="h-6 w-[16ch] ck-skeleton--white"
              isLoading={loading}
              label="loading"
            >
              <CKNavigationBarMenu.Subheading className="capitalize">
                {getPlanDisplayName(planId)}
              </CKNavigationBarMenu.Subheading>
            </CKSkeleton>
          </div>
        ) : (
          <div>No Usage Data Available</div>
        )}
        <CKNavigationBarMenu.Menu>
          <CKActionMenu>
            <CKActionMenu.Item
              as={Link}
              className="group"
              leftIcon={<UsageAltNav height={18} width={18} />}
              rightIcon={<GoIcon />}
              to={routes.usage}
              variant="tertiary"
              variantColor="blue"
            >
              View Usage
            </CKActionMenu.Item>
            <CKActionMenu.Item
              as={Link}
              className="group"
              isDisabled={isPlanLinkDisabled}
              leftIcon={<SubscriptionNav height={18} width={18} />}
              rightIcon={isPlanLinkDisabled ? null : <GoIcon />}
              to={routes.plansUpgrade}
              variant="tertiary"
              variantColor="blue"
            >
              Upgrade Plan
            </CKActionMenu.Item>
          </CKActionMenu>
        </CKNavigationBarMenu.Menu>
      </CKNavigationBarMenu.Content>
    </CKNavigationBarMenu>
  );
};
