import classnames from 'classnames';
import { CKContainerProps } from 'clearkit';
import React, { FC } from 'react';

export const HomeHeading: FC<CKContainerProps<HTMLHeadingElement>> = ({
  className,
  children,
  ...rest
}) => {
  return (
    <h2
      className={classnames('font-medium flex gap-2 items-center', className)}
      {...rest}
    >
      {children}
    </h2>
  );
};
