import isEmpty from 'lodash/isEmpty';
const defaultTypeMapping = {
  string: ['string', 'number', 'boolean', 'datetime', 'select', 'multi_select'],
  number: ['number', 'string'],
  datetime: ['datetime', 'string'],
  boolean: ['boolean'],
  select: ['multi_select', 'select', 'string', 'number', 'boolean'],
};

const defaultExternalTypeMapping = {
  multi_select: [],
};

const salesforceExternalTypeMapping = {
  multi_select: ['textarea', 'multipicklist'],
};

const hubSpotExternalTypeMapping = {
  multi_select: ['textarea', 'checkbox'],
};

const marketoExternalTypeMapping = {
  multi_select: ['string'],
};

const selectExternalMapping = (integrationType) => {
  switch (integrationType) {
    case 'salesforce':
      return salesforceExternalTypeMapping;
    case 'hubspot':
      return hubSpotExternalTypeMapping;
    case 'marketo':
      return marketoExternalTypeMapping;
    default:
      return defaultExternalTypeMapping;
  }
};

export const isTypeMismatch = (sourceAttribute, targetAttribute) => {
  if (
    targetAttribute &&
    targetAttribute.meta &&
    Object.prototype.hasOwnProperty.call(targetAttribute.meta, 'updateable')
  ) {
    if (!targetAttribute.meta.updateable) return true;
  }

  if (
    !sourceAttribute ||
    !targetAttribute ||
    isEmpty(sourceAttribute) ||
    isEmpty(targetAttribute)
  ) {
    return false;
  }

  if (
    sourceAttribute.type === 'category' ||
    targetAttribute.type === 'category'
  ) {
    return false;
  }

  const typeMapping = defaultTypeMapping;

  const externalTypeMapping = selectExternalMapping(
    targetAttribute.meta?.sourceType,
  );

  // The external_type field holds the original, raw type that comes from the
  // external services (i.e. multipicklist from Salesforce).
  //
  // If we've defined an external type mapping for the source attribute type
  // we must make sure the target attribute type is allowed for that type.
  //
  // i.e. multi-select enrichment attributes (like tags) must only be mapped
  // to textarea or multipicklist types from Salesforce. Even though it would
  // be technically possible to map a multi-select attribute to a simple
  // string field, we would potentially break the fields' length limit, as
  // arrays usually have plenty items on it.
  const externalTypes = externalTypeMapping[sourceAttribute.meta?.dataType];
  if (externalTypes) {
    return !externalTypes.some(
      (type) => type === targetAttribute.meta?.externalType,
    );
  }

  // If we've defined a type mapping for the target attribute type we must
  // make sure the entity attribute type is allowed for that type.
  const entityTypes = typeMapping[targetAttribute.meta?.dataType];
  if (entityTypes) {
    return !entityTypes.some((type) => type === sourceAttribute.meta?.dataType);
  }

  // If nothing was defined, there is no mismatch.
  return false;
};
