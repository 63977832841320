import {
  Companies,
  Facebook,
  Job,
  Linkedin,
  Location,
  Money,
  Tech,
  Twitter,
} from '@clearkit/icons';
import startCase from 'lodash/startCase';

import Group from './Group';

const CompanyEnrichment = ({ enrichment, search }) => {
  const valueForAttribute = (attribute, attributeKey) => {
    const normalizedAttribute =
      attributeKey ||
      attribute.substring(0, 1).toLowerCase() +
        attribute.replace(/ /g, '').substring(1);

    // Direct lookup/guess by attribute
    let value = enrichment?.[attribute] || enrichment?.[normalizedAttribute];

    // Nested lookup (like Parent Domain)
    if (!value) {
      const keys = attribute.split(' ');
      if (keys.length > 1) {
        const nestedEnrichmentData = valueForAttribute(keys[0]);
        value =
          nestedEnrichmentData?.[keys[1]] ||
          nestedEnrichmentData?.[
            keys[1].substring(0, 1).toLowerCase() +
              keys[1].replace(/ /g, '').substring(1)
          ];
      }
    }

    // Any lookup by attribute (like metrics -> employees)
    if (!value) {
      const allKeys = Object.keys(enrichment).map((key) => key.toLowerCase());

      value = allKeys
        .map((key) => {
          return enrichment[key]?.[normalizedAttribute] || null;
        })
        .filter(Boolean)?.[0];
    }

    return value;
  };
  const attribute = (label) => ({ label, value: valueForAttribute(label) });

  return (
    <>
      <Group
        icon={Companies}
        search={search}
        sections={[
          ['Name', 'Legal Name', 'Founded Year'].map(attribute),
          [
            'Domain',
            'Domain Aliases',
            'Parent Domain',
            'Ultimate Parent Domain',
            'Phone',
          ].map(attribute),
          [
            {
              label: 'Description',
              value: valueForAttribute('Description'),
            },
            {
              label: 'Type',
              value: startCase(valueForAttribute('Type')),
            },
          ],
          ['Employees', 'Employees Range'].map(attribute),
          ['Clearbit Traffic Rank'].map(attribute),
          ['Email Provider'].map(attribute),
        ]}
        title="Company"
      />

      <Group
        icon={Job}
        search={search}
        sections={[
          [
            {
              ...attribute('Industry'),
              children: ['Sector', 'Industry Group', 'Sub Industry'].map(
                attribute,
              ),
            },
          ],
          ['Tags'].map(attribute),
          [
            {
              label: 'GICS 8 Digit Code',
              value: valueForAttribute('GICS 8 Digit Code', 'gics8Code'),
              tooltip:
                'Clearbit provides the first 2 digits of SIC, representing the company sector',
            },
            {
              label: 'SIC 2 Digit Code',
              value: valueForAttribute('SIC 2 Digit Code', 'sicCode'),
              tooltip:
                'Clearbit provides the first 2 digits of SIC, representing the major group',
            },
            {
              label: 'SIC 4 Digit Codes',
              value: valueForAttribute('SIC 4 Digit Codes', 'sic4Codes'),
            },
            {
              label: 'NAICS 2 Digit Code',
              value: valueForAttribute('NAICS 2 Digit Code', 'naicsCode'),
              tooltip:
                'Clearbit provides the first 2 digits of NAICS, representing the company sector',
            },
            {
              label: 'NAICS 6 Digit Codes',
              value: valueForAttribute('NAICS 6 Digit Codes', 'naics6Codes'),
            },
            {
              label: 'NAICS 2022 6 Digit Codes',
              value: valueForAttribute(
                'NAICS 2022 6 Digit Codes',
                'naics6Codes2022',
              ),
            },
          ],
        ]}
        title={'Industry'}
      />
      <Group
        icon={Money}
        search={search}
        sections={[
          ['Annual Revenue', 'Estimated Annual Revenue'].map(attribute),
          ['Ticker', 'Market Cap'].map(attribute),
          ['Raised'].map(attribute),
          ['Fiscal Year End'].map(attribute),
        ]}
        title="Finances"
      />
      <Group
        icon={Location}
        search={search}
        sections={[
          ['Location', 'Time Zone', 'UTC Offset'].map(attribute),
          [
            'Street Number',
            'Street Name',
            'City',
            'State',
            'State Code',
            'Postal Code',
            'Country',
            'Country Code',
          ].map(attribute),
          [
            {
              label: 'Latitude',
              value: valueForAttribute('Latitude', 'lat'),
            },
            {
              label: 'Longitude',
              value: valueForAttribute('Longitude', 'lng'),
            },
          ],
        ]}
        title="Location"
      />
      <Group
        icon={Tech}
        search={search}
        sections={[
          ['Tech Categories'].map(attribute),
          [
            {
              label: 'Tech Tags',
              value: valueForAttribute('Tech')?.map((t) => startCase(t)),
            },
          ],
        ]}
        title="Tech"
      />
      <Group
        icon={Twitter}
        search={search}
        sections={[
          [
            {
              label: 'Twitter Handle',
              value: valueForAttribute('Twitter Handle'),
            },
            {
              label: 'Twitter Id',
              value: valueForAttribute('Twitter Id'),
            },
            {
              label: 'Twitter Followers',
              value: valueForAttribute('Twitter Followers'),
            },
            {
              label: 'Twitter Following',
              value: valueForAttribute('Twitter Following'),
            },
          ],
        ]}
        title="Twitter"
        type={'twitter'}
      />
      <Group
        icon={Facebook}
        search={search}
        sections={[['Facebook Handle', 'Facebook Likes'].map(attribute)]}
        title="Facebook"
        type={'facebook'}
      />
      <Group
        icon={Linkedin}
        search={search}
        sections={[['Linkedin Handle'].map(attribute)]}
        title="Linkedin"
        type={'linkedin'}
      />
    </>
  );
};

CompanyEnrichment.propTypes = {
  enrichment: PropTypes.object,
  search: PropTypes.string,
};

export default CompanyEnrichment;
