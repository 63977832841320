import { useQuery } from '@apollo/client';

import PublishableKeyQuery from './query.graphql';

const usePublishableKeys = () => {
  const { data, loading, error } = useQuery(PublishableKeyQuery, {
    nextFetchPolicy: 'cache-only',
  });

  const publishableKeys: Record<string, string> = {};

  data?.publishableKeys.forEach((key: any) => {
    publishableKeys[key.id] = key.value;
  });

  return {
    publishableKeys,
    loading,
    error,
  };
};

export { usePublishableKeys };
