import { CKButton } from 'clearkit';
import { Link } from 'react-router-dom';

import { routes } from '~/components/app/appMainRoutes';

import { CreditUsageTile } from '..';
import {
  CurrentPeriodTotalUsage,
  usageHelpDeskLink,
  UsageMetricGraph,
} from '../helpers';

type ApiCreditUsageTileProps = {
  usageMetricGraph: UsageMetricGraph;
  currentPeriodTotalUsage: CurrentPeriodTotalUsage;
  planId: string;
};

export function ApiCreditUsageTile({
  currentPeriodTotalUsage,
  planId,
}: ApiCreditUsageTileProps) {
  let totalUsageCount = 0;
  Object.keys(currentPeriodTotalUsage).forEach((key) => {
    if (key.toLocaleLowerCase().includes('api')) {
      totalUsageCount += currentPeriodTotalUsage[key].uniques;
    }
  });

  const detailsUrl = `${routes.usage}/${planId}_api`;

  return (
    <CreditUsageTile
      cta={
        <CKButton as={Link} to={detailsUrl}>
          Details
        </CKButton>
      }
      description={Description()}
      title={`${totalUsageCount} API calls`}
    />
  );
}

function Description() {
  return (
    <>
      APIs are only billed for successful calls with each product having a
      different credit cost. Click details to see the breakdown of API calls by
      product or{' '}
      <a href={usageHelpDeskLink} rel="noopener noreferrer" target="_blank">
        learn more
      </a>{' '}
      about usage
    </>
  );
}
