import classnames from 'classnames';
import { CKContainerProps, CKLayoutGrid, CKLayoutGridProps } from 'clearkit';
import { FC } from 'react';

type ListItemGridComposition = {
  Right: FC<CKContainerProps>;
};

export const ListItemGrid: FC<CKLayoutGridProps> & ListItemGridComposition = ({
  children,
  className,
  gap = '1.5rem',
  ...rest
}) => (
  <CKLayoutGrid
    {...rest}
    className={classnames(
      'flex flex-wrap min-w-0 basis-full shrink grow @item/list:grid',
      className,
    )}
    gap={gap}
  >
    {children}
  </CKLayoutGrid>
);

ListItemGrid.Right = ({ children, className, ...rest }) => (
  <div
    {...rest}
    className={classnames(
      'min-w-0 @item/list:pl-6 @item/list:border-l grow shrink',
      className,
    )}
  >
    {children}
  </div>
);
ListItemGrid.Right.displayName = 'ListItemGrid.Right';
