import { useMutation, useQuery } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CKButton } from 'clearkit';
import moment from 'moment';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

import GraphqlError from '~/components/GraphqlError';
import Layout from '~/components/layouts/DefaultLayout';
import LoadingArea from '~/components/LoadingArea';
import LoadingBar, { useLoadingBarState } from '~/components/LoadingBar';
import MoreMenu from '~/components/MoreMenu';
import Pill from '~/components/Pill';
import Tile from '~/images/Tiles/Import.svg';
import { uploadFileToS3 } from '~/lib/client/s3';
import history from '~/lib/history';
import paths from '~/lib/paths';
import {
  trackCSVUploaded,
  trackCSVUploadErrored,
} from '~/lib/segmentAnalytics';
import CreateSegmentDialog from '~/pages/CsvImport/components/CreateSegmentDialog';

import analyze from './analyze.graphql';
import CompaniesIcon from './Companies.svg';
import create from './create.graphql';
import CreatePlus from './CreatePlus.svg';
import CurvedArrowIcon from './CurvedArrow.svg';
import DeleteIcon from './Delete.svg';
import destroy from './destroy.graphql';
import ErrorIcon from './Error.svg';
import ImportFileIcon from './Import.svg';
import ImportIcon from './ImportIcon.svg';
import query from './index.graphql';
import PeopleIcon from './People.svg';
import SegmentIcon from './SegmentIcon.svg';
import styles from './styles';
import { validateCSV } from './validations';
import ViewCSVIcon from './ViewCSV.svg';
import WarningIcon from './Warning.svg';

const helpURL =
  'https://help.clearbit.com/hc/en-us/articles/1500005069962-How-To-Upload-CSV-Files-to-the-Clearbit-Platform';

const ERRORS = {
  missingDomain: (filename) => ({
    type: 'error',
    title: `${filename} is missing domain names`,
    subtitle: (
      <>
        Domain names are required to import companies.{' '}
        <a className="ck-link" href={helpURL}>
          Learn more
        </a>
      </>
    ),
  }),
  missingEmail: (filename) => ({
    type: 'error',
    title: `${filename} is missing emails`,
    subtitle: (
      <>
        Emails are required to import people.{' '}
        <a className="ck-link" href={helpURL}>
          Learn more
        </a>
      </>
    ),
  }),
  couldNotBeParsed: (filename) => ({
    type: 'error',
    title: `${filename} could not be parsed`,
    subtitle: (
      <>
        Unable to parse some data in this CSV file. Check the data before
        re-uploading.{' '}
        <a className="ck-link" href={helpURL}>
          Learn more
        </a>
      </>
    ),
  }),
  containsPersonData: (filename) => ({
    type: 'warning',
    title: `${filename} looks like it contains person data`,
    subtitle: (
      <>
        This CSV file appears to contain person instead of company data. Try
        uploading a different file or{' '}
        <Link to="/people/import">Import People</Link> instead.
      </>
    ),
  }),
  containsCompanyData: (filename) => ({
    type: 'warning',
    title: `${filename} looks like it contains company data`,
    subtitle: (
      <>
        This CSV file appears to contain company instead of person data. Try
        uploading a different file or{' '}
        <Link to="/companies/import">Import Companies</Link> instead.
      </>
    ),
  }),
};

const reportedError = (error, filename) => {
  const errorElement = ERRORS[error] || ERRORS.could_not_be_parsed;
  return errorElement(filename);
};

const ImportLoadingBar = ({ className }) => {
  const loadingBarProps = useLoadingBarState({ maxPercentage: 75 });

  return <LoadingBar className={className} {...loadingBarProps} />;
};

const CsvImport = ({ classes, type }) => {
  const singular = type === 'Company' ? 'company' : 'person';
  const path = type === 'Company' ? 'companies' : 'people';

  const [dragging, setDragging] = useState();
  const [processing, setProcessing] = useState();
  const [fileName, setFileName] = useState();
  const [error, setError] = useState();
  const [percentage, setPercentage] = useState(0);
  const [selectedCSV, setSelectedCSV] = useState(null);

  const [createCsvImport] = useMutation(create);
  const [analyzeCsvImport] = useMutation(analyze);
  const [destroyCsvImport] = useMutation(destroy, {
    refetchQueries: ['ImportCsvIndexPage'],
  });

  const redirectToSegment = (type, csvImport) => {
    return history.push(paths.segment(type, csvImport.segmentId));
  };

  const redirectToImport = (type, csvImport) => {
    return history.push(paths.mapCsv(type, csvImport.id));
  };

  const button = (csvImport, type) => {
    const matchedNoRowsError =
      csvImport.status === 'success' && csvImport.rowsMatchedCount === 0;
    let status = csvImport.status;

    if (matchedNoRowsError) {
      status = 'error';
    }

    switch (status) {
      case 'uploading':
        return (
          <div className={classes.importingOptions}>
            <div className={classes.importStatusContainer}>
              <span>Uploading...</span>
            </div>
            <div className={classes.moreMenu}>
              <MoreMenu
                options={[
                  {
                    component: (
                      <span className={classes.menuItem}>
                        <DeleteIcon className={classes.moreIcon} /> Remove
                        import
                      </span>
                    ),
                    onClick: () =>
                      destroyCsvImport({
                        variables: {
                          input: { id: csvImport.id },
                        },
                      }),
                  },
                ]}
              />
            </div>
          </div>
        );
      case 'analyzed':
        return (
          <div className={classes.moreMenu}>
            <MoreMenu
              options={[
                {
                  component: (
                    <span className={classes.menuItem}>
                      <ViewCSVIcon className={classes.moreIcon} /> Import CSV
                    </span>
                  ),
                  onClick: () => redirectToImport(type, csvImport),
                },
                {
                  component: (
                    <span className={classes.menuItem}>
                      <DeleteIcon className={classes.moreIcon} /> Remove import
                    </span>
                  ),
                  onClick: () =>
                    destroyCsvImport({
                      variables: {
                        input: { id: csvImport.id },
                      },
                    }),
                },
              ]}
            />
          </div>
        );
      case 'importing':
        return (
          <div className={classes.importingOptions}>
            <ImportLoadingBar className={classes.importingLoader} />
            <div className={classes.moreMenu}>
              <MoreMenu
                options={[
                  {
                    component: (
                      <span className={classes.menuItem}>
                        <ViewCSVIcon className={classes.moreIcon} /> View CSV
                        Import
                      </span>
                    ),
                    onClick: () => redirectToImport(type, csvImport),
                  },
                ]}
              />
            </div>
          </div>
        );
      case 'success':
        return (
          <>
            {csvImport.segmentId ? (
              <div className={classes.moreMenu}>
                <MoreMenu
                  options={[
                    {
                      component: (
                        <span className={classes.menuItem}>
                          <SegmentIcon className={classes.moreIcon} /> View
                          audience
                        </span>
                      ),
                      onClick: () => redirectToSegment(type, csvImport),
                    },
                  ]}
                />
              </div>
            ) : (
              <div className={classes.moreMenu}>
                <MoreMenu
                  options={[
                    {
                      component: (
                        <span className={classes.menuItem}>
                          <CreatePlus className={classes.moreIcon} /> Create
                          audience
                        </span>
                      ),
                      onClick: () => setSelectedCSV(csvImport),
                    },
                  ]}
                />
              </div>
            )}
          </>
        );
      case 'error':
        return (
          <div className={classes.errorOptions}>
            <div className={classes.importStatusContainer}>
              <ErrorIcon /> <div className={classes.error}>Import failed</div>
            </div>
            <div className={classes.moreMenu}>
              <MoreMenu
                options={[
                  {
                    component: (
                      <span className={classes.menuItem}>
                        <DeleteIcon className={classes.moreIcon} /> Remove
                        failed import
                      </span>
                    ),
                    onClick: () =>
                      destroyCsvImport({
                        variables: {
                          input: { id: csvImport.id },
                        },
                      }),
                  },
                ]}
              />
            </div>
          </div>
        );
      default:
        break;
    }
  };

  const {
    data: { csvImports, collections } = {},
    error: _error,
    refetch: _refetch,
    loading: _loading,
  } = useQuery(query, {
    variables: {
      type: singular,
      collectionType: type,
    },
    fetchPolicy: 'network-only',
  });

  const setFile = async (file) => {
    setProcessing(true);
    setFileName(file.name);

    const validation = await validateCSV(file, type);

    if (validation) {
      setError(reportedError(validation, file.name));
      trackCSVUploadErrored(type, file.name, validation);
      setProcessing(false);
      _refetch();
      return;
    }

    const {
      data: {
        response: { node },
      },
    } = await createCsvImport({
      variables: {
        input: { filename: file.name, type: singular },
      },
    });

    await uploadFileToS3(node.uploadUrl, file, setPercentage);

    trackCSVUploaded(node);

    await analyzeCsvImport({
      variables: {
        input: { id: node.id },
      },
    });

    setProcessing(false);
    history.push(`/${path}/import/${node.id}`);
  };

  const input = React.createRef();

  return (
    <Layout
      tile={<Tile />}
      title={`Import ${type === 'Company' ? 'companies' : 'people'} from CSV`}
    >
      <Helmet>
        <title>Upload CSV files</title>
      </Helmet>
      <div className={classes.root}>
        <div className={classes.title}>Upload CSV file</div>

        {!processing ? (
          <>
            <div
              className={classnames(classes.dropTarget, {
                [classes.dropTargetDragging]: dragging || processing,
              })}
              onDragLeave={() => setDragging(false)}
              onDragOver={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setDragging(true);
              }}
              onDrop={(e) => {
                setDragging(false);
                e.preventDefault();
                setFile(e.dataTransfer.files[0]);
              }}
              rel="dropzone"
            >
              {error ? (
                <>
                  <div className={classes.errorIcon}>
                    {error.type === 'warning' && <WarningIcon />}
                    {error.type === 'error' && <ErrorIcon />}
                  </div>
                  <div className={classes.text}>
                    <p className={classes.heading}>{error.title}</p>
                    <p className={classes.body}>{error.subtitle}</p>
                    <hr className={classes.errorBreak} />
                  </div>
                </>
              ) : (
                <>
                  <ImportIcon />
                  <div className={classes.text}>
                    <p className={classes.heading}>Drop your CSV file here</p>
                    <p className={classes.body}>
                      Your CSV file <strong>must contain headers</strong> and
                      company data <strong>must include a domain name</strong>
                    </p>
                  </div>
                </>
              )}

              <div className={classes.actions}>
                <CKButton isLabel variant="bold" variantColor="blue">
                  Choose CSV file...
                  <input
                    accept=".csv"
                    className="hidden"
                    multiple
                    onChange={(e) => setFile(e.target.files[0])}
                    ref={input}
                    type="file"
                  />
                </CKButton>
              </div>
            </div>
            <p className="mt-0 mb-8 text-sm">
              Need sample CSV files or more information on how to upload?{' '}
              <a className="ck-link" href={helpURL}>
                Learn more
              </a>
            </p>
          </>
        ) : (
          <div className={classes.dropTarget}>
            <div className={classes.loader}>
              <CircularProgress
                className={classes.spinner}
                style={{ width: 20, height: 20 }}
              />
            </div>

            <div className={classes.text}>
              {percentage < 100 ? (
                <>
                  <p className={classes.body}>Uploading {fileName}...</p>
                  <LoadingBar
                    className={classes.progressBar}
                    percentage={percentage}
                  />
                </>
              ) : (
                <>
                  <p className={classes.heading}>Processing {fileName}</p>
                  <p className={classes.body}>
                    Checking for headers and {singular} data...
                  </p>
                </>
              )}
            </div>
          </div>
        )}
        {csvImports?.length > 0 ? (
          <>
            <div className={classes.title}>Past uploads</div>
            <GraphqlError
              error={_error}
              loading={_loading}
              refetch={_refetch}
            />
            {csvImports?.map((csvImport) => (
              <div className={classes.csvImport} key={csvImport.id}>
                <ImportFileIcon height={16} width={14} />
                <span className={classes.sourceName}>
                  {csvImport.sourceName || csvImport.filename}
                </span>
                {csvImport.status === 'success' && (
                  <>
                    <Pill
                      className={classes.rowCountPill}
                      color="green"
                      tooltipMessage={`${csvImport.rowsMatchedCount.toLocaleString(
                        'en-US',
                      )} ${
                        csvImport.rowsMatchedCount === 1
                          ? 'row was'
                          : 'rows were'
                      } matched during import.`}
                    >
                      {type === 'Company' ? <CompaniesIcon /> : <PeopleIcon />}
                      {csvImport.rowsMatchedCount.toLocaleString('en-US')}
                    </Pill>
                    <Pill
                      className={classes.rowCountPill}
                      color="orange"
                      tooltipMessage={`${csvImport.rowsSkippedCount.toLocaleString(
                        'en-US',
                      )} ${
                        csvImport.rowsSkippedCount === 1
                          ? 'row was'
                          : 'rows were'
                      } skipped during import.`}
                    >
                      <CurvedArrowIcon />
                      {csvImport.rowsSkippedCount.toLocaleString('en-US')}
                    </Pill>
                  </>
                )}
                <span className={classes.userText}>
                  {(() => {
                    if (
                      csvImport.status === 'success' &&
                      csvImport.rowsMatchedCount === 0
                    ) {
                      return `Import on ${moment(csvImport.createdAt).format(
                        'MMMM Do, YYYY',
                      )} did not match any rows`;
                    }

                    if (csvImport.status === 'importing') {
                      return `Import started by ${
                        csvImport.user?.email
                      }, ${moment(csvImport.createdAt).fromNow()}`;
                    }

                    return `Imported by ${csvImport.user?.email}, ${moment(
                      csvImport.createdAt,
                    ).format('MMMM Do, YYYY')}`;
                  })()}
                </span>
                <span>{button(csvImport, type)}</span>
              </div>
            ))}
          </>
        ) : (
          <LoadingArea />
        )}
      </div>
      <CreateSegmentDialog
        closeDialog={() => setSelectedCSV(null)}
        collections={collections}
        csvImport={selectedCSV}
        type={type}
      />
    </Layout>
  );
};

CsvImport.propTypes = {
  classes: PropTypes.object,
  type: PropTypes.string,
};

export default withStyles(styles)(CsvImport);
