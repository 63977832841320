import {
  AccountFlagEnum,
  AccountPlanEnum,
  useAccountBillingInfoQuery,
} from '~/generated/graphql';

import { useCreditUsage } from './useCreditUsage';
import useCurrentAccount from './useCurrentAccount';

/**
 * Legacy plans that include enrichment and advertising, etc
 * Currently not taking new customers
 */
export const legacyPlans = [AccountPlanEnum.FreeTier, AccountPlanEnum.PaidTier];

/**
 * Sales intelligence plans
 */
export const salesIntelligencePlans = [
  AccountPlanEnum.HubspotFreeTier,
  AccountPlanEnum.HubspotPaidTier,
];

/**
 * Hubspot platform plans
 */
export const hubspotPlatformPlans = [
  AccountPlanEnum.HubspotPlatformFree,
  AccountPlanEnum.HubspotPlatformPaid,
];

export const hubspotPlatformPaidPlans = [AccountPlanEnum.HubspotPlatformPaid];

export const selfServePlans = [...salesIntelligencePlans, ...legacyPlans];

export const contractPlans = [AccountPlanEnum.BusinessTier];

export const useEntitlements = () => {
  const { account, currentUser, loading: loadingAccount } = useCurrentAccount();
  const { data: billingInfoData } = useAccountBillingInfoQuery();
  const { hasUsage, loading: loadingUsage } = useCreditUsage();

  const hasFlag = (flag: AccountFlagEnum) => !!account?.flags?.includes(flag);
  const hasPlans = (plans: AccountPlanEnum[]) =>
    !!account?.plans?.some((plan) => plans.includes(plan)) && !loadingAccount;

  const isLoading = loadingAccount || loadingUsage;
  const isOwner = currentUser?.role === 'owner';

  const isContractAccount = hasPlans(contractPlans);
  const isHubspotPlatform = hasPlans(hubspotPlatformPlans);
  const isHubspotPlatformPaid = hasPlans(hubspotPlatformPaidPlans);
  const isSalesIntelligence = hasPlans(salesIntelligencePlans);
  const isHubspotAccount = isSalesIntelligence || isHubspotPlatform;
  const isLegacyAccount = !isHubspotAccount && !loadingAccount;
  const isSelfServeAccount = hasPlans(selfServePlans);

  /**
   * Add new entitlements in alphabetical order to keep the list in order
   * and make it easier to find the entitlements.
   *
   * Use entitlements in AppRoutes.tsx and NavBar.tsx to show/hide routes and
   * nav items respectively. Can also use entitlements in components to show/hide
   * content.
   *
   * @example canAccessAdvertising
   */
  const hasAccessAdvertising =
    isLegacyAccount && hasFlag(AccountFlagEnum.AllowPlatformAdvertisingMenu);
  const hasAccessBatch = !isHubspotPlatform;
  const hasAccessBilling =
    isOwner &&
    !!billingInfoData?.accountBillingInfo &&
    !isHubspotAccount &&
    !loadingAccount;

  const hasAccessCompanies = isLegacyAccount;
  const hasAccessAddHubSpot = isHubspotAccount;
  const hasAccessEnrichment = isLegacyAccount;
  const hasAccessEnrichmentHubspot = isHubspotPlatform;
  const hasAccessEnrichmentHubspotEditing = isHubspotPlatformPaid;
  const hasAccessForms = isLegacyAccount;
  const hasAccessFormsHubspot = isHubspotAccount;
  const hasAccessFormsHubspotEditing =
    isHubspotPlatformPaid || isSalesIntelligence || isLegacyAccount;
  const hasAccessIntegrations = isLegacyAccount;
  const hasAccessLegacyRevealPlan =
    isLegacyAccount && hasPlans([AccountPlanEnum.Ipcompany]);
  const hasAccessLookup =
    isHubspotPlatform || isLegacyAccount || isSalesIntelligence;

  const hasAccessPeople = isLegacyAccount;

  const hasAccessPlans = hasPlans(selfServePlans);

  const hasAccessProspector =
    (isSelfServeAccount || isContractAccount) && !isHubspotPlatform;
  const hasAccessProspectorCRMExports = isHubspotAccount || isContractAccount;
  const hasAccessProspectorExports = hasUsage;

  const hasAccessSettingsApi = isLegacyAccount;
  const hasAccessSettingsApiUsage =
    hasAccessSettingsApi && hasFlag(AccountFlagEnum.AllowApiUsageTab);
  const hasAccessSettingsIntegrations = isHubspotAccount;
  const hasAccessSettingsSecurity = isLegacyAccount;
  const hasAccessSettingsTeamCreation = isLegacyAccount;
  const hasAccessSettingsTeams = isLegacyAccount;
  const hasAccessSettingsTeamUsers = isLegacyAccount;

  const hasAccessUsage = hasUsage;
  const hasAccessUsagePreview =
    hasUsage && !isHubspotPlatform && !loadingAccount;

  return {
    hasAccessAdvertising,
    hasAccessAddHubSpot,
    hasAccessBatch,
    hasAccessBilling,
    hasAccessCompanies,
    hasAccessEnrichment,
    hasAccessEnrichmentHubspot,
    hasAccessEnrichmentHubspotEditing,
    hasAccessForms,
    hasAccessFormsHubspot,
    hasAccessFormsHubspotEditing,
    hasAccessIntegrations,
    hasAccessLegacyRevealPlan,
    hasAccessLookup,
    hasAccessPeople,
    hasAccessPlans,
    hasAccessProspector,
    hasAccessProspectorCRMExports,
    hasAccessProspectorExports,
    hasAccessSettingsApi,
    hasAccessSettingsApiUsage,
    hasAccessSettingsIntegrations,
    hasAccessSettingsSecurity,
    hasAccessSettingsTeamCreation,
    hasAccessSettingsTeams,
    hasAccessSettingsTeamUsers,
    hasAccessUsage,
    hasAccessUsagePreview,
    isOwner,
    loading: isLoading,
  };
};
