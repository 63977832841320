import { CKDrawer } from 'clearkit';
import startCase from 'lodash/startCase';

import Check from '~/images/destinations/Check';
import {
  destinationSubtitleForType,
  destinationTypes,
} from '~/lib/destinations';
import Logo from '~/pages/destinations/components/Logo';

import Arrow from './arrow.svg';
import styles from './styles';
import tile from './tile.png';

const SegmentDestinationFormHeader = ({
  classes,
  customTitle,
  destinationType,
  destinationName,
  destinationFlowDescription,
  noBorder,
  subtitle,
  segmentName,
  segmentCollection,
  children,
}) => {
  subtitle = subtitle || destinationSubtitleForType(destinationType);
  destinationName = destinationName || startCase(destinationType);
  destinationFlowDescription = destinationFlowDescription || (
    <>Configuring Destination</>
  );

  const headerClasses = classnames({
    'border-0': noBorder,
  });

  return (
    <CKDrawer.Header className={headerClasses}>
      <h1 className={classes.heading}>
        {customTitle || `Set up ${destinationName} Destination`}
      </h1>

      <span className={classes.subtitle}>{subtitle}</span>

      <div className={classes.flow}>
        <div className={classes.flowBox}>
          <img alt="" className={classes.tile} src={tile} />
          <div className={classes.flowDetail}>
            <span>{segmentName}</span>
            <span className={classes.detailSub}>{segmentCollection}</span>
          </div>
        </div>

        <Arrow className={classes.flowArrow} />

        <div className={classes.flowBox}>
          <div className={classes.logo}>
            <Logo destinationType={destinationType} height="40" width="40" />
          </div>

          <div className={classes.flowDetail}>
            <span>{destinationName}</span>
            <span className={classes.detailSub}>
              <Check className={classes.check} />
              <div>{destinationFlowDescription}</div>
            </span>
          </div>
        </div>
      </div>

      {children}
    </CKDrawer.Header>
  );
};

SegmentDestinationFormHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  customTitle: PropTypes.string,
  destinationName: PropTypes.node,
  subtitle: PropTypes.node,
  segmentName: PropTypes.node.isRequired,
  segmentCollection: PropTypes.node.isRequired,
  destinationFlowDescription: PropTypes.node,
  destinationType: PropTypes.oneOf(destinationTypes).isRequired,
};

export default withStyles(
  (theme) => ({
    ...styles(theme),
  }),
  { name: 'SegmentDestinationNewPage' },
)(SegmentDestinationFormHeader);
