import { Go } from '@clearkit/icons';
import {
  CKButton,
  CKLayoutGridAuto,
  CKLayoutHorizontal,
  CKSkeleton,
} from 'clearkit';
import { Link } from 'react-router-dom';

import { routes } from '~/components/app/appMainRoutes';
import { CRMPresenceProvider } from '~/components/CRMPresence/CRMPresenceProvider';
import { useTargetMarketsRoute } from '~/components/TargetMarkets/hooks/useTargetMarketsRoute';

import { AddressableMarketHeader } from '../HomeCompanies/AddressableMarketHeader';
import { CompanyCard } from '../HomeCompanies/CompanyCard';
import { COMPANY_LIMIT, useHomeCompanies } from '../hooks/useHomeCompanies';
import { NoCompaniesEmptyState } from './NoCompaniesEmptyState';

const LOADING_CARDS = Array.from({ length: COMPANY_LIMIT }, (_, i) => i);

export const HomeSectionCompanies = () => {
  const { companyCount, isLoading, mainICPCompanies } = useHomeCompanies();
  const { targetMarketsRoute } = useTargetMarketsRoute();

  return (
    <section className="space-y-6">
      <CKLayoutHorizontal>
        <AddressableMarketHeader companies={companyCount} />
        <CKButton
          as={Link}
          rightIcon={<Go />}
          to={targetMarketsRoute}
          variant="tertiary"
          variantColor="blue"
        >
          Discover more of your market
        </CKButton>
      </CKLayoutHorizontal>
      {isLoading || mainICPCompanies.length ? (
        <CRMPresenceProvider
          domains={mainICPCompanies.map((company) => company.domain)}
        >
          <CKLayoutGridAuto gap="1.5rem" minWidth="16rem">
            {isLoading
              ? LOADING_CARDS.map((index) => (
                  <CKSkeleton className="w-full h-[15.4375rem]" key={index} />
                ))
              : mainICPCompanies.map((company) => (
                  <CompanyCard company={company} key={company.domain} />
                ))}
          </CKLayoutGridAuto>
        </CRMPresenceProvider>
      ) : (
        <NoCompaniesEmptyState />
      )}
      <CKLayoutHorizontal className="py-4" justifyContent="center">
        <CKButton
          as={Link}
          to={routes.targetMarkets}
          variant="simple"
          variantColor="blue"
        >
          Find more companies
        </CKButton>
      </CKLayoutHorizontal>
    </section>
  );
};
