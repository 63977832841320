import { CKSearch, CKTab, CKTabList, CKTabs } from 'clearkit';
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import startCase from 'lodash/startCase';

import wrapError from '~/components/JavascriptError/JavascriptErrorHandler';
import { paginateField } from '~/lib/graphql/pagination';

import CompanyEnrichment from './CompanyEnrichment';
import CompanyEnrichmentFromObject from './CompanyEnrichmentFromObject';
import Group from './Group';
import HubspotGroups from './HubspotGroups';
import PersonEnrichment from './PersonEnrichment';
import SalesforceGroups from './SalesforceGroups';
import styles from './styles';

const AttributeBrowser = ({
  classes,
  traits = {},
  enrichment = [],
  hubspotCompanies,
  salesforceAccounts = [],
  salesforceContacts = [],
  salesforceLeads = [],
  onTabChange,
  salesforceAccountConnector,
  object,
}) => {
  const [search, setSearch] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const showTraits = Object.keys(traits)?.length > 0;

  const showHubspot = hubspotCompanies?.length > 0;
  const stickyRef = React.useRef();
  const [stickyBounds, setStickyBounds] = React.useState(0);

  React.useLayoutEffect(() => {
    const stickyBounds = stickyRef.current.getBoundingClientRect();
    setStickyBounds(stickyBounds);
  }, [setStickyBounds]);

  const handleTabChange = (index) => {
    onTabChange(stickyBounds);
    setTabIndex(index);
  };

  const salesforceObjects = parseInt(
    salesforceAccounts?.length +
      compact(
        flatten(
          salesforceAccounts?.map((account) =>
            paginateField(account, 'salesforceOpportunities'),
          ),
        ),
      )?.length +
      salesforceContacts.length +
      salesforceLeads.length,
  );

  const hubspotObjects = parseInt(
    hubspotCompanies?.length +
      compact(
        flatten(
          hubspotCompanies?.map((company) => paginateField(company, 'deals')),
        ),
      ).length,
  );

  const showSalesforce = salesforceObjects > 0;

  const showEmployer =
    object.__typename === 'Person' && object.companyEnrichment;

  return (
    <div className={classnames(classes.root, 'flex-grow')}>
      <CKTabs index={tabIndex} isSmall onChange={handleTabChange}>
        <div className={classes.attributeHeader} ref={stickyRef}>
          <CKSearch
            className={classes.search}
            onChange={setSearch}
            placeholder="Search for attributes"
            value={search}
          />

          {!search && (
            <CKTabList aria-label="Attribute tabs" className={classes.tabs}>
              {compact([
                <CKTab key="information">Information</CKTab>,
                <CKTab key="traits" style={{ display: !showTraits && 'none' }}>
                  Custom Traits
                </CKTab>,
                <CKTab
                  key="company"
                  style={{ display: showEmployer || 'none' }}
                >
                  Employer
                </CKTab>,
                <CKTab
                  badge={salesforceObjects}
                  key="salesforce"
                  style={{ display: !showSalesforce && 'none' }}
                >
                  Salesforce
                </CKTab>,
                <CKTab
                  badge={hubspotObjects}
                  key="hubspot"
                  style={{ display: !showHubspot && 'none' }}
                >
                  Hubspot
                </CKTab>,
              ])}
            </CKTabList>
          )}
        </div>

        <div className="-mx-4">
          {(search || tabIndex === 0) && (
            <>
              {object.__typename === 'Company' && (
                <CompanyEnrichment enrichment={enrichment} search={search} />
              )}
              {object.__typename === 'EnrichmentData' && (
                <CompanyEnrichmentFromObject
                  enrichment={enrichment}
                  search={search}
                />
              )}
              {object.__typename === 'Person' && (
                <PersonEnrichment
                  enrichment={enrichment}
                  person={object}
                  search={search}
                />
              )}
            </>
          )}

          {(search || tabIndex === 1) && (
            <Group
              search={search}
              sections={[
                Object.keys(traits).map((trait) => ({
                  label: startCase(trait),
                  value: traits[trait],
                })),
              ]}
              title="Custom Traits"
              type="traits"
            />
          )}

          {showEmployer && (search || tabIndex === 2) && (
            <CompanyEnrichment
              enrichment={object.companyEnrichment}
              search={search}
            />
          )}

          {(search || tabIndex === 3) && salesforceAccountConnector && (
            <SalesforceGroups
              salesforceAccountConnector={salesforceAccountConnector}
              salesforceAccounts={salesforceAccounts}
              salesforceContacts={salesforceContacts}
              salesforceLeads={salesforceLeads}
              search={search}
            />
          )}

          {(search || tabIndex === 4) && (
            <HubspotGroups
              hubspotCompanies={hubspotCompanies}
              search={search}
            />
          )}
        </div>
      </CKTabs>
    </div>
  );
};

AttributeBrowser.propTypes = {
  classes: PropTypes.object,
  enrichment: PropTypes.array,
  traits: PropTypes.object,
  hubspotCompanies: PropTypes.array,
  salesforceAccounts: PropTypes.array,
  onTabChange: PropTypes.func,
  object: PropTypes.shape({
    __typename: PropTypes.string,
  }),
  salesforceContacts: PropTypes.arrayOf(PropTypes.object),
  salesforceLeads: PropTypes.arrayOf(PropTypes.object),
  salesforceAccountConnector: PropTypes.object,
};

export default wrapError(withStyles(styles)(AttributeBrowser));
