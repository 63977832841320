const queryItem = {
  flexGrow: 1,
  marginTop: 0,
  marginBottom: 0,
};

export default () => ({
  disabled: {
    overflow: 'auto',
    padding: 2,
    userSelect: 'none',

    '& > *': {
      opacity: 0.8,
      pointerEvents: 'none',
    },
  },

  searchBuilder: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  queryGroup: {
    marginBottom: 10,
    position: 'relative',
    '& $queryGroup': {
      marginTop: 10,
    },
  },

  actionButtons: {
    paddingTop: 10,
  },

  attribute: {
    ...queryItem,
    marginRight: 12,
    width: '35%',
    flexGrow: 0,
  },

  comparator: {
    ...queryItem,
    width: '25%',
    minWidth: 200,
  },

  value: {
    ...queryItem,
    width: '30%',
    marginLeft: 12,
  },

  queryGroupLine: {
    width: '100%',
    marginBottom: 8,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    '&::after': {
      transitionDelay: '0.5s',
      pointerEvents: 'none',
      transition: 'ease all 0.5s',
      position: 'absolute',
      content: "''",
      top: -1,
      left: -1,
      width: 'calc(100% - 38px)',
      height: 'calc(100% + 2px)',
      background: 'rgba(255, 0, 0, 0.1)',
      border: '1px solid rgba(255, 0, 0, 0.5)',
      borderRadius: 8,
      opacity: 0,
    },
  },

  invalidLine: {
    '&::after': {
      opacity: 1,
      transitionDuration: '0.3s',
      transitionDelay: '0s',
    },
  },
});
