import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import Popover from '@material-ui/core/Popover';
import moment from 'moment';

import ClearIcon from '~/components/icons/ClearIcon';

import Calendar from './Calendar';
import styles from './index.style.js';

class DatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();

    const { defaultValue, defaultStartDate } = props;

    this.state = {
      value: defaultValue && moment(defaultValue),
      startDate: defaultStartDate,
      calendarOpen: false,
    };
  }

  openCalendar = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.inputRef.blur();
    this.setState({ calendarOpen: true });
  };

  closeCalendar = () => this.setState({ calendarOpen: false });

  onDateChange = (value) => {
    this.setState(
      {
        value,
        calendarOpen: false,
      },
      () => this.props.onChange(this.state.value.format('YYYY-MM-DD')),
    );
  };

  clearValue = (e) => {
    this.setState({ value: '' }, () => this.props.onChange(this.state.value));
    e.stopPropagation();
    e.preventDefault();
  };

  render = () => {
    const { value, calendarOpen, startDate } = this.state;

    const {
      label,
      classes,
      variant,
      minDate,
      maxDate,
      clearable,
      placeholder,
    } = this.props;

    const zone = ` (${Intl.DateTimeFormat()
      .resolvedOptions()
      .timeZone.replace('_', ' ')})`;

    const input = (
      <Input
        className={classes.inputRoot}
        endAdornment={
          clearable &&
          value && (
            <ClearIcon
              className={classes.clearIcon}
              height={13}
              onClick={this.clearValue}
              width={13}
            />
          )
        }
        inputRef={(ref) => (this.inputRef = ref)}
        onFocus={this.openCalendar}
        placeholder={placeholder}
        value={value ? value.format('MMM D, YYYY') + zone : ''}
        variant={variant}
      />
    );

    return (
      <>
        {label ? (
          <FormControl>
            <FormLabel>{label}</FormLabel>
            {input}
          </FormControl>
        ) : (
          input
        )}
        <Popover
          anchorEl={this.inputRef}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          onClose={this.closeCalendar}
          open={calendarOpen}
        >
          <Calendar
            classes={classes}
            dateChange={this.onDateChange}
            maxDate={maxDate}
            minDate={minDate}
            selectedDate={value || moment()}
            startDate={startDate}
          />
        </Popover>
      </>
    );
  };
}

DatePicker.defaultProps = {
  startDate: moment().format('YYYY-MM-DD'),
  maxDate: null,
  variant: 'standard',
  clearable: false,
  placeholder: 'Choose a date',
};

DatePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  defaultStartDate: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  variant: PropTypes.string,
  clearable: PropTypes.bool,
  placeholder: PropTypes.string,
  defaultValue: function(props, propName) {
    if (!props[propName]) return;
    if (!/\d{4}-\d{2}-\d{2}/.test(props[propName])) {
      return new Error(
        'Invalid prop `' +
          propName +
          '` supplied to' +
          ' DatePicker. Must be a date string in format YYYY-MM-DD.' +
          ` Received ${props[propName]}`,
      );
    }
  },
};

export default withStyles(styles)(DatePicker);
