import queryString from 'query-string';
import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import MissingPage from '~/components/MissingPage';
import SegmentsMenu from '~/components/SegmentsMenu';
import SidebarDrawer from '~/components/SidebarDrawer';
import { useEntitlements } from '~/hooks/account';
import { Configuration } from '~/lib/configuration';
import history from '~/lib/history';
import paths, { SegmentRootPathKey } from '~/lib/paths';
import { segmentTypes } from '~/lib/segments';
import ConfigureROIMetrics from '~/pages/audiences/ConfigureROIMetrics';
import ExportSalesPipeline from '~/pages/audiences/ExportSalesPipeline';
import { ConfigType } from '~/pages/configurations/Show/ConfigurationPanelFactory';
import ImportCsv from '~/pages/CsvImport';
import MapCsv from '~/pages/CsvImport/Map';
import SegmentDestinationSkeleton from '~/pages/destinations/destinationConfigs/SkeletonForm';
import SegmentDestinationEdit from '~/pages/destinations/Edit';
import SegmentDestinations from '~/pages/destinations/List';
import SegmentDestinationNew from '~/pages/destinations/New';
import EnrichmentLogs from '~/pages/enrichment/Logs';
import EnrichmentShow, {
  IntegrationEnum,
  ObjectTypeName,
} from '~/pages/enrichment/Show';
import { Integration } from '~/pages/enrichment/Show/FieldMappingForm/types';
import Forms from '~/pages/forms';
import { HomePage } from '~/pages/home/HomePage';
import NoAccess from '~/pages/NoAccess';
import { UsageDetails, UsageDetailsProps } from '~/pages/Usage/Details';
import { UsageOverview } from '~/pages/Usage/UsageOverview';

import TargetMarketsPage from '../../pages/targetMarkets';
import { PageCredits } from '../billing/PageCredits';
import { enrichmentRoutes } from '../HubspotEnrichment/constants';
import LoadingArea from '../LoadingArea';
import { ProspectorPage } from '../prospector/Page';
import { ProspectorShowPage } from '../prospector/Show/Page';
import { settingsRoutes } from '../settings/settingsRoutes';
import {
  AnyStringRegex,
  concatEnrichmentTypes,
  routes,
  UUIDRegex,
} from './appMainRoutes';
import { GatedRouteEntitlement } from './GatedRouteEntitlement';
import { RoutesSidebar } from './RoutesSidebar';

const Audience = lazy(() => import('~/pages/audiences/Show'));
const AudienceEdit = lazy(() => import('~/pages/audiences/Edit'));
const AudienceClone = lazy(() => import('~/pages/audiences/Clone'));
const AudienceNew = lazy(() => import('~/pages/audiences/New'));
const Audiences = lazy(() => import('~/pages/audiences/List'));
const Batches = lazy(() => import('~/pages/Batches'));
const BatchesShow = lazy(() => import('~/pages/Batches/Show'));

const Companies = lazy(() => import('~/pages/companies/List'));
const CompaniesSegment = lazy(() => import('~/pages/companies/Segment'));
const Configurations = lazy(() => import('~/pages/configurations/List'));
const ConfigurationPanelFactory = lazy(() =>
  import('~/pages/configurations/Show/ConfigurationPanelFactory'),
);
const UpgradeAccountForm = lazy(() =>
  import('~/components/UpgradeAccountForm'),
);
const Disconnected = lazy(() =>
  import('~/pages/configurations/Show/Disconnected'),
);

const GetStarted = lazy(() => import('~/pages/GetStarted'));
const HubspotEnrichment = lazy(() => import('~/pages/HubspotEnrichment'));
const Integrations = lazy(() => import('~/pages/integrations/list'));
const LookupPage = lazy(() => import('~/pages/lookup'));
const People = lazy(() => import('~/pages/people/List'));
const PeopleSegment = lazy(() => import('~/pages/people/Segment'));
const Segment = lazy(() => import('~/pages/configurations/Show/Segment'));
const SegmentEdit = lazy(() => import('~/pages/SegmentEdit'));
const SegmentNew = lazy(() => import('~/pages/SegmentNew'));
const SettingsPage = lazy(() => import('~/pages/settings'));
const TagsJs = lazy(() => import('~/pages/configurations/Show/TagsJs'));
const Visitors = lazy(() => import('~/pages/Visitors'));

export const AppRoutes = () => {
  const {
    hasAccessAdvertising,
    hasAccessBatch,
    hasAccessCompanies,
    hasAccessPeople,
    hasAccessEnrichment,
    hasAccessEnrichmentHubspot,
    hasAccessIntegrations,
    hasAccessLookup,
    hasAccessProspector,
    hasAccessSettingsIntegrations,
    hasAccessPlans,
    hasAccessForms,
    hasAccessFormsHubspot,
    hasAccessUsage,
  } = useEntitlements();

  return (
    <>
      <GatedRouteEntitlement
        hasAccess={hasAccessPeople}
        path={[`/people/segments/:id(${UUIDRegex})`, '/people']}
        render={({
          match: {
            params: { id },
          },
        }: any) => <SegmentsMenu key="person" segmentId={id} type="Person" />}
      />

      <GatedRouteEntitlement
        hasAccess={hasAccessCompanies}
        path={[
          '/companies/lookup/:domain',
          `/companies/segments/:id(${UUIDRegex})`,
          '/companies',
        ]}
        render={({
          match: {
            params: { id },
          },
        }: any) => <SegmentsMenu key="company" segmentId={id} type="Company" />}
      />

      <Suspense fallback={<LoadingArea className="w-full" loading />}>
        <Switch>
          <Route component={NoAccess} exact path="/no-access" />

          <Route component={HomePage} exact path="/" />

          <Route component={GetStarted} path={`${routes.getStarted}/:id`} />
          <Route component={GetStarted} path={routes.getStarted} />

          <Route component={TargetMarketsPage} path={routes.targetMarkets} />

          <Route component={Visitors} path={routes.visitors} />

          <GatedRouteEntitlement
            component={ProspectorPage}
            exact
            hasAccess={hasAccessProspector}
            path={routes.prospector}
          />
          <GatedRouteEntitlement
            component={ProspectorShowPage}
            exact
            hasAccess={hasAccessProspector}
            path={`${routes.prospector}/list/:id`}
          />

          <Redirect exact from="/batch" to={routes.batch} />
          <GatedRouteEntitlement
            component={Batches}
            exact
            hasAccess={hasAccessBatch}
            path={routes.batch}
          />
          <GatedRouteEntitlement
            component={BatchesShow}
            exact
            hasAccess={hasAccessBatch}
            path={`${routes.batch}/:id`}
          />

          <GatedRouteEntitlement
            component={LookupPage}
            hasAccess={hasAccessLookup}
            path={`${routes.lookup}/:id?`}
          />

          <GatedRouteEntitlement
            component={UsageOverview}
            exact
            hasAccess={hasAccessUsage}
            path={routes.usage}
          />

          <GatedRouteEntitlement
            hasAccess={hasAccessUsage}
            path={`${routes.usage}/:id`}
            render={({
              match: {
                params: { id },
              },
            }) => <UsageDetails id={id as UsageDetailsProps['id']} />}
          />

          <GatedRouteEntitlement
            component={PageCredits}
            exact
            hasAccess={hasAccessPlans}
            path={routes.plans}
          />

          <Redirect
            exact
            from="/team/users"
            to={settingsRoutes.teamUsersRoute}
          />
          <Redirect exact from="/api" to={settingsRoutes.apiKeysRoute} />
          <Redirect exact from="/api/logs" to={settingsRoutes.apiLogsRoute} />
          <Redirect exact from="/settings/usage" to={routes.usage} />
          <Redirect exact from="/settings/team/usage" to={routes.usage} />
          <Redirect exact from="/settings/team/plans" to={routes.plans} />
          <Redirect exact from="/plans/upgrade" to={routes.plans} />
          <Redirect
            exact
            from="/settings/team/plans/upgrade"
            to={routes.plans}
          />

          <GatedRouteEntitlement
            component={Configurations}
            exact
            hasAccess={hasAccessIntegrations}
            path="/settings/configuration"
          />

          {!hasAccessSettingsIntegrations ? (
            <Route exact path={routes.tagsJs}>
              <TagsJs />
            </Route>
          ) : null}

          <GatedRouteEntitlement
            exact
            hasAccess={hasAccessIntegrations}
            path="/settings/configuration/segment"
          >
            <Segment />
          </GatedRouteEntitlement>

          {!hasAccessSettingsIntegrations ? (
            <Route
              exact
              path="/settings/configuration/:service/connect"
              render={({
                match: {
                  params: { service },
                },
              }) => <Disconnected service={service} />}
            />
          ) : null}

          {!hasAccessSettingsIntegrations ? (
            <Route
              path="/settings/configuration/:service"
              render={({
                match: {
                  params: { service },
                },
              }) => (
                <ConfigurationPanelFactory config={service as ConfigType} />
              )}
            />
          ) : null}

          <Route component={SettingsPage} path="/settings/:page?" />

          {/**
           * Here lies the graveyard of legacy routes.
           */}
          <GatedRouteEntitlement
            component={Integrations}
            exact
            hasAccess={hasAccessIntegrations}
            path={routes.integrations}
          />

          <GatedRouteEntitlement
            exact
            hasAccess={hasAccessCompanies}
            path="/import/companies"
            render={() => <ImportCsv key="Company" type="Company" />}
          />

          <GatedRouteEntitlement
            exact
            hasAccess={hasAccessPeople}
            path="/import/people"
            render={() => <ImportCsv key="Person" type="Person" />}
          />

          <GatedRouteEntitlement
            exact
            hasAccess={hasAccessCompanies}
            path={`/companies/import/:id(${UUIDRegex})`}
            render={({
              match: {
                params: { id },
              },
            }) => <MapCsv id={id} key="Company" type="Company" />}
          />

          <GatedRouteEntitlement
            exact
            hasAccess={hasAccessPeople}
            path={`/people/import/:id(${UUIDRegex})`}
            render={({
              match: {
                params: { id },
              },
            }) => <MapCsv id={id} key="Person" type="Person" />}
          />

          <GatedRouteEntitlement
            exact
            hasAccess={hasAccessAdvertising}
            path={['/audiences', '/audiences/configure-roi-metrics']}
            render={({ match }) => {
              const showDrawer = match.path.includes('/configure-roi-metrics');
              return (
                <>
                  <SidebarDrawer
                    isVisible={showDrawer}
                    onToggle={() => history.push('/audiences')}
                    size="xl"
                  >
                    <ConfigureROIMetrics />
                  </SidebarDrawer>
                  <Audiences />
                </>
              );
            }}
          />

          <GatedRouteEntitlement
            exact
            hasAccess={hasAccessAdvertising}
            path={['/audiences', '/audiences/export-sales-pipeline']}
            render={({ match }) => {
              const showDrawer = match.path.includes('/export-sales-pipeline');
              return (
                <>
                  <SidebarDrawer
                    isVisible={showDrawer}
                    onToggle={() => history.push('/audiences')}
                    size="xl"
                  >
                    <ExportSalesPipeline />
                  </SidebarDrawer>
                  <Audiences />
                </>
              );
            }}
          />

          <GatedRouteEntitlement
            exact
            hasAccess={hasAccessAdvertising}
            path="/audiences/new/:audienceSubtype(company-segment-prospects|contacts|managed-visitors|prospects|visitors)?/:step(sync)?"
            render={({
              match: {
                params: { audienceSubtype, step },
              },
              location,
            }: any) => {
              const { segmentId, segmentType } = queryString.parse(
                location.search,
              );

              const stepIndex = [audienceSubtype, step].filter(Boolean).length;

              return (
                <AudienceNew
                  key={audienceSubtype}
                  segmentId={segmentId}
                  segmentType={segmentType}
                  stepIndex={stepIndex}
                  subtype={audienceSubtype?.replace(/-/g, '_')}
                />
              );
            }}
          />

          <GatedRouteEntitlement
            exact
            hasAccess={hasAccessAdvertising}
            path={`/audiences/:id(${UUIDRegex})`}
            render={({
              match: {
                params: { id },
              },
            }) => <Audience id={id} />}
          />

          <GatedRouteEntitlement
            exact
            hasAccess={hasAccessAdvertising}
            path="/audiences/:id/edit"
            render={({
              match: {
                params: { id },
              },
            }) => <AudienceEdit id={id} />}
          />

          <GatedRouteEntitlement
            exact
            hasAccess={hasAccessAdvertising}
            path="/audiences/:id/clone/:step(sync)?"
            render={({
              match: {
                params: { id },
              },
            }) => <AudienceClone id={id} />}
          />

          <GatedRouteEntitlement
            component={Companies}
            exact
            hasAccess={hasAccessCompanies}
            path={`/companies/:companyId(${UUIDRegex})?`}
          />

          <GatedRouteEntitlement
            exact
            hasAccess={hasAccessCompanies}
            path="/companies/segments/new"
            render={({
              history: {
                location: { state = {} },
              },
            }: any) => {
              const { collection_id: collectionId } = state;

              return (
                <SegmentNew
                  collectionId={collectionId}
                  key={collectionId}
                  type="Company"
                />
              );
            }}
          />

          <GatedRouteEntitlement
            exact
            hasAccess={hasAccessCompanies}
            path={`/companies/segments/:id(${UUIDRegex})/edit`}
            render={({
              match: {
                params: { id },
              },
            }) => (
              <SegmentEdit
                attributeType="Company"
                collectionType="Company"
                id={id}
                legacyType="Company"
                type={segmentTypes.company}
              />
            )}
          />

          <GatedRouteEntitlement
            exact
            hasAccess={hasAccessCompanies}
            path={[
              `/companies/segments/:segmentId(${UUIDRegex})/destinations/:id(${UUIDRegex})?/:action?`,
              `/companies/segments/:segmentId(${UUIDRegex})/destinations/:action?/:product?`,
            ]}
            render={({
              match: {
                params: { action, id, segmentId },
              },
              location,
            }: any) => {
              const searchParams = new URLSearchParams(location.search);

              return (
                <>
                  <SegmentDestinations
                    segmentId={segmentId}
                    segmentType={segmentTypes.company}
                  />
                  <SidebarDrawer
                    isVisible={!!action}
                    onToggle={() => {
                      history.push(
                        paths.segmentDestinations(
                          segmentTypes.company as SegmentRootPathKey,
                          segmentId,
                        ),
                      );
                    }}
                    size="xxl"
                  >
                    {(() => {
                      switch (action) {
                        case 'new':
                          return (
                            <SegmentDestinationNew
                              segmentId={segmentId}
                              segmentType={segmentTypes.company}
                              type={searchParams.get('type')}
                            />
                          );
                        case 'edit':
                          return (
                            <SegmentDestinationEdit
                              destinationId={id}
                              segmentId={segmentId}
                              segmentType={segmentTypes.company}
                            />
                          );
                        case 'contact-success':
                          return (
                            <UpgradeAccountForm
                              backPath={paths.segmentDestinations(
                                segmentTypes.company as SegmentRootPathKey,
                                segmentId,
                              )}
                            />
                          );
                        default:
                          // A default is needed when the segment is animating in and out
                          return (
                            <SegmentDestinationSkeleton
                              segmentType={segmentTypes.company}
                            />
                          );
                      }
                    })()}
                  </SidebarDrawer>
                </>
              );
            }}
          />

          <GatedRouteEntitlement
            exact
            hasAccess={hasAccessCompanies}
            path={`/companies/segments/:id(${UUIDRegex})/:personId(${UUIDRegex})?`}
            render={({
              match: {
                params: { id },
              },
            }) => <CompaniesSegment id={id} key={id} />}
          />

          <Redirect
            exact
            from={`/companies/segments/:segmentId(${UUIDRegex})/destinations/:id(${UUIDRegex})`}
            to="/companies/segments/:segmentId/destinations/:id/edit"
          />

          {/* This should be the second to final route that starts with /companies/ */}
          <GatedRouteEntitlement
            exact
            hasAccess={hasAccessCompanies}
            path={`/companies/segments/:id(${UUIDRegex})/:companyId(${AnyStringRegex})?`}
            render={({
              match: {
                params: { id },
              },
            }) => <CompaniesSegment id={id} key={id} />}
          />

          {/* This should be the final route that starts with /companies/ */}
          <GatedRouteEntitlement
            component={Companies}
            exact
            hasAccess={hasAccessCompanies}
            path={`/companies/:companyId(${AnyStringRegex})?`}
          />

          <GatedRouteEntitlement
            component={People}
            exact
            hasAccess={hasAccessPeople}
            path={`/people/:personId(${UUIDRegex})?`}
          />

          <GatedRouteEntitlement
            exact
            hasAccess={hasAccessPeople}
            path="/people/segments/new"
            render={({
              history: {
                location: { state = {} },
              },
            }: any) => {
              const { collection_id: collectionId } = state;

              return (
                <SegmentNew
                  collectionId={collectionId}
                  key={collectionId}
                  type="Person"
                />
              );
            }}
          />

          <GatedRouteEntitlement
            exact
            hasAccess={hasAccessPeople}
            path={`/people/segments/:id(${UUIDRegex})/edit`}
            render={({
              match: {
                params: { id },
              },
            }) => (
              <SegmentEdit
                attributeType="Person"
                collectionType="Person"
                id={id}
                legacyType="Person"
                type={segmentTypes.person}
              />
            )}
          />

          <GatedRouteEntitlement
            exact
            hasAccess={hasAccessPeople}
            path={[
              `/people/segments/:segmentId(${UUIDRegex})/destinations/:id(${UUIDRegex})?/:action?`,
              `/people/segments/:segmentId(${UUIDRegex})/destinations/:action?/:product`,
            ]}
            render={({
              match: {
                params: { action, id, segmentId },
              },
              location,
            }: any) => {
              const searchParams = new URLSearchParams(location.search);

              return (
                <>
                  <SegmentDestinations
                    segmentId={segmentId}
                    segmentType={segmentTypes.person}
                  />
                  <SidebarDrawer
                    isVisible={!!action}
                    onToggle={() =>
                      history.push(
                        paths.segmentDestinations(
                          segmentTypes.person as SegmentRootPathKey,
                          segmentId,
                        ),
                      )
                    }
                    size="xl"
                  >
                    {(() => {
                      switch (action) {
                        case 'new':
                          return (
                            <SegmentDestinationNew
                              segmentId={segmentId}
                              segmentType={segmentTypes.person}
                              type={searchParams.get('type')}
                            />
                          );
                        case 'edit':
                          return (
                            <SegmentDestinationEdit
                              destinationId={id}
                              segmentId={segmentId}
                              segmentType={segmentTypes.person}
                            />
                          );
                        case 'contact-success':
                          return (
                            <UpgradeAccountForm
                              backPath={paths.segmentDestinations(
                                segmentTypes.person as SegmentRootPathKey,
                                segmentId,
                              )}
                            />
                          );
                        default:
                          // A default is needed when the segment is animating in and out
                          return (
                            <SegmentDestinationSkeleton
                              segmentType={segmentTypes.person}
                            />
                          );
                      }
                    })()}
                  </SidebarDrawer>
                </>
              );
            }}
          />

          <GatedRouteEntitlement
            exact
            hasAccess={hasAccessPeople}
            path={`/people/segments/:id(${UUIDRegex})/:personId(${UUIDRegex})?`}
            render={({
              match: {
                params: { id },
              },
            }) => <PeopleSegment id={id} key={id} />}
          />

          <Redirect
            exact
            from={`/people/segments/:segmentId(${UUIDRegex})/destinations/:id(${UUIDRegex})`}
            to="/people/segments/:segmentId/destinations/:id/edit"
          />

          <GatedRouteEntitlement
            component={HubspotEnrichment}
            exact
            hasAccess={hasAccessEnrichmentHubspot}
            path={[
              routes.enrichment,
              enrichmentRoutes.company,
              enrichmentRoutes.contact,
            ]}
          />

          <GatedRouteEntitlement
            exact
            hasAccess={hasAccessEnrichment}
            path={[
              `/enrichment/:integration(${concatEnrichmentTypes})/logs`,
              `/enrichment/:integration(${concatEnrichmentTypes})/logs/:id(${UUIDRegex})`,
            ]}
            render={({
              match: {
                params: { integration },
              },
            }) => (
              <EnrichmentLogs enrichmentLogsType={integration as Integration} />
            )}
          />

          <GatedRouteEntitlement
            exact
            hasAccess={hasAccessEnrichment}
            path={[
              `/enrichment/:integration(${concatEnrichmentTypes})`,
              `/enrichment/:integration(${concatEnrichmentTypes})/:objectType`,
            ]}
            render={({
              match: {
                params: { integration, objectType },
              },
            }) => (
              <EnrichmentShow
                integration={integration as IntegrationEnum}
                objectType={objectType as ObjectTypeName}
              />
            )}
          />

          <GatedRouteEntitlement
            exact
            hasAccess={hasAccessFormsHubspot || hasAccessForms}
            path="/forms/hubspot/:selectedTab?"
            render={({
              match: {
                params: { selectedTab },
              },
            }) => <Forms integration="hubspot" selectedTab={selectedTab} />}
          />

          <GatedRouteEntitlement
            exact
            hasAccess={hasAccessForms}
            path="/forms/:integration/:selectedTab?"
            render={({
              match: {
                params: { integration, selectedTab },
              },
            }) => (
              <Forms
                integration={integration as Configuration}
                selectedTab={selectedTab}
              />
            )}
          />

          <Route component={MissingPage} />
        </Switch>
      </Suspense>

      <RoutesSidebar />
    </>
  );
};
