import { CKButton, CKLayoutGrid } from 'clearkit';

import {
  Export,
  ExportStatusEnum,
  Segment,
  SegmentLegacyTypeEnum,
  useExistingSegmentExportQuery,
} from '~/generated/graphql';

import { CsvExportHistoryRow } from '../CsvExportHistoryRow';

type CsvExportHistoryProps = {
  type: 'Company' | 'Person';
  segment: Segment | undefined;
};

export function CsvExportHistoryBody({ segment, type }: CsvExportHistoryProps) {
  const { data, loading, refetch } = useExistingSegmentExportQuery({
    variables: {
      segmentId: segment?.id,
      status: [
        ExportStatusEnum.Uploading,
        ExportStatusEnum.Success,
        ExportStatusEnum.Error,
        ExportStatusEnum.Queued,
        ExportStatusEnum.Skipped,
        ExportStatusEnum.Cancelled,
      ],
      type: type as SegmentLegacyTypeEnum,
    },
    fetchPolicy: 'no-cache',
    pollInterval: 1000,
  });

  if (loading) {
    const placeHoldersCount = 6;
    return (
      <>
        {[...Array(placeHoldersCount)].map((_, i) => (
          <CsvExportHistoryRow
            csvExport={undefined}
            key={`export-history-row-${i}`}
            loading={loading}
            refetchExport={refetch}
          />
        ))}
      </>
    );
  }
  return (
    <div className="my-4">
      {data?.segmentExport?.map((csvExport: Export) => (
        <CsvExportHistoryRow
          className="rounded-none"
          csvExport={csvExport}
          key={`export-history-row-${csvExport.id}`}
          loading={loading}
          refetchExport={refetch}
        />
      ))}
    </div>
  );
}

type CsvExportHistoryFooterProps = {
  onClose: () => void;
  showUsageHelpDoc?: boolean;
};

export function CsvExportHistoryFooter({
  onClose,
  showUsageHelpDoc = false,
}: CsvExportHistoryFooterProps) {
  return (
    <>
      <CKLayoutGrid gridTemplateColumns="1fr auto">
        {showUsageHelpDoc ? (
          <a
            href="https://help.clearbit.com/hc/en-us/articles/16764226936471-Understanding-Credits-and-Usage"
            rel="noopener noreferrer"
            target="_blank"
          >
            How does a CSV export impact my usage?
          </a>
        ) : (
          <div></div>
        )}
        <CKButton onClick={onClose} variant="simple">
          Dismiss
        </CKButton>
      </CKLayoutGrid>
    </>
  );
}
