interface Options extends RequestInit {
  timeout?: number;
}

async function fetchWithTimeout(
  url: string,
  options: Options,
): Promise<Response> {
  const controller = new AbortController();
  const id = setTimeout(
    () => controller.abort(),
    options.timeout ? (options.timeout as number) : 10000,
  );

  const response = await fetch(url, {
    ...options,
    signal: controller.signal,
  });

  clearTimeout(id);

  return response;
}

export default fetchWithTimeout;
