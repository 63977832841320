import { CKBox } from 'clearkit';

import ContentBox from '~/components/ContentBox';
import { segmentTypes } from '~/lib/segments';

import {
  AddAudience,
  AddDestination,
} from './Destination/AddDestinationOrAudience';
import styles from './styles';

function PlanDestinationGroup({
  classes,
  title,
  description,
  audiences,
  link,
  items,
  segment,
  enabled,
}) {
  let destinations = items.all || [];

  if (segment.type === segmentTypes.company) {
    destinations = destinations.concat(items.company || []);
  }

  if (segment.type === segmentTypes.person) {
    destinations = destinations.concat(items.person || []);
  }

  const intersection = destinations.filter((x) => enabled.includes(x));

  if (intersection.length === 0) {
    return null;
  }

  return (
    <>
      <ContentBox.Title>
        <h2 className={classes.heading}>{title}</h2>
        <p className={classes.infoText}>
          {description}{' '}
          {link && (
            <a href={link} style={{ textDecoration: 'underline' }}>
              Learn More
            </a>
          )}
        </p>
      </ContentBox.Title>

      {intersection.map((type) =>
        audiences ? (
          <CKBox className="mb-4" key={type}>
            <AddAudience key={type} segment={segment} subtype={type} />
          </CKBox>
        ) : (
          <AddDestination
            className={classes.audienceItem}
            key={type}
            segment={segment}
            type={type}
          />
        ),
      )}
    </>
  );
}

export default withStyles(styles)(PlanDestinationGroup);
