import { Hubspot } from '@clearkit/icons';
import { CKButton, CKLayoutHorizontal, CKLayoutVertical } from 'clearkit';
import { Link } from 'react-router-dom';

import { settingsRoutes } from '~/components/settings/settingsRoutes';

import { ClearbitHubSpotConnection } from './ClearbitHubSpotConnection';

export const ConnectHubSpotCTA = () => {
  return (
    <CKLayoutVertical alignItems="stretch" gap="1.5rem" justifyContent="center">
      <div className="text-center space-y-4">
        <ClearbitHubSpotConnection />
        <h3 className="font-medium">Get started with Prospector</h3>
        <p className="text-gray-500">
          Connect HubSpot to quickly add prospects to your CRM
        </p>
      </div>
      <CKButton
        as={Link}
        className="w-full"
        to={settingsRoutes.configureHubspot}
        variant="simple"
      >
        <CKLayoutHorizontal as="span" gap=".25rem" justifyContent="center">
          <Hubspot className="text-hubspot" />
          Connect HubSpot
        </CKLayoutHorizontal>
      </CKButton>
    </CKLayoutVertical>
  );
};
