import { CKAvatar } from 'clearkit';

import { TableCell, TableRow } from '~/components/Table';

import styles from './styles';

const NameColumn = ({ avatar, name }) => (
  <TableCell ellipsis key="name" link title={name}>
    <div className="flex items-center gap-3">
      <CKAvatar size={24} src={avatar} />
      <span>{name}</span>
    </div>
  </TableCell>
);

NameColumn.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string,
};

class SearchPeopleListRow extends React.Component {
  render() {
    const { classes, person, onClick } = this.props;
    const { avatar, email, id, name, title } = person;

    return (
      <TableRow className={classes.root} hover key={id} onClick={onClick}>
        <NameColumn avatar={avatar} classes={classes} name={name} />
        <TableCell ellipsis key="email" title={email}>
          {email || '-'}
        </TableCell>
        <TableCell ellipsis key="title" title={title}>
          {title || '-'}
        </TableCell>
      </TableRow>
    );
  }
}

SearchPeopleListRow.propTypes = {
  classes: PropTypes.shape({
    company: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
    root: PropTypes.string.isRequired,
  }).isRequired,
  person: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.string),
  height: PropTypes.number,
};

export default withStyles(styles)(SearchPeopleListRow);
