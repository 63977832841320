import { Mutation } from '@apollo/client/react/components';
import { CKTextField } from 'clearkit';

import mutation from './mutation.graphql';

class CollectionName extends React.Component {
  componentDidMount() {
    const input = this.renameInput.current;

    if (!input) {
      return;
    }

    input.focus();
    input.setSelectionRange(0, input.value.length);
  }

  renameInput = React.createRef();

  renameKeyDown = (event, mutation) => {
    const { collection, onBlur, type } = this.props;
    const { __typename } = collection;

    if (event.key === 'Enter') {
      const name = event.target.value.trim();

      mutation({
        optimisticResponse: {
          __typename: 'Mutation',
          response: {
            __typename: 'CollectionsUpdatePayload',
            node: {
              __typename,
              id: collection.id,
              name,
            },
          },
        },
        variables: {
          input: {
            type,
            id: collection.id,
            name,
          },
        },
      });
      onBlur();
    } else if (event.key === 'Escape') {
      onBlur();
    }
  };

  render() {
    const { collection } = this.props;

    return (
      <Mutation mutation={mutation} refetchQueries={['PeopleSegments']}>
        {(renameMutation) => (
          <CKTextField
            defaultValue={collection.name}
            onBlur={this.props.onBlur}
            onKeyDown={(event) => this.renameKeyDown(event, renameMutation)}
            placeholder="New name"
            ref={this.renameInput}
          />
        )}
      </Mutation>
    );
  }
}

CollectionName.propTypes = {
  value: PropTypes.string,
  onBlur: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['Person', 'Company']).isRequired,
  collection: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    __typename: PropTypes.string.isRequired,
  }).isRequired,
};

export default CollectionName;
