import { useAccountSettingsQuery } from '~/generated/graphql';

import { useUpdateAccountDetails } from './useUpdateAccountDetails';
import { useUpdateAccountSettings } from './useUpdateAccountSettings';
import { useUpdateAPIVersion } from './useUpdateAPIVersion';
import { useUpdateSuppressionSettings } from './useUpdateSuppressionSettings';

export function useAccountSettings() {
  const { data, loading, refetch } = useAccountSettingsQuery();
  const { updateAccountSettings } = useUpdateAccountSettings();
  const { updateAccountDetails } = useUpdateAccountDetails();
  const { updateAPIVersion } = useUpdateAPIVersion();
  const { updateSuppressionSettings } = useUpdateSuppressionSettings();

  return {
    loading,
    refetch,
    name: data?.account?.name ?? '',
    publishableToken: data?.account?.publishableToken ?? '',
    secretToken: data?.account?.secretToken ?? '',
    webhookUrl: data?.account?.webhookUrl ?? '',
    remoteAllowedReferrers: data?.account?.remoteAllowedReferrers ?? [],
    apiVersions: data?.account?.apiVersions,
    suppressionSettings: data?.account?.suppressionSettings,
    mfaEnforced: data?.account?.mfaEnforced ?? false,
    updateAccountSettings,
    updateAccountDetails,
    updateAPIVersion,
    updateSuppressionSettings,
  };
}
