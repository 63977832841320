const styles = () => ({
  expandContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 12,
  },

  numberLeft: {
    marginLeft: 12,
    backgroundColor: '#2b9ffd',
    borderRadius: 5,
    height: 17,
    minWidth: 22,
    fontSize: 10,
    fontWeight: 'bold',
    letterSpacing: 0,
    padding: '1px 4px 0',
    color: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  noWrap: {
    flexWrap: 'nowrap !important',
  },

  valueContainer: {
    alignItems: 'center',
    marginLeft: -2,
    display: 'flex',
    flex: 1,
    position: 'relative',
    boxSizing: 'border-box',
    flexWrap: 'wrap',
    overflow: 'none',
  },

  root: {
    '.value__control--menu-is-open &': {
      '& $valueContainer': {
        maxHeight: '40vh',
        overflow: 'auto',
      },
    },
  },

  truncate: {
    maxHeight: 29,
    overflow: 'hidden',
  },
});

export default styles;
