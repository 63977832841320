import { integrationTabs as objectTypes } from '../../../lib/dataEnrichment';
import { UseTabStateArguments } from './types';

export function useIntegrationTabState({
  integration,
  tabName,
}: UseTabStateArguments) {
  const tabs = objectTypes[integration];
  const currentTabIndex = tabs.findIndex((tab) => tab.name === tabName) ?? 0;
  const resolvedTabName = tabName ?? tabs[0].name;

  return {
    tabs,
    currentTabIndex,
    currentTab: tabs[currentTabIndex],
    tabName: resolvedTabName,
    getTabByIndex: (index: number) => tabs[index],
  };
}
