import Contacts from '~/components/audiences/Tile/Contacts.svg';
import Prospects from '~/components/audiences/Tile/Prospects.svg';
import Visitors from '~/components/audiences/Tile/Visitors.svg';
import CustomHtml from '~/images/destinations/CustomHtml.svg';
import Email from '~/images/destinations/Email';
import FacebookConversion from '~/images/destinations/Facebook.svg';
import Salesforce from '~/images/destinations/Salesforce.svg';
import Slack from '~/images/destinations/Slack.svg';
import Webhook from '~/images/destinations/Webhook.svg';
import Google from '~/images/services/Google.svg';
import { audienceSubtypes } from '~/lib/audiences';
import {
  destinationTypeCustomHtml,
  destinationTypeEmail,
  destinationTypeFacebookConversion,
  destinationTypeGoogleConversion,
  destinationTypeSalesforce,
  destinationTypeSalesforceCreateAccount,
  destinationTypeSalesforceCreateContact,
  destinationTypeSalesforceCreateLead,
  destinationTypeSlack,
  destinationTypeWebhook,
} from '~/lib/destinations';

const logos = {
  [audienceSubtypes.companySegmentProspects]: Prospects,
  [audienceSubtypes.contacts]: Contacts,
  [audienceSubtypes.prospects]: Prospects,
  [audienceSubtypes.visitors]: Visitors,
  [destinationTypeCustomHtml]: CustomHtml,
  [destinationTypeFacebookConversion]: FacebookConversion,
  [destinationTypeGoogleConversion]: Google,
  [destinationTypeEmail]: Email,
  [destinationTypeSalesforce]: Salesforce,
  [destinationTypeSalesforceCreateAccount]: Salesforce,
  [destinationTypeSalesforceCreateContact]: Salesforce,
  [destinationTypeSalesforceCreateLead]: Salesforce,
  [destinationTypeSlack]: Slack,
  [destinationTypeWebhook]: Webhook,
};

export default function Logo({ destinationType, audienceSubtype, ...props }) {
  const Component = logos[destinationType] || logos[audienceSubtype];

  if (!Component) {
    return <div {...props} />;
  }

  return <Component {...props} />;
}

Logo.propTypes = {
  className: PropTypes.string,
  destinationType: PropTypes.string,
  audienceSubtype: PropTypes.string,
};
