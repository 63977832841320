import { ArrowRight } from '@clearkit/icons';
import classnames from 'classnames';
import { CKContainerProps, CKLayoutHorizontal } from 'clearkit';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

type Breadcrumb = {
  path: string;
  title: string;
};

export type BreadcrumbProps = CKContainerProps & {
  breadcrumbs?: Breadcrumb[];
};

export const BreadcrumbHeading: FC<BreadcrumbProps> = ({
  breadcrumbs,
  children,
  className,
  ...rest
}) => {
  return (
    <CKLayoutHorizontal
      {...rest}
      className={classnames('text-2xl text-gray-900', className)}
      gap="0.5rem"
      justifyContent="start"
    >
      {breadcrumbs?.map((breadcrumb) => (
        <React.Fragment key={breadcrumb.path}>
          <Link className="text-gray-600 hover:underline" to={breadcrumb.path}>
            {breadcrumb.title}
          </Link>
          <ArrowRight />
        </React.Fragment>
      ))}
      <span className="font-semibold">{children}</span>
    </CKLayoutHorizontal>
  );
};
