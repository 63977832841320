import { useQuery } from '@apollo/client';
import { Go, Help, Signout } from '@clearkit/icons';
import { CKButton, CKCompanyLogo } from 'clearkit';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import AccountSwitch from '~/components/AccountSwitch';
import { settingsRoutes } from '~/components/settings/settingsRoutes';

import query from './query.graphql';

type UserMenuProps = {
  accounts: any[];
  currentAccountId: string;
  currentUser: {
    email: string;
    name: string;
  };
  domain: string;
};

const UserMenu: FC<UserMenuProps> = ({
  accounts,
  currentAccountId,
  currentUser,
  domain,
}) => {
  if (!currentUser) return null;

  const { name, email } = currentUser;
  const buttonClasses =
    'w-full px-4 py-1 text-gray-800 font-medium hover:bg-blue-0';

  return (
    <div className="min-w-[17.5rem]">
      <div className="flex items-start px-4 py-3 font-medium border-b border-gray-200 gap-4">
        <CKCompanyLogo domain={domain} size={36} />
        <div className="text-sm text-gray-900">
          <h3 className="font-semibold">{name}</h3>
          <h4 className="mb-1 text-gray-600">{email}</h4>
          <CKButton
            as={Link}
            className="flex items-center font-sm gap-1 group"
            rightIcon={
              <Go className="opacity-0 group-hover:opacity-100 transition-opacity duration-150 ease-out" />
            }
            to={settingsRoutes.generalAccountRoute}
            variant="tertiary"
            variantColor="blue"
          >
            Edit profile
          </CKButton>
        </div>
      </div>
      <AccountSwitch accounts={accounts} currentAccountId={currentAccountId} />
      <div className="items-start py-2 text-sm grid gap-1">
        <CKButton
          className={buttonClasses}
          href="https://help.clearbit.com/hc/en-us/"
          leftIcon={<Help className="mr-1" />}
          rel="noopener noreferrer"
          target="_blank"
          variant="clear"
        >
          Support & Guides
        </CKButton>
        <CKButton
          className={buttonClasses}
          href="/logout"
          leftIcon={<Signout className="mr-1" />}
          variant="clear"
        >
          Sign out
        </CKButton>
      </div>
    </div>
  );
};

const WrappedUserMenu = () => {
  const result = useQuery(query);

  if (!result.data) return null;

  const { data } = result;
  if (!data.viewer || !data.account) return null;

  return (
    <UserMenu
      accounts={data.viewer.user?.accounts || []}
      currentAccountId={data.account.id}
      currentUser={data.viewer.user}
      domain={data.account.domain}
    />
  );
};

export default WrappedUserMenu;
