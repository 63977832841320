// https://api.highcharts.com/highcharts

export default {
  chart: {
    style: {
      fontFamily: '"Avenir Next", Avenir, Helvetica, Arial, sans-serif',
    },
    margin: [0, 0, 0, 0],
    marginLeft: -35,
    marginRight: -35,
  },
  tooltip: {
    enabled: false,
    className: 'dashboard-graph-tooltip',
    borderRadius: 10,
    pointFormatter: function() {
      return `<span>${this.y.toLocaleString()}</span>`;
    },
    padding: 0,
    dateTimeLabelFormats: {
      millisecond: '%H:%M:%S.%L',
      second: '%H:%M:%S',
      minute: '%H:%M',
      hour: '%d %b',
      day: '%e %b',
      week: '%e %b',
      month: "%b '%y",
      year: '%Y',
    },
    useHTML: true,
    style: {
      color: 'white',
      textAlign: 'center',
      fontWeight: '600',
      fontSize: '16px',
    },
    shadow: {
      offsetX: 0,
      opacity: 0.05,
    },
    backgroundColor: '#4EDC9F',
    tooltipFormat: '{point.y}',
    valueSuffix: ' cm',
  },
  plotOptions: {
    series: {
      animation: false,
      marker: {
        fillColor: '#4EDC9F',
        lineWidth: 2,
        radius: 6,
        lineColor: 'white',
        enabled: true,
      },
      fillColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
        },
        stops: [
          [0, 'rgba(151, 236, 202, 0.4)'],
          [1, 'rgba(241, 252, 248, 0.4)'],
        ],
      },
    },
  },
  xAxis: {
    labels: {
      enabled: false,
    },
    dateTimeLabelFormats: {
      millisecond: '%H:%M:%S.%L',
      second: '%H:%M:%S',
      minute: '%H:%M',
      hour: '%H:%M',
      day: '%e %b',
      week: '%e %b',
      month: "%b '%y",
      year: '%Y',
    },
    tickLength: 0,
    lineWidth: 0,
  },
  yAxis: {
    floor: 0,
    min: 0,
    minRange: 0.1,
    visible: false,
  },
  time: {
    useUTC: false,
  },
};

export const downwardGraphOptions = {
  plotOptions: {
    series: {
      fillColor: {
        stops: [[0, 'white']],
      },
      marker: {
        fillColor: '#637eff',
      },
    },
  },
};
