import { Config, Forms } from '@clearkit/icons';
import {
  CKLayoutHorizontal,
  CKTab,
  CKTabList,
  CKTabPanel,
  CKTabPanels,
} from 'clearkit';

import { routes } from '~/components/app/appMainRoutes';
import AccountConnectorAction from '~/components/layouts/ConnectorLayout/AccountConnectorAction';
import ConnectInterstitial from '~/components/layouts/ConnectorLayout/ConnectInterstitial';
import { useAccountConnector } from '~/components/layouts/ConnectorLayout/useAccountConnector';
import { PageLayoutTabs } from '~/components/PageLayout';
import { useRouterTabs } from '~/components/unified/hooks';
import { useEntitlements } from '~/hooks/account';
import {
  Configuration as ConfigurationType,
  configurationNameForType,
} from '~/lib/configuration';
import { connectorLocations } from '~/lib/segmentAnalytics';
import { Spinner } from '~/pages/authentication/Spinner';

import Configuration from './Configuration';
import FormFieldMappings from './Mappings';

export type FormConfigurationProps = {
  integration: ConfigurationType;
  selectedTab?: string;
};

export function FormsPage({
  integration,
  selectedTab = 'configuration',
}: FormConfigurationProps) {
  const formsRoutes = {
    0: `/forms/${integration}/configuration`,
    1: `/forms/${integration}/mappings`,
  };

  const selectedTabIndex = selectedTab === 'mappings' ? 1 : 0;

  const { handleTabIndexChange, tabIndex } = useRouterTabs(
    formsRoutes,
    selectedTabIndex || 0,
  );

  const {
    accountConnector,
    isAccountConnectorComplete,
    loading,
    refetch,
  } = useAccountConnector(integration);

  const { hasAccessIntegrations } = useEntitlements();
  const connectInterstitialBackground = !isAccountConnectorComplete
    ? 'bg-white'
    : '';
  const integrationDisplayName = configurationNameForType(integration);
  const title = `${integrationDisplayName} Forms`;

  if (loading) {
    return (
      <CKLayoutHorizontal
        alignItems="center"
        className="w-full h-full"
        justifyContent="center"
      >
        <Spinner />
      </CKLayoutHorizontal>
    );
  }

  return (
    <PageLayoutTabs index={tabIndex} onChange={handleTabIndexChange}>
      <PageLayoutTabs.Header>
        <PageLayoutTabs.Tile variant="purple">
          <Forms />
        </PageLayoutTabs.Tile>

        {hasAccessIntegrations ? (
          <PageLayoutTabs.Breadcrumb
            breadcrumbs={[
              {
                title: 'Integrations',
                path: routes.integrations,
              },
            ]}
          >
            {title}
          </PageLayoutTabs.Breadcrumb>
        ) : (
          <PageLayoutTabs.Heading>{title}</PageLayoutTabs.Heading>
        )}

        <PageLayoutTabs.SubHeading>
          Improve conversion rates on {integrationDisplayName} forms
        </PageLayoutTabs.SubHeading>

        <PageLayoutTabs.Actions>
          {isAccountConnectorComplete ? (
            <AccountConnectorAction accountConnector={accountConnector} />
          ) : null}
        </PageLayoutTabs.Actions>
        <CKTabList>
          {isAccountConnectorComplete ? (
            <>
              <CKTab
                icon={<Config height={16} width={16} />}
                label="Configuration"
              >
                Configuration
              </CKTab>
              <CKTab
                icon={<Forms height={16} width={16} />}
                label="Form Field Mappings"
              >
                Form Field Mappings
              </CKTab>
            </>
          ) : null}
        </CKTabList>
      </PageLayoutTabs.Header>
      <PageLayoutTabs.Body className={connectInterstitialBackground}>
        {!isAccountConnectorComplete ? (
          <ConnectInterstitial
            connectorLocation={connectorLocations.forms}
            integration={integration}
            onSuccess={() => refetch()}
          />
        ) : (
          <>
            <PageLayoutTabs.Content>
              <CKTabPanels>
                <CKTabPanel>
                  <Configuration integration={integration} />
                </CKTabPanel>
                <CKTabPanel>
                  <FormFieldMappings integration={integration} />
                </CKTabPanel>
              </CKTabPanels>
            </PageLayoutTabs.Content>
          </>
        )}
      </PageLayoutTabs.Body>
    </PageLayoutTabs>
  );
}
