import { useMutation } from '@apollo/client';
import { Restart, Route, SlackServices as SlackLogo } from '@clearkit/icons';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { CKBox, CKButton, CKDropdownGroup, CKMultiPickerChips } from 'clearkit';
import { useState } from 'react';

import InfoTooltip from '~/components/InfoTooltip';
import MultiPicklist from '~/components/MultiPicklist';
import TextField from '~/components/RedesignedTextField';
import { destinationTypeSlack } from '~/lib/destinations';
import { hasError } from '~/lib/graphql';
import { legacySegmentType, readableSingularSegmentType } from '~/lib/segments';
import DeliverTestButton from '~/pages/destinations/components/DeliverTestButton';
import Disconnected from '~/pages/destinations/components/Disconnected';
import SuppressionForm from '~/pages/destinations/components/SuppressionForm';
import TriggerType from '~/pages/destinations/components/TriggerType';
import defaultTriggerTypes from '~/pages/destinations/components/TriggerType/defaultValues';

import FormContentWrapper from '../../components/FormContentWrapper';
import FormHeader from '../../components/FormHeader';
import sharedStyles from '../../New/styles';
import {
  getDeliveryTargets,
  HUBSPOT_TARGETS,
  SALESFORCE_TARGETS,
} from '../dynamicDeliveryTargets';
import SlackDestinationDescription from './DestinationDescription';
import refreshRemoteMeta from './refreshRemoteMeta.graphql';
import SlackConnectorStatus from './SlackConnectorStatus';
import styles from './styles';

export function combineUsersAndChannels(slackUsers, slackChannels) {
  return slackUsers
    .map((user) => ({
      id: user.id,
      value: user.id,
      label: `${user.profile.realName} (@${user.profile.displayName ||
        user.profile.realName})`,
      realName: user.profile.realName,
      displayName: user.profile.displayName,
      isChannel: false,
    }))
    .concat(
      slackChannels
        .map(({ id, name }) => ({
          id,
          value: id,
          label: `#${name} ${id}`,
          name,
          options: [],
          isChannel: true,
        }))
        .filter((channel) => !!channel.name),
    );
}

const RefreshButton = ({ connectorId }) => {
  const [refresh, { loading }] = useMutation(refreshRemoteMeta, {
    refetchQueries: ['SegmentDestinationNewPage'],
  });

  return (
    <CKButton
      isDisabled={loading}
      leftIcon={<Restart />}
      onClick={() => {
        refresh({
          variables: {
            input: {
              id: connectorId,
            },
          },
        });
      }}
      variant="tertiary"
      variantColor="blue"
    >
      {loading ? 'Refreshing' : 'Refresh'}
    </CKButton>
  );
};

function SlackDestination({
  accountConnectors,
  attributes,
  classes,
  errors,
  refetch,
  segment,
  destination,
  FormFooter,
}) {
  const accountConnector = accountConnectors.slack;
  const isNew = !destination;
  const initialConfig = isNew ? {} : destination.config;
  const webhookUrl = initialConfig.webhookUrl || null;

  let channelList = [];
  if (accountConnectors.salesforce) {
    channelList = [
      ...channelList,
      ...getDeliveryTargets(SALESFORCE_TARGETS, segment.type),
    ];
  }
  if (accountConnectors.hubspot) {
    channelList = [
      ...channelList,
      ...getDeliveryTargets(HUBSPOT_TARGETS, segment.type),
    ];
  }

  const [dynamicChannels, _setDynamicChannels] = useState(
    channelList.map((channel) => ({
      ...channel,
      label: `DM ${channel.label}`,
    })),
  );

  const initialState = {
    columns: initialConfig.columns || [],
    duplicateSuppressionTimeframe:
      initialConfig.duplicateSuppressionTimeframe || 0,
    channels: initialConfig.channels || [],
    message: initialConfig.message || '',
    triggerType: isNew ? 'segment_enter' : destination.triggerType,
  };

  const [columns, setColumns] = useState(initialState.columns);
  const [
    duplicateSuppressionTimeframe,
    setDuplicateSuppressionTimeframe,
  ] = useState(initialState.duplicateSuppressionTimeframe);
  const [triggerType, setTriggerType] = useState(initialState.triggerType);
  const [channels, setChannels] = useState(initialState.channels);
  const [message, setMessage] = useState(initialState.message);

  const slackIsConnected = accountConnector?.status === 'active';

  function destinationConfig() {
    return {
      config: {
        columns,
        duplicateSuppressionTimeframe,
        webhookUrl: channels ? null : webhookUrl,
        channels,
        message,
      },
      triggerType,
    };
  }

  if (!slackIsConnected && !webhookUrl) {
    return (
      <Disconnected
        connectorStatus={accountConnector?.status}
        FormFooter={FormFooter}
        Logo={SlackLogo}
        onSuccess={refetch}
        service="slack"
      />
    );
  }

  const humanizedSegmentType = readableSingularSegmentType(segment.type);
  const destinationFlowDescription = destination ? (
    <SlackDestinationDescription
      accountConnector={accountConnector}
      config={destination.config}
    />
  ) : null;

  return (
    <>
      <FormHeader
        destinationFlowDescription={destinationFlowDescription}
        destinationName="Slack"
        destinationType={destinationTypeSlack}
        segmentCollection={segment.collection.name}
        segmentName={segment.name}
      />
      <FormContentWrapper>
        <h2 className={classes.subheading}>Trigger</h2>
        <span className={classes.triggerSubtext}>
          Choose when this Destination will be run.{' '}
          <a
            href="https://help.clearbit.com/hc/en-us/articles/360023453694"
            rel="noopener noreferrer"
            style={{ textDecoration: 'underline' }}
            target="_blank"
          >
            Learn more
          </a>
        </span>

        <TriggerType
          errors={errors}
          handleChange={(_, value) => {
            setTriggerType(value);
          }}
          triggerType={triggerType}
          triggerTypes={defaultTriggerTypes(segment.type)}
        />

        <h2 className={classes.subheading}>Configure Slack options</h2>

        <CKBox className="p-6 mb-6">
          {webhookUrl && (
            <SlackConnectorStatus
              accountConnector={accountConnector}
              onSuccess={refetch}
              webhookUrl={webhookUrl}
            />
          )}

          {slackIsConnected ? (
            <ChannelSelector
              accountConnector={accountConnector}
              channels={channels}
              classes={classes}
              dynamicChannelOptions={dynamicChannels}
              errors={errors}
              setChannels={setChannels}
            />
          ) : (
            <WebhookInput
              classes={classes}
              errors={errors}
              webhookUrl={webhookUrl}
            />
          )}

          <FormControl
            className={classes.field}
            error={hasError(errors, 'channels')}
            fullWidth
            required
          >
            <div className="flex items-center mb-2">
              <FormLabel>Custom Message</FormLabel>
              <InfoTooltip title="Include a custom message at the top of your Slack post" />
            </div>
            <TextField
              defaultValue={message}
              error={hasError(errors, 'message')}
              fullWidth
              onChange={(event) => {
                setMessage(event.target.value);
              }}
              variant="standard"
            />
          </FormControl>
          <hr className={classes.break} />
          <SuppressionForm
            exclusion_timeframe={duplicateSuppressionTimeframe}
            onChange={({ exclusion_timeframe }) => {
              setDuplicateSuppressionTimeframe(exclusion_timeframe);
            }}
            typename={humanizedSegmentType}
          />
        </CKBox>

        <h2 className={classes.subheading}>Customize your Slack message</h2>

        <p className={classes.columnsHelperText}>
          Select which attributes you want to include as fields in your Slack
          message
        </p>

        <MultiPicklist
          allColumns={attributes}
          onChange={(value) => {
            setColumns(value);
          }}
          type={legacySegmentType(segment.type)}
          value={columns || []}
        />

        <h2 className={classes.subheading}>Test this destination</h2>

        <p className={classes.columnsHelperText}>
          Run a test by manually triggering this destination
        </p>

        <CKBox className="p-6 mb-6">
          <DeliverTestButton
            config={{
              columns,
              duplicateSuppressionTimeframe,
              message,
              channels,
              webhookUrl,
            }}
            destinationType="slack"
            segmentId={segment.id}
            segmentType={segment.type}
          >
            <p className="mb-4 text-base text-gray-600">
              Trigger an example Slack alert for this audience.
            </p>
          </DeliverTestButton>
        </CKBox>
      </FormContentWrapper>
      <FormFooter destinationConfig={destinationConfig()} />
    </>
  );
}

function WebhookInput({ classes, errors, webhookUrl }) {
  return (
    <TextField
      className={classes.field}
      disabled
      error={hasError(errors, 'webhookUrl')}
      fullWidth
      label="Webhook URL"
      value={webhookUrl}
      variant="standard"
    />
  );
}

function ChannelSelector({
  classes,
  errors,
  dynamicChannelOptions,
  channels,
  setChannels,
  accountConnector,
}) {
  const {
    remoteMeta: { channels: slackChannels = [], users: slackUsers = [] },
    id,
  } = accountConnector;

  const [channelOptions, _setChannelOptions] = useState(
    combineUsersAndChannels(slackUsers, slackChannels),
  );

  const selectedOptions = [...dynamicChannelOptions, ...channelOptions].filter(
    (channelOption) => channels.indexOf(channelOption.value) > -1,
  );

  return (
    <FormControl
      className={classes.field}
      error={hasError(errors, 'channels')}
      fullWidth
      required
    >
      <CKMultiPickerChips
        onChange={(newValue) => {
          let items = newValue.map((item) => item.value);
          setChannels(items);
        }}
        placeholder="Choose a channel"
        value={selectedOptions}
      >
        <CKMultiPickerChips.Label className="flex items-center">
          Slack Channel
          <InfoTooltip title="Choose the channel or channels you would like to send alerts to." />
        </CKMultiPickerChips.Label>
        <CKMultiPickerChips.Group items={dynamicChannelOptions}>
          <CKDropdownGroup.Header>
            <CKDropdownGroup.Title>
              <CKDropdownGroup.Icon>
                <Route />
              </CKDropdownGroup.Icon>
              Slack channels
            </CKDropdownGroup.Title>
          </CKDropdownGroup.Header>
        </CKMultiPickerChips.Group>
        <CKMultiPickerChips.Group items={channelOptions}>
          <CKDropdownGroup.Header>
            <CKDropdownGroup.Title>
              <CKDropdownGroup.Icon>
                <SlackLogo />
              </CKDropdownGroup.Icon>
              Slack channels
            </CKDropdownGroup.Title>
            <CKDropdownGroup.Action>
              <RefreshButton connectorId={id} />
            </CKDropdownGroup.Action>
          </CKDropdownGroup.Header>
        </CKMultiPickerChips.Group>
      </CKMultiPickerChips>
    </FormControl>
  );
}

SlackDestination.propTypes = {
  accountConnectors: PropTypes.shape({
    slack: PropTypes.shape({
      id: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      remoteMeta: PropTypes.shape({
        channels: PropTypes.array.isRequired,
        users: PropTypes.array.isRequired,
      }).isRequired,
    }),
  }),
  attributes: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  destination: PropTypes.object,
  errors: PropTypes.array.isRequired,
  refetch: PropTypes.func.isRequired,
  segment: PropTypes.object.isRequired,
};

export default withStyles(
  (theme) => ({
    ...sharedStyles(theme),
    ...styles(theme),
  }),
  { name: 'SegmentDestinationNewSlack' },
)(SlackDestination);
