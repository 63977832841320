import { Error, HubspotServices, Lock } from '@clearkit/icons';
import {
  CKBannerNext,
  CKBox,
  CKButton,
  CKContainerProps,
  CKLayoutHorizontal,
} from 'clearkit';
import { useState } from 'react';

import useAuthenticateHubspot from '~/hooks/authentication/useAuthenticateHubspot';

import { Description } from './Description';

export type ConnectHubspotProps = CKContainerProps & {
  onSuccess?: () => void;
};

export const ConnectHubspot = ({
  children,
  onSuccess,
}: ConnectHubspotProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { error, errorMessage, connect } = useAuthenticateHubspot({
    onSuccess,
  });

  const handleHubspotConnect = () => {
    setIsLoading(true);
    connect();
  };

  return (
    <>
      <div className="p-8 space-y-8">
        {children}
        <CKLayoutHorizontal justifyContent="center">
          <CKButton
            isLoading={isLoading}
            leftIcon={<HubspotServices className="w-5 h-5 mr-1" />}
            onClick={handleHubspotConnect}
            variant="simple"
          >
            Connect HubSpot
          </CKButton>
        </CKLayoutHorizontal>
        {error ? (
          <CKBannerNext className="w-full" variant="error">
            <CKBannerNext.IconStatus>
              <Error />
            </CKBannerNext.IconStatus>
            <CKBannerNext.Header>
              Error Connecting to HubSpot
            </CKBannerNext.Header>
            <CKBannerNext.Body>{errorMessage}</CKBannerNext.Body>
          </CKBannerNext>
        ) : null}
      </div>
      <CKBox.Footer className="px-8 py-6">
        <CKLayoutHorizontal alignItems="start" wrap="nowrap">
          <Lock className="shrink-0" />
          <Description>
            Clearbit will not write data to HubSpot without your permission or
            without you explicitly turning on features. You can learn more about
            Clearbit's data security and privacy practices{' '}
            <a className="ck-link" href="https://clearbit.com/privacy-policy">
              here
            </a>
            .
          </Description>
        </CKLayoutHorizontal>
      </CKBox.Footer>
    </>
  );
};
