import { CKBox, CKContainerProps } from 'clearkit';

type ChartEmptyOverlayProps = CKContainerProps & {
  message?: React.ReactNode;
  showEmptyState?: boolean;
};

export const ChartEmptyOverlay: React.FC<ChartEmptyOverlayProps> = ({
  children,
  showEmptyState,
  message = 'No data available for this period',
}) => {
  return (
    <div className="relative w-full h-full">
      {children}
      {showEmptyState ? (
        <div className="absolute inset-0 flex w-full h-full bg-white/[0.72]">
          <CKBox className="p-3 m-auto font-medium">{message}</CKBox>
        </div>
      ) : null}
    </div>
  );
};
