import { components } from 'react-select';

import ClearbitIcon from './Clearbit.svg';
import styles from './styles';

const MenuHeader = withStyles(
  styles,
  {},
)(
  ({
    classes,
    selectProps: { menuListTitle, menuListIcon, menuListActions },
  }) => (
    <div className={classes.menuListHeader}>
      <div className={classes.titleContainer}>
        <div className={classes.menuListIconContainer}>
          {menuListIcon ? menuListIcon : <ClearbitIcon />}
        </div>
        <span className={classes.sectionTitle}>{menuListTitle}</span>
      </div>
      {menuListActions && (
        <div className={classes.actionsContainer}>{menuListActions}</div>
      )}
    </div>
  ),
);

export default class Menu extends React.Component {
  render() {
    return (
      <components.Menu {...this.props}>
        <MenuHeader selectProps={this.props.selectProps} />
        {this.props.children}
      </components.Menu>
    );
  }
}

Menu.propTypes = {
  selectProps: PropTypes.object,
  children: PropTypes.node,
};
