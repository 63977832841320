import classnames from 'classnames';
import {
  CKBadge,
  CKBox,
  CKButton,
  CKCompanyLogo,
  CKIconBox,
  CKLayoutGrid,
  CKLayoutHorizontal,
} from 'clearkit';
import { Link } from 'react-router-dom';

import { AddHubSpotError } from '~/components/AddHubSpot/AddHubSpotError';
import { routes } from '~/components/app/appMainRoutes';

import { HubspotCrmPresence } from '../../AddHubSpot/HubspotCrmPresence';
import { CompanyType } from './types';

type companyCardProps = {
  company: CompanyType;
};

export const CompanyCard = ({ company }: companyCardProps) => {
  const dividerClasses = 'pt-4 border-t border-gray-200';
  const hasTags = company.tags.length;

  return (
    <CKBox className="p-6">
      <CKLayoutGrid
        className="grow"
        gridTemplateRows={hasTags ? 'auto 1fr auto' : 'auto 1fr'}
      >
        <header className="space-y-4">
          <CKLayoutHorizontal justifyContent="center">
            <CKIconBox className="!p-0.5">
              <CKCompanyLogo domain={company.domain ?? ''} size={48} />
            </CKIconBox>
          </CKLayoutHorizontal>
          <div className="text-sm text-center">
            <h3 className="text-lg font-medium">{company.name}</h3>
            <h4>{company.domain}</h4>
          </div>
        </header>
        {hasTags ? (
          <CKLayoutHorizontal
            alignItems="start"
            className={classnames(dividerClasses, 'self-start')}
            gap=".5rem"
            justifyContent="center"
          >
            {company.tags.map((tag) => (
              <CKBadge key={tag}>
                <span className="min-w-0 truncate">{tag}</span>
              </CKBadge>
            ))}
          </CKLayoutHorizontal>
        ) : null}
        <div>
          <CKLayoutHorizontal className={dividerClasses} gap=".5rem">
            <div>
              <HubspotCrmPresence domain={company.domain} />
            </div>
            <CKButton
              as={Link}
              to={`${routes.targetMarketsDetails}/${company.domain}`}
              variant="tertiary"
              variantColor="blue"
            >
              View
            </CKButton>
          </CKLayoutHorizontal>
          <AddHubSpotError />
        </div>
      </CKLayoutGrid>
    </CKBox>
  );
};
