import { SpinnerIndicators } from '@clearkit/icons';
import classnames from 'classnames';
import { CKContainerProps } from 'clearkit';
import { FC, ReactNode } from 'react';

const containerClasses = 'flex flex-col flex-auto min-h-0 min-w-0';

const Loading = () => {
  return (
    <div className={`${containerClasses} items-center justify-center h-48`}>
      <SpinnerIndicators className="w-12 h-12" />
    </div>
  );
};

const Empty = ({ children }: CKContainerProps) => {
  return (
    <div
      className={`${containerClasses} h-24 text-base items-center justify-center bg-gray-0 text-gray-400`}
    >
      {children}
    </div>
  );
};

const Wrap = ({ className, ...props }: CKContainerProps) => {
  return <div {...props} className={classnames(containerClasses, className)} />;
};

type LoadingAreaProps = CKContainerProps & {
  emptyBody?: ReactNode;
  empty?: boolean;
  loading?: boolean;
};

const LoadingArea: FC<LoadingAreaProps> = ({
  children,
  empty,
  emptyBody = 'Nothing to show',
  loading,
  ...props
}) => {
  if (loading) {
    return (
      <Wrap {...props}>
        <Loading />
      </Wrap>
    );
  } else if (empty) {
    return (
      <Wrap {...props}>
        <Empty>{emptyBody}</Empty>
      </Wrap>
    );
  }

  return (
    <Wrap {...props}>
      <div className={containerClasses}>{children}</div>
    </Wrap>
  );
};

export default LoadingArea;
