import { HubspotServices as Hubspot } from '@clearkit/icons';
import { CKButton, CKLayoutHorizontal } from 'clearkit';
import { useState } from 'react';

import { AuthBox, AuthBoxProps } from '../AuthBox';

type SignInWithHubspotProps = AuthBoxProps & { buttonText?: string };

export function SignInWithHubspot({
  buttonText = 'Sign in with HubSpot',
  className,
  ...boxProps
}: SignInWithHubspotProps) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <AuthBox className={className} {...boxProps}>
      <CKButton
        as={'a'}
        href="/auth/oauth/hubspot/start"
        isLoading={isLoading}
        onClick={() => setIsLoading(true)}
      >
        <CKLayoutHorizontal
          as="span"
          className="p-2"
          gap=".5rem"
          justifyContent="center"
        >
          <span>{buttonText}</span>
          <Hubspot className="w-4 h-4" />
        </CKLayoutHorizontal>
      </CKButton>
    </AuthBox>
  );
}
