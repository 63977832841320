import { CKButton } from 'clearkit';
import { Link } from 'react-router-dom';

import { routes } from '~/components/app/appMainRoutes';
import { EMPTY_LABEL } from '~/lib/constants';

import { CreditUsageTile } from '..';
import { CreditUsageProductType, meteredUnit } from '../helpers';

type CreditUsageTileProps = {
  product: CreditUsageProductType;
  modifier: number;
  usage: { uniques: number; cumulative: number };
  planId?: string;
};

const creditUsageTilesWithDetailsLink = ['platform prospector'];
export function MetricSubsetCreditUsageTile({
  product,
  modifier,
  usage,
  planId,
}: CreditUsageTileProps) {
  return (
    <CreditUsageTile
      cta={
        creditUsageTilesWithDetailsLink.includes(
          product.toLocaleLowerCase(),
        ) ? (
          <CKButton
            as={Link}
            to={`${
              routes.usage
            }/${planId}_${product.toLocaleLowerCase().replace(' ', '_')}`}
          >
            Details
          </CKButton>
        ) : null
      }
      description={description(product, modifier)}
      title={Title(product, usage)}
    />
  );
}

function Title(
  product: CreditUsageProductType,
  usage: { uniques: number; cumulative: number },
) {
  return `${usage?.uniques ? usage.uniques : EMPTY_LABEL} ${meteredUnit(
    product,
  )}`;
}

function description(product: CreditUsageProductType, modifier: number) {
  const creditWord = modifier > 1 ? 'credits' : 'credit';
  switch (product.toLocaleLowerCase()) {
    case 'exports':
      return `${modifier} ${creditWord} per unique record exported with Clearbit data and synced to a connected integration.`;
    case 'forms':
      return `${modifier} ${creditWord} per unique form filled and/or shortened with Clearbit data.`;
    case 'alerts':
      return `${modifier} ${creditWord} per alert sent.`;
    case 'crm enrichments':
      return `${modifier} ${creditWord} per unique enrichment.`;
    case 'platform prospector':
      return `${modifier} ${creditWord} per prospects exported.`;
    case 'contacts created':
      return `${modifier} ${creditWord} per contact created.`;
    case 'companies created':
      return `${modifier} ${creditWord} per company created.`;
    case 'prospector csv export':
      return `${modifier} ${creditWord} per contact exported.`;
    default:
      return '';
  }
}
