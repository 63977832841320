import Logo from '~/components/ConfigurationLogo';
import { Configuration } from '~/lib/configuration';

import ConnectionInterstitialContent from './ConnectionInterstitialContent';

export { default as ConnectionInterstitialContent } from './ConnectionInterstitialContent';

export type ConnectionInterstitialProps = {
  config: Configuration;
  children: React.ReactNode;
};

export default function ConnectionInterstitial({
  config,
  children,
}: ConnectionInterstitialProps) {
  return (
    <div className="flex flex-col items-center w-full h-full">
      <div className="flex flex-col items-center justify-center h-full max-w-5xl">
        <div className="flex items-center justify-center w-16 h-16 mb-6 border border-gray-200 border-dashed rounded">
          <Logo style={{ width: 40, height: 40 }} type={config} />
        </div>
        {children}
      </div>
    </div>
  );
}

ConnectionInterstitial.Content = ConnectionInterstitialContent;
