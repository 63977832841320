import { routes } from '~/components/app/appMainRoutes';
import { settingsRoutes } from '~/components/settings/settingsRoutes';
import { useEntitlements } from '~/hooks/account';

export const useCRMLabel = () => {
  const { hasAccessSettingsIntegrations } = useEntitlements();

  const crmLabel = hasAccessSettingsIntegrations ? 'HubSpot' : 'your CRM';
  const integrationsRoute = hasAccessSettingsIntegrations
    ? settingsRoutes.hubSpotIntegration
    : routes.integrations;

  return { crmLabel, integrationsRoute };
};
