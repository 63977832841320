import { dollars } from '~/lib/formatters';

import TwoLineMenuItem from './TwoLineMenuItem';

const SalesforceOpportunityListItem = ({
  salesforceOpportunity,
  salesforceAccountConnector,
}) => (
  <TwoLineMenuItem
    externalLinkClass="text-salesforce"
    href={`${salesforceAccountConnector.externalInstanceUrl}/${salesforceOpportunity.externalId}`}
    subtitle={
      <>
        {salesforceOpportunity.owner?.name || 'Unknown User'} &mdash;{' '}
        {dollars(salesforceOpportunity.amount)}
      </>
    }
    title={salesforceOpportunity.name}
  />
);

SalesforceOpportunityListItem.propTypes = {
  salesforceOpportunity: PropTypes.shape({
    opportunities: PropTypes.array,
    name: PropTypes.string,
    externalId: PropTypes.string,
    websiteDomain: PropTypes.string,
    amount: PropTypes.number,
    owner: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  salesforceAccountConnector: PropTypes.shape({
    externalInstanceUrl: PropTypes.string,
  }),
};

export default SalesforceOpportunityListItem;
