import { useMutation, useQuery } from '@apollo/client/react/hooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import capitalize from 'lodash/capitalize';
import groupBy from 'lodash/groupBy';
import { useEffect, useMemo, useRef, useState } from 'react';

import LoadingArea from '~/components/LoadingArea';
import ToastsContainer from '~/containers/Toasts';
import { usePollJobHistoryForStatus } from '~/lib/jobHistory/usePollJobHistoryForStatus';

import SelectiveEnrichmentConfig from '../SelectiveEnrichmentConfig';
import BackfillTriggerFields from './BackfillTriggerFields';
import BetaFieldMappingForm from './BetaFieldMappingForm';
import EnrichmentToggle from './EnrichmentToggle';
import RemoveMappingModal from './FieldMappingModals/RemoveMappingModal';
import fieldMappingQuery from './fieldMappingQuery.graphql';
import mutation from './mutation.graphql';
import query from './query.graphql';
import reexportProperties from './reexportProperties.graphql';
import { getServicesForIntegration, getSourceTypes } from './util';
const STUB_MAPPING = {
  targetField: null,
  sourceField: null,
  sourceType: null,
  sourceObjectType: null,
  /* eslint-disable */
  // joaomdmoura: DISCLAIMER
  // We ended up changing the way this feature works after we implemented it,
  // and that led to some weird naming issue
  //
  // Despite the name being conditionaOverwrite, in the interface we indicate the
  // oposite value of this (if it would hard overwrite data or not)
  // hardOverwrite = !conditionalOverwrite
  //
  // By default the conditonalOverwrite should be set to true, as data is not
  // overwritten by default, and that will show the hardOverwrite Switch selector
  // as disabled
  /* eslint-enable */
  conditionalOverwrite: true, // hardOverwrite = false
  dataType: null,
  createdAt: null,
};

const FieldMappingForm = ({ integration, objectType }) => {
  const [fieldMappings, setFieldMappings] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [openDisableModal, setOpenDisableModal] = useState(false);
  const [showInvalidFields, setShowInvalidFields] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [triggering, setTriggering] = useState(false);
  const [reexportJobId, setReexportJobId] = useState(null);
  const [reexportJobQueue, setReexportJobQueue] = useState(null);
  const [reexportJobQueuedAt, setReexportJobQueuedAt] = useState(null);
  const [enrichmentEnabled, setEnrichmentEnabled] = useState(false);

  const [
    refetchingPropertiesCompleted,
    setRefetchingPropertiesCompleted,
  ] = useState(false);
  // Using ref to get access to current state within the waitForPropertyRefresh callback
  const reexportRef = useRef();
  reexportRef.current = refetchingPropertiesCompleted;

  const {
    data,
    data: { accountConnector = {}, sourceAttributes, targetAttributes } = {},
    loading,
    refetch,
  } = useQuery(query, {
    fetchPolicy: 'no-cache',
    variables: {
      attributeType: capitalize(objectType.type),
      refreshAttributes: false,
      service: getServicesForIntegration(integration),
      sourceTypes: getSourceTypes(integration, objectType),
      subCategory: objectType.name,
      targetObjectType: capitalize(objectType.name),
      targetTypes: [`${integration}_${objectType.name}`],
      externalObjectType: objectType.name,
    },
  });

  const [
    accountConnectorBackfillActive,
    setAccountConnectorBackfillActive,
  ] = useState(accountConnector?.backfillActive || false);

  const {
    data: { fieldMapping: savedFieldMapping } = {},
    loading: fieldMappingLoading,
  } = useQuery(fieldMappingQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      accountConnectorId: accountConnector.id,
      targetObjectType: capitalize(objectType.name),
    },
    onCompleted: (data) => {
      // if you have saved field mappings for one object type and navigate to
      // a tab without field mappings, then the saved mappings from the previous
      // tab will persist, so we need to reset the mapping state if there is
      // no saved field mappings in the navigated object type
      setFieldMappings(data.fieldMapping?.fieldMappings || []);
      setEnrichmentEnabled(!!data.fieldMapping?.enabled);
    },
    skip: !accountConnector.id,
  });

  const hasSavedSelectiveEnrichmentConfig = !!accountConnector
    ?.selectiveEnrichmentFilters?.filters;
  const hasSavedFieldMappings = !!savedFieldMapping?.fieldMappings.length;

  const input = useMemo(
    () => ({
      id: accountConnector?.id,
      targetObjectType: capitalize(objectType.name),
      fieldMappings,
    }),
    [accountConnector, fieldMappings, objectType],
  );

  const [triggerReexportProperties] = useMutation(reexportProperties, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (rexportJobData) => {
      const reexportJobId = rexportJobData.reexportProperties.node.jobId;
      const reexportJobQueue = rexportJobData.reexportProperties.node.queue;
      setReexportJobId(reexportJobId);
      setReexportJobQueue(reexportJobQueue);
      setReexportJobQueuedAt(Date.now());
      ToastsContainer.addSuccess(
        'Refreshing Properties, this may take up to 30s to complete.',
      );
    },
    onError: (error) => {
      ToastsContainer.addError(
        `There was an error refreshing properties, please try again. ${error}`,
      );
    },
    variables: {
      input: { id: accountConnector.id },
    },
  });

  // onClickRefreshAttributes currently expects a callback that returns a promise so this is necessary in order
  // to get the refresh button state to properly reflect loading/complete state
  function waitForPropertyRefresh() {
    return new Promise((resolve) => {
      function checkPropertyRefreshStatus() {
        if (reexportRef.current) {
          resolve();
        } else {
          // Creating large set timeout to allow time for refetch of properties to complete before updating state
          window.setTimeout(checkPropertyRefreshStatus, 15000);
        }
      }
      checkPropertyRefreshStatus();
    });
  }
  const onClickRefreshAttributes = () => {
    if (integration === 'hubspot') {
      triggerReexportProperties();
      return waitForPropertyRefresh();
    } else {
      return refetch({ refreshAttributes: true });
    }
  };

  usePollJobHistoryForStatus({
    onCompleted: () => {
      refetch({ pollInterval: 500 });
      setReexportJobId(null);
      setReexportJobQueuedAt(null);
      setRefetchingPropertiesCompleted(true);
    },
    jobId: reexportJobId,
    queue: reexportJobQueue,
    minTimestamp: reexportJobQueuedAt,
    status: 'completed',
  });

  const [updateFieldMapping, { loading: isUpdating }] = useMutation(mutation, {
    onCompleted: (data) => {
      if (data.errors?.length) {
        ToastsContainer.addWarning(
          'An error occurred when saving field mappings, please try again.',
        );
      } else {
        ToastsContainer.addSuccess('Field mappings saved');
      }
    },
    onError: () => {
      ToastsContainer.addWarning(
        'An error occurred when saving field mappings, please try again.',
      );
    },
    refetchQueries: ['FieldMapping'],
    variables: {
      input,
    },
  });

  async function handleSave() {
    setIsSaving(true);
    await updateFieldMapping();
    setIsSaving(false);
  }

  async function disableFieldMappings() {
    const setEmptyFieldMappings = await updateFieldMapping({
      variables: { input: { ...input, fieldMappings: [] } },
    });

    const onComplete = (setEmptyFieldMappings) => {
      if (setEmptyFieldMappings) {
        setFieldMappings([]);
      }
      setOpenDisableModal(false);
    };

    onComplete(setEmptyFieldMappings);
  }

  useEffect(() => {
    (async () => {
      await refetch();
    })();
  }, [objectType]);

  const handleMappingChange = (index) => (changes) => {
    const newFieldMappings = [...fieldMappings];
    newFieldMappings[index] = {
      ...newFieldMappings[index],
      ...changes,
    };
    setFieldMappings(newFieldMappings);
  };

  const applyChangesToAllGroupMembers = (groupId) => (changes) => {
    const newFieldMappings = [...fieldMappings];
    const updatedMappings = newFieldMappings.reduce((result, mapping) => {
      if (mapping.groupId === groupId) {
        result.push({
          ...mapping,
          ...changes,
        });
      } else {
        result.push(mapping);
      }

      return result;
    }, []);
    setFieldMappings(updatedMappings);
  };

  const addMapping = (attributes = {}) => {
    attributes.createdAt = new Date().toISOString();

    setFieldMappings([...fieldMappings, { ...STUB_MAPPING, ...attributes }]);
  };

  const removeMapping = (indexToRemove) => () => {
    if (indexToRemove === 0 && fieldMappings.length === 1) {
      setOpenDisableModal(true);
    } else {
      setFieldMappings(fieldMappings.filter((_, i) => i !== indexToRemove));
    }
  };

  const removeMappingGroup = (groupId) => () => {
    // returns true if all mappings have the same groupId, meaning that there
    // is only a single grouping left in this field mapping so we prompt a modal
    const lastMappingCheck = fieldMappings.every((mapping) => {
      return mapping.groupId === groupId;
    });

    if (!lastMappingCheck) {
      setFieldMappings(
        fieldMappings.filter((mapping) => mapping.groupId !== groupId),
      );
    } else {
      setOpenDisableModal(true);
    }
  };

  const updateErrors = (key, value) => {
    const errors = fieldErrors;
    if (value === undefined) {
      delete errors[key];
      setFieldErrors(errors);
    } else if (errors[key] === undefined) {
      errors[key] = [value];
      setFieldErrors(errors);
    } else {
      errors[key].push(value);
      setFieldErrors(errors);
    }
  };

  const findDuplicates = (array) => {
    return array.filter((value, index, arry) => {
      if (arry.indexOf(value) !== index) return value;
    }, []);
  };

  const areFieldMappingsInvalid = useMemo(() => {
    const nullFields = fieldMappings
      .map((fm) => [fm.targetField, fm.sourceField])
      .flat()
      .includes(null);

    const targetMappings = fieldMappings.map((fm) => fm.targetField);
    const targetFieldDuplicates = findDuplicates(targetMappings);
    if (targetFieldDuplicates.length > 0) {
      targetFieldDuplicates.forEach((targetField) => {
        updateErrors(targetField);
        updateErrors(targetField, 'duplicate field');
      });
    } else {
      setFieldErrors({});
    }

    return nullFields || targetFieldDuplicates.length > 0;
  }, [fieldMappings]);

  if (loading && !data) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <CircularProgress />
      </div>
    );
  }

  const indexedMappings = fieldMappings.map((mapping, index) => {
    return { ...mapping, index };
  });

  const groupedMappings = groupBy(
    indexedMappings,
    (mapping) => mapping.groupId,
  );

  const sortedMappings = Object.keys(groupedMappings)
    .reduce((result, key) => {
      if (key === 'undefined') {
        return result.concat(groupedMappings[key]);
      }

      result.push({
        groupId: key,
        conditionalOverwrite: groupedMappings[key][0].conditionalOverwrite,
        createdAt: groupedMappings[key][0].createdAt,
        fieldMappings: groupedMappings[key],
      });
      return result;
    }, [])
    .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

  const onToggleChange = (enabled) => {
    setEnrichmentEnabled(enabled);
  };

  return (
    <LoadingArea
      className="flex justify-center w-full h-full"
      loading={loading || fieldMappingLoading}
    >
      <div className="mx-4">
        <BetaFieldMappingForm
          addMapping={addMapping}
          applyChangesToAllGroupMembers={applyChangesToAllGroupMembers}
          areFieldMappingsInvalid={areFieldMappingsInvalid}
          fieldErrors={fieldErrors}
          fieldMappings={fieldMappings}
          handleMappingChange={handleMappingChange}
          integration={integration}
          isSaving={isSaving}
          isUpdating={isUpdating}
          objectType={objectType}
          onClickRefreshAttributes={onClickRefreshAttributes}
          onSave={handleSave}
          removeMapping={removeMapping}
          removeMappingGroup={removeMappingGroup}
          savedFieldMappings={savedFieldMapping?.fieldMappings}
          setFieldMappings={setFieldMappings}
          setShowInvalidFields={setShowInvalidFields}
          showInvalidFields={showInvalidFields}
          sortedMappings={sortedMappings}
          sourceAttributes={sourceAttributes}
          targetAttributes={targetAttributes}
          triggering={triggering}
        />
      </div>

      <div className="mt-10">
        <SelectiveEnrichmentConfig
          accountConnector={accountConnector}
          integration={integration}
          objectType={objectType}
        />
      </div>

      <>
        <EnrichmentToggle
          accountConnector={accountConnector}
          hasSavedFieldMappings={hasSavedFieldMappings}
          hasSavedSelectiveEnrichmentConfig={hasSavedSelectiveEnrichmentConfig}
          integration={integration}
          isActive={enrichmentEnabled}
          loading={loading}
          objectType={objectType}
          onToggleChange={onToggleChange}
        />
        <BackfillTriggerFields
          accountConnector={accountConnector}
          accountConnectorBackfillActive={accountConnectorBackfillActive}
          enrichmentEnabled={enrichmentEnabled}
          hasSavedFieldMappings={hasSavedFieldMappings}
          hasSavedSelectiveEnrichmentConfig={hasSavedSelectiveEnrichmentConfig}
          integration={integration}
          loading={loading}
          objectType={objectType}
          setAccountConnectorBackfillActive={setAccountConnectorBackfillActive}
          setTriggering={setTriggering}
        />
      </>

      <RemoveMappingModal
        accountConnector={accountConnector}
        accountConnectorBackfillActive={accountConnectorBackfillActive}
        disableFieldMappings={disableFieldMappings}
        integration={integration}
        isActive={enrichmentEnabled}
        isUpdating={isUpdating}
        objectType={objectType}
        onToggleChange={onToggleChange}
        openDisableModal={openDisableModal}
        setOpenDisableModal={setOpenDisableModal}
      />
    </LoadingArea>
  );
};

export default FieldMappingForm;
