import { CKBox } from 'clearkit';
import get from 'lodash/get';

import ContentBox from '~/components/ContentBox';
import GraphqlError from '~/components/GraphqlError';
import SegmentLayout from '~/components/layouts/SegmentLayout';
import LoadingArea from '~/components/LoadingArea';
import MissingPage from '~/components/MissingPage';
import { useFancyQuery } from '~/lib/graphql';
import { paginateData } from '~/lib/graphql/pagination';
import { readableSingularSegmentType, segmentTypes } from '~/lib/segments';

import Audience from './Audience';
import Destination from './Destination';
import PlatformPlanDestinations from './PlatformPlanDestinations';
import query from './query.graphql';
import styles from './styles';

export function SegmentDestinationsPage({
  classes,
  data,
  error,
  refetch,
  loading,
}) {
  const { account, segment, slackAccountConnector } = data;

  // Loading state
  if (loading && !segment?.id) return <LoadingArea loading />;

  // Total counts
  const { destinationsCount = 0, destinations } = segment;
  const { audiences, audiencesTotalCount = 0 } = paginateData(segment, {
    fields: ['audiences'],
  });

  const destinationsAndAudiencesCount = destinationsCount + audiencesTotalCount;

  // Showing and hiding sections
  const showDestinations = destinationsAndAudiencesCount > 0;

  return (
    <div className={classes.root}>
      <h2 className={classes.heading}>Set up a new destination</h2>
      <p className={classes.infoText}>
        Automatically trigger an action when a{' '}
        {readableSingularSegmentType(segment.type)} matches an audience.{' '}
        <a
          className={classes.link}
          href="https://help.clearbit.com/hc/en-us/articles/360023392714"
          rel="noopener noreferrer"
          target="_blank"
        >
          Learn more
        </a>
      </p>
      <CKBox className="px-8 py-6 bg-gray-50">
        <GraphqlError error={error} inline refetch={refetch} />
        {showDestinations && (
          <>
            <ContentBox.Title>
              <h2 className={classes.heading}>Configured destinations</h2>
            </ContentBox.Title>

            {!destinations ? (
              new Array(destinationsAndAudiencesCount)
                .fill({})
                .map((_empty, index) => (
                  <Destination
                    className={classes.audienceItem}
                    destination={{}}
                    key={`loading-${index}`}
                    loading
                  />
                ))
            ) : (
              <>
                {audiences?.map((audience) => (
                  <Audience
                    audience={audience}
                    className={classes.audienceItem}
                    key={audience.id}
                  />
                ))}
                {destinations?.map((destination) => (
                  <Destination
                    className={classes.audienceItem}
                    destination={destination}
                    key={destination.id}
                    loading={!destination.config}
                    segment={segment}
                    slackAccountConnector={slackAccountConnector}
                  />
                ))}
              </>
            )}
            <ContentBox.Break className={classes.break} />
          </>
        )}

        <PlatformPlanDestinations account={account} segment={segment} />
      </CKBox>
    </div>
  );
}

const WrappedSegmentDestinationsPage = (props) => {
  const graphqlProps = useFancyQuery(query, {
    returnPartialData: true,
    variables: { segmentId: props.segmentId, segmentType: props.segmentType },
    fields: ['audiences'],
  });

  const {
    data: { segment },
    loading,
  } = graphqlProps;

  if (!loading && !segment?.id) {
    return <MissingPage />;
  }

  if (loading && !segment) return <LoadingArea />;

  return (
    <SegmentLayout
      resultsCount={get(segment, 'resultsCount')}
      segment={segment}
      tab="destinations"
    >
      <SegmentDestinationsPage {...graphqlProps} {...props} />
    </SegmentLayout>
  );
};

WrappedSegmentDestinationsPage.propTypes = {
  segmentId: PropTypes.string,
  segmentType: PropTypes.string,
};
SegmentDestinationsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.shape({
    account: PropTypes.shape({
      plans: PropTypes.arrayOf(PropTypes.string),
    }),
    audiences: PropTypes.array,
    audiencesTotalCount: PropTypes.number,
    slackAccountConnector: PropTypes.shape({
      remoteMeta: PropTypes.shape({
        users: PropTypes.array,
        channels: PropTypes.array,
      }),
    }),
    segment: PropTypes.shape({
      id: PropTypes.string,
      audiences: PropTypes.object,
      destinations: PropTypes.array,
      destinationsCount: PropTypes.number,
      type: PropTypes.oneOf([segmentTypes.company, segmentTypes.person]),
    }),
  }),
  error: PropTypes.object,
  refetch: PropTypes.func,
  loading: PropTypes.bool,
  segmentId: PropTypes.string,
  segmentType: PropTypes.string,
};
export default withStyles(styles)(WrappedSegmentDestinationsPage);
