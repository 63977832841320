import { EMPTY_STATE_SEARCH } from '~/components/SearchBuilder/utils/constants';

import ConfigureGraphic from '../ConfigureROIMetricsGraphic';
import ConfigureROIMetricsStep from '../ConfigureROIMetricsStep';

export const ConfigureROIMetrics = ({
  searchAttributes,
  leadsConfiguration,
  mqlConfiguration,
  onMqlChange,
  onLeadsChange,
}) => {
  const handleMqlEnabledChange = () => {
    onMqlChange({
      ...mqlConfiguration,
      enabled: !mqlConfiguration.enabled,
    });
  };

  const handleMqlSearchChange = (newSearch) => {
    onMqlChange({
      ...mqlConfiguration,
      search: newSearch,
    });
  };

  const handleLeadsEnabledChange = () => {
    onLeadsChange({
      ...leadsConfiguration,
      enabled: !leadsConfiguration.enabled,
    });
  };

  const handleLeadsSearchChange = (newSearch) => {
    onLeadsChange({
      ...leadsConfiguration,
      search: newSearch,
    });
  };

  return (
    <div>
      <div className="flex items-center px-4 py-8 bg-white border-b border-gray-200 space-x-8">
        <div className="flex-shrink-0">
          <ConfigureGraphic />
        </div>
        <div className="flex-1">
          <p>
            You can define up to{' '}
            <span className="font-medium">two additional stages</span> in the
            audiences funnel to get a more complete story around your audience
            data. The two stages are{' '}
            <span className="font-medium text-indigo">Leads</span> and{' '}
            <span className="font-medium text-indigo">MQLs</span> (Marketing
            Qualified Leads) — Clearbit just needs to know how you define these
            in Salesforce so we can display them.
          </p>
        </div>
      </div>
      <div className="p-6">
        <div className="space-y-12">
          <section id="step-leads">
            <div className="space-y-6">
              <header className="text-gray-700 space-y-1">
                <h2 className="font-medium">Define leads</h2>
                <p>
                  Build the query you use to define{' '}
                  <span className="font-medium">leads</span> for your metrics
                </p>
              </header>
              <ConfigureROIMetricsStep
                attributes={searchAttributes}
                enabled={leadsConfiguration.enabled}
                id="leads"
                onSearchChange={handleLeadsSearchChange}
                onToggle={handleLeadsEnabledChange}
                search={leadsConfiguration.search || EMPTY_STATE_SEARCH}
              />
            </div>
          </section>
          <section id="step-mqls">
            <div className="space-y-6">
              <header className="text-gray-700 space-y-1">
                <h2 className="font-medium">
                  Define MQLs (Marketing Qualified Leads)
                </h2>
                <p>
                  Build the query you use to define{' '}
                  <span className="font-medium">MQLs</span> for your metrics
                </p>
              </header>
              <ConfigureROIMetricsStep
                attributes={searchAttributes}
                enabled={mqlConfiguration.enabled}
                id="mqls"
                onSearchChange={handleMqlSearchChange}
                onToggle={handleMqlEnabledChange}
                search={mqlConfiguration.search || EMPTY_STATE_SEARCH}
              />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ConfigureROIMetrics;
