import { CKSkeleton } from 'clearkit';

import { PageLayout } from '~/components/PageLayout';

import { PageWrapper } from './PageWrapper';
import { LoadingList } from './ProspectList/LoadingList';
import { ProspectorSidebar } from './Sidebar/ProspectorSidebar';

export function Loading() {
  return (
    <>
      <ProspectorSidebar />
      <PageWrapper>
        <PageLayout.Body className="!overflow-hidden">
          <PageLayout.Content className="h-full space-y-4">
            <CKSkeleton className="rounded h-[4.125rem]" isLoading />
            <LoadingList />
          </PageLayout.Content>
        </PageLayout.Body>
        <PageLayout.Footer>
          <div className="h-8" />
        </PageLayout.Footer>
      </PageWrapper>
    </>
  );
}
