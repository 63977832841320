import { InfoHollow } from '@clearkit/icons';
import classnames from 'classnames';
import {
  CKBox,
  CKChartLineGradient,
  CKContainerProps,
  CKLayoutHorizontal,
  CKSkeleton,
  CKTooltip,
  includeChildrenByDisplayName,
} from 'clearkit';
import moment from 'moment';
import { FC } from 'react';

import { EMPTY_LABEL } from '~/lib/constants';
const tooltipDisplayName = 'HomeDataOverviewCard.Tooltip';

type ChartData = {
  id: string;
  data: {
    x: string;
    y: number;
  }[];
};

type MetricDate = {
  [date: string]: number;
};

export type MetricsTimeSeries = MetricDate[];

export const formatMetricsTimeSeries = (
  metrics: Record<string, number>,
  period: number,
): MetricsTimeSeries => {
  const usageArray: MetricsTimeSeries = [];
  for (let i = period; i > 0; i--) {
    const date = moment()
      .subtract(i, 'days')
      .format('YYYY-MM-DD');

    const usage = metrics[date] || 0;
    usageArray.push({ [date]: usage });
  }

  return usageArray;
};

type HomeDataOverviewCardProps = CKContainerProps & {
  id: 'companies' | 'people' | 'visitors';
  isDisabled?: boolean;
  isLoading: boolean;
  overviewData?: MetricsTimeSeries;
  overviewTotal?: number;
  shouldShowData?: boolean;
  title: string;
  variant: 'purple' | 'green' | 'blue';
};

type TooltipProps = CKContainerProps & {
  isDisabled?: boolean;
};

type HomeDataOverviewCardComposition = {
  Tooltip: FC<TooltipProps>;
};

export const HomeDataOverviewCard: FC<HomeDataOverviewCardProps> &
  HomeDataOverviewCardComposition = ({
  children,
  id,
  isDisabled = false,
  isLoading,
  shouldShowData,
  title,
  variant,
  overviewData,
  overviewTotal,
}) => {
  const chartColor = {
    blue: 'dataVizA',
    purple: 'dataVizD',
    green: 'dataVizF',
  };

  const chartData = overviewData?.length
    ? ([
        {
          id,
          data: overviewData.map((datum) => {
            return {
              x: Object.keys(datum)[0],
              y: Object.values(datum)[0],
            };
          }),
        },
      ] as ChartData[])
    : [];

  const sum = overviewData?.length
    ? chartData[0].data
        .map((item) => item.y)
        .reduce((prev, next) => prev + next)
    : 0;
  const total = overviewTotal ? overviewTotal : sum;
  const hasOverviewData = overviewData?.length && overviewData?.length > 0;

  return (
    <CKSkeleton className="h-40" isLoading={isLoading} label="loading">
      <CKBox
        className={classnames(
          'relative h-40 overflow-hidden grid grid-rows-auto-1fr',
          {
            'opacity-30': isDisabled,
          },
        )}
      >
        <CKLayoutHorizontal
          alignItems="start"
          as="header"
          className="p-6 pb-0"
          wrap="nowrap"
        >
          <div>
            <h3 className="text-sm font-medium">{title}</h3>
            <h4
              className={classnames('font-semibold', {
                'text-[2rem]': !hasOverviewData,
                ' text-[1.5rem]': hasOverviewData,
              })}
            >
              {shouldShowData && !isDisabled
                ? total.toLocaleString('en-us')
                : EMPTY_LABEL}
            </h4>
          </div>
          {includeChildrenByDisplayName({
            children,
            componentDisplayName: tooltipDisplayName,
            newProps: { isDisabled },
          })}
        </CKLayoutHorizontal>
        <div className="relative pl-4">
          {hasOverviewData && total > 0 ? (
            <CKChartLineGradient
              animate={false}
              axisBottom={null}
              axisLeft={null}
              chartColor={chartColor[variant] as any}
              className="h-full"
              data={chartData}
              enableGridX={false}
              enablePoints={false}
              hasTooltip={false}
              margin={{ top: 12, right: 0, bottom: 0, left: 0 }}
              yScale={{
                type: 'linear',
                stacked: false,
                reverse: false,
              }}
            />
          ) : null}
          <span
            className={`absolute bottom-0 block w-24 left-4 bg-gradient-to-r from-white to-transparent h-full`}
          />
        </div>
      </CKBox>
    </CKSkeleton>
  );
};

HomeDataOverviewCard.Tooltip = ({ children, isDisabled }) => {
  if (isDisabled) return <InfoHollow />;

  return (
    <CKTooltip tooltip={children}>
      <InfoHollow />
    </CKTooltip>
  );
};
HomeDataOverviewCard.Tooltip.displayName = tooltipDisplayName;
