
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SegmentLayout"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Segment"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"resultsCount"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"collectionId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"search"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"enabledDestinationsCount"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"legacyType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"type"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"connectorErrors"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"search"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"destinations"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"},"arguments":[],"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SegmentTabs"},"directives":[]}]}}],"loc":{"start":0,"end":271}};
    doc.loc.source = {"body":"#import \"~/components/SegmentTabs/fragments.graphql\"\n\nfragment SegmentLayout on Segment {\n  id\n  name\n  resultsCount\n  collectionId\n  search\n  enabledDestinationsCount\n  legacyType\n  type\n  connectorErrors {\n    search\n    destinations\n  }\n  updatedAt\n  ...SegmentTabs\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("~/components/SegmentTabs/fragments.graphql").definitions));


      module.exports = doc;
    
