import fetchWithTimeout from './fetchWithTimeout';

const isLocalhost = window.location.hostname.includes('localhost');

export const trackBackendEvent = (event: string, params = {}) => {
  if (isLocalhost) {
    // eslint-disable-next-line no-console
    console.log('TRACK BACKEND', event, params);
  }

  return fetchWithTimeout('/v1/event', {
    body: JSON.stringify({
      event,
      properties: params,
    }),
    method: 'POST',
    timeout: 10000,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    credentials: 'include',
    referrerPolicy: 'no-referrer',
    referrer: '',
  });
};

export const trackEvent = (
  event: string,
  params?: Record<string, any>,
  trackBackend = false,
) => {
  if (isLocalhost) {
    // eslint-disable-next-line no-console
    console.log('TRACK', event, params);
  }

  const trackResult = window?.analytics?.track(
    trackBackend ? `client_${event}` : event,
    params,
  );

  // Note: X backend and frontend are using different
  // Segment Write keys, so they are tracked in
  // separate sources in Segment / Redshift
  if (trackBackend) {
    return trackBackendEvent(event, params) as Promise<Response>;
  }

  return trackResult as void;
};

export const identify = (userId: string, traits = {}) => {
  window?.analytics?.identify(userId, traits);

  if (isLocalhost) {
    // eslint-disable-next-line no-console
    console.log('IDENTIFY', userId, traits);
  }
};

const defaultExport = {};
export default defaultExport;
