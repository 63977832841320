import GraphqlError from '~/components/GraphqlError';
import EventsList from '~/components/Sidebar/RedesignedEventsList';

import { usePersonEvents } from './usePersonEvents';

type PersonEventsListProps = {
  person: any;
  type?: string;
  onTypeChange(newType: string): void;
  className?: string;
};
export function PersonEventsList({
  person,
  type,
  onTypeChange,
  className,
}: PersonEventsListProps) {
  const {
    error,
    loading,
    data,
    refetch,
    fetchMore,
    loadingFirstPage,
  } = usePersonEvents({ person, type });

  const { eventsHasNextPage, events } = data;

  if (error) {
    return <GraphqlError error={error} loading={loading} refetch={refetch} />;
  }

  return (
    <EventsList
      className={className}
      events={events}
      hasNextPage={eventsHasNextPage}
      id={person.id}
      loading={loadingFirstPage}
      loadingMore={loading}
      onFilter={onTypeChange}
      onLoadMore={fetchMore}
      type={type}
    />
  );
}
