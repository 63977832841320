import moment from 'moment';

import { UsageBreakdownGranularityEnum } from '~/generated/graphql';
import { EMPTY_LABEL } from '~/lib/constants';

export const backendDateFormat = 'YYYY-MM-DD';

export function xAxisFormatter(value: string | number) {
  return moment(value, backendDateFormat).format('MM/DD');
}

export function yAxisFormatter(value: string | number) {
  const formatter = Intl.NumberFormat('en', { notation: 'compact' });
  return formatter.format(value as number);
}

export function tooltipTitleFormatter(
  value: string | number,
  granularity = UsageBreakdownGranularityEnum.Month,
) {
  if (granularity === UsageBreakdownGranularityEnum.Day) {
    return moment(value, backendDateFormat).format('LL');
  }

  return `${moment(value, backendDateFormat)
    .add(1, 'day')
    .add(-1, granularity)
    .format('LL')} to ${moment(value, backendDateFormat).format('LL')}`;
}

export function CreditBasedUsageOverviewDataFormatter(value: string | number) {
  return moment(value, backendDateFormat).format('LL');
}

export const formatAxisDate = (date: string) => {
  const dateObj = new Date(date);
  return dateObj.toLocaleDateString('en-US', {
    month: 'numeric',
    day: 'numeric',
  });
};

export const formatNumberCommas = (
  value?: number | string | null | undefined,
) => {
  let numberValue: number | undefined;

  if (typeof value === 'string') {
    numberValue = parseInt(value, 10);
  } else if (typeof value === 'number') {
    numberValue = value;
  } else {
    numberValue = undefined;
  }

  return numberValue ? numberValue.toLocaleString('en-US') : EMPTY_LABEL;
};
