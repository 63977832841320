import { SpinnerIndicators } from '@clearkit/icons';
import classnames from 'classnames';
import {
  CKContainerProps,
  CKLayoutGrid,
  CKLayoutHorizontal,
  excludeChildrenByDisplayName,
  includeChildrenByDisplayName,
} from 'clearkit';
import { FC } from 'react';

import { ProspectListExport } from '~/generated/graphql';

import { ExportItem } from './ExportItem';

type ExportsListProps = CKContainerProps & {
  isLoading?: boolean;
  items?: ProspectListExport[];
};

type ExportsListComposition = {
  Title: FC<CKContainerProps>;
  Description: FC<CKContainerProps>;
  EmptyState: FC<CKContainerProps>;
};

const emptyStateComponentDisplayName = 'ExportsList.EmptyState';

type NoItemsProps = CKContainerProps & {
  isLoading?: boolean;
};

const NoItems = ({ children, isLoading }: NoItemsProps) => {
  return isLoading ? (
    <CKLayoutHorizontal className="w-full h-full" justifyContent="center">
      <SpinnerIndicators height={40} width={40} />
    </CKLayoutHorizontal>
  ) : (
    <>{children}</>
  );
};

export const ExportsList: FC<ExportsListProps> & ExportsListComposition = ({
  children,
  isLoading,
  items,
  ...rest
}) => {
  const hasItems = items && items.length > 0 && !isLoading;

  return (
    <CKLayoutGrid
      {...rest}
      className="min-h-full"
      gridTemplateRows={hasItems ? 'auto 1fr' : '1fr'}
    >
      {hasItems ? (
        <>
          <header className="px-8 py-10">
            {excludeChildrenByDisplayName({
              children,
              componentDisplayName: emptyStateComponentDisplayName,
            })}
          </header>
          <div>
            <div className="bg-white border-gray-200 border-y divide-y divide-gray-200">
              {items?.map((exportItem) => (
                <ExportItem exportItem={exportItem} key={exportItem.id} />
              ))}
            </div>
          </div>
        </>
      ) : (
        <NoItems isLoading={isLoading}>
          {includeChildrenByDisplayName({
            children,
            componentDisplayName: emptyStateComponentDisplayName,
          })}
        </NoItems>
      )}
    </CKLayoutGrid>
  );
};

ExportsList.Title = ({ children, ...rest }) => (
  <h2 className="mb-4 text-lg font-semibold" {...rest}>
    {children}
  </h2>
);
ExportsList.Title.displayName = 'ExportsList.Title';

ExportsList.Description = ({ children, ...rest }) => (
  <div {...rest}>{children}</div>
);
ExportsList.Description.displayName = 'ExportsList.Description';

ExportsList.EmptyState = ({ children, className, ...rest }) => (
  <div {...rest} className={classnames('min-h-full', className)}>
    {children}
  </div>
);
ExportsList.EmptyState.displayName = emptyStateComponentDisplayName;
