import { routes } from '~/components/app/appMainRoutes';

import { useMainICP } from './useMainICP';

export const useTargetMarketsRoute = () => {
  const { firstTargetMarketId, mainICPId } = useMainICP();

  const targetMarketsRoute = mainICPId
    ? `${routes.targetMarkets}/list/${mainICPId}`
    : firstTargetMarketId
    ? `${routes.targetMarkets}/list/${firstTargetMarketId}`
    : routes.targetMarkets;

  return {
    targetMarketsRoute,
  };
};
