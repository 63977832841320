import { CKChartLineTooltipProps, CKLayoutGrid } from 'clearkit';
import { capitalize } from 'lodash';

import {
  CreditUsageProductType,
  meteredUnit,
} from '../../CreditUsageTile/helpers';
import { usageMetricGraphCollectionType } from '../MultiSeriesCumulativeUsageChart';

type CreditBreakdownChartTooltipProps = {
  usageMetricGraphCollection: usageMetricGraphCollectionType;
} & CKChartLineTooltipProps;

export function CreditBreakdownChartTooltip({
  title,
  data,
  usageMetricGraphCollection,
}: CreditBreakdownChartTooltipProps) {
  return (
    <div className="p-4 text-sm bg-white border border-gray-200 rounded-sm">
      <h3 className="mb-2 font-medium">{title}</h3>
      <CKLayoutGrid
        gapColumn="0.8rem"
        gapRow="0.5rem"
        gridTemplateColumns="1fr auto auto"
      >
        {data.points.map((point) => (
          <CreditBreakdownChartTooltipItem
            key={`tooltip-item-${point.serieId}`}
            serieId={point.serieId as string}
            usageMetricGraphCollection={usageMetricGraphCollection}
            value={point.data.y as number}
          />
        ))}
      </CKLayoutGrid>
      <div className="flex justify-between mt-8 mb-2 font-medium gap-2 min-w-64">
        <span className="flex gap-2">
          <span>Total credits used</span>
        </span>
        <div>
          <span>
            {data.points.reduce(
              (partialSum: any, a: { data: { y: any } }) =>
                partialSum + a.data.y,
              0,
            )}
          </span>
        </div>
      </div>
    </div>
  );
}

type CreditBreakdownChartTooltipItemProps = {
  serieId: string;
  value: number;
  usageMetricGraphCollection: usageMetricGraphCollectionType;
};
function CreditBreakdownChartTooltipItem({
  serieId,
  value,
  usageMetricGraphCollection,
}: CreditBreakdownChartTooltipItemProps) {
  const multiplier = (usageMetricGraphCollection[
    (serieId as string).toLocaleLowerCase()
  ] as { modifier: number })?.modifier;
  return (
    <>
      <span>
        <span>
          {capitalize(
            meteredUnit(serieId.toLocaleLowerCase() as CreditUsageProductType),
          )}
        </span>
      </span>
      <span className="text-right">{value / multiplier}</span>
      <span className="text-right">{value} credits</span>
    </>
  );
}
