import { useQuery } from '@apollo/client/react/hooks';

import query from './query.graphql';

export const usePollJobHistoryForStatus = ({
  onCompleted,
  jobId,
  queue,
  minTimestamp,
  status,
}) => {
  const { stopPolling: stopJobHistoryPolling } = useQuery(query, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted(jobHistoryData) {
      const jobHistory = jobHistoryData?.jobHistory;
      if (
        jobHistory &&
        jobHistory?.status == status &&
        new Date(jobHistory.timestamp) > new Date(minTimestamp)
      ) {
        onCompleted();
        stopJobHistoryPolling();
      }
    },
    skip: !jobId || !queue,
    pollInterval: 1000,
    variables: {
      jobId,
      queue,
    },
  });
};
