import { components } from 'react-select';

import { matchValue } from '../MenuList/checkboxLogic';
import styles from './styles.js';

const Input = ({ ...props }) => {
  const {
    value,
    selectProps: {
      suggestions,
      menuIsOpen,
      inputValue,
      activeSuggestion,
      getOptionLabel,
    },
  } = props;

  const suggestion = suggestions[activeSuggestion];
  const match = matchValue(suggestion ? getOptionLabel(suggestion) : '', value);

  return (
    <div style={styles.input}>
      <components.Input {...props} />
      {menuIsOpen &&
        inputValue.length !== 0 &&
        suggestions.length !== 0 &&
        match && (
          <span style={styles.remainder}>
            {getOptionLabel(suggestion).slice(match.index + match[0].length)}
          </span>
        )}
    </div>
  );
};

Input.propTypes = {
  value: PropTypes.string,
  selectProps: PropTypes.shape({
    suggestions: PropTypes.array.isRequired,
    menuIsOpen: PropTypes.bool.isRequired,
    inputValue: PropTypes.string.isRequired,
    activeSuggestion: PropTypes.number.isRequired,
    getOptionLabel: PropTypes.func.isRequired,
  }).isRequired,
};

export default Input;
