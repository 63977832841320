/* eslint-disable import/no-named-as-default */
import { ApolloClient, ApolloLink, createHttpLink } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/browser';

import { authUser } from '~/lib/client';

const cache = new InMemoryCache({
  typePolicies: {
    Attribute: {
      keyFields: ['attributeType', 'category', 'subCategory', 'value'],
    },
    EnrichmentAttribute: {
      keyFields: ['value', 'identifier'],
    },
    Query: {
      fields: {
        company: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'Company',
              id: args?.id,
            });
          },
        },
        audience: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'Audience',
              id: args?.id,
            });
          },
        },
        enrichmentLog: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'EnrichmentLog',
              id: args?.id,
            });
          },
        },
      },
    },
  },
  possibleTypes: {
    Collection: ['CompanySegmentCollection', 'PersonSegmentCollection'],
    Node: [
      'Company',
      'CompanySegment',
      'CompanySegmentCollection',
      'Person',
      'PersonSegment',
      'PersonSegmentCollection',
    ],
    Segment: ['CompanySegment', 'PersonSegment'],
    AccountConnector: [
      'FacebookAccountConnector',
      'G2AccountConnector',
      'GoogleAccountConnector',
      'HubspotAccountConnector',
      'MarketoAccountConnector',
      'SalesforceAccountConnector',
      'SlackAccountConnector',
    ],
  },
});

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach((error) => {
          const { message, locations, path } = error;
          /* eslint-disable-next-line no-console */
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          );

          Sentry.captureException(error);
        });
      }
      if (networkError) {
        if (
          'statusCode' in networkError &&
          networkError.statusCode === 401 &&
          'result' in networkError &&
          networkError?.result?.error?.type === 'auth'
        ) {
          authUser(networkError.result.error);
        }
        /* eslint-disable-next-line no-console */
        console.error(`[Network error]: ${networkError}`);
      }
    }),
    createHttpLink({
      uri: (query) => {
        return `/graphql?${query.operationName}`;
      },
    }),
  ]),
});

export default client;
