import request from './request';

// Companies Columns

export function updateCompanyColumns({ columns, segmentId }) {
  const path = segmentId
    ? `/v1/companies/segments/${segmentId}/columns`
    : `/v1/companies/columns`;

  return request({
    path,
    type: 'put',
    json: { columns },
  });
}
