const styles = {
  input: {
    display: 'flex',
    flexDirection: 'row',
  },

  inputField: {
    fontSize: 15,
  },

  remainder: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    right: 3,
    marginLeft: -1,
    color: '#9BADBC',
    whiteSpace: 'pre-wrap',
    fontSize: 15,
  },
};

export default styles;
