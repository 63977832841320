import classnames from 'classnames';
import { CKBadge, CKContainerProps } from 'clearkit';

type ListItemNumberProps = CKContainerProps;

export const ListItemNumber = ({
  children,
  className,
}: ListItemNumberProps) => {
  return (
    <div className={classnames('w-[2.5rem] shrink-0', className)}>
      <CKBadge className="text-sm bg-black">#{children}</CKBadge>
    </div>
  );
};
