import { CKButton, CKCompanyLogo, CKLayoutHorizontal } from 'clearkit';
import { useState } from 'react';

import { AuthLayoutHeader } from '~/components/layouts/AuthLayout';

import { AuthBox } from '../../AuthBox';
import { DiscoveredAccount } from '../types';

type ListOrganizationsProps = {
  accounts: DiscoveredAccount[];
};

export default function ListOrganizations({
  accounts,
}: ListOrganizationsProps) {
  const [selectedAccountId, setSelectedAccountId] = useState<
    string | undefined
  >();

  const sortedAccounts = [...accounts].sort((a, b) =>
    a.account.name.localeCompare(b.account.name),
  );

  return (
    <>
      <AuthLayoutHeader
        description="Pick an account to continue."
        header={
          <div className="w-full font-semibold text-center">
            Choose your Clearbit account
          </div>
        }
      />
      <AuthBox>
        <ul className="flex flex-col gap-6">
          {sortedAccounts.map((discovered, idx) => (
            <CKLayoutHorizontal as="li" key={idx}>
              <span>
                <CKCompanyLogo
                  size={24}
                  src={`https://logo.clearbit.com/${discovered.account.domain}`}
                />
              </span>
              <span className="flex-grow text-base font-medium text-left">
                {discovered.account.name}
              </span>

              <form
                action={`/auth/login/${discovered.account.id}`}
                method="POST"
                onSubmit={() => {
                  setSelectedAccountId(discovered.account.id);
                }}
              >
                <CKButton
                  isLoading={discovered.account.id === selectedAccountId}
                  type="submit"
                  variant="tertiary"
                  variantColor="blue"
                >
                  {actionLabel(discovered)}
                </CKButton>
              </form>
            </CKLayoutHorizontal>
          ))}
        </ul>
      </AuthBox>
    </>
  );
}

function actionLabel(discovered: DiscoveredAccount) {
  switch (discovered.membership_type) {
    case 'invited_member':
      return 'Accept invite & sign in';
    case 'active_member':
      return 'Sign in';
    case 'pending_member':
      return 'Join & sign in';
    case 'eligible_to_join_by_email_domain':
      return 'Join & sign in';
    default:
      return 'Sign in';
  }
}
