import { ZapAlt } from '@clearkit/icons';
import { CKIconBox, CKLayoutVertical } from 'clearkit';

import { OnboardingCard } from '~/components/HubspotEnrichment/Onboarding/OnboardingCard';
import { UpgradeAccountFormButton } from '~/components/UpgradeAccountFormButton';

import { PageLayout } from '../PageLayout';

export const NoAccessContactCTA = () => {
  return (
    <PageLayout.Body className="flex items-center justify-center w-full p-8">
      <OnboardingCard className="p-10 space-y-10">
        <header className="text-center space-y-4">
          <CKIconBox>
            <ZapAlt className="w-4 h-4 fill-gradient-br-blue" />
          </CKIconBox>
          <h1 className="m-auto font-medium max-w-[28ch]">
            Contact us to get access to Forms
          </h1>
          <div className="m-auto text-sm text-gray-500 max-w-[38ch]">
            Use Clearbit Forms to fill in the blanks and dynamically show form
            fields for improved lead capture and form conversion. Your team does
            not currently have access to Forms.
          </div>
        </header>
        <CKLayoutVertical alignItems="center">
          <UpgradeAccountFormButton
            preSelectedInterests={['forms']}
            variant="bold"
            variantColor="green"
          >
            Contact us
          </UpgradeAccountFormButton>
          <a href="https://help.clearbit.com/hc/en-us/articles/4420176895127-Getting-started-with-Clearbit-forms">
            Learn more
          </a>
        </CKLayoutVertical>
      </OnboardingCard>
    </PageLayout.Body>
  );
};
