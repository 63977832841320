import { DELIVERY_TARGET_LABELS } from '../dynamicDeliveryTargets';

export default function EmailDestinationDescription({ config }) {
  const { deliveryTarget: target, frequency, recipients = [] } = config;
  if (target === 'email' || !target) {
    const recipientsText =
      recipients.length > 1 ? `${recipients.length} recipients` : recipients;

    return (
      <>
        Will deliver <strong>{frequency}</strong> to{' '}
        <strong>{recipientsText}</strong>
      </>
    );
  } else {
    return (
      <>
        Will deliver <strong>{frequency}</strong> to{' '}
        <strong>{DELIVERY_TARGET_LABELS[target]}</strong>
      </>
    );
  }
}
