import { startCase, uniq } from 'lodash';

import { DEFAULT_COLUMNS } from '~/components/AttributeCategories';
import { legacySegmentType, segmentTypes } from '~/lib/segments';

import { configurations } from './configuration';
import {
  destinationTypeCustomHtml,
  destinationTypeEmail,
  destinationTypeFacebookConversion,
  destinationTypeGoogleConversion,
  destinationTypeSalesforce,
  destinationTypeSlack,
  destinationTypeWebhook,
} from './destinations';

function analyticsEnabled() {
  return window.analytics && window.analytics.user;
}

export function track(event, properties = {}) {
  if (!analyticsEnabled()) {
    return;
  }

  return currentUser().then(({ user }) =>
    window.analytics.track(event, {
      account_id: user.id,
      user_id: user.email,
      ...properties,
    }),
  );
}

export function identify(traits) {
  if (!analyticsEnabled()) {
    return;
  }

  return currentUser().then(({ user }) => {
    return window.analytics.identify(user.id, traits);
  });
}

export function group(traits) {
  if (!analyticsEnabled()) {
    return;
  }

  return currentUser().then(({ currentAccount }) => {
    const account = currentAccount();

    return window.analytics.group(account.id, traits);
  });
}

export const connectorLocations = {
  destination: 'destination',
  configuration: 'configuration',
  onboarding: 'onboarding',
  enrichment: 'enrichment',
  forms: 'forms',
};

export const segmentActionLocations = {
  segmentMenu: 'segment_menu',
  collectionsMenu: 'collections_menu',
  segmentEditor: 'segment_editor',
};

const currentUser = () =>
  import('~/containers/User').then((UserContainer) => ({
    user: UserContainer.default.state.currentUser,
    ...UserContainer.default,
  }));

function parseConnectorType(connectorType) {
  if (connectorType === configurations.salesforcesandbox)
    return 'salesforce_sandbox';

  return connectorType;
}

function parseSegmentType(type) {
  switch (type) {
    case segmentTypes.company:
      return 'companies';
    case segmentTypes.person:
      return 'people';
    default:
      return type;
  }
}

function parseAttributeSources(attributeSources) {
  return attributeSources.map((source) => {
    switch (source) {
      case 'clearbit':
        return 'Enrichment';
      case 'traits':
        return 'Custom Traits';
      default:
        return startCase(source);
    }
  });
}

// Event functions
// Connector flow

export function trackDisconnectedAccountConnector(connectorType, location) {
  const parsedConnectorType = parseConnectorType(connectorType);

  track('x_connector_disconnected', {
    connector_type: parsedConnectorType,
    location,
  });
}

export function trackConnectedAccountConnector(connectorType, location) {
  const parsedConnectorType = parseConnectorType(connectorType);

  track('x_connector_connected', {
    connector_type: parsedConnectorType,
    location,
  });
}

export function trackAuthStartedAccountConnector(connectorType, location) {
  const parsedConnectorType = parseConnectorType(connectorType);

  track('x_connector_auth_started', {
    connector_type: parsedConnectorType,
    location,
  });
}

export function trackSyncEnabledAccountConnector(
  connectorType,
  location,
  meta = {},
) {
  const parsedConnectorType = parseConnectorType(connectorType);

  track('x_sync_enabled', {
    connector_type: parsedConnectorType,
    location,
    ...meta,
  });
}

export function trackSyncDisabledAccountConnector(
  connectorType,
  location,
  meta = {},
) {
  const parsedConnectorType = parseConnectorType(connectorType);

  track('x_sync_disabled', {
    connector_type: parsedConnectorType,
    location,
    ...meta,
  });
}

// Segment flow

export function trackSegmentCreated(segment, attributeSources = []) {
  const parsedAttributeSources = parseAttributeSources(attributeSources);

  track('x_segment_created', {
    segment_id: segment.id,
    segment_name: segment.name,
    segment_type: parseSegmentType(segment.type),
    segment_attribute_source: parsedAttributeSources,
    total_results: segment.resultsCount,
  });
}

export function trackSegmentUpdated(segment, attributeSources = []) {
  const parsedAttributeSources = parseAttributeSources(attributeSources);

  track('x_segment_updated', {
    segment_id: segment.id,
    segment_name: segment.name,
    segment_type: parseSegmentType(segment.type),
    segment_attribute_source: parsedAttributeSources,
    total_results: segment.resultsCount,
  });
}

export function trackSegmentDeleted(segment, location) {
  track('x_segment_deleted', {
    segment_id: segment.id,
    segment_type: parseSegmentType(segment.type),
    segment_name: segment.name,
    location,
  });
}

export function trackSegmentCloned(segment, clonedSegment, location) {
  track('x_segment_cloned', {
    segment_id: segment.node.id,
    segment_type: parseSegmentType(clonedSegment.type),
    segment_name: segment.node.name,
    segment_clone_id: clonedSegment.id,
    location,
  });
}

// Destination flow

export function trackDestinationEnabled(destination, segment) {
  track('x_destination_enabled', {
    destination_type: destination.type,
    destination_id: destination.id,
    segment_id: segment.id,
    segment_name: segment.name,
    segment_type: parseSegmentType(segment.type),
  });
}

export function trackDestinationDisabled(destination, segment) {
  track('x_destination_disabled', {
    destination_type: destination.type,
    destination_id: destination.id,
    segment_id: segment.id,
    segment_name: segment.name,
    segment_type: parseSegmentType(segment.type),
  });
}

export function trackDestinationDeleted(destination, segment) {
  track('x_destination_deleted', {
    destination_type: destination.type,
    destination_id: destination.id,
    segment_id: segment.id,
    segment_name: segment.name,
    segment_type: parseSegmentType(segment.type),
  });
}

export function trackDestinationCreated(destination) {
  const { segment, config = {} } = destination;
  const defaultProperties = {
    destination_id: destination.id,
    segment_id: segment.id,
    segment_name: segment.name,
    segment_type: parseSegmentType(segment.type),
  };
  const destinationProperties = {
    trigger: destination.triggerType,
    suppression: config.duplicateSuppressionTimeframe > 0,
  };

  let event = 'x_unknown_destination_created';
  let otherData = {};

  switch (destination.type) {
    case destinationTypeSlack:
      event = 'x_slack_destination_created';
      otherData = {
        ...destinationProperties,
      };
      break;
    case destinationTypeEmail:
      event = 'x_email_destination_created';
      otherData = {
        ...destinationProperties,
        schedule: config.frequency,
        email_attribute_source: getAttributeSources(config.columns, segment),
      };
      break;
    case destinationTypeWebhook:
      event = 'x_webhook_destination_created';
      otherData = {
        ...destinationProperties,
        webhook_url: config.url,
        webhook_attribute_source: getAttributeSources(config.columns, segment),
      };
      break;
    case destinationTypeSalesforce:
      event = 'x_salesforce_destination_created';
      otherData = {
        ...destinationProperties,
        type: config.object,
      };
      break;
    case destinationTypeCustomHtml:
      event = 'x_custom_html_destination_created';
      break;
    case destinationTypeGoogleConversion:
      event = 'x_google_conversion_destination_created';
      break;
    case destinationTypeFacebookConversion:
      event = 'x_facebook_conversion_destination_created';
      break;
  }

  track(event, { ...defaultProperties, ...otherData });
}

function getAttributeSources(allColumns = [], segment) {
  const columns =
    allColumns.length === 0
      ? DEFAULT_COLUMNS[legacySegmentType(segment.type)]
      : allColumns;

  return parseAttributeSources(uniq(columns.map((column) => column.category)));
}

// CSV Export events

export function trackCSVExported(segment, csvAttributeSources) {
  track('x_csv_exported', {
    segment_id: segment.id,
    segment_name: segment.name,
    segment_type: parseSegmentType(segment.type),
    csv_attribute_source: parseAttributeSources(csvAttributeSources),
  });
}

// Onboarding events

export function trackOnboardingStarted() {
  track('x_onboarding_started');
}

export function trackOnboardingConnectorSetupComplete(connectorType) {
  track('x_onboarding_connector_step_complete', {
    salesforce_synced: connectorType === configurations.salesforce,
    hubspot_synced: connectorType === configurations.hubspot,
  });
}

export function trackOnboardingIntegrationTested(type, successful) {
  track('x_onboarding_integration_tested', {
    type,
    successful,
  });
}

export function trackOnboardingCompleted() {
  track('x_onboarding_completed');
}

// Search input events

export function trackSearchEntered(searchValue, segment = {}) {
  track('x_search_entered', {
    value: searchValue,
    segment_id: segment.id,
    segment_name: segment.name,
    segment_type: parseSegmentType(segment.type),
  });
}

// NPS survey

const getCommonNPSTrackProperties = ({ user, currentAccount }) => {
  const account = currentAccount();

  return {
    user_id: user.id,
    email: user.email,
    product: 'x',
    account_id: account.id,
    domain: account.domain,
    url: window.location.href,
  };
};

export function trackNPSScoreSelect(score) {
  currentUser().then((user) => {
    const commonProperties = getCommonNPSTrackProperties(user);

    identify({
      email: commonProperties.email,
      x_nps_score: score,
      x_nps_date: new Date(),
    });

    group({
      x_nps_score: score,
      x_nps_date: new Date(),
    });

    track('x_nps_score_submitted', {
      score,
      ...commonProperties,
    });
  });
}

export function trackNPSCommentSubmit(score, comment) {
  currentUser().then((user) => {
    const commonProperties = getCommonNPSTrackProperties(user);

    identify({
      email: commonProperties.email,
      x_nps_date: new Date(),
      x_nps_comment: comment,
    });

    group({
      x_nps_date: new Date(),
    });

    track('x_nps_comment_submitted', {
      score,
      comment,
      ...commonProperties,
    });
  });
}

export function trackNPSDismiss() {
  currentUser().then((user) => {
    const commonProperties = getCommonNPSTrackProperties(user);

    identify({
      email: commonProperties.email,
      x_nps_dismiss_date: new Date(),
    });

    group({
      x_nps_dismiss_date: new Date(),
    });

    track('x_nps_dismissed', commonProperties);
  });
}

// CSV Upload

export function trackCSVUploaded(csvImport) {
  track('x_csv_uploaded', {
    segment_type: csvImport.type,
    csv_id: csvImport.id,
    filename: csvImport.filename,
  });
}

export function trackCSVUploadErrored(type, filename, error) {
  track('x_csv_upload_errored', {
    segment_type: type,
    filename,
    error_type: error,
  });
}

// Reveal GA

export const flattenGAMapping = (mapping) => {
  return mapping.reduce((result, map) => {
    result[map.dimension.value] = map.attribute;
    return result;
  }, {});
};

export function trackGADimensionsCreated(numberOfDimensionsCreated) {
  track('x_ga_dimension_created', {
    total_dimensions_created: numberOfDimensionsCreated,
  });
}

export function trackGAAttributesUpdated(propertyMapping) {
  track('x_ga_dimension_created', {
    connector_type: 'google_analytics',
    ga_property_name: propertyMapping.externalPropertyName,
    ga_property_id: propertyMapping.externalPropertyId,
    ga_account_id: propertyMapping.externalAccountId,
    attributes: flattenGAMapping(propertyMapping.data),
  });
}

// Upgrade account form

export function trackUpgradeFormSubmitted({
  email,
  name,
  message,
  interests,
  crmSize,
}) {
  const requestedInterests = `Interested in: ${interests}`;

  track('plans_contact', {
    name,
    email,
    offer_medium: 'contact request',
    offer_subject: `contact request | ${interests}`,
    crm_size: crmSize,
    message: [message, requestedInterests].join(' | '),
  });
}
