import {
  DirectionForward,
  Restart as RestartIcon,
  Trash,
} from '@clearkit/icons';
import { CKAttributeSelector, CKButton, CKLayoutGrid } from 'clearkit';
import capitalize from 'lodash/capitalize';
import { useState } from 'react';

import {
  attributeForFinder,
  backendSupportedDataType,
  findAttribute,
  readableType,
  sourceIcon,
} from '~/lib/dataEnrichment';
import { isTypeMismatch } from '~/lib/isTypeMismatch';

import { ConditionalOverwriteSelect } from '../ConditionalOverwriteSelect';
import ServiceIcon from '../ServiceIcon';
import { isSystemAttribute } from '../util';

export const RefreshButton = ({ onClickRefresh }) => {
  const [loading, setLoading] = useState(false);
  const onClick = async () => {
    setLoading(true);

    try {
      await onClickRefresh();
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
    } finally {
      setLoading(false);
    }
  };

  return (
    <CKButton
      data-testid="refreshPropertiesButton"
      isDisabled={loading}
      leftIcon={<RestartIcon />}
      onClick={onClick}
      variant="tertiary"
      variantColor="blue"
    >
      {loading ? 'Refreshing' : 'Refresh'}
    </CKButton>
  );
};

export default function FieldMappingRow({
  enableConditionalOverwrite,
  integration,
  mapping,
  type,
  objectType,
  onChange,
  onClickRefreshAttributes,
  onRemove,
  sourceAttributes,
  targetAttributes,
  sourceErrors,
  targetErrors,
  showError,
}) {
  const selectedSourceAttribute = sourceAttributes
    ? findAttribute(attributeForFinder(mapping, 'source'), sourceAttributes)
    : null;
  const selectedTargetAttribute = targetAttributes
    ? findAttribute(
        {
          value: mapping.targetField,
          objectType: objectType.name,
          sourceType: type,
        },
        targetAttributes,
      )
    : null;

  const inErrorState = (sourceOrTarget) => {
    if (sourceOrTarget === 'source') {
      return !!sourceErrors || (!mapping.sourceField && showError);
    } else if (sourceOrTarget === 'target') {
      return !!targetErrors || (!mapping.targetField && showError);
    } else return false;
  };

  const isAlwaysOverwrite =
    integration === 'marketo' && !enableConditionalOverwrite;

  return (
    <CKLayoutGrid
      className="items-center"
      gridTemplateColumns="1fr 1rem 1fr 1fr 3rem"
    >
      <CKAttributeSelector
        attributes={sourceAttributes}
        icon={<ServiceIcon type={selectedSourceAttribute} />}
        isTypeMismatch={(attribute) =>
          isTypeMismatch(attribute, selectedTargetAttribute)
        }
        onSelectedItemChange={(selected) => {
          const conditionalOverwrite =
            isSystemAttribute(selected) || isAlwaysOverwrite
              ? false
              : mapping.conditionalOverwrite;

          onChange({
            conditionalOverwrite,
            sourceField: selected?.value,
            sourceObjectType: selected?.meta?.objectType,
            sourceType: selected?.meta?.sourceType,
          });
        }}
        placeholder="Find an attribute"
        selectedAttribute={selectedSourceAttribute || {}}
        variant={inErrorState('source') && 'error'}
      />
      <DirectionForward className="text-gray" />
      <CKAttributeSelector
        attributes={targetAttributes}
        icon={sourceIcon(type)}
        isTypeMismatch={(attribute) =>
          isTypeMismatch(selectedSourceAttribute, attribute)
        }
        onSelectedItemChange={(selected) =>
          onChange({
            targetField: selected?.value,
            dataType: backendSupportedDataType(selected?.meta?.dataType),
          })
        }
        placeholder={`Choose a ${readableType(type)} ${capitalize(
          objectType.name,
        )} attribute`}
        rootCategoryIdentifier={`category_${type}`}
        rootCategoryLabel={readableType(type)} // here
        rootCategoryMeta={
          <RefreshButton onClickRefresh={onClickRefreshAttributes} />
        }
        selectedAttribute={selectedTargetAttribute || {}}
        variant={inErrorState('target') && 'error'}
      />
      <div className="pl-4 border-l border-gray-600 border-opacity-10">
        <ConditionalOverwriteSelect
          enableConditionalOverwrite={enableConditionalOverwrite}
          integration={integration}
          mapping={mapping}
          onChange={(changes) => onChange(changes)}
        />
      </div>
      <div className="text-right">
        <CKButton onClick={onRemove} size="small" variant="tertiary">
          <Trash className="text-gray-600" />
        </CKButton>
      </div>
    </CKLayoutGrid>
  );
}
