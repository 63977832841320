import { Docs as DocsIcon } from '@clearkit/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CKBox, CKButton, CKField, CKTextField } from 'clearkit';
import { useEffect, useState } from 'react';

import SecurityDisclaimer from '~/components/ConfigurationSecurityDisclaimer';
import {
  MarketoAccountConnector,
  useMarketoConnectionQuery,
  useUpsertMarketoAccountConnectorMutation,
} from '~/generated/graphql';

const learnMoreLink =
  'https://help.clearbit.com/hc/en-us/articles/4420189078807';

type ErrorMap = Record<string, string[]>;
type MarketoConnectionFormProps = {
  onSuccess: () => void;
};
export default function MarketoConnectionForm({
  onSuccess,
}: MarketoConnectionFormProps) {
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [endpointUrl, setEndpointUrl] = useState('');
  const [updateErrors, setUpdateErrors] = useState<ErrorMap>({});

  const { data = {}, loading } = useMarketoConnectionQuery();

  const [
    upsertMarketoConnector,
    { loading: upsertLoading },
  ] = useUpsertMarketoAccountConnectorMutation();

  const accountConnector = data.accountConnector as MarketoAccountConnector;

  useEffect(() => {
    if (accountConnector) {
      setClientId(accountConnector.clientId);
      setClientSecret(accountConnector.clientSecret);
      setEndpointUrl(accountConnector.endpointUrl);
    }
  }, [accountConnector]);

  async function upsertMarketoAccountConnector() {
    setUpdateErrors({});

    const { data: mutationData } = await upsertMarketoConnector({
      variables: {
        input: {
          clientId,
          clientSecret,
          endpointUrl,
        },
      },
    });

    const errors = mutationData?.upsertMarketoConnector?.errors;

    if (errors?.length) {
      const errorObj: ErrorMap = {};

      errors.forEach(({ field, messages }) => {
        errorObj[field] = messages;
      });

      setUpdateErrors(errorObj);
    } else {
      onSuccess();
    }
  }

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <CKBox className="p-8">
        <div className="text-lg font-semibold text-center text-black">
          Connect to your Marketo account
        </div>

        <p className="pb-4 text-sm text-center text-gray-500">
          Connect your Marketo account to sync Clearbit data to Marketo.
          <a
            className="flex gap-1"
            href={learnMoreLink}
            rel="noopener noreferrer"
            target="_blank"
          >
            <DocsIcon />
            How to Connect to Marketo
          </a>
        </p>

        <div className="flex flex-col pb-4 gap-2">
          <div className="flex justify-between text-sm">
            <label htmlFor="client-id">Client ID</label>
          </div>

          <CKField
            className="block w-full"
            variant={updateErrors.client_id ? 'error' : undefined}
          >
            <CKTextField
              className="block w-full"
              id="client-id"
              onChange={(e) => {
                const value = e.currentTarget.value.trim();
                setClientId(value);
              }}
              value={clientId || ''}
            />
          </CKField>
        </div>

        <div className="flex flex-col pb-4 gap-2">
          <div className="flex justify-between text-sm">
            <label htmlFor="client-secret">Client Secret</label>
          </div>

          <CKField
            className="block w-full"
            variant={updateErrors.client_secret ? 'error' : undefined}
          >
            <CKTextField
              className="block w-full"
              id="client-secret"
              onChange={(e) => {
                const value = e.currentTarget.value.trim();
                setClientSecret(value);
              }}
              value={clientSecret || ''}
            />
          </CKField>
        </div>

        <div className="flex flex-col pb-4 gap-2">
          <div className="flex justify-between text-sm">
            <label htmlFor="endpoint-url">Endpoint URL</label>
          </div>

          <CKField
            className="block w-full"
            variant={updateErrors.endpoint_url ? 'error' : undefined}
          >
            <CKTextField
              className="block w-full"
              id="endpoint-url"
              onChange={(e) => {
                const value = e.currentTarget.value.trim();
                setEndpointUrl(value);
              }}
              placeholder="example https://123-ABC-456.mktorest.com"
              value={endpointUrl || ''}
            />
          </CKField>
        </div>

        <CKButton
          className="text-center"
          isDisabled={!clientId || !clientSecret || !endpointUrl}
          isLoading={upsertLoading}
          onClick={upsertMarketoAccountConnector}
          variant="bold"
          variantColor="blue"
        >
          Connect Marketo
        </CKButton>
      </CKBox>
      <SecurityDisclaimer className="pt-4" />
    </>
  );
}
