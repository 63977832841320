import castArray from 'lodash/castArray';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

import attributeTypes from './attributeTypes';
import {
  allOperators,
  comparatorsForAttribute,
  DATE_COMPARATORS,
} from './comparators';
import {
  DEFAULT_EVENT_PART,
  DEPENDENT_HUBSPOT_KEYS,
  DEPENDENT_SALESFORCE_KEYS,
  PRIMARY_HUBSPOT_KEYS,
  PRIMARY_MARKETO_KEYS,
  PRIMARY_SALESFORCE_KEYS,
} from './constants';
import { mapSearch } from './searchUtils';
import {
  changeMultiColumnStringComparator,
  changeRollupComparator,
  getRollupComparator,
  isRollupNode,
} from './searchUtilsRollup';

// API methods for altering a node:

// Get the attribute (column)
export const getAttribute = (node, attributes, category, subCategory) => {
  if (node.parentAttribute) {
    return getAttributeByValue(node.parentAttribute, attributes);
  }

  if (node.event) {
    const { event } = node;
    const { match } = event;
    return getAttributeByValue(
      match,
      attributes.filter((c) => c.category === 'page view'),
    );
  } else {
    let attributeValue = getAttributeValue(node);
    if (!attributeValue) return false;

    if (category) {
      return getAttributeByValue(
        attributeValue,
        attributes.filter(
          (attribute) =>
            attribute.category === category &&
            attribute.subCategory === subCategory,
        ),
      );
    } else {
      return getAttributeByValue(attributeValue, attributes);
    }
  }
};

// Changing the attribute means changing a few values depending on the node type
// If we change from attribute to event, we need to create an { event: {} } value
export const changeNodeAttribute = (node, attribute, attributes, search) => {
  let { value } = attribute;

  const oldAttribute = getAttribute(node, attributes);

  // Rollup column
  if (attribute.details.type === attributeTypes.NESTED_SELECT) {
    const comparators = comparatorsForAttribute(attribute);
    const comparator = Object.keys(comparators)[0];
    node = {
      __index: node.__index,
      __nest: node.__nest,
      parentAttribute: value,
      comparator,
    };
    return node;
  } else if (attribute.details.type === attributeTypes.MULTI_COLUMN_STRING) {
    const comparators = comparatorsForAttribute(attribute);
    const comparator = Object.keys(comparators)[0];
    const andOrOr = comparator === 'match_any' ? 'or' : 'and';

    // Set up the AND/OR conditions for this node
    const columns = attribute.details.columns;
    const value = getValue(node);
    const conditions = columns.map((attribute) => ({
      [comparator]: {
        attribute,
        value,
      },
    }));

    node = {
      __index: node.__index,
      __nest: node.__nest,
      parentAttribute: attribute.value,
      [andOrOr]: conditions,
    };
    return node;
  } else {
    delete node.parentAttribute;
  }

  if (node.event && attribute.category === 'page view') {
    node.event.match = value;
    if (value === 'all') node.event.path = '';
    return node;
  }

  // Choose the first operator that applies to the new attribute
  if (get(attribute, 'details.type')) {
    const allowedOperators = Object.keys(comparatorsForAttribute(attribute));
    if (allowedOperators.indexOf(getComparator(node)) === -1) {
      node = changeComparator(node, allowedOperators[0], attributes);
    }
  }

  // Clear the value if we're changing type
  const oldType = get(oldAttribute, 'details.type');
  const newType = get(attribute, 'details.type');
  if (
    oldAttribute !== attribute &&
    (oldType !== newType ||
      oldType === attributeTypes.SELECT ||
      oldType === attributeTypes.NESTED_SELECT)
  ) {
    if (
      newType === attributeTypes.SELECT ||
      newType === attributeTypes.NESTED_SELECT
    ) {
      node = changeValue(node, []);
    } else {
      node = changeValue(node, '');
    }
  }

  // Switch from an Event type to a non-Event type
  if (node.event && attribute.category !== 'page view') {
    delete node.event;
  }

  // Switch from a mon-event type to an Event type
  if (attribute.category === 'page view') {
    // turn this into an event
    node = {
      ...DEFAULT_EVENT_PART,
      __index: node.__index,
      __nest: node.__nest,
    };

    node.event.match = value;
    return node;
  }

  // Note(Elliott):
  // I think we'll have to move this into a verification step that happens after you change a node,
  // which will take the search structure into account.
  // This only accounts for changing from a non-salesforce group into a salesforce group.
  if (attribute.category === 'salesforce' || attribute.category === 'hubspot') {
    const key = `${attribute.category}_${attribute.subCategory}`;
    const comparator = getComparator(node);

    if (
      attribute.category === 'salesforce' &&
      !isNodeInSalesforceCondition(search, node)
    ) {
      switch (key) {
        case 'salesforce_opportunity':
          node = {
            __index: node.__index,
            __nest: node.__nest,
            salesforce_account: {
              [key]: {
                and: [
                  {
                    ...node,
                    [comparator]: {
                      attribute: value,
                      value: '',
                    },
                  },
                ],
              },
            },
          };
          break;
        case 'salesforce_contact':
          node = {
            __index: node.__index,
            __nest: node.__nest,
            salesforce_account: {
              [key]: {
                and: [
                  {
                    ...node,
                    [comparator]: {
                      attribute: value,
                      value: '',
                    },
                  },
                ],
              },
            },
          };
          break;
        default:
          node = {
            __index: node.__index,
            __nest: node.__nest,
            [key]: {
              and: [
                {
                  ...node,
                  [comparator]: {
                    attribute: value,
                    value: '',
                  },
                },
              ],
            },
          };
          break;
      }
    } else if (
      attribute.category === 'hubspot' &&
      !isNodeInHubspotCondition(search, node)
    ) {
      if (key === 'hubspot_deal') {
        node = {
          __index: node.__index,
          __nest: node.__nest,
          hubspot_company: {
            [key]: {
              and: [
                {
                  ...node,
                  [comparator]: {
                    attribute: value,
                    value: '',
                  },
                },
              ],
            },
          },
        };
      } else {
        node = {
          __index: node.__index,
          __nest: node.__nest,
          [key]: {
            and: [
              {
                ...node,
                [comparator]: {
                  attribute: value,
                  value: '',
                },
              },
            ],
          },
        };
      }
    } else {
      node = {
        ...node,
        [comparator]: {
          ...node[comparator],
          attribute: value,
        },
      };
    }
    return node;
  }

  const comparator = getComparator(node);
  node[comparator] = { ...node[comparator], attribute: value };
  node[comparator].attribute = value;

  return node;
};

// Comparator: match, gte, lte
export const getComparator = (node) => {
  if (!node) return false;

  if (isRollupNode(node)) {
    return getRollupComparator(node);
  }

  const operators = allOperators();
  const keys = Object.keys(node).filter((key) => operators.includes(key));
  return keys[0];
};

export const changeComparator = (node, newComparator, attributes) => {
  const attribute = getAttribute(node, attributes);

  // Note: this is optional because we may not
  // have the previous attribute available right now.
  if (attribute?.details?.type === attributeTypes.MULTI_COLUMN_STRING) {
    return changeMultiColumnStringComparator(node, newComparator, attribute);
  }

  //
  if (isRollupNode(node)) {
    return changeRollupComparator(node, newComparator);
  }

  let comparator = getComparator(node);
  if (comparator === newComparator) return node;
  const savedLineContents = node[comparator];
  node[newComparator] = savedLineContents;

  // Changing between date types

  const wasADateField = Object.keys(DATE_COMPARATORS).includes(comparator);
  const willBeADatefield = Object.keys(DATE_COMPARATORS).includes(
    newComparator,
  );
  if (wasADateField && willBeADatefield) {
    if (
      ['date_not_match', 'date_match', 'date_lt', 'date_gt'].includes(
        newComparator,
      )
    ) {
      node[newComparator].value = castArray(node[comparator].value)[0];
    } else if (
      newComparator === 'date_between' ||
      newComparator === 'date_not_between'
    ) {
      const oldValue = node[newComparator].value;
      if (oldValue && !isArray(oldValue)) {
        node[newComparator].value = [oldValue, oldValue];
      }
    }
  }

  // Delete the value if we're changing to 'exists' / 'does not exist'
  if (!comparatorHasValue(newComparator)) {
    if (node[comparator]) {
      delete node[comparator].value;
    }
  }

  // If we're converting from a rollup column,
  // remove the rollup attributes from it
  delete node.or;
  delete node.and;

  delete node[comparator];
  return node;
};

// The right-hand side of a query node
export const getValue = (node) => {
  // Multi-column node
  if (node?.parentAttribute) {
    const andOrOr = node.or || node.and;
    if (!andOrOr) return undefined;
    return getValue(andOrOr[0]);
  }
  const comparator = getComparator(node);
  if (!comparator) return undefined;
  return node[comparator].value;
};

export const changeValue = (node, value) => {
  const comparator = getComparator(node);

  // Nested string nodes
  if (node.or || node.and) {
    node = mapSearch(node, (nestedNode) => {
      const comparator = getComparator(nestedNode);
      if (comparator && nestedNode[comparator])
        nestedNode[comparator].value = value;
      return nestedNode;
    });
    return node;
  }

  if (!node[comparator]) node[comparator] = {};
  if (node[comparator]) delete node[comparator].subQuery;
  node[comparator].value = value;
  return node;
};

// Utility functions

// Query "groups" look a little like nodes, but have "or"/"and" keys.
// Rollup nodes have keys too, but they're not groups for our purposes.
export const isQueryGroup = (node) => {
  return getAndOrOr(node) && !isRollupNode(node);
};

// If a node has a dependent condition, but it has no nodes in that condition,
// remove that key from the node.
export const removeEmptySalesforceDependentConditions = (node) => {
  DEPENDENT_SALESFORCE_KEYS.forEach((key) => {
    ['and', 'or'].forEach((operator) => {
      if (get(node, [key, operator, 'length']) === 0) {
        delete node[key];
      }
    });
  });
  return node;
};
// A group node has an { and: [] } or { or: [] } structure
// for nesting its children. This finds out whether it's an "and" or an "or"
export const getAndOrOr = (node) => {
  if (!node) return false;
  let keys = Object.keys(node);
  if (keys.indexOf('and') > -1) {
    return 'and';
  } else if (keys.indexOf('or') > -1) {
    return 'or';
  }
  return false;
};

export const getAttributeValue = (node) => {
  if (node.event) return node.event.value;
  if (node.parentAttribute) return node.parentAttribute;
  if (node.attribute) return node.attribute;
  const comparator = getComparator(node);
  if (!comparator) return false;
  return node[comparator].attribute;
};

// Figure out which attribute this node's "attribute" represents
// based on the attribute's value and the node's value
export const getAttributeByValue = (value, attributes) => {
  const attribute = attributes.find((c) => c.value === value);
  if (!attribute && value && value.indexOf('->') > -1) {
    const parts = value.split('->');
    if (parts.length > 2) {
      const category = parts[0];
      const subCategory = parts[1];
      const value = parts.slice(2).join('->');
      const attribute = find(attributes, { value, category, subCategory });
      if (attribute) return attribute;
    } else if (parts.length === 2) {
      const [category, value] = parts;
      const attribute = find(attributes, { category, value });
      if (attribute) return attribute;
    }

    value = parts[parts.length - 1];
    return attributes.find((c) => c.value === value);
  }
  return attribute;
};

export const showValueForNode = (node, attribute) => {
  if (!attributeHasValue(attribute)) return false;
  const comparator = getComparator(node);
  if (comparator === 'exists' || comparator === 'not_exists') {
    return false;
  }
  return true;
};
// Whether to show the value field for an attribute - we don't
// need to show a value for "exists" and "doesn't exist"
export const attributeHasValue = (attribute) => {
  switch (get(attribute, 'details.type')) {
    case attributeTypes.EXISTENCE_BOOLEAN:
      return false;
    default:
      return true;
  }
};

export const comparatorHasValue = (comparator) => {
  switch (comparator) {
    case 'not_exists':
    case 'exists':
      return false;
    default:
      return true;
  }
};
// Salesforce & Hubspot
export const disabledCategoriesForNode = (search, node) => {
  // TODO: If this is inside a Salesforce Account or Salesforce Opportunity section, don't use this
  if (node.__nest > 3) {
    return ['salesforce'];
  }
  return [];
};

export const hasDependentConditions = (node) => {
  let dependentKeys = false;
  [...DEPENDENT_SALESFORCE_KEYS, ...DEPENDENT_HUBSPOT_KEYS].forEach((key) => {
    if (node[key]) dependentKeys = true;
  });
  return dependentKeys;
};

export const hasPrimaryConditions = (node) => {
  let primaryKeys = false;
  [
    ...PRIMARY_SALESFORCE_KEYS,
    ...PRIMARY_HUBSPOT_KEYS,
    ...PRIMARY_MARKETO_KEYS,
  ].forEach((key) => {
    if (node[key]) primaryKeys = true;
  });
  return primaryKeys;
};

// These functions let us access a node's parent nodes' attributes, to figure out whether it's in a Salesforce node
function plainToFlattenObject(object) {
  const result = new Map();

  function flatten(obj, prefix = '') {
    forEach(obj, (value, key) => {
      if (isObject(value)) {
        result.set([...prefix, key], value);
        flatten(value, [...prefix, key]);
      } else {
        result.set([...prefix, key], value);
      }
    });
  }

  flatten(object);
  return result;
}

export const getPathForNode = (search, node) => {
  let flatSearch = plainToFlattenObject(search);
  const resultArray = Array.from(flatSearch.entries());
  let result = resultArray.filter((k) => k[1] === node.__index);
  result = flatten(result);
  result = result[0];
  if (!result) return false;
  result = result.filter((v) => v !== '__index');
  result = result.filter((v) => v !== '__nest');
  return result;
};

export const isNodeInSalesforceCondition = (search, node) => {
  const path = getPathForNode(search, node);

  if (!path) {
    throw 'Node not found in search';
  }

  let result = false;
  [...PRIMARY_SALESFORCE_KEYS, ...DEPENDENT_SALESFORCE_KEYS].forEach((key) => {
    if (path.indexOf(key) > -1) {
      result = true;
    }
  });
  return result;
};

export const isNodeInHubspotCondition = (search, node) => {
  const path = getPathForNode(search, node);

  if (!path) {
    throw 'Node not found in search';
  }

  let result = false;
  [...PRIMARY_HUBSPOT_KEYS, ...DEPENDENT_HUBSPOT_KEYS].forEach((key) => {
    if (path.indexOf(key) > -1) {
      result = true;
    }
  });
  return result;
};

// Legacy salesforce search operations
export const isLegacySalesforceNode = (node) => {
  let result = false;

  const allIndexes = [...DEPENDENT_SALESFORCE_KEYS, ...PRIMARY_SALESFORCE_KEYS];
  if (intersection(Object.keys(node), allIndexes).length === 0) {
    return false;
  }

  const dependentKeys = DEPENDENT_SALESFORCE_KEYS.filter((k) => !!node[k]);

  if (dependentKeys.length > 0) result = true;

  // primary keys with secondary keys inside them
  const primaryKeys = PRIMARY_SALESFORCE_KEYS.filter((k) => {
    const hasDependentKeys = DEPENDENT_SALESFORCE_KEYS.filter(
      (d) => node[k] && !!node[k][d],
    );
    return hasDependentKeys.length > 0 || (node[k] && getAndOrOr(node[k]));
  });

  if (primaryKeys.length === 0) result = true;

  return result;
};
