import { CKAvatar, CKLayoutHorizontal } from 'clearkit';

import { Prospect } from '~/generated/graphql';

import { AVATAR_IMAGE_SIZE } from './constants';
import { ProspectTokens } from './ProspectTokens';

export const ItemBlockPerson = ({ prospect }: { prospect: Prospect }) => (
  <CKLayoutHorizontal
    alignItems="start"
    className="min-h-0 @container basis-full shrink grow"
    justifyContent="start"
    wrap="nowrap"
  >
    <CKAvatar
      className="shrink-0"
      name={prospect.name}
      size={AVATAR_IMAGE_SIZE}
      src={prospect.avatar || ''}
      variant="user"
    />
    <CKLayoutHorizontal className="flex-wrap shrink grow @item-small:flex-nowrap">
      <div className="text-sm basis-full shrink grow">
        <h3 className="font-medium truncate whitespace-nowrap">
          {prospect.name}
        </h3>
        <p className="text-xs text-gray-600 truncate whitespace-nowrap">
          {prospect.title}
        </p>
      </div>
      <ProspectTokens
        hasEmail={prospect.email}
        hasLinkedIn={prospect.linkedin}
        hasPhone={prospect.phone}
      />
    </CKLayoutHorizontal>
  </CKLayoutHorizontal>
);
