const styles = () => ({
  root: {
    minWidth: 280,
  },

  menuHeader: {
    fontSize: 14,
    textTransform: 'capitalize',
    background: 'linear-gradient(#FCFEFF, #F9FBFC)',
    borderBottom: '1px solid #E1E9F0',
    minHeight: 44,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },

  menuHeaderTop: {
    width: '100%',
    height: 45,
    paddingLeft: 15,
    display: 'flex',
    alignItems: 'center',
  },

  headerTitle: {
    fontSize: 14,
    paddingTop: 2,
    fontWeight: 500,
    color: '#596168',
  },

  categoryList: {
    color: '#596168',
    fontSize: 14,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },

  categoryIcon: {
    width: 18,
    height: 18,
    marginRight: 10,
    position: 'relative',
    top: -1,
  },

  valueIcon: {
    width: 16,
    height: 16,
    marginRight: 8,
    position: 'relative',
    top: 3,
    flexShrink: 0,
  },

  valueName: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },

  optionGroup: {
    background: 'red',
    padding: '0px !important',
    '&:first-of-type': {
      borderBottom: '1px solid #E1E9F0',
    },
    '&:last-of-type': {
      borderBottom: 'none',
    },
  },

  existenceBooleanIndicator: {
    transform: 'rotate(45deg)',
    background: '#9BADBC',
    width: 5,
    height: 5,
    display: 'inline-block',
    marginRight: 10,
    position: 'relative',
    top: -2,
  },

  groupHeadingIcon: {
    width: 18,
    height: 18,
    marginRight: 8,
    position: 'relative',
    display: 'inline-block',
  },

  groupHeadingLabel: {
    position: 'relative',
    textTransform: 'Capitalize',
    fontSize: 14,
    color: '#7B8B99',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 4,

    '& svg': {
      position: 'relative',
      top: -1,
    },
  },

  groupOptionLabel: {
    paddingLeft: 24,
    fontSize: 14,
    color: '#27282A',
    lineHeight: '15px',
    cursor: 'pointer',
  },

  backButton: {
    position: 'relative',
    cursor: 'pointer',
    marginRight: 15,
    width: 18,
    height: 18,
  },

  tabsRoot: {
    height: 44,
    minHeight: 30,
    marginBottom: -2,
    marginTop: -12,
    width: '100%',
    flexGrow: 1,
    marginLeft: 5,
    borderBottom: 'none',
  },

  categoryTabRoot: {
    height: 2,
    minHeight: 43,
    paddingTop: 0,
    minWidth: 'unset',
    width: 'auto',
    flexGrow: 0,
    marginLeft: 10,
    marginRight: 10,
  },

  categoryTabLabelWrapper: {
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: 0,
    width: 'auto',
  },

  categoryTabLabel: {
    margin: 0,
    fontSize: 14,
    paddingBottom: 10,
  },

  flexContainer: {
    padding: 0,
    height: 20,
    minHeight: 32,
  },

  caret: {
    marginLeft: 'auto',
    alignSelf: 'center',
  },
});

export default styles;
