import { AccountFlagEnum } from '~/generated/graphql';

import useCurrentAccount from './useCurrentAccount';
import useDeveloperOverride from './useDeveloperOverride';

type FlagStatus = {
  enabled: boolean;
  disabled: boolean;
  loading: boolean;
};

export default function useFeatureFlag(flag: AccountFlagEnum): FlagStatus {
  const { account, loading } = useCurrentAccount();
  const override = useDeveloperOverride(`FEATURE_FLAG:${flag}`);
  let value;

  if (override.isOverridden) {
    value = override.value();
  } else if (!account) {
    value = false;
  } else if (account.flags.indexOf(flag) > -1) {
    value = true;
  } else {
    value = false;
  }

  return {
    enabled: !!value,
    disabled: !value,
    loading,
  };
}
