import { Lock } from '@clearkit/icons';
import classnames from 'classnames';
import {
  CKBox,
  CKButton,
  CKContainerProps,
  CKLayoutHorizontal,
} from 'clearkit';

import { BillingInfo } from '~/generated/graphql';

import CustomStripeCCInput from './CustomStripeCCInput';

type BillingPaymentMethodProps = CKContainerProps & {
  accountBilling: BillingInfo | undefined;
  ccErrorMessage: string | null;
  formErrors: any;
  isEditingCC: boolean;
  setIsEditingCC: (isEditingCC: boolean) => void;
  setFormErrors: (formErrors: any) => void;
  stripeCCInputRef: any;
};

export const BillingPaymentMethod = ({
  accountBilling,
  ccErrorMessage,
  formErrors,
  isEditingCC,
  setIsEditingCC,
  setFormErrors,
  stripeCCInputRef,
  ...rest
}: BillingPaymentMethodProps) => {
  return (
    <CKBox {...rest}>
      <section className="p-6">
        {ccErrorMessage && isEditingCC && (
          <span className="mb-2 text-base font-medium text-red-500">
            {ccErrorMessage}
          </span>
        )}
        {accountBilling?.cardLastFour && !isEditingCC ? (
          <div className="flex flex-row items-center justify-between mb-4 text-base text-gray-700">
            <div className="flex flex-row items-center">
              <span
                className={classnames(
                  'p-2 border border-gray-200 rounded mr-2',
                )}
              >
                ************{accountBilling.cardLastFour}
              </span>
              <span>{accountBilling.billingEmail}</span>
            </div>
            <CKButton
              onClick={() => {
                setFormErrors({});
                setIsEditingCC(true);
              }}
              variant="tertiary"
              variantColor="blue"
            >
              Update
            </CKButton>
          </div>
        ) : (
          <CustomStripeCCInput errors={formErrors} ref={stripeCCInputRef} />
        )}
      </section>
      <CKBox.Footer className="px-6 py-3">
        <CKLayoutHorizontal gap=".25rem" justifyContent="start">
          <Lock />
          <span className="text-sm">Payments securely processed by Stripe</span>
        </CKLayoutHorizontal>
      </CKBox.Footer>
    </CKBox>
  );
};
