import { NoResultsPeople } from '@clearkit/icons';
import { CKContainerProps } from 'clearkit';

import { EmptyState } from './EmptyState';

export const EmptyStateFilters = (props: CKContainerProps) => (
  <EmptyState {...props}>
    <EmptyState.Icon>
      <NoResultsPeople />
    </EmptyState.Icon>
    <EmptyState.Title>Find prospects</EmptyState.Title>
    <EmptyState.Description>
      Add people and company filters to search for prospects in the Clearbit
      dataset of over 250M contacts.
    </EmptyState.Description>
  </EmptyState>
);
