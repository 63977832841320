import classnames from 'classnames';
import { CKContainerProps } from 'clearkit';

export const ExportHeader = ({
  children,
  className,
  ...rest
}: CKContainerProps<HTMLHeadingElement>) => (
  <h3 {...rest} className={classnames('mb-1 font-medium', className)}>
    {children}
  </h3>
);
