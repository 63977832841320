import Highcharts from 'highcharts';
import { merge } from 'lodash';
import Chartkick, { AreaChart } from 'react-chartkick';

import { formatNumberWithSuffix } from '~/lib/formatters';

import ChangeArrow from './changeArrow.svg';
import DownArrow from './downArrow.svg';
import chartOptions, { downwardGraphOptions } from './highchartOptions';

const UPWARD_TREND_GRAPH_DATA = [0, 10, 40, 75, 100];

const graphData = (percentageChange) => {
  if (percentageChange > 0) return UPWARD_TREND_GRAPH_DATA;
  if (percentageChange < 0) return UPWARD_TREND_GRAPH_DATA.slice().reverse();

  return [];
};

Chartkick.use(Highcharts);

const PageviewGraph = ({ percentageChange, className }) => {
  const data = graphData(percentageChange);
  const noChange = percentageChange == 0;
  const up = percentageChange > 0;

  const library = up
    ? chartOptions
    : merge({}, chartOptions, downwardGraphOptions);

  const labelForGraph = noChange ? (
    'No change'
  ) : (
    <>
      {`${formatNumberWithSuffix(percentageChange)}%`}

      {up ? (
        <ChangeArrow className="inline ml-1 -mb-1" />
      ) : (
        <DownArrow className="inline ml-1 -mb-1" />
      )}
    </>
  );

  return (
    <div
      className={classnames('ck-box overflow-hidden p-0 relative', className)}
      style={{ height: 120 }}
    >
      <div className="w-full h-full m-0 ml-auto">
        <AreaChart
          colors={[up ? '#4EDC9F' : '#637eff']}
          data={data.reduce((acc, datum, index) => {
            acc[index] = datum;
            return acc;
          }, {})}
          height="100%"
          library={library}
        />
      </div>
      <div
        className="absolute flex flex-col w-10/12 h-full p-4"
        style={{
          background: 'linear-gradient(90deg, white, rgba(255, 255, 255, 0)',
        }}
      >
        <span className="mb-auto text-gray-500">Recent pageviews</span>
        <span
          className={classnames('text-xl font-bold align-baseline', {
            'text-green-500': up && !noChange,
            'text-indigo-500': !up && !noChange,
            'text-gray-400': noChange,
          })}
          style={{ fontSize: 32, lineHeight: 1 }}
        >
          {labelForGraph}
        </span>
        <span className="text-gray-600">
          {noChange ? 'No change' : up ? 'Up' : 'Down'} this week
        </span>
      </div>
    </div>
  );
};

PageviewGraph.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      total: PropTypes.number,
      date: PropTypes.string,
    }),
  ),
  className: PropTypes.string,
};

export default PageviewGraph;
