import castArray from 'lodash/castArray';
import compact from 'lodash/compact';

import {
  changeComparator,
  changeValue,
  getAttribute,
  getComparator,
  getValue,
} from './nodeUtils';
import { mapSearch } from './searchUtils';

function newStringComparator(comparator) {
  switch (comparator) {
    case 'match':
      return 'match_any';
    case 'not_match':
      return 'not_match_any';
    case 'contains':
      return 'contains_all';
    case 'not_contains':
      return 'not_contains_all';
    default:
      return comparator;
  }
}

export default function upgradeMultiStringFields(search, attributes) {
  search = mapSearch(search, (node) => {
    const comparator = getComparator(node);
    if (!comparator) return node;
    const attribute = getAttribute(node, attributes);
    if (!attribute) return node;

    // Existence on string fields

    if (attribute.details.type === 'string') {
      if (['exists', 'not_exists'].includes(comparator)) {
        delete node.value;
      } else {
        const newComparator = newStringComparator(comparator);
        node = changeComparator(node, newComparator, attributes);
        const newValue = compact(castArray(getValue(node)));
        node = changeValue(node, newValue);
      }
    }
    return node;
  });
  return search;
}
