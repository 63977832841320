import { AddBlock, RemoveHollow } from '@clearkit/icons';
import classnames from 'classnames';
import {
  CKBadge,
  CKButton,
  CKContainerProps,
  CKDetailGroup,
  CKDropdown,
  CKDropdownProps,
  CKLayoutHorizontal,
  cloneChildren,
  includeChildrenByDisplayName,
} from 'clearkit';
import { FC, useEffect, useState } from 'react';

export type ErrorMessageProps = CKContainerProps & {
  isVisible?: boolean;
};

export type FilterProps = CKContainerProps & {
  filterCount?: number;
  onClearFilter: () => void;
  popoverPlacement?: CKDropdownProps['popoverPlacement'];
  popoverWidth?: string;
  shouldStartOpen?: boolean;
};

export type FilterComposition = {
  HelpText: FC<CKContainerProps>;
  Icon: FC<CKContainerProps>;
  Input: FC<CKContainerProps>;
  Menu: FC<CKContainerProps>;
  Title: FC<CKContainerProps>;
};

export type FilterInstanceComposition = Omit<
  FilterComposition,
  'Input' | 'Menu' | 'Popover'
> & {
  ErrorMessage: FC<ErrorMessageProps>;
};

export const componentDisplayNames = {
  helpTextDisplayName: 'Filter.HelpText',
  iconDisplayName: 'Filter.Icon',
  inputDisplayName: 'Filter.Input',
  menuDisplayName: 'Filter.Menu',
  titleDisplayName: 'Filter.Title',
};

const {
  helpTextDisplayName,
  iconDisplayName,
  inputDisplayName,
  menuDisplayName,
  titleDisplayName,
} = componentDisplayNames;

export const Filter: FC<FilterProps> & FilterComposition = ({
  children,
  onClearFilter,
  filterCount,
  shouldStartOpen = false,
  popoverPlacement,
  popoverWidth = '22rem',
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(shouldStartOpen);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const hasNoFilterCount = !filterCount || filterCount === 0;

  const handleToggle = () => {
    setIsOpen(!isOpen);

    if (hasNoFilterCount) {
      setIsPopoverOpen(!isOpen);
    }
  };

  const togglePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);

    if (hasNoFilterCount && !isPopoverOpen) {
      setIsOpen(true);
    }
  };

  const handleClear = () => {
    handleToggle();
    onClearFilter();
  };

  useEffect(() => {
    if (hasNoFilterCount) {
      setIsOpen(isPopoverOpen);
    }
  }, [isPopoverOpen]);

  useEffect(() => {
    if (shouldStartOpen) {
      setIsOpen(shouldStartOpen);
    }
  }, [shouldStartOpen]);

  const menuComponent = includeChildrenByDisplayName({
    children: children,
    componentDisplayName: menuDisplayName,
  });
  const hasMenuComponent = !!menuComponent?.length;

  const FilterHeader = () => (
    <CKLayoutHorizontal
      as="span"
      className="font-medium text-gray-600"
      gap=".5rem"
      justifyContent="start"
      wrap="nowrap"
    >
      {includeChildrenByDisplayName({
        children: children,
        componentDisplayName: iconDisplayName,
      })}
      {includeChildrenByDisplayName({
        children: children,
        componentDisplayName: titleDisplayName,
      })}
    </CKLayoutHorizontal>
  );

  const FilterContent = () => (
    <CKLayoutHorizontal as="span" className="grow shrink" wrap="nowrap">
      <FilterHeader />
      {filterCount ? <CKBadge variant="rounded">{filterCount}</CKBadge> : null}
    </CKLayoutHorizontal>
  );

  return (
    <CKDetailGroup {...rest} isOpen={isOpen} onToggle={handleToggle}>
      {filterCount || isOpen ? (
        <CKDetailGroup.TriggerChevron className="!py-1 [&_span]:flex">
          <FilterContent />
        </CKDetailGroup.TriggerChevron>
      ) : (
        <CKButton
          className="w-full !py-1"
          onClick={handleToggle}
          variant="tertiary"
        >
          <CKLayoutHorizontal className="shrink-0" wrap="nowrap">
            <FilterContent />

            <CKLayoutHorizontal className="shrink-0 text-blue" gap=".25rem">
              <AddBlock /> Add
            </CKLayoutHorizontal>
          </CKLayoutHorizontal>
        </CKButton>
      )}
      {isOpen ? (
        <CKDetailGroup.Group className="space-y-2">
          {hasMenuComponent ? (
            <CKDropdown
              className="w-full !block"
              isOpen={isPopoverOpen}
              onChange={togglePopover}
              popoverPlacement={popoverPlacement}
              popoverWidth={popoverWidth}
            >
              <CKDropdown.Menu className="!px-0 !max-w-full">
                <header className="px-4 py-3 border-b border-gray-200 bg-gray-0">
                  <FilterHeader />
                </header>
                {menuComponent}
              </CKDropdown.Menu>
            </CKDropdown>
          ) : null}
          {includeChildrenByDisplayName({
            children: children,
            componentDisplayName: inputDisplayName,
          })}
          {includeChildrenByDisplayName({
            children: children,
            componentDisplayName: helpTextDisplayName,
          })}
          <CKLayoutHorizontal className="px-2 pt-2">
            <div>
              {hasMenuComponent ? (
                <CKButton
                  leftIcon={<AddBlock />}
                  onClick={togglePopover}
                  variant="tertiary"
                  variantColor="blue"
                >
                  Add filters
                </CKButton>
              ) : null}
            </div>
            <div>
              <CKButton
                leftIcon={<RemoveHollow />}
                onClick={handleClear}
                variant="tertiary"
                variantColor="blue"
              >
                Clear filters
              </CKButton>
            </div>
          </CKLayoutHorizontal>
        </CKDetailGroup.Group>
      ) : null}
    </CKDetailGroup>
  );
};

Filter.Icon = ({ children, ...rest }) => {
  return (
    <span className="flex items-center" {...rest}>
      {cloneChildren({
        children: children,
        newProps: {
          height: 18,
          width: 18,
        },
      })}
    </span>
  );
};
Filter.Icon.displayName = iconDisplayName;

Filter.Input = ({ className, ...rest }) => {
  return <div {...rest} className={classnames(className)} />;
};
Filter.Input.displayName = inputDisplayName;

Filter.Title = ({ children, ...rest }) => {
  return (
    <span className="flex items-center min-w-0 shrink" {...rest}>
      {children}
    </span>
  );
};
Filter.Title.displayName = titleDisplayName;

Filter.HelpText = ({ children, className, ...rest }) => (
  <div {...rest} className={classnames(className, 'text-sm')}>
    {children}
  </div>
);
Filter.HelpText.displayName = helpTextDisplayName;

Filter.Menu = ({ children, ...rest }) => {
  return <div {...rest}>{children}</div>;
};
Filter.Menu.displayName = menuDisplayName;
