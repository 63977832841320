import classnames from 'classnames';
import {
  CKContainerProps,
  CKLayoutGrid,
  CKLayoutHorizontal,
  excludeChildrenByDisplayName,
  includeChildrenByDisplayName,
} from 'clearkit';
import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { routes } from '~/components/app/appMainRoutes';

type TargetMarketSidebarProps = CKContainerProps;

type BreadcrumbsProps = CKContainerProps & {
  title?: ReactNode;
  handleReset: () => void;
};

type TargetMarketSidebarComposition = {
  Breadcrumbs: FC<BreadcrumbsProps>;
  Footer: FC<CKContainerProps>;
};

const breadcrumbsDisplayName = 'TargetMarketSidebar.Breadcrumbs';
const footerDisplayName = 'TargetMarketSidebar.Footer';

export const TargetMarketSidebar: FC<TargetMarketSidebarProps> &
  TargetMarketSidebarComposition = ({
  children,
  className,
  title,
  ...rest
}) => {
  return (
    <CKLayoutGrid
      {...rest}
      as="aside"
      className={classnames(className, 'w-[20rem] border-r border-gray-200')}
      gap="0"
      gridTemplateRows="auto 1fr auto"
    >
      <header className="px-6 pt-4 pb-6 space-y-2">
        <div className="min-h-[1.325rem]">
          {includeChildrenByDisplayName({
            children,
            componentDisplayName: breadcrumbsDisplayName,
          })}
        </div>

        <h1 className="font-semibold text-[1.5rem]">
          {title || 'Target Markets'}
        </h1>
      </header>
      <div className="ck-scrollbar ck-scrollbar--vertical">
        {excludeChildrenByDisplayName({
          children,
          componentDisplayName: [breadcrumbsDisplayName, footerDisplayName],
        })}
      </div>
      {includeChildrenByDisplayName({
        children,
        componentDisplayName: footerDisplayName,
      })}
    </CKLayoutGrid>
  );
};

TargetMarketSidebar.Breadcrumbs = ({
  children,
  className,
  handleReset,
  ...rest
}) => (
  <CKLayoutHorizontal
    className={classnames(className, 'text-sm  text-gray-600 overflow-hidden')}
    gap=".5rem"
    justifyContent="start"
    wrap="nowrap"
    {...rest}
  >
    <Link
      className="font-medium !text-blue basis-auto shrink-0"
      onClick={handleReset}
      to={routes.targetMarkets}
    >
      Target Markets
    </Link>
    <span>/</span>
    <span className="block min-w-0 font-medium text-gray-900 truncate basis-full shrink grow whitespace-nowrap">
      {children}
    </span>
  </CKLayoutHorizontal>
);
TargetMarketSidebar.Breadcrumbs.displayName = breadcrumbsDisplayName;

TargetMarketSidebar.Footer = ({ children, className, ...rest }) => (
  <footer
    className={classnames(className, 'px-6 py-3 border-t border-gray-200')}
    {...rest}
  >
    {children}
  </footer>
);
TargetMarketSidebar.Footer.displayName = footerDisplayName;
