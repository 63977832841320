import { CKAvatar } from 'clearkit';

type CaptureProspect = {
  id: string;
  name: string;
  title: string;
  avatar: string;
};

type CaptureProspectsProps = {
  prospects?: CaptureProspect[];
};

const Prospect = ({
  key,
  prospect,
}: {
  key: string;
  prospect: CaptureProspect;
}) => {
  const { name, title, avatar } = prospect;

  return (
    <div className="text-center" key={key}>
      <div className="flex justify-center m-auto mb-3">
        <CKAvatar alt={name} size={64} src={avatar} />
      </div>
      <h3 className="mb-1 text-base text-gray-900 ck-line-clamp" title={name}>
        {name}
      </h3>
      <h4
        className="text-xs text-gray-600 ck-line-clamp"
        style={{ ['--line-clamp' as string]: 2 }}
        title={title}
      >
        {title}
      </h4>
    </div>
  );
};

export function CaptureProspects({ prospects }: CaptureProspectsProps) {
  if (!prospects) {
    return null;
  }

  return (
    <div className="items-start grid gap-4 grid-cols-5">
      {prospects.map((prospect) => (
        <Prospect key={prospect.id} prospect={prospect} />
      ))}
    </div>
  );
}
