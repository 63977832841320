import { CKPicker, CKSwitch, CKTab, CKTabList, CKTabs } from 'clearkit';

import FacebookLogo from '~/images/destinations/Facebook.svg';
import { destinationTypeFacebookConversion } from '~/lib/destinations';
import { hasError } from '~/lib/graphql';
import { readableSingularSegmentType } from '~/lib/segments';
import Disconnected from '~/pages/destinations/components/Disconnected';
import TriggerType from '~/pages/destinations/components/TriggerType';

import ContentBox from '../../components/ContentBox';
import DebugConsole from '../../components/ConversionDebugConsole';
import FieldLabel from '../../components/FieldLabel';
import FormContentWrapper from '../../components/FormContentWrapper';
import FormHeader from '../../components/FormHeader';
import MoneyInput from '../../components/MoneyInput';
import EventTypePicker from './EventTypePicker';

function FacebookConversion({
  accountConnectors,
  errors,
  destination,
  refetch,
  segment,
  destinationOptions,
  FormFooter,
}) {
  const accountConnector = accountConnectors.facebook;
  const isNew = !destination;
  const initialConfig = isNew ? {} : destination.config;

  const initialState = {
    eventName: initialConfig.eventName || '',
    facebookAdAccountId: initialConfig.facebookAdAccountId || null,
    facebookAdPixelId: initialConfig.facebookAdPixelId || null,
    leadValue: initialConfig.leadValue || 0,
    leadValueCurrency: initialConfig.leadValueCurrency || 'USD',
    limitedDataUse: initialConfig.limitedDataUse || true,
    triggerType: isNew ? 'segment_enter' : destination.triggerType,
  };

  const {
    facebookCurrencyOptions,
    facebookEventTypeOptions,
  } = destinationOptions;

  const [eventName, setEventName] = useState(initialState.eventName);
  const [facebookAdAccountId, setFacebookAdAccountId] = useState(
    initialState.facebookAdAccountId,
  );
  const [facebookAdPixelId, setFacebookAdPixelId] = useState(
    initialState.facebookAdPixelId,
  );
  const [leadValue, setLeadValue] = useState(initialState.leadValue);
  const [leadValueCurrency, setLeadValueCurrency] = useState(
    initialState.leadValueCurrency,
  );
  const [limitedDataUse, setLimitedDataUse] = useState(
    initialState.limitedDataUse,
  );
  const [triggerType, setTriggerType] = useState(initialState.triggerType);

  const [tabIndex, setTabIndex] = useState(0);

  const facebookAdAccounts = accountConnector?.adAccounts || [];
  const facebookAdAccount = facebookAdAccounts.find((adAccount) => {
    return adAccount.id === facebookAdAccountId;
  });
  const facebookAdPixel = facebookAdAccount?.adPixels?.find(
    (pixel) => pixel.id === facebookAdPixelId,
  );
  const facebookAdPixelOptions =
    facebookAdAccount?.adPixels.map((pixel) => ({
      label: pixel.name,
      value: pixel.id,
    })) || [];

  const humanizedSegmentType = readableSingularSegmentType(segment.type);
  const destinationFlowDescription = destination
    ? 'Will send event to Facebook'
    : null;

  if (!accountConnector || accountConnector?.status !== 'active') {
    return (
      <Disconnected
        connectorStatus={accountConnector?.status}
        FormFooter={FormFooter}
        Logo={FacebookLogo}
        onSuccess={refetch}
        service="facebook"
      />
    );
  }

  return (
    <>
      <FormHeader
        destinationFlowDescription={destinationFlowDescription}
        destinationName="Facebook"
        destinationType={destinationTypeFacebookConversion}
        segmentCollection={segment.collection.name}
        segmentName={segment.name}
      />
      {isNew ? null : (
        <CKTabs index={tabIndex} isBordered onChange={setTabIndex}>
          <CKTabList className="flex-shrink-0 px-10 -mt-4">
            <CKTab label="Configure" value="form">
              Configure
            </CKTab>
            <CKTab label="Debug" value="debug">
              Debug
            </CKTab>
          </CKTabList>
        </CKTabs>
      )}
      {tabIndex === 0 ? (
        <>
          <FormContentWrapper>
            <h2 className="text-base font-medium text-gray-600">Trigger</h2>
            <span className="text-sm text-gray-600">
              Select the event that triggers this Destination to run.{' '}
              <a
                href="https://help.clearbit.com/hc/en-us/articles/360023453694"
                rel="noopener noreferrer"
                style={{ textDecoration: 'underline' }}
                target="_blank"
              >
                Learn more
              </a>
            </span>

            <TriggerType
              errors={errors}
              handleChange={setTriggerType}
              triggerType={triggerType}
              triggerTypes={[
                {
                  value: 'segment_enter',
                  label: (
                    <span>
                      When a {humanizedSegmentType} <strong>enters</strong> the
                      audience
                    </span>
                  ),
                },
              ]}
            />

            <h2 className="mb-6 text-base font-medium text-gray-600">
              Configure Facebook Conversion
            </h2>

            <ContentBox>
              <div className="mb-4">
                <div className="flex mb-4">
                  <div className="flex-1 mr-2">
                    <FieldLabel
                      for="facebookAccount-input"
                      label="Facebook account *"
                    />
                    <CKPicker
                      id="facebookAccount"
                      items={facebookAdAccounts.map((adAccount) => ({
                        label: adAccount.name,
                        value: adAccount.id,
                      }))}
                      itemToString={(item) => item.label}
                      onSelectedItemChange={({ selectedItem }) => {
                        setFacebookAdAccountId(selectedItem.value);
                      }}
                      placeholder="Choose a Facebook account"
                      selectedItem={
                        facebookAdAccount && {
                          label: facebookAdAccount.name,
                          value: facebookAdAccount.id,
                        }
                      }
                      variant={
                        hasError(errors, 'facebookAdAccountId') ? 'error' : null
                      }
                    />
                  </div>
                  <div className="flex-1 ml-2">
                    <FieldLabel
                      for="facebookPixel-input"
                      label="Facebook pixel *"
                    />
                    <CKPicker
                      id="facebookPixel"
                      isDisabled={facebookAdPixelOptions.length === 0}
                      items={facebookAdPixelOptions}
                      itemToString={(item) => item.label}
                      onSelectedItemChange={({ selectedItem }) => {
                        setFacebookAdPixelId(selectedItem.value);
                      }}
                      placeholder={
                        facebookAdAccountId &&
                        facebookAdPixelOptions.length === 0
                          ? 'No Facebook pixels for this account'
                          : 'Choose a Facebook pixel'
                      }
                      selectedItem={
                        facebookAdPixel && {
                          label: facebookAdPixel.name,
                          value: facebookAdPixel.id,
                        }
                      }
                      variant={
                        hasError(errors, 'facebookAdPixelId') ? 'error' : null
                      }
                    />
                  </div>
                </div>

                <div className="flex mb-4">
                  <div className="flex-1 mr-2">
                    <EventTypePicker
                      error={hasError(errors, 'eventName')}
                      onChange={(value) => {
                        setEventName(value);
                      }}
                      options={facebookEventTypeOptions}
                      selectedEventType={eventName}
                    />
                  </div>
                  <div className="flex flex-1 ml-2">
                    <div className="flex-1 mr-2">
                      <FieldLabel for="leadValue" label="Lead value" />
                      <MoneyInput
                        id="leadValue"
                        onChange={(value) => {
                          setLeadValue(value);
                        }}
                        placeholder="Enter a value"
                        value={leadValue}
                      />
                    </div>
                    <div className="flex-1 ml-2">
                      <FieldLabel
                        for="valueCurrency-input"
                        label="Value currency"
                      />
                      <CKPicker
                        id="valueCurrency"
                        items={facebookCurrencyOptions.map((currency) => ({
                          label: currency,
                          value: currency,
                        }))}
                        itemToString={(item) => item.label}
                        onSelectedItemChange={({ selectedItem }) => {
                          setLeadValueCurrency(selectedItem.value);
                        }}
                        placeholder="--"
                        selectedItem={{
                          label: leadValueCurrency,
                          value: leadValueCurrency,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="py-6 my-6 border-t border-b">
                <div className="mr-2">
                  <FieldLabel label="Facebook Limited Data Use" />
                  <div className="my-4">
                    <CKSwitch
                      checked={limitedDataUse}
                      id="ldu"
                      onChange={() => {
                        setLimitedDataUse(!limitedDataUse);
                      }}
                    >
                      {limitedDataUse ? 'Enabled' : 'Disabled'}
                    </CKSwitch>
                  </div>
                </div>
                <div className="text-sm text-gray-500">
                  Facebook offers limited data use processing in connection with
                  the California Consumer Privacy Act (CCPA) that limits the use
                  of data that they process through the provision of its
                  services. You can learn more about Facebook's limited data use{' '}
                  <a
                    href="https://www.facebook.com/business/help/1151133471911882"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    here
                  </a>
                  .
                </div>
              </div>
              <div className="text-sm italic text-gray-500">
                * Required fields
              </div>
            </ContentBox>
          </FormContentWrapper>
          <FormFooter
            destinationConfig={{
              config: {
                eventName,
                leadValue,
                leadValueCurrency,
                facebookAdAccountId,
                facebookAdPixelId,
                limitedDataUse,
              },
              triggerType,
            }}
          />
        </>
      ) : (
        <DebugConsole destination={destination} segment={segment} />
      )}
    </>
  );
}

FacebookConversion.propTypes = {
  destination: PropTypes.object,
  destinationOptions: PropTypes.object,
  errors: PropTypes.array.isRequired,
  refetch: PropTypes.func.isRequired,
  segment: PropTypes.object.isRequired,
  accountConnectors: PropTypes.shape({
    facebook: PropTypes.shape({
      adAccounts: PropTypes.array,
      status: PropTypes.string,
    }),
  }),
};

export default FacebookConversion;
