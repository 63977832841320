import FormHeader from '../components/FormHeader';

function SkeletonDestinationForm() {
  return (
    <>
      <FormHeader
        destinationType="email"
        segmentCollection="Example Collection"
        segmentName="Example Audience"
        subtitle="Send data to a destination"
      />
    </>
  );
}

SkeletonDestinationForm.propTypes = {
  segmentType: PropTypes.string,
};

export default SkeletonDestinationForm;
