import { AccountFlagEnum } from '~/generated/graphql';
import useFeatureFlag from '~/hooks/account/useFeatureFlag';

import FieldMappingGroup from '../FieldMappingGroup';
import FieldMappingRow from '../FieldMappingRow';

const FieldMappingFormFields = ({
  integration,
  fieldErrors,
  sortedMappings,
  handleMappingChange,
  applyChangesToAllGroupMembers,
  removeMappingGroup,
  removeMapping,
  addMapping,
  objectType,
  sourceAttributes,
  targetAttributes,
  showInvalidFields,
  onClickRefreshAttributes,
}) => {
  const enableConditionalOverwrite = useFeatureFlag(
    AccountFlagEnum.MarketoConditionalOverwrite,
  ).enabled;

  return (
    <div className="space-y-6">
      {sortedMappings.map((mapping) => {
        if (mapping.groupId) {
          return (
            <FieldMappingGroup
              addMapping={addMapping}
              enableConditionalOverwrite={enableConditionalOverwrite}
              errors={fieldErrors}
              group={mapping}
              integration={integration}
              key={`group:${mapping.groupId}`}
              objectType={objectType}
              onChange={handleMappingChange}
              onGroupChange={applyChangesToAllGroupMembers(mapping.groupId)}
              onRemove={removeMappingGroup(mapping.groupId)}
              onRemoveMapping={removeMapping}
              showError={showInvalidFields}
              sourceAttributes={sourceAttributes || []}
              targetAttributes={targetAttributes || []}
              type={integration}
            />
          );
        }

        return (
          <FieldMappingRow
            enableConditionalOverwrite={enableConditionalOverwrite}
            index={mapping.index}
            integration={integration}
            key={`${mapping.targetField || 'empty'}
          :${mapping.sourceField || 'empty'}
          -${mapping.index}`}
            mapping={mapping}
            objectType={objectType}
            onChange={handleMappingChange(mapping.index)}
            onClickRefreshAttributes={onClickRefreshAttributes}
            onRemove={removeMapping(mapping.index)}
            showError={showInvalidFields}
            sourceAttributes={sourceAttributes || []}
            sourceErrors={fieldErrors[mapping.sourceField]}
            targetAttributes={targetAttributes || []}
            targetErrors={fieldErrors[mapping.targetField]}
            type={integration}
          />
        );
      })}
    </div>
  );
};

export default FieldMappingFormFields;
