import { DailyUsage, useEnrichmentRecentUsageQuery } from '~/generated/graphql';

export const useEnrichmentRecentUsage = () => {
  const { data, loading } = useEnrichmentRecentUsageQuery();

  const chartData = data?.enrichmentRecentUsage;

  let maxValue = 0;

  chartData?.map((datum: DailyUsage) => {
    maxValue = Math.max(maxValue, datum.usage ?? 0) * 1.05;
  });

  return {
    chartData,
    loading,
    maxValue,
  };
};
