import { CKLayoutGridAuto } from 'clearkit';

import { useEntitlements } from '~/hooks/account';

import {
  ListEnrichment,
  ListForms,
  ListTargetMarkets,
  ListVisitors,
} from '../GetStartedChecklists';

export const HomeSectionOnboarding = () => {
  const { hasAccessEnrichmentHubspot } = useEntitlements();

  return (
    <section className="space-y-6">
      <CKLayoutGridAuto gap="3rem" minWidth="25rem">
        {hasAccessEnrichmentHubspot ? <ListEnrichment /> : null}
        <ListForms />
        <ListTargetMarkets />
        <ListVisitors />
      </CKLayoutGridAuto>
    </section>
  );
};
