import { CKTextField } from 'clearkit';

export default function MoneyInput({ value, onChange, ...inputProps }) {
  return (
    <CKTextField
      onChange={(e) => {
        const val = Math.round(Number(e.target.value) * 100);
        onChange(val);
      }}
      type="number"
      value={value ? value / 100 : null}
      {...inputProps}
    />
  );
}
