import { Caret, Hubspot, Salesforce } from '@clearkit/icons';
import { CKActionMenu, CKButton, CKCompanyLogo, CKPopover } from 'clearkit';

import HubspotCompanyListItem from '~/components/ProfileHeaderSalesforceHubspotMenuItem/hubspotCompany';
import SalesforceContactMenuItem from '~/components/ProfileHeaderSalesforceHubspotMenuItem/salesforceContact';
import SalesforceLeadListItem from '~/components/ProfileHeaderSalesforceHubspotMenuItem/salesforceLead';

import styles from './styles';

const Actions = ({
  classes = {},
  person,
  hubspotOwners,
  hubspotCompanies,
  salesforceContacts,
  salesforceAccountConnector,
  salesforceLeads,
  salesforceAccounts,
}) => {
  const numberOfLeads = salesforceLeads?.length || 0;
  const numberOfContacts = salesforceContacts?.length || 0;

  return (
    <div className="flex flex-row">
      {(numberOfContacts > 0 || numberOfLeads > 0) && (
        <CKPopover
          aria-label="Popover"
          className="z-20 overflow-y-auto popover-max-height"
          placement="bottom-end"
          popover={
            <CKActionMenu
              aria-label="Default Menu"
              hideOnClickOutside={false}
              visible
            >
              <>
                {salesforceLeads.length > 0 && (
                  <div>
                    <span className="block px-4 pt-1 text-sm font-medium text-gray">
                      {salesforceLeads.length} lead
                      {salesforceLeads.length != 1 && 's'}
                    </span>
                    {salesforceAccountConnector &&
                      salesforceLeads?.map((salesforceLead) => (
                        <SalesforceLeadListItem
                          key={salesforceLead.externalId}
                          salesforceAccountConnector={
                            salesforceAccountConnector
                          }
                          salesforceLead={salesforceLead}
                        />
                      ))}
                  </div>
                )}

                {salesforceAccounts?.map((salesforceAccount, i) => {
                  const contacts = salesforceContacts?.filter(
                    (contact) =>
                      contact.salesforceAccount?.properties?.Id ===
                      salesforceAccount.properties.Id,
                  );
                  return (
                    <div className="flex flex-col" key={i}>
                      {contacts?.length > 0 && (
                        <>
                          <CKActionMenu.Divider />
                          <span className="px-4 pt-1 pt-2 text-sm font-medium text-gray">
                            <div className="flex flex-row items-center space-x-2">
                              <CKCompanyLogo
                                className="shadow-sm"
                                domain={
                                  salesforceAccount.websiteDomain ||
                                  person.company?.domain
                                }
                                size={24}
                              />
                              <span>
                                <span className="font-semibold">
                                  {salesforceAccount?.name}
                                </span>{' '}
                                account
                              </span>
                            </div>
                          </span>
                          <span className="px-4 pt-1 pt-2 text-sm font-medium text-gray">
                            {salesforceContacts?.length}{' '}
                            {salesforceContacts?.length === 1
                              ? 'contact'
                              : 'contacts'}
                          </span>
                          {salesforceContacts?.map((salesforceContact) => (
                            <SalesforceContactMenuItem
                              key={salesforceContact.externalId}
                              salesforceAccountConnector={
                                salesforceAccountConnector
                              }
                              salesforceContact={salesforceContact}
                            />
                          ))}
                        </>
                      )}
                    </div>
                  );
                })}
              </>
            </CKActionMenu>
          }
        >
          <CKButton
            className={classnames(classes.salesforceButton, 'ml-4')}
            leftIcon={<Salesforce className="mr-1" />}
            rightIcon={<Caret className="-mr-1" />}
          >
            Salesforce
          </CKButton>
        </CKPopover>
      )}
      {hubspotCompanies?.length > 0 && (
        <CKPopover
          aria-label="Popover"
          className="z-20 overflow-y-auto popover-max-height"
          gutter={4}
          placement="bottom-end"
          popover={
            <CKActionMenu
              aria-label="Default Menu"
              hideOnClickOutside={false}
              visible
            >
              {hubspotCompanies?.map((hubspotCompany) => (
                <HubspotCompanyListItem
                  hubspotCompany={hubspotCompany}
                  hubspotOwners={hubspotOwners}
                  key={hubspotCompany.id}
                />
              ))}
            </CKActionMenu>
          }
        >
          <CKButton
            className={classnames(classes.hubspotButton, 'ml-4')}
            leftIcon={<Hubspot className="mr-1" />}
            rightIcon={<Caret className="-mr-1" />}
          >
            HubSpot
          </CKButton>
        </CKPopover>
      )}
    </div>
  );
};

Actions.propTypes = {
  classes: PropTypes.object,
  person: PropTypes.shape({
    company: PropTypes.shape({
      domain: PropTypes.string,
    }),
  }),
  hubspotCompanies: PropTypes.array,
  salesforceLeads: PropTypes.array,
  salesforceAccounts: PropTypes.array,
  salesforceContacts: PropTypes.array,
  hubspot: PropTypes.object,
  hubspotOwners: PropTypes.array,
  salesforceAccountConnector: PropTypes.shape({
    externalInstanceUrl: PropTypes.string,
  }),
};

export default withStyles(styles)(Actions);
