import { capitalize } from 'lodash';

export const segmentWebhookUrl = (pubKey: string) =>
  `https://app.clearbit.com/v1/webhooks/segment?authorization=${pubKey}`;

export function tagsjsSnippet(pubKey: string, isGTMIntegration: boolean) {
  const domain = getTagsSourceDomain();
  const { protocol } = window.location;
  const src = `${protocol}//${domain}/v1/${pubKey}/tags.js`;

  return `<script src="${src}"${
    // Google Tag Manager doesn't support referrerpolicy attribute in a script tag
    // If you include it when setting a script in GTM, it will throw an invalid HTML error
    isGTMIntegration ? '' : ' referrerpolicy="strict-origin-when-cross-origin"'
  }></script>`;
}

const getTagsSourceDomain = () => {
  const { hostname, port } = window.location;

  if (hostname === 'x.clearbit.com' || hostname === 'app.clearbit.com') {
    return 'tag.clearbitscripts.com';
  } else if (hostname === 'localhost') {
    return `localhost:${port}`;
  }

  return hostname;
};

export const accountConnectors = {
  salesforce: 'salesforce',
  hubspot: 'hubspot',
  marketo: 'marketo',
  slack: 'slack',
  google: 'google',
  facebook: 'facebook',
  g2: 'g2',
} as const;

export type AccountConnectorService = typeof accountConnectors[keyof typeof accountConnectors];

export const configurations = {
  javascript: 'javascript',
  segment: 'segment',
  g2: 'g2',
  googleAds: 'google_ads',
  googleAnalytics: 'google_analytics',
  googleConversion: 'google_conversion',
  googleTagManager: 'google_tag_manager',
  google: 'google',
  pardot: 'pardot',
  salesforce: 'salesforce',
  salesforcesandbox: 'salesforcesandbox',
  slack: 'slack',
  facebook: 'facebook',
  hubspot: 'hubspot',
  marketo: 'marketo',
} as const;

export type Configuration = typeof configurations[keyof typeof configurations];

export const connectorStatuses = {
  active: 'active',
  disconnected: 'disconnected',
  error: 'error',
  badAuth: 'bad_auth',
  hitQuota: 'hit_quota',
  missingSetup: 'account_missing_setup',
} as const;

export type ConnectorStatus = typeof connectorStatuses[keyof typeof connectorStatuses];

export const dataSources = {
  clearbitjs: 'clearbitjs',
  googleTagManager: 'google_tag_manager',
  segment: 'segment',
} as const;

export type DataSource = typeof dataSources[keyof typeof dataSources];

export const googleAccountTypes = {
  google360: 'analytics_360_suite',
  standard: 'standard',
} as const;

export const dimensionMappingStatuses = {
  active: 'active',
  paused: 'paused',
} as const;

export const DIMENSION_LIMITS = {
  [googleAccountTypes.standard]: 20,
  [googleAccountTypes.google360]: 200,
} as const;

export const revealTypeAttribute = {
  attributeType: 'Company',
  category: 'clearbit',
  label: 'Type',
  subCategory: null,
  value: 'type',
  __typename: 'Attribute',
} as const;

export const defaultDimensionMapping = [
  {
    attribute: 'type',
    dimension: {
      label: 'Clearbit Type',
      index: null,
      value: null,
    },
  },
  {
    attribute: 'company->name',
    dimension: {
      label: 'Clearbit Company Name',
      index: null,
      value: null,
    },
  },
  {
    attribute: 'company->domain',
    dimension: {
      label: 'Clearbit Company Domain',
      index: null,
      value: null,
    },
  },
  {
    attribute: 'company->metrics->employees_range',
    dimension: {
      label: 'Clearbit Company Employees Range',
      index: null,
      value: null,
    },
  },
  {
    attribute: 'company->category->sub_industry',
    dimension: {
      label: 'Clearbit Company Sub Industry',
      index: null,
      value: null,
    },
  },
  {
    attribute: 'company->tech',
    dimension: {
      label: 'Clearbit Company Tech',
      index: null,
      value: null,
    },
  },
] as const;

export function syncInProgress(accountConnector: any) {
  const remoteMeta = accountConnector?.remoteMeta;
  const syncStatus = remoteMeta?.syncStatus;

  switch (accountConnector?.service) {
    case configurations.salesforce:
    case configurations.salesforcesandbox:
    case configurations.hubspot:
      return !syncStatus || syncStatus === 'syncing';
    default:
      return false;
  }
}

export function configurationNameForType(type: Configuration): string {
  switch (type) {
    case configurations.javascript:
      return 'JavaScript';
    case configurations.segment:
      return 'Segment';
    case configurations.g2:
      return 'G2';
    case configurations.googleAds:
      return 'Google Ads';
    case configurations.googleAnalytics:
      return 'Google Analytics 4';
    case configurations.googleTagManager:
      return 'Google Tag Manager';
    case configurations.google:
      return 'Google Analytics';
    case configurations.googleConversion:
      return 'Google Ad Conversions';
    case configurations.salesforce:
    case configurations.salesforcesandbox:
      return 'Salesforce';
    case configurations.slack:
      return 'Slack';
    case configurations.facebook:
      return 'Facebook';
    case configurations.hubspot:
      return 'HubSpot';
    case configurations.pardot:
      return 'Pardot';
    case configurations.marketo:
      return 'Marketo';
  }
}

export function purposeForConnectorService(service: Configuration) {
  switch (service) {
    case configurations.salesforce:
    case configurations.salesforcesandbox:
      return 'syncing Salesforce data to Clearbit';
    case configurations.g2:
      return 'and bring powerful intent data into Clearbit to create powerful intent-based audiences';
    case configurations.google:
      return 'mapping to Google Tag Manager';
    case configurations.googleConversion:
      return 'tracking conversion actions with Google Ads';
    case configurations.hubspot:
      return 'syncing HubSpot data to Clearbit';
    case configurations.slack:
      return 'triggering destinations to Slack';
    case configurations.facebook:
      return 'syncing Facebook audiences and destinations';
    case configurations.marketo:
      return 'enriching and shortening Marketo forms';
    default:
      return `utilizing ${capitalize(service)}`;
  }
}
