const styles = () => ({
  field: {
    margin: '0 0 30px',

    '&:last-of-type': {
      margin: 0,
    },
  },

  bodyInput: {
    height: 159,
  },

  formControl: {
    display: 'block',
  },

  select: {
    display: 'block',
    margin: '0 0 30px',
    width: 340,
  },

  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  markdownCopy: {
    fontSize: 14,
    color: '#596168',
    margin: '16px 0 4px 0',
  },

  link: {
    textDecoration: 'underline',
  },

  // the next styles will be injected into the SuppressionForm

  selectMenuPaper: {
    borderRadius: 10,
  },

  selectLabel: {
    position: 'relative',
    top: -7,
  },

  contentBox: {
    background: '#fff',
    border: '1px solid rgba(89, 97, 104, 0.15)',
    borderRadius: 8,
    margin: '24px 0 24px',
    padding: '20px 30px 30px',
  },

  break: {
    border: '0.5px solid rgba(89, 97, 104, 0.15)',
    margin: '20px -30px 30px -30px',
  },

  radioRoot: {
    padding: 0,
    marginRight: 12,
  },

  formControlLabelRoot: {
    margin: '0 0 8px 9px',

    '&:last-of-type': {
      marginBottom: 0,
    },
  },

  formControlLabel: {
    color: '#27282a',
    fontSize: 15,
  },

  question: {
    marginLeft: 9,
  },

  radioLabel: {
    display: 'flex',
    alignItems: 'center',
  },

  errorText: {
    margin: '0 0 0 9px',
  },

  subheading: {
    color: '#596168',
    flex: '0 0 auto',
    fontSize: 15,
    fontWeight: 500,
    margin: '0 0 7px',
  },

  triggerSubtext: {
    color: '#596168',
    fontSize: 14,
  },
});

export default styles;
