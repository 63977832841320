export default () => ({
  wrapper: {
    background: '#fafbfc',
    border: '1px solid rgba(89, 97, 104, 0.15)',
    borderRadius: 8,
    padding: '24px 32px 16px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  break: {
    width: '100%',
    height: '1px',
    background: '#E7E8E9',
    marginBottom: '24px',
  },

  group: {
    marginBottom: 24,
    border: '1px solid #e1e9f0',
    borderRadius: 6,
    background: '#ffffff',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    width: '100%',
  },

  groupHalfWidth: {
    width: 'calc(50% - 8px)',
    '& + &': {
      marginLeft: 16,
    },
  },

  section: {
    borderBottom: '1px solid #e1e9f0',
    padding: 16,

    '&:first-child': {
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      borderTop: 'none',
    },

    '&:last-child': {
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,
      borderBottom: 'none',
    },

    '& p': {
      margin: 0,
    },
  },

  sectionHorizontal: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 12,
    paddingTop: 12,
    paddingLeft: 16,
  },

  greyBackground: {
    background: '#fafbfc',
  },

  icon: {
    height: 40,
    marginRight: 16,
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      height: 40,
      width: 40,
    },
  },

  title: {
    display: 'flex',
    flexDirection: 'column',
  },
});
