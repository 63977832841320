import { ApolloProvider } from '@apollo/client';
import { CKIconGradient } from '@clearkit/icons';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { CKLayoutGrid, ToastProvider } from 'clearkit';
import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Router } from 'react-router-dom';
import { Provider } from 'unstated';

import ToastStack from '~/components/ToastStack';
import apollo from '~/lib/apollo';
import { isAuthRoute } from '~/lib/client/auth';
import history from '~/lib/history';
import CreateHubspotProfile from '~/pages/authentication/CreateHubspotProfile';
import CreateProfile from '~/pages/authentication/CreateProfile';
import AuthDiscovery from '~/pages/authentication/Discovery';
import Login from '~/pages/authentication/Login';
import SignUp from '~/pages/authentication/Signup';
import SignupsBlocked from '~/pages/authentication/SignupsBlocked';
import SwitchAccount from '~/pages/authentication/SwitchAccount';
import TOTPMultiFactorFlow from '~/pages/authentication/TOTPMultiFactorFlow';

import { AppRoutes } from './app/AppRoutes';
import Gatekeeper from './app/Gatekeeper';
import theme from './app/theme';
import ErrorHandler from './errorHandler';
import ImpersonationWarning from './ImpersonationWarning';
import NavBar from './NavBar/NavBar';

const queryClient = new QueryClient({});

function AppWrapperStack({ children }: { children: React.ReactNode }) {
  const isAuthenticating = isAuthRoute();

  return (
    <QueryClientProvider client={queryClient}>
      <ApolloProvider client={apollo}>
        <Provider>
          <Helmet titleTemplate="%s – Clearbit" />

          <CKIconGradient />
          <ToastStack />
          <ToastProvider className="z-[10000]">
            <Router history={history}>
              <Route component={SignUp} exact path="/auth/signup" />
              <Route component={Login} exact path="/auth/login" />
              <Route
                component={SignupsBlocked}
                exact
                path="/auth/signups_blocked"
              />
              <Route component={AuthDiscovery} exact path="/auth/discover" />
              <Route component={TOTPMultiFactorFlow} exact path="/auth/mfa" />
              <Route
                component={CreateProfile}
                exact
                path="/auth/signup/profile"
              />
              <Route
                component={CreateHubspotProfile}
                exact
                path="/auth/signup/setup_profile"
              />
              <Route
                component={SwitchAccount}
                exact
                path="/auth/switch_account/:accountId"
              />
              <Route
                component={SwitchAccount}
                exact
                path="/auth/login/:accountId/upgrade_auth_factors"
              />

              {isAuthenticating ? null : (
                <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
              )}
              <Route
                path="/"
                render={() => {
                  if (
                    window.analytics &&
                    typeof window.analytics.page === 'function'
                  ) {
                    window.analytics.page(history.location.pathname);
                  }
                  return null;
                }}
              />
            </Router>
          </ToastProvider>
        </Provider>
      </ApolloProvider>
    </QueryClientProvider>
  );
}

function App() {
  return (
    <AppWrapperStack>
      <Gatekeeper />
      <CKLayoutGrid
        className="h-full min-w-0 min-h-0 overflow-hidden"
        gap="0"
        gridTemplate="'sidebar main' 1fr / auto 1fr"
      >
        <ImpersonationWarning />
        <NavBar />

        <ErrorHandler>
          <article className="flex overflow-auto" style={{ gridArea: 'main' }}>
            <AppRoutes />
          </article>
        </ErrorHandler>
      </CKLayoutGrid>
    </AppWrapperStack>
  );
}

export default App;
