import styles from './Table.style';

class Table extends React.Component {
  getClassName() {
    const { classes, className: classNameProp, sidebar } = this.props;
    return classnames(
      classes.root,
      { [classes.sidebar]: sidebar },
      classNameProp,
    );
  }

  render() {
    const {
      classes: _classes,
      className: _className,
      children,
      sidebar: _sidebar,
      ...props
    } = this.props;

    return (
      <div {...props} className={this.getClassName()}>
        {children}
      </div>
    );
  }
}

class TableBody extends React.Component {
  getChildContext() {
    return {
      table: {
        body: true,
      },
    };
  }

  render() {
    const { classes, children } = this.props;

    return <div className={classes.tableBody}>{children}</div>;
  }
}

TableBody.childContextTypes = {
  table: PropTypes.object,
};

class TableHead extends React.Component {
  getChildContext() {
    return {
      table: {
        head: true,
      },
    };
  }

  getClassName() {
    const { classes, className: classNameProp } = this.props;

    return classnames(classes.tableHead, classNameProp);
  }

  render() {
    const { children } = this.props;

    return <div className={this.getClassName()}>{children}</div>;
  }
}

TableHead.childContextTypes = {
  table: PropTypes.object,
};

class TableRow extends React.Component {
  getClassName() {
    const {
      classes,
      hover,
      selected,
      animatable,
      className: classNameProp,
      inheritColor,
    } = this.props;

    return classnames(
      classes.tableRow,
      {
        [classes.tableRowTypeBodyHover]: hover,
        [classes.tableRowTypeBodySelected]: selected,
        [classes.tableRowAnimateIn]: animatable,
        [classes.tableRowInheritColor]: inheritColor,
      },
      classNameProp,
    );
  }

  render() {
    const {
      children,
      hover: _hover,
      animatable: _animatable,
      classes: _classes,
      className: _className,
      inheritColor: _inheritColor,
      ...props
    } = this.props;

    return (
      <div {...props} className={this.getClassName()}>
        {children}
      </div>
    );
  }
}

TableRow.defaultProps = {
  hover: false,
};

class TableCell extends React.Component {
  getClassName() {
    const {
      classes,
      sub,
      link,
      small,
      tiny,
      slim,
      className,
      leftAligned,
      rightAligned,
    } = this.props;

    const { table } = this.context;

    return classnames(
      classes.tableCell,
      {
        [classes.tableCellTypeHead]: table && table.head,
        [classes.tableCellTypeBody]: table && table.body,
        [classes.tableCellSub]: sub,
        [classes.tableCellLink]: link,
        [classes.tableCellSmall]: small,
        [classes.tableCellTiny]: tiny,
        [classes.tableCellSlim]: slim,
        [classes.tableCellLeftAligned]: leftAligned,
        [classes.tableCellRightAligned]: rightAligned,
      },
      className,
    );
  }

  getInnerClassName() {
    const { ellipsis, innerClassName, classes } = this.props;

    return classnames(
      {
        [classes.tableCellEllipsis]: ellipsis,
      },
      innerClassName,
    );
  }

  render() {
    const {
      children,
      slim: _slim,
      classes: _classes,
      ellipsis: _ellipsis,
      sub: _sub,
      link: _link,
      small: _small,
      innerClassName: _innerClassName,
      leftAligned: _leftAligned,
      rightAligned: _rightAligned,
      ...props
    } = this.props;

    return (
      <div {...props} className={this.getClassName()}>
        <div className={this.getInnerClassName()}>{children}</div>
      </div>
    );
  }
}

TableCell.contextTypes = {
  table: PropTypes.object.isRequired,
};

const StyledTableBody = withStyles(styles)(TableBody);
const StyledTableHead = withStyles(styles)(TableHead);
const StyledTableRow = withStyles(styles)(TableRow);
const StyledTableCell = withStyles(styles)(TableCell);

export default withStyles(styles)(Table);
export {
  StyledTableBody as TableBody,
  StyledTableHead as TableHead,
  StyledTableRow as TableRow,
  StyledTableCell as TableCell,
};
