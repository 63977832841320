import { useProspectCrmPresencesQuery } from '~/generated/graphql';

export const useProspectCrmPresence = (prospectIds: string[]) => {
  const { data: crmPresence, loading } = useProspectCrmPresencesQuery({
    variables: { prospectIds },
  });

  const isInHubSpot = (prospectId: string) => {
    return crmPresence?.prospectCrmPresences?.some(
      (crmPresence) =>
        crmPresence?.prospectId === prospectId && crmPresence?.existsInHubspot,
    );
  };

  return { crmPresence, isInHubSpot, isLoadingCrmPresence: loading };
};
