import { useQuery } from '@apollo/client/react/hooks';
import { CKButton } from 'clearkit';
import { Link } from 'react-router-dom';

import Footer from '~/components/Footer';
import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '~/components/Table';
import { timeFromNow } from '~/lib/formatters';
import paths from '~/lib/paths';

import query from './query.graphql';

export default function ConversionDebugConsole({ destination, segment }) {
  const { data, loading } = useQuery(query, {
    variables: {
      destinationId: destination.id,
    },
  });

  return loading ? (
    <div className="px-8 pt-4">Loading...</div>
  ) : data.conversionEvents.nodes.length === 0 ? (
    <p className="px-8 pt-4">There are no conversions for this audience yet.</p>
  ) : (
    <>
      <div className="px-8 pt-4">
        <h3 className="text-lg font-bold">Recent Conversion Events</h3>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Ad Impression Date/Time</TableCell>
              <TableCell>Conversion Date/Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.conversionEvents.nodes.map((event) => (
              <TableRow key={event.id}>
                <TableCell>{event.person.name}</TableCell>
                <TableCell title={event.clickId}>
                  {timeFromNow(event.pageviewAt)}
                </TableCell>
                <TableCell>{timeFromNow(event.createdAt)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <Footer
        right={
          <>
            <CKButton
              as={Link}
              to={paths.segmentDestinations(segment.type, segment.id)}
              variant="simple"
              variantColor="default"
            >
              Cancel
            </CKButton>
            <CKButton
              as="a"
              href={`/v1/conversion_events/${destination.id}/csv`}
              variant="bold"
              variantColor="yellow"
            >
              Download CSV
            </CKButton>
          </>
        }
      />
    </>
  );
}
