import { useMutation } from '@apollo/client';
import { SpinnerMonoIndicators as SpinnerMono } from '@clearkit/icons';
import {
  CKField,
  CKFieldLabel,
  CKSelectableItem,
  CKSelectNext,
  CKTextField,
} from 'clearkit';
import map from 'lodash/map';
import { ChangeEvent, FC, useEffect, useState } from 'react';

import { ConfirmDialogBase } from '~/components/ConfirmDialog';
import { CollectionConnection } from '~/generated/graphql';
import history from '~/lib/history';
import paths, { SegmentRootPathKey } from '~/lib/paths';
import createCSVSegmentQuery from '~/pages/CsvImport/createSegment.graphql';

const collectionNodesToOptions = (collections: CollectionConnection) => {
  if (!collections) return [];

  return map(map(collections.edges, 'node'), ({ id, name }) => ({
    value: id,
    label: name,
  }));
};

type CreateSegmentDialogProps = {
  csvImport: {
    id: string;
    sourceName: string;
  };
  closeDialog: () => void;
  collections: CollectionConnection;
  type: string;
};

type Validation = {
  collection?: boolean;
  segmentName?: boolean;
};

const CreateSegmentDialog: FC<CreateSegmentDialogProps> = ({
  csvImport,
  closeDialog,
  collections,
  type,
}) => {
  const [segmentName, setSegmentName] = useState<string>('');
  const [collection, setCollection] = useState<CKSelectableItem>();
  const [validations, setValidations] = useState<Validation>({});

  const clearStateAndClose = () => {
    setSegmentName('');
    setCollection(undefined);
    setValidations({});
    closeDialog();
  };

  const handleCSVSegmentCreateComplete = ({ response: { node } }: any) => {
    clearStateAndClose();
    history.push(paths.segment(type as SegmentRootPathKey, node.segmentId));
  };

  const [createCSVSegment, { loading: isCreatingSegment }] = useMutation(
    createCSVSegmentQuery,
    {
      onCompleted: handleCSVSegmentCreateComplete,
    },
  );

  useEffect(() => {
    if (csvImport) {
      setSegmentName(csvImport?.sourceName);
    }
  }, [csvImport]);

  const validate = () => ({
    collection: !collection,
    segmentName: !segmentName,
  });

  const handleCreateSegment = async () => {
    const newValidations = validate();

    if (Object.values(newValidations).some((value) => value)) {
      setValidations(newValidations);
      return;
    }

    createCSVSegment({
      variables: {
        input: {
          id: csvImport.id,
          segmentName,
          collectionId: collection?.value,
        },
      },
    });
  };

  return (
    <ConfirmDialogBase
      buttonDisabled={isCreatingSegment}
      confirmButtonVariant="blue"
      confirmText={
        isCreatingSegment ? (
          <SpinnerMono className="w-4 h-4 mx-12" />
        ) : (
          'Create audience'
        )
      }
      message={
        <>
          <CKField
            className="!block"
            label="Audience name"
            variant={validations.segmentName ? 'error' : 'default'}
          >
            <CKTextField
              className="w-full"
              isDisabled={!!isCreatingSegment}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const { segmentName: _, ...newValidations } = validations;
                setValidations(newValidations);
                setSegmentName(event.target.value);
              }}
              value={segmentName}
            />
          </CKField>
          <CKFieldLabel className="mt-6" htmlFor="" label="Collection" />
          <CKSelectNext
            isDisabled={isCreatingSegment}
            items={collectionNodesToOptions(collections)}
            onChange={(newValue) => {
              const { collection: _, ...newValidations } = validations;
              setValidations(newValidations);
              setCollection(newValue);
            }}
            placeholder="Select a collection"
            value={collection}
            variant={validations.collection ? 'error' : undefined}
            zIndex={2000}
          />
        </>
      }
      onCancel={clearStateAndClose}
      onConfirm={handleCreateSegment}
      open={!!csvImport}
      title={`Create audience from ${csvImport?.sourceName}`}
    />
  );
};

export default CreateSegmentDialog;
