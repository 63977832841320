import { CKDrawer } from 'clearkit';

import InfoTooltip from '~/components/InfoTooltip';
import TextField from '~/components/TextField';
import { destinationTypeCustomHtml } from '~/lib/destinations';
import { hasError } from '~/lib/graphql';
import { segmentTypes } from '~/lib/segments';
import TriggerType from '~/pages/destinations/components/TriggerType';

import ContentBox from '../../components/ContentBox';
import FormContentWrapper from '../../components/FormContentWrapper';
import FormHeader from '../../components/FormHeader';
import styles from './styles';

class CustomHtml extends React.Component {
  state = {
    source: null,
    triggerType: 'page',
  };

  UNSAFE_componentWillMount() {
    if (this.props.destination) {
      this.setState({
        ...this.props.destination,
        ...this.props.destination.config,
      });
    }
  }

  handleFieldChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    const { classes, errors, segment, destination, FormFooter } = this.props;

    const { source, triggerType } = this.state;

    const destinationFlowDescription = destination
      ? 'Will append your Custom HTML'
      : null;

    const triggerPerson =
      segment.type === segmentTypes.company
        ? 'someone at the company'
        : 'a person';
    const triggerTitle = `The destination is triggered every time ${triggerPerson} hits your website.`;

    return (
      <>
        <FormHeader
          destinationFlowDescription={destinationFlowDescription}
          destinationType={destinationTypeCustomHtml}
          segmentCollection={segment.collection.name}
          segmentName={segment.name}
        />

        <CKDrawer.Body>
          <FormContentWrapper>
            <h2 className={classes.subheading}>Trigger</h2>
            <span className={classes.triggerSubtext}>
              Select the event that triggers this Destination to run.{' '}
              <a
                href="https://help.clearbit.com/hc/en-us/articles/360023453694"
                rel="noopener noreferrer"
                style={{ textDecoration: 'underline' }}
                target="_blank"
              >
                Learn more
              </a>
            </span>

            <TriggerType
              errors={errors}
              handleChange={this.handleFieldChange}
              triggerType={triggerType}
              triggerTypes={[
                {
                  value: 'page',
                  label: (
                    <div className="flex items-center">
                      On&nbsp;<strong>page view</strong>{' '}
                      <InfoTooltip title={triggerTitle} />
                    </div>
                  ),
                },
              ]}
            />

            <h2 className={classes.subheading}>Configure Custom HTML</h2>

            <ContentBox>
              <TextField
                className={classes.field}
                defaultValue={source}
                error={hasError(errors, 'source')}
                fullWidth
                InputProps={{
                  classes: { input: classes.sourceInput },
                }}
                label="Custom HTML"
                multiline
                onChange={(event) => {
                  this.handleFieldChange('source', event.target.value);
                }}
                placeholder="Enter your HTML"
                rows="8"
                variant="standard"
              />
            </ContentBox>
          </FormContentWrapper>
        </CKDrawer.Body>
        <FormFooter
          destinationConfig={{
            config: {
              source: this.state.source,
            },
            triggerType: this.state.triggerType,
          }}
        />
      </>
    );
  }
}

CustomHtml.propTypes = {
  classes: PropTypes.object.isRequired,
  destination: PropTypes.object,
  errors: PropTypes.array.isRequired,
  onInputChange: PropTypes.func.isRequired,
  typename: PropTypes.string.isRequired,
};

export default withStyles(
  (theme) => ({
    ...styles(theme),
  }),
  { name: 'SegmentDestinationNewCustomHtml' },
)(CustomHtml);
