import { SearchContext } from '~/components/SearchBuilder/utils/searchUtils';
import TextField from '~/components/TextField';

const ValueText = ({ className, value, onChange }) => {
  const { disabled } = useContext(SearchContext);
  return (
    <TextField
      disabled={disabled}
      FormControlProps={{ className }}
      onChange={(e) => onChange(e.target.value)}
      placeholder="Enter a value"
      value={value}
      variant="outlined"
    />
  );
};

ValueText.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
};

export default ValueText;
