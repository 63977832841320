import { CKHTMLElement, CKHTMLElementProps } from 'clearkit';

type CountProps = CKHTMLElementProps & {
  count: number;
  label?: string;
  labelPlural?: string;
};

export const Count = ({
  as = 'span',
  count,
  label,
  labelPlural,
  ...rest
}: CountProps) => {
  const displayLabel = count === 1 ? label : labelPlural || label;

  return (
    <CKHTMLElement {...rest} as={as}>
      {count.toLocaleString('en-us')} {displayLabel}
    </CKHTMLElement>
  );
};
