const styles = () => ({
  tabsIndicator: {
    height: 3,
    background: '#3386ee',
  },

  tabRoot: {
    margin: '0 30px 0 0',
    minWidth: 0,

    '& svg': {
      display: 'inline-block',
      verticalAlign: 'unset',
      margin: '0 5px 0 0',
      position: 'relative',
      top: 1,
    },

    '& path': {
      fill: 'currentColor',
    },

    '&:focus': {
      outline: '2px solid transparent',
      'outline-offset': '2px',
    },
  },

  tabLabelContainer: {
    padding: 0,
  },
});

export default styles;
