import classnames from 'classnames';
import { CKContainerProps, CKTabs } from 'clearkit';
import React, { FC } from 'react';

import { PageLayout, PageLayoutComposition } from './PageLayout';

type PageLayoutTabsProps = Omit<CKContainerProps, 'onChange'> & {
  index?: number;
  onChange?: (index: number) => void;
};

type PageLayoutTabsComposition = PageLayoutComposition;

export const PageLayoutTabs: FC<PageLayoutTabsProps> &
  PageLayoutTabsComposition = ({
  className,
  children,
  index,
  onChange,
  ...rest
}) => (
  <div {...rest} className={classnames('w-full h-full', className)}>
    <CKTabs className="h-full" index={index} onChange={onChange}>
      <PageLayout className="h-full grid grid-rows-[auto_1fr_auto]">
        {children}
      </PageLayout>
    </CKTabs>
  </div>
);
PageLayoutTabs.Actions = PageLayout.Actions;
PageLayoutTabs.Actions.displayName = 'PageLayout.Actions';

PageLayoutTabs.Body = PageLayout.Body;
PageLayoutTabs.Body.displayName = 'PageLayout.Body';

PageLayoutTabs.Breadcrumb = PageLayout.Breadcrumb;
PageLayoutTabs.Breadcrumb.displayName = 'PageLayout.Breadcrumb';

PageLayoutTabs.Content = PageLayout.Content;
PageLayoutTabs.Content.displayName = 'PageLayout.Content';

PageLayoutTabs.Footer = PageLayout.Footer;
PageLayoutTabs.Footer.displayName = 'PageLayout.Footer';

PageLayoutTabs.Header = PageLayout.Header;
PageLayoutTabs.Header.displayName = 'PageLayout.Header';

PageLayoutTabs.HeaderWrapper = PageLayout.HeaderWrapper;
PageLayoutTabs.HeaderWrapper.displayName = 'PageLayout.HeaderWrapper';

PageLayoutTabs.Heading = PageLayout.Heading;
PageLayoutTabs.Heading.displayName = 'PageLayout.Heading';

PageLayoutTabs.SubHeading = PageLayout.SubHeading;
PageLayoutTabs.SubHeading.displayName = 'PageLayout.SubHeading';

PageLayoutTabs.Tile = PageLayout.Tile;
PageLayoutTabs.Tile.displayName = 'PageLayout.Tile';
