import { useLazyQuery, useMutation } from '@apollo/client';
import { useMachine } from '@xstate/react';
import { assign, Machine } from 'xstate';

import refreshMutation from './mutation.graphql';
import segmentQuery from './query.graphql';

const actions = {
  refetch: 'refetch',
  pollSegment: 'pollSegment',
  refreshSegment: 'refreshSegment',
  segmentPolled: 'segmentPolled',
  stopPollingSegment: 'stopPollingSegment',
  checkForNewMatches: 'checkForNewMatches',
  newMatchesFound: 'newMatchesFound',
  noNewMatchesFound: 'noNewMatchesFound',
};

const states = {
  checkingForNewMatches: 'checkingForNewMatches',
  newMatchesAvailable: 'newMatchesAvailable',
  refetchForNewMatches: 'refetchForNewMatches',
  segmentUpToDate: 'segmentUpToDate',
};

const segmentRefreshed = (context, event) =>
  context.originalSegment?.updatedAt !== event.data?.updatedAt;

const newMatchesFound = (context, event) =>
  context.originalSegment?.resultsCount !== event.data?.resultsCount;

const updateContext = assign({
  segmentRefreshed,
  newMatchesFound,
  polledSegment: (_, event) => event.data,
});

const machine = new Machine({
  id: 'refresh',
  initial: 'idle',
  states: {
    idle: {
      on: {
        checkForNewMatches: {
          target: states.checkingForNewMatches,
          actions: assign({
            originalSegment: (_, event) => event.data,
          }),
        },
      },
    },
    checkingForNewMatches: {
      entry: [actions.refreshSegment, actions.pollSegment],
      on: {
        newMatchesFound: states.newMatchesAvailable,
        noNewMatchesFound: states.segmentUpToDate,
        segmentPolled: {
          actions: [updateContext, 'compareSegments'],
        },
      },
    },
    newMatchesAvailable: {
      entry: [actions.stopPollingSegment],
      after: {
        3000: 'refetchForNewMatches',
      },
    },
    refetchForNewMatches: {
      on: {
        refetch: 'idle',
      },
    },
    segmentUpToDate: {
      entry: [actions.stopPollingSegment],
      after: {
        3000: 'idle',
      },
    },
  },
});

const useSegmentQuery = (segment) => {
  const [pollSegment, { data, stopPolling }] = useLazyQuery(segmentQuery, {
    pollInterval: 1000,
    variables: { id: segment?.id, type: segment?.type },
  });

  return {
    startPolling: pollSegment,
    stopPolling,
    data,
  };
};

const useRefreshState = ({ segment, refetch, shouldRefreshSegment }) => {
  const [refreshSegment] = useMutation(refreshMutation);
  const { startPolling, stopPolling, data } = useSegmentQuery(segment);
  const [current, send] = useMachine(machine, {
    actions: {
      refreshSegment: () =>
        refreshSegment({
          variables: {
            input: {
              id: segment.id,
              type: segment.type,
            },
          },
        }),
      pollSegment: startPolling,
      stopPollingSegment: stopPolling,
      compareSegments: ({ segmentRefreshed, newMatchesFound }) => {
        if (segmentRefreshed) {
          if (newMatchesFound) {
            send(actions.newMatchesFound);
          } else {
            send(actions.noNewMatchesFound);
          }
        }
      },
    },
  });

  useEffect(() => {
    if (data) {
      send({ type: actions.segmentPolled, data: data.segment });
    }
  }, [data]);

  useEffect(() => {
    if (shouldRefreshSegment) {
      send({ type: actions.checkForNewMatches, data: segment });
    }
  }, [shouldRefreshSegment]);

  const onRefetchForNewMatches = () => {
    refetch();
    send(actions.refetch);
  };

  const triggerRefresh = () => {
    send({ type: actions.checkForNewMatches, data: segment });
  };

  const { originalSegment } = current.context;

  return {
    triggerRefresh,
    refreshState: current.value,
    originalResultsCount: originalSegment?.resultsCount,
    onRefetchForNewMatches,
  };
};

export { states };
export default useRefreshState;
