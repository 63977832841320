import Select, { createFilter } from 'react-select';
import AsyncSelect from 'react-select/async';

import DropdownIndicator from './DropdownIndicator';
import selectStyles, { errorStyles, selectTheme } from './styles';

export default class ClearbitSelect extends React.Component {
  render() {
    const { styles, components, async, error, ...props } = this.props;

    const selectProps = {
      filterOption: createFilter({ ignoreAccents: false }),
      theme: selectTheme,
      styles: { ...selectStyles, ...styles, ...(error && errorStyles) },
      components: { DropdownIndicator, ...components },
      ...props,
    };

    if (async) {
      if (!selectProps.loadOptions) {
        /* eslint-disable-next-line no-console */
        console.warn(
          "Select requires the 'loadOptions' prop to work asynchronously",
        );
      }
      return <AsyncSelect {...selectProps} />;
    }

    return <Select {...selectProps} />;
  }
}

ClearbitSelect.propTypes = {
  styles: PropTypes.object,
  components: PropTypes.object,
  async: PropTypes.bool,
  loadOptions: PropTypes.func,
  error: PropTypes.bool,
};
