const styles = (theme) => ({
  root: {
    transition: 'ease width 0.4s',
    width: 390,
    [theme.breakpoints.down('lg')]: {
      width: 290,
    },
    height: '100vh',
    minWidth: 290,
    padding: '30px 24px 0 24px',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    overflow: 'auto',
    flexShrink: 0,

    // This can be removed with https://github.com/clearbit/clearkit-one/pull/60
    '& *:focus': {
      outline: 'none',
    },
  },

  legacyFont: {
    color: 'white',
  },

  legacyBackground: {
    background: '#4A5259',
  },

  segmentLoadingArea: {
    flexGrow: 1,
  },

  segments: {
    overflowY: 'auto',
    overflowX: 'hidden',
    flexGrow: 0,
    width: 'calc(100% + 24px)',

    marginLeft: -12,
    padding: '8px 12px',
  },

  mainTitle: {
    margin: '0 10px 18px',
    fontSize: 26,
    fontWeight: '600',
    letterSpacing: 0,
    lineHeight: '30px',
  },

  segmentLink: {},

  selectedSegment: {
    '&:focus': {
      boxShadow: 'inset 0 0 0 1px rgba(255, 255, 255, .1)',
    },
    '& $homeIcon': {
      opacity: 1,
    },
  },

  homeLink: {
    display: 'flex',
    flex: '0 0 auto',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '29px',
    paddingLeft: 12,
    paddingBottom: 1,
    width: '100%',
    borderRadius: 6,
    transition: 'background-color ease .2s',
  },

  legacyHomeLink: {
    color: 'white',
    '&:focus, &:hover': {
      backgroundColor: 'rgba(255, 255, 255, .1)',

      '& $homeIcon': {
        opacity: 1,
      },
    },
  },

  legacyActionMenuItem: {
    color: 'white',

    '&:hover, &:focus': {
      background: 'rgba(255, 255, 255, .1)',
    },

    '&.selected': {
      background: 'linear-gradient(to left, #637EFF 0%, #C363E5 100%)',
    },
  },

  homeIcon: {
    opacity: 0.5,
    marginLeft: -4,
    marginRight: 8,
    transition: 'opacity ease .2s',
  },

  // Footer

  newButtonWrapper: {
    position: 'sticky',
    marginTop: 'auto',
    paddingTop: 12,
    bottom: 0,
  },

  newButton: {
    width: '100%',
  },
});

export default styles;
