import { Email } from '@clearkit/icons';
import { CKContainerProps } from 'clearkit';

import { UpgradeAccountFormButton } from '~/components/UpgradeAccountFormButton';

import { EmptyState } from '../ProspectList/EmptyState';

export function NoAccess(props: CKContainerProps) {
  return (
    <div className="w-full h-full bg-gray-0">
      <EmptyState {...props}>
        <EmptyState.Icon>
          <Email />
        </EmptyState.Icon>
        <EmptyState.Title>You don't have access to Prospector</EmptyState.Title>
        <EmptyState.Description className="space-y-6">
          <div>To get access to Prospector, contact us.</div>
          <UpgradeAccountFormButton variant="bold" variantColor="blue">
            Talk to us
          </UpgradeAccountFormButton>
        </EmptyState.Description>
      </EmptyState>
    </div>
  );
}
