import { Edit, Ellipsis, Trash } from '@clearkit/icons';
import { CKActionMenu, CKButton, CKPopover } from 'clearkit';

import { useProspector } from '../useProspector';

export const ListManagementMenu = () => {
  const [_state, send] = useProspector();

  const handleRename = () => {
    send('RENAME');
  };

  const handleDelete = () => {
    send('DELETE');
  };

  return (
    <CKPopover
      popover={
        <CKActionMenu>
          <CKActionMenu.Item icon={<Edit />} onClick={handleRename}>
            Rename list
          </CKActionMenu.Item>

          <CKActionMenu.Item
            icon={<Trash />}
            isDestructive
            onClick={handleDelete}
          >
            Delete list
          </CKActionMenu.Item>
        </CKActionMenu>
      }
      popoverPlacement="top-end"
    >
      <CKButton>
        <Ellipsis />
      </CKButton>
    </CKPopover>
  );
};
