import styles from './styles.style';

function SegmentsMenuItem(props) {
  const {
    className,
    classes,
    children,
    selected,
    focused,
    as: Component = 'div',
    ...rest
  } = props;

  return (
    <Component
      className={classnames(
        classes.root,
        'flex items-center py-1 text-sm leading-5 border-box border rounded-md px-4 relative',
        className,
        {
          focused,
          selected,
        },
      )}
      {...rest}
    >
      {children}
    </Component>
  );
}

export default withStyles(styles)(SegmentsMenuItem);
