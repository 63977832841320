import classnames from 'classnames';
import {
  CKContainerProps,
  excludeChildrenByDisplayName,
  includeChildrenByDisplayName,
} from 'clearkit';
import { createContext, FC, useContext } from 'react';

type BillingSectionProps = CKContainerProps & {
  isActive?: boolean;
  isDisabled?: boolean;
  isOpen?: boolean;
};

type BillingSectionHeadingProps = CKContainerProps<HTMLHeadingElement>;

type BillingSectionComposition = {
  Heading: FC<BillingSectionHeadingProps>;
};

const BillingSectionContext = createContext<BillingSectionProps>({
  isActive: true,
});

export const BillingSection: FC<BillingSectionProps> &
  BillingSectionComposition = ({
  className,
  isOpen = true,
  isActive = true,
  isDisabled = false,
  children,
  ...rest
}) => {
  return (
    <BillingSectionContext.Provider value={{ isActive, isDisabled, isOpen }}>
      <div {...rest} className={classnames(className, 'space-y-6 pt-6')}>
        {includeChildrenByDisplayName({
          children,
          componentDisplayName: 'BillingSection.Heading',
        })}

        {isOpen
          ? excludeChildrenByDisplayName({
              children,
              componentDisplayName: 'BillingSection.Heading',
            })
          : null}
      </div>
    </BillingSectionContext.Provider>
  );
};

const Heading = ({
  className,
  children,
  ...rest
}: BillingSectionHeadingProps) => {
  const { isActive, isDisabled, isOpen } = useContext(BillingSectionContext);

  return (
    <h4
      {...rest}
      className={classnames(className, 'font-medium text-base', {
        'text-gray-400': !isOpen || !isActive || isDisabled,
        'line-through': !isActive && !isDisabled,
      })}
    >
      {children}
    </h4>
  );
};
BillingSection.Heading = Heading;
BillingSection.Heading.displayName = 'BillingSection.Heading';
