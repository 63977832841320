import { Location, Money, Tags, Team } from '@clearkit/icons';
import { uniq } from 'lodash';

import { FilterMultiPicker } from '~/components/Filters/FilterMultiPicker';
import { useProspectFiltersQuery } from '~/generated/graphql';

import {
  BUSINESS_MODELS,
  COMPANY_TYPES,
  EMPLOYEE_RANGES,
  RAISED_RANGES,
} from '../constants';
import { Search } from '../hooks/useTargetMarket';

type TargetMarketFormProps = {
  value: Search;
  onChange: (value: Search) => void;
};

export const TargetMarketForm = ({
  value,
  onChange,
}: TargetMarketFormProps) => {
  const { data: filterData } = useProspectFiltersQuery();

  const tags = value?.tag ?? [];
  const businessModelValues = BUSINESS_MODELS.map((tag) => tag.value);
  const businessModelTags = tags.filter((tag) =>
    businessModelValues.includes(tag),
  );
  const companyTags = tags.filter((tag) => !businessModelValues.includes(tag));

  const updateSearch = (fieldName: keyof Search) => (newValue: string[]) => {
    const newSearch = {
      ...value,
      [fieldName]: newValue,
    };

    onChange(newSearch);
  };

  const updateBusinessModel = (newValue: string[]) => {
    const allTags = [...companyTags, ...newValue];

    onChange({
      ...value,
      tag: uniq(allTags),
    });
  };

  const updateTags = (newValue: string[]) => {
    const allTags = [...businessModelTags, ...newValue];

    onChange({
      ...value,
      tag: uniq(allTags),
    });
  };

  const filters = filterData?.prospectFilters;

  return (
    <div className="p-6 pt-1 divide-y space-y-4 divide-gray-200">
      <FilterMultiPicker
        items={EMPLOYEE_RANGES}
        onChange={updateSearch('employees')}
        value={value?.employees ?? []}
      >
        <FilterMultiPicker.Icon>
          <Team />
        </FilterMultiPicker.Icon>
        <FilterMultiPicker.Title>Employee Ranges</FilterMultiPicker.Title>
      </FilterMultiPicker>

      <FilterMultiPicker
        className="pt-4"
        items={filters?.industries}
        onChange={updateSearch('industry')}
        value={value?.industry ?? []}
      >
        <FilterMultiPicker.Icon>
          <Tags />
        </FilterMultiPicker.Icon>
        <FilterMultiPicker.Title>Industries</FilterMultiPicker.Title>
      </FilterMultiPicker>

      <FilterMultiPicker
        className="pt-4"
        items={filters?.countries}
        onChange={updateSearch('country')}
        value={value?.country ?? []}
      >
        <FilterMultiPicker.Icon>
          <Location />
        </FilterMultiPicker.Icon>
        <FilterMultiPicker.Title>Countries</FilterMultiPicker.Title>
      </FilterMultiPicker>

      <FilterMultiPicker
        className="pt-4"
        items={BUSINESS_MODELS}
        onChange={updateBusinessModel}
        value={businessModelTags}
      >
        <FilterMultiPicker.Icon>
          <Tags />
        </FilterMultiPicker.Icon>
        <FilterMultiPicker.Title>Business Models</FilterMultiPicker.Title>
      </FilterMultiPicker>

      <FilterMultiPicker
        className="pt-4"
        items={COMPANY_TYPES}
        onChange={updateSearch('type')}
        value={value.type ?? []}
      >
        <FilterMultiPicker.Icon>
          <Tags />
        </FilterMultiPicker.Icon>
        <FilterMultiPicker.Title>Company Types</FilterMultiPicker.Title>
      </FilterMultiPicker>

      <FilterMultiPicker
        className="pt-4"
        items={filters?.companyTags ?? []}
        onChange={updateTags}
        value={companyTags}
      >
        <FilterMultiPicker.Icon>
          <Tags />
        </FilterMultiPicker.Icon>
        <FilterMultiPicker.Title>Tags</FilterMultiPicker.Title>
      </FilterMultiPicker>

      <FilterMultiPicker
        className="pt-4"
        items={filters?.companyTech}
        onChange={updateSearch('tech')}
        value={value?.tech ?? []}
      >
        <FilterMultiPicker.Icon>
          <Tags />
        </FilterMultiPicker.Icon>
        <FilterMultiPicker.Title>Technologies</FilterMultiPicker.Title>
      </FilterMultiPicker>

      <FilterMultiPicker
        className="pt-4"
        items={RAISED_RANGES}
        onChange={updateSearch('raised')}
        value={value?.raised ?? []}
      >
        <FilterMultiPicker.Icon>
          <Money />
        </FilterMultiPicker.Icon>
        <FilterMultiPicker.Title>Funding Raised</FilterMultiPicker.Title>
      </FilterMultiPicker>
    </div>
  );
};
