import { CKDrawer } from 'clearkit';

const FormContentWrapper = ({ children }) => {
  return (
    <CKDrawer.Body className="flex-1 px-10 py-6 bg-gray-0">
      {children}
    </CKDrawer.Body>
  );
};

FormContentWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormContentWrapper;
