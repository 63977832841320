import { CKContainerProps } from 'clearkit';
import React from 'react';

type EntitlementWrapperProps = CKContainerProps & {
  hasAccess: boolean;
};

export const EntitlementWrapper = ({
  children,
  hasAccess,
}: EntitlementWrapperProps) => (hasAccess ? <>{children}</> : null);
