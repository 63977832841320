import { CKButton } from 'clearkit';

export type ConnectionInterstitialContentProps = {
  text: string;
  helpLink: string;
  onButtonClick?: () => void;
  buttonText?: string;
  button?: React.ReactNode;
};

export default function ConnectionInterstitialContent({
  text,
  helpLink,
  onButtonClick,
  buttonText,
  button,
}: ConnectionInterstitialContentProps) {
  if (!onButtonClick && !button) {
    throw new Error(
      'ConnectionInterstitialContent must have either onButtonClick or button',
    );
  }

  return (
    <div className="flex flex-col items-center">
      <div className="max-w-sm text-base text-center text-gray-600 border-0 border-b border-gray-200">
        <div>{text} </div>

        <div className="my-8">
          Explore our{' '}
          <a
            className="text-underlined"
            href={helpLink}
            rel="noopener noreferrer"
            target="_blank"
          >
            {' '}
            documentation{' '}
          </a>{' '}
          to learn more.
        </div>
      </div>

      <div className="mt-8">
        {onButtonClick ? (
          <CKButton onClick={onButtonClick} variant="bold" variantColor="blue">
            {buttonText || 'Connect'}
          </CKButton>
        ) : (
          button
        )}
      </div>
    </div>
  );
}
