import {
  Destinations as DestinationsIcon,
  Edit as EditIcon,
  Segment as SegmentIcon,
} from '@clearkit/icons';
import isEmpty from 'lodash/isEmpty';

import Tabs from '~/components/RedesignedTabs';
import paths from '~/lib/paths';

import styles from './styles';

function SegmentTabs({
  segment,
  segment: { audiences, enabledDestinationsCount } = {},
  value,
  className,
  classes,
}) {
  const destinationsCount = enabledDestinationsCount + audiences?.totalCount;

  if (value === 'new') {
    return (
      <Tabs className={className} value={value}>
        <Tabs.Tab label="Create audience" value="new" />
      </Tabs>
    );
  }

  return (
    <Tabs value={value}>
      <Tabs.Tab
        disabled={isEmpty(segment)}
        label={
          <span>
            <SegmentIcon
              className={classnames(classes.segmentTabIcon, {
                [classes.selectedIcon]: value === 'show',
              })}
            />
            Audience matches
          </span>
        }
        to={segment ? paths.segment(segment.type, segment.id) : ''}
        value="show"
      />

      <Tabs.Tab
        disabled={isEmpty(segment)}
        label={
          <span>
            <EditIcon
              className={classnames(classes.segmentTabIcon, {
                [classes.selectedIcon]: value === 'edit',
              })}
            />
            Edit audience
          </span>
        }
        to={segment ? paths.segmentEdit(segment.type, segment.id) : ''}
        value="edit"
      />

      <Tabs.Tab
        disabled={isEmpty(segment)}
        label={
          <span>
            <DestinationsIcon
              className={classnames(classes.segmentTabIcon, {
                [classes.selectedIcon]: value === 'destinations',
              })}
            />
            Destinations
            {destinationsCount > 0 && (
              <span
                className={classnames(classes.destinationsCount, {
                  [classes.selectedDestinationsCount]: value === 'destinations',
                })}
              >
                {destinationsCount}
              </span>
            )}
          </span>
        }
        to={segment ? paths.segmentDestinations(segment.type, segment.id) : ''}
        value="destinations"
      />
    </Tabs>
  );
}

SegmentTabs.defaultProps = {
  value: 'show',
};

SegmentTabs.propTypes = {
  value: PropTypes.oneOf(['show', 'edit', 'destinations', 'new']),
  classes: PropTypes.object,
  segment: PropTypes.object,
  className: PropTypes.string,
};

export const StyledTabs = withStyles(styles)(SegmentTabs);

export default withStyles(styles)(SegmentTabs);
