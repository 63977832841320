function Warning({ fill, height, width }) {
  return (
    <svg height={height} viewBox="0 0 14 12" width={width}>
      <path
        d="M9.27748748,73.0759171 L14.5931408,81.7138539 C15.0273174,82.4193907 14.8073364,83.343311 14.1017995,83.7774875 C13.865388,83.9229715 13.593243,84 13.3156534,84 L2.68434664,84 C1.85591951,84 1.18434664,83.3284271 1.18434664,82.5 C1.18434664,82.2224103 1.26137513,81.9502654 1.40685916,81.7138539 L6.72251252,73.0759171 C7.15668905,72.3703803 8.08060929,72.1503993 8.78614614,72.5845758 C8.9861268,72.7076408 9.15442245,72.8759365 9.27748748,73.0759171 Z M9,82 C9.00000002,81.4477167 8.55228333,81 8,81 C7.44771667,81 7,81.4477167 7,82 C6.99999992,82.5522832 7.44771667,82.9999999 8,83 C8.55228333,83 9,82.5522833 9,82 Z M7.33932065,75.0000185 C7.15409548,74.9983033 7.00218807,75.115902 7.0000229,75.2626823 C6.99995242,75.2674615 7.00004464,75.2722414 7.00029944,75.2770166 L7.30405448,79.7506716 C7.31556479,79.8919506 7.46446516,80.0014401 7.64310979,79.9999857 L8.35685278,79.9999857 C8.53549076,80.0014143 8.68438154,79.8919427 8.6959413,79.7506715 L8.99969634,75.2770165 C9.00750384,75.1303564 8.86380158,75.0064499 8.67872885,75.0002631 C8.67270322,75.0000616 8.66667183,74.999989 8.66064126,75.0000453 L7.33932065,75.0000185 Z"
        fill={fill}
        fillRule="evenodd"
        transform="translate(-1 -72)"
      />
    </svg>
  );
}

Warning.defaultProps = {
  width: 14,
  height: 12,
  fill: '#9BADBC',
};

Warning.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default Warning;
