const styles = () => ({
  header: {
    padding: '27px 40px',
  },

  headerBorder: {
    borderBottom: '1px solid rgba(155, 173, 188, 0.20)',
  },

  heading: {
    color: '#27282a',
    fontSize: 24,
    fontWeight: 600,
    margin: '0 0 5px',
    flex: '0 0 auto',
  },

  subheading: {
    color: '#596168',
    flex: '0 0 auto',
    fontSize: 15,
    fontWeight: 500,
    margin: '0 0 7px',
  },

  subtitle: {
    color: '#27282a',
    fontSize: 14,
    fontWeight: 500,
  },

  flow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 18,
  },

  flowBox: {
    color: '#27282a',
    display: 'flex',
    fontSize: 16,
    fontWeight: 500,
    boxSize: 'border-box',
    alignItems: 'center',
  },

  flowArrow: {
    margin: '0 24px',
  },

  flowDetail: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontSize: 15,
    '& svg': {
      marginRight: 6,
      marginTop: -2,
    },
  },

  detailSub: {
    fontSize: 13,
    color: '#596168',
    display: 'flex',
    alignItems: 'center',
  },

  tile: {
    height: 40,
    margin: '0 12px 0 0',
    width: 40,
  },

  check: {
    marginRight: 6,
  },

  logo: {
    display: 'flex',
    margin: '0 15px 0 0',
  },
});

export default styles;
