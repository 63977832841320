import { difference, flatten, map } from 'lodash';
import { Component, Fragment } from 'react';

import history from '~/lib/history';

import Collection from '../Collection';
import segmentsForCollection from './segmentsForCollection';
import styles from './styles.style';

class CollectionsList extends Component {
  componentDidUpdate(oldProps) {
    const currentCollections = this.props.collections;
    const previousCollections = oldProps.collections;

    if (!currentCollections || !previousCollections) return;

    if (currentCollections.length - previousCollections.length === 1) {
      const newCollection = difference(
        currentCollections,
        previousCollections,
      )[0];
      const newCollectionDiv = this.collectionRefs[
        `collection-${newCollection.id}`
      ];
      if (!newCollectionDiv) return;
      newCollectionDiv.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  }

  collectionRefs = {};

  render() {
    const { props } = this;
    const {
      loading,
      classes,
      collections,
      search,
      highlightedIndex,
      segments,
      error,
    } = props;

    const { collection_id } = history.location.state || {};

    let index = 0;

    const visibleSegments = flatten(
      map(collections, (collection) =>
        segmentsForCollection(segments, collection, search),
      ),
    );

    if (visibleSegments.length === 0 && search) {
      return (
        <span className={classes.noMatches}>
          Couldn't find any audiences <br /> matching{' '}
          <strong className={classes.searchTerm}>'{search}'</strong>
        </span>
      );
    }

    if (error) {
      return <span className={classes.noMatches}>Error loading audiences</span>;
    }

    if (!collections) {
      return <span className={classes.noMatches}>No collections found</span>;
    }

    if (collections.length === 0 && !loading) {
      return <span className={classes.noMatches}>No collections found</span>;
    }

    return collections.map((collection) => {
      const collectionSegments = segmentsForCollection(
        segments,
        collection,
        search,
      );
      if (collectionSegments.length == 0 && search)
        return <Fragment key={collection.id} />;

      let segmentHighlightedIndex = -1;
      if (
        index <= highlightedIndex &&
        index + collectionSegments.length >= highlightedIndex
      ) {
        segmentHighlightedIndex = highlightedIndex - index;
      }
      index += collectionSegments.length;
      return (
        <div
          key={collection.id}
          ref={(ref) =>
            (this.collectionRefs[`collection-${collection.id}`] = ref)
          }
        >
          <Collection
            addingSegment={collection.id === collection_id}
            canDelete={collections.length > 1}
            collection={collection}
            highlightedIndex={segmentHighlightedIndex}
            search={search}
            segmentId={props.segmentId}
            segments={collectionSegments}
            type={props.type}
          />
        </div>
      );
    });
  }
}

CollectionsList.propTypes = {
  segmentId: PropTypes.string,
  collections: PropTypes.array,
  segments: PropTypes.array,
  loading: PropTypes.bool,
  classes: PropTypes.object,
  search: PropTypes.string,
  highlightedIndex: PropTypes.number,
  error: PropTypes.bool,
  type: PropTypes.oneOf(['Company', 'Person']),
};

export default withStyles(styles)(CollectionsList);
