import { useQuery } from '@apollo/client';

import InfiniteLoader from '~/components/GraphInfiniteLoader';
import GraphqlError from '~/components/GraphqlError';
import PeopleCompaniesSearchField from '~/components/PeopleCompaniesSearchField/PeopleCompaniesSearchField';
import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '~/components/Table';
import { paginateData } from '~/lib/graphql/pagination';
import history from '~/lib/history';
import paths from '~/lib/paths';

import ListPeopleRow from './ListRow';
import query from './query.graphql';

const ROW_HEIGHT = 58;
const initialPageSize = 10;
const perPage = 20;
const networkIsFetchingMore = 3;

const ListPeople = ({ companyId, className }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const variables = {
    first: initialPageSize,
    ...(searchQuery && { searchQuery }),
    id: companyId,
  };

  const {
    data: { company = {} } = {},
    error,
    fetchMore,
    loading,
    networkStatus,
    refetch,
  } = useQuery(query, { variables, notifyOnNetworkStatusChange: true });

  return (
    <div className={className}>
      <div className="px-8 py-6 border-b border-gray-200 bg-gray-0">
        <PeopleCompaniesSearchField
          fullWidth
          onChange={setSearchQuery}
          placeholder="Filter by name or email"
          value={searchQuery}
        />
      </div>

      <div className="flex flex-grow px-8">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Title</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(() => {
              if (error) {
                return (
                  <GraphqlError
                    error={error}
                    loading={loading}
                    refetch={refetch}
                  />
                );
              }

              const {
                people = [],
                peopleHasNextPage,
                peopleEndCursor,
              } = paginateData(company, {
                fields: ['people'],
              });

              const firstPageLoading =
                loading && networkStatus !== networkIsFetchingMore;

              return (
                <InfiniteLoader
                  hasNextPage={peopleHasNextPage}
                  loading={firstPageLoading}
                  loadingMore={loading}
                  nodes={people}
                  onLoadMore={() => {
                    if (loading) {
                      return;
                    }

                    fetchMore({
                      variables: {
                        ...variables,
                        after: peopleEndCursor,
                        first: perPage,
                      },

                      updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) {
                          return prev;
                        }

                        fetchMoreResult.company.people.edges = [
                          ...prev.company.people.edges,
                          ...fetchMoreResult.company.people.edges,
                        ];

                        return fetchMoreResult;
                      },
                    });
                  }}
                  rowHeight={ROW_HEIGHT}
                  rowRenderer={(person, index, style) => (
                    <div key={person.id} role="listitem" style={style}>
                      <ListPeopleRow
                        company={company}
                        onClick={() => {
                          history.push(paths.person(person.id));
                        }}
                        person={person}
                      />
                    </div>
                  )}
                />
              );
            })()}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

ListPeople.propTypes = {
  companyId: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ListPeople;
