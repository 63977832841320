import { useUserUpdatePasswordMutation } from '~/generated/graphql';

export function useUpdatePassword() {
  const [updatePassword, { loading }] = useUserUpdatePasswordMutation();

  return {
    updatePassword,
    loading,
  };
}
