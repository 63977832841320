import castArray from 'lodash/castArray';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

import { DATE_COMPARATORS } from './comparators';
import {
  getComparator,
  hasDependentConditions,
  hasPrimaryConditions,
} from './nodeUtils';
import { mapSearch } from './searchUtils';
import { isRollupNode } from './searchUtilsRollup';

const validateSearch = (search) => {
  const invalidNodes = [];
  mapSearch(search, (node) => {
    const comparator = getComparator(node);
    const query = node[comparator];

    // Event
    if (typeof query === 'string') return node;
    if (node.event) {
      if (node.event.match !== 'all' && !node.event.path) {
        invalidNodes.push(node);
      }
      return node;
    }

    // Rollup nodes need to have a value
    if (isRollupNode(node) && !['not_exists', 'exists'].includes(comparator)) {
      ['and', 'or'].forEach((key) => {
        if (node[key] && node[key].length === 0) {
          invalidNodes.push(node);
        }
      });

      if (!node['and'] && !node['or']) {
        invalidNodes.push(node);
      }
      return node;
    }

    if (!comparator || !query) return node;

    let { value } = query;

    if (Object.keys(DATE_COMPARATORS).includes(comparator)) {
      if (['date_between', 'date_not_between'].includes(comparator)) {
        value = castArray(value);
        const startDate = value[0];
        const endDate = value[1];
        if (new Date(startDate) >= new Date(endDate)) {
          invalidNodes.push(node);
        }
      }

      if (['date_in_last', 'date_in_next'].includes(comparator)) {
        const { interval, amount } = value || {};
        if (!amount || !interval || amount < 1) {
          invalidNodes.push(node);
        }
      }
    }

    // Salesforce nodes
    if (hasPrimaryConditions(node) || hasDependentConditions(node)) {
      return node;
    }

    if (!query['attribute']) {
      invalidNodes.push(node);
    }

    // Normal nodes
    if (
      isEmpty(query['value']) &&
      comparator !== 'not_exists' &&
      comparator !== 'exists'
    ) {
      invalidNodes.push(node);
    }
    return node;
  });
  return uniq(invalidNodes);
};

export function isValidSearch(search) {
  if (!search) return false;
  return validateSearch(search).length === 0;
}

export default validateSearch;
