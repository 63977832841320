import { Clock } from '@clearkit/icons';
import {
  CKBox,
  CKLayoutHorizontal,
  CKProgressBar,
  CKProgressBarVariant,
} from 'clearkit';
import Maybe from 'graphql/tsutils/Maybe';
import { capitalize } from 'lodash';
import moment from 'moment';

type UsageDetailCardProps = {
  currentPeriodTotalUsage: Record<string, Record<string, number>>;
  currentPeriodEndDate: string;
  usageLimit?: Maybe<number>;
  meteredUnit: string;
};

const UsageDetailCard = ({
  usageLimit,
  currentPeriodTotalUsage,
  currentPeriodEndDate,
  meteredUnit,
}: UsageDetailCardProps) => {
  const parsedEndDate = moment(currentPeriodEndDate, 'YYYY-MM-DD').format(
    'MMM D, YYYY',
  );
  const totalUsage = currentPeriodTotalUsage?.total?.uniques || 0;

  const creditsRemaining = () => {
    if (usageLimit) {
      const currentCredits = usageLimit - totalUsage;
      return currentCredits > 0 ? currentCredits.toLocaleString() : 0;
    } else {
      return 'Unlimited';
    }
  };

  const variant = (
    used: number,
    total: Maybe<number>,
  ): CKProgressBarVariant => {
    if (!total || used < total) return 'info';
    else if (used >= total) return 'warning';

    return 'info';
  };

  return (
    <CKBox className="flex flex-col justify-between">
      <div className="p-6 text-sm space-y-2">
        <h3 className="text-base font-medium">Usage Detail</h3>
        <CKProgressBar
          className="!mb-6"
          max={usageLimit || Infinity}
          value={totalUsage}
          variant={variant(totalUsage, usageLimit)}
        />
        <CKLayoutHorizontal justifyContent="start">
          {capitalize(meteredUnit)}
          <strong>{totalUsage.toLocaleString()}</strong>
        </CKLayoutHorizontal>
        <CKLayoutHorizontal justifyContent="start">
          Limit
          <strong>
            {usageLimit ? usageLimit.toLocaleString() : 'Unlimited'}
          </strong>
        </CKLayoutHorizontal>
        <CKLayoutHorizontal justifyContent="start">
          Credits remaining <strong>{creditsRemaining()}</strong>
        </CKLayoutHorizontal>
      </div>
      <CKBox.Footer className="px-6 py-3 text-sm text-gray-600">
        <CKLayoutHorizontal gap=".5rem" justifyContent="start">
          <Clock /> Limit refreshes {parsedEndDate}
        </CKLayoutHorizontal>
      </CKBox.Footer>
    </CKBox>
  );
};

export default UsageDetailCard;
