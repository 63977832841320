import { AddBlock } from '@clearkit/icons';
import { CKButton, CKTooltip } from 'clearkit';
import isEqual from 'lodash/isEqual';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

const FieldMappingFooter = ({
  addMapping,
  areFieldMappingsInvalid,
  className,
  isSaving,
  onSave,
  setShowInvalidFields,
  fieldMappings,
  setFieldMappings,
  savedFieldMappings,
}) => {
  const areButtonsDisabled = useMemo(() => {
    if (!fieldMappings?.length > 0) {
      return true;
    } else if (savedFieldMappings?.length > 0) {
      return isEqual(fieldMappings, savedFieldMappings);
    }

    return isEqual(fieldMappings, []);
  }, [fieldMappings, savedFieldMappings]);

  const SaveButton = () => {
    return (
      <CKButton
        data-testid="FieldMappingFooter__submit"
        isDisabled={areButtonsDisabled || areFieldMappingsInvalid}
        isLoading={isSaving}
        onClick={onSave}
        variant="bold"
        variantColor="green"
      >
        Save
      </CKButton>
    );
  };

  const AddButtons = () => {
    return (
      <div>
        <CKButton
          className="mr-4"
          leftIcon={<AddBlock />}
          onClick={() => addMapping()}
          variant="bold"
          variantColor="blue"
        >
          Add field mapping
        </CKButton>
        <CKButton
          leftIcon={<AddBlock />}
          onClick={() => addMapping({ groupId: uuidv4() })}
          variantColor="blue"
        >
          Add address group
        </CKButton>
      </div>
    );
  };

  return (
    <div className={classnames(className, 'grid grid-cols-2')}>
      <AddButtons />
      <div className="flex justify-end gap-3">
        <CKButton
          isDisabled={areButtonsDisabled}
          onClick={() => setFieldMappings(savedFieldMappings || [])}
          variant="simple"
          variantColor="default"
        >
          Cancel
        </CKButton>

        {areFieldMappingsInvalid ? (
          <CKTooltip
            placement="top-end"
            tooltip="Some field mappings are invalid"
          >
            <div
              onMouseEnter={() => setShowInvalidFields(true)}
              onMouseLeave={() => setShowInvalidFields(false)}
            >
              <SaveButton />
            </div>
          </CKTooltip>
        ) : (
          <SaveButton />
        )}
      </div>
    </div>
  );
};

export default FieldMappingFooter;
