import capitalize from 'lodash/capitalize';

import {
  integrationTabs as objectTypes,
  readableType,
} from '~/lib/dataEnrichment';

import Preview from './Preview.svg';

function objectTypesClause(type) {
  function objectName(object) {
    return capitalize(object.name);
  }

  var objectNames = objectTypes[type].map(objectName);

  const leadingSpace = objectNames.length > 0 ? ' ' : null;

  switch (objectNames.length) {
    case 0:
      return null;
    case 1:
      // Contacts
      return `${leadingSpace}${objectNames.slice(-1)}`;
    default:
      // Contact or Company
      // Account, Contact, or Lead
      var objectsString = `${objectNames
        .slice(0, -1)
        .join(', ')} or ${objectNames.slice(-1)}`;

      return `${leadingSpace}${objectsString}`;
  }
}

export default function EnrichmentLogsPlaceholder({
  className,
  enrichmentType,
}) {
  return (
    <div className={classnames('flex flex-col items-center', className)}>
      <Preview className="mb-6" />

      <h1 className="mb-2 font-semibold text-gray-700 text-md">
        There are no enrichments, yet.
      </h1>

      <p className="text-gray-600">
        Once {readableType(enrichmentType)}
        {objectTypesClause(enrichmentType)} data is enriched, details will
        appear here.
      </p>
    </div>
  );
}
