import { CKRadioBox, CKRadioBoxGroup } from 'clearkit';

type ContactLeadsToggleProps = {
  value: string;
  onChange: (value: string) => void;
};

export const SalesforceContactLeadsToggle = ({
  value,
  onChange,
}: ContactLeadsToggleProps) => {
  return (
    <section>
      <h2 className="mb-1 font-medium">Contacts or leads</h2>
      <p className="mb-4 text-sm text-gray-600">
        Choose whether to create Salesforce contacts or leads from the list of
        prospected people. If a record already exists in Salesforce, we will
        automatically prevent a duplicate from being created.
      </p>
      <CKRadioBoxGroup
        gap="1.5rem"
        gridTemplateColumns="repeat(auto-fit, minmax(10rem,1fr))"
        name="salesforce-type"
        onChange={onChange}
        value={value}
      >
        <CKRadioBox value="contact">
          Capture prospects as Salesforce contacts
        </CKRadioBox>
        <CKRadioBox value="lead">
          Capture prospects as Salesforce leads
        </CKRadioBox>
      </CKRadioBoxGroup>
    </section>
  );
};
