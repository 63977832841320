import { useQuery } from '@apollo/client';

import prospectListExportQuery from '../useProspector/queries/prospectListExportQuery.graphql';
/**
 * This function is intended to be fired _after_ a prospect list
 * export is downloaded. In cases where the export has already been
 * purchased we won't poll and just return the data as CSV. If the
 * export has not been purchased we will poll until the export transitions
 * to purchased and then return the data as CSV.
 *
 * We do the polling because this gets fired off when the user first
 * downloads the export as well, so it has to go from "completed" to
 * "purchased". We do this inline so we can use the same user interaction
 * to trigger the download.
 *
 * @example
 * const pollAndExport = usePollAndExport(id);
 * const data = await pollAndExport();
 * downloadCSV(data);
 *
 * @param id the prospect list export id
 * @returns a function that will poll and return the data as CSV
 */
export function usePollAndExport(id: string) {
  const { refetch } = useQuery(prospectListExportQuery, {
    variables: { id },
  });

  function pollAndExport() {
    return new Promise((resolve, reject) => {
      async function fetchDataAndResolve() {
        const { data, error } = await refetch();

        if (data.prospectListExport.status === 'purchased') {
          resolve(data.prospectListExport);
        } else if (error) {
          reject(error);
        } else {
          setTimeout(fetchDataAndResolve, 1000);
        }
      }

      fetchDataAndResolve();
    });
  }

  return pollAndExport;
}
