import classnames from 'classnames';
import { CKBox, CKButton } from 'clearkit';
import { capitalize } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';

import { CreditSelector } from '~/components/billing/CreditSelector';
import {
  CustomStripeCCInputRef,
  FormData,
} from '~/components/billing/CustomStripeCCInput';
import { useStatusToast } from '~/components/unified/hooks';
import {
  AccountPlanEnum,
  BillingInfo,
  useAccountBillingInfoQuery,
  useAccountUpdateBillingInfoMutation,
  useDowngradeAccountSubscriptionMutation,
  useUpgradeAccountPlanMutation,
} from '~/generated/graphql';
import { useCreditUsage } from '~/hooks/account/useCreditUsage';
import useCreateStripeToken from '~/hooks/useCreateStripeToken';
import {
  creditOptions,
  getCostPerMonth,
  getIsFreeCreditTier,
  HUBSPOT_FREE_TIER_CREDITS,
  LEGACY_FREE_TIER_CREDITS,
} from '~/lib/selfServePlanHelpers';
import { usePublishableKeys } from '~/pages/Batches/hooks/usePublishableKeys';

import { useCurrentUser } from '../profile/useCurrentUser';
import { BillingChangeOptInModal } from './BillingChangeOptInModal';
import { BillingPaymentMethod } from './BillingPaymentMethod';
import { BillingSection } from './BillingSection';
import { BillingTOS } from './BillingTOS';
import {
  createRenewalDate,
  CurrentPlanContext,
  useCurrentPlan,
} from './useCurrentPlan';
import { formatDate } from './utils';

export const BillingManagePlan = () => {
  const { refetch: refetchAccount } = useCurrentUser();
  const { credits, isHubspotAccount } = useCreditUsage();
  const { canAccountUpgrade } = useCurrentPlan();

  const { currentPlan, setPendingPlanChange, setCurrentPlan } = useContext(
    CurrentPlanContext,
  );

  const [formErrors, setFormErrors] = useState<Partial<FormData>>({});
  const [isDowngrading, setIsDowngrading] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { publishableKeys } = usePublishableKeys();
  const stripeCCInputRef = useRef<CustomStripeCCInputRef>(null);
  const [ccErrorMessage, setCCErrorMessage] = useState<string | null>(null);
  const [tosError, setTosError] = useState(false);
  const [isEditingCC, setIsEditingCC] = useState(false);
  const [tosChecked, setTosChecked] = useState(false);
  const [creditGrantValue, setCreditGrantValue] = useState<number>(credits);
  const [isDirty, setIsDirty] = useState(false);

  const { mutate: createStripeToken } = useCreateStripeToken();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    data: billingData,
    refetch: refetchBilling,
  } = useAccountBillingInfoQuery();

  const [accountBilling, setAccountBillingInfo] = useState<
    BillingInfo | undefined
  >(billingData?.accountBillingInfo);

  useEffect(() => {
    setAccountBillingInfo(billingData?.accountBillingInfo);
    setIsEditingCC(!billingData?.accountBillingInfo.cardLastFour);
  }, [billingData]);

  useEffect(() => {
    setIsDirty(credits !== creditGrantValue);
  }, [credits, creditGrantValue]);

  const { addSuccessToast, addErrorToast } = useStatusToast();

  const [upgradeAccountPlan] = useUpgradeAccountPlanMutation({
    onCompleted: (resp) => {
      if (resp.upgradeAccountPlan?.errors?.length) {
        setIsSubmitting(false);
        addErrorToast({
          description: resp.upgradeAccountPlan?.errors[0].messages[0],
          heading: 'Error',
        });
        setCreditGrantValue(credits);
        return;
      } else {
        addSuccessToast({
          description:
            'Your subscription limit will be updated when your invoice has been successfully processed. You will receive an email confirmation shortly.',
          heading: 'Subscription Updated',
        });
      }

      setIsSubmitting(false);
      updateCurrentPlan();
    },
  });

  const [
    downgradeAccountSubscription,
  ] = useDowngradeAccountSubscriptionMutation({
    onCompleted: () => {
      setIsSubmitting(false);
      addSuccessToast({
        description: `Your subscription limit will be updated to its new limit ${formatDate(
          currentPlan?.renewDate as Date,
        )}.`,
        heading: 'Subscription Downgrade Scheduled',
      });
      refetchAccount();
    },
  });

  const handleCreditGrantChange = (value: number) => {
    if (value < credits) {
      setIsDowngrading(true);
    } else {
      setIsDowngrading(false);
    }

    setCreditGrantValue(value);
  };

  const upgradeToGrowth = async () => {
    let planId;

    if (isHubspotAccount) {
      planId =
        creditGrantValue === HUBSPOT_FREE_TIER_CREDITS
          ? AccountPlanEnum.HubspotFreeTier
          : AccountPlanEnum.HubspotPaidTier;
    } else {
      planId =
        creditGrantValue === LEGACY_FREE_TIER_CREDITS
          ? AccountPlanEnum.FreeTier
          : AccountPlanEnum.PaidTier;
    }

    return await upgradeAccountPlan({
      variables: {
        input: {
          planId,
          creditGrant: creditGrantValue,
        },
      },
    });
  };

  const [updateBillingInfo] = useAccountUpdateBillingInfoMutation();

  const handleTOSChange = (checked: boolean) => {
    setTosChecked(checked);
    setTosError(false);
  };

  const handleToggleConfirmModal = () => {
    setIsConfirmModalOpen(!isConfirmModalOpen);
  };

  const resetForm = () => {
    setTosChecked(false);
    setTosError(false);
    setFormErrors({});
    setIsEditingCC(false);
    setCCErrorMessage(null);
    setAccountBillingInfo(undefined);
    setIsDirty(false);
  };

  const updateCurrentPlan = () => {
    setCurrentPlan({
      ...currentPlan,
      costPerMonth: getCostPerMonth({
        credits: creditGrantValue,
        isHubspotAccount,
      }),
      credits: creditGrantValue,
      planLabel: 'Paid',
      isHubspotAccount: !!isHubspotAccount,
      renewDate: createRenewalDate(currentPlan?.startDate),
    });
  };

  const onSubmit = async () => {
    if (!tosChecked) {
      setTosError(true);
      handleToggleConfirmModal();
      return;
    }

    setIsSubmitting(true);
    setFormErrors({});

    const formData = stripeCCInputRef.current?.getFormData() as FormData;

    const newFormErrors: Partial<FormData> = {};

    if (formData) {
      Object.keys(formData).forEach((key) => {
        const formDataKey = key as keyof FormData;
        if (!formData[formDataKey]) {
          const readableKey = formDataKey
            .replace(/([A-Z])/g, ' $1')
            .toLowerCase();
          newFormErrors[formDataKey] = `${capitalize(readableKey)} is required`;
        }
      });
    }
    setFormErrors(newFormErrors);

    if (Object.keys(newFormErrors).length) {
      setIsSubmitting(false);
      return;
    }

    // Do not submit upgrade if there is no card saved
    if (formData && isEditingCC) {
      const cardData = {
        number: formData.creditCardNumber,
        exp_month: parseInt(formData.expiration.split('/')[0]),
        exp_year: parseInt(formData.expiration.split('/')[1]),
        cvc: formData.cvv,
        name: formData.fullName,
        address_line1: formData.billingAddress,
        address_city: formData.city,
        address_state: formData.state,
        address_zip: formData.zipcode,
      };

      createStripeToken(
        {
          cardData,
          stripePublishableKey: publishableKeys.STRIPE_PUBLISHABLE_KEY,
        },
        {
          onError: () => {
            addErrorToast({
              heading: 'Error',
              description: 'Invalid card information. Please try again.',
            });
            setCCErrorMessage('Invalid card information. Please try again.');
            setIsSubmitting(false);
            return;
          },
          onSuccess: async (result: any) => {
            if (result.error) {
              setCCErrorMessage(result?.error?.message as string);
              setIsSubmitting(false);
            } else {
              setCCErrorMessage(null);
              await updateBillingInfo({
                variables: {
                  input: {
                    cardToken: result.id,
                  },
                },
              });

              await upgradeToGrowth();
              refetchBilling();
            }
          },
        },
      );
    } else if (accountBilling?.cardLastFour) {
      if (isDowngrading) {
        await downgradeAccountSubscription({
          variables: {
            input: {
              downgradeLimit: creditGrantValue,
            },
          },
        });

        setCreditGrantValue(credits);

        /**
         * We have to set the pending plan change to give
         * feedback of the change. This will update with the actual
         * account data on the next page load.
         */
        setPendingPlanChange({
          isDowngrading,
          credits: creditGrantValue,
          effectiveDate: currentPlan?.renewDate,
          costPerMonth: getCostPerMonth({
            credits: creditGrantValue ?? 0,
            isHubspotAccount: false,
          }),
        });
      } else {
        upgradeToGrowth();
        resetForm();
      }
    }
    handleToggleConfirmModal();
    refetchAccount();
  };

  const selectedCreditOptions = creditOptions(isHubspotAccount);
  const isFreePlanSelected = getIsFreeCreditTier({
    credits: creditGrantValue,
    isHubspotAccount,
  });

  const isUpgradeDisabled = !canAccountUpgrade;

  return (
    <CKBox className="p-6 space-y-6 divide-y divide-gray-200">
      <h2
        className={classnames('font-medium', {
          'text-gray-400': isUpgradeDisabled,
        })}
      >
        Manage your plan
      </h2>
      <BillingSection isDisabled={isUpgradeDisabled}>
        <BillingSection.Heading className="space-y-2">
          <div>Select a credit tier</div>
          <div className="font-normal">
            Credits refresh monthly. All paid plans are annual commitment with
            monthly billing.
          </div>
        </BillingSection.Heading>

        <CreditSelector
          currentCredits={credits}
          isDisabled={isUpgradeDisabled}
          onChange={(value) => handleCreditGrantChange(value)}
          options={selectedCreditOptions}
          value={creditGrantValue}
        />
      </BillingSection>
      <BillingSection
        isActive={!isFreePlanSelected}
        isDisabled={isUpgradeDisabled}
        isOpen={isDirty && !isFreePlanSelected}
      >
        <BillingSection.Heading>Payment Method</BillingSection.Heading>
        <BillingPaymentMethod
          accountBilling={accountBilling}
          ccErrorMessage={ccErrorMessage}
          formErrors={formErrors}
          isEditingCC={isEditingCC}
          setFormErrors={setFormErrors}
          setIsEditingCC={setIsEditingCC}
          stripeCCInputRef={stripeCCInputRef}
        />
      </BillingSection>
      <BillingSection className="pt-6" isOpen={isDirty}>
        <BillingSection.Heading>Terms & conditions</BillingSection.Heading>
        <BillingTOS
          checked={tosChecked}
          onChange={handleTOSChange}
          tosError={tosError}
        />
      </BillingSection>

      <footer className="pt-6">
        <CKButton
          className="basis-auto shrink-0"
          isDisabled={!isDirty || tosError || isUpgradeDisabled}
          isLoading={isSubmitting}
          onClick={handleToggleConfirmModal}
          variant="bold"
          variantColor="green"
        >
          Change plan
        </CKButton>
        <BillingChangeOptInModal
          handleCancel={handleToggleConfirmModal}
          handleConfirm={onSubmit}
          isLoading={isSubmitting}
          isOpen={isConfirmModalOpen}
          pendingPlanChange={{
            isDowngrading,
            credits: creditGrantValue,
            effectiveDate: isDowngrading ? currentPlan?.renewDate : new Date(),
            costPerMonth: getCostPerMonth({
              credits: creditGrantValue ?? 0,
              isHubspotAccount,
            }),
          }}
        />
      </footer>
    </CKBox>
  );
};
