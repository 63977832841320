import { ClearbitIdentity, Go, Hubspot, ZapAlt } from '@clearkit/icons';
import { CKButton, CKIconBox, CKLayoutHorizontal } from 'clearkit';
import { Link } from 'react-router-dom';

import { useEntitlements } from '~/hooks/account';

import { HomeHeading } from '../HomeHeading';
import { useCRMLabel } from '../hooks/useCRMLabel';
import { useHomeIntegrations } from '../hooks/useHomeIntegrations';

export const HomeSidebarIntegrations = () => {
  const { hasAccessSettingsIntegrations } = useEntitlements();
  const { integrationsRoute } = useCRMLabel();

  const {
    hubspotConnector,
    isHubSpotConnected,
    isTagConnected,
  } = useHomeIntegrations();

  if (!hasAccessSettingsIntegrations) return null;

  return (
    <section className="pt-8 space-y-8">
      <CKLayoutHorizontal as="header">
        <HomeHeading>
          <ZapAlt height={16} width={16} /> Integrations
        </HomeHeading>
        <CKButton
          as={Link}
          rightIcon={<Go />}
          to={integrationsRoute}
          variant="tertiary"
          variantColor="blue"
        >
          Manage Integrations
        </CKButton>
      </CKLayoutHorizontal>
      <CKLayoutHorizontal justifyContent="start" wrap="nowrap">
        <CKIconBox className="!p-3 basis-auto shrink-0">
          <Hubspot className="text-hubspot" />
        </CKIconBox>
        <span className="text-base text-gray-900">
          <span className="block min-w-0 font-medium truncate">
            HubSpot
            {isHubSpotConnected
              ? ` ID: ${hubspotConnector?.hubspotAccountId}`
              : null}
          </span>
          {isHubSpotConnected
            ? hubspotConnector.remoteMeta.email
            : 'Not yet connected'}
        </span>
      </CKLayoutHorizontal>
      <CKLayoutHorizontal justifyContent="start" wrap="nowrap">
        <CKIconBox className="!p-3 basis-auto shrink-0">
          <ClearbitIdentity className="w-[1.125rem] h-[1.125rem]" />
        </CKIconBox>
        <span className="text-base text-gray-900">
          <span className="block min-w-0 font-medium truncate">
            Clearbit JavaScript
          </span>
          {isTagConnected ? 'Connected' : 'Not yet installed'}
        </span>
      </CKLayoutHorizontal>
    </section>
  );
};
