import { NetworkStatus, useQuery } from '@apollo/client';

import { paginateData } from '~/lib/graphql/pagination';

import query from './query.graphql';

const INITIAL_PAGE_SIZE = 40;
const PAGE_SIZE = 20;

type UsePersonEventArguments = {
  person: any;
  type?: string;
  path?: string;
};
export function usePersonEvents({
  person: providedPerson,
  type,
  path,
}: UsePersonEventArguments) {
  const variables = {
    first: INITIAL_PAGE_SIZE,
    id: providedPerson.id,
    type: type || undefined,
    path,
  };

  const {
    data = {},
    error,
    loading,
    fetchMore,
    refetch,
    networkStatus,
  } = useQuery(query, {
    fetchPolicy: 'cache-and-network',
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const person = { ...providedPerson, ...data?.person };

  const { events, eventsHasNextPage, eventsEndCursor } = paginateData(person, {
    fields: ['events'],
  });

  const handleFetchMore = () => {
    if (loading) {
      return;
    }

    fetchMore({
      variables: {
        ...variables,
        after: eventsEndCursor,
        first: PAGE_SIZE,
      },

      updateQuery: (prev, { fetchMoreResult }: any) => {
        if (!fetchMoreResult) {
          return prev;
        }

        fetchMoreResult.person.events.edges = [
          ...prev.person.events.edges,
          ...fetchMoreResult.person.events.edges,
        ];

        return fetchMoreResult;
      },
    });
  };

  return {
    error,
    loading,
    loadingFirstPage:
      loading &&
      networkStatus !== NetworkStatus.fetchMore &&
      events.length === 0,
    fetchMore: handleFetchMore,
    refetch,
    data: {
      person,
      events,
      eventsHasNextPage,
      eventsEndCursor,
    },
  };
}
