import { Docs as DocsIcon } from '@clearkit/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CKBox, CKButton, CKField, CKTextField } from 'clearkit';
import { useEffect, useState } from 'react';

import SecurityDisclaimer from '~/components/ConfigurationSecurityDisclaimer';
import {
  SalesforceAccountConnector,
  usePardotConnectionQuery,
  useUpdatePardotBusinessUnitIdMutation,
} from '~/generated/graphql';

import SalesforceConnectionForm from '../SalesforceConnectionForm';

type ErrorMap = Record<string, string[]>;
type PardotConnectionFormProps = {
  auth: (serviceIntegration: string, refetch: () => void) => void;
  onSuccess: () => void;
};
export default function PardotConnectionForm({
  auth,
  onSuccess,
}: PardotConnectionFormProps) {
  const [pardotBusinessUnitId, setPardotBusinessUnitId] = useState('');
  const [updateErrors, setUpdateErrors] = useState<ErrorMap>({});
  const { data = {}, loading, refetch } = usePardotConnectionQuery();
  const [
    updatePardotID,
    { loading: updatePardotLoading },
  ] = useUpdatePardotBusinessUnitIdMutation();
  const accountConnector = data.accountConnector as SalesforceAccountConnector;

  useEffect(() => {
    if (accountConnector) {
      setPardotBusinessUnitId(accountConnector.pardotBusinessUnitId ?? '');
    }
  }, [data]);

  async function updatePardotBusinessUnit() {
    setUpdateErrors({});

    const { data: mutationData } = await updatePardotID({
      variables: {
        input: {
          id: accountConnector.id,
          pardotBusinessUnitId,
        },
      },
    });

    const errors = mutationData?.updatePardotBusinessUnitId?.errors;

    if (errors?.length) {
      const errorObj: ErrorMap = {};

      errors.forEach(({ field, messages }) => {
        errorObj[field] = messages;
      });

      setUpdateErrors(errorObj);
    } else {
      onSuccess();
    }
  }

  if (loading) {
    return <CircularProgress />;
  }

  if (!accountConnector) {
    return (
      <>
        <SalesforceConnectionForm
          onAuth={(salesforceService: string) =>
            auth(salesforceService, refetch)
          }
        />
        <SecurityDisclaimer className="pt-4" />
      </>
    );
  }

  return (
    <div>
      <CKBox className="p-8 sm:w-xl">
        <div className="text-lg font-semibold text-center text-black">
          Connect to a Pardot Account
        </div>

        <p className="pb-4 text-center text-gray-500 text-md">
          Connect your Pardot account to sync Clearbit data to Pardot.
        </p>

        <div className="flex flex-col pb-4 gap-2">
          <div className="flex justify-between text-sm">
            <label htmlFor="pardot-business-unit-id">Business Unit ID</label>
            <a
              className="flex gap-1"
              href="https://help.clearbit.com/hc/en-us/articles/360060389094-Where-Can-I-Find-My-Pardot-Business-Unit-ID"
              rel="noopener noreferrer"
              target="_blank"
            >
              <DocsIcon />
              <span>How to find your Business Unit ID key</span>
            </a>
          </div>

          <CKField
            className="block w-full"
            variant={updateErrors.pardot_business_unit_id ? 'error' : undefined}
          >
            <CKTextField
              className="block w-full"
              id="pardot-business-unit-id"
              onChange={(e) => {
                const value = e.currentTarget.value.trim();
                setPardotBusinessUnitId(value);
              }}
              value={pardotBusinessUnitId || ''}
            />
          </CKField>

          <div className="text-red-500">
            {updateErrors.pardot_business_unit_id}
          </div>
        </div>

        <CKButton
          isDisabled={!pardotBusinessUnitId}
          isLoading={updatePardotLoading}
          onClick={updatePardotBusinessUnit}
          variant="bold"
          variantColor="blue"
        >
          Connect Pardot
        </CKButton>

        <div className="text-red-500">{updateErrors.base}</div>
      </CKBox>
      <SecurityDisclaimer className="pt-4" />
    </div>
  );
}
