import { CKContainerProps } from 'clearkit';

import { ExportGroup } from './ExportGroup';
import { ExportHeader } from './ExportHeader';
import { SalesforceDefaultMapping } from './SalesforceDefaultMapping';

export const SalesforceDefaultFields = (props: CKContainerProps) => {
  return (
    <ExportGroup {...props} className="space-y-6">
      <ExportHeader>Default Fields</ExportHeader>
      <SalesforceDefaultMapping>
        <SalesforceDefaultMapping.Label>
          Lead source
        </SalesforceDefaultMapping.Label>
        <SalesforceDefaultMapping.Value>
          Clearbit Created Lead
        </SalesforceDefaultMapping.Value>
      </SalesforceDefaultMapping>
      <SalesforceDefaultMapping>
        <SalesforceDefaultMapping.Label>Company</SalesforceDefaultMapping.Label>
        <SalesforceDefaultMapping.Value>
          Company Name
        </SalesforceDefaultMapping.Value>
      </SalesforceDefaultMapping>
      <SalesforceDefaultMapping>
        <SalesforceDefaultMapping.Label>
          Company website
        </SalesforceDefaultMapping.Label>
        <SalesforceDefaultMapping.Value>
          Company Website
        </SalesforceDefaultMapping.Value>
      </SalesforceDefaultMapping>
      <SalesforceDefaultMapping>
        <SalesforceDefaultMapping.Label>
          Company location
        </SalesforceDefaultMapping.Label>
        <SalesforceDefaultMapping.Value>
          Company Location
        </SalesforceDefaultMapping.Value>
      </SalesforceDefaultMapping>
      <SalesforceDefaultMapping>
        <SalesforceDefaultMapping.Label>
          Lead email
        </SalesforceDefaultMapping.Label>
        <SalesforceDefaultMapping.Value>
          Email Address
        </SalesforceDefaultMapping.Value>
      </SalesforceDefaultMapping>
      <SalesforceDefaultMapping>
        <SalesforceDefaultMapping.Label>
          Lead first name
        </SalesforceDefaultMapping.Label>
        <SalesforceDefaultMapping.Value>
          First Name
        </SalesforceDefaultMapping.Value>
      </SalesforceDefaultMapping>
      <SalesforceDefaultMapping>
        <SalesforceDefaultMapping.Label>
          Lead last name
        </SalesforceDefaultMapping.Label>
        <SalesforceDefaultMapping.Value>
          Last Name
        </SalesforceDefaultMapping.Value>
      </SalesforceDefaultMapping>
      <SalesforceDefaultMapping>
        <SalesforceDefaultMapping.Label>
          Lead title
        </SalesforceDefaultMapping.Label>
        <SalesforceDefaultMapping.Value>Title</SalesforceDefaultMapping.Value>
      </SalesforceDefaultMapping>
      <SalesforceDefaultMapping>
        <SalesforceDefaultMapping.Label>
          Lead phone number (if available)
        </SalesforceDefaultMapping.Label>
        <SalesforceDefaultMapping.Value>
          Phone Number
        </SalesforceDefaultMapping.Value>
      </SalesforceDefaultMapping>
      <SalesforceDefaultMapping>
        <SalesforceDefaultMapping.Label>
          Lead LinkedIn (if available)
        </SalesforceDefaultMapping.Label>
        <SalesforceDefaultMapping.Value>
          LinkedIn Profile
        </SalesforceDefaultMapping.Value>
      </SalesforceDefaultMapping>
    </ExportGroup>
  );
};
