import { CompaniesNav, Sparkles } from '@clearkit/icons';
import { CKLayoutHorizontal, cloneChildren } from 'clearkit';
import { ReactNode } from 'react';

import { EMPTY_LABEL } from '~/lib/constants';

type ProspectsHeaderProps = {
  companies: number;
};

const Count = ({
  children,
  count,
}: {
  children: ReactNode;
  count?: number;
}) => {
  let countDisplay;

  if (count && count > 1000000) {
    countDisplay = `${Math.round(count / 1000000)}M+`;
  } else if (count && count > 1000) {
    countDisplay = `${Math.round(count / 1000)}K+`;
  } else {
    countDisplay = count || EMPTY_LABEL;
  }

  return (
    <CKLayoutHorizontal gap=".25rem" justifyContent="start">
      {cloneChildren({
        children,
        newProps: {
          className: 'text-gray-600',
          height: 14,
          width: 14,
        },
      })}
      <span>{countDisplay}</span>
    </CKLayoutHorizontal>
  );
};

export const AddressableMarketHeader = ({
  companies,
}: ProspectsHeaderProps) => {
  const Divider = () => <span className="pt-1 text-xs text-gray-500">•</span>;

  return (
    <CKLayoutHorizontal
      className="px-4 py-1 text-base text-gray-800 border border-gray-200 rounded-full bg-gray-0"
      gap="0.75rem"
      justifyContent="start"
    >
      <CKLayoutHorizontal
        className="font-medium"
        gap=".5rem"
        justifyContent="start"
      >
        <Sparkles className="fill-gradient-br-yellow" />
        Companies in your addressable market
      </CKLayoutHorizontal>
      <Divider />
      <Count count={companies}>
        <CompaniesNav />
      </Count>
    </CKLayoutHorizontal>
  );
};
