import { useQuery } from '@apollo/client';
import { isEmpty, partition } from 'lodash';
import { useEffect, useState } from 'react';

import { SalesforceTrait } from '~/generated/graphql';
import contactQuery from '~/pages/destinations/destinationConfigs/CreateExternalRecords/salesforce/contact/query.graphql';
import leadQuery from '~/pages/destinations/destinationConfigs/CreateExternalRecords/salesforce/lead/query.graphql';

import { DEFAULT_EXPORT_CONTEXT } from './constants';
import { SalesforceExportContext } from './context';
import { ExportType, SalesforceExportProviderProps } from './types';
import { filterPresetFields, generateValueKeys } from './util';

export function SalesforceExportProvider({
  children,
}: SalesforceExportProviderProps) {
  const [exportType, setExportType] = useState<ExportType>(
    DEFAULT_EXPORT_CONTEXT.exportType,
  );
  const [fieldMapping, setFieldMapping] = useState(
    DEFAULT_EXPORT_CONTEXT.fieldMapping,
  );

  const { data: contactData, loading: contactLoading } = useQuery(contactQuery);
  const { data: leadData, loading: leadLoading } = useQuery(leadQuery);

  const isLoading = contactLoading || leadLoading;
  const data = exportType === 'contact' ? contactData : leadData;

  const [requiredFields, optionalFields] = partition(
    data?.salesforceTraits,
    (trait: SalesforceTrait) => trait.required,
  );

  const optionalFieldsFiltered = filterPresetFields(optionalFields);
  const requiredFieldsFiltered = filterPresetFields(requiredFields);
  const _requiredFieldKeys = requiredFieldsFiltered.map(
    (trait: SalesforceTrait) => trait.name,
  );

  useEffect(() => {
    if (isLoading) return;

    if (isEmpty(fieldMapping) && data.length > 0) {
      setFieldMapping({
        ...generateValueKeys(requiredFieldsFiltered),
        ...generateValueKeys(optionalFieldsFiltered),
      });
    }
  }, [isLoading, fieldMapping, requiredFields, optionalFields]);

  const isValid = true; // TODO: Implement validation with _requiredFieldKeys

  return (
    <SalesforceExportContext.Provider
      value={{
        isLoading,
        isValid,
        exportType,
        fieldMapping,
        optionalFields: optionalFieldsFiltered,
        requiredFields: requiredFieldsFiltered,
        setFieldMapping,
        setExportType,
      }}
    >
      {children}
    </SalesforceExportContext.Provider>
  );
}
