import {
  CKChipListNext,
  CKLayoutHorizontal,
  CKListBoxMultiSelect,
  CKListBoxMultiSelectProps,
  CKMultiPickerNext,
  CKSelectableItem,
  excludeChildrenByDisplayName,
  includeChildrenByDisplayName,
} from 'clearkit';
import { FC } from 'react';

import {
  componentDisplayNames,
  Filter,
  FilterInstanceComposition,
} from './Filter';

type FilterMultiPickerComposition = FilterInstanceComposition;

const errorDisplayName = 'CKMultiPickerNext.ErrorMessage';
const {
  helpTextDisplayName,
  iconDisplayName,
  titleDisplayName,
} = componentDisplayNames;

type FilterMultiPickerProps = Omit<
  CKListBoxMultiSelectProps,
  'onChange' | 'value'
> & {
  onChange: (value: string[]) => void;
  value?: string[];
};

export const FilterMultiPicker: FC<FilterMultiPickerProps> &
  FilterMultiPickerComposition = ({
  children,
  className,
  items,
  onChange,
  canAddCustomValue = false,
  placeholder = 'Choose filter or add custom value',
  popoverPlacement = 'bottom-start',
  popoverWidth,
  value,
  ...rest
}) => {
  const handleClearFilter = () => {
    onChange([]);
  };

  const handleRemoveFilter = (item: CKSelectableItem) => {
    if (value) onChange(value.filter((valueItem) => valueItem !== item.value));
  };

  const lookupLabel = (value: string) =>
    items?.find((item) => item.value === value)?.label || value;

  return (
    <Filter
      className={className}
      filterCount={value?.length}
      onClearFilter={handleClearFilter}
      popoverPlacement={popoverPlacement}
      popoverWidth={popoverWidth}
      shouldStartOpen={value && value?.length > 0}
    >
      {excludeChildrenByDisplayName({
        children,
        componentDisplayName: [errorDisplayName],
      })}
      <Filter.Menu>
        <CKListBoxMultiSelect
          {...rest}
          autoFocus
          canAddCustomValue={canAddCustomValue}
          items={items}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
        >
          {includeChildrenByDisplayName({
            children,
            componentDisplayName: [errorDisplayName],
          })}
        </CKListBoxMultiSelect>
      </Filter.Menu>
      <Filter.Input>
        <CKLayoutHorizontal gap=".25rem" justifyContent="start">
          <CKChipListNext
            className="gap-1"
            items={value?.map((item) => ({
              label: lookupLabel(item),
              value: item,
            }))}
            removeItem={(newValue) =>
              handleRemoveFilter(newValue as CKSelectableItem)
            }
            shouldOverflow={false}
          />
        </CKLayoutHorizontal>
      </Filter.Input>
    </Filter>
  );
};

FilterMultiPicker.ErrorMessage = CKMultiPickerNext.ErrorMessage;
FilterMultiPicker.ErrorMessage.displayName = errorDisplayName;

FilterMultiPicker.HelpText = Filter.HelpText;
FilterMultiPicker.HelpText.displayName = helpTextDisplayName;

FilterMultiPicker.Icon = Filter.Icon;
FilterMultiPicker.Icon.displayName = iconDisplayName;

FilterMultiPicker.Title = Filter.Title;
FilterMultiPicker.Title.displayName = titleDisplayName;
