import { useMutation, useQuery } from '@apollo/client';
import {
  CKButton,
  CKModal,
  CKTab,
  CKTabList,
  CKTabPanel,
  CKTabPanels,
  CKTabs,
} from 'clearkit';
import { uniq } from 'lodash';
import { FC, useEffect, useState } from 'react';

import SidebarDrawer from '~/components/SidebarDrawer';
import UpgradeAccountForm from '~/components/UpgradeAccountForm';
import {
  AccountFlagEnum,
  ExportStatusEnum,
  Segment,
  SegmentLegacyTypeEnum,
  useExistingSegmentExportQuery,
  useUsageActionAllowedQuery,
} from '~/generated/graphql';
import { useFeatureFlag } from '~/hooks/account';
import { trackCSVExported } from '~/lib/segmentAnalytics';

import {
  CsvExportHistoryBody,
  CsvExportHistoryFooter,
} from './CsvExportHistory';
import csvExportQuery from './csvExportQuery.graphql';
import {
  CsvExportSettingsBody,
  CsvExportSettingsFooter,
} from './CsvExportSettings';
import exportCsvMutation from './mutation.graphql';

interface CsvExportDialogProps {
  open: boolean;
  onClose: () => void;
  searchQuery?: string;
  segment?: Segment;
  type: 'Company' | 'Person';
}

const CsvExportDialog: FC<CsvExportDialogProps> = ({
  open,
  onClose,
  searchQuery,
  segment,
  type,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const allowUsageWarningBanner = useFeatureFlag(
    AccountFlagEnum.AllowPlatformCsvExportWarningBanner,
  ).enabled;

  const {
    data: existingExportData,
    loading: existingExportLoading,
    refetch,
  } = useExistingSegmentExportQuery({
    variables: {
      segmentId: segment?.id,
      status: [ExportStatusEnum.Uploading, ExportStatusEnum.Queued],
      type: type as SegmentLegacyTypeEnum,
    },
    fetchPolicy: 'no-cache',
  });

  const quotaEnforced = useFeatureFlag(AccountFlagEnum.AllowPlatformGateUsage)
    .enabled;

  const { data: actionAllowedData } = useUsageActionAllowedQuery({
    variables: {
      credits: segment?.resultsCount || 0,
    },
    skip: !quotaEnforced,
    fetchPolicy: 'no-cache',
  });

  const actionQuotaEnforced = !(
    !quotaEnforced || actionAllowedData?.usageActionAllowed
  );

  const [columns, setColumns] = useState<string[] | undefined>(undefined);
  const [step, setStep] = useState<'columns' | 'confirmation'>('columns');

  const { data, loading, error } = useQuery(csvExportQuery, {
    variables: {
      attributeType: type,
      columnType: type,
      ...(segment?.id && { segmentId: segment.id }),
    },
  });

  const [exportCsv] = useMutation(exportCsvMutation);

  useEffect(() => {
    if (loading) {
      return;
    }
    const columnObjectsData = columnObjects(data.attributes, data.columns);
    setColumns(columnObjectsData);
  }, [loading, data]);

  const columnObjects = (allColumns: any[], stringColumns: string[]) => {
    return stringColumns.map((stringColumn) => {
      const fullColumn = allColumns.find(
        (fullColumn) => fullColumn.value === stringColumn,
      );
      return { ...fullColumn };
    });
  };

  const handleCompleted = (segment: any, columns: any) => {
    if (segment) {
      trackCSVExported(
        segment,
        uniq(columns.map((column: any) => column.category)),
      );
    }
    setStep('confirmation');
  };
  const inProgressExport = existingExportData?.segmentExport?.[0];

  if (loading || error) {
    return null;
  }
  const {
    attributes,
    viewer: {
      user: { email },
    },
  } = data;
  return (
    <CKModal
      isVisible={open}
      onToggle={onClose}
      size={step === 'columns' ? 'xl' : 'md'}
    >
      {step === 'columns' ? (
        <>
          <CKTabs
            className="overflow-hidden grid grid-rows-[auto_auto_1fr_auto]"
            onChange={setTabIndex}
            tabIndex={tabIndex}
          >
            <CKModal.Header className="border-none">
              <CKModal.Heading>
                Configure the CSV file to export
              </CKModal.Heading>
              <p>
                Export a maximum of 2500 enriched records from this segment.
              </p>
            </CKModal.Header>

            <CKTabList className="px-10 pb-4">
              <CKTab label="Export Settings">Export Settings</CKTab>
              <CKTab label="Export History">Export History</CKTab>
            </CKTabList>
            <CKModal.Body className="min-h-0 px-10 ck-scrollbar ck-scrollbar--vertical">
              <CKTabPanels>
                <CKTabPanel>
                  <CsvExportSettingsBody
                    attributes={attributes}
                    columns={columns}
                    existingExport={inProgressExport}
                    existingExportLoading={existingExportLoading}
                    refetchExport={refetch}
                    segment={segment}
                    setColumns={setColumns}
                    type={type}
                  />
                </CKTabPanel>
                <CKTabPanel>
                  <CsvExportHistoryBody segment={segment} type={type} />
                </CKTabPanel>
              </CKTabPanels>
            </CKModal.Body>
            <CKModal.Footer className="flex gap-4">
              <CKTabPanels className="w-full">
                <CKTabPanel>
                  <CsvExportSettingsFooter
                    columns={columns}
                    existingExport={inProgressExport}
                    exportCsv={exportCsv}
                    handleCompleted={handleCompleted}
                    onClose={onClose}
                    searchQuery={searchQuery}
                    segment={segment}
                    showUsageHelpDoc={allowUsageWarningBanner}
                    type={type}
                  />
                </CKTabPanel>
                <CKTabPanel>
                  <CsvExportHistoryFooter
                    onClose={onClose}
                    showUsageHelpDoc={allowUsageWarningBanner}
                  />
                </CKTabPanel>
              </CKTabPanels>
            </CKModal.Footer>
          </CKTabs>
        </>
      ) : null}
      {step === 'confirmation' ? (
        <ConfirmationStep
          actionQuotaEnforced={actionQuotaEnforced}
          email={email}
          onClose={onClose}
        />
      ) : null}
    </CKModal>
  );
};

type ConfirmationStepProps = {
  actionQuotaEnforced: boolean;
  email: string;
  onClose: () => void;
};
function ConfirmationStep({
  actionQuotaEnforced,
  email,
  onClose,
}: ConfirmationStepProps) {
  const [showUpgradeForm, setShowUpgradeForm] = useState(false);

  if (actionQuotaEnforced) {
    return (
      <>
        <CKModal.Header>
          <CKModal.Heading>
            You are out of credits for this month
          </CKModal.Heading>
        </CKModal.Header>
        <CKModal.Body className="px-8 py-4">
          <a className="ck-link" onClick={() => setShowUpgradeForm(true)}>
            Contact our sales team
          </a>
          &nbsp; now to upgrade or wait until next month for a free refresh.
        </CKModal.Body>
        <CKModal.Footer>
          <CKButton onClick={onClose} variant="simple">
            Dismiss
          </CKButton>
        </CKModal.Footer>

        <SidebarDrawer
          isVisible={showUpgradeForm}
          onToggle={() => {
            setShowUpgradeForm(!showUpgradeForm);
          }}
        >
          <UpgradeAccountForm
            onClose={() => {
              setShowUpgradeForm(false);
            }}
          />
        </SidebarDrawer>
      </>
    );
  } else {
    return (
      <>
        <CKModal.Header>
          <CKModal.Heading>Your CSV download is being prepared</CKModal.Heading>
        </CKModal.Header>
        <CKModal.Body className="px-8 py-4">
          We&apos;ll email you at the address below when your CSV is ready for
          download.
          <p>{email}</p>
        </CKModal.Body>
        <CKModal.Footer>
          <CKButton onClick={onClose} variant="simple">
            Dismiss
          </CKButton>
        </CKModal.Footer>
      </>
    );
  }
}

export default CsvExportDialog;
