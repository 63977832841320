// eslint-disable-next-line clearbit-x/no-tree-shaking-imports
import { fade } from '@material-ui/core/styles/colorManipulator';

const theme = (outerTheme) => ({
  ...outerTheme,

  editorStyles: {
    primary: outerTheme.palette.hubspot[500],
    secondary: outerTheme.palette.hubspot[400],
    tertiary: outerTheme.palette.hubspot[300],
    border: `${fade(outerTheme.palette.hubspot[200], 0.4)}`,
    tint: outerTheme.palette.hubspot[100],
    background: outerTheme.palette.hubspot[0],
    shadow: `0 0 0 1px ${fade(
      outerTheme.palette.hubspot[200],
      0.4,
    )}, 0 1px 2px 1px rgba(0, 0, 0, .08)`,
  },
});

export default theme;
