
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SegmentDestinationsPageAudience"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Audience"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"createdAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"externalName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"subtype"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"user"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"AudiencesServiceIcon"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"AudiencesTile"},"directives":[]}]}}],"loc":{"start":0,"end":293}};
    doc.loc.source = {"body":"#import \"~/components/audiences/ServiceIcon/fragments.graphql\"\n#import \"~/components/audiences/Tile/fragments.graphql\"\n\nfragment SegmentDestinationsPageAudience on Audience {\n  id\n  createdAt\n  externalName\n  subtype\n  user {\n    id\n    name\n  }\n  ...AudiencesServiceIcon\n  ...AudiencesTile\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("~/components/audiences/ServiceIcon/fragments.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("~/components/audiences/Tile/fragments.graphql").definitions));


      module.exports = doc;
    
