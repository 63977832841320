const styles = () => ({
  root: {
    padding: '30px 40px',
  },

  audienceItem: {
    background: '#ffffff',
    border: 'none',
    borderRadius: 6,
    boxShadow:
      '0 0 0 1px rgba(107, 144, 192, .15), 0 1px 2px 1px rgba(0, 0, 0, .05)',
    width: '100%',
    marginBottom: 16,
    overflow: 'hidden',
  },

  buttonRoot: {
    border: '1px solid rgb(225, 233, 240)',
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
  },

  heading: {
    color: '#596168',
    fontSize: 15,
    fontWeight: 500,
    margin: '0 0 15px 0',
  },

  infoText: {
    color: '#596168',
    fontWeight: 400,
    fontSize: 14,
    marginBottom: 20,
    marginTop: -5,
  },

  link: {
    textDecoration: 'underline',
  },

  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },

  break: {
    marginTop: 16,
  },
});

export default styles;
