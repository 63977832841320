const styles = () => ({
  icon: {
    position: 'relative',
    marginRight: 6,
    flexShrink: 0,
  },

  segmentWithDestinationsIcon: {
    marginRight: 6,
  },

  segmentIconUnselected: {
    '& path.modifier': {
      fill: '#31D48B',
    },
  },

  segmentIconSelected: {
    '& path.modifier': {
      fill: 'fill-gradient-br-blue',
    },
  },
});

export default styles;
