import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom';

import App from '~/components/App';
import ErrorHandler from '~/components/errorHandler';
import disableAnimationsWhileResizing from '~/lib/disableAnimationsWhileResizing';

import './css/submission/themes/default.css';
import './index.css';

Sentry.init({
  dsn: process.env.SENTRY_FRONTEND_DSN,
  env: process.env.NODE_ENV,
});

disableAnimationsWhileResizing();

const renderApp = () => {
  return (
    <ErrorHandler>
      <App />
    </ErrorHandler>
  );
};

ReactDOM.render(renderApp(), document.getElementById('root'));
