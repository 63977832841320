import { useEffect } from 'react';

export default function useNavigationEditGuard(checkFn) {
  function nativeUnloadHandler(event) {
    if (!checkFn()) {
      return;
    }

    event.preventDefault();
    event.returnValue = '';
  }

  useEffect(() => {
    window.addEventListener('beforeunload', nativeUnloadHandler);

    return () => {
      window.removeEventListener('beforeunload', nativeUnloadHandler);
    };
  }, [checkFn]);
}
