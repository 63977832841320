import { useMutation } from '@apollo/client/react/hooks';
import { InfoHollow } from '@clearkit/icons';
import {
  CKBadge,
  CKButton,
  CKCardCollapsible,
  CKModal,
  CKTooltip,
} from 'clearkit';
import capitalize from 'lodash/capitalize';

import ToastsContainer from '~/containers/Toasts';
import { AccountFlagEnum } from '~/generated/graphql';
import { useFeatureFlag } from '~/hooks/account';
import { readableObjectTypeName, readableType } from '~/lib/dataEnrichment';

import cancel from './cancel.graphql';
import trigger from './trigger.graphql';

const BackfillTriggerFields = ({
  integration,
  objectType,
  accountConnector,
  setTriggering,
  hasSavedSelectiveEnrichmentConfig,
  hasSavedFieldMappings,
  enrichmentEnabled,
  accountConnectorBackfillActive,
  setAccountConnectorBackfillActive,
}) => {
  const isMarketo = integration === 'marketo';
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const [triggerBackfill, { loading: triggering }] = useMutation(trigger, {
    onCompleted: (data) => {
      if (data.response?.errors?.length) {
        setTriggering(false);
        data.response.errors.forEach((error) => {
          error.messages.forEach((message) => {
            ToastsContainer.addWarning(message);
          });
        });
        return;
      }

      setTriggering(false);
      setAccountConnectorBackfillActive(true);
      ToastsContainer.addSuccess('Backfill enrichment started');
    },
    onError: () => {
      setTriggering(false);
      ToastsContainer.addWarning(
        `An error occurred with initiating your backfill enrichment, please try again.`,
      );
    },
    refetchQueries: ['FieldMappingForm'],
    variables: {
      input: {
        id: accountConnector?.id,
        targetObjectType: objectType.name,
      },
    },
  });

  const [cancelBackfill, { loading: cancelling }] = useMutation(cancel, {
    onCompleted: () => {
      setAccountConnectorBackfillActive(false);
      ToastsContainer.addSuccess(
        `${capitalize(integration)} ${capitalize(
          objectType.name,
        )} backfill cancelled!`,
      );
    },
    onError: () => {
      ToastsContainer.addWarning(
        `An error occurred with cancelling your backfill enrichment, please try again.`,
      );
    },
    refetchQueries: ['FieldMappingForm'],
    variables: {
      input: {
        id: accountConnector?.id,
        targetObjectType: objectType.name,
      },
    },
  });

  const startBackfill = () => {
    setTriggering(true);
    triggerBackfill().then(() => setConfirmationModalOpen(false));
  };

  const backfillLoading = triggering || cancelling;

  return (
    <SelectiveEnrichmentBackfillConfig
      accountConnectorBackfillActive={accountConnectorBackfillActive}
      backfillLoading={backfillLoading}
      cancelBackfill={cancelBackfill}
      confirmationModalOpen={confirmationModalOpen}
      enrichmentEnabled={enrichmentEnabled}
      hasSavedFieldMappings={hasSavedFieldMappings}
      hasSavedSelectiveEnrichmentConfig={hasSavedSelectiveEnrichmentConfig}
      integration={integration}
      isMarketo={isMarketo}
      objectType={objectType}
      onDismiss={() => setConfirmationModalOpen(false)}
      onOpen={() => setConfirmationModalOpen(true)}
      startBackfill={() => startBackfill()}
    />
  );
};

function SelectiveEnrichmentBackfillConfig({
  isMarketo,
  integration,
  objectType,
  accountConnectorBackfillActive,
  startBackfill,
  backfillLoading,
  cancelBackfill,
  enrichmentEnabled,
  hasSavedSelectiveEnrichmentConfig,
  hasSavedFieldMappings,
  confirmationModalOpen,
  onOpen,
  onDismiss,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="px-4 mt-10 mb-6">
      <CKCardCollapsible
        isOpen={isMarketo ? false : isOpen}
        onToggle={handleToggle}
      >
        <SelectiveEnrichmentBackfillHeader
          accountConnectorBackfillActive={accountConnectorBackfillActive}
          integration={integration}
          isMarketo={isMarketo}
          objectType={objectType}
        />
        <CKCardCollapsible.Body>
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-col mr-20 w-96">
              <p className="text-base font-medium text-gray-800">
                Start a backfill enrichment on {readableType(integration)}{' '}
                {readableObjectTypeName(objectType.name)}
              </p>
            </div>
            {accountConnectorBackfillActive ? (
              <CKButton
                isLoading={backfillLoading}
                onClick={onOpen}
                variant="simple"
                variantColor="red"
              >
                Cancel Backfill
              </CKButton>
            ) : (
              <StartBackfillButton
                enrichmentEnabled={enrichmentEnabled}
                hasSavedFieldMappings={hasSavedFieldMappings}
                onClick={onOpen}
              />
            )}
          </div>
          {hasSavedSelectiveEnrichmentConfig ? (
            <SelectiveEnrichmentBackfillFooter
              integration={integration}
              objectType={objectType}
            />
          ) : null}
        </CKCardCollapsible.Body>
      </CKCardCollapsible>
      <ConfirmationModal
        cancelBackfill={cancelBackfill}
        confirmationModalOpen={confirmationModalOpen}
        integration={integration}
        isActive={accountConnectorBackfillActive}
        isLoading={backfillLoading}
        objectType={objectType}
        onClick={onDismiss}
        startBackfill={startBackfill}
      />
    </div>
  );
}

function SelectiveEnrichmentBackfillFooter({ integration, objectType }) {
  return (
    <CKCardCollapsible.Footer className="flex flex-row items-center bg-gray-0">
      <div className="flex flex-col mr-4">
        <InfoHollow />
      </div>
      Selective enrichment is on. Enriched {objectType.name} data will only be
      synced to {readableType(integration)} for{' '}
      {readableObjectTypeName(objectType.name)} that meet your selective
      enrichment criteria.
    </CKCardCollapsible.Footer>
  );
}

function SelectiveEnrichmentBackfillHeader({
  isMarketo,
  integration,
  objectType,
  accountConnectorBackfillActive,
}) {
  return (
    <CKCardCollapsible.Header className={isMarketo ? 'bg-gray-0' : ''}>
      <div
        className="grid grid-cols-1fr-auto gap-4"
        data-testid="backfillConfigCardHeader"
      >
        <div className="text-base">
          <h3 className="mb-2 font-medium">Backfill Enrichment</h3>
          {isMarketo ? (
            <p>Backfill enrichment is currently unavailable for Marketo.</p>
          ) : (
            <p>
              Use this to enrich existing{' '}
              {readableObjectTypeName(objectType.name)} found within{' '}
              {readableType(integration)}. This can be helpful if you’ve added
              field mappings and want these enriched fields synced to{' '}
              {readableObjectTypeName(objectType.name)} already in your CRM.
              This process may take some time.
            </p>
          )}
        </div>
        {accountConnectorBackfillActive ? (
          <CKBadge
            className="self-start text-xs text-green-700 bg-green-100"
            variant="default"
          >
            Backfill running
          </CKBadge>
        ) : null}
      </div>
      {isMarketo && (
        <CKCardCollapsible.Trigger>
          <a
            href="https://help.clearbit.com/hc/en-us/articles/17748264375575"
            rel="noopener noreferrer"
            target="_blank"
          >
            <CKButton variant="tertiary" variantColor="blue">
              Learn more
            </CKButton>
          </a>
        </CKCardCollapsible.Trigger>
      )}
    </CKCardCollapsible.Header>
  );
}

function StartBackfillButton({
  isLoading,
  enrichmentEnabled,
  hasSavedFieldMappings,
  onClick,
}) {
  const enrichmentUIEnabled = useFeatureFlag(
    AccountFlagEnum.AllowDataEnrichmentPreview,
  ).enabled;
  const enrichmentDisabled = useFeatureFlag(AccountFlagEnum.XDataEnrichment)
    .disabled;
  // userPreviewingFeature - a free_tier user can accerss the UI for CRM enrichment but cant actually enable the feature
  const userPreviewingFeature = enrichmentUIEnabled && enrichmentDisabled;

  if (!hasSavedFieldMappings || !enrichmentEnabled || userPreviewingFeature) {
    const message = tooltipMessage(enrichmentEnabled, userPreviewingFeature);
    return (
      <CKTooltip placement="top-end" tooltip={message} tooltipMaxWidth="25ch">
        <CKButton
          className="cursor-pointer"
          isDisabled
          isLoading={isLoading}
          variant="bold"
          variantColor="blue"
        >
          Run Backfill
        </CKButton>
      </CKTooltip>
    );
  }

  return (
    <CKButton
      isLoading={isLoading}
      onClick={onClick}
      variant="bold"
      variantColor="blue"
    >
      Run Backfill
    </CKButton>
  );
}

function tooltipMessage(enrichmentEnabled, userPreviewingFeature) {
  if (userPreviewingFeature) {
    return 'CRM enrichment is not enabled for free tier customers. Get access by upgrading to our Business plan.';
  } else if (!enrichmentEnabled) {
    return 'Enrichment must be turned on before running a backfill';
  } else {
    return 'Field mappings are required before running a backfill';
  }
}

function ConfirmationModal({
  isActive,
  isLoading,
  confirmationModalOpen,
  onClick,
  integration,
  objectType,
  startBackfill,
  cancelBackfill,
}) {
  return (
    <CKModal
      aria-label="Modal"
      isVisible={confirmationModalOpen}
      onToggle={onClick}
      size="xs"
    >
      <CKModal.Header className="pb-2">
        <CKModal.Heading>
          {isActive
            ? 'Are you sure you want to cancel this backfill enrichment?'
            : 'Are you sure you want to start a backfill enrichment?'}
        </CKModal.Heading>
      </CKModal.Header>
      <CKModal.Body className="px-10 pt-2 pb-10">
        <p>
          {isActive
            ? `Any ${capitalize(integration)} ${
                objectType.name
              } with data synced during this backfill enrichment will retain that data.`
            : `Enriched data for the field mappings you’ve set up will be synced for to all ${
                objectType.name
              }s in your ${capitalize(integration)} CRM.`}
        </p>
      </CKModal.Body>
      <CKModal.Footer className="gap-2">
        <CKButton isLoading={isLoading} onClick={onClick}>
          Dismiss
        </CKButton>
        {isActive ? (
          <CKButton
            isLoading={isLoading}
            onClick={() => cancelBackfill().then(onClick)}
            variant="bold"
            variantColor="red"
          >
            Yes, cancel this backfill enrichment
          </CKButton>
        ) : (
          <CKButton
            isLoading={isLoading}
            onClick={startBackfill}
            variant="bold"
            variantColor="blue"
          >
            Yes, enrich and sync data
          </CKButton>
        )}
      </CKModal.Footer>
    </CKModal>
  );
}

export default BackfillTriggerFields;
