import SvgIcon from '~/components/icons/SvgIcon';

const ClearIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 10 10">
    <path
      d="M5,6.12110854 L1.56055699,2.25173515 C1.28536848,1.94214808 0.811314197,1.91426253 0.501727121,2.18945104 C0.192140044,2.46463956 0.164254499,2.93869384 0.439443011,3.24828091 L4.43944301,7.74828091 C4.73778627,8.08391707 5.26221373,8.08391707 5.56055699,7.74828091 L9.56055699,3.24828091 C9.8357455,2.93869384 9.80785996,2.46463956 9.49827288,2.18945104 C9.1886858,1.91426253 8.71463152,1.94214808 8.43944301,2.25173515 L5,6.12110854 Z"
      fill="#9BADBC"
      fillRule="evenodd"
    />
  </SvgIcon>
);

ClearIcon.defaultProps = {
  width: 10,
  height: 10,
};

ClearIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default ClearIcon;
