import { ExportsHistoryEmptyState } from '../Exports';
import { useProspector } from '../useProspector';
import { ExportsList } from './ExportsList';

export const ExportsListHistory = () => {
  const [state] = useProspector();

  const items = state.context.prospectListExportsHistory;

  return (
    <ExportsList isLoading={state.context.loadingExports} items={items}>
      <ExportsList.Title>Recent Exports</ExportsList.Title>
      <ExportsList.Description>
        CSV exports or exports to your CRM that have completed. Previously
        downloaded CSVs can be redownloaded here.
      </ExportsList.Description>
      <ExportsList.EmptyState>
        <ExportsHistoryEmptyState />
      </ExportsList.EmptyState>
    </ExportsList>
  );
};
