import { UsageAltNav } from '@clearkit/icons';
import {
  CKBox,
  CKButton,
  CKContainerProps,
  CKLayoutHorizontal,
} from 'clearkit';
import { ReactNode, useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { useCancelDowngradeAccountSubscriptionMutation } from '~/generated/graphql';
import { EMPTY_LABEL } from '~/lib/constants';
import { formatCostPerMonth } from '~/lib/selfServePlanHelpers';

import { routes } from '../app/appMainRoutes';
import { useCurrentUser } from '../profile/useCurrentUser';
import { useStatusToast } from '../unified/hooks';
import { BillingPendingPlanChange } from './BillingPendingChange';
import { BillingSection } from './BillingSection';
import { CurrentPlanContext } from './useCurrentPlan';
import { formatDate } from './utils';

const Value = ({
  children,
  label,
}: {
  children: ReactNode;
  label: ReactNode;
}) => (
  <div>
    <label className="text-sm text-gray-500">{label}</label>
    <div className="text-xl font-semibold">{children}</div>
  </div>
);

export const BillingCurrentPlan = ({
  className,
  ...rest
}: CKContainerProps) => {
  const { currentPlan, pendingPlanChange, setPendingPlanChange } = useContext(
    CurrentPlanContext,
  );

  const { refetch: refetchAccount } = useCurrentUser();
  const { addSuccessToast, addErrorToast } = useStatusToast();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const renewDateLabel = currentPlan?.renewDate
    ? formatDate(currentPlan?.renewDate)
    : EMPTY_LABEL;

  const isFree = currentPlan?.costPerMonth === 0;

  const [cancelDowngrade] = useCancelDowngradeAccountSubscriptionMutation({
    variables: {
      input: {},
    },
    onCompleted: (resp) => {
      if (resp.cancelDowngradeAccountSubscription?.errors?.length) {
        addErrorToast({
          description:
            resp.cancelDowngradeAccountSubscription?.errors[0].messages[0],
          heading: 'Error',
        });
        setIsSubmitting(false);

        return;
      } else {
        setPendingPlanChange(undefined);
        addSuccessToast({
          description:
            'Your scheduled downgrade has been cancelled. Your account will remain on your current plan.',
          heading: 'Downgrade Cancelled',
        });
      }

      setIsSubmitting(false);
      refetchAccount();
    },
  });

  const handleCancel = async () => {
    setIsSubmitting(true);
    await cancelDowngrade();
  };

  return (
    <CKBox {...rest} className={className}>
      <section className="p-6 space-y-6 divide-y divide-gray-200">
        <header className="font-medium">Current plan</header>
        <div className="pt-6 space-y-6">
          <Value label="Plan">{currentPlan?.planLabel}</Value>
          <Value label="Credits per month">
            {currentPlan?.credits?.toLocaleString('en-us') ?? EMPTY_LABEL}
          </Value>
          <Value label="Monthly cost">
            {formatCostPerMonth(currentPlan?.costPerMonth ?? 0)}
          </Value>
          <Value label="Subscription renews on">
            {isFree ? EMPTY_LABEL : renewDateLabel}
          </Value>
          {pendingPlanChange?.isDowngrading ? (
            <BillingSection>
              <BillingSection.Heading>
                Pending plan change
              </BillingSection.Heading>

              <BillingPendingPlanChange
                handleCancel={handleCancel}
                isLoading={isSubmitting}
                pendingPlanChange={pendingPlanChange}
                previousCostsPerMonth={currentPlan?.costPerMonth}
                previousCredits={currentPlan?.credits}
              />
            </BillingSection>
          ) : null}
        </div>
      </section>
      <CKBox.Footer className="px-6 py-3">
        <CKLayoutHorizontal>
          <CKButton
            as={Link}
            leftIcon={<UsageAltNav className="w-4 h-4" />}
            to={routes.usage}
            variant="tertiary"
            variantColor="blue"
          >
            View usage
          </CKButton>
          <a className="text-sm ck-link" href="/">
            Learn more about credits
          </a>
        </CKLayoutHorizontal>
      </CKBox.Footer>
    </CKBox>
  );
};
