import { Mutation } from '@apollo/client/react/components';
import { useQuery } from '@apollo/client/react/hooks';
import { CKButton, CKDrawer } from 'clearkit';
import { Link } from 'react-router-dom';

import ConfirmDeleteModal from '~/components/ConfirmDeleteModal';
import ToastContainer from '~/containers/Toasts';
import history from '~/lib/history';
import paths from '~/lib/paths';
import { trackDestinationDeleted } from '~/lib/segmentAnalytics';
import { legacySegmentType } from '~/lib/segments';

import FormWrapper from '../components/FormWrapper';
import DestinationFormFactory from '../destinationConfigs/DestinationFormFactory';
import destroyMutation from './destroy.graphql';
import query from './query.graphql';
import updateMutation from './update.graphql';

export default function SegmentDestinationEditPage({
  destinationId,
  segmentId,
  segmentType,
}) {
  const [errors, setErrors] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDestroyComplete = (destination, segment) => {
    const path = paths.segmentDestinations(segment.type, segment.id);

    trackDestinationDeleted(destination, segment);

    ToastContainer.addSuccess('Destination removed');

    history.push(path);
  };

  const handleUpdate = (updateDestination, destination, destinationConfig) => {
    updateDestination({
      variables: {
        input: {
          id: destination.id,
          ...destinationConfig,
        },
      },
    });
  };

  const handleUpdateComplete = ({ response: { node, errors } }) => {
    if (errors.length) {
      ToastContainer.addError('There was a problem saving this destination.');
      setErrors(errors);
      return;
    }

    ToastContainer.addSuccess('Destination saved');

    const { segment } = node;

    const path = paths.segmentDestinations(segment.type, segment.id);
    history.push(path);
  };

  const attributeType = legacySegmentType(segmentType);

  const variables = {
    attributeType,
    destinationId,
    segmentId,
    segmentType,
  };

  const { data = {}, refetch, loading, error } = useQuery(query, {
    variables,
    skip: !destinationId,
  });

  const { segment = {} } = data;
  const { destination = {} } = segment;

  const accountConnectors = {
    facebook: data.facebookAccountConnector,
    google: data.googleAccountConnector,
    hubspot: data.hubspotAccountConnector,
    salesforce: data.salesforceAccountConnector,
    slack: data.slackAccountConnector,
  };

  const FormFooter = ({ destinationConfig, submitDisabled }) => {
    return (
      <CKDrawer.Footer className="justify-between">
        <CKButton
          isDisabled={loading}
          onClick={() => {
            setShowDeleteModal(true);
          }}
          variant="simple"
          variantColor="red"
        >
          Remove
        </CKButton>
        <div className="flex gap-3">
          <CKButton
            as={Link}
            to={paths.segmentDestinations(segment.type, segment.id)}
            variant="simple"
            variantColor="default"
          >
            Cancel
          </CKButton>
          <Mutation
            mutation={updateMutation}
            onCompleted={handleUpdateComplete}
            refetchQueries={['SegmentDestinationsPage']}
          >
            {(updateDestination, { loading }) => (
              <CKButton
                isDisabled={loading || submitDisabled}
                onClick={() => {
                  handleUpdate(
                    updateDestination,
                    destination,
                    destinationConfig,
                  );
                }}
                type="button"
                variant="bold"
                variantColor="green"
              >
                Save changes
              </CKButton>
            )}
          </Mutation>
        </div>
      </CKDrawer.Footer>
    );
  };

  return (
    <FormWrapper
      error={error}
      loading={loading || !destinationId}
      refetch={refetch}
    >
      {() => (
        <>
          <DestinationFormFactory
            accountConnectors={accountConnectors}
            attributes={data.attributes}
            destination={destination}
            destinationOptions={data.destinationOptions}
            errors={errors}
            FormFooter={FormFooter}
            refetch={refetch}
            segment={segment}
            type={destination.type}
          />
          <Mutation
            mutation={destroyMutation}
            onCompleted={() => {
              handleDestroyComplete(destination, segment);
            }}
            refetchQueries={['SegmentDestinationsPage']}
            variables={{
              input: {
                id: destination.id,
              },
            }}
          >
            {(mutation) => (
              <ConfirmDeleteModal
                canDelete
                closeModal={() => {
                  setShowDeleteModal(false);
                }}
                deleteItem={mutation}
                item={destination}
                onCompleted={() => setShowDeleteModal(false)}
                open={showDeleteModal}
                type="destination"
              />
            )}
          </Mutation>
        </>
      )}
    </FormWrapper>
  );
}

SegmentDestinationEditPage.propTypes = {
  destinationId: PropTypes.string,
};
