import { ErrorBanner, ErrorBannerProps } from '~/components/ErrorBanner';

const GraphqlError: React.FC<ErrorBannerProps> = ({
  refetch = window.location.reload,
  ...rest
}) => {
  return <ErrorBanner {...rest} refetch={refetch} />;
};

export default GraphqlError;
