import { CKContainerProps } from 'clearkit';
import { FC } from 'react';

export const HomeSidebar: FC<CKContainerProps> = ({ children, ...rest }) => {
  return (
    <aside
      {...rest}
      className="items-start min-h-0 p-8 bg-gray-100 border-t border-gray-200 divide-gray-200 space-y-8 divide-y ck-scrollbar ck-scrollbar--vertical"
    >
      {children}
    </aside>
  );
};
