import { useQuery } from '@apollo/client';
import { Enrichment, GoExternal } from '@clearkit/icons';
import { CKAvatar, CKButton, CKCompanyLogo, CKDrawer, CKTag } from 'clearkit';

import GraphqlError from '~/components/GraphqlError';
import LoadingArea from '~/components/LoadingArea';
import MissingPage from '~/components/MissingPage';
import { configurations } from '~/lib/configuration';
import { readableType, subTypes } from '~/lib/dataEnrichment';

import query from './query.graphql';
import resultsQuery from './resultsQuery.graphql';
import startQuery from './startQuery.graphql';
import statusQuery from './statusQuery.graphql';
import EnrichmentLogStep from './Step';

const getServices = (service) => {
  switch (service) {
    case configurations.salesforce:
      return [configurations.salesforce, configurations.salesforcesandbox];
    default:
      return [service];
  }
};

function EnrichmentLogImage({ className, enrichmentLog }) {
  switch (enrichmentLog.subType) {
    case subTypes.company:
    case subTypes.account:
      return (
        <CKCompanyLogo
          className={className}
          cornerRadius={50}
          domain={enrichmentLog.identifier}
          size={50}
        />
      );
    case subTypes.contact:
    case subTypes.lead:
      return (
        <CKAvatar
          className={className}
          name={enrichmentLog.name || enrichmentLog.identifier}
          size={50}
          src={enrichmentLog.image}
        />
      );
    default:
      return (
        <Enrichment
          className={`${className} fill-gradient-br-metal`}
          height={50}
          name={enrichmentLog.name || enrichmentLog.identifier}
          width={50}
        />
      );
  }
}

export default function EnrichmentLogsShow({ type, id }) {
  // Intervals in ms
  const POLLING_DELAY = 5000;
  const POLLING_INTERVAL = 2500;
  const POLLING_TIMEOUT = 60000;
  const SUCCEEDED = 'SUCCEEDED';

  const [enrichmentLog, setEnrichmentLog] = useState({});
  const [queryExecutionId, setQueryExecutionId] = useState(undefined);
  const [queryExecutionStatus, setQueryExecutionStatus] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [polling, setPolling] = useState(false);
  const [pollingError, setPollingError] = useState(undefined);
  let pollingTimerId = undefined;

  const {
    data: { accountConnector },
  } = useQuery(query, {
    fetchPolicy: 'cache-first',
    returnPartialData: true,
    variables: {
      service: getServices(type),
    },
  });

  const startResult = useQuery(startQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      type: type,
      id: id,
    },
  });

  const statusResult = useQuery(statusQuery, {
    fetchPolicy: 'no-cache',
    skip: !polling,
    notifyOnNetworkStatusChange: true,
    variables: {
      queryExecutionId: queryExecutionId,
    },
  });

  const logResult = useQuery(resultsQuery, {
    skip: queryExecutionStatus !== SUCCEEDED,
    variables: {
      queryExecutionId: queryExecutionId,
    },
    onCompleted: () => {
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (enrichmentLog && enrichmentLog.type) {
      setLoading(false);
    }
  }, [enrichmentLog]);

  useEffect(() => {
    if (startResult.data) {
      setQueryExecutionId(startResult.data.enrichmentLog.queryExecutionId);
    }
  }, [startResult.data]);

  useEffect(() => {
    if (statusResult.data) {
      setQueryExecutionStatus(statusResult.data.enrichmentLogsStatus.state);
    }
  }, [statusResult.data]);

  useEffect(() => {
    if (polling) {
      pollingTimerId = setTimeout(() => {
        setPolling(false);
        setPollingError(true);
      }, POLLING_TIMEOUT);

      return () => {
        clearTimeout(pollingTimerId);
      };
    } else {
      statusResult.stopPolling();
    }
  }, [polling]);

  useEffect(() => {
    if (queryExecutionStatus === SUCCEEDED) {
      setPolling(false);
      statusResult.stopPolling();
    }

    if (queryExecutionStatus === SUCCEEDED && pollingTimerId) {
      clearTimeout(pollingTimerId);
    }
  }, [queryExecutionStatus]);

  useEffect(() => {
    if (queryExecutionId && queryExecutionStatus === undefined) {
      const delayTimer = setTimeout(() => {
        setPolling(true);
      }, POLLING_DELAY);

      return () => {
        clearTimeout(delayTimer);
      };
    }
  }, [queryExecutionId]);

  useEffect(() => {
    if (polling && queryExecutionId && queryExecutionStatus === undefined) {
      statusResult.startPolling(POLLING_INTERVAL);
    }
  }, [polling]);

  useEffect(() => {
    if (logResult.data) {
      setEnrichmentLog(logResult.data.enrichmentLogResult);
    }
  }, [logResult]);

  const error =
    startResult.isError ||
    statusResult.isError ||
    logResult.isError ||
    pollingError;

  if (loading) {
    return (
      <div className="x-sidebar">
        <LoadingArea loading />
      </div>
    );
  }

  if (error || pollingError) {
    return (
      <div className="x-sidebar">
        <GraphqlError error={error} refetch={startResult.refetch} />;
      </div>
    );
  }

  if (!enrichmentLog) {
    return (
      <div className="x-sidebar">
        <MissingPage />
      </div>
    );
  }

  const enrichmentLogReadableType = readableType(enrichmentLog.type);
  const showTypeInfo = enrichmentLog.externalId != 'unknown';
  const sfdcRecordOverviewUrl = enrichmentLog.type.match(/salesforce/i)
    ? `${accountConnector.remoteMeta?.salesforceInstanceUrl}/${enrichmentLog.externalId}`
    : false;

  return (
    <div className="x-sidebar">
      <CKDrawer.Header>
        <div className="flex items-center flex-1 mb-4">
          <EnrichmentLogImage
            className="mr-4 flex-0"
            enrichmentLog={enrichmentLog}
          />

          <div className="flex flex-col flex-1">
            <CKDrawer.Heading>
              {enrichmentLog.name || enrichmentLog.identifier}
            </CKDrawer.Heading>

            {enrichmentLog.name && (
              <span className="text-base text-blue-500">
                {enrichmentLog.identifier}
              </span>
            )}
          </div>

          {sfdcRecordOverviewUrl && (
            <CKButton
              as="a"
              className="ml-2 shrink-0"
              href={sfdcRecordOverviewUrl}
              isDisabled={enrichmentLog.externalId === 'unknown'}
              rightIcon={<GoExternal />}
              target="_blank"
              variant="simple"
              variantColor="blue"
            >
              Go To {enrichmentLogReadableType} Record
            </CKButton>
          )}
        </div>

        <div className="flex items-center justify-between flex-1">
          <span className="text-sm text-gray-400">
            Clearbit Trace ID: {enrichmentLog.id}
          </span>

          {showTypeInfo && (
            <CKTag>
              {enrichmentLogReadableType} ID: {enrichmentLog.externalId}
            </CKTag>
          )}
        </div>
      </CKDrawer.Header>

      <CKDrawer.Body className="px-10 py-6">
        <ol className="space-y-3.5">
          {enrichmentLog.steps.map((enrichmentLogStep, index) => (
            <li key={index}>
              <EnrichmentLogStep enrichmentLogStep={enrichmentLogStep} />
            </li>
          ))}
        </ol>
      </CKDrawer.Body>
    </div>
  );
}
