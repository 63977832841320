import { CKBox, CKChartBar, CKLayoutHorizontal, clearkitTheme } from 'clearkit';

import { ChartEmptyOverlay } from '~/components/ChartEmptyOverlay';
import { DailyUsage } from '~/generated/graphql';

import { useEnrichmentRecentUsage } from './hooks/useEnrichmentRecentUsage';

const ChartTooltip = ({ config }: { config: any }) => {
  return (
    <div className="p-4 text-sm bg-white border border-gray-200 rounded-sm min-w-[40ch]">
      <header className="pb-2 font-medium border-b border-gray-100">
        Usage for{' '}
        {new Date(config.data.date).toLocaleDateString('en-us', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        })}
      </header>
      <CKLayoutHorizontal as="footer" className="pt-2 font-medium">
        <strong>Total credits used</strong>
        <span>{config.data.usage.toLocaleString('en-us')}</span>
      </CKLayoutHorizontal>
    </div>
  );
};

export const OverviewChart = () => {
  const { chartData, loading, maxValue } = useEnrichmentRecentUsage();
  const { colors } = clearkitTheme.theme.extend;

  const hasData = chartData && chartData?.length > 0;

  const fill = [{ match: '*', id: 'gradientX' }];

  const defs = [
    {
      id: 'gradientX',
      type: 'linearGradient',
      colors: [
        { offset: 0, color: colors.purple[500] },
        { offset: 100, color: colors.indigo[500] },
      ],
      gradientTransform: 'rotate(-45 0.5 0.5)',
    },
  ];

  return (
    <CKBox className="p-6 text-base text-gray-800 space-y-4 min-h-[20.5rem]">
      <h3 className="font-medium">Usage last 7 days</h3>
      {hasData && !loading ? (
        <CKChartBar
          axisLeft={{
            tickValues: 4,
          }}
          borderRadius={4}
          className="h-full"
          data={chartData}
          defs={defs}
          fill={fill}
          indexBy="date"
          keys={['usage']}
          margin={{
            top: 0,
            right: 0,
            bottom: 32,
            left: 48,
          }}
          maxValue={maxValue}
          minValue={0}
          padding={0.8}
          tooltipComponent={(config: DailyUsage) => (
            <ChartTooltip config={config} />
          )}
          xValueFormat={(value) => {
            const date = new Date((value as string) || '');
            return date.toLocaleString('en-us', {
              month: 'numeric',
              day: 'numeric',
            });
          }}
        />
      ) : (
        <ChartEmptyOverlay
          message="No data available"
          showEmptyState={!hasData}
        />
      )}{' '}
    </CKBox>
  );
};
