import classnames from 'classnames';
import {
  CKContainerProps,
  CKIconBox,
  CKLayoutHorizontal,
  CKSkeleton,
  includeChildrenByDisplayName,
} from 'clearkit';
import { FC } from 'react';

import { EMPTY_LABEL } from '~/lib/constants';

import { Count } from '../Exports/Count';

type CountProps = CKContainerProps & {
  count: number;
  isLoading?: boolean;
};

type UsageBlockComposition = {
  Count: FC<CountProps>;
  Icon: FC<CKContainerProps>;
  Label: FC<CKContainerProps>;
};

const countDisplayName = 'UsageBlock.Count';
const iconDisplayName = 'UsageBlock.Icon';
const labelDisplayName = 'UsageBlock.Label';

export const UsageBlock: FC<CKContainerProps> & UsageBlockComposition = ({
  children,
  ...rest
}) => {
  return (
    <CKLayoutHorizontal {...rest} justifyContent="start">
      {includeChildrenByDisplayName({
        children,
        componentDisplayName: [iconDisplayName],
      })}
      <div className="space-y-1">
        {includeChildrenByDisplayName({
          children,
          componentDisplayName: [labelDisplayName, countDisplayName],
        })}
      </div>
    </CKLayoutHorizontal>
  );
};

UsageBlock.Count = ({ count, className, isLoading = false, ...rest }) => {
  return (
    <CKSkeleton className="h-4 w-[10ch]" isLoading={isLoading}>
      {count ? (
        <Count
          {...rest}
          className={classnames('leading-1', className)}
          count={count}
        />
      ) : (
        <span>{EMPTY_LABEL}</span>
      )}
    </CKSkeleton>
  );
};
UsageBlock.Count.displayName = countDisplayName;

UsageBlock.Icon = ({ children, ...rest }) => {
  return <CKIconBox {...rest}>{children}</CKIconBox>;
};
UsageBlock.Icon.displayName = iconDisplayName;

UsageBlock.Label = ({ children, className, ...rest }) => {
  return (
    <div {...rest} className={classnames('text-sm font-medium', className)}>
      {children}
    </div>
  );
};
UsageBlock.Label.displayName = labelDisplayName;
