import MuiTooltip from '@material-ui/core/Tooltip';

import styles from './Tooltip.style.js';

class Tooltip extends React.Component {
  render() {
    const {
      hovered,
      classes,
      children,
      tooltipStyles,
      ...childrenProps
    } = this.props;
    const { question, hidden, container, ...tooltipClasses } = classes;

    const tooltipTargetClass = hovered ? question : hidden;

    return (
      <div className={container}>
        {children}
        <MuiTooltip
          classes={tooltipClasses}
          {...childrenProps}
          style={{
            ...tooltipStyles,
          }}
        >
          <div className={tooltipTargetClass}>?</div>
        </MuiTooltip>
      </div>
    );
  }
}

Tooltip.propTypes = {
  placement: PropTypes.string,
  title: PropTypes.string,
  tooltipStyles: PropTypes.object,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(Tooltip);
