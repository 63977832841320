import { useQuery } from 'react-query';

import { AuthErrorResponse } from '~/lib/client/auth';

import { DiscoveredAccount } from './types';

export default function useDiscovery() {
  return useQuery(
    'discovery',
    async function(): Promise<AuthenticationResponseData> {
      const response = await fetch(`/auth/stytch/discovery`, {
        headers: { Accept: 'application/json' },
      });
      const data:
        | AuthenticationResponseData
        | { error: AuthErrorResponse } = await response.json();

      if ('error' in data) {
        return {
          action: 'unauthorized',
          type: 'intermediate_session_not_found',
        };
      }

      return data;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
}

export type DiscoverResponse = {
  action: 'discover';
  discovered_accounts: Array<DiscoveredAccount>;
};

export type UnauthorizedResponse = {
  action: 'unauthorized';
  type: string;
};

export type AuthenticationResponseData =
  | DiscoverResponse
  | UnauthorizedResponse;
