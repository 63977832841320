import { CKCompanyLogo, CKDrawer, CKLayoutHorizontal } from 'clearkit';
import some from 'lodash/some';
import { Helmet } from 'react-helmet';

import AttributeBrowser from '~/components/AttributeBrowser';
import GraphqlError from '~/components/GraphqlError';
import wrapError from '~/components/JavascriptError/JavascriptErrorHandler';
import LoadingArea from '~/components/LoadingArea';
import MissingPage from '~/components/MissingPage';
import { useFancyQuery } from '~/lib/graphql';
import { paginateData } from '~/lib/graphql/pagination';

import CompanyDetails from './Details';
import query from './query.graphql';

type CompanyDrawerDetailsProps = {
  id: string;
  hideProspectorPreview?: boolean;
};

const CompanyDrawerDetails = ({
  id,
  hideProspectorPreview,
}: CompanyDrawerDetailsProps) => {
  const {
    data,
    data: { company, salesforceAccountConnector, hubspotAccountConnector } = {},
    error,
    loading,
    refetch,
  } = useFancyQuery(query, {
    // @ts-ignore
    variables: { id },
    returnPartialData: true,
  });

  if (error) {
    return (
      <div className="x-sidebar">
        <GraphqlError error={error} refetch={refetch} />
      </div>
    );
  }

  if (!loading && !data.company) return <MissingPage className="x-sidebar" />;

  if ((loading && !data) || !data.company)
    return <LoadingArea className="x-sidebar" loading />;

  const { salesforceAccounts, hubspotCompanies } = paginateData(company, {
    fields: ['salesforceAccounts', 'hubspotCompanies'],
  });

  return (
    <div className="x-sidebar">
      <Helmet title={company.name} />
      <CKDrawer.Header>
        <CKLayoutHorizontal justifyContent="start">
          <CKCompanyLogo
            className="shadow-sm"
            cornerRadius={8}
            domain={company.domain}
            size={56}
          />

          <div>
            <CKDrawer.Heading>
              {company.name || company.domain || 'Loading...'}
            </CKDrawer.Heading>
            <a
              className="text-blue"
              href={`http://${company.domain}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {company.domain || '...'}
            </a>
          </div>
        </CKLayoutHorizontal>
      </CKDrawer.Header>
      <GraphqlError error={error} inline refetch={refetch} />

      <div className="ck-scrollbar ck-scrollbar--vertical">
        <CompanyDetails
          company={company}
          hideProspectorPreview={hideProspectorPreview}
          hubspotCompanies={hubspotCompanies}
          hubspotOwners={hubspotAccountConnector?.owners}
          salesforceAccountConnector={salesforceAccountConnector}
          salesforceAccounts={salesforceAccounts}
        />

        <LoadingArea loading={!some([company.traits, company.enrichment])}>
          <AttributeBrowser
            enrichment={company.enrichment}
            hubspotCompanies={hubspotCompanies}
            hubspotOwners={hubspotAccountConnector?.owners}
            object={company}
            salesforceAccountConnector={salesforceAccountConnector}
            salesforceAccounts={salesforceAccounts}
            traits={company.traits}
          />
        </LoadingArea>
      </div>
    </div>
  );
};

export default wrapError(CompanyDrawerDetails);
