import { AddBlock, FitCompany } from '@clearkit/icons';
import { CKBox, CKButton, CKIconBox } from 'clearkit';
import { Link } from 'react-router-dom';

import { trackEvent } from '~/lib/tracking';

import { NEW_TARGET_MARKET_LIST } from '../constants';

export const EmptyList = ({ isVisible }: { isVisible: boolean }) => {
  return isVisible ? (
    <div className="absolute inset-0 z-40 flex items-center justify-center p-4 bg-gray-300 bg-opacity-25 backdrop-blur-sm">
      <CKBox className="items-center max-w-sm p-8 text-center gap-4">
        <CKIconBox>
          <FitCompany className="fill-gradient-br-x" />
        </CKIconBox>
        <header>
          <h3 className="mb-2 font-semibold">Target Markets</h3>

          <p>
            Create a new list or select an existing one to see matching
            companies.
          </p>
        </header>

        <CKButton
          as={Link}
          className="mt-2"
          leftIcon={<AddBlock />}
          onClick={() => trackEvent('target_market_new_list_clicked')}
          to={NEW_TARGET_MARKET_LIST}
          variant="simple"
          variantColor="blue"
        >
          New list
        </CKButton>
      </CKBox>
    </div>
  ) : null;
};
