import classnames from 'classnames';
import {
  CKActionMenu,
  CKBadge,
  CKContainerProps,
  CKLayoutHorizontal,
  CKPopover,
} from 'clearkit';
import { ReactNode } from 'react';

import { useCreditUsage } from '~/hooks/account/useCreditUsage';
import {
  formatCostPerMonth,
  getCostPerMonth,
} from '~/lib/selfServePlanHelpers';

export type CreditOption = {
  credits: number;
  cost: number;
};

type CreditSelectorProps = Omit<CKContainerProps, 'onChange'> & {
  currentCredits: number;
  isDisabled?: boolean;
  onChange?: (value: number) => void;
  options: CreditOption[];
  value: number;
};

export const CreditSelector: React.FC<CreditSelectorProps> = ({
  className,
  currentCredits,
  isDisabled,
  options,
  value,
  onChange,
  ...rest
}) => {
  const RECOMMENDED_CREDITS = 750;
  const { isHubspotAccount } = useCreditUsage();

  const handleChange = (index: number) => {
    onChange?.(options[index].credits);
  };

  const OptionText = ({
    children,
    credits,
  }: {
    children?: ReactNode;
    credits: number;
  }) => {
    const costPerMonth = getCostPerMonth({ credits, isHubspotAccount });

    return (
      <CKLayoutHorizontal
        alignItems="start"
        as="span"
        className="pr-4 leading-snug text-left grow"
      >
        <span>
          <span className="block font-semibold">
            {credits?.toLocaleString()} monthly credits
          </span>
          <span className="!font-normal">
            {formatCostPerMonth(costPerMonth)}
          </span>
        </span>
        {children}
      </CKLayoutHorizontal>
    );
  };

  const SelectedOptionBadge = ({ credits }: { credits: number }) => {
    if (credits === currentCredits) {
      return (
        <CKBadge
          className={classnames({
            'bg-gray-400': isDisabled,
          })}
        >
          Current Tier
        </CKBadge>
      );
    }

    if (credits === RECOMMENDED_CREDITS) {
      return (
        <CKBadge className="bg-gradient-to-br gradient-green">
          Recommended
        </CKBadge>
      );
    }

    return null;
  };

  const Menu = () => (
    <CKActionMenu className="space-y-1">
      {options.map((option, index) => (
        <CKActionMenu.Item
          className={classnames({
            'font-medium': option.credits === value,
          })}
          isDisabled={isDisabled}
          key={option.credits}
          onClick={() => handleChange(index)}
        >
          <OptionText credits={option.credits}>
            <SelectedOptionBadge credits={option.credits} />
          </OptionText>
        </CKActionMenu.Item>
      ))}
    </CKActionMenu>
  );

  return (
    <div {...rest} className={className}>
      <CKPopover
        isDisabled={isDisabled}
        isFullWidth
        maxHeight="20.5rem"
        popover={<Menu />}
      >
        <CKPopover.Trigger className="p-4 !h-auto !w-full">
          <OptionText credits={value}>
            <SelectedOptionBadge credits={value} />
          </OptionText>
        </CKPopover.Trigger>
      </CKPopover>
    </div>
  );
};
