import { useQuery } from 'react-query';

export default function useUpgradeAuthFactors(accountId: string) {
  return useQuery(
    'upgradeAuthFactors',
    async function() {
      const authMethodsResponse = await fetch(
        `/auth/auth_methods/${accountId}`,
      );
      const { auth_methods: authMethods } = await authMethodsResponse.json();

      if (authMethods.magic_link?.enabled) {
        const magicLinkResponse = await fetch(
          `/auth/stytch/magic_link/${accountId}`,
          {
            method: 'POST',
          },
        );

        if (magicLinkResponse.ok) {
          return {
            type: 'magic_link',
          };
        }
        return Promise.reject(new Error("Couldn't send magic link"));
      } else if (authMethods.sso?.enabled) {
        setTimeout(() => (window.location.href = authMethods.sso.start_url));
        return {
          type: 'sso',
        };
      } else if (authMethods.hubspot_oauth?.enabled) {
        return {
          type: 'hubspot',
          startUrl: authMethods.hubspot_oauth.start_url,
        };
      } else {
        return Promise.reject(
          new Error(
            `No supported auth methods. Account ${accountId} requires one of ${Object.keys(
              authMethods,
            ).join(', ')}`,
          ),
        );
      }
    },
    {
      refetchOnWindowFocus: false,
    },
  );
}
