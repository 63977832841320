import isArray from 'lodash/isArray';
import map from 'lodash/map';

// There are various ways to pass values through to these selects.
// These come from various different types of traits:
// 1. [['a', 'b'], ['a','b']]
// 2. ['a','b']
// 3. {a: 'b', b: 'c'}

export const getOptionValue = (option) => {
  if (!option) return undefined;
  if (typeof option === 'string') return option;
  if (typeof option === 'number') return option;
  if (isArray(option)) return option[0];

  if (option.attribute) {
    return { attribute: option.attribute, value: option.value };
  }

  if (option.value) return option.value;
  return Object.keys(option)[0];
};

export const getOptionLabel = (option) => {
  if (!option) return undefined;
  if (typeof option === 'string') return option;
  if (typeof option === 'number') return option;
  if (isArray(option)) return option[1];
  if (option.label) return option.label;
  return Object.values(option)[0];
};

export const transformOptions = (options, getOptionValue, getOptionLabel) => {
  if (!isArray(options)) {
    return map(options, (label, value) => ({ value, label }));
  }

  const recurse = (options) => {
    return options.map((option) => ({
      value: getOptionValue(option),
      label: getOptionLabel(option),
      options: option.options ? recurse(option.options) : [],
    }));
  };

  return recurse(options);
};
