import { upperFirst } from 'lodash';
import get from 'lodash/get';
import map from 'lodash/map';
import reduce from 'lodash/reduce';

/**
 * Moves GraphQL Relay concepts out of our domain logic. Flattens edges, nodes,
 * page info and counts as simple structures.
 *
 * const query = gql`
 *   query AudiencesQuery {
 *     account {
 *       id
 *     }
 *     audiences {
 *       edges {
 *         node {
 *           id
 *         }
 *       }
 *       pageInfo {
 *        endCursor
 *        hasNextPage
 *       }
 *       totalCount
 *     }
 *   }
 * `;
 *
 * const { data } = useQuery(query);
 *
 * const {
 *   account,
 *   audiences,
 *   audiencesEndCursor,
 *   audiencesHasNextPage,
 *   audiencesTotalCount,
 * } = paginateData(data, { fields: ['audiences'] });
 */

export function paginateData(data, { fields = [] }) {
  if (data === undefined) {
    return;
  }

  const reducedData = reduce(
    fields,
    (res, field) => {
      const dataField = get(data, field, { edges: [] });
      const extraDataFields = {};

      Object.keys(dataField)
        .filter((key) => !['edges', 'pageInfo'].includes(key))
        .forEach((key) => {
          extraDataFields[`${field}${upperFirst(key)}`] = dataField[key];
        });

      return {
        ...res,
        ...extraDataFields,
        [`${field}`]: map(dataField.edges, ({ node }) => node),
        [`${field}EndCursor`]: dataField.pageInfo?.endCursor,
        [`${field}HasNextPage`]: dataField.pageInfo?.hasNextPage,
      };
    },
    {},
  );

  return {
    ...data,
    ...reducedData,
  };
}

export function paginateField(object, field) {
  if (object[field] === null) return [];

  return paginateData(object, { fields: [field] })[field];
}
