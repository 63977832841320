import { Mutation } from '@apollo/client/react/components';
import {
  CloseOrb as ClearIcon,
  Ellipsis as MoreIcon,
  Error as ErrorIcon,
  Folder as FolderIcon,
  FolderOpen as OpenFolderIcon,
} from '@clearkit/icons';
import { CKField, CKFieldLeftElement, CKFieldRightElement } from 'clearkit';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';

import ConfirmDeleteModal from '~/components/ConfirmDeleteModal';
import SegmentActionsMenu from '~/components/SegmentActionsMenu';
import ToastsContainer from '~/containers/Toasts';
import history from '~/lib/history';
import paths from '~/lib/paths';
import {
  segmentActionLocations,
  trackSegmentDeleted,
} from '~/lib/segmentAnalytics';

import SegmentsMenuItem from '../SegmentsMenuItem';
import CollectionName from './CollectionName';
import destroyCollectionMutation from './destroyCollection.graphql';
import MoreMenu from './MoreMenu';
import SegmentListIcon from './SegmentListIcon';
import styles from './styles.style';

class Collection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: !this.getClosedState(),
      renaming: false,
      menuOpen: false,
      showDeleteModal: false,
    };
  }

  componentDidUpdate = (previousProps, previousState) => {
    if (previousState.selected !== this.state.selected) {
      this.setInitialClosedState(!this.state.selected);
    }
  };

  moreIcon = React.createRef();
  renameInput = React.createRef();

  segmentSelected = () => {
    const { segments, segmentId, addingSegment } = this.props;
    return segments.map((s) => s.id).indexOf(segmentId) > -1 || addingSegment;
  };

  handleDeleteCollectionError = () => {
    ToastsContainer.addError('There was an error deleting this collection');
  };

  handleDeleteCollectionCompleted = () => {
    const { segments = [] } = this.props;

    ToastsContainer.addSuccess('Collection deleted');

    segments.forEach((segment) => {
      trackSegmentDeleted(segment, segmentActionLocations.collectionsMenu);
    });
  };

  closedStateKey = () => `${this.props.collection.id}:closed`;

  getClosedState = () => !!window.localStorage.getItem(this.closedStateKey());

  setInitialClosedState = (isClosed) => {
    if (isClosed) {
      window.localStorage.setItem(this.closedStateKey(), true);
    } else {
      window.localStorage.removeItem(this.closedStateKey());
    }
  };

  select = (event) => {
    event.preventDefault();
    this.setState((prevState) => {
      return { selected: !prevState.selected };
    });
  };

  renderSegment = (segment, index) => {
    const { classes, segmentId, highlightedIndex, type, search } = this.props;

    const selected = segment.id === segmentId;
    const highlighted = index === highlightedIndex;
    const searchWords = search ? search.toString().split(' ') : [];

    const content = (
      <>
        <span
          className={classnames('flex items-center overflow-hidden w-full', {
            'text-gray-600': !selected,
            'text-blue-500': selected,
          })}
        >
          <SegmentListIcon
            className={classes.segmentIcon}
            segment={segment}
            selected={selected}
          />
          <span className={classnames(classes.segmentLinkLabel, 'flex-grow')}>
            <Highlighter
              autoEscape
              highlightTag={({ children }) => (
                <strong className={classes.highlightedTerm}>{children}</strong>
              )}
              searchWords={searchWords}
              textToHighlight={segment.name}
            />
          </span>
        </span>
        {segment.searchDependencyErrors && (
          <div className={classes.segmentDisabledNotice}>
            <ErrorIcon />
          </div>
        )}
        <SegmentActionsMenu
          buttonComponent={
            <span
              className={classnames(
                classes.segmentMoreMenu,
                'group-hover:opacity-50 text-gray-600',
                {
                  'text-blue-500': selected,
                  selected,
                  highlighted,
                },
              )}
            >
              <MoreIcon />
            </span>
          }
          location={segmentActionLocations.collectionsMenu}
          onClone={(clone) => {
            ToastsContainer.addSuccess('Audience cloned');
            history.push(paths.segmentEdit(clone.type, clone.id));
          }}
          onDelete={
            selected
              ? () => history.replace(paths.segments(segment.type))
              : null
          }
          segment={segment}
          type={type}
        />
      </>
    );

    return (
      <SegmentsMenuItem
        as={Link}
        className={classnames(
          'pl-6 group',
          classes.ActionMenuItemMargin,
          classes.iconHover,
        )}
        focused={highlighted}
        key={index}
        selected={selected}
        to={paths.segment(segment.type, segment.id)}
      >
        {content}
      </SegmentsMenuItem>
    );
  };

  renderTitle = () => {
    const { classes, collection, type } = this.props;
    const { selected } = this.state;
    const segmentSelected = this.segmentSelected();

    const className = classnames(classes.collectionLink, {
      'text-gray-600': true,
    });

    return (
      <>
        <a className={className} href="#none" onClick={this.select}>
          {selected || segmentSelected ? (
            <OpenFolderIcon className={classes.collectionIcon} />
          ) : (
            <FolderIcon className={classes.collectionIcon} />
          )}
          <span className={classes.collectionLinkLabel}>{collection.name}</span>
        </a>
        <MoreMenu
          className={classnames(classes.collectionMoreMenu, {
            'text-gray-600': true,
          })}
          collection={collection}
          onDelete={() =>
            this.setState({ showMenu: false, showDeleteModal: true })
          }
          onRename={() => this.setState({ renaming: true })}
          type={type}
        />
      </>
    );
  };

  renderRenameInput = () => {
    const { classes, collection, type } = this.props;
    const { selected } = this.state;
    const segmentSelected = this.segmentSelected();
    const iconClassName = classnames(classes.renameCollectionIcon);

    return (
      <CKField className={classnames(classes.renameCollectionInput)}>
        <CollectionName
          collection={collection}
          onBlur={() => {
            this.setState({ renaming: false });
          }}
          type={type}
        />
        <CKFieldLeftElement>
          {selected || segmentSelected ? (
            <OpenFolderIcon className={iconClassName} />
          ) : (
            <FolderIcon className={iconClassName} />
          )}
        </CKFieldLeftElement>
        <CKFieldRightElement isInteractive>
          <ClearIcon
            className={classnames(
              'cursor-pointer opacity-40 hover:opacity-70 transition ease-linear duration-200',
            )}
            height="16"
            onClick={() => {
              this.setState({ renaming: false });
            }}
            width="16"
          />
        </CKFieldRightElement>
      </CKField>
    );
  };

  render() {
    const { selected, renaming, showDeleteModal } = this.state;
    const {
      search,
      classes,
      highlightedIndex,
      segments,
      collection,
      canDelete,
      type,
    } = this.props;

    const segmentIsHighlighted = highlightedIndex > -1;
    const segmentSelected = this.segmentSelected();

    const showSegments =
      selected || segmentIsHighlighted || !!search || segmentSelected;

    return (
      <div
        className={classnames(classes.collection, {
          [classes.openCollection]: selected,
        })}
      >
        <div className={classes.collectionLinkContainer}>
          {renaming ? this.renderRenameInput() : this.renderTitle()}
        </div>
        {showDeleteModal && (
          <Mutation
            mutation={destroyCollectionMutation}
            onCompleted={this.handleDeleteCollectionCompleted}
            onError={this.handleDeleteCollectionError}
            refetchQueries={['SegmentsMenu', 'SegmentActivity']}
            variables={{
              input: {
                type,
                id: collection.id,
              },
            }}
          >
            {(mutation) => (
              <ConfirmDeleteModal
                associatedItemNames={segments.map((segment) => segment.name)}
                canDelete={canDelete}
                closeModal={() => {
                  this.setState({
                    showDeleteModal: false,
                    menuOpen: false,
                  });
                }}
                deleteItem={mutation}
                item={collection}
                onCompleted={() => this.setState({ menuOpen: false })}
                open
                type="collection"
              />
            )}
          </Mutation>
        )}
        {showSegments && (
          <div className={classes.collectionSegments}>
            {segments.length > 0 ? (
              segments.map((segment, i) => this.renderSegment(segment, i))
            ) : (
              <div className={classes.noSegments}>No audiences</div>
            )}
          </div>
        )}
      </div>
    );
  }
}

Collection.propTypes = {
  segments: PropTypes.array.isRequired,
  segmentId: PropTypes.string,
  collections: PropTypes.array,
  collection: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    segments: PropTypes.array,
    __typename: PropTypes.string.isRequired,
  }),
  classes: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['Company', 'Person']).isRequired,
  search: PropTypes.string.isRequired,
  highlightedIndex: PropTypes.number.isRequired,
  canDelete: PropTypes.bool.isRequired,
  addingSegment: PropTypes.bool,
};

export default withStyles(styles)(Collection);
