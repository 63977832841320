import { CKToastContainer } from 'clearkit';
import { Subscribe } from 'unstated';

import ToastsContainer from '~/containers/Toasts';

const ToastNotificationStack = ({ toasts, removeToast }) => (
  <CKToastContainer
    className="z-[10000]"
    onClose={removeToast}
    toasts={toasts}
  />
);

ToastNotificationStack.propTypes = {
  removeToast: PropTypes.func,
  toasts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      description: PropTypes.string,
      heading: PropTypes.string.isRequired,
      variant: PropTypes.oneOf([
        'default',
        'warning',
        'success',
        'error',
        'special',
        'info',
      ]).isRequired,
    }),
  ),
};

const WrappedToastNotificationStack = (props) => (
  <Subscribe to={[ToastsContainer]}>
    {(toastsContainer) => (
      <ToastNotificationStack
        {...props}
        removeToast={toastsContainer.removeToast}
        toasts={toastsContainer.state.toasts}
      />
    )}
  </Subscribe>
);

export default WrappedToastNotificationStack;
