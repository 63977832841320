import { CKDrawer } from 'clearkit';

import GraphqlError from '~/components/GraphqlError';
import LoadingArea from '~/components/LoadingArea';

export default function FormWrapper({ children, loading, error, refetch }) {
  return (
    <form className="flex flex-col h-full overflow-hidden">
      {loading ? (
        <CKDrawer.Body>
          <LoadingArea loading />
        </CKDrawer.Body>
      ) : error ? (
        <CKDrawer.Body>
          <GraphqlError error={error} loading={loading} refetch={refetch} />
        </CKDrawer.Body>
      ) : children ? (
        children()
      ) : null}
    </form>
  );
}

FormWrapper.propTypes = {
  children: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.object,
  refetch: PropTypes.func,
};
