import Cookies, { CookieAttributes } from 'js-cookie';
import { useState } from 'react';

export const useCookie = (
  key: string,
  cookieOptions?: CookieAttributes,
): [string | undefined, (newValue: string | undefined) => void] => {
  const [value, setValueInternal] = useState<string | undefined>(
    Cookies.get(key),
  );
  const setCookie = (key: string, value: string) => {
    setValueInternal(value);
    Cookies.set(key, value, cookieOptions);
  };
  const removeCookie = (key: string) => {
    setValueInternal(undefined);
    Cookies.remove(key);
  };

  function setValue(newValue: string | undefined): void {
    if (newValue) {
      setCookie(key, newValue);
    } else {
      removeCookie(key);
    }
  }

  // TODO: Add a useEffect to listen to cookie change events and update the state accordingly

  return [value, setValue];
};
