import {
  Facebook,
  Github,
  Go as GoIcon,
  Linkedin,
  Twitter,
} from '@clearkit/icons';
import { CKCompanyLogo } from 'clearkit';
import startCase from 'lodash/startCase';
import { Link } from 'react-router-dom';

import { FitTag } from '~/components/FitScore/FitTag/Tag.tsx';
import { WhenFitScore } from '~/components/FitScore/WhenFitScore.tsx';
import paths from '~/lib/paths';
const truncateLength = 120;

const PersonDetails = ({ person }) => {
  const company = person.company || person.corporateCompany;

  const [showingDescription, setShowingDescription] = useState(
    company?.description?.length < truncateLength,
  );

  return (
    <div className="relative flex flex-col justify-between flex-shrink-0 w-full text-sm border-b border-gray-200">
      <div className="flex flex-row px-10 space-y-4">
        <div className="flex-grow py-6 text-base">
          <div className="pb-6 mb-6 border-b border-gray-200">
            <div className="flex flex-col flex-shrink-0 pr-4 mb-4">
              <span className="mb-1 text-xs text-gray-500">Name</span>
              <span className="flex flex-row font-medium text-gray-900">
                {person.name}
                <span className="flex flex-row ml-auto space-x-3">
                  {person.twitter && (
                    <a
                      className="text-twitter"
                      href={`https://twitter.com/${person.twitter}`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Twitter height={18} width={18} />
                    </a>
                  )}
                  {person.facebook && (
                    <a
                      className="text-facebook"
                      href={`https://facebook.com/${person.facebook}`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Facebook height={18} width={18} />
                    </a>
                  )}
                  {person.linkedin && (
                    <a
                      className="text-linkedin"
                      href={`https://linkedin.com/${person.linkedin}`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Linkedin height={18} width={18} />
                    </a>
                  )}
                  {person.github && (
                    <a
                      className="text-github"
                      href={`https://github.com/${person.github}`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Github height={18} width={18} />
                    </a>
                  )}
                </span>
              </span>
            </div>

            <div className="flex flex-col flex-shrink-0 pr-8 mb-4">
              <span className="mb-1 text-xs text-gray-500">Employment</span>
              <span className="font-medium text-gray-900">
                {person.employment || <>&mdash;</>}
              </span>
            </div>

            <div className="flex flex-col flex-shrink-0 pr-8">
              <span className="mb-1 text-xs text-gray-500">Email</span>
              {person.email ? (
                <a
                  className="text-blue"
                  href={`mailto://${person.email}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {person.email}
                </a>
              ) : (
                <span>&mdash;</span>
              )}
            </div>
          </div>

          <div>
            <div className="flex flex-row mt-4">
              <div className="flex flex-col pr-4 min-w-24">
                <span className="mb-1 text-xs text-gray-500">Role</span>
                <span>{startCase(person.role) || <>&mdash;</>}</span>
              </div>
              <div className="flex flex-col pr-4 min-w-24">
                <span className="mb-1 text-xs text-gray-500">Seniority</span>
                <span>{startCase(person.seniority) || <>&mdash;</>}</span>
              </div>
              <div className="flex flex-col pr-4 min-w-24">
                <span className="mb-1 text-xs text-gray-500">Location</span>
                <span>{person.location || <>&mdash;</>}</span>
              </div>
            </div>
          </div>
        </div>

        {company && (
          <div className="z-10 flex-shrink-0 w-1/2 pl-8">
            <div className="p-4 mt-4 -mb-12 ck-box ck-box--card">
              <div
                className={classnames(
                  'flex flex-row items-center pb-4 pl-2 relative',
                  {
                    'border-b border-gray-200':
                      company.employeesRange || company.estimatedAnnualRevenue,
                  },
                )}
              >
                <div className="flex w-full">
                  <Link className="mr-4" to={paths.company(company.id)}>
                    <CKCompanyLogo
                      className="shadow-sm"
                      cornerRadius={8}
                      domain={company.domain}
                      size={56}
                    />
                  </Link>

                  <div className="flex items-start w-full text-sm leading-snug group">
                    <div className="flex flex-col flex-1">
                      <span className="text-lg font-medium text-gray-900">
                        <Link
                          className=" text-gray-900 space-x-1 hover:text-blue"
                          to={paths.company(company.id)}
                        >
                          {company.name}
                          <GoIcon
                            className="relative inline-block ml-1 text-gray-500 -top-px"
                            height={16}
                            width={16}
                          />
                        </Link>
                      </span>
                      <a
                        href={`https://${company.domain}`}
                        rel="noopener noreferrer"
                        target="blank"
                      >
                        {company.domain}
                      </a>
                      {company.sector && <span>{company.sector}</span>}
                    </div>
                    <WhenFitScore>
                      <FitTag score={company.fitScore} />
                    </WhenFitScore>
                  </div>
                </div>
              </div>

              {(company.employeesRange || company.estimatedAnnualRevenue) && (
                <div
                  className={classnames(
                    'flex flex-shrink-0 px-2 py-4 text-sm hide-if-empty',
                    {
                      'border-b border-gray-200': !!company.description,
                    },
                  )}
                >
                  <div className="flex flex-col flex-shrink-0 mr-8">
                    <span className="mb-1 text-xs text-gray-500">
                      Employees
                    </span>
                    <span>{company.employeesRange || <>&mdash;</>}</span>
                  </div>
                  <div className="flex flex-col flex-shrink-0">
                    <span className="mb-1 text-xs text-gray-500 truncate">
                      Est. Annual Revenue
                    </span>
                    <span>
                      {company.estimatedAnnualRevenue || <>&mdash;</>}
                    </span>
                  </div>
                </div>
              )}

              {company.description && (
                <div className="px-2 pt-4">
                  <p className="leading-snug">
                    {showingDescription
                      ? company?.description
                      : company?.description?.substring(0, truncateLength) +
                        '…'}
                  </p>

                  {!showingDescription && (
                    <p>
                      <a
                        className="mt-2 text-xs text-blue-400"
                        href="#none"
                        onClick={() =>
                          setShowingDescription(!showingDescription)
                        }
                      >
                        Show full description
                      </a>
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="w-full">
        <div className="px-10 py-4 border-t border-gray-200 bg-gray-0">
          <div className="w-1/2">
            <div className="flex flex-col flex-shrink-0 py-2 pr-8">
              <span className="mb-1 text-xs text-gray-500">Bio</span>
              <span>{person.bio || <>&mdash;</>}</span>
            </div>

            {company && (
              <div className="flex flex-col flex-shrink-0 py-2 pr-8">
                <span className="mb-1 text-xs text-gray-500">Website</span>
                <a
                  href={`https://${company.domain}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {company.domain || <>&mdash;</>}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

PersonDetails.propTypes = {
  person: PropTypes.shape({
    twitter: PropTypes.string,
    facebook: PropTypes.string,
    github: PropTypes.string,
    linkedin: PropTypes.string,
    bio: PropTypes.string,
    company: PropTypes.shape({
      name: PropTypes.string,
      domain: PropTypes.string,
      sector: PropTypes.string,
      employeesRange: PropTypes.string,
      estimatedAnnualRevenue: PropTypes.string,
      description: PropTypes.string,
    }),
    corporateCompany: PropTypes.shape({
      name: PropTypes.string,
      domain: PropTypes.string,
      sector: PropTypes.string,
      employeesRange: PropTypes.string,
      estimatedAnnualRevenue: PropTypes.string,
      description: PropTypes.string,
    }),
    email: PropTypes.string,
    employment: PropTypes.string,
    location: PropTypes.string,
    name: PropTypes.string,
    role: PropTypes.string,
    seniority: PropTypes.string,
  }),
};

export default PersonDetails;
