import { useQuery } from '@apollo/client/react/hooks';

import { configurations } from '~/lib/configuration';

import query from './query.graphql';

export const useAccountConnector = (integration: string) => {
  const normalizeIntegrationForAccountConnectorQuery = () => {
    switch (integration) {
      case configurations.salesforce:
      case configurations.pardot:
        return [configurations.salesforce, configurations.salesforcesandbox];
      default:
        return [integration];
    }
  };

  const { data: { accountConnector = null } = {}, loading, refetch } = useQuery(
    query,
    {
      variables: {
        service: normalizeIntegrationForAccountConnectorQuery(),
      },
    },
  );

  function accountConnectorIsComplete() {
    if (!accountConnector) {
      return false;
    }

    if (integration === configurations.pardot) {
      return !!accountConnector.pardotBusinessUnitId;
    }

    return true;
  }

  const isAccountConnectorComplete = accountConnectorIsComplete();

  return {
    accountConnector,
    isAccountConnectorComplete,
    loading,
    refetch,
  };
};
