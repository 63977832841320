import attributeTypes from './attributeTypes';

export const LEGACY_STRING_COMPARATORS = {
  match: 'is equal to',
  not_match: 'is not equal to',
  exists: 'is not blank',
  not_exists: 'is blank',
  contains: 'contains',
  not_contains: 'does not contain',
};

export const KEYWORD_STRING_COMPARATORS = {
  contains_all: 'include all of',
  contains_any: 'include any of',
  not_contains_all: 'do not include',
};

export const SINGLE_MULTI_SELECT_COMPARATORS = {
  match_any: 'is any of',
  not_match_any: 'is none of',
  exists: 'is not blank',
  not_exists: 'is blank',
};

export const MULTI_SELECT_COMPARATORS = {
  array_contains_all: 'contains all of',
  array_contains: 'contains any of',
  array_not_contains: 'contains none of',
  exists: 'is not blank',
  not_exists: 'is blank',
};

export const ARRAY_MULTI_SELECT_COMPARATORS = {
  array_contains_all: 'contains all of',
  array_contains: 'contains any of',
  array_not_contains: 'contains none of',
  exists: 'is not blank',
  not_exists: 'is blank',
};

export const STRING_COMPARATORS = {
  match_any: 'equals any of',
  not_match_any: 'is not equal to any of',
  contains_all: 'contains all of',
  not_contains_all: 'contains none of',
  contains_any: 'contains any of',
  exists: 'is not blank',
  not_exists: 'is blank',
};

export const NUMBER_COMPARATORS = {
  match: 'is equal to',
  not_match: 'is not equal to',
  gt: 'is greater than',
  gte: 'is greater than or equal to',
  lt: 'is less than',
  lte: 'is less than or equal to',
  exists: 'is not blank',
  not_exists: 'is blank',
};

export const DATE_COMPARATORS = {
  date_match: 'is equal to',
  date_not_match: 'is not equal to',
  date_lt: 'is before',
  date_gt: 'is after',
  exists: 'is not blank',
  not_exists: 'is blank',
  date_between: 'is between',
  date_not_between: 'is not between',
  date_in_last: 'is within the previous...',
  date_in_next: 'is within the next...',
};

export const EXISTENCE_BOOLEAN_COMPARATORS = {
  exists: 'true',
  not_exists: 'false',
};

export const BOOLEAN_COMPARATORS = {
  match: 'is equal to',
  not_match: 'is not equal to',
  exists: 'is not blank',
  not_exists: 'is blank',
};

export const SELECT_COMPARATORS = {
  match: 'is equal to',
  not_match: 'is not equal to',
  exists: 'is not blank',
  not_exists: 'is blank',
};

export const PAGEVIEW_COMPARATORS = {
  match: 'matching',
  start_with: 'starts with',
  all: 'all',
};

export const allOperators = () =>
  Object.keys({
    ...LEGACY_STRING_COMPARATORS,
    ...STRING_COMPARATORS,
    ...NUMBER_COMPARATORS,
    ...EXISTENCE_BOOLEAN_COMPARATORS,
    ...BOOLEAN_COMPARATORS,
    ...SELECT_COMPARATORS,
    ...SINGLE_MULTI_SELECT_COMPARATORS,
    ...DATE_COMPARATORS,
    ...MULTI_SELECT_COMPARATORS,
  });

export const comparatorsForAttribute = (attribute) => {
  if (!attribute) return [];

  const {
    details: { type, columnType },
  } = attribute;

  switch (type) {
    case attributeTypes.MULTI_COLUMN_STRING:
      return STRING_COMPARATORS;
    case attributeTypes.STRING:
      switch (columnType) {
        case 'multiple':
          return MULTI_SELECT_COMPARATORS;
        default:
          return STRING_COMPARATORS;
      }
    case attributeTypes.NUMBER:
      return NUMBER_COMPARATORS;
    case attributeTypes.EXISTENCE_BOOLEAN:
      return EXISTENCE_BOOLEAN_COMPARATORS;
    case attributeTypes.BOOLEAN:
      return BOOLEAN_COMPARATORS;
    case attributeTypes.SELECT:
      return SINGLE_MULTI_SELECT_COMPARATORS;
    case attributeTypes.MULTI_SELECT:
      return MULTI_SELECT_COMPARATORS;
    case attributeTypes.DATETIME:
      return DATE_COMPARATORS;
    case attributeTypes.NESTED_SELECT:
      switch (columnType) {
        case 'multiple':
          return ARRAY_MULTI_SELECT_COMPARATORS;
        default:
          return SINGLE_MULTI_SELECT_COMPARATORS;
      }
    case attributeTypes.KEYWORD_STRING:
      return KEYWORD_STRING_COMPARATORS;
    default:
      return { match: 'is equal to' };
  }
};
