import { useQuery } from '@apollo/client';

import query from './query.graphql';

export function useFitModel() {
  const { data, loading, refetch, error } = useQuery(query);

  const payload = {
    ...data?.fitModel,
    account: data?.account,
    accountNotifications: data?.accountNotifications,
  };

  return { data: payload, loading, refetch, error };
}
