
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AccountConnectorSyncStatus"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AccountConnector"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"lastSyncedAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"syncStartedAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"nextSyncAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"syncStatus"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"syncProgress"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isInitialSync"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":148}};
    doc.loc.source = {"body":"fragment AccountConnectorSyncStatus on AccountConnector {\n  lastSyncedAt\n  syncStartedAt\n  nextSyncAt\n  syncStatus\n  syncProgress\n  isInitialSync\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
