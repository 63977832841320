import Select from '~/components/Select';

import styles from '../styles';
import { comparatorsForAttribute } from '../utils/comparators';

const ComparatorField = ({ value, attribute, onChange, classes, ...props }) => {
  const operators = comparatorsForAttribute(attribute);
  const keys = Object.keys(operators);
  return (
    <Select
      classes={{ root: classes.comparator }}
      className={classes.comparator}
      onChange={(e) => onChange(e)}
      options={keys}
      value={[value]}
      {...props}
      getOptionLabel={(option) => comparatorsForAttribute(attribute)[option]}
      getOptionValue={(option) => option}
      isDisabled={props.isDisabled || keys.length === 1}
      isSearchable={false}
      maxMenuHeight={380}
      placeholder="Select a value"
    />
  );
};

ComparatorField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.object,
  isDisabled: PropTypes.bool,
  attribute: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

export default withStyles(styles)(ComparatorField);
