import { ProspectListExport } from '~/generated/graphql';

export function convertExportItemsToCSV(
  prospectListExport: ProspectListExport,
) {
  const items = prospectListExport.items?.nodes || [];

  if (!items.length) {
    throw new Error('No items to export');
  }

  let csv = CSV_COLUMNS.map((column) => column.title).join(',') + '\n';
  items.filter(Boolean).forEach((item: any) => {
    const row = CSV_COLUMNS.map((column) => {
      // @ts-ignore
      const value = item[column.key];
      return value ? `"${value}"` : '';
    }).join(',');

    csv += row + '\n';
  });

  return csv;
}

const CSV_COLUMNS = [
  {
    title: 'Name',
    key: 'name',
  },
  {
    title: 'Email',
    key: 'email',
  },
  {
    title: 'Phone',
    key: 'phone',
  },
  {
    title: 'Role',
    key: 'role',
  },
  {
    title: 'Title',
    key: 'title',
  },
  {
    title: 'LinkedIn',
    key: 'linkedin',
  },
  {
    title: 'Company Name',
    key: 'companyName',
  },
  {
    title: 'Company Domain',
    key: 'companyDomain',
  },
  {
    title: 'Company Location',
    key: 'companyLocation',
  },
  {
    title: 'Company City',
    key: 'companyCity',
  },
  {
    title: 'Company State',
    key: 'companyState',
  },
  {
    title: 'Company Country',
    key: 'companyCountry',
  },
];
