import { CKButton, CKTextFieldNext } from 'clearkit';
import { FC, useState } from 'react';

import AuthLayout, { AuthLayoutHeader } from '~/components/layouts/AuthLayout';

import { AuthBox } from '../AuthBox';
import useVerifyTOTPCode from './useVerifyTOTPCode';

const TOTPMultiFactorFlow: FC = function() {
  const [code, setCode] = useState('');
  const [error, setError] = useState<string>();

  const { mutateAsync: verifyCode, isLoading } = useVerifyTOTPCode();

  async function submit() {
    setError(undefined);
    const result = await verifyCode({ code });

    if (result.isSuccess) {
      window.location.pathname = '/';
      return;
    } else {
      setError(result.error);
      setCode('');
    }
  }

  function inputIsValid(code: string) {
    return code.match(/^[0-9]+$/) && code.length === 6;
  }

  function errorMessage(messageCode: string) {
    switch (messageCode) {
      case 'invalid_totp_code':
        return 'Invalid code. Please try again.';
      default:
        return 'Something went wrong. Please try again.';
    }
  }

  return (
    <AuthLayout>
      <>
        <AuthLayoutHeader
          className="sm:w-80"
          description="Multi-factor authentication is required for this account, for security purposes. Enter the six-digit one-time password from your authenticator app."
          header={
            <div className="font-normal text-center">
              Enter your one-time password to sign in
            </div>
          }
        />

        <form
          className="w-full"
          onSubmit={(e) => {
            e.preventDefault();
            submit();
          }}
        >
          <AuthBox className="gap-6">
            <CKTextFieldNext
              name="code"
              onChange={setCode}
              placeholder="One-time password"
              type="text"
              value={code}
              variant={error ? 'error' : undefined}
            >
              {error ? (
                <CKTextFieldNext.ErrorMessage>
                  {errorMessage(error)}
                </CKTextFieldNext.ErrorMessage>
              ) : null}
            </CKTextFieldNext>
            <div>
              <CKButton
                isDisabled={!inputIsValid(code)}
                isLoading={isLoading}
                onClick={submit}
                variant="bold"
                variantColor="blue"
              >
                Verify and continue
              </CKButton>
            </div>
          </AuthBox>
        </form>
      </>
    </AuthLayout>
  );
};

export default TOTPMultiFactorFlow;
