import { ReactNode } from 'react';

import { FINISHED_STATUS } from '../IdealCustomerProfile/constants';
import { useFitModel } from '../IdealCustomerProfile/useFitModel';

type WhenFitScoreProps = {
  children: ReactNode;
};
export function WhenFitScore({ children }: WhenFitScoreProps) {
  const hasFitScoreEnabled = useFitScoreEnabled();

  return <>{hasFitScoreEnabled ? children : null}</>;
}

export function useFitScoreEnabled() {
  const { data } = useFitModel();

  return data?.status === FINISHED_STATUS;
}
