import { components } from 'react-select';

import styles from './styles.js';

class ValueContainer extends React.Component {
  constructor(props) {
    super(props);

    this.valueContainer = React.createRef();
    this.truncateCount = 0;
  }

  componentDidUpdate() {
    this.truncateCount = this.wrappedChildrenCount();
  }

  wrappedChildrenCount = () => {
    if (!this.valueContainer.current) return 0;
    const children = this.valueContainer.current.childNodes;
    let count = 0;
    for (let index = 0; index < children.length - 1; index++) {
      const child = children[index];
      if (child.offsetTop > 20) {
        count++;
      }
    }
    return count;
  };

  render() {
    const {
      classes,
      children,
      selectProps: { menuIsOpen },
      ...props
    } = this.props;

    return (
      <components.ValueContainer {...props} className={classes.root}>
        <div
          className={classnames(classes.valueContainer, {
            [classes.truncate]: !menuIsOpen,
          })}
          ref={this.valueContainer}
        >
          {children}
        </div>
        <>
          {!menuIsOpen && !!this.truncateCount && (
            <span className={classes.expandContainer}>
              ...
              <div className={classes.numberLeft}>+{this.truncateCount}</div>
            </span>
          )}
        </>
      </components.ValueContainer>
    );
  }
}

export default withStyles(styles)(ValueContainer);
