import { ArrowRight } from '@clearkit/icons';
import { CKBox, CKButton, CKLayoutGrid, CKLayoutHorizontal } from 'clearkit';

import { EMPTY_LABEL } from '~/lib/constants';
import { formatCostPerMonth } from '~/lib/selfServePlanHelpers';

import { PendingPlanChange } from './types';
import { formatDate } from './utils';

type BillingPendingPlanChangeProps = {
  handleCancel?: () => void;
  isLoading?: boolean;
  pendingPlanChange?: PendingPlanChange | null | undefined;
  previousCredits?: number;
  previousCostsPerMonth?: number;
};

const CreditsBox = ({
  credits,
  costPerMonth = 0,
}: {
  costPerMonth?: number;
  credits?: number;
}) => {
  return (
    <CKBox className="p-4 text-sm grow" variant="tinted-frame">
      <div className="font-semibold">
        {credits?.toLocaleString('en-us')} credits per month
      </div>
      <div className="text-sm text-gray-500">
        {formatCostPerMonth(costPerMonth)}
      </div>
    </CKBox>
  );
};

export const BillingPendingPlanChange = ({
  handleCancel,
  isLoading = false,
  pendingPlanChange,
  previousCredits,
  previousCostsPerMonth,
}: BillingPendingPlanChangeProps) => {
  if (!pendingPlanChange) return null;

  const effectiveDate = pendingPlanChange?.effectiveDate;

  return (
    <CKBox>
      <CKLayoutGrid
        className="p-4 !items-center"
        gridTemplateColumns="1fr auto 1fr"
      >
        <CreditsBox
          costPerMonth={previousCostsPerMonth}
          credits={previousCredits}
        />
        <ArrowRight className="text-gray-600" />
        <CreditsBox
          costPerMonth={pendingPlanChange?.costPerMonth}
          credits={pendingPlanChange?.credits}
        />
      </CKLayoutGrid>
      <CKBox.Footer className="p-4 text-small">
        <CKLayoutHorizontal>
          <CKLayoutHorizontal gap=".325rem" justifyContent="start">
            <strong>Change effective</strong>
            {effectiveDate ? formatDate(effectiveDate) : EMPTY_LABEL}
          </CKLayoutHorizontal>

          {handleCancel ? (
            <CKButton
              isLoading={isLoading}
              onClick={handleCancel}
              variant="tertiary"
              variantColor="blue"
            >
              Cancel
            </CKButton>
          ) : null}
        </CKLayoutHorizontal>
      </CKBox.Footer>
    </CKBox>
  );
};
