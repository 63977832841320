import {
  CheckmarkOrb as CheckmarkIcon,
  Restart as UpdateIcon,
} from '@clearkit/icons';
import { CKButton } from 'clearkit';

import LoadingBar, { useLoadingBarState } from '~/components/LoadingBar';
import {
  readableSegmentType,
  readableSingularSegmentType,
  searchDescription,
} from '~/lib/segments';

import { states as refreshStates } from '../useRefreshState';

const Separator = () => (
  <div className="h-6 ml-2 mr-2 border-l border-gray-200 border-solid" />
);

const RefetchForNewMatches = ({ resultsCount, onRefetch, type }) => {
  const readableCount = resultsCount?.toLocaleString();
  const segmentType =
    resultsCount === 1
      ? readableSingularSegmentType(type)
      : readableSegmentType(type);

  return (
    <div className="flex items-center">
      <span>
        {readableCount} {segmentType}{' '}
      </span>
      <Separator />
      <CKButton
        leftIcon={<UpdateIcon />}
        onClick={() => onRefetch()}
        size="small"
        tabIndex={0}
        variant="tertiary"
        variantColor="blue"
      >
        Update with new matches
      </CKButton>
    </div>
  );
};

RefetchForNewMatches.propTypes = {
  resultsCount: PropTypes.number,
  onRefetch: PropTypes.func,
  type: PropTypes.string,
};

const CheckingForMatchesLoadingBar = () => {
  const loadingBarProps = useLoadingBarState();

  return <LoadingBar className="inline-block w-16 mx-1" {...loadingBarProps} />;
};

const Subtitle = ({
  currentResultsCount,
  originalResultsCount,
  segment,
  searchQuery,
  refreshState,
  onRefetch,
  loading,
}) => {
  if (loading) return 'Loading...';

  const readableCurrentResultsCount = currentResultsCount?.toLocaleString();
  const segmentType =
    currentResultsCount === 1
      ? readableSingularSegmentType(segment?.type)
      : readableSegmentType(segment?.type);

  switch (refreshState) {
    case refreshStates.checkingForNewMatches:
      return (
        <div className="flex items-center">
          <span>
            {readableCurrentResultsCount} {segmentType}{' '}
          </span>
          <Separator />
          <CheckingForMatchesLoadingBar /> Checking for new matches
        </div>
      );
    case refreshStates.segmentUpToDate:
      return (
        <div className="flex items-center text-green-500">
          <CheckmarkIcon className="flex items-center mr-1 fill-gradient-br-green" />
          Audience up to date
        </div>
      );
    case refreshStates.newMatchesAvailable:
      return (
        <div className="flex items-center text-green-500">
          <CheckmarkIcon className="flex items-center mr-1 fill-gradient-br-green" />
          New audience matches available.
        </div>
      );
    case refreshStates.refetchForNewMatches:
      return (
        <RefetchForNewMatches
          onRefetch={onRefetch}
          resultsCount={originalResultsCount}
          type={segment?.type}
        />
      );
    default:
      return (
        <div>
          {searchDescription(
            segment?.type,
            segment?.id,
            currentResultsCount,
            segment?.updatedAt,
            searchQuery,
          )}{' '}
        </div>
      );
  }
};

Subtitle.propTypes = {
  segment: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
  currentResultsCount: PropTypes.number,
  originalResultsCount: PropTypes.number,
  searchQuery: PropTypes.string,
  refreshState: PropTypes.string,
  onRefetch: PropTypes.func,
  loading: PropTypes.bool,
};

export default Subtitle;
