import { routes } from '~/components/app/appMainRoutes';
import { settingsRoutes } from '~/components/settings/settingsRoutes';

import { useHomeIntegrations } from '../hooks/useHomeIntegrations';
import { ItemConnectCRM } from './ItemConnectCRM';
import { OnboardingChecklist } from './OnboardingChecklist';
import { OnboardingItem, OnboardingItemDivider } from './OnboardingItem';

export const ListVisitors = () => {
  const {
    isTagConnected,
    hasVisitorsAddedCompanies,
    hasVisitorsIntentCriteria,
    loadingAccount,
  } = useHomeIntegrations();

  /**
   * @see Intent criteria is stored in local storage, so it doesn't
   * really make sense to check if it's complete here.
   */

  /**
   * @todo Show add companies item when we add the functionality
   */

  const isLoading = loadingAccount;

  return (
    <OnboardingChecklist title="Get started with Website Visitors & Intent">
      <OnboardingItem
        isComplete={!!isTagConnected}
        isLoading={isLoading}
        number={1}
        title="Connect your website"
        to={settingsRoutes.tagsJs}
      />
      <OnboardingItem
        isComplete={hasVisitorsIntentCriteria}
        isDisabled={!isTagConnected}
        isHidden
        isLoading={isLoading}
        number={2}
        title="Define your intent criteria"
        to={routes.visitors}
      />
      <OnboardingItemDivider />
      <ItemConnectCRM number={3} />
      <OnboardingItem
        isComplete={hasVisitorsAddedCompanies}
        isDisabled={!isTagConnected}
        isHidden
        isLoading={isLoading}
        number={4}
        title="Add companies showing intent to your CRM"
        to={routes.visitors}
      />
    </OnboardingChecklist>
  );
};
