const styles = () => ({
  disconnectedContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },

  heading: {
    color: '#27282a',
    fontSize: 16,
    fontWeight: 500,
    marginTop: 28,
  },

  subheading: {
    margin: '12px 0 28px 0',
    color: '#596168',
    fontSize: 15,
  },
});

export default styles;
