import wrapError from '~/components/JavascriptError/JavascriptErrorHandler';

import CompanyDrawerDetails from '../Show/CompaniesDrawerDetails';

type CompanyFromDomainProps = {
  domain: string;
};

const CompanyFromDomain = ({ domain }: CompanyFromDomainProps) => {
  return <CompanyDrawerDetails hideProspectorPreview id={domain} />;
};

export default wrapError(CompanyFromDomain);
