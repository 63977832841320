const styles = () => ({
  '@keyframes animated-background-position': {
    '0%': {
      backgroundPosition: '0% 0%',
    },
    '100%': {
      backgroundPosition: '200% 0%',
    },
  },

  loadingBarWrapper: {
    maxWidth: 120,
    height: 8,
    background: '#F0F4F8',
    boxShadow: 'inset 0 0 1px 0 #C0D0DF',
    borderRadius: 4,
    overflow: 'hidden',
  },

  loadingBarInner: {
    transition: 'linear width 1s',
    background:
      'linear-gradient(135deg, #7793FF 0%, #D576F0 50%, #7793FF 100%)',
    boxShadow: 'inset 0 0 0 1px rgba(0,0,0,0.10)',
    borderRadius: 4,
    height: '100%',
    animation: 'animated-background-position 3s ease infinite',
    backgroundSize: '200% 200%',
  },
});

export default styles;
