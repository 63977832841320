const styles = () => ({
  root: {},
  selected: {},
  option: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 16px',
    fontSize: 14,
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: '1 0 auto',
  },
});

export default styles;
