const styles = () => ({
  segmentLinkContainer: {
    position: 'relative',

    '&:hover $segmentDisabledNotice': {
      right: 30,
    },

    '&:hover $segmentMoreMenu': {
      opacity: 0.5,

      '&:hover': {
        opacity: 1,
      },
    },
  },

  legacyActionMenuItem: {
    color: 'white',
    marginBottom: 2,

    '&:hover, &:focus, &.focused': {
      background: 'rgba(255, 255, 255, .1)',
    },

    '&.selected': {
      background: 'linear-gradient(to left, #637EFF 0%, #C363E5 100%)',
    },
  },

  ActionMenuItemMargin: {
    marginBottom: 2,
  },

  actionMenuItem: {
    border: '1px solid transparent',
    fontSize: 14,
    height: 36,
    borderRadius: 8,

    '&:hover, &:focus, &.focused': {
      backgroundColor: '#FAFBFC',
      color: '#FAFBFC',
      borderColor: '#E1E9F0',
      fontWeight: 500,
    },

    '&.selected': {
      backgroundColor: '#FAFBFC',
      color: 'rgba(20, 140, 252, 0.16)',
      borderColor: 'rgba(77, 177, 253, 0.12)',
      fontWeight: 500,
    },
  },

  segmentLink: {
    display: 'flex',
    color: 'white',
    flex: '0 0 auto',
    alignItems: 'center',
    fontWeight: 400,
    lineHeight: '29px',
    width: '100%',
    borderRadius: 6,
    transition: 'background-color ease .2s',

    '&:focus, &:hover': {
      backgroundColor: 'rgba(255, 255, 255, .1)',

      '& $segmentIcon': {
        fill: '#fff',
      },
    },

    '&:focus ~ $segmentMoreMenu': {
      opacity: 0.5,
    },
  },

  segmentIcon: {
    marginRight: 8,
    flexShrink: 0,
    transition: 'fill ease .2s',
  },

  segmentLinkLabel: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginRight: 'auto',
    paddingTop: 1,
  },

  segmentMoreMenu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0,
    transition: 'opacity ease .2s',

    '&.legacy': {
      position: 'absolute',
      top: 0,
      right: 0,
      width: 30,
      height: 30,
    },

    '&.legacy::before': {
      content: '""',
      position: 'absolute',
      top: 3,
      left: 3,
      width: 24,
      height: 24,
      borderRadius: '100%',
    },

    '&.highlighted': {
      opacity: 0.5,
    },

    '&:focus, &:hover': {
      opacity: '1 !important',

      '&.legacy::before': {
        backgroundColor: 'rgba(255, 255, 255, .1)',
      },
    },
  },

  selectedSegment: {
    borderRadius: 6,
    color: 'white',

    '& $segmentMoreMenu': {
      opacity: 0.5,

      '&:hover, &:focus': {
        opacity: 1,
      },
    },

    '& $segmentDisabledNotice': {
      right: 30,
    },

    '& $segmentLink:focus': {
      boxShadow: 'inset 0 0 0 1px rgba(255, 255, 255, .1)',

      '& ~ $segmentMoreMenu:hover': {
        opacity: 1,
      },
    },

    '& $segmentIcon, & $segmentIcon path:last-child': {
      fill: '#fff',
    },
  },

  segmentDisabledNotice: {
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    position: 'absolute',
    right: 5,
    top: 0,
    transition: 'right ease .1s',
  },

  disabledSegment: {},

  segmentLinkHighlighted: {
    backgroundColor: 'rgba(255, 255, 255, .1)',
    backgroundImage: 'none',

    '& $segmentIcon': {
      fill: '#fff',
    },
  },

  collectionLinkContainer: {
    position: 'relative',

    '&:hover $collectionMoreMenu': {
      opacity: 0.5,

      '&:hover': {
        opacity: 1,
      },
    },
  },

  collectionLink: {
    display: 'flex',
    flex: '0 0 auto',
    alignItems: 'center',
    marginBottom: 2,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '29px',
    paddingLeft: 12,
    paddingRight: 32,
    width: '100%',
    borderRadius: 6,
    transition: 'color ease .2s',

    '&:hover, &:focus': {
      '& $collectionIcon': {
        fill: '#27282A',
      },
    },
  },

  legacyCollectionLink: {
    color: 'rgba(255, 255, 255, .5)',

    '&:hover': {
      color: 'rgba(255, 255, 255, .8)',

      '& $collectionIcon': {
        fill: 'rgba(255, 255, 255, .8)',
      },
    },

    '&:focus': {
      color: '#fff',

      '& ~ $collectionMoreMenu': {
        opacity: 0.5,
      },
    },
  },

  selectedCollectionLink: {
    color: 'rgba(255, 255, 255, .8)',

    '& $collectionIcon': {
      fill: 'rgba(255, 255, 255, .8)',
    },

    '& ~ $collectionMoreMenu': {
      opacity: 0.5,
    },
  },

  collectionIcon: {
    marginRight: 8,
    flexShrink: 0,
    transition: 'fill ease .2s',
  },

  collectionLinkLabel: {
    width: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginTop: 1,
  },

  collectionMoreMenu: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    right: 0,
    width: 30,
    height: 30,
    opacity: 0,
    transition: 'opacity ease .2s',

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 3,
      left: 3,
      width: 24,
      height: 24,
      borderRadius: '100%',
    },

    '&:focus, &:hover': {
      opacity: 1,
      fill: '#27282A',
    },
  },

  legacyCollectionMoreMenu: {
    color: '#fff',

    '&:focus, &:hover': {
      '&::before': {
        backgroundColor: 'rgba(255, 255, 255, .1)',
      },
    },
  },

  renameCollectionInput: {
    width: '100%',
    marginBottom: 2,

    '& input': {
      width: '100%',
      height: 30,
      fontSize: '14px',
      fontWeight: 500,
      paddingLeft: '38px !important',
    },

    '& .ck-field-icon': {
      height: '100%',
    },

    '& $renameCollectionIcon': {
      marginLeft: 17,
    },
  },

  legacyRenameCollectionInput: {
    '& input': {
      color: '#fff',
      background: 'rgba(0, 0, 0, .05)',
      boxShadow:
        '0 0 0 1px rgba(39, 40, 42, .2), inset 0 2px 1px rgba(39, 40, 42, .05), 0 0 0 4px rgba(213, 118, 240, 0)',

      '&:focus': {
        background: 'rgba(0, 0, 0, .1)',
        boxShadow:
          '0 0 0 1px rgba(193, 126, 241, 1), inset 0 2px 1px rgba(39, 40, 42, .05), 0 0 0 4px rgba(213, 118, 240, .3)',
      },

      '&::placeholder': {
        color: 'rgba(255, 255, 255, 0.4)',
      },

      '&::-webkit-input-placeholder': {
        color: 'rgba(255, 255, 255, 0.4)',
      },
    },
  },

  renameCollectionIcon: {
    marginLeft: 8,
    marginRight: 8,
    flexShrink: 0,
  },

  disableHoverCursorPointer: {
    pointerEvents: 'none',
  },

  highlightedTerm: {
    background: 'rgba(255, 255, 255, .2)',
    borderRadius: 2,
  },

  noSegments: {
    opacity: 0.5,
    fontSize: 14,
    fontStyle: 'italic',
    padding: '3px 0 6px 32px',
  },

  iconHover: {
    '&:hover, &:focus': {
      '& $segmentIcon': {
        color: '#27282A',
      },
    },
  },
});

export default styles;
