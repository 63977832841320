import GraphqlError from '~/components/GraphqlError';
import PageviewsList from '~/components/Sidebar/RedesignedPageviewsList';

import { useCompanyEvents } from './useCompanyEvents';

const PAGEVIEWS_EVENT_TYPE = 'page';

type CompanyEventsListProps = {
  company: any;
  path?: string;
  excludingAnonymous?: boolean;
  className?: string;
};
export function CompanyPageviewsList({
  company,
  path,
  className,
  excludingAnonymous,
}: CompanyEventsListProps) {
  const {
    error,
    loading,
    data,
    refetch,
    fetchMore,
    loadingFirstPage,
  } = useCompanyEvents({
    company,
    path,
    excludingAnonymous,
    type: PAGEVIEWS_EVENT_TYPE,
  });

  const { eventsHasNextPage, events } = data;

  if (error) {
    return <GraphqlError error={error} loading={loading} refetch={refetch} />;
  }

  return (
    <PageviewsList
      className={className}
      events={events}
      hasNextPage={eventsHasNextPage}
      id={company.id}
      loading={loadingFirstPage}
      loadingMore={loading}
      onLoadMore={fetchMore}
      path={path}
    />
  );
}
