const DONUT_SIZE = 48;

const styles = () => ({
  donut: {
    width: DONUT_SIZE,
    height: DONUT_SIZE,
  },
  firstDonut: {
    width: DONUT_SIZE,
    height: DONUT_SIZE,
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      height: '100%',
      width: '75%',
      background:
        'linear-gradient(to left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
      zIndex: 10,
    },
  },
  lastDonut: {
    width: DONUT_SIZE,
    height: DONUT_SIZE,
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      height: '100%',
      width: '75%',
      background:
        'linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
      zIndex: 10,
    },
  },
});

export default styles;
