import { useMutation, useQuery } from '@apollo/client/react/hooks';
import { CKButton, CKDrawer } from 'clearkit';
import { Link } from 'react-router-dom';

import ToastsContainer from '~/containers/Toasts';
import {
  destinationTypeCustomHtml,
  destinationTypeEmail,
  destinationTypeFacebookConversion,
  destinationTypeGoogleConversion,
  destinationTypeSalesforce,
  destinationTypeSalesforceCreateAccount,
  destinationTypeSalesforceCreateContact,
  destinationTypeSalesforceCreateLead,
  destinationTypeSlack,
  destinationTypeWebhook,
} from '~/lib/destinations';
import history from '~/lib/history';
import paths from '~/lib/paths';
import { trackDestinationCreated } from '~/lib/segmentAnalytics.js';
import { legacySegmentType } from '~/lib/segments';

import FormWrapper from '../components/FormWrapper';
import DestinationFormFactory from '../destinationConfigs/DestinationFormFactory';
import mutation from './mutation.graphql';
import query from './query.graphql';

export default function SegmentDestinationNewPage({
  segmentId,
  segmentType,
  type,
}) {
  const [errors, setErrors] = useState([]);

  const handleCreate = (createDestination, destinationConfig) => {
    createDestination({
      variables: {
        input: {
          segmentId,
          segmentType,
          type,
          ...destinationConfig,
        },
      },
    });
  };

  const handleComplete = ({ response: { node: destination, errors } }) => {
    if (errors.length) {
      setErrors(errors);
      ToastsContainer.addError('There was an error saving the destination');
      return;
    }

    const { segment } = destination;

    trackDestinationCreated(destination);
    ToastsContainer.addSuccess('Destination saved');

    const path = paths.segmentDestinations(segment.type, segment.id);
    history.push(path);
  };

  const attributeType = legacySegmentType(segmentType);

  const dynamicDestinationTarget =
    type === destinationTypeEmail || type === destinationTypeSlack;

  const variables = {
    attributeType,
    includeSalesforceAccountConnector:
      type === destinationTypeSalesforce ||
      dynamicDestinationTarget ||
      type === destinationTypeSalesforceCreateAccount ||
      type === destinationTypeSalesforceCreateLead ||
      type === destinationTypeSalesforceCreateContact,
    includeHubspotAccountConnector: dynamicDestinationTarget,
    includeSlackAccountConnector: type === destinationTypeSlack,
    includeFacebookAccountConnector: type === destinationTypeFacebookConversion,
    includeGoogleAccountConnector: type === destinationTypeGoogleConversion,
    segmentId,
    segmentType,
  };

  const { data = {}, refetch, loading, error } = useQuery(query, { variables });

  const { segment } = data;

  const accountConnectors = {
    facebook: data.facebookAccountConnector,
    google: data.googleAccountConnector,
    hubspot: data.hubspotAccountConnector,
    salesforce: data.salesforceAccountConnector,
    slack: data.slackAccountConnector,
  };

  const FormFooter = ({ destinationConfig, submitDisabled }) => {
    const [createDestination, { loading }] = useMutation(mutation, {
      onCompleted: handleComplete,
      refetchQueries: ['SegmentDestinationsPage'],
    });

    return (
      <CKDrawer.Footer className="gap-3">
        <CKButton
          as={Link}
          to={paths.segmentDestinations(segment.type, segment.id)}
          variant="simple"
          variantColor="default"
        >
          Cancel
        </CKButton>
        <CKButton
          isDisabled={loading || submitDisabled}
          onClick={() => {
            handleCreate(createDestination, destinationConfig);
          }}
          type="button"
          variant="bold"
          variantColor="green"
        >
          Save changes
        </CKButton>
      </CKDrawer.Footer>
    );
  };

  return (
    <FormWrapper error={error} loading={loading} refetch={refetch}>
      {() => (
        <DestinationFormFactory
          accountConnectors={accountConnectors}
          attributes={data.attributes}
          destinationOptions={data.destinationOptions}
          errors={errors}
          FormFooter={FormFooter}
          refetch={refetch}
          segment={segment}
          type={type}
        />
      )}
    </FormWrapper>
  );
}

SegmentDestinationNewPage.propTypes = {
  segmentId: PropTypes.string.isRequired,
  segmentType: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    destinationTypeCustomHtml,
    destinationTypeEmail,
    destinationTypeFacebookConversion,
    destinationTypeGoogleConversion,
    destinationTypeSalesforce,
    destinationTypeSalesforceCreateAccount,
    destinationTypeSalesforceCreateContact,
    destinationTypeSalesforceCreateLead,
    destinationTypeSlack,
    destinationTypeWebhook,
  ]).isRequired,
};
