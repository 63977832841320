import { ProductFeatureEnum } from '~/generated/graphql';

export function getPlanDisplayName(planId?: string) {
  if (!planId) return 'Plan';

  switch (planId) {
    case ProductFeatureEnum.HubspotFreeTier:
      return 'HubSpot Free Tier Plan';
    case ProductFeatureEnum.HubspotPaidTier:
      return 'HubSpot Paid Tier Plan';
    default:
      return `${planId.split('_').join(' ')} Plan`;
  }
}
