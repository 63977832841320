export const EMPTY_SEARCH_ATTRIBUTE = { match: { attribute: '', value: '' } };

export const EMPTY_SALESFORCE_CONTACT_OBJECT = {
  salesforce_contact: {
    and: [EMPTY_SEARCH_ATTRIBUTE],
  },
};

export function getAttributeType(integration, objectType) {
  if (integration === 'salesforce') {
    return objectType.name == 'account' ? 'Company' : 'Person';
  } else if (integration === 'hubspot') {
    return objectType.name == 'company' ? 'Company' : 'Person';
  }
  return 'Company';
}

export function getEmptyStateSubObject(integration, objectType) {
  return {
    [`${integration}_${objectType.name}`]: { and: [EMPTY_SEARCH_ATTRIBUTE] },
  };
}

export function getEmptyStateSearch(integration, objectType) {
  return {
    and: [getEmptyStateSubObject(integration, objectType)],
    version: 2,
  };
}
