import { CKButton } from 'clearkit';
import { Link } from 'react-router-dom';

import AudienceServiceIcon from '~/components/audiences/ServiceIcon';
import AudiencesTile from '~/components/audiences/Tile';
import TimeAgo from '~/components/TimeAgo';
import paths from '~/lib/paths';

import styles from './styles';

function Audience({ audience, classes, className }) {
  return (
    <li className={classnames(classes.audience, className)}>
      <AudiencesTile
        audience={audience}
        className={classes.audienceTile}
        size={32}
      />
      <div className={classes.audienceDetailsWrapper}>
        <div className={classes.audienceNameWrapper}>
          <span className={classes.audienceName}>
            <AudienceServiceIcon audience={audience} size={16} />
            {audience.externalName}
          </span>
          <span className={classes.audienceDescription}>
            Created
            {audience.user && (
              <>
                {' '}
                by <strong>{audience.user.name}</strong>
              </>
            )}{' '}
            <TimeAgo time={audience.createdAt} />
          </span>
        </div>
        <CKButton
          as={Link}
          className={classes.audienceButton}
          size="small"
          to={paths.audience(audience.id)}
        >
          View
        </CKButton>
      </div>
    </li>
  );
}

Audience.propTypes = {
  className: PropTypes.string,
  audience: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Audience);
