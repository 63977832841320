import { useMutation } from '@apollo/client/react/hooks';
import { InfoHollow, SpinnerIndicators as Spinner } from '@clearkit/icons';
import {
  CKBadge,
  CKButton,
  CKCardCollapsible,
  CKModal,
  CKSwitch,
  CKTooltip,
} from 'clearkit';
import capitalize from 'lodash/capitalize';
import { useState } from 'react';

import ToastsContainer from '~/containers/Toasts';
import { AccountFlagEnum } from '~/generated/graphql';
import { useFeatureFlag } from '~/hooks/account';
import { readableObjectTypeName, readableType } from '~/lib/dataEnrichment';

import toggle from '../toggle.graphql';

const EnrichmentToggle = ({
  integration,
  objectType,
  loading,
  isActive,
  onToggleChange,
  accountConnector,
  hasSavedSelectiveEnrichmentConfig,
  hasSavedFieldMappings,
}) => {
  const [toggleFieldMapping, { loading: isToggling }] = useMutation(toggle, {
    onCompleted: () => {
      ToastsContainer.addSuccess(
        `${capitalize(objectType.name)} enrichment ${
          isActive ? 'disabled' : 'enabled'
        }`,
      );
    },
    onError: () => {
      ToastsContainer.addWarning(
        `An error occured when ${isActive ? 'disabling' : 'enabling'} ${
          objectType.name
        } enrichment, please try again.`,
      );
    },
    refetchQueries: ['FieldMappingForm'],
    variables: {
      input: {
        id: accountConnector?.id,
        targetObjectType: capitalize(objectType.name),
        enabled: !isActive,
      },
      targetObjectType: capitalize(objectType.name),
    },
  });

  const [confirmToggleDialogOpen, setConfirmToggleDialogOpen] = useState(false);

  return (
    <>
      <SelectiveEnrichmentToggle
        hasSavedFieldMappings={hasSavedFieldMappings}
        hasSavedSelectiveEnrichmentConfig={hasSavedSelectiveEnrichmentConfig}
        integration={integration}
        isActive={isActive}
        loading={loading}
        objectType={objectType}
        setConfirmToggleDialogOpen={setConfirmToggleDialogOpen}
      />

      <ConfirmationModal
        confirmToggleDialogOpen={confirmToggleDialogOpen}
        integration={integration}
        isActive={isActive}
        isToggling={isToggling}
        objectType={objectType}
        setConfirmToggleDialogOpen={setConfirmToggleDialogOpen}
        toggleFieldMapping={async () => {
          await toggleFieldMapping();
          onToggleChange(isActive ? false : true);
        }}
      />
    </>
  );
};

function SelectiveEnrichmentToggle({
  integration,
  objectType,
  loading,
  isActive,
  hasSavedSelectiveEnrichmentConfig,
  hasSavedFieldMappings,
  setConfirmToggleDialogOpen,
}) {
  const enrichmentUIEnabled = useFeatureFlag(
    AccountFlagEnum.AllowDataEnrichmentPreview,
  ).enabled;
  const enrichmentDisabled = useFeatureFlag(AccountFlagEnum.XDataEnrichment)
    .disabled;
  // userPreviewingFeature - a free_tier user can accerss the UI for CRM enrichment but cant actually enable the feature
  const userPreviewingFeature = enrichmentUIEnabled && enrichmentDisabled;
  const disabled = !hasSavedFieldMappings || userPreviewingFeature;

  const tooltipMessage = userPreviewingFeature
    ? 'CRM enrichment is not enabled for free tier customers. Get access by upgrading to our Business plan.'
    : 'Field mappings are required before enabling enrichment';
  return (
    <div className="px-4 mt-10">
      <CKCardCollapsible>
        <SelectiveEnrichmentToggleHeader
          integration={integration}
          isActive={isActive}
          objectType={objectType}
        />
        <CKCardCollapsible.Body>
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-col mr-20 w-96">
              <p className="text-base font-medium text-gray-800">
                Enable or disable {objectType.name} enrichment
              </p>
            </div>
            {loading ? (
              <Spinner className="mx-4" />
            ) : !disabled ? (
              <CKSwitch
                checked={isActive}
                id="field mapping status"
                onChange={() => setConfirmToggleDialogOpen(true)}
              >
                {isActive ? 'Enabled' : 'Disabled'}
              </CKSwitch>
            ) : (
              <CKTooltip
                placement="top-end"
                tooltip={tooltipMessage}
                tooltipMaxWidth="25ch"
              >
                <CKSwitch
                  checked={isActive}
                  id="field mapping status"
                  isDisabled
                >
                  {isActive ? 'Enabled' : 'Disabled'}
                </CKSwitch>
              </CKTooltip>
            )}
          </div>
          {hasSavedSelectiveEnrichmentConfig ? (
            <SelectiveEnrichmentToggleFooter
              integration={integration}
              objectType={objectType}
            />
          ) : null}
        </CKCardCollapsible.Body>
      </CKCardCollapsible>
    </div>
  );
}

function SelectiveEnrichmentToggleFooter({ integration, objectType }) {
  return (
    <CKCardCollapsible.Footer className="flex flex-row items-center bg-gray-0">
      <div className="flex flex-col mr-4">
        <InfoHollow />
      </div>
      Selective enrichment is on. Enriched {objectType.name} data will only be
      synced to {readableType(integration)} for{' '}
      {readableObjectTypeName(objectType.name)} that meet your selective
      enrichment criteria.
    </CKCardCollapsible.Footer>
  );
}

function SelectiveEnrichmentToggleHeader({
  integration,
  objectType,
  isActive,
}) {
  return (
    <CKCardCollapsible.Header>
      <div
        className="grid grid-cols-1fr-auto gap-4"
        data-testid="enrichmentToggleConfigCardHeader"
      >
        <div className="text-base">
          <h3 className="mb-2 font-medium">
            {capitalize(objectType.name)} Enrichment
          </h3>
          <p>
            Enable {objectType.name} enrichment in order to{' '}
            <span className="font-medium">
              sync enriched {objectType.name} data to {capitalize(integration)},
            </span>{' '}
            keeping new and existing {readableObjectTypeName(objectType.name)}{' '}
            up-to-date with fresh data using the field mappings you’ve set up
            for {capitalize(integration)}{' '}
            {readableObjectTypeName(objectType.name)}.
          </p>
        </div>
        {isActive ? (
          <CKBadge
            className="self-start text-xs text-green-700 bg-green-100"
            variant="default"
          >
            Enabled
          </CKBadge>
        ) : (
          <CKBadge
            className="self-start text-xs text-gray-600 bg-gray-100"
            variant="default"
          >
            Disabled
          </CKBadge>
        )}
      </div>
    </CKCardCollapsible.Header>
  );
}

function ConfirmationModal({
  confirmToggleDialogOpen,
  setConfirmToggleDialogOpen,
  isActive,
  integration,
  objectType,
  toggleFieldMapping,
  isToggling,
}) {
  return (
    <CKModal
      aria-label="Modal"
      baseId="toggle-field-mapping-modal"
      isVisible={confirmToggleDialogOpen}
      onToggle={() => setConfirmToggleDialogOpen(false)}
      size="sm"
    >
      <CKModal.Header>
        <CKModal.Heading>
          Are you sure you want to {isActive ? 'disable' : 'enable'} enriching{' '}
          {readableType(integration)} {readableObjectTypeName(objectType.name)}?
        </CKModal.Heading>
      </CKModal.Header>
      <CKModal.Body className="px-10 py-6">
        {isActive
          ? `${readableType(integration)} ${readableObjectTypeName(
              objectType.name,
            )} previously enriched by Clearbit data will
      retain that data.`
          : `${readableType(integration)} ${readableObjectTypeName(
              objectType.name,
            )} will be enriched with Clearbit data.`}
      </CKModal.Body>
      <CKModal.Footer>
        <CKButton
          className="mr-2"
          onClick={() => setConfirmToggleDialogOpen(false)}
        >
          Cancel
        </CKButton>
        <CKButton
          isLoading={isToggling}
          onClick={async () => {
            await toggleFieldMapping();
            setConfirmToggleDialogOpen(false);
          }}
          variant="bold"
          variantColor="red"
        >
          Yes, {isActive ? 'disable' : 'enable'} syncing data to{' '}
          {readableObjectTypeName(objectType.name)}
        </CKButton>
      </CKModal.Footer>
    </CKModal>
  );
}

export default EnrichmentToggle;
