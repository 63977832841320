import { CKButton, CKDrawer } from 'clearkit';
import { useState } from 'react';

import { ButtonContainer } from '~/components/unified/ButtonContainer';

import { CRMOptInModal } from '../Dialogs';
import { useProspector } from '../useProspector';
import { HubspotExport } from './HubspotExport';

type ExportProspectHubspotWrapperProps = {
  children: React.ReactNode;
};
export function ExportProspectHubspotWrapper({
  children,
}: ExportProspectHubspotWrapperProps) {
  return <>{children}</>;
}

export function ExportProspectHubspot() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [_state, send] = useProspector();

  const handleOpenModal = () => setIsModalOpen(true);
  const handleClose = () => send('CANCEL');

  return (
    <ExportProspectHubspotWrapper>
      <CKDrawer.Header>
        <CKDrawer.Heading>Add prospects to HubSpot</CKDrawer.Heading>
        <p className="text-sm text-gray-600">
          Create new HubSpot contacts from prospects revealed by Clearbit.
        </p>
      </CKDrawer.Header>
      <CKDrawer.Body className="p-8 bg-gray-0">
        <HubspotExport />
      </CKDrawer.Body>
      <CKDrawer.Footer>
        <ButtonContainer>
          <CKButton onClick={handleClose}>Cancel</CKButton>
          <CKButton
            onClick={handleOpenModal}
            variant="bold"
            variantColor="green"
          >
            Create contacts in HubSpot
          </CKButton>
        </ButtonContainer>
        <CRMOptInModal isVisible={isModalOpen} service="hubspot" />
      </CKDrawer.Footer>
    </ExportProspectHubspotWrapper>
  );
}
