import { UsageAltNav } from '@clearkit/icons';

import { routes } from '~/components/app/appMainRoutes';
import GraphqlError from '~/components/GraphqlError';
import LoadingArea from '~/components/LoadingArea';
import { PageLayout } from '~/components/PageLayout';
import { CreditBasedUsageDetailsLayout } from '~/components/usage/CreditBasedUsageLayout/CreditBasedUsageDetailsLayout';
import { DefaultLayout } from '~/components/usage/DefaultLayout';
import {
  ProductFeatureEnum,
  useProductFeatureDetailsQuery,
} from '~/generated/graphql';

export type UsageDetailsProps = {
  id: ProductFeatureEnum;
};

export const UsageDetails = ({ id }: UsageDetailsProps) => {
  const { data, loading, error, refetch } = useProductFeatureDetailsQuery({
    variables: {
      id: id,
    },
  });

  const showCreditBasedUsageLayout =
    id.includes('free_tier') ||
    id.includes('business_tier') ||
    id.includes('paid_tier');

  if (error) {
    return (
      <GraphqlError
        className="!m-0"
        error={error}
        loading={loading}
        refetch={refetch}
      />
    );
  }
  if (loading || !data) {
    return <LoadingArea loading={loading} />;
  }

  const feature = data.productFeature;
  if (!feature) {
    return <p>Your account does not have access to this feature.</p>;
  }

  return (
    <PageLayout>
      <PageLayout.Header>
        <PageLayout.Tile>
          <UsageAltNav />
        </PageLayout.Tile>
        <PageLayout.Breadcrumb
          breadcrumbs={[
            {
              title: 'Usage',
              path: routes.usage,
            },
          ]}
        >
          {feature.name}
        </PageLayout.Breadcrumb>
        <PageLayout.SubHeading>
          Measured by {feature.meteredUnit} used
        </PageLayout.SubHeading>
      </PageLayout.Header>
      <PageLayout.Body>
        <PageLayout.Content className="pb-12 space-y-8 divide-y divide-gray-200">
          {showCreditBasedUsageLayout ? (
            <CreditBasedUsageDetailsLayout feature={feature} />
          ) : (
            <DefaultLayout feature={feature} />
          )}
        </PageLayout.Content>
      </PageLayout.Body>
    </PageLayout>
  );
};
