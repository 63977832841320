import { useMutation } from 'react-query';

export default function useCreateProfileAndSignIn() {
  return useMutation(
    async (params: {
      firstName: string | undefined;
      lastName: string | undefined;
      telemetryId: string;
    }) => {
      const response = await fetch('/auth/signup/profile', {
        method: 'POST',
        body: JSON.stringify({
          first_name: params.firstName,
          last_name: params.lastName,
          telemetry_id: params.telemetryId,
          anonymous_id: window.analytics?.user?.()?.anonymousId?.(),
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        return { isSuccess: true };
      } else {
        const data = await response.json();
        return { isSuccess: false, error: data };
      }
    },
  );
}
