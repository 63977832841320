import { CKTab, CKTabList, CKTabs } from 'clearkit';
import capitalize from 'lodash/capitalize';

import history from '~/lib/history';
import paths from '~/lib/paths';

import { IntegrationTabsProps } from './types';
import { useIntegrationTabState } from './useIntegrationTabState';

export function IntegrationTabs({
  integration,
  tabName,
}: IntegrationTabsProps) {
  const { currentTabIndex, tabs, getTabByIndex } = useIntegrationTabState({
    integration,
    tabName,
  });

  return (
    <CKTabs
      index={currentTabIndex}
      isBordered
      onChange={(index) => {
        history.push(
          paths.dataEnrichmentObject(integration, getTabByIndex(index).name),
        );
      }}
    >
      <CKTabList aria-label="Integration tabs">
        {tabs.map((tab: any) => (
          <CKTab icon={tab.icon} key={tab.label} label={capitalize(tab.label)}>
            {capitalize(tab.label)}
          </CKTab>
        ))}
      </CKTabList>
    </CKTabs>
  );
}
