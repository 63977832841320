import { ApolloError } from '@apollo/client';
import { Error, SpinnerIndicators as Spinner } from '@clearkit/icons';
import classnames from 'classnames';
import { CKBadge, CKBannerGroup, CKBannerNext, CKButton } from 'clearkit';
import get from 'lodash/get';

export type ErrorBannerProps = {
  className?: string;
  error: ApolloError | undefined;
  inline?: boolean;
  isJavaScript?: boolean;
  loading?: boolean;
  refetch?: () => void;
};

export const ErrorBanner: React.FC<ErrorBannerProps> = ({
  className,
  error,
  inline,
  isJavaScript,
  loading,
  refetch = window.location.reload,
}) => {
  if (error === undefined) return null;

  const { networkError } = error;

  const backtrace = isJavaScript
    ? error.stack?.split('\n')
    : get(networkError, 'result.error.backtrace');
  const networkErrorMessage = get(error, 'networkError.result.error.message');

  const errors = error.graphQLErrors;

  const showDetails =
    (errors || networkErrorMessage || backtrace) &&
    process.env.NODE_ENV !== 'production';

  return (
    <CKBannerGroup
      className={classnames('my-6 mx-10', className, {
        'mb-5': inline,
      })}
    >
      <CKBannerNext variant="error">
        <CKBannerNext.IconStatus>
          <Error />
        </CKBannerNext.IconStatus>
        <CKBannerNext.Header>Whoops, something went wrong.</CKBannerNext.Header>
        <CKBannerNext.Body>
          Try loading this page again. If you continue to experience this issue,{' '}
          <a href="mailto:support@clearbit.com">contact Clearbit support</a>.
        </CKBannerNext.Body>
        <CKBannerNext.CTA className="gap-4">
          {loading ? <Spinner height={20} width={20} /> : null}

          <CKButton
            isDisabled={loading}
            onClick={refetch}
            variant="bold"
            variantColor="red"
          >
            Try again
          </CKButton>
        </CKBannerNext.CTA>
      </CKBannerNext>
      {showDetails ? (
        <CKBannerNext variant="error">
          <CKBannerNext.Header className="flex items-start gap-4">
            <div className="flex-1 mb-4">
              <h3 className="mb-2 font-semibold">{networkErrorMessage}</h3>
              <p>{error.message}</p>
            </div>
            <CKBadge className="text-red-500 uppercase bg-transparent border border-red-500">
              Internal stack trace
            </CKBadge>
          </CKBannerNext.Header>

          <CKBannerNext.Body className="overflow-hidden">
            {backtrace ? (
              <pre className="min-w-0 overflow-auto font-mono text-xs break-all">
                {(backtrace as string[]).map((line: string, index: number) => (
                  <div key={`${line} ${!isJavaScript ? index : null}`}>
                    {line}
                  </div>
                ))}
              </pre>
            ) : null}
          </CKBannerNext.Body>
        </CKBannerNext>
      ) : null}
    </CKBannerGroup>
  );
};
