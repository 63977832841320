import { CKBox, CKButton, CKLayoutGrid, CKLayoutHorizontal } from 'clearkit';
import React from 'react';

import { usageHelpDeskLink } from './helpers';

type CreditUsageTileProps = {
  title: string;
  description: React.ReactNode;
  cta?: React.ReactNode;
};
export function CreditUsageTile({
  title,
  description,
  cta,
}: CreditUsageTileProps) {
  return (
    <CKLayoutGrid className="items-start ck-box" gridTemplateRows="1fr auto">
      <CKLayoutHorizontal className="p-6">
        <h3 className="text-base font-medium">{title}</h3>
        <div className="flex">
          {cta || (
            <CKButton
              href={usageHelpDeskLink}
              rel="no referrer"
              target="_blank"
            >
              Learn More
            </CKButton>
          )}
        </div>
      </CKLayoutHorizontal>
      <CKBox.Footer className="px-6 py-3 text-sm font-medium text-gray-500">
        <p>{description}</p>
      </CKBox.Footer>
    </CKLayoutGrid>
  );
}
