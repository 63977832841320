const styles = () => ({
  root: {
    userSelect: 'none',
    height: 58,
  },

  avatarColumn: {
    width: 105,
    flex: 'none',
    padding: '4px 24px',
  },

  cellLink: {
    color: '#5786D7',
    fontWeight: 500,
  },

  company: {
    display: 'flex',
    alignItems: 'center',
  },

  companyLogo: {
    minWidth: 24,
  },

  companyName: {
    marginLeft: 15,
    flex: '1 1 auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export default styles;
