import request from './request';

// Accounts

export function getAccounts() {
  return request({
    path: `/v1/accounts`,
  });
}

export function switchAccount(accountId: string) {
  return request({
    path: `/auth/switch_accounts`,
    type: 'post',
    json: { account_id: accountId },
  });
}

export function navigateToNewTeams() {
  window.location.href = 'https://dashboard.clearbit.com/team/new';
  return;
}

export function flushAccountData() {
  window.location.href = '/';
}
