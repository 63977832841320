import MuiTextField from '@material-ui/core/TextField';

import styles from './styles';

function TextField({ classes, onChange, readOnly, ...props }) {
  const { InputLabelProps, InputProps, ...otherProps } = props;

  const { classes: inputClasses, ...inputProps } = InputProps;

  return (
    <MuiTextField
      InputLabelProps={{
        ...InputLabelProps,
        classes: {
          root: classes.formLabelRoot,
        },
        shrink: false,
      }}
      InputProps={{
        classes: {
          input: classes.inputText,
          root: classes.inputRoot,
          ...inputClasses,
        },
        ...inputProps,
      }}
      onChange={readOnly ? null : onChange}
      readOnly={readOnly}
      {...otherProps}
    />
  );
}

TextField.propTypes = {
  classes: PropTypes.object,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

TextField.defaultProps = {
  variant: 'standard',
};

TextField.defaultProps = {
  InputProps: {},
};

export default withStyles(styles)(TextField);
