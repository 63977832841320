import { AddHollow as AddHollowIcon } from '@clearkit/icons';
import ThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { CKButton } from 'clearkit';

import DebugView from '~/components/Debug';
import LoadingArea from '~/components/LoadingArea';
import { getAndOrOr } from '~/components/SearchBuilder/utils/nodeUtils';
import {
  addIndexes,
  deleteIndexes,
  insertNode,
  SearchContext,
} from '~/components/SearchBuilder/utils/searchUtils';

import baseTheme from './baseTheme';
import AndOrDecorator from './components/AndOrDecorator';
import AnyQueryNode from './components/AnyQueryNode';
import styles from './styles';
import { emptyGroup as emptyGroupDefault } from './utils/fixtures';
import upgradeSearch from './utils/searchDeLegacification';
import validateSearch from './utils/validateSearch';

export class BaseSearchBuilder extends React.Component {
  state = {
    search: undefined,
    error: undefined,
    invalidNodes: [],
  };

  static getDerivedStateFromProps(props, state) {
    let search = undefined;
    let invalidNodes = undefined;

    if (props.search) {
      search = addIndexes(props.search);
      search = upgradeSearch(search, props.attributes);
      invalidNodes = validateSearch(search);
    }

    return {
      ...state,
      search,
      invalidNodes,
    };
  }

  // Search operations

  exportSearch = (search) => {
    const { disabled, onChange } = this.props;
    if (disabled) return;
    const invalidNodes = validateSearch(search);
    const sanitizedSearch = deleteIndexes(search);
    const valid = invalidNodes.length === 0;

    onChange(sanitizedSearch, valid);
    return sanitizedSearch;
  };

  render() {
    const {
      type,
      className,
      disabled,
      isSelectiveEnrichmentConfig,
      emptyGroup,
    } = this.props;
    const { search, invalidNodes } = this.state;

    if (!search) return <LoadingArea loading />;

    const {
      classes,
      attributes,
      salesforceUsers,
      showInvalidFields,
    } = this.props;

    const andOrOr = getAndOrOr(search);
    const nodes = search[andOrOr];
    const context = {
      salesforceUsers,
      invalidNodes: showInvalidFields ? invalidNodes : [],
      search,
      disabled,
    };
    const effectiveEmptyGroup = emptyGroup || emptyGroupDefault;
    return (
      <SearchContext.Provider value={context}>
        <ThemeProvider theme={baseTheme}>
          <div
            className={classnames(
              classes.searchBuilder,
              { [classes.disabled]: disabled },
              className,
            )}
          >
            <AndOrDecorator
              node={search}
              onChange={this.exportSearch}
              search={search}
            >
              {nodes.map((node) => (
                <AnyQueryNode
                  attributes={attributes}
                  emptyGroup={emptyGroup}
                  isSelectiveEnrichmentConfig={isSelectiveEnrichmentConfig}
                  key={node.__index}
                  node={node}
                  onChange={this.exportSearch}
                  search={search}
                  type={type}
                />
              ))}

              <div className={classes.actionButtons}>
                {!disabled && !isSelectiveEnrichmentConfig && (
                  <CKButton
                    isDisabled={disabled}
                    leftIcon={<AddHollowIcon />}
                    onClick={() => {
                      const newSearch = insertNode(
                        search,
                        search,
                        effectiveEmptyGroup,
                      );
                      this.exportSearch(newSearch);
                    }}
                  >
                    Add group
                  </CKButton>
                )}
              </div>
            </AndOrDecorator>
          </div>
          <DebugView debug={{ search: deleteIndexes(this.state.search) }} />
        </ThemeProvider>
      </SearchContext.Provider>
    );
  }
}

BaseSearchBuilder.defaultProps = {
  classes: {},
  onChange: () => {},
};

BaseSearchBuilder.propTypes = {
  type: PropTypes.oneOf(['Company', 'Person']),
  search: PropTypes.object,
  emptyGroup: PropTypes.object,
  classes: PropTypes.object,
  attributes: PropTypes.array,
  removeNode: PropTypes.func,
  updateNode: PropTypes.func,
  insertInto: PropTypes.func,
  onChange: PropTypes.func,
  showInvalidFields: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  salesforceUsers: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
  ),
  isSelectiveEnrichmentConfig: PropTypes.bool,
};

export { validateSearch };
export default withStyles(styles)(BaseSearchBuilder);
