import { CKButton, CKModal, CKTextFieldNext } from 'clearkit';

import { ButtonContainer } from '~/components/unified/ButtonContainer';

import { useProspector } from '../useProspector';

export function CreateProspectList() {
  const [state, send] = useProspector();

  const isOpen = state.matches('confirmingCreate') || state.matches('creating');

  const handleCancel = () => send('CANCEL');

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && state.context.nameEdit) {
      send('SAVE');
    } else if (event.key === 'Escape') {
      handleCancel();
    } else {
      return;
    }
  };

  return (
    <CKModal hasHideOnEscape isVisible={isOpen} onToggle={handleCancel}>
      <CKModal.Header>
        <CKModal.Heading>Save this list</CKModal.Heading>
      </CKModal.Header>
      <CKModal.Body className="p-8 space-y-4">
        <p>
          Give this list a name and we’ll save its filters so you can continue
          to discover new prospects.
        </p>
        <CKTextFieldNext
          autoFocus
          onChange={(value) => send('UPDATE_NAME', { value })}
          onKeyUp={handleKeyUp}
          placeholder="Name your list"
          value={state.context.nameEdit}
        />
      </CKModal.Body>
      <CKModal.Footer>
        <ButtonContainer>
          <CKButton
            isDisabled={state.matches('creating')}
            onClick={handleCancel}
          >
            Cancel
          </CKButton>
          <CKButton
            isDisabled={state.matches('creating')}
            onClick={() => send('SAVE')}
            variant="bold"
            variantColor="blue"
          >
            Save
          </CKButton>
        </ButtonContainer>
      </CKModal.Footer>
    </CKModal>
  );
}
