import {
  AudiencesNav,
  BatchNav,
  CompaniesNav,
  DashboardNav,
  FitCompany,
  FormsNav,
  Global,
  GoExternal,
  HelpNav,
  PeopleNav,
  Prospector,
  RevealBlock,
  SettingsNav,
  SubscriptionNav,
  UsageAltNav,
  Website,
  ZapAlt,
} from '@clearkit/icons';
import { CKNavigationBar } from 'clearkit';
import { FC, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { NavLink, RouteComponentProps } from 'react-router-dom';

import { routes } from '~/components/app/appMainRoutes';
import { useEntitlements } from '~/hooks/account';

import { WhenProspector } from '../policies/WhenProspector';
import { useTargetMarketsRoute } from '../TargetMarkets/hooks/useTargetMarketsRoute';
import { NavBarBottomMenu } from './NavBarBottomMenu';
import { NavRouterItem } from './NavRouterItem';

type NavBarProps = RouteComponentProps;

const LOCAL_STORAGE_KEY = 'clearbit-navIsExpanded';

const NavBar: FC<NavBarProps> = ({
  history: {
    location: { pathname, search },
  },
}) => {
  const {
    hasAccessAdvertising,
    hasAccessBatch,
    hasAccessCompanies,
    hasAccessEnrichmentHubspot,
    hasAccessFormsHubspot,
    hasAccessLookup,
    hasAccessPeople,
    hasAccessIntegrations,
    hasAccessPlans,
    hasAccessUsage,
  } = useEntitlements();
  const { targetMarketsRoute } = useTargetMarketsRoute();

  /**
   * Local storage and state for navigation bar isExpanded
   */
  const localStorageIsExpanded =
    localStorage?.getItem(LOCAL_STORAGE_KEY) !== 'false';
  const [isExpanded, setIsExpanded] = useState<boolean>(
    localStorageIsExpanded || true,
  );

  const handleExpandNavBar = () => {
    setIsExpanded(!isExpanded);
    localStorage.setItem(LOCAL_STORAGE_KEY, String(!isExpanded));
  };

  useEffect(() => {
    setIsExpanded(localStorageIsExpanded);
  }, []);

  const HomeLink = () => (
    <CKNavigationBar.Logo as={NavLink} label="Home" to={routes.home} />
  );
  HomeLink.displayName = 'CKNavigationBar.Logo';

  /**
   * The new free trial will grant platform access, but we still don't want to
   * show the navigation bar sometimes. For example, when updating their CRM settings in
   * grow.clearbit.com or during onboarding.
   * To make it flexible, pass ?navigation=hide in the URL.
   * */
  if (
    pathname.startsWith('/onboarding') ||
    pathname.startsWith(routes.getStarted) ||
    search.includes('navigation=hide')
  ) {
    return null;
  }

  const showFormsItem = hasAccessFormsHubspot && !hasAccessEnrichmentHubspot;
  const showLookupItem = hasAccessLookup && !hasAccessEnrichmentHubspot;

  return (
    <CKNavigationBar isExpanded={isExpanded} onToggle={handleExpandNavBar}>
      <HomeLink />

      <CKNavigationBar.Section>
        <NavRouterItem exact label="Home" to="/">
          <DashboardNav />
        </NavRouterItem>
      </CKNavigationBar.Section>

      {hasAccessEnrichmentHubspot ? (
        <CKNavigationBar.Section>
          <NavRouterItem
            isVisible={hasAccessEnrichmentHubspot}
            label="Enrichment"
            to={routes.enrichment}
          >
            <ZapAlt />
          </NavRouterItem>
          <NavRouterItem
            isVisible={hasAccessEnrichmentHubspot}
            label="Forms"
            to={routes.formsHubspot}
          >
            <FormsNav />
          </NavRouterItem>
        </CKNavigationBar.Section>
      ) : null}

      <CKNavigationBar.Section>
        <NavRouterItem label="Target Markets" to={targetMarketsRoute}>
          <FitCompany />
        </NavRouterItem>

        <NavRouterItem label="Visitors" to={routes.visitors}>
          <Website />
        </NavRouterItem>

        <WhenProspector>
          <NavRouterItem label="Prospector" to={routes.prospector}>
            <Prospector />
          </NavRouterItem>
        </WhenProspector>

        <NavRouterItem
          exact={false}
          isVisible={hasAccessLookup && hasAccessEnrichmentHubspot}
          label="Lookup"
          to={routes.lookup}
        >
          <RevealBlock />
        </NavRouterItem>

        <NavRouterItem
          isVisible={hasAccessPeople}
          label="People"
          to={routes.people}
        >
          <PeopleNav />
        </NavRouterItem>

        <NavRouterItem
          isVisible={hasAccessCompanies}
          label="Companies"
          to={routes.companies}
        >
          <CompaniesNav />
        </NavRouterItem>
      </CKNavigationBar.Section>

      {showFormsItem ||
      hasAccessIntegrations ||
      hasAccessBatch ||
      showLookupItem ? (
        <CKNavigationBar.Section>
          <NavRouterItem
            isVisible={showFormsItem}
            label="Forms"
            to={routes.formsHubspot}
          >
            <FormsNav />
          </NavRouterItem>
          <NavRouterItem
            isVisible={hasAccessIntegrations}
            label="Integrations"
            to={routes.integrations}
          >
            <ZapAlt />
          </NavRouterItem>

          <NavRouterItem
            isVisible={hasAccessBatch}
            label="Batch"
            to={routes.batch}
          >
            <BatchNav />
          </NavRouterItem>

          <NavRouterItem
            exact={false}
            isVisible={showLookupItem}
            label="Lookup"
            to={routes.lookup}
          >
            <RevealBlock />
          </NavRouterItem>
        </CKNavigationBar.Section>
      ) : null}

      <CKNavigationBar.Section>
        <NavRouterItem
          isVisible={hasAccessAdvertising}
          label="Advertising"
          to={routes.advertising}
        >
          <AudiencesNav />
        </NavRouterItem>
        <CKNavigationBar.Item
          href="https://chrome.google.com/webstore/detail/clearbit-connect-free-ver/pmnhcgfcafcnkbengdcanjablaabjplo"
          label="Connect"
          target="_blank"
        >
          <CKNavigationBar.ItemLeft>
            <Global />
          </CKNavigationBar.ItemLeft>
          <CKNavigationBar.ItemRight>
            <GoExternal />
          </CKNavigationBar.ItemRight>
        </CKNavigationBar.Item>
      </CKNavigationBar.Section>

      {hasAccessPlans || hasAccessUsage ? (
        <CKNavigationBar.Section>
          <NavRouterItem
            isVisible={hasAccessUsage}
            label="Usage"
            to={routes.usage}
          >
            <UsageAltNav />
          </NavRouterItem>

          <NavRouterItem
            exact={false}
            isVisible={hasAccessPlans}
            label="Credits & Subscription"
            to={routes.plans}
          >
            <SubscriptionNav />
          </NavRouterItem>
        </CKNavigationBar.Section>
      ) : null}
      <CKNavigationBar.Section>
        <NavRouterItem label="Settings" to={routes.settings}>
          <SettingsNav />
        </NavRouterItem>

        <CKNavigationBar.Item
          as="a"
          href={routes.support}
          label="Support"
          rel="noopener noreferrer"
          target="_blank"
        >
          <CKNavigationBar.ItemLeft>
            <HelpNav />
          </CKNavigationBar.ItemLeft>
        </CKNavigationBar.Item>
      </CKNavigationBar.Section>

      <NavBarBottomMenu />
    </CKNavigationBar>
  );
};

export default withRouter(NavBar);
