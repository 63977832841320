const styles = (_theme) => ({
  loader: {
    background: '#FFFFFF',
    borderRadius: 8,
    bottom: 48,
    boxShadow:
      '0 1px 3px 0 rgba(89, 97, 104, 0.15), 0 3px 15px 0 rgba(89, 97, 104, 0.20)',
    boxSizing: 'content-box',
    height: 32,
    left: '50%',
    margin: '0 0 0 -24px',
    padding: 18,
    position: 'absolute',
    verticalAlign: 'bottom',
    width: 32,
  },
});

export default styles;
