import { CKBox, CKChartArea, CKSliceTooltipProps } from 'clearkit';
import { capitalize } from 'lodash';
import moment from 'moment';

import { ChartEmptyOverlay } from '~/components/ChartEmptyOverlay';
import GraphqlError from '~/components/GraphqlError';
import LoadingArea from '~/components/LoadingArea';
import {
  SubscriptionBillingTermEnum,
  UsageBreakdownGranularityEnum,
  useUsageBreakdownQuery,
} from '~/generated/graphql';

import {
  backendDateFormat,
  tooltipTitleFormatter,
  xAxisFormatter,
  yAxisFormatter,
} from '../utils/formatHelpers';
import { gridYLinesBuilder } from '../utils/gridLinesHelper';
import { buildMarker } from '../utils/markerHelper';
import { formatBottomAxisValues } from './chartUtils';
import { UsageBreakDownDatum } from './types';

export type CumulativeUsageChartProps = {
  meteredUnit: string;
  usageLimit: number | null | undefined;
  startDate: any;
  endDate: any;
  usageTerm: SubscriptionBillingTermEnum;
  title?: string | null;
  usageMetricGraph: Record<string, unknown>;
  includeLimitMarker?: boolean;
  name: string;
  granularity?: UsageBreakdownGranularityEnum;
};

export default function CumulativeUsageChart({
  meteredUnit,
  usageLimit,
  startDate,
  endDate,
  usageTerm,
  usageMetricGraph,
  title,
  name,
  includeLimitMarker = true,
  granularity = UsageBreakdownGranularityEnum.Month,
}: CumulativeUsageChartProps) {
  const { data, loading, error, refetch } = useUsageBreakdownQuery({
    variables: {
      usageTerm: usageTerm,
      startDate: startDate,
      endDate: endDate,
      usageMetricGraph: usageMetricGraph,
      granularity: granularity,
    },
  });

  if (error || (!data?.usageBreakdown && !loading)) {
    return <GraphqlError error={error} loading={loading} refetch={refetch} />;
  }

  if (loading) {
    return <LoadingArea loading={loading} />;
  }

  const usageBreakdown =
    data?.usageBreakdown[name.toLowerCase()] || ([] as UsageBreakDownDatum[]);

  const now = moment(moment.now());

  let trendingMaxY = 0;

  const chartData = [
    {
      id: `Total ${meteredUnit}`,
      data: [
        {
          x: moment(usageBreakdown[0]?.startDate, backendDateFormat).format(
            backendDateFormat,
          ),
          y: 0,
        },
        ...usageBreakdown.map((d: any, index: any) => {
          const startDate = moment(d.startDate, backendDateFormat);
          let usage: null | number = null;

          if (now.isAfter(startDate, 'day')) {
            usage = d.cumulative;

            // Scale max Y based on highest cumulative value and overall progress toward
            // the end of the yearly billing term.
            trendingMaxY = Math.max(
              trendingMaxY,
              d.cumulative / ((index + 1) / 13),
            );
          }

          return {
            x: startDate.add(1, 'month').format(backendDateFormat),
            y: usage,
          };
        }),
      ],
    },
    {
      id: `${capitalize(meteredUnit)} this period`,
      data: [
        {
          x: moment(usageBreakdown[0]?.startDate, backendDateFormat).format(
            backendDateFormat,
          ),
          y: 0,
        },
        ...usageBreakdown.map((d: any) => {
          const startDate = moment(d.startDate, backendDateFormat);
          const y = now.isAfter(startDate, 'day') ? d.netNew : null;

          return {
            x: startDate.add(1, 'month').format(backendDateFormat),
            y,
          };
        }),
      ],
    },
  ];

  const maxValue = Math.max(trendingMaxY, (usageLimit || 0) * 1.1);
  const gridYLines = gridYLinesBuilder(maxValue);
  const showEmptyState = !usageBreakdown.filter(
    (datum: any) => datum.cumulative,
  ).length;
  const bottomAxisValues = formatBottomAxisValues({ chartData, usageTerm });

  return (
    <CKBox className="w-full p-4 pt-2 h-85" variant="card">
      <ChartEmptyOverlay showEmptyState={showEmptyState}>
        <CKChartArea
          axisBottom={{
            tickValues: bottomAxisValues,
          }}
          axisLeft={{
            tickValues: gridYLines,
          }}
          axisMarker={includeLimitMarker ? buildMarker(usageLimit) : undefined}
          chartColors={['dataVizE', 'dataVizE']}
          className="w-full h-full"
          data={chartData}
          enableArea
          enableGridY
          gridYValues={gridYLines}
          title={title || 'Cumulative Usage'}
          toolTipHideTotal
          toolTipShouldHideSlice={(slice: CKSliceTooltipProps['slice']) => {
            return slice.points[0].data.x === usageBreakdown[0]?.startDate;
          }}
          toolTipTitleFormat={(value) => tooltipTitleFormatter(value)}
          toolTipValueFormat={(value: string | number) => value}
          xValueFormat={xAxisFormatter}
          yScale={{
            type: 'linear',
            max: maxValue * 1.25,
          }}
          yValueFormat={yAxisFormatter}
        />
      </ChartEmptyOverlay>
    </CKBox>
  );
}
