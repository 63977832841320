const styles = () => ({
  destinationsCount: {
    background: '#9BADBC',
    borderRadius: '50%',
    margin: '0 0 0 6px',
    paddingLeft: 1,
    fontSize: 9,
    color: 'white',
    position: 'relative',
    top: -2,
    minWidth: '16px',
    lineHeight: '16px',
    display: 'inline-block',
    textAlign: 'center',
  },

  selectedDestinationsCount: {
    background: '#61A6FC',
  },

  segmentTabIcon: {
    // Hack until we refactor out and drop MUITabs
    top: '4px !important',
  },
});

export default styles;
