import { ApolloError, useMutation } from '@apollo/client';

import { useAccountConnectorConfigurationQuery } from '~/generated/graphql';
import {
  AccountConnectorService,
  configurations,
  syncInProgress,
} from '~/lib/configuration';
import { useFancyQuery } from '~/lib/graphql';
import {
  connectorLocations,
  trackDisconnectedAccountConnector,
} from '~/lib/segmentAnalytics';

import destroy from './AccountConnector/destroy.graphql';
import query from './AccountConnector/query.graphql';

const getServices = (service: AccountConnectorService) => {
  switch (service) {
    case configurations.salesforce:
      return [configurations.salesforce, configurations.salesforcesandbox];
    default:
      return [service];
  }
};

export type AccountConnector = Exclude<
  Exclude<
    ReturnType<typeof useAccountConnectorConfigurationQuery>['data'],
    undefined
  >['accountConnector'],
  undefined | null
>;

type UseAccountConnectorData = {
  refetch: ReturnType<typeof useFancyQuery>['refetch'];
  polling: boolean;
  handleDisconnect: () => Promise<void>;
  disconnectInProgress: boolean;
} & (
  | {
      accountConnector: AccountConnector;
      loading: false;
      error: undefined;
    }
  | {
      accountConnector: undefined;
      loading: true;
      error: undefined;
    }
  | {
      accountConnector: undefined;
      loading: false;
      error: ApolloError;
    }
);

export default function useAccountConnector(
  service: AccountConnectorService,
): UseAccountConnectorData {
  const pollInterval = ({ accountConnector } = { accountConnector: {} }) =>
    syncInProgress(accountConnector) ? 2_000 : false;

  const {
    data: { accountConnector } = {},
    loading,
    error,
    refetch,
    polling,
  } = useFancyQuery(query, {
    pollInterval,
    returnPartialData: true,
    variables: { service: getServices(service) },
    fetchPolicy: 'network-only', // Ensure that we never display stale data, which is especially important when we are making a new account connection
  } as any);

  const disconnectConfig = {
    ...(service === configurations.google && {
      refetchQueries: ['RevealGA'],
    }),
  };

  const [disconnectConnector, { loading: disconnectInProgress }] = useMutation(
    destroy,
    disconnectConfig,
  );

  const handleDisconnect = async () => {
    await disconnectConnector({
      variables: {
        input: { id: accountConnector.id },
      },
    });

    trackDisconnectedAccountConnector(
      accountConnector.service,
      connectorLocations.configuration,
    );

    refetch();
  };

  const retVal: Omit<
    UseAccountConnectorData,
    'accountConnector' | 'error' | 'loading'
  > = {
    refetch,
    polling,
    handleDisconnect,
    disconnectInProgress,
  };

  if (loading) {
    return {
      ...retVal,
      loading,
      accountConnector: undefined,
      error: undefined,
    };
  }

  if (error) {
    return {
      ...retVal,
      loading,
      accountConnector: undefined,
      error,
    };
  }

  return {
    ...retVal,
    accountConnector,
    loading,
    error,
  };
}
