// eslint-disable-next-line clearbit-x/no-tree-shaking-imports
import { fade, lighten } from '@material-ui/core/styles/colorManipulator';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

import caretIcon from '~/components/icons/CaretIcon';

const palette = {
  primary: {
    main: '#eff0f0',
    light: '#f9fafb',
    dark: '#3386EE',
  },

  secondary: {
    light: '#85BBFF',
    main: '#85BBFF',
    dark: '#85BBFF',
  },

  blue: {
    500: '#148CFC',
    400: '#2B9FFD',
    300: '#4DB1FD',
    200: '#98D5FD',
    0: '#F2FAFE',
  },

  graphs: {
    purple: 'purple',
    red: 'red',
    green: 'green',
    orange: 'orange',
    separatorLine: 'rgb(226, 233, 239)',
  },

  salesforce: {
    500: '#00A1E0',
    400: `${lighten('#00A1E0', 0.1)}`,
    300: `${lighten('#00A1E0', 0.2)}`,
    200: `${lighten('#00A1E0', 0.3)}`,
    100: `#EAF6FB`,
    0: '#F8FCFE',
  },

  hubspot: {
    500: '#FF7A59',
    400: `${lighten('#FF7A59', 0.1)}`,
    300: `${lighten('#FF7A59', 0.2)}`,
    200: `${lighten('#FF7A59', 0.3)}`,
    100: `#FFF5F2`,
    0: '#FFFBFA',
  },

  marketo: {
    500: '#5C4C9F',
    400: `#7793FF`,
    300: `#9CB2FF`,
    200: `#C2CFFF`,
    100: `#EAEEFF`,
    0: '#F7F8FF',
  },

  twitter: {
    500: '#1DA1F1',
    100: '#E0F3FD',
    0: '#F6FCFE',
  },

  linkedin: {
    500: '#0073B0',
    100: '#DEEDF5',
    0: '#F8FBFD',
  },

  facebook: {
    500: '#1877F2',
    100: '#DDECF6',
    0: '#F7FAFE',
  },

  error: {
    main: '#f44366',
  },

  notice: {
    main: '#976000',
    secondary: '#C98000',
  },

  text: {
    secondary: '#969BA1',
    errorHeading: '#B11E0E',
    error: '#B11E0E',
    link: '#148CFC',
    success: '#1DC463',
    linkHover: lighten('#148CFC', 0.2),
  },

  list: {
    label: '#7b8b99',
    value: '#27282a',
  },
};

const theme = createMuiTheme({
  body: {
    backgroundGrey: 'rgb(250, 251, 252)',
  },

  typography: {
    fontFamily: ['Avenir Next', 'Avenir', 'Helvetica', 'Arial', 'sans-serif'],
    useNextVariants: true,
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1441,
      xl: 1920,
    },
  },

  palette: { ...palette },

  editorStyles: {
    primary: palette.blue[500],
    secondary: palette.blue[400],
    tertiary: palette.blue[300],
    border: palette.blue[500],
    tint: palette.blue[0],
    background: `${lighten(palette.blue[0], 0.5)}`,
    shadow: `0 0 0 1px rgba(152, 213, 253, .5), 0 1px 2px 1px rgba(0, 0, 0, .08)`,
  },

  sidebar: {
    root: {
      width: 940,
      maxWidth: '80vw',
      minHeight: 0,
    },
  },

  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiToggleButton: {
      disableRipple: true,
    },
    MuiInput: {
      disableUnderline: true,
    },
    MuiSelect: {
      IconComponent: caretIcon,
    },
  },

  overrides: {
    MuiInputBase: {
      root: {
        position: 'relative',
        color: '#27282A',
        minHeight: '36px',
        fontSize: '15px',
        border: 'none',
        borderRadius: '6px',
        background: '#FFFFFF',
        lineHeight: '1.4',
        boxShadow: `inset 0 0 0 1px ${fade('#083864', 0.1)},
          inset 0 2px 2px 0 ${fade('#000', 0.05)},
          0 0 0 0px ${fade('#98D5FD', 0)}`,
        transition: 'box-shadow .2s ease',

        '&:hover': {
          boxShadow: `inset 0 0 0 1px ${fade('#083864', 0.2)},
            inset 0 2px 2px 0 ${fade('#000', 0.05)},
            0 0 0 2px ${fade('#98D5FD', 0)}`,
        },

        '&$disabled': {
          color: '#C0D0DF',
          background: '#FAFBFC',
          WebkitTextFillColor: '#C0D0DF',
          WebkitOpacity: 1,
        },

        '&$focused': {
          boxShadow: `inset 0 0 0 1px ${fade('#148CFC', 0.9)},
            inset 0 2px 2px 0 ${fade('#000', 0.05)},
            0 0 0 2px ${fade('#98D5FD', 0.5)}`,

          '&:hover': {
            boxShadow: `inset 0 0 0 1px ${fade('#148CFC', 0.9)},
              inset 0 2px 2px 0 ${fade('#000', 0.05)},
              0 0 0 2px ${fade('#98D5FD', 0.5)}`,
          },
        },

        '&$disabled:hover': {
          boxShadow: `inset 0 0 0 1px ${fade('#083864', 0.1)},
            inset 0 2px 2px 0 ${fade('#000', 0.05)},
            0 0 0 0px ${fade('#98D5FD', 0)}`,
        },
      },
      input: {
        padding: '7px 0',

        '&::-webkit-input-placeholder': {
          color: '#9BADBC',
          opacity: '1 !important',
          lineHeight: '1.4',
        },

        '&::-moz-placeholder': {
          color: '#9BADBC',
          opacity: '1 !important',
          lineHeight: '1.4',
        },

        '&:-ms-input-placeholder': {
          color: '#9BADBC',
          opacity: '1 !important',
          lineHeight: '1.4',
        },

        '&::placeholder': {
          color: '#9BADBC',
          opacity: '1 !important',
          lineHeight: '1.4',
        },
      },
      error: {
        boxShadow: `inset 0 0 0 1px ${fade('#EA3927', 0.8)},
          inset 0 2px 2px 0 ${fade('#000', 0.05)},
          0 0 0 0px ${fade('#EA3927', 0)}`,

        '&:hover': {
          boxShadow: `inset 0 0 0 1px ${fade('#EA3927', 0.8)},
            inset 0 2px 2px 0 ${fade('#000', 0.05)},
            0 0 0 0px ${fade('#EA3927', 0)}`,
        },

        '&$focused': {
          boxShadow: `inset 0 0 0 1px ${fade('#EA3927', 0.8)},
            inset 0 2px 2px 0 ${fade('#000', 0.05)},
            0 0 0 2px ${fade('#EA3927', 0.2)}`,
        },
      },
      multiline: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },

    MuiInput: {
      input: {
        position: 'relative',
        paddingLeft: '8px',
        paddingRight: '8px',
        height: '100%',
        boxSizing: 'border-box',

        '&[readonly]': {
          color: '#7B8B99',
          padding: '6px 7px',
          marginLeft: '1px',
          marginTop: '1px',
          height: 'calc(100% - 2px)',
          width: 'calc(100% - 2px)',
          borderRadius: '5px',
          background: '#FAFBFC',
        },
      },
      inputMultiline: {
        paddingTop: '8px',
        paddingBottom: '8px',

        '&[readonly]': {
          paddingTop: '7px',
          paddingBottom: '7px',
          marginTop: '1px',
          marginBottom: '1px',
        },
      },
    },

    MuiSelect: {
      root: {
        margin: '1px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '6px',
        background: '#FFFFFF',
        boxShadow: `0 2px 2px 0 ${fade('#000', 0.05)}`,

        '&$disabled': {
          color: '#C0D0DF',
          background: '#FAFBFC',
          borderRadius: '5px',
        },
      },
      select: {
        height: '100%',
        paddingRight: '24px',

        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      selectMenu: {
        display: 'flex',
        width: '100%',
        height: '34px',
        alignItems: 'center',
      },
      icon: {
        color: 'inherit',
        top: 'calc(50% - 5px)',
        right: 8,
      },
    },

    MuiInputLabel: {
      root: {
        display: 'flex',
      },

      formControl: {
        position: 'static',
        display: 'flex',
        transform: 'none',
      },

      marginDense: {
        transformOrigin: 'top left',
        transform: 'none',
      },

      shrink: {
        transform: 'translate(0, 7px) scale(1)',
      },

      animated: {
        transition: 'none',
      },

      outlined: {
        transform: 'unset',
        zIndex: 'auto',
      },
    },

    MuiFormLabel: {
      root: {
        color: '#596168',
        fontSize: 15,

        '&$focused': {
          color: '#85BBFF',
        },
      },
    },

    MuiFormHelperText: {
      root: {
        color: '#596168',
        fontSize: 15,
        marginTop: 0,
        pointerEvents: 'none',
        height: 20,
      },
    },

    MuiFormControlLabel: {
      label: {
        color: '#596168',
        fontSize: 14,
        fontWeight: 'normal',
      },
    },

    MuiTabs: {
      root: {
        backgroundColor: 'transparent',
        minHeight: 49,
      },
    },

    MuiTab: {
      root: {
        minWidth: 105,
        height: 48,
        minHeight: 48,
        fontSize: '15px',
        color: '#B2B8C2',
        textTransform: 'unset',
        '&:hover': {
          color: '#BBC2CD',
        },

        '&$selected': {
          color: palette.primary.dark,
          '&:hover': {
            color: palette.primary.dark,
          },
        },
      },

      label: {
        fontSize: 15,
      },
    },

    MuiTabIndicator: {
      root: {
        display: 'none',
      },
    },

    MuiButton: {
      root: {
        letterSpacing: '1.1px',
        fontSize: '13px',
        fontWeight: '600',
      },

      textPrimary: {
        backgroundColor: '#5188e7',

        '&:hover': {
          backgroundColor: '#5188e7',
          opacity: 0.8,
        },
      },

      containedPrimary: {
        minHeight: 38,
        backgroundImage: 'linear-gradient(-180deg, #338AF6 0%, #6C86D9 96%)',
        boxShadow: '0 1px 2px 0 #C6D2E0',
        borderRadius: 7,
        color: '#FFFFFF',
        fontWeight: 600,
        transition: 'all 0.15s ease',
        padding: '8px 25px',
        textTransform: 'uppercase',

        '&:hover': {
          opacity: 0.9,
          transform: 'translateY(-1px)',
          boxShadow:
            '0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)',
          backgroundColor: 'unset',
        },

        '&$disabled': {
          background: '#eff0f0',
          color: '#969BA1',
        },
      },

      textSecondary: {
        fontSize: 15,
        color: '#A9B0B8',
        textTransform: 'none',
        fontWeight: '400',
        backgroundColor: 'transparent',

        '&:hover': {
          backgroundColor: 'transparent',
        },

        '&$disabled': {
          backgroundColor: 'transparent',
        },
      },

      fab: {
        width: '45px',
        height: '45px',
        boxShadow:
          '0 1px 2px 0 rgba(52,88,133,0.32), 0 2px 8px 0 rgba(52,88,133,0.10)',
        backgroundImage: 'linear-gradient(-180deg, #338AF6 0%, #6C86D9 96%)',
      },
    },

    MuiSwitch: {
      icon: {
        backgroundImage: 'linear-gradient(-180deg, #bcc5cf 0%, #9ea6ae 100%)',
        boxShadow: '0 1px 2px 0 rgba(60,64,74,0.29)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '&:after': {
          content: '""',
          display: 'block',
          width: '8px',
          height: '8px',
          borderRadius: '50%',
          backgroundColor: '#FFF',
        },
      },

      bar: {
        backgroundColor: '#dbdfe4',
        borderRadius: 42,
        opacity: 1,
      },

      root: {
        '&$checked': {
          '& + $bar': {
            backgroundColor: '#7baef6',
            opacity: 1,
          },

          '& $icon': {
            backgroundImage:
              'linear-gradient(-180deg, #3589f5 0%, #6b86d8 100%)',
          },
        },
      },
    },

    MuiCheckbox: {
      root: {
        marginRight: '8px',
      },

      colorPrimary: {
        '&$checked': {
          color: '#9ea6ae',
        },
      },
    },

    MuiRadio: {
      root: {
        marginRight: '8px',
        '&$checked': {
          color: '#9ea6ae',
        },
      },
    },

    MuiTypography: {
      h1: {
        fontSize: 30,
        color: '#414953',
        letterSpacing: 0,
      },

      h2: {
        fontSize: 22,
        color: '#414953',
        letterSpacing: 0,
        fontWeight: 'normal',
      },

      subtitle1: {
        fontSize: 13,
        fontWeight: 600,
        textTransform: 'uppercase',
        color: '#414953',
        letterSpacing: '1.71px',
        margin: 0,
      },

      gutterBottom: {
        marginBottom: 27,
      },
    },

    MuiCircularProgress: {
      colorPrimary: {
        color: '#85BBFF',
      },
    },

    MuiSvgIcon: {
      root: {
        '& + span': {
          marginLeft: 5,
        },
      },
    },

    MuiList: {
      root: {},
      padding: {
        overflow: 'hidden',
        paddingTop: 0,
        paddingBottom: 0,
      },
    },

    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,.02)',
        },
      },
    },

    MuiListItemIcon: {
      root: {
        display: 'inline-flex',
        flex: '0 0 18px',
        justifyContent: 'center',
        marginRight: 10,
      },
    },

    MuiDivider: {
      root: {
        backgroundColor: 'rgb(229, 233, 236)',
      },
    },

    MuiMenuItem: {
      root: {
        borderTop: '1px solid rgb(211, 213, 215)',
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,0.14)',
        },
      },
    },

    MuiDialog: {
      paper: {
        padding: '10px 0px',
        borderRadius: 7,
        background: '#FFFFFF',
        boxShadow:
          '0 1px 2px 0 rgba(110,114,121,0.08), 0 4px 11px 0 rgba(73,85,105,0.09)',
      },

      paperWidthSm: {
        maxWidth: 490,
      },
    },

    MuiDialogContent: {
      root: {
        paddingLeft: 30,
        paddingRight: 30,
        paddingBottom: 30,
      },
    },

    MuiDialogTitle: {
      root: {
        padding: '24px 30px 10px',
        fontWeight: 600,
        fontSize: 24,
        color: '#27282A',
      },
    },

    MuiDialogActions: {
      root: {
        padding: '15px 24px 5px',
        margin: '0px 0 0 4px',
        boxShadow: '0 -1px 0 0 rgba(39,40,42,0.10)',
      },
      action: {
        margin: '0 0 0 20px',
      },
    },

    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(191,195,200,0.23)',
      },
    },

    MuiIconButton: {
      root: {
        color: '#B2B8C2',
        '&:hover': {
          backgroundColor: 'transparent',
          color: '#BBC2CD',
        },
      },
    },

    MuiPaper: {
      rounded: {
        borderRadius: 6,
      },
    },

    MuiPopover: {
      paper: {
        boxShadow:
          '0 1px 3px 0 rgba(89,97,104,0.15), 0 3px 15px 0 rgba(89,97,104,0.20)',
      },
    },

    MuiDrawer: {
      paper: {
        overflowX: 'visible',
        overflowY: 'visible',
      },
    },

    MuiTableRow: {
      hover: {
        cursor: 'pointer',
        transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderBottom: '1px solid ##e1e9f0',

        '&:hover': {
          backgroundColor: 'rgba(0,0,0,.02)',
        },
      },
    },

    MuiTableCell: {
      root: {
        borderBottom: '1px solid #F3F4F5',
      },

      typeHead: {
        fontSize: 12,
        fontWeight: 600,
        textTransform: 'uppercase',
        color: '#414953',
        letterSpacing: '1.71px',
        margin: 0,
      },

      typeBody: {
        fontSize: 15,
        color: '#969BA1',
      },
    },

    MuiTooltip: {
      tooltip: {
        borderRadius: 8,
        fontSize: '14px',
        backgroundColor: 'rgba(59,67,83,0.96)',
        boxShadow:
          '0px 1px 1.5px 0 rgba(59,64,67,0.16), 0px 5.5px 16px 0 rgba(59,64,67,0.1)',
        padding: '10px 15px',
        color: '#FFF',
        textAlign: 'center',
      },
    },

    MuiExpansionPanel: {
      root: {
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
    },

    MuiExpansionPanelSummary: {
      root: {
        padding: 0,
        minHeight: 'unset',
      },

      content: {
        fontWeight: 600,
        fontSize: 12,
        letterSpacing: '1px',
        textTransform: 'uppercase',
      },

      expanded: {
        minHeight: 'unset',
        margin: '12px 0',
      },
    },

    MuiExpansionPanelDetails: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
      },
    },

    MuiStepIcon: {
      completed: {
        fill: '#5786D7',
      },
    },

    MuiToggleButtonGroup: {
      root: {
        overflow: 'auto',
        borderRadius: 8,
        height: 36,
        boxShadow: 'none',
        border: 'solid 1px #eff2f4',

        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 'auto',

        '&$selected': {
          boxShadow: 'none',
          background: 'transparent',
        },

        '&&': {
          border: 'none',
          height: 'auto',
          padding: '16px 24px',
          boxShadow: 'none',
        },

        // DN: Because MuiToggleButton sets custom backgrounds below, there was a border
        // issue where the background was covering up the corners of our border. The
        // selectors below ensure that this doesn't happen.
        '& a:last-of-type': {
          '& button': {
            borderRadius: '0 6px 6px 0',
          },
        },

        '& a:first-of-type': {
          '& button': {
            borderRadius: '6px 0 0 6px',
          },
        },
      },
    },

    MuiToggleButton: {
      root: {
        backgroundColor: 'white',
        color: '#b3bdc8',
        fontSize: 13,
        padding: '8px 16px 8px 16px',
        fontWeight: 600,
        textTransform: 'unset',
        height: '100%',

        '&&': {
          margin: '0 8px',
          borderRadius: 8,
          boxShadow: `0 0 0 1px ${fade('#083864', 0.05)},
            0 2px 2px 0 ${fade('#000', 0.05)}`,
          '&:focus': {
            outline: 'none',
            boxShadow: '0 0 0 4px rgba(152,213,253,.4)',
          },
          color: '#27282a',
          fontWeight: 'normal',
        },

        '&&$disabled': {
          opacity: 0.3,
        },

        '&$selected': {
          color: 'white',
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: 'white',
          },
          '&:after': {
            opacity: 0,
          },
          '&&': {
            boxShadow:
              '0px 0px 0px 1px rgba(89, 97, 104, 0.05), 0px 1px 3px 0px rgba(89, 97, 104, 0.15), 0px 3px 15px 0px rgba(89, 97, 104, 0.2)',
            border: 'none',
          },
        },

        '&&:hover': {
          background: '#FAFBFC',
          boxShadow: `0 0 0 1px ${fade('#083864', 0.1)},
            0 2px 4px 0 ${fade('#000', 0.1)}`,
        },
      },
    },
  },

  flexNone: {
    flex: 'none',
  },

  flex: {
    display: 'flex',
  },

  flexAuto: {
    flex: '1 1 auto',
    minWidth: 0,
    minWeight: 0,
  },

  flexStretch: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
  },

  flexColumn: {
    flexDirection: 'column',
  },

  justifyContent: {
    justifyContent: 'center',
  },

  boxGroup: {
    background: '#fafbfc',
    border: '1px solid rgba(89, 97, 104, 0.15)',
    borderRadius: 8,
    margin: '0 0 25px 0',
    padding: '26px 24px 24px 24px',
  },

  box: {
    background: '#ffffff',
    border: 'none',
    borderRadius: 6,
    boxShadow:
      '0 0 0 1px rgba(107, 144, 192, .15), 0 1px 2px 1px rgba(0, 0, 0, .05)',
  },

  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

export default theme;
