import { ConfigConnectedIndicators as ConfigConnected } from '@clearkit/icons';
import { CKBox, CKButton } from 'clearkit';
import { Link } from 'react-router-dom';

import Logo from '~/components/ConfigurationLogo';
import paths from '~/lib/paths';

export default function AccountConnectorAction({ accountConnector }) {
  if (!accountConnector) {
    return null;
  }

  return (
    <CKBox
      className="flex flex-row items-center px-4 py-3"
      variant={CKBox.Variant.card}
    >
      <div className="flex items-center justify-center w-8 h-8 rounded-full bg-green-0">
        <ConfigConnected height={16} width={24} />
      </div>
      <div className="px-4 border-r border-gray-100">
        <p className="text-sm font-medium">Active Connection</p>
        <p className="flex flex-row items-center text-sm text-gray-600">
          <Logo
            className="mr-1"
            height={18}
            type={accountConnector.service}
            width={18}
          />
          {accountConnector.remoteMeta?.name}
        </p>
      </div>
      <CKButton
        as={Link}
        className="ml-4 mr-2"
        to={paths.configuration(accountConnector.service)}
        variant="tertiary"
        variantColor="blue"
      >
        Manage
      </CKButton>
    </CKBox>
  );
}
