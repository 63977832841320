import { createContext, useEffect, useState } from 'react';

import { useCrmPresenceQuery } from '~/generated/graphql';

import { useHomeIntegrations } from '../home/hooks/useHomeIntegrations';

type CRMPresence = Record<string, boolean>;
type SetDomainPresenceParams = {
  domain: string;
  isPresent: boolean;
};

type CRMPresenceContextType = {
  crmPresence: CRMPresence;
  isHubSpotConnected: boolean;
  isDomainPresent: (domain: string) => boolean;
  isLoading: boolean;
  setDomainPresence?: (params: SetDomainPresenceParams) => void;
};

export const CRMPresenceContext = createContext<CRMPresenceContextType>({
  crmPresence: {},
  isHubSpotConnected: false,
  isDomainPresent: () => false,
  isLoading: false,
});

export const useCRMPresence = (domains: string[] = []) => {
  const { isHubSpotConnected, loadingAccount } = useHomeIntegrations();
  const [crmPresence, setCrmPresence] = useState<CRMPresence>({});
  const { data, loading: loadingCrmPresence } = useCrmPresenceQuery({
    variables: {
      domains,
    },
    skip: domains.length === 0,
  });

  useEffect(() => {
    const newCrmPresence = data?.crmPresence?.nodes?.reduce(
      (acc: any, curr: any) => {
        return {
          ...acc,
          [curr.id]: true,
        };
      },
      {},
    );

    setCrmPresence((previousValue) => ({
      ...previousValue,
      ...newCrmPresence,
    }));
  }, [data?.crmPresence]);

  const isDomainPresent = (domain: string) => {
    return crmPresence?.[domain] ?? false;
  };

  const setDomainPresence = ({
    domain,
    isPresent,
  }: SetDomainPresenceParams) => {
    setCrmPresence((previousValue) => ({
      ...previousValue,
      [domain]: isPresent,
    }));
  };

  const isLoading = loadingCrmPresence || loadingAccount;

  return {
    crmPresence,
    isDomainPresent,
    isHubSpotConnected,
    isLoading,
    setDomainPresence,
  };
};
