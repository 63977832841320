const styles = () => ({
  moreMenu: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',

    '& svg *': {
      fill: '#596168',
    },
  },
});

export default styles;
