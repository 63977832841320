import { EmailAlt, Linkedin, Phone } from '@clearkit/icons';
import classnames from 'classnames';
import { CKContainerProps, CKLayoutHorizontal } from 'clearkit';

import { Token, TokenGroup } from '~/components/ListItem/Tokens';

type ProspectTokensProps = CKContainerProps & {
  hasLinkedIn?: boolean;
  hasPhone?: boolean;
  hasEmail?: boolean;
};

export const ProspectTokens = ({
  className,
  hasEmail,
  hasLinkedIn,
  hasPhone,
  ...rest
}: ProspectTokensProps) => (
  <CKLayoutHorizontal
    {...rest}
    alignItems="center"
    className={classnames(
      'divide-x divide-gray-200 basis-auto shrink-0 grow min-h-[1.5rem]',
      className,
    )}
    gap="0.75rem"
    justifyContent="start"
  >
    <TokenGroup>
      <Token hasToken={hasLinkedIn} title="Prospect has LinkedIn">
        <Linkedin
          className={classnames({
            'text-linkedin': hasLinkedIn,
          })}
        />
      </Token>
      <Token hasToken={hasPhone} title="Prospect has a phone number">
        <Phone />
      </Token>
      <Token hasToken={hasEmail} title="Prospect has an email address">
        <EmailAlt />
      </Token>
    </TokenGroup>
  </CKLayoutHorizontal>
);
