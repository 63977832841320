import {
  ClearbitIdentity as Logo,
  Lock,
  Signout as SignOut,
  Team,
} from '@clearkit/icons';
import { CKBox, CKButton, CKTag } from 'clearkit';
import { Subscribe } from 'unstated';

import CompanyLogo from '~/components/CompanyLogo';
import LoadingArea from '~/components/LoadingArea';
import UserContainer from '~/containers/User';

const accountCanAccessPlatform = (account) =>
  account.permissions.can_access_platform;

const NoAccess = ({
  accountDomain,
  accounts,
  currentAccountId,
  email,
  switchAccount,
}) => {
  const renderAccount = (account) => {
    const canAccessAccount = accountCanAccessPlatform(account);
    const isCurrentAccount = account.id === currentAccountId;

    return (
      <div
        className="flex items-center justify-between p-4 text-sm border-t border-gray-100 gap-4"
        key={account.id}
      >
        <div
          className={classnames('flex item-start gap-2', {
            'text-gray-500': !canAccessAccount,
            'text-gray-900': canAccessAccount,
          })}
        >
          {!canAccessAccount ? (
            <Lock className="flex-shrink-0" />
          ) : (
            <Team className="text-xs" />
          )}
          {account.name}
          <div>
            {isCurrentAccount ? (
              <CKTag className="flex-shrink-0 bg-gray-500">Current</CKTag>
            ) : null}
          </div>
        </div>
        <div className="self-start flex-grow-0 flex-shrink-0 w-32 text-right">
          {!isCurrentAccount ? (
            <div>
              {canAccessAccount ? (
                <CKButton
                  onClick={() => switchAccount(account.id)}
                  size="small"
                  variant="bold"
                  variantColor="blue"
                >
                  Switch to team
                </CKButton>
              ) : (
                <span className="italic font-normal text-gray-500">
                  No access
                </span>
              )}
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const hasAccessInAnotherTeam = accounts.find(accountCanAccessPlatform);
  const currentTeam =
    currentAccountId &&
    accounts.length > 0 &&
    accounts.find((account) => account.id === currentAccountId).name;

  return (
    <div className="flex flex-col max-w-md m-auto font-medium align-center gap-8">
      <header className="text-center">
        <Logo
          className="self-center mx-auto mb-2 mb-6"
          height={48}
          width={48}
        />
        <h2 className="mb-2 text-xl font-semibold">
          {hasAccessInAnotherTeam
            ? 'Switch teams to access Clearbit'
            : 'Request access to the Clearbit platform'}
        </h2>
        <p className="max-w-sm m-auto text-sm text-gray-600">
          Your current team, <span className="text-black">{currentTeam}</span>,
          does not have access to the Clearbit platform.
        </p>
      </header>

      <CKBox variant="card">
        <header className="flex items-center justify-between p-4 text-sm bg-gray-0 gap-2 rounded-t-md">
          <div className="flex items-center text-black gap-4">
            <CompanyLogo domain={accountDomain} size={38} />
            {email}
          </div>
          <CKButton as="a" href="/logout" rightIcon={<SignOut />} size="small">
            Sign out
          </CKButton>
        </header>
        {accounts.map(renderAccount)}
      </CKBox>

      <footer className="text-sm text-center text-gray-600">
        <p className="mb-4">
          {hasAccessInAnotherTeam
            ? 'Switch to a team with access to continue or contact '
            : 'Contact '}
          <a
            href="mailto:contactsales@clearbit.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            Clearbit Sales
          </a>{' '}
          to request access
          {hasAccessInAnotherTeam
            ? ' for your current team.'
            : ' to the Clearbit platform.'}
        </p>
        <p>
          If you believe you should already have access with the current team,
          please email{' '}
          <a
            href="mailto:customersuccess@clearbit.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            customersuccess@clearbit.com
          </a>{' '}
          for help.
        </p>
      </footer>
    </div>
  );
};

NoAccess.propTypes = {
  accountDomain: PropTypes.string.isRequired,
  currentAccountId: PropTypes.string.isRequired,
  email: PropTypes.string,
  domainForAccount: PropTypes.func.isRequired,
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      xAccess: PropTypes.bool,
    }),
  ),
};

const WrappedNoAccess = (props) => (
  <Subscribe to={[UserContainer]}>
    {(userContainer) => {
      const {
        accountDomain,
        domainForAccount,
        currentAccountId,
        switchAccount,
        state: { accounts, currentUser },
      } = userContainer;

      return (
        <LoadingArea loading={!currentUser}>
          <NoAccess
            accountDomain={accountDomain()}
            accounts={accounts}
            currentAccountId={currentAccountId()}
            domainForAccount={domainForAccount}
            email={currentUser && currentUser.email}
            switchAccount={switchAccount}
            {...props}
          />
        </LoadingArea>
      );
    }}
  </Subscribe>
);

export default WrappedNoAccess;
