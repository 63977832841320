import { Email } from '@clearkit/icons';
import { CKBannerNext } from 'clearkit';

import { AuthBannerProps } from './bannerTypes';

type BannerLinkProps = AuthBannerProps & {
  email?: string | null;
};

export const BannerLink = ({ email, isVisible }: BannerLinkProps) => {
  if (!isVisible) return null;

  const EmailText = () =>
    email ? (
      <strong className="font-semibold">{email}</strong>
    ) : (
      <span>this email</span>
    );

  return (
    <CKBannerNext className="w-full" variant="info">
      <CKBannerNext.IconStatus>
        <Email />
      </CKBannerNext.IconStatus>
      <CKBannerNext.Body>
        If we have an account for <EmailText />, a secure sign in link has been
        sent. Check your email.
      </CKBannerNext.Body>
    </CKBannerNext>
  );
};
