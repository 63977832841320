import { GoExternal } from '@clearkit/icons';
import classnames from 'classnames';
import {
  CKBox,
  CKButton,
  CKContainerProps,
  CKLayoutHorizontal,
  CKProgressBar,
  CKProgressBarVariant,
} from 'clearkit';
import Maybe from 'graphql/tsutils/Maybe';
import { capitalize } from 'lodash';
import { Link } from 'react-router-dom';

import { routes } from '~/components/app/appMainRoutes';
import { AccountFlagEnum, ProductFeatureEnum } from '~/generated/graphql';
import { useFeatureFlag } from '~/hooks/account';

import { CurrentPeriodTotalUsage } from '../../CreditUsageTile/helpers';
import {
  CreditBasedUsageOverviewDataFormatter,
  formatNumberCommas,
} from '../../utils/formatHelpers';
type BillingUsageMeterCardProps = CKContainerProps & {
  name: string;
  meteredUnit: string;
  documentationUrl: string;
  currentPeriodTotalUsage: CurrentPeriodTotalUsage;
  usageLimit?: number | null;
  id: ProductFeatureEnum;
  detailedCard?: boolean;
  usageTerm?: string;
  includeDetailLink?: boolean;
  currentPeriodStartDate?: string;
  currentPeriodEndDate?: string;
};

export const BillingUsageMeterCard = ({
  className,
  name,
  meteredUnit,
  documentationUrl,
  usageLimit,
  id,
  currentPeriodTotalUsage,
  detailedCard,
  usageTerm,
  includeDetailLink = true,
  currentPeriodStartDate,
  currentPeriodEndDate,
}: BillingUsageMeterCardProps) => {
  const variant = (
    used: number,
    total: Maybe<number>,
  ): CKProgressBarVariant => {
    if (!total || used < total) return 'info';
    if (used >= total) return 'warning';

    return 'info';
  };

  const { enabled: isLearnMoreLinkEnabled } = useFeatureFlag(
    AccountFlagEnum.XUsageUiDisplayLearnMoreLinks,
  );

  const totalUsage = currentPeriodTotalUsage['total']?.credits || 0;
  const detailsUrl = `${routes.usage}/${id}`;

  return (
    <CKBox className={classnames('p-6', className)}>
      <header className="mb-6 space-y-2">
        <h3 className="text-base font-medium">{name}</h3>
        <CKProgressBar
          className="mb-6"
          max={usageLimit || Infinity}
          value={totalUsage}
          variant={variant(totalUsage, usageLimit)}
        />
      </header>
      <div className="text-gray-600 space-y-4">
        <ProgressBarSubtitle
          currentPeriodTotalUsage={totalUsage}
          detailedCard={detailedCard || false}
          meteredUnit={meteredUnit}
          usageLimit={usageLimit}
          usageTerm={usageTerm as string}
        />
        {detailedCard ? (
          <PlanDetails
            currentPeriodEndDate={currentPeriodEndDate as string}
            currentPeriodStartDate={currentPeriodStartDate as string}
            usageTerm={usageTerm as string}
          />
        ) : null}
      </div>
      {includeDetailLink ? (
        <CKLayoutHorizontal className="mt-6">
          <CKButton as={Link} className="p-0" to={detailsUrl}>
            Details
          </CKButton>
          {isLearnMoreLinkEnabled ? (
            <CKButton
              as={Link}
              rightIcon={<GoExternal />}
              to={documentationUrl}
              variant="tertiary"
              variantColor="blue"
            >
              Learn more
            </CKButton>
          ) : null}
        </CKLayoutHorizontal>
      ) : null}
    </CKBox>
  );
};

type PlanDetailsProps = {
  usageTerm: string;
  currentPeriodStartDate: string;
  currentPeriodEndDate: string;
};

function PlanDetails({
  usageTerm,
  currentPeriodStartDate,
  currentPeriodEndDate,
}: PlanDetailsProps) {
  return (
    <>
      <p>
        <strong>Plan start</strong>{' '}
        {CreditBasedUsageOverviewDataFormatter(currentPeriodStartDate)}
      </p>
      <p>
        <strong>Plan Period</strong> {capitalize(usageTerm)}
      </p>
      <p>
        <strong>Credits reset</strong>{' '}
        {CreditBasedUsageOverviewDataFormatter(currentPeriodEndDate)}
      </p>
    </>
  );
}

type ProgressBarSubtitleProps = {
  detailedCard: boolean;
  currentPeriodTotalUsage: number;
  usageLimit?: number | null;
  meteredUnit: string;
  usageTerm: string;
};

function ProgressBarSubtitle({
  detailedCard,
  currentPeriodTotalUsage,
  usageLimit,
  meteredUnit,
  usageTerm,
}: ProgressBarSubtitleProps) {
  return (
    <>
      {detailedCard ? (
        <>
          <p>
            <strong>{formatNumberCommas(usageLimit)}</strong> {meteredUnit}{' '}
            available {usageTerm}
          </p>
          <p>
            <strong>{formatNumberCommas(currentPeriodTotalUsage)}</strong>{' '}
            {meteredUnit} consumed
          </p>
          <p>
            <strong>
              {usageLimit
                ? formatNumberCommas(usageLimit - currentPeriodTotalUsage)
                : 'unlimited'}
            </strong>{' '}
            {meteredUnit} remaining
          </p>
        </>
      ) : (
        <p className="mt-3 text-sm font-normal text-gray-600">
          {`${formatNumberCommas(currentPeriodTotalUsage)} of ${
            usageLimit ? formatNumberCommas(usageLimit) : 'unlimited'
          } ${formatNumberCommas(meteredUnit)} used`}{' '}
        </p>
      )}
    </>
  );
}
