const attributeTypes = {
  KEYWORD_STRING: 'keyword_string',
  MULTI_COLUMN_STRING: 'multi_column_string',
  STRING: 'string',
  NUMBER: 'number',
  EXISTENCE_BOOLEAN: 'existence_boolean',
  BOOLEAN: 'boolean',
  SELECT: 'select',
  MULTI_SELECT: 'multi_select',
  DATETIME: 'datetime',
  NESTED_SELECT: 'nested_select',
};

export default attributeTypes;
