const styles = () => ({
  disclaimerContainer: {
    display: 'flex',
    alignItems: 'center',

    '@media (max-width: 780px)': {
      flexDirection: 'column',
    },
  },

  disclaimer: {
    color: 'rgb(155, 173, 188)',
    fontSize: '14px',

    '@media (max-width: 780px)': {
      textAlign: 'center',
    },
  },

  disclaimerLink: {
    color: '#9badbc',
    textDecoration: 'underline',
  },

  disclaimerIcon: {
    height: 22,
    marginRight: 8,

    '@media (max-width: 780px)': {
      margin: '0 0 8px 0',
    },
  },
});

export default styles;
