import { Error } from '@clearkit/icons';
import {
  CKBannerNext,
  CKButton,
  CKIconBox,
  CKLayoutHorizontal,
  CKLayoutVertical,
} from 'clearkit';

import Logo from '~/components/ConfigurationLogo';
import SecurityDisclaimer from '~/components/ConfigurationSecurityDisclaimer';
import useAuthenticateHubspot from '~/hooks/authentication/useAuthenticateHubspot';
import {
  Configuration,
  configurationNameForType,
  configurations,
  purposeForConnectorService,
} from '~/lib/configuration';

import G2ConnectionForm from './G2ConnectionForm';
import GoogleAdsConnectionForm from './GoogleAdsConnectionForm';
import GoogleConnectionForm from './GoogleConnectionForm';
import MarketoConnectionForm from './MarketoConnectionForm';
import PardotConnectionForm from './PardotConnectionForm';
import SalesforceConnectionForm from './SalesforceConnectionForm';

const documentationLinks = {
  [configurations.slack]:
    'https://help.clearbit.com/hc/en-us/articles/360022030174-Slack-Destination',
  [configurations.google]:
    'https://help.clearbit.com/hc/en-us/articles/360035758434-Google-Remarketing-Destination',
  [configurations.facebook]:
    'https://help.clearbit.com/hc/en-us/articles/360033672274-Facebook-Retargeting-Audience-Destination',
  [configurations.hubspot]:
    'https://help.clearbit.com/hc/en-us/articles/4420179541911',
  [configurations.salesforce]:
    'https://help.clearbit.com/hc/en-us/articles/360010028354-Syncing-your-Salesforce-data-with-Clearbit',
  [configurations.salesforcesandbox]:
    'https://help.clearbit.com/hc/en-us/articles/360010028354-Syncing-your-Salesforce-data-with-Clearbit',
  [configurations.pardot]:
    'https://help.clearbit.com/hc/en-us/articles/360010028354-Syncing-your-Salesforce-data-with-Clearbit',
};

type ConnectionFormProps = {
  auth: (service: any) => void;
  wasOpenedExternallyAsPopup: boolean;
  onSuccess: () => void;
  service: Configuration;
};

const ConnectionForm = ({
  service,
  auth,
  onSuccess,
  wasOpenedExternallyAsPopup,
}: ConnectionFormProps) => {
  const purpose = purposeForConnectorService(service);
  const name = configurationNameForType(service);
  const isSalesforceService =
    service === configurations.salesforce ||
    service === configurations.salesforcesandbox;
  const isPardotService = service === configurations.pardot;

  const isGoogleService = service === configurations.google;
  const isGoogleAdsService = service === configurations.googleAds;

  const isHubspotService = service === configurations.hubspot;
  const isMarketoService = service === configurations.marketo;
  const isG2Service = service === configurations.g2;

  const { error, errorMessage, connect } = useAuthenticateHubspot({
    onSuccess,
  });

  function doAuth() {
    if (isHubspotService) {
      connect();
    } else {
      auth(service);
    }
  }

  const dividerClasses = 'border-t border-gray-200 w-full my-6';

  return (
    <CKLayoutVertical className="h-full max-w-[1000px]" gap="1.5rem">
      {error ? (
        <CKBannerNext className="w-full" variant="error">
          <CKBannerNext.IconStatus>
            <Error />
          </CKBannerNext.IconStatus>
          <CKBannerNext.Header>Error Connecting to HubSpot</CKBannerNext.Header>
          <CKBannerNext.Body>{errorMessage}</CKBannerNext.Body>
        </CKBannerNext>
      ) : null}

      <CKLayoutVertical alignItems="center" className="w-full h-full">
        <CKIconBox>
          <Logo style={{ width: 40, height: 40 }} type={service} />
        </CKIconBox>

        {(() => {
          if (isSalesforceService) {
            return (
              <div className="space-y-6">
                <SalesforceConnectionForm
                  onAuth={(salesforceService: any) => auth(salesforceService)}
                />
                <hr className={dividerClasses} />
                <SecurityDisclaimer />
              </div>
            );
          }

          if (isPardotService) {
            return <PardotConnectionForm auth={auth} onSuccess={onSuccess} />;
          }

          if (isGoogleService) {
            return <GoogleConnectionForm onAuth={auth} />;
          }

          if (isGoogleAdsService) {
            return <GoogleAdsConnectionForm onAuth={auth} />;
          }

          if (isMarketoService) {
            return <MarketoConnectionForm onSuccess={onSuccess} />;
          }

          if (isG2Service) {
            return <G2ConnectionForm />;
          }

          return (
            <>
              <div className="mb-4 text-center text-gray-600 max-w-[25rem]">
                Connect your {name} account to automatically start {purpose}.{' '}
                {documentationLinks[
                  service as keyof typeof documentationLinks
                ] && (
                  <>
                    Head to our {name} sync{' '}
                    <a
                      className="ck-link"
                      href={
                        documentationLinks[
                          service as keyof typeof documentationLinks
                        ]
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      documentation
                    </a>{' '}
                    to learn more.
                  </>
                )}
              </div>

              <CKLayoutHorizontal justifyContent="center">
                <CKButton onClick={doAuth} variant="bold" variantColor="blue">
                  <span>Connect {name}</span>
                </CKButton>

                {wasOpenedExternallyAsPopup && (
                  <CKButton
                    onClick={() => {
                      // since the window was opened remotely, window.close() doesn't work
                      // opener should work, but there are many edge cases so calling both opener and parent
                      // https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
                      (window.opener || window.parent)?.postMessage(
                        'close',
                        '*',
                      );
                    }}
                    variant="simple"
                    variantColor="blue"
                  >
                    Cancel and close window
                  </CKButton>
                )}
              </CKLayoutHorizontal>

              {isHubspotService && (
                <>
                  <hr className={dividerClasses} />
                  <SecurityDisclaimer className="text-sm text-gray-600" />
                </>
              )}
            </>
          );
        })()}
      </CKLayoutVertical>
    </CKLayoutVertical>
  );
};

export default ConnectionForm;
