import {
  ClearbitIdentity,
  FitCompany,
  G2Services as G2,
  HubspotServices as Hubspot,
  MarketoServices,
  SalesforceServices as Salesforce,
  Trait,
  View,
} from '@clearkit/icons';
import capitalize from 'lodash/capitalize';

import { AttributeCategory, AttributeSection } from './types';

export function getIcon(category: AttributeCategory) {
  switch (category) {
    case 'clearbit':
      return ClearbitIdentity;
    case 'fit score':
      return FitCompany;
    case 'g2':
      return G2;
    case 'hubspot':
      return Hubspot;
    case 'page view':
    case 'page_views':
      return View;
    case 'salesforce':
      return Salesforce;
    case 'marketo':
      return MarketoServices;
    case 'traits':
      return Trait;
    case 'target_market':
      return FitCompany;
    default:
      return ClearbitIdentity;
  }
}

export function getAttributeLabel(category: AttributeCategory) {
  switch (category) {
    case 'clearbit':
      return 'Clearbit';
    case 'fit score':
      return 'Fit Score';
    case 'g2':
      return 'G2';
    case 'hubspot':
      return 'HubSpot';
    case 'page view':
    case 'page_views':
      return 'Page Views';
    case 'salesforce':
      return 'Salesforce';
    case 'traits':
      return 'Traits';
    case 'target_market':
      return 'Target Markets';
    default:
      return getAttributeLabelFallback(category);
  }
}

export function getAttributeLabelFallback(category?: string) {
  return category
    ?.split(' ')
    .map((substr) => capitalize(substr))
    .join(' ');
}

export function getAttributeSectionHeader(category: AttributeSection) {
  switch (category) {
    case 'page view':
    case 'page_views':
      return 'Page View attributes';
    case 'salesforce->account':
      return 'Salesforce Account attributes';
    case 'salesforce->contact':
      return 'Salesforce Contact attributes';
    case 'salesforce->lead':
      return 'Salesforce Lead attributes';
    case 'salesforce->opportunity':
      return 'Salesforce Opportunity attributes';
    case 'hubspot->contact':
      return 'HubSpot Contact';
    case 'hubspot->company':
      return 'HubSpot Company';
    case 'hubspot->deal':
      return 'HubSpot Deal';
    case 'traits':
      return 'Custom Traits';
    case 'target_market':
      return 'Target Markets';
    default:
      return `${getAttributeLabel(category)} attributes`;
  }
}
