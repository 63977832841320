import classnames from 'classnames';
import { CKContainerProps, CKLayoutGrid } from 'clearkit';

import { FauxCheckbox } from './FauxCheckbox';

type ListItemProps = Omit<CKContainerProps, 'onSelect'> & {
  isSelected: boolean;
  onSelect?: () => void;
};

export const ListItemCheckbox = ({
  id,
  children,
  className,
  isSelected,
  onSelect,
}: ListItemProps) => {
  return (
    <label
      className={classnames(
        className,
        'ck-box relative px-4 py-3 ck-focus-ring hover:border-blue hover:bg-blue-50 cursor-pointer',
        '@container/list',
        {
          'bg-transparent': !isSelected,
        },
      )}
      htmlFor={id}
    >
      <input className="sr-only" id={id} onChange={onSelect} type="checkbox" />
      <CKLayoutGrid
        className="flex flex-nowrap gap-6 @item/list:grid @item/list:gap-4"
        gridTemplateColumns="auto 1fr auto"
        gridTemplateRows="auto"
      >
        <FauxCheckbox checked={isSelected} className="min-w-0 mt-4 shrink-0" />
        {children}
      </CKLayoutGrid>
    </label>
  );
};
