const ContentBox = ({ classes, children }) => {
  return <div className={classes.contentBox}>{children}</div>;
};

ContentBox.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node.isRequired,
};

export default withStyles(
  () => ({
    contentBox: {
      background: '#fff',
      border: '1px solid rgba(89, 97, 104, 0.15)',
      borderRadius: 8,
      margin: '24px 0',
      padding: '20px 30px 30px',

      '& hr': {
        borderColor: 'inherit',
      },
    },
  }),
  {
    name: 'ContentBox',
  },
)(ContentBox);
