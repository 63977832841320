import classnames from 'classnames';
import {
  CKCompanyLogo,
  CKContainerProps,
  CKIconBox,
  CKLayoutGridAuto,
} from 'clearkit';

import { AddHubSpotError } from '~/components/AddHubSpot/AddHubSpotError';
import { ListItem } from '~/components/ListItem/ListItem';
import { ListItemNumber } from '~/components/ListItem/ListItemNumber';
import { AVATAR_IMAGE_SIZE } from '~/components/prospector/ProspectList/constants';
import { LoadingListItem } from '~/components/prospector/ProspectList/LoadingList';
import { EMPTY_LABEL } from '~/lib/constants';

import { TargetMarketsCompany } from '../types';
import { CompanyLinks } from './CompanyLinks';

type CompanyItemProps = {
  company: TargetMarketsCompany;
  loading: boolean;
  number: number;
};

const Field = ({ className, children }: CKContainerProps) => {
  return (
    <p
      className={classnames(
        'text-xs text-gray-600 truncate whitespace-nowrap',
        className,
      )}
    >
      {children}
    </p>
  );
};

export const CompanyItem = ({ company, loading, number }: CompanyItemProps) => {
  const { country, domain, employeesRange, industry, name } = company;

  return loading ? (
    <LoadingListItem />
  ) : (
    <ListItem alignItems="start" className="px-4 py-3 ck-box">
      <ListItemNumber className="pt-3">{number}</ListItemNumber>
      <CKIconBox className="!p-1 shrink-0">
        <CKCompanyLogo
          className="shrink-0"
          domain={domain ?? ''}
          size={AVATAR_IMAGE_SIZE}
        />
      </CKIconBox>
      <CKLayoutGridAuto
        autoRepeat="auto-fit"
        className="shrink grow"
        minWidth="10rem"
      >
        <div>
          <h3 className="text-sm font-medium truncate whitespace-nowrap">
            {name}
          </h3>
          <Field>{country || 'Unknown Country'}</Field>
        </div>
        <div>
          <Field>{industry || EMPTY_LABEL}</Field>
          {employeesRange ? <Field>{employeesRange} employees</Field> : null}
        </div>
      </CKLayoutGridAuto>
      <div>
        <CompanyLinks className="mt-2 shrink-0" company={company} />
        <AddHubSpotError />
      </div>
    </ListItem>
  );
};
