import SvgIcon from '~/components/icons/SvgIcon';

const ClearIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      d="M8,1 C11.785442,1 14.8690983,4.00479289 14.995941,7.75935084 L15,8 C15,11.7854417 11.9952071,14.8690983 8.24064916,14.995941 L8,15 C4.13401667,15 1,11.8659833 1,8 C1,4.13401667 4.13401667,1 8,1 Z M4.68638279,4.68650902 C4.43789117,4.93520652 4.43786955,5.33844635 4.68633507,5.58716962 L7.1028735,8.00590031 L4.69555349,10.415533 C4.45069675,10.6678065 4.4565186,11.0709957 4.70855674,11.3160791 C4.95550922,11.5562188 5.34850616,11.5561631 5.59538902,11.3159536 L8.00264555,8.90641803 L10.4134622,11.3194535 C10.6652191,11.5648029 11.068018,11.5594181 11.3131398,11.3074263 C11.5536804,11.0601428 11.5536385,10.6660714 11.3130447,10.4188403 L8.90238695,8.00593213 L11.322422,5.58370155 C11.565944,5.3301615 11.5580143,4.92702985 11.3047107,4.68327958 C11.0584305,4.44628883 10.6691193,4.44628883 10.4228404,4.68327961 L8.00264644,7.10528745 L5.58620339,4.68655678 C5.33773783,4.43783319 4.93487409,4.43781155 4.68638279,4.68650902 Z"
      fill="#C0D0DF"
      fillRule="evenodd"
    />
  </SvgIcon>
);

ClearIcon.defaultProps = {
  width: 16,
  height: 16,
};

ClearIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default ClearIcon;
