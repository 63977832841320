export default () => ({
  root: {
    background: '#FAFBFC',
    padding: 24,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 13,
    color: '#596168',
    textAlign: 'center',

    '& p': {
      maxWidth: 380,
      marginBottom: 0,
      '&:last-of-type': {
        marginBottom: 24,
      },
    },
  },

  title: {
    fontWeight: 500,
    fontSize: 14,
    color: '#596168',
    textAlign: 'center',
  },
});
