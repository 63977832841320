import { CKAxisMarker } from 'clearkit';

type limit = number | null | undefined;
type formatter = CKAxisMarker['axisMarkerValueFormat'];

export function buildMarker(
  limit: limit,
  formatter?: formatter,
): CKAxisMarker | undefined {
  if (!limit) return undefined;

  return {
    axis: 'y',
    value: limit,
    axisMarkerValueFormat: formatter ?? ((value: string | number) => value),
  };
}
