import castArray from 'lodash/castArray';
import uniq from 'lodash/uniq';
import CreatableSelect from 'react-select/creatable';

import selectStyles, { errorStyles, selectTheme } from './styles';

export default class ClearbitCreatableSelect extends React.Component {
  render() {
    const { styles, splitOnPaste, className, error, ...props } = this.props;

    return (
      <div
        className={className}
        onPaste={(e) => {
          if (splitOnPaste) {
            e.preventDefault();
            const { onChange, value } = props;
            const arrayValue = castArray(value);
            const text = e.clipboardData.getData('text');
            let newValues = uniq(text.split('\n'));
            newValues = newValues.map((value) => ({ label: value, value }));
            onChange([...arrayValue, ...newValues], {
              action: 'create-option',
            });
          }
        }}
      >
        <CreatableSelect
          styles={{ ...selectStyles, ...styles, ...(error && errorStyles) }}
          theme={selectTheme}
          {...props}
        />
      </div>
    );
  }
}

ClearbitCreatableSelect.customizedStyles = selectStyles;

ClearbitCreatableSelect.defaultProps = {
  splitOnPaste: true,
};

ClearbitCreatableSelect.propTypes = {
  splitOnPaste: PropTypes.bool,
  styles: PropTypes.object,
  className: PropTypes.string,
  error: PropTypes.bool,
};
