import { routes } from '~/components/app/appMainRoutes';
import { settingsRoutes } from '~/components/settings/settingsRoutes';

import { useHomeIntegrations } from '../hooks/useHomeIntegrations';
import { OnboardingChecklist } from './OnboardingChecklist';
import { OnboardingItem, OnboardingItemDivider } from './OnboardingItem';

export const ListForms = () => {
  const {
    isTagConnected,
    hasFormsAllowedSites,
    hasFormsAutoFill,
    hasFormsFieldMappings,
    loadingClearbitJs,
    loadingForms,
  } = useHomeIntegrations();

  const isLoading = loadingClearbitJs || loadingForms;

  return (
    <OnboardingChecklist title="Get started with Forms">
      <OnboardingItem
        isComplete={!!isTagConnected}
        isLoading={isLoading}
        number={1}
        title="Connect your website"
        to={settingsRoutes.tagsJs}
      />
      <OnboardingItemDivider />
      <OnboardingItem
        isComplete={hasFormsFieldMappings}
        isDisabled={!isTagConnected}
        isLoading={isLoading}
        number={2}
        title="Set up form field mappings"
        to={routes.formsHubspot}
      />
      <OnboardingItemDivider />
      <OnboardingItem
        isComplete={hasFormsAllowedSites}
        isDisabled={!isTagConnected}
        isLoading={isLoading}
        number={3}
        title="Add allowed sites"
        to={routes.formsHubspot}
      />
      <OnboardingItemDivider />
      <OnboardingItem
        isComplete={hasFormsAutoFill}
        isDisabled={!isTagConnected}
        isLoading={isLoading}
        number={4}
        title="Enable form field autofill"
        to={routes.formsHubspot}
      />
    </OnboardingChecklist>
  );
};
