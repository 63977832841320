export const dollarFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const cleanDate = (date: string) => {
  return new Date(date.replace(/-/g, '/'));
};

export const formatDate = (date: string | Date) => {
  let dateCleaned;

  if (typeof date === 'string') {
    dateCleaned = new Date(cleanDate(date));
  } else {
    dateCleaned = date;
  }

  return new Date(dateCleaned).toLocaleDateString('en-us', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
};
