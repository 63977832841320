import { CKButton } from 'clearkit';
import { FC } from 'react';

import AuthLayout, { AuthLayoutHeader } from '~/components/layouts/AuthLayout';

import { AuthBox } from '../AuthBox';
import { BannerError, BannerLink } from '../Banners';
import TextFieldWithValidations from '../shared/TextFieldWithValidations';
import useStartMagicLinkFlow, {
  buildErrorMessage,
  emailIsValid,
} from '../shared/useStartMagicLinkFlow';

const Login: FC = function() {
  const {
    createMagicLink,
    email,
    error,
    formHandler,
    isCreatingMagicLink,
    setEmail,
    sentEmail,
    validationErrors,
  } = useStartMagicLinkFlow();

  return (
    <AuthLayout>
      <AuthLayoutHeader
        header={
          <>
            <span className="font-semibold">Welcome back,&nbsp;</span>
            <span>sign in to continue to Clearbit.</span>
          </>
        }
      />

      <form className="w-full" onSubmit={formHandler}>
        <AuthBox className="gap-6">
          <BannerLink email={sentEmail} isVisible={!!sentEmail} />
          <BannerError isVisible={!!error}>
            {buildErrorMessage(error)}
          </BannerError>
          <TextFieldWithValidations
            autoComplete="email"
            id="email"
            name="email_address"
            onChange={setEmail}
            placeholder="Enter your email address"
            type="email"
            validationErrors={validationErrors}
            value={email}
          >
            <TextFieldWithValidations.Label>
              Email
            </TextFieldWithValidations.Label>
          </TextFieldWithValidations>
          <CKButton
            className="self-start"
            isDisabled={!emailIsValid(email)}
            isLoading={isCreatingMagicLink}
            onClick={createMagicLink}
            variant="bold"
            variantColor="blue"
          >
            Send me a sign in link
          </CKButton>
        </AuthBox>
      </form>

      <div>
        <div className="text-sm text-center text-gray-600">
          <span>Trouble signing in?</span>
          <a
            className="pl-2 font-medium text-blue-600 hover:text-blue-500"
            href="https://help.clearbit.com/hc/en-us/articles/18895106621463-How-to-log-in-to-Clearbit"
          >
            Check out our Help Center article
          </a>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Login;
