import castArray from 'lodash/castArray';

import attributeTypes from '~/components/SearchBuilder/utils/attributeTypes';

import upgradeMultiStringFields from './multiStringFields';
import {
  changeComparator,
  changeValue,
  getAttribute,
  getComparator,
  getValue,
} from './nodeUtils';
import { mapSearch } from './searchUtils';

export default function upgradeSearch(search, attributes) {
  // Multi-value string fields didn't happen at the same time as V2.
  search = upgradeMultiStringFields(search, attributes);
  search = updateMultiSelectColumns(search, attributes);
  return search;
}

export function updateMultiSelectColumns(search, attributes) {
  search = mapSearch(search, (node) => {
    const attribute = getAttribute(node, attributes);
    if (!attribute) return node;
    if (attribute.details.type === attributeTypes.SELECT) {
      const comparator = getComparator(node);
      const comparatorMapping = {
        array_contains: 'array_contains',
        array_not_contains: 'array_not_contains',
        match: 'match_any',
        not_match: 'not_match_any',
        exists: 'exists',
        not_exists: 'not_exists',
      };
      const newComparator = comparatorMapping[comparator];
      if (newComparator) {
        node = changeComparator(node, newComparator, attributes);
      }

      // These types of comparators require an array value
      const arrayComparators = [
        'array_contains',
        'array_not_contains',
        'match_any',
        'not_match_any',
      ];

      if (arrayComparators.includes(getComparator(node))) {
        const newValue = castArray(getValue(node));
        node = changeValue(node, newValue);
      }
    }
    return node;
  });
  return search;
}
