import classnames from 'classnames';

const Footer = ({
  classes,
  children,
  left,
  right,
  secondary,
  isBottomSticky = true,
}) => {
  const footerClasses = classnames(
    {
      'flex flex-row items-center': true,
      'z-10 sticky bottom-0 px-10 py-6 mt-auto bg-white border-t border-gray-200': isBottomSticky,
    },
    classes,
  );

  return (
    <footer className={footerClasses}>
      {left && <div className="mr-auto">{left}</div>}
      <div className="flex flex-row items-center ml-auto">
        {secondary && (
          <div className="flex">
            {secondary}
            <div className="mx-6 border-r border-gray-200"></div>
          </div>
        )}

        <div className="flex items-center space-x-3">{right}</div>
      </div>
      {children}
    </footer>
  );
};

Footer.propTypes = {
  classes: PropTypes.object,
  isBottomSticky: PropTypes.bool,
  left: PropTypes.node,
  right: PropTypes.node.isRequired,
  secondary: PropTypes.node,
};

export default Footer;
