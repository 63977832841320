import { Error, SubscriptionNav, Warning } from '@clearkit/icons';
import { CKBannerNext, CKLayoutGridAuto } from 'clearkit';

import { PageLayout } from '~/components/PageLayout';
import { AccountFlagEnum } from '~/generated/graphql';
import { useFeatureFlag } from '~/hooks/account';
import { useCreditUsage } from '~/hooks/account/useCreditUsage';

import { BillingCurrentPlan } from './BillingCurrentPlan';
import { BillingManagePlan } from './BillingManagePlan';
import { NoUpgradeCTA } from './NoUpgradeCTA';
import { CurrentPlanContext, useCurrentPlan } from './useCurrentPlan';

export const PageCredits = () => {
  const { isPaidAccount } = useCreditUsage();
  const {
    currentPlan,
    pendingPlanChange,
    setCurrentPlan,
    setPendingPlanChange,
  } = useCurrentPlan();

  const showInvoiceFailedBanner = useFeatureFlag(
    AccountFlagEnum.LastInvoiceFailed,
  ).enabled;

  const lastInvoiceSucceeded = useFeatureFlag(
    AccountFlagEnum.LastInvoiceSucceeded,
  ).enabled;
  const billDirectlyToStripe = useFeatureFlag(
    AccountFlagEnum.RolloutBillDirectlyToStripe,
  ).enabled;

  return (
    <PageLayout>
      <PageLayout.Header>
        <PageLayout.Tile>
          <SubscriptionNav />
        </PageLayout.Tile>
        <PageLayout.Heading>Credits</PageLayout.Heading>
        <PageLayout.SubHeading>
          Manage your account's Clearbit plan and available credits
        </PageLayout.SubHeading>
      </PageLayout.Header>
      <PageLayout.Body>
        <PageLayout.Content className="max-w-[75rem] space-y-8">
          {!billDirectlyToStripe && showInvoiceFailedBanner ? (
            <CKBannerNext className="width-full" variant="error">
              <CKBannerNext.IconStatus>
                <Error />
              </CKBannerNext.IconStatus>
              <CKBannerNext.Body>
                An error occurred when processing your payment. Please re-enter
                your card information and try again.
              </CKBannerNext.Body>
            </CKBannerNext>
          ) : null}

          {!billDirectlyToStripe &&
          !showInvoiceFailedBanner &&
          !lastInvoiceSucceeded &&
          isPaidAccount ? (
            <CKBannerNext className="width-full" variant="warning">
              <CKBannerNext.IconStatus>
                <Warning />
              </CKBannerNext.IconStatus>
              <CKBannerNext.Body>
                Invoice has not yet been processed. You will receive email
                confirmation shortly after processing and your credit limit will
                be increased.
              </CKBannerNext.Body>
            </CKBannerNext>
          ) : null}
          <CurrentPlanContext.Provider
            value={{
              currentPlan,
              pendingPlanChange,
              setCurrentPlan,
              setPendingPlanChange,
            }}
          >
            <CKLayoutGridAuto
              autoRepeat="auto-fit"
              className="!items-start @container/credits"
              gap="2rem"
              minWidth="24rem"
            >
              <BillingCurrentPlan className="z-50 @[50rem]/credits:sticky top-6" />
              <div className="space-y-4">
                <NoUpgradeCTA />
                <BillingManagePlan />
              </div>
            </CKLayoutGridAuto>
          </CurrentPlanContext.Provider>
        </PageLayout.Content>
      </PageLayout.Body>
    </PageLayout>
  );
};
