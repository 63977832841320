import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, VariableSizeList } from 'react-window';
import ReactWindowInfiniteLoader from 'react-window-infinite-loader';

import LoadingArea from '~/components/LoadingArea';

import Loader from './Loader';

export default function InfiniteLoader({
  dynamicHeight,
  emptyBody,
  hasNextPage,
  loading,
  loadingMore,
  listRef,
  nodes,
  onLoadMore,
  rowHeight,
  rowRenderer,
}) {
  const itemCount = nodes.length;
  const isEmpty = itemCount === 0;
  const isItemLoaded = (index) => !hasNextPage || index < itemCount;
  const loadMoreCount = hasNextPage ? itemCount + 1 : itemCount;
  const List = dynamicHeight ? VariableSizeList : FixedSizeList;

  return (
    <LoadingArea empty={isEmpty} emptyBody={emptyBody} loading={loading}>
      <AutoSizer>
        {({ height, width }) => (
          <ReactWindowInfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={loadMoreCount}
            loadMoreItems={onLoadMore}
            threshold={25}
          >
            {({ onItemsRendered, ref }) => {
              return (
                <List
                  height={height}
                  itemCount={itemCount}
                  itemSize={rowHeight}
                  onItemsRendered={onItemsRendered}
                  ref={(innerRef) => {
                    if (listRef) listRef.current = innerRef;
                    ref(innerRef);
                  }}
                  width={width}
                >
                  {({ index, style }) =>
                    rowRenderer(nodes[index], index, style)
                  }
                </List>
              );
            }}
          </ReactWindowInfiniteLoader>
        )}
      </AutoSizer>
      <Loader loading={loadingMore} />
    </LoadingArea>
  );
}

InfiniteLoader.defaultProps = {
  dynamicHeight: false,
  emptyBody: 'Nothing to show',
  rowHeight: 58,
  threshold: 15,
};

InfiniteLoader.propTypes = {
  batchSize: PropTypes.number,
  dynamicHeight: PropTypes.bool,
  emptyBody: PropTypes.node,
  hasNextPage: PropTypes.bool,
  listRef: PropTypes.object,
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  nodes: PropTypes.array,
  onLoadMore: PropTypes.func,
  rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  rowRenderer: PropTypes.func.isRequired,
  threshold: PropTypes.number,
};
