import { ATTRIBUTE_CATEGORY_ICON_WIDTH } from './constants';
import { AttributeCategory } from './types';
import { getIcon } from './util';

type AttributeCategoryIconProps = {
  category: AttributeCategory;
} & JSX.IntrinsicElements['svg'];
export function AttributeCategoryIcon({
  category,
  ...props
}: AttributeCategoryIconProps) {
  const Icon = getIcon(category);

  return <Icon width={ATTRIBUTE_CATEGORY_ICON_WIDTH} {...props} />;
}
