import { FC } from 'react';

import AuthLayout, { AuthLayoutHeader } from '~/components/layouts/AuthLayout';

const SignupsBlocked: FC = function() {
  return (
    <AuthLayout>
      <AuthLayoutHeader
        header={
          <div className="flex flex-col">
            <span>The HubSpot portal you</span>
            <span>selected is not associated</span>
            <span>with a Clearbit account</span>
          </div>
        }
      />

      <div className="text-center">
        <p className="pb-6">
          The HubSpot portal you chose does not have a Clearbit account
          associated with it. Free Clearbit accounts are not being created at
          this time.
        </p>

        <p>
          If you’re trying to sign in to an existing Clearbit account using your
          HubSpot credentials, choose the HubSpot portal connected to your
          Clearbit account.
        </p>
      </div>

      <div className="flex text-sm text-center text-gray-600 gap-6">
        <div className="text-sm text-center text-gray-600">
          <a
            className="font-medium text-blue-600 hover:text-blue-500"
            href="/auth/oauth/hubspot/start"
          >
            Choose a different HubSpot portal
          </a>
        </div>

        <div>
          <a
            className="font-medium text-blue-600 hover:text-blue-500"
            href="mailto:support@clearbit.com"
          >
            Contact support@clearbit.com
          </a>
        </div>
      </div>
    </AuthLayout>
  );
};

export default SignupsBlocked;
