import MuiMenuItem from '@material-ui/core/MenuItem';

const styles = {
  root: {
    height: 10,
    fontSize: 14,
    fontWeight: 500,
    paddingRight: 30,
    '& svg': {
      fill: 'currentColor',
    },
  },

  primary: {
    border: 'none',
    color: '#596168',
    '&:hover': {
      color: '#596168',
      backgroundColor: 'rgb(248, 250, 252)',
    },
  },

  destructive: {
    border: 'none',
    color: '#C44A36',
    '&:hover': {
      color: '#C44A36',
      backgroundColor: 'rgb(248, 250, 252)',
    },
  },

  large: {
    height: 14,
  },
};

class MenuItem extends React.Component {
  classNameForVariant = () => {
    const { variant, classes } = this.props;

    switch (variant) {
      case 'primary':
        return classes.primary;
      case 'destructive':
        return classes.destructive;
      default:
        return classes.primary;
    }
  };

  render() {
    const {
      children,
      classes,
      large,
      variant: _variant,
      ...props
    } = this.props;

    return (
      <MuiMenuItem
        classes={{
          root: classnames(classes.root, this.classNameForVariant(), {
            [classes.large]: large,
          }),
        }}
        {...props}
      >
        {children}
      </MuiMenuItem>
    );
  }
}

MenuItem.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  disableGutters: PropTypes.bool,
  large: PropTypes.bool,
  role: PropTypes.string,
  selected: PropTypes.bool,
  tabIndex: PropTypes.number,
  variant: PropTypes.string,
};

export default withStyles(styles)(MenuItem);
