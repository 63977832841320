import { PeopleNav, UsageAltNav } from '@clearkit/icons';
import classnames from 'classnames';
import { CKBox, CKButton, CKLayoutGrid, CKMeter, CKModal } from 'clearkit';
import { Link } from 'react-router-dom';

import { routes } from '~/components/app/appMainRoutes';
import { ButtonContainer } from '~/components/unified/ButtonContainer';
import { useCreditUsage } from '~/hooks/account/useCreditUsage';

import { useSalesforceExport } from '../Exports/SalesforceExportProvider/useSalesforceExport';
import { useProspector } from '../useProspector';
import { UsageBlock } from './UsageBlock';

type CRMOptInModalProps = {
  isVisible: boolean;
  service: 'salesforce' | 'hubspot';
};

export function CRMOptInModal({ isVisible, service }: CRMOptInModalProps) {
  const [state, send] = useProspector();
  const { exportType, fieldMapping } = useSalesforceExport();
  const {
    credits,
    creditsUsed,
    creditsRemaining,
    creditsPerProspect,
    loading,
    hasUsage,
  } = useCreditUsage();

  const handleCancel = () => send('CANCEL');
  const handleCreate = () => {
    if (service === 'hubspot') {
      send('SAVE_HUBSPOT_EXPORT', {
        recordType: 'contact',
        mapping: {},
      });
    } else {
      send('SAVE_SALESFORCE_EXPORT', {
        recordType: exportType,
        mapping: fieldMapping,
      });
    }
  };

  const { selectedProspectsCount } = state.context;
  const creditsNeeded = selectedProspectsCount * creditsPerProspect;
  const isOverLimit = creditsNeeded > creditsRemaining || !hasUsage;

  const ServiceLabel = () => <span className="capitalize">{service}</span>;
  const Asterisk = () => <span className="superscript"> *</span>;

  const iconClasses = 'w-5 h-5 fill-gradient-br-blue';
  const creditsIconClasses = classnames(iconClasses, {
    'fill-gradient-br-yellow': isOverLimit,
  });
  const creditsCountClasses = classnames({
    'text-yellow-500': isOverLimit,
  });

  return (
    <CKModal
      hasHideOnEscape
      isVisible={isVisible}
      onToggle={handleCancel}
      size="md"
    >
      <CKModal.Header>
        <CKModal.Heading>Create contacts</CKModal.Heading>
      </CKModal.Header>
      <CKModal.Body className="p-8 space-y-6">
        <p>
          Each contact created in <ServiceLabel /> by Clearbit Prospector
          requires {creditsPerProspect} credits.
        </p>
        <CKBox>
          <div className="p-6 space-y-6">
            <CKLayoutGrid gap="1.5rem" gridTemplateColumns="1fr 1fr">
              <UsageBlock>
                <UsageBlock.Icon>
                  <PeopleNav className={iconClasses} />
                </UsageBlock.Icon>
                <UsageBlock.Label>Prospects Selected</UsageBlock.Label>
                <UsageBlock.Count count={selectedProspectsCount} />
              </UsageBlock>
              <UsageBlock>
                <UsageBlock.Icon>
                  <UsageAltNav className={iconClasses} />
                </UsageBlock.Icon>
                <UsageBlock.Label>
                  Credits Per Contact
                  <Asterisk />
                </UsageBlock.Label>
                <UsageBlock.Count count={creditsPerProspect} />
              </UsageBlock>
            </CKLayoutGrid>
            <CKLayoutGrid gap="1.5rem" gridTemplateColumns="1fr 1fr">
              <UsageBlock className={creditsCountClasses}>
                <UsageBlock.Icon>
                  <UsageAltNav className={creditsIconClasses} />
                </UsageBlock.Icon>
                <UsageBlock.Label>
                  Credits Required
                  <Asterisk />
                </UsageBlock.Label>
                <UsageBlock.Count count={creditsNeeded} />
              </UsageBlock>
              {hasUsage ? (
                <UsageBlock className={creditsCountClasses}>
                  <UsageBlock.Icon>
                    <CKMeter
                      max={credits}
                      value={creditsUsed}
                      variant={isOverLimit ? 'warning' : 'x'}
                    />
                  </UsageBlock.Icon>
                  <UsageBlock.Label>Available Credits</UsageBlock.Label>
                  <UsageBlock.Count
                    count={creditsRemaining}
                    isLoading={loading}
                  />
                </UsageBlock>
              ) : null}
            </CKLayoutGrid>
          </div>
          <footer className="px-6 py-4 overflow-hidden border-t border-gray-200 rounded-b bg-gray-0">
            <p className="text-sm italic text-gray-600">
              <Asterisk /> Clearbit Prospector will not create contact records
              for those prospects that already exist in <ServiceLabel />.
            </p>
          </footer>
        </CKBox>

        <p>
          Would you like to create these prospects as <ServiceLabel /> contacts?
        </p>
      </CKModal.Body>
      <CKModal.Footer>
        <ButtonContainer>
          <CKButton onClick={handleCancel}>Cancel</CKButton>
          {isOverLimit ? (
            <CKButton
              as={Link}
              to={routes.plans}
              variant="bold"
              variantColor="green"
            >
              Upgrade Plan & Add Credits
            </CKButton>
          ) : (
            <CKButton
              isDisabled={loading || isOverLimit}
              onClick={handleCreate}
              variant="bold"
              variantColor="blue"
            >
              Yes, create contacts in <ServiceLabel />
            </CKButton>
          )}
        </ButtonContainer>
      </CKModal.Footer>
    </CKModal>
  );
}
