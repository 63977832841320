import { CKContainerProps } from 'clearkit';

import { useEntitlements } from '../useEntitlements';
import { EntitlementWrapper } from './EntitlementWrapper';

export const WhenLegacyForms = (props: CKContainerProps) => {
  const { hasAccessForms } = useEntitlements();

  return <EntitlementWrapper hasAccess={hasAccessForms} {...props} />;
};
