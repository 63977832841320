import { useEffect } from 'react';

import {
  AccountConnector,
  FormIntegrationEnum,
  HubspotAccountConnector,
  useConfigurationPageQuery,
  useFieldMappingsLazyQuery,
  useFormConfigurationPageQuery,
  useMappingsLazyQuery,
} from '~/generated/graphql';
import { useCurrentAccount } from '~/hooks/account';
import { configurations, dataSources } from '~/lib/configuration';

import { useHomeCompanies } from './useHomeCompanies';

const getSalesforceConnector = (
  connectors: Record<string, AccountConnector>,
) => {
  const connector = connectors[configurations.salesforce];

  if (connector?.status === 'active') {
    return connector;
  }

  return connectors[configurations.salesforcesandbox];
};

const getConnectorsByService = (connectors: AccountConnector[]) => {
  const connectorsByService: Record<string, AccountConnector> = {};

  if (connectors) {
    connectors.forEach((connector) => {
      if (connectorsByService[connector.service]) {
        if (connector.status === 'active')
          connectorsByService[connector.service] = connector;
      } else {
        connectorsByService[connector.service] = connector;
      }
    });
  }

  return connectorsByService;
};

export const useHomeIntegrations = () => {
  const { account, loading: loadingAccount } = useCurrentAccount();

  // CRM Configuration
  const connectors = account?.connectors;
  const connectorsByService = getConnectorsByService(
    connectors as AccountConnector[],
  );

  // HubSpot Connector
  const hubspotConnector = connectorsByService[
    configurations.hubspot
  ] as HubspotAccountConnector;

  const isHubSpotConnected = !!hubspotConnector;

  // Salesforce Connector
  const salesforceConnector = getSalesforceConnector(connectorsByService);
  const isSalesforceConnected = !!salesforceConnector;

  const hasCRMConnector = isHubSpotConnected || isSalesforceConnected;

  // Clearbit JavaScript Configuration
  const {
    data: configurationData,
    loading: loadingClearbitJs,
  } = useConfigurationPageQuery();
  const isTagConnected = configurationData?.account?.accountSetting?.dataSources?.includes(
    dataSources.clearbitjs,
  );

  // Forms Configuration
  const {
    data: formConfigurationData,
    loading: loadingForms,
  } = useFormConfigurationPageQuery({
    variables: {
      integration: FormIntegrationEnum.Hubspot,
    },
  });
  const formConfiguration = formConfigurationData?.formConfiguration;
  const hasFormsAllowedSites = !!(
    formConfiguration?.allowedSites &&
    formConfiguration?.allowedSites.length > 0
  );
  const hasFormsAutoFill = !!formConfiguration?.autofill;

  // Forms Field Mappings
  const [
    getFieldMappings,
    { data: formFieldMappingData },
  ] = useFieldMappingsLazyQuery();

  useEffect(() => {
    if (hubspotConnector?.id) {
      getFieldMappings({
        variables: {
          integration: FormIntegrationEnum.Hubspot,
        },
      });
    }
  }, [hubspotConnector]);

  const fieldMappings = formFieldMappingData?.formFieldMapping?.fieldMappings;
  const hasFormsFieldMappings = !!fieldMappings && fieldMappings.length > 0;

  // Target Market Configuration
  const {
    hasTargetMarketDefinedIcp,
    isLoading: loadingTargetMarkets,
  } = useHomeCompanies();

  const hasTargetMarketAddedCompanies = false;

  // Visitors Configuration
  const hasVisitorsIntentCriteria = false;
  const hasVisitorsAddedCompanies = false;

  // HubSpot Enrichment
  const [
    getMappings,
    { data: hubspotEnrichmentMappingsData, loading: loadingHubspotEnrichment },
  ] = useMappingsLazyQuery();

  useEffect(() => {
    const accountConnectorId = hubspotConnector?.id;

    if (accountConnectorId) {
      getMappings({
        variables: {
          accountConnectorId,
        },
      });
    }
  }, [hubspotConnector]);

  const companyMappings = hubspotEnrichmentMappingsData?.company;
  const contactMappings = hubspotEnrichmentMappingsData?.contact;

  const hasCompanyEnrichment = !!companyMappings?.enabled;
  const hasContactEnrichment = !!contactMappings?.enabled;
  const hasEnrichmentMappings = !!(
    (companyMappings && companyMappings?.fieldMappings?.length > 0) ||
    (contactMappings && contactMappings?.fieldMappings?.length > 0)
  );

  const isOnboarding = (!hasCRMConnector || !isTagConnected) && !loadingAccount;

  return {
    hasCompanyEnrichment,
    hasContactEnrichment,
    hasCRMConnector,
    hasEnrichmentMappings,
    hasFormsAllowedSites,
    hasFormsAutoFill,
    hasFormsFieldMappings,
    hasTargetMarketAddedCompanies,
    hasTargetMarketDefinedIcp,
    hasVisitorsAddedCompanies,
    hasVisitorsIntentCriteria,
    hubspotConnector,
    isHubSpotConnected,
    isOnboarding,
    isSalesforceConnected,
    isTagConnected,
    loadingAccount,
    loadingHubspotEnrichment,
    loadingClearbitJs,
    loadingTargetMarkets,
    loadingForms,
    salesforceConnector,
  };
};
