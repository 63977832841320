import MenuItem from '@material-ui/core/MenuItem';

import { CheckmarkIcon, PlusIcon, RemoveIcon } from './icons/';
import styles from './Option.style.js';

const Option = ({ classes, column, onClick, removable, selected, style }) => (
  <MenuItem
    classes={{
      root: classes.root,
      selected: classes.selected,
    }}
    onClick={onClick}
    selected={selected}
    style={style}
  >
    <div className={classes.option} key={selected}>
      {column.label}

      {selected && <CheckmarkIcon />}
      {!selected && !removable && <PlusIcon id="addIcon" />}
      {removable && <RemoveIcon width={12} />}
    </div>
  </MenuItem>
);

Option.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    selected: PropTypes.string.isRequired,
    option: PropTypes.string.isRequired,
  }).isRequired,
  column: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
  removable: PropTypes.bool,
  selected: PropTypes.bool,
};

Option.defaultProps = {
  removable: false,
  selected: false,
  style: null,
};

export default withStyles(styles, {
  name: 'AppMultiPicklistOption',
})(Option);
