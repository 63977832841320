import { components } from 'react-select';

import DeleteIcon from './Delete.svg';

const RemoveButton = (props) => {
  const { MultiValueRemove } = components;
  const {
    selectProps: { menuIsOpen },
  } = props;

  if (!menuIsOpen) return <div style={{ paddingRight: 3 }} />;

  return (
    <MultiValueRemove {...props}>
      <DeleteIcon style={{ cursor: 'pointer' }} />
    </MultiValueRemove>
  );
};

RemoveButton.propTypes = {
  selectProps: PropTypes.shape({
    menuIsOpen: PropTypes.bool,
  }),
};

export default RemoveButton;
