import GraphqlError from '~/components/GraphqlError';
import EventsList from '~/components/Sidebar/RedesignedEventsList';

import { useCompanyEvents } from './useCompanyEvents';

type CompanyEventsListProps = {
  company: any;
  type?: string;
  className?: string;
};
export function CompanyEventsList({
  company,
  type,
  className,
}: CompanyEventsListProps) {
  const {
    error,
    loading,
    data,
    refetch,
    fetchMore,
    loadingFirstPage,
  } = useCompanyEvents({ company, type, excludingAnonymous: false });

  const { eventsHasNextPage, events } = data;

  if (error) {
    return <GraphqlError error={error} loading={loading} refetch={refetch} />;
  }

  return (
    <EventsList
      className={className}
      events={events}
      hasNextPage={eventsHasNextPage}
      id={company.id}
      loading={loadingFirstPage}
      loadingMore={loading}
      onLoadMore={fetchMore}
      type={type}
    />
  );
}
