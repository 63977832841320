const styles = () => ({
  testButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > button': {
      flex: 'none',
      marginRight: 18,
    },
    '& > svg': {
      flex: 'none',
      marginRight: 9,
    },
    '& > span': {
      flexGrow: 0,
    },
  },

  buttonText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  loader: {
    marginRight: 6,
  },
});

export default styles;
