import { CKDialogSize, CKDrawer } from 'clearkit';
import { FC, ReactNode } from 'react';

type SidebarDrawer = {
  children: ReactNode;
  hasClickOutside?: boolean;
  isVisible: boolean;
  onToggle: () => void;
  size?: CKDialogSize;
};

const SidebarDrawer: FC<SidebarDrawer> = ({
  children,
  hasClickOutside = false,
  isVisible,
  onToggle,
  size = CKDialogSize.md,
}) => {
  return (
    <CKDrawer
      hasClickOutside={hasClickOutside}
      isVisible={isVisible}
      onToggle={onToggle}
      size={size}
    >
      {children}
    </CKDrawer>
  );
};

export default SidebarDrawer;
