import { CKLayoutGridAuto } from 'clearkit';

import GraphqlError from '~/components/GraphqlError';
import LoadingArea from '~/components/LoadingArea';
import { BillingUsageMeterCard } from '~/components/usage/BillingUsageMeters/Show';
import {
  ProductFeatureEnum,
  useUsageDashboardFeaturesListQuery,
} from '~/generated/graphql';

export const BillingUsageMetersList = () => {
  const {
    data,
    loading,
    error,
    refetch,
  } = useUsageDashboardFeaturesListQuery();

  if (error) {
    return (
      <GraphqlError
        className="!m-0"
        error={error}
        loading={loading}
        refetch={refetch}
      />
    );
  }

  if (loading || !data) {
    return <LoadingArea loading={loading} />;
  }

  const features = [...data.productFeatures].sort((a, b) => {
    // If one of the features is "standard_bundle", prioritize it by moving it to the front
    if (a.id === ProductFeatureEnum.StandardBundle) return -1;
    if (b.id === ProductFeatureEnum.StandardBundle) return 1;

    // If neither feature is "standard_bundle", sort alphabetically by name
    return a.name.localeCompare(b.name);
  });

  return (
    <CKLayoutGridAuto gap="1.5rem" minWidth="18rem">
      {features.map((item) => {
        return (
          <BillingUsageMeterCard
            currentPeriodTotalUsage={item.currentPeriodTotalUsage}
            documentationUrl={item.documentationUrl}
            id={item.id}
            key={item.name}
            meteredUnit={item.meteredUnit}
            name={item.name}
            usageLimit={item.usageLimit}
          />
        );
      })}
    </CKLayoutGridAuto>
  );
};

export default BillingUsageMetersList;
