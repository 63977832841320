import { ArrowRight } from '@clearkit/icons';
import { CKBox, CKContainerProps, CKLayoutHorizontal } from 'clearkit';
import { FC } from 'react';

import { CRMExportFieldWrapper } from './CRMExportFieldWrapper';
import { ExportGroup } from './ExportGroup';
import { ExportHeader } from './ExportHeader';

export function HubspotForm() {
  return (
    <CKBox className="p-4 space-y-6 divide-y divide-gray-200">
      <ExportGroup className="pt-0">
        <ExportHeader>Default Fields</ExportHeader>
        <HubspotDefaultMapping label="Email" value="Email" />
        <HubspotDefaultMapping label="First Name" value="First Name" />
        <HubspotDefaultMapping label="Last Name" value="Last Name" />
        <HubspotDefaultMapping label="Job Title" value="Title" />
        <HubspotDefaultMapping label="Company" value="Company Name" />
        <HubspotDefaultMapping label="Website" value="Company Website" />
        <HubspotDefaultMapping label="Phone" value="Phone" />
      </ExportGroup>
    </CKBox>
  );
}

type HubspotDefaultMappingProps = {
  label: string;
  value: string;
};
function HubspotDefaultMapping({ label, value }: HubspotDefaultMappingProps) {
  return (
    <CRMExportFieldWrapper>
      <CRMExportFieldWrapper.IconHubspot />
      <CRMExportFieldWrapper.Label>{label}</CRMExportFieldWrapper.Label>
      <CRMExportFieldWrapper.Input>
        <CKLayoutHorizontal
          alignItems="start"
          className="self-start"
          gap="4rem"
          justifyContent="start"
        >
          <ArrowRight className="text-gray" />
          {value}
        </CKLayoutHorizontal>
      </CRMExportFieldWrapper.Input>
    </CRMExportFieldWrapper>
  );
}

type HubspotDefaultMappingComposition = {
  Label: FC<CKContainerProps>;
  Value: FC<CKContainerProps>;
};
