import { CKCompanyLogo } from 'clearkit';

import { dollars } from '~/lib/formatters';
import { paginateField } from '~/lib/graphql/pagination';

import TwoLineMenuItem from './TwoLineMenuItem';

const HubspotCompanyListItem = ({
  className,
  hubspotCompany,
  hubspotOwners,
}) => {
  const deals = paginateField(hubspotCompany, 'deals');
  const { externalAccountId } = hubspotCompany;

  return (
    <div className={className}>
      <TwoLineMenuItem
        externalLinkClass="text-hubspot"
        href={`https://app.hubspot.com/contacts/${externalAccountId}/company/${hubspotCompany.id}`}
        icon={
          <CKCompanyLogo
            className="shadow-sm"
            domain={hubspotCompany.domain}
            size={24}
          />
        }
        key={hubspotCompany.id}
        title={
          <>
            <span className="font-semibold">{hubspotCompany.name}</span> account
          </>
        }
      />

      {deals?.length > 0 && (
        <span className="px-4 text-sm font-medium text-gray">
          {deals?.length} {deals?.length == 1 ? 'deal' : 'deals'}
        </span>
      )}

      {deals?.map(({ name, id, ownerId, amount }) => {
        const owner = hubspotOwners?.find((owner) => owner.id === ownerId);
        const ownerName = owner?.ownerName || 'Unknown User';

        return (
          <TwoLineMenuItem
            externalLinkClass="text-hubspot"
            href={`https://app.hubspot.com/contacts/${externalAccountId}/deal/${id}`}
            key={id}
            subtitle={
              <>
                {owner && <>Owned by {ownerName}</>}
                <>{amount && <> &mdash; {dollars(amount)}</>}</>
              </>
            }
            title={name}
          />
        );
      })}
    </div>
  );
};

HubspotCompanyListItem.propTypes = {
  className: PropTypes.string,
  hubspotCompany: PropTypes.shape({
    domain: PropTypes.string,
    deals: PropTypes.shape({
      edges: PropTypes.array,
    }),
    externalAccountId: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  hubspotOwners: PropTypes.array,
};

export default HubspotCompanyListItem;
