import { Error } from '@clearkit/icons';
import {
  CKBox,
  CKButton,
  CKLayoutGridAuto,
  CKLayoutHorizontal,
} from 'clearkit';
import { Link } from 'react-router-dom';

import { AccountConnector } from '~/generated/graphql';

import { useCRMLabel } from '../hooks/useCRMLabel';

type HomeConnectionBannerProps = {
  connector: AccountConnector;
};

const serviceDisplayNameMap = {
  facebook: 'Facebook',
  g2: 'G2',
  google: 'Google',
  hubspot: 'HubSpot',
  marketo: 'Marketo',
  salesforce: 'Salesforce',
  salesforcesandbox: 'Salesforce Sandbox',
  segment: 'Segment',
  slack: 'Slack',
};

const actionDisplayMap = {
  slack: 'Alerts',
};

export const HomeConnectionBanner = ({
  connector,
}: HomeConnectionBannerProps) => {
  const { remoteMeta, service } = connector;
  const { integrationsRoute } = useCRMLabel();

  const serviceDisplayName =
    serviceDisplayNameMap[service as keyof typeof serviceDisplayNameMap];
  const actionDisplay =
    actionDisplayMap[service as keyof typeof actionDisplayMap] ||
    'Data syncing';

  return (
    <CKBox className="divide-y divide-gray-200">
      <CKLayoutHorizontal as="header" className="p-6">
        <CKLayoutHorizontal className="text-red" justifyContent="start">
          <CKBox className="p-2">
            <Error />
          </CKBox>
          <div>
            <h3 className="font-medium">
              {serviceDisplayName} is disconnected
            </h3>
            <p className="text-sm">
              {actionDisplay} disabled until {serviceDisplayName} is reconnected
            </p>
          </div>
        </CKLayoutHorizontal>
        <CKButton
          as={Link}
          to={integrationsRoute}
          variant="bold"
          variantColor="blue"
        >
          Reconnect {serviceDisplayName}
        </CKButton>
      </CKLayoutHorizontal>
      <CKLayoutGridAuto
        as="footer"
        className="p-6 text-sm text-gray-600"
        gapColumn="3.75rem"
        gapRow="1.5rem"
        minWidth="15rem"
      >
        <div>
          <div>Previously connected as</div>
          <div className="text-lg font-medium text-gray-900">
            {remoteMeta.name}
          </div>
          <div>{remoteMeta.email}</div>
        </div>
        <div>
          {remoteMeta.organizationId ? (
            <>
              <div>Account ID</div>
              <div className="text-lg font-medium text-gray-900">
                {remoteMeta.organizationId}
              </div>
            </>
          ) : null}
          <div>
            {service === 'salesforcesandbox' ? 'sandbox' : null}
            {service === 'salesforce' ? 'production' : null}
          </div>
        </div>
      </CKLayoutGridAuto>
    </CKBox>
  );
};
