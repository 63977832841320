import {
  Edit,
  Ellipsis,
  FitCompany,
  SpinnerIndicators,
  Trash,
} from '@clearkit/icons';
import {
  CKActionMenu,
  CKButton,
  CKLayoutHorizontal,
  CKNavigationBar,
  CKPopover,
  CKSkeleton,
} from 'clearkit';

import { routes } from '~/components/app/appMainRoutes';
import { NavRouterItem } from '~/components/NavBar/NavRouterItem';
import { ButtonContainer } from '~/components/unified/ButtonContainer';

import { UseTargetMarket } from '../hooks/useTargetMarket';
import { ButtonNewList } from './ButtonNewList';
import SaveListModal from './SaveListModal';
import { SidebarEmptyState } from './SidebarEmptyState';
import { TargetMarketForm } from './TargetMarketForm';
import { TargetMarketSidebar } from './TargetMarketSidebar';

export const Sidebar = ({
  handleDeleteTargetMarket,
  handleDiscardChanges,
  handleUpdateTargetMarket,
  isEditing,
  isLoading,
  isNewList,
  isSaveModalOpen,
  modifiedNotSaved,
  search,
  selectedTargetMarket,
  setIsEditing,
  setIsLoading,
  setIsSaveModalOpen,
  setSearch,
  targetMarkets,
}: UseTargetMarket) => {
  const handleModalSave = async (name: string) => {
    if (!search) return;

    await handleUpdateTargetMarket(name);
  };

  const handleModalCancel = () => {
    setIsSaveModalOpen(false);
    setIsLoading(false);
  };

  const handleReset = () => {
    setIsEditing(false);
    setSearch(null);
  };

  const handleSaveList = () => {
    if (!selectedTargetMarket) {
      setIsSaveModalOpen(true);
    } else {
      handleUpdateTargetMarket();
    }
  };

  const ButtonEditMenu = () => (
    <CKPopover
      popover={
        <CKActionMenu>
          <CKActionMenu.Item
            icon={<Edit />}
            onClick={() => setIsSaveModalOpen(true)}
          >
            Rename list
          </CKActionMenu.Item>

          <CKActionMenu.Item
            icon={<Trash />}
            isDestructive
            onClick={handleDeleteTargetMarket}
          >
            Delete list
          </CKActionMenu.Item>
        </CKActionMenu>
      }
      popoverPlacement="top-end"
    >
      <CKButton>
        <Ellipsis />
      </CKButton>
    </CKPopover>
  );

  const ButtonDiscard = () => (
    <CKButton onClick={handleDiscardChanges}>Discard</CKButton>
  );

  return (
    <TargetMarketSidebar
      title={selectedTargetMarket || isEditing ? 'Filters' : 'Target Markets'}
    >
      {(!!selectedTargetMarket || isEditing) && (
        <TargetMarketSidebar.Breadcrumbs handleReset={handleReset}>
          {isLoading ? (
            <CKSkeleton className="w-full h-3 shrink-1 grow" />
          ) : (
            <span title={selectedTargetMarket?.name || ''}>
              {selectedTargetMarket?.name || 'New Target Market'}
            </span>
          )}
        </TargetMarketSidebar.Breadcrumbs>
      )}

      {isLoading ? (
        <div className="flex items-center justify-center p-6">
          <SpinnerIndicators />
        </div>
      ) : isEditing ? (
        <TargetMarketForm onChange={setSearch} value={search || {}} />
      ) : targetMarkets && targetMarkets.length ? (
        <CKNavigationBar
          className="border-r-0 !w-full"
          isExpanded
          isResponsive={false}
        >
          <CKNavigationBar.Section className="space-y-1">
            <h3 className="pl-3 text-sm font-medium">Saved Target Markets</h3>

            {targetMarkets.map((targetMarket) => (
              <NavRouterItem
                className="!font-normal !w-full"
                key={targetMarket.id}
                label={targetMarket.name}
                to={`${routes.targetMarkets}/list/${targetMarket.id}`}
              >
                <CKNavigationBar.ItemLeft>
                  <FitCompany />
                </CKNavigationBar.ItemLeft>
              </NavRouterItem>
            ))}
          </CKNavigationBar.Section>
        </CKNavigationBar>
      ) : (
        <SidebarEmptyState />
      )}

      <TargetMarketSidebar.Footer>
        <CKLayoutHorizontal>
          {isEditing ? (
            <CKButton
              isDisabled={!modifiedNotSaved || !search}
              onClick={handleSaveList}
              variant="bold"
              variantColor="blue"
            >
              Save list
            </CKButton>
          ) : (
            <ButtonNewList />
          )}

          {selectedTargetMarket ? (
            <ButtonContainer>
              {isEditing ? (
                <ButtonDiscard />
              ) : (
                <CKButton
                  onClick={() => setIsEditing(true)}
                  variant="simple"
                  variantColor="blue"
                >
                  Edit
                </CKButton>
              )}

              <ButtonEditMenu />
            </ButtonContainer>
          ) : null}
          {isNewList && isEditing ? <ButtonDiscard /> : null}
        </CKLayoutHorizontal>
      </TargetMarketSidebar.Footer>

      <SaveListModal
        handleCancel={handleModalCancel}
        handleSave={handleModalSave}
        isOpen={isSaveModalOpen}
        loading={isLoading}
        selected={selectedTargetMarket}
      />
    </TargetMarketSidebar>
  );
};
