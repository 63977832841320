import {
  destinationTypeCustomHtml,
  destinationTypeEmail,
  destinationTypeFacebookConversion,
  destinationTypeGoogleConversion,
  destinationTypeSalesforce,
  destinationTypeSalesforceCreateAccount,
  destinationTypeSalesforceCreateContact,
  destinationTypeSalesforceCreateLead,
  destinationTypeSlack,
  destinationTypeWebhook,
} from '~/lib/destinations';
import CreateExternalRecordsAccountSalesforce from '~/pages/destinations/destinationConfigs/CreateExternalRecords/salesforce/account';
import CreateExternalRecordsContactSalesforce from '~/pages/destinations/destinationConfigs/CreateExternalRecords/salesforce/contact';
import CreateExternalRecordsLeadSalesforce from '~/pages/destinations/destinationConfigs/CreateExternalRecords/salesforce/lead';
import CustomHtml from '~/pages/destinations/destinationConfigs/CustomHtml';
import Email from '~/pages/destinations/destinationConfigs/Email';
import FacebookConversion from '~/pages/destinations/destinationConfigs/FacebookConversion';
import GoogleConversion from '~/pages/destinations/destinationConfigs/GoogleConversion';
import Salesforce from '~/pages/destinations/destinationConfigs/Salesforce';
import Slack from '~/pages/destinations/destinationConfigs/Slack';
import Webhook from '~/pages/destinations/destinationConfigs/Webhook';

const componentForType = (type) => {
  switch (type) {
    case destinationTypeCustomHtml:
      return CustomHtml;

    case destinationTypeEmail:
      return Email;

    case destinationTypeFacebookConversion:
      return FacebookConversion;

    case destinationTypeGoogleConversion:
      return GoogleConversion;

    case destinationTypeSalesforce:
      return Salesforce;

    case destinationTypeSalesforceCreateAccount:
      return CreateExternalRecordsAccountSalesforce;

    case destinationTypeSalesforceCreateContact:
      return CreateExternalRecordsContactSalesforce;

    case destinationTypeSalesforceCreateLead:
      return CreateExternalRecordsLeadSalesforce;

    case destinationTypeSlack:
      return Slack;

    case destinationTypeWebhook:
      return Webhook;

    default:
      throw new Error(`Unknown destination type "${type}"`);
  }
};

export default function DestinationFormFactory(props) {
  const Component = componentForType(props.type);

  return <Component {...props} />;
}

DestinationFormFactory.propTypes = {
  type: PropTypes.string.isRequired,
  errors: PropTypes.array.isRequired,
  refetch: PropTypes.func.isRequired,
  accountConnectors: PropTypes.object,
  attributes: PropTypes.array.isRequired,
  FormFooter: PropTypes.elementType,
  destination: PropTypes.object,
  destinationOptions: PropTypes.object,
  segment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    collection: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
