const styles = () => ({
  menuListHeader: {
    height: 45,
    background: 'linear-gradient(-180deg, #fcfeff 0%, #f9fbfc 100%)',
    borderBottom: '1px solid #e1e9f0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 16px',
  },

  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },

  menuListIconContainer: {
    width: 16,
    height: 16,
    '& svg': {
      width: 16,
      height: 16,
    },
  },

  actionsContainer: {
    flex: 1,
    textAlign: 'right',
  },

  sectionTitle: {
    fontSize: 14,
    fontWeight: 500,
    color: '#596168',
    marginLeft: 8,
  },
});

export default styles;
