import * as Sentry from '@sentry/browser';
import { Container } from 'unstated';

import * as client from '~/lib/client';
import { HasRequiredPermissions } from '~/lib/permissions';
const { analytics } = window;

class UserContainer extends Container {
  state = {
    currentUser: null,
    accounts: [],
    allowedReferrers: {},
    savedNewReferrers: false,
  };

  constructor() {
    super();

    if (client.isAuthRoute()) {
      return;
    }
    this.fetchCurrentUser();
    this.fetchCurrentUserAccounts();
  }

  fetchCurrentUser = () => {
    return client
      .getCurrentUser()
      .then(this.setCurrentUser)
      .then(this.identifyUser)
      .catch(this.onFailFetchCurrentUser);
  };

  fetchCurrentUserAccounts = () => {
    return client
      .getAccounts()
      .then(this.setCurrentUserAccounts)
      .catch(this.onFailFetchCurrentUser);
  };

  identifyUser = () => {
    analytics &&
      analytics.identify(this.state.currentUser.id, this.state.currentUser);
  };

  setCurrentUser = (currentUser) => {
    this.setState({ currentUser });

    Sentry.configureScope((scope) => {
      scope.setUser({
        id: currentUser.id,
        account_id: currentUser.account_id,
        email: currentUser.email,
      });
    });
  };

  setCurrentUserAccounts = (accounts) => {
    this.setState({ accounts });
  };

  currentAccountId = () => {
    return this.state.currentUser ? this.state.currentUser.account_id : '';
  };

  currentAccount = () => {
    return this.state.accounts.find(
      (account) => account.id === this.currentAccountId(),
    );
  };

  hasPermission = (permission_type) => {
    return this.state.currentUser?.permissions?.[permission_type] == true;
  };

  hasPermissionTo({ objectType, objectSubtype, objectAction }) {
    const action = [objectType, objectSubtype, objectAction]
      .filter((obj) => obj)
      .join('_');
    const permissions = this.state.currentUser?.permissions;

    return HasRequiredPermissions({ action, permissions });
  }

  accountHasPermission = (permission) => {
    const account = this.currentAccount();
    if (!account) return false;

    return !!account.permissions[permission];
  };

  hasFlag = (flag) => {
    const account = this.currentAccount();
    if (!account) return false;
    if (account.flags.indexOf(flag) > -1) {
      return true;
    } else {
      return false;
    }
  };

  domainForAccount = (account) => {
    if (account && account.domain) {
      return account.domain;
    }

    if (this.state.currentUser) {
      const email = this.state.currentUser.email;
      return email.replace(/.*@/, '');
    }

    return '';
  };

  accountDomain = () => {
    const account = this.currentAccount();
    return this.domainForAccount(account);
  };

  switchAccount = (accountId) => {
    if (accountId === 'new-team') {
      client.navigateToNewTeams();
      return;
    }

    client.switchAccount(accountId).then(() => {
      client.flushAccountData();
    });
  };

  onFailFetchCurrentUser = (response) => {
    if (response.error && response.error.type === 'auth') {
      client.authUser(response.error);
    }
  };

  currentUserLoaded = () => {
    return this.state.currentUser !== null;
  };

  currentAccountLoaded = () => {
    return !!this.currentAccount();
  };

  hasNoAccess = () => {
    return !this.accountHasPermission('can_access_platform');
  };

  hasInternalAccess = () => {
    return this.hasFlag('x_clearbit_internal');
  };

  hasEngagementAccess = () => {
    return this.hasInternalAccess() || this.hasFlag('x_engagement');
  };

  shouldShowAudiences = () => {
    return this.accountHasPermission('can_access_ads');
  };

  shouldShowDashboard = () => {
    return this.accountHasPermission('can_access_platform');
  };

  shouldShowSettings = () => {
    // All users should be able to access settings
    // As we allow grow.clearbit.com users to enable certain integrations
    return true;
  };

  shouldShowPeople = () => {
    return this.accountHasPermission('can_access_platform');
  };

  shouldShowCompanies = () => {
    return this.accountHasPermission('can_access_platform');
  };

  shouldShowICP = () => {
    return this.hasFlag('x_fit_score');
  };

  shouldShowDataEnrichment = () => {
    return this.accountHasPermission('can_access_enrichment');
  };

  shouldShowDataEnrichmentLogs = () => {
    return this.hasFlag('x_data_enrichment_logs');
  };

  shouldShowForms = () => {
    return this.accountHasPermission('can_access_forms');
  };

  shouldShowDestinationGroup = (group) => {
    const permissions = {
      ads: 'can_access_ads',
      alerting: 'can_access_alerting',
      personalization: 'can_access_personalization',
    };

    const permission = permissions[group];
    return this.accountHasPermission(permission);
  };

  shouldShowSetup = () => {
    return this.accountHasPermission('can_access_platform');
  };

  needsToOnboard = () => {
    const account = this.currentAccount();
    if (!account) return false;

    account.needs_to_onboard;
  };
}

export default new UserContainer();
