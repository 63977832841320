import { Error } from '@clearkit/icons';
import { CKBannerGroup, CKBannerNext } from 'clearkit';
import { get, uniq } from 'lodash';
import moment from 'moment';
import { Helmet } from 'react-helmet';

import DefaultLayout from '~/components/layouts/DefaultLayout';
import SegmentActionsMenu from '~/components/SegmentActionsMenu';
import SegmentTabs from '~/components/SegmentTabs';
import SegmentTile from '~/components/SegmentTile';
import history from '~/lib/history';
import paths from '~/lib/paths';
import { segmentActionLocations } from '~/lib/segmentAnalytics';

import ConnectorError from './ConnectorError';
import Subtitle from './Subtitle';
import useRefreshState from './useRefreshState';

const UPDATE_THRESHOLD_IN_MINUTES = 15;

const shouldRefreshSegment = (segment) =>
  !segment?.enabledDestinations &&
  moment().diff(moment(segment?.updatedAt), 'minutes') >
    UPDATE_THRESHOLD_IN_MINUTES;

function SegmentLayout(props) {
  const { segment, loading, resultsCount, refetch } = props;
  const { searchQuery, tab, children, ...layoutProps } = props;

  const connectorErrors = get(segment, 'connectorErrors', {});

  const searchErrorServices = get(connectorErrors, 'search', []);
  const destinationErrorServices = get(connectorErrors, 'destinations', []);

  const errorServices = uniq([
    ...searchErrorServices,
    ...destinationErrorServices,
  ]);

  const shouldRefresh =
    tab === 'show' &&
    !loading &&
    !errorServices.length &&
    shouldRefreshSegment(segment);

  // "Original results count" is the count of results in the original search.
  // "Current results count" is the count of results in the latest search.

  const {
    triggerRefresh,
    refreshState,
    onRefetchForNewMatches,
    originalResultsCount,
  } = useRefreshState({
    shouldRefreshSegment: shouldRefresh,
    segment,
    refetch,
  });

  return (
    <>
      {segment && (
        <Helmet>
          <title>{segment.name}</title>
        </Helmet>
      )}

      <DefaultLayout
        actions={
          segment && (
            <SegmentActionsMenu
              exportCsv
              location={segmentActionLocations.segmentMenu}
              onClone={(clone) => {
                history.push(paths.segmentEdit(clone.type, clone.id));
              }}
              onDelete={() => {
                history.replace(paths.segments(segment.type));
              }}
              onRefresh={triggerRefresh}
              searchQuery={searchQuery}
              segment={segment}
              type={segment.legacyType}
            />
          )
        }
        loading={loading}
        subtitle={
          <Subtitle
            currentResultsCount={resultsCount}
            loading={!segment || !Number.isInteger(resultsCount)}
            onRefetch={onRefetchForNewMatches}
            originalResultsCount={originalResultsCount}
            refreshState={refreshState}
            searchQuery={searchQuery}
            segment={segment}
          />
        }
        tabs={<SegmentTabs segment={segment} value={tab} />}
        tile={<SegmentTile size={50} type={segment?.type} />}
        title={get(segment, 'name', 'Loading...')}
        {...layoutProps}
      >
        <>
          {errorServices.length > 0 && (
            <>
              <CKBannerNext isFullWidth variant="warning">
                <CKBannerNext.IconStatus>
                  <Error />
                </CKBannerNext.IconStatus>
                <CKBannerNext.Body>
                  This audience is paused and destinations will not run until
                  the issues below are fixed.
                </CKBannerNext.Body>
              </CKBannerNext>
              <CKBannerGroup className="mx-10 my-6">
                {errorServices.map((service) => (
                  <ConnectorError key={service} service={service} />
                ))}
              </CKBannerGroup>
            </>
          )}
          {children}
        </>
      </DefaultLayout>
    </>
  );
}

SegmentLayout.propTypes = {
  children: PropTypes.node,
  tab: PropTypes.string,
  searchQuery: PropTypes.string,
  resultsCount: PropTypes.number,
  segment: PropTypes.object,
  loading: PropTypes.bool,
  updatedAt: PropTypes.node,
};

export default SegmentLayout;
