import { useCurrentUserQuery } from '~/generated/graphql';

import { useUpdatePassword } from './useUpdatePassword';
import { useUpdateProfile } from './useUpdateProfile';

export function useCurrentUser() {
  const { loading, error, data, refetch } = useCurrentUserQuery();
  const { updateProfile } = useUpdateProfile();
  const { updatePassword } = useUpdatePassword();

  return {
    loading,
    error,
    role: data?.viewer?.role,
    user: data?.viewer?.user,
    account: data?.account,
    updateProfile,
    updatePassword,
    refetch,
  };
}
