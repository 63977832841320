import {
  Clock as ActivityIcon,
  Companies as CompanyIcon,
  People as PeopleIcon,
  Segment as SegmentsIcon,
} from '@clearkit/icons';
import { CKCompanyLogo, CKDrawer, CKTab, CKTabList, CKTabs } from 'clearkit';
import some from 'lodash/some';
import { Helmet } from 'react-helmet';

import AttributeBrowser from '~/components/AttributeBrowser';
import { FitTag } from '~/components/FitScore/FitTag';
import { WhenFitScore } from '~/components/FitScore/WhenFitScore';
import GraphqlError from '~/components/GraphqlError';
import wrapError from '~/components/JavascriptError/JavascriptErrorHandler';
import LoadingArea from '~/components/LoadingArea';
import MissingPage from '~/components/MissingPage';
import { useFancyQuery } from '~/lib/graphql';
import { paginateData } from '~/lib/graphql/pagination';

import Actions from './Actions';
import Activity from './Activity';
import CompanyDetails from './Details';
import People from './People';
import query from './query.graphql';
import Segments from './Segments';

const Company = ({ id }) => {
  const {
    data,
    data: { company, salesforceAccountConnector, hubspotAccountConnector } = {},
    error,
    loading,
    refetch,
  } = useFancyQuery(query, {
    variables: { id },
    returnPartialData: true,
  });

  const [tabIndex, setTabIndex] = useState(0);
  const overflowScrollRef = React.useRef();

  const handleTabChange = (stickyHeaderBounds) => {
    const stickyHeaderGap = 28;
    const customOffset =
      stickyHeaderBounds.top - stickyHeaderBounds.height - stickyHeaderGap;
    if (overflowScrollRef.current) {
      overflowScrollRef.current.scrollTo({ top: customOffset });
    }
  };

  if (error) {
    return (
      <div className="x-sidebar">
        <GraphqlError error={error} refetch={refetch} />;
      </div>
    );
  }

  if (!loading && !data.company) return <MissingPage className="x-sidebar" />;

  if ((loading && !data) || !data.company)
    return <LoadingArea className="x-sidebar" loading />;

  const { salesforceAccounts, hubspotCompanies } = paginateData(company, {
    fields: ['salesforceAccounts', 'hubspotCompanies'],
  });

  return (
    <div className="x-sidebar">
      <Helmet title={company.name} />
      <CKDrawer.Header className="border-none">
        <div className="flex items-center flex-1">
          <div className="mr-4">
            <CKCompanyLogo
              className="shadow-sm"
              cornerRadius={8}
              domain={company.domain}
              size={56}
            />
          </div>

          <div>
            <CKDrawer.Heading>
              {company.name || company.domain}
            </CKDrawer.Heading>
            <a
              className="text-blue"
              href={`http://${company.domain}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {company.domain || '...'}
            </a>
          </div>

          <div className="flex items-center ml-auto">
            <WhenFitScore>
              <FitTag score={company.fitScore} />
            </WhenFitScore>
            <Actions
              hubspotCompanies={hubspotCompanies}
              hubspotOwners={hubspotAccountConnector?.owners}
              salesforceAccountConnector={salesforceAccountConnector}
              salesforceAccounts={salesforceAccounts}
            />
          </div>
        </div>
      </CKDrawer.Header>
      <GraphqlError error={error} inline refetch={refetch} />
      <CKTabs
        aria-label="Company tabs"
        className="flex flex-col flex-grow min-h-0"
        index={tabIndex}
        isBordered
        onChange={setTabIndex}
      >
        <CKTabList
          aria-label="Company tabs"
          className="flex-shrink-0 px-10 -mt-4"
        >
          <CKTab icon={<CompanyIcon />}>Company details</CKTab>
          <CKTab icon={<ActivityIcon />}>Activity</CKTab>
          <CKTab
            badge={company.people?.totalCount || false}
            icon={<PeopleIcon />}
          >
            People
          </CKTab>
          <CKTab
            badge={company.segments?.totalCount || false}
            icon={<SegmentsIcon />}
          >
            Audiences
          </CKTab>
        </CKTabList>

        {tabIndex === 0 && (
          <div className="overflow-y-auto" ref={overflowScrollRef}>
            <CompanyDetails
              company={company}
              hubspotCompanies={hubspotCompanies}
              hubspotOwners={hubspotAccountConnector?.owners}
              salesforceAccountConnector={salesforceAccountConnector}
              salesforceAccounts={salesforceAccounts}
            />

            <LoadingArea loading={!some([company.traits, company.enrichment])}>
              <AttributeBrowser
                enrichment={company.enrichment}
                hubspotCompanies={hubspotCompanies}
                hubspotOwners={hubspotAccountConnector?.owners}
                object={company}
                onTabChange={handleTabChange}
                salesforceAccountConnector={salesforceAccountConnector}
                salesforceAccounts={salesforceAccounts}
                traits={company.traits}
              />
            </LoadingArea>
          </div>
        )}
        {tabIndex === 1 && (
          <Activity className="flex flex-col flex-grow" company={company} />
        )}
        {tabIndex === 2 && (
          <People className="flex flex-col flex-grow" companyId={company.id} />
        )}
        {tabIndex === 3 && (
          <Segments className="flex flex-col flex-grow" company={company} />
        )}
      </CKTabs>
    </div>
  );
};

Company.propTypes = {
  id: PropTypes.string,
};

export default wrapError(Company);
