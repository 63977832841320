import { Prospector } from '@clearkit/icons';
import { CKNavigationBar } from 'clearkit';
import { RouteComponentProps, withRouter } from 'react-router';

import { ProspectList } from '../../../generated/graphql';
import { List } from './List';
// import { targetMarketList } from './mockData';

type ProspectorListsProps = {
  list: ProspectList[];
};

interface ComponentProps
  extends RouteComponentProps<any>,
    ProspectorListsProps {}

const ProspectorLists = ({ list }: ProspectorListsProps) => {
  return (
    <CKNavigationBar
      className="bg-transparent border-r-0 !w-full"
      isExpanded
      isResponsive={false}
    >
      {/* <CKNavigationBar.Section className="pb-8">
        <List items={targetMarketList} title="Target Markets">
          <FitCompany />
        </List>
      </CKNavigationBar.Section>
       */}
      <CKNavigationBar.Section className="space-y-8">
        {/* <List items={list} title="Recent Prospect Lists">
          <Prospector />
        </List> */}
        <List items={list} title="Saved Prospect Lists">
          <Prospector />
        </List>
      </CKNavigationBar.Section>
    </CKNavigationBar>
  );
};

export default withRouter(({ list, ...rest }: ComponentProps) => (
  <ProspectorLists {...rest} list={list} />
));
