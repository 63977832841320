import InfoTooltip from '~/components/InfoTooltip';
import MultiPicklist from '~/components/MultiPicklist';
import TextField from '~/components/RedesignedTextField';
import { destinationTypeWebhook } from '~/lib/destinations';
import { hasError } from '~/lib/graphql';
import { legacySegmentType, readableSingularSegmentType } from '~/lib/segments';
import DeliverTestButton from '~/pages/destinations/components/DeliverTestButton';
import SuppressionForm from '~/pages/destinations/components/SuppressionForm';
import TriggerType from '~/pages/destinations/components/TriggerType';
import defaultTriggerTypes from '~/pages/destinations/components/TriggerType/defaultValues';

import ContentBox from '../../components/ContentBox';
import FormContentWrapper from '../../components/FormContentWrapper';
import FormHeader from '../../components/FormHeader';
import sharedStyles from '../../New/styles';
import styles from './styles';
import Warning from './warning.svg';
class Webhook extends React.Component {
  state = {
    columns: [],
    duplicateSuppressionTimeframe: 0,
    triggerType: 'segment_enter',
    url: null,
  };

  UNSAFE_componentWillMount() {
    if (this.props.destination) {
      this.setState({
        ...this.props.destination,
        ...this.props.destination.config,
      });
    }
  }

  handleFieldChange = (name, value) => {
    this.setState({ [name]: value });
  };

  get destinationConfig() {
    const {
      columns,
      duplicateSuppressionTimeframe,
      url,
      triggerType,
    } = this.state;

    return {
      config: {
        columns,
        duplicateSuppressionTimeframe,
        url,
      },
      triggerType,
    };
  }

  render() {
    const {
      attributes,
      classes,
      destination,
      errors,
      segment,
      FormFooter,
    } = this.props;

    const {
      columns,
      triggerType,
      url,
      duplicateSuppressionTimeframe,
    } = this.state;

    // NOTE(SB): we need to show a little warning to users who are using the old webhook
    // shape when editing a destination. We'll keep an eye on the DB, and once all destinations
    // have config columns persisted, we can remove this and the warning - see
    // https://app.asana.com/0/1112113577861959/1126868843495394
    const showPayloadWarning =
      destination && destination.id && !destination.config['columns'];

    const humanizedSegmentType = readableSingularSegmentType(segment.type);
    const destinationFlowDescription = destination
      ? 'Webhook configured'
      : null;

    return (
      <>
        <FormHeader
          destinationFlowDescription={destinationFlowDescription}
          destinationName="Webhook"
          destinationType={destinationTypeWebhook}
          segmentCollection={segment.collection.name}
          segmentName={segment.name}
        />

        <FormContentWrapper>
          <h2 className={classes.subheading}>Trigger</h2>
          <span className={classes.triggerSubtext}>
            Choose when this Destination will be run.{' '}
            <a
              href="https://help.clearbit.com/hc/en-us/articles/360023453694"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline' }}
              target="_blank"
            >
              Learn more
            </a>
          </span>

          <TriggerType
            errors={errors}
            handleChange={this.handleFieldChange}
            triggerType={triggerType}
            triggerTypes={defaultTriggerTypes(segment.type)}
          />

          <h2 className={classes.subheading}>Configure webhook options</h2>

          <div className={`${classes.contentBox} mb-6`}>
            <TextField
              defaultValue={url}
              error={hasError(errors, 'url')}
              fullWidth
              label={
                <>
                  Webhook URL *{' '}
                  <InfoTooltip
                    title={`The URL you want to send the ${humanizedSegmentType} payload to.`}
                  />{' '}
                </>
              }
              onChange={(event) => {
                this.handleFieldChange('url', event.target.value);
              }}
              placeholder="Enter a webhook URL"
              variant="standard"
            />

            <hr className={classes.break} />

            <SuppressionForm
              exclusion_timeframe={duplicateSuppressionTimeframe}
              onChange={({ exclusion_timeframe }) => {
                this.handleFieldChange(
                  'duplicateSuppressionTimeframe',
                  exclusion_timeframe,
                );
              }}
              typename={humanizedSegmentType}
            />
          </div>

          <h2 className={classes.subheading}>Customize your Payload</h2>

          <p className={classes.columnsHelperText}>
            Select which attributes you want sent to your webhook URL
            {showPayloadWarning && (
              <span>
                <Warning /> Your Webhook Destination is currently using an old
                payload shape which is not reflected by the attributes listed
                below. Your Webhook will continue to use the old payload shape
                until you Save Changes.
              </span>
            )}
          </p>

          <MultiPicklist
            allColumns={attributes}
            onChange={(value) => {
              this.handleFieldChange('columns', value);
            }}
            type={legacySegmentType(segment.type)}
            value={this.state.columns}
          />

          <h2 className={classes.subheading}>Test this destination</h2>
          <span className={classes.triggerSubtext}>
            Run a test by manually triggering this destination
          </span>
          <ContentBox>
            <DeliverTestButton
              config={{
                columns,
                url,
                duplicateSuppressionTimeframe,
              }}
              destinationType="webhook"
              segmentId={segment.id}
              segmentType={segment.type}
            >
              <p className="mb-4 text-base text-gray-600">
                {url ? (
                  <>
                    Deliver an example payload to{' '}
                    <span className={classes.testUrl}>{url}</span>
                  </>
                ) : (
                  <>Set a webhook URL above to test this destination</>
                )}
              </p>
            </DeliverTestButton>
          </ContentBox>
        </FormContentWrapper>
        <FormFooter destinationConfig={this.destinationConfig} />
      </>
    );
  }
}

Webhook.propTypes = {
  classes: PropTypes.object.isRequired,
  attributes: PropTypes.array.isRequired, //TODO: arrayOf
  destination: PropTypes.object,
  errors: PropTypes.array.isRequired,
  segment: PropTypes.object.isRequired,
  FormFooter: PropTypes.elementType.isRequired,
};

export default withStyles(
  (theme) => ({
    ...sharedStyles(theme),
    ...styles(theme),
  }),
  { name: 'SegmentDestinationNewWebhook' },
)(Webhook);
