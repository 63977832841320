import { useToast } from 'clearkit';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import history from '~/lib/history';

type ToastProps = {
  description?: string;
  heading?: string;
};

export const useStatusToast = () => {
  const { addToast } = useToast();

  const autoHideDuration = 5000;

  const addSuccessToast = ({ heading, description }: ToastProps) => {
    addToast({
      autoHideDuration,
      description,
      heading,
      variant: 'success',
    });
  };

  const addErrorToast = ({ heading, description }: ToastProps) => {
    addToast({
      autoHideDuration,
      description,
      heading,
      variant: 'error',
    });
  };

  return { addErrorToast, addSuccessToast };
};

type Routes = {
  [key: number]: string;
};

export const useRouterTabs = (routes: Routes, selectedTab = 0) => {
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState<number>(selectedTab);

  const handleTabIndexChange = (index: number) => {
    setTabIndex(index);
    history.push(routes[index as keyof typeof routes]);
  };

  useEffect(() => {
    const index = Object.values(routes).indexOf(location.pathname);

    setTabIndex(index === -1 ? 0 : index);
  }, [location.pathname]);

  return { handleTabIndexChange, setTabIndex, tabIndex };
};
