import { useMutation } from 'react-query';

interface CardData {
  number: string;
  exp_month: number;
  exp_year: number;
  cvc: string;
  name: string;
  address_line1: string;
  address_city: string;
  address_state: string;
  address_zip: string;
}

const createStripeToken = ({
  cardData,
  stripePublishableKey,
}: {
  cardData: CardData;
  stripePublishableKey: string;
}) => {
  return fetch('https://api.stripe.com/v1/tokens', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${stripePublishableKey}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      'card[number]': cardData.number,
      'card[exp_month]': cardData.exp_month.toString(),
      'card[exp_year]': cardData.exp_year.toString(),
      'card[cvc]': cardData.cvc,
      'card[name]': cardData.name,
      'card[address_line1]': cardData.address_line1,
      'card[address_city]': cardData.address_city,
      'card[address_state]': cardData.address_state,
      'card[address_zip]': cardData.address_zip,
    }),
  }).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      throw new Error(res.statusText);
    }
  });
};

const useCreateStripeToken = () => {
  return useMutation(createStripeToken);
};

export default useCreateStripeToken;
