import { CKAvatar } from 'clearkit';
import gravatarUrl from 'gravatar-url';

import SalesforceOpportunityListItem from './salesforceOpportunity';
import TwoLineMenuItem from './TwoLineMenuItem';

const SalesforceContactMenuItem = ({
  salesforceContact,
  salesforceAccountConnector,
}) => (
  <div>
    <TwoLineMenuItem
      externalLinkClass="text-blue-500"
      href={`${salesforceAccountConnector.externalInstanceUrl}/${salesforceContact.externalId}`}
      icon={
        <CKAvatar
          className="shadow-sm"
          size={32}
          src={gravatarUrl(salesforceContact.email)}
        />
      }
      subtitle={
        <>Owned by {salesforceContact?.owner?.name || 'Unknown User'}</>
      }
      title={`${salesforceContact.name} (${salesforceContact.salesforceAccount?.name})`}
    />

    {salesforceContact?.opportunities?.length > 0 && (
      <span className="px-3 text-sm font-medium text-gray">
        {salesforceContact.opportunities?.length}{' '}
        {salesforceContact.opportunities?.length == 1
          ? 'opportunity'
          : 'opportunities'}
      </span>
    )}

    {salesforceContact?.opportunities?.map((opportunity) => {
      <SalesforceOpportunityListItem
        key={opportunity.externalId}
        salesforceAccountConnector={salesforceAccountConnector}
        salesforceOpportunity={opportunity}
      />;
    })}
  </div>
);

export default SalesforceContactMenuItem;
