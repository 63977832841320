import { HubspotServices, SalesforceServices } from '@clearkit/icons';
import classnames from 'classnames';
import {
  CKContainerProps,
  CKLayoutGrid,
  CKLayoutHorizontal,
  CKSkeleton,
  includeChildrenByDisplayName,
} from 'clearkit';
import { FC } from 'react';

export type CRMExportFieldWrapperProps = CKContainerProps & {
  isLoading?: boolean;
};

type CRMExportFieldComposition = {
  IconSalesforce: FC<CKContainerProps>;
  IconHubspot: FC<CKContainerProps>;
  Input: FC<CKContainerProps>;
  Label: FC<CKContainerProps>;
};

const iconProps = {
  height: 18,
  width: 18,
  className: 'relative top-1',
};

export const iconDisplayName = 'CRMExportFieldWrapper.Icon';
export const inputDisplayName = 'CRMExportFieldWrapper.Input';
export const labelDisplayName = 'CRMExportFieldWrapper.Label';

export const CRMExportFieldWrapper: FC<CRMExportFieldWrapperProps> &
  CRMExportFieldComposition = ({
  className,
  children,
  isLoading = false,
  ...rest
}) => {
  return (
    <CKSkeleton className="w-full h-9" isLoading={isLoading}>
      <CKLayoutGrid
        className={classnames(className, 'items-center')}
        gridTemplateColumns="15rem 1fr"
        {...rest}
      >
        <CKLayoutHorizontal
          alignItems="start"
          justifyContent="start"
          wrap="nowrap"
        >
          {includeChildrenByDisplayName({
            children,
            componentDisplayName: iconDisplayName,
          })}
          {includeChildrenByDisplayName({
            children,
            componentDisplayName: labelDisplayName,
          })}
        </CKLayoutHorizontal>
        {includeChildrenByDisplayName({
          children,
          componentDisplayName: inputDisplayName,
        })}
      </CKLayoutGrid>
    </CKSkeleton>
  );
};

CRMExportFieldWrapper.Label = ({ children }) => {
  return <div className="text-gray-600">{children}</div>;
};
CRMExportFieldWrapper.Label.displayName = labelDisplayName;

CRMExportFieldWrapper.IconSalesforce = () => (
  <SalesforceServices {...iconProps} />
);
CRMExportFieldWrapper.IconSalesforce.displayName = iconDisplayName;

CRMExportFieldWrapper.IconHubspot = () => <HubspotServices {...iconProps} />;
CRMExportFieldWrapper.IconHubspot.displayName = iconDisplayName;

CRMExportFieldWrapper.Input = (props) => <div {...props} />;
CRMExportFieldWrapper.Input.displayName = inputDisplayName;
