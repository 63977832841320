import compact from 'lodash/compact';
import filter from 'lodash/filter';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';

export const getItems = (attributes, category, subCategory = null) => {
  let results = attributes;
  if (category) {
    results = filter(attributes, { category });
  }
  if (subCategory) {
    results = filter(results, { subCategory });
  }
  return results;
};

export const getCategories = (attributes) => {
  return uniq(map(attributes, 'category'));
};

export const getSubCategories = (attributes, category) => {
  const attributesInCategory = filter(attributes, { category });
  const subCategories = map(attributesInCategory, 'subCategory');

  return compact(uniq(subCategories));
};

// We want to be able to mark a field as "legacy".
// This means we don't show it in the segment builder, unless it's already
// been selected.
export const filterLegacyAttributes = (attributes, value) => {
  return attributes.filter((attribute) => {
    return !attribute.details.legacy || attribute.value === value;
  });
};

// This groups options by their sections for attribute searching.
export const groupOptions = (attributes, category, subCategory, value) => {
  let groupedOptions = {};
  let filteredAttributes = filterLegacyAttributes(attributes, value);

  if (category) {
    filteredAttributes = filteredAttributes.filter(
      (attribute) => attribute.category === category,
    );
  }

  if (subCategory) {
    filteredAttributes = filteredAttributes.filter(
      (attribute) => attribute.subCategory === subCategory,
    );
  }

  filteredAttributes.forEach((option) => {
    if (option.subCategory) {
      if (!groupedOptions[option.subCategory]) {
        groupedOptions[option.subCategory] = {
          options: [],
          category: option.category,
          subCategory: option.subCategory,
        };
      }
      groupedOptions[option.subCategory].options.push(option);
    } else {
      if (!groupedOptions[option.category]) {
        groupedOptions[option.category] = {
          options: [],
          category: option.category,
        };
      }
      groupedOptions[option.category].options.push(option);
    }
  });

  Object.keys(groupedOptions).forEach((key) => {
    groupedOptions[key].options = orderBy(
      groupedOptions[key].options,
      (option) => option.label.toLowerCase(),
    );
  });

  return Object.values(groupedOptions);
};
