import styles from './EmptyStates.style.js';
import { EmptySearchIcon } from './icons/';

const EmptySearchResults = ({ classes, filter }) => (
  <div className={classes.container}>
    <EmptySearchIcon />
    <p>
      There are no attributes that match &apos;<span>{filter}</span>&apos;
    </p>
  </div>
);

EmptySearchResults.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
  }),
  filter: PropTypes.string.isRequired,
};

export default withStyles(styles, {
  name: 'AppMultiPicklistEmptySearchResults',
})(EmptySearchResults);
