import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { CKBadge } from 'clearkit';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import { components as ReactSelectComponents } from 'react-select';

import {
  AttributeCategoryIcon,
  getAttributeLabel,
} from '~/components/AttributeCategories';
import Select from '~/components/Select';

import {
  filterLegacyAttributes,
  getCategories,
  getItems,
  getSubCategories,
  groupOptions,
} from '../../utils/attributes';
import BackButton from './icons/BackButton.svg';
import CaretRight from './icons/CaretRight.svg';
import SalesforceNotAllowedAtThisLevel from './SalesforceDisabled';
import styles from './styles';

export class BaseAttributeSelector extends React.Component {
  state = {
    category: undefined,
    subCategory: undefined,
    searchText: undefined,
  };

  componentDidMount() {
    const { category, subCategory } = this.props;
    const attributes = this.attributes();

    if (category) {
      const defaultSubCategory = getSubCategories(attributes, category)[0];
      this.setState({
        category,
        subCategory: subCategory || defaultSubCategory,
      });
      return;
    }
    if (this.categories().length === 1) {
      this.setState({ category: this.categories()[0] });
    }
    const column = this.selectedColumn();
    if (column) {
      const { category, subCategory } = column;
      this.setState({ category, subCategory });
    }
  }

  attributes = () => {
    const { attributes, value } = this.props;

    return filterLegacyAttributes(attributes, value);
  };

  categories = () => {
    const attributes = this.attributes();

    return uniq(attributes.map((attribute) => attribute.category));
  };

  betaCategories = () => {
    const attributes = this.attributes();

    return uniq(
      attributes
        .filter((attribute) => {
          return attribute.details.beta;
        })
        .map((attribute) => attribute.category),
    );
  };

  selectedColumn = () => {
    const { value, category, subCategory } = this.props;
    const column = this.props.attributes.find((c) => {
      return (
        c.value === value &&
        (!category || c.category === category) &&
        (!subCategory || c.subCategory === subCategory)
      );
    });
    return column;
  };

  selectComponents = () => {
    const { classes } = this.props;
    return {
      SingleValue: ({ children, ...props }) => (
        <ReactSelectComponents.SingleValue {...props}>
          <AttributeCategoryIcon
            category={props.data.category}
            className={classes.valueIcon}
          />
          <span className={classes.valueName}>{children}</span>
        </ReactSelectComponents.SingleValue>
      ),
    };
  };

  // Grouped options is used by search to list out grouped search
  groupedOptions = () => {
    const { category, subCategory, value } = this.props;
    const attributes = this.attributes();

    return groupOptions(attributes, category, subCategory, value);
  };

  renderTabs = () => {
    const attributes = this.attributes();
    const { classes } = this.props;
    const { category, subCategory } = this.state;
    const subCategories = getSubCategories(attributes, category);

    return (
      <Tabs
        classes={{ root: classes.tabsRoot }}
        TabIndicatorProps={{
          style: {
            backgroundColor: '#2B9FFD',
          },
        }}
        value={subCategory}
        variant="fullWidth"
      >
        {subCategories.map((subCategory) => (
          <Tab
            classes={{
              root: classes.categoryTabRoot,
              label: classes.categoryTabLabel,
              labelContainer: classes.categoryTabLabelWrapper,
            }}
            key={subCategory}
            label={getAttributeLabel(subCategory)}
            onClick={() => this.setState({ subCategory })}
            value={subCategory}
          />
        ))}
      </Tabs>
    );
  };

  renderSearch = () => {
    const {
      classes,
      menuIsOpen,
      classNamePrefix,
      disabled,
      placeholder,
    } = this.props;
    const { searchText } = this.state;

    return (
      <Select
        async
        autoFocus
        classNamePrefix={classNamePrefix}
        clearable={false}
        components={{
          ...this.selectComponents(),
          Option: ({ children, ...props }) => (
            <ReactSelectComponents.Option {...props}>
              <span className={classes.groupOptionLabel}>{children}</span>
            </ReactSelectComponents.Option>
          ),
        }}
        defaultOptions
        formatGroupLabel={(group) => {
          return (
            <span className={classes.groupHeadingLabel}>
              <AttributeCategoryIcon
                category={group.category}
                className={classes.groupHeadingIcon}
              />
              {group.category} {group.subCategory}
            </span>
          );
        }}
        getOptionLabel={(option) => option.label}
        inputValue={searchText}
        isDisabled={disabled}
        loadOptions={(inputValue, callback) => {
          const options = this.groupedOptions().filter((option) => {
            return option.options.filter((child) =>
              child.label.toLowerCase().indexOf(inputValue.toLowerCase()),
            );
          });
          callback(options);
        }}
        menuIsOpen={menuIsOpen}
        noOptionsMessage={() =>
          searchText ? 'No options found' : 'Start typing to search'
        }
        onChange={(attribute) => {
          const { category, subCategory } = attribute;
          this.setState({ category, subCategory });

          this.props.onChange(attribute);
        }}
        onInputChange={(searchText, { action }) => {
          if (
            !searchText &&
            (action === 'input-blur' || action === 'menu-close')
          ) {
            this.setState({ searchText: undefined });
          } else {
            this.setState({ searchText });
          }
        }}
        openMenuOnFocus
        placeholder={placeholder || 'Filter attributes'}
      />
    );
  };

  renderSelectCategory = () => {
    const {
      classes,
      value,
      menuIsOpen,
      classNamePrefix,
      disabled,
      placeholder,
    } = this.props;
    const attributes = this.attributes();
    const { autoFocus } = this.state;

    let options = getCategories(attributes).map((column) => ({
      label: column,
      value: column,
    }));

    return (
      <Select
        autoFocus={autoFocus}
        classNamePrefix={classNamePrefix}
        components={{
          ...this.selectComponents(),
          Option: ({ children, ...props }) => (
            <ReactSelectComponents.Option {...props}>
              <div className={`${classes.categoryList} justify-between`}>
                <div className="flex flex-row items-center">
                  <AttributeCategoryIcon
                    category={props.data.value}
                    className={classes.categoryIcon}
                  />
                  {children}
                </div>
                <div className="flex flex-row items-center">
                  {this.betaCategories().includes(props.value) && (
                    <CKBadge
                      className={`text-gray-600 bg-gray-200 ${classes.badge} mr-2`}
                      variant="rounded"
                    >
                      Beta
                    </CKBadge>
                  )}
                  <CaretRight className={classes.caret} />
                </div>
              </div>
            </ReactSelectComponents.Option>
          ),
        }}
        getOptionLabel={(option) => getAttributeLabel(option.label)}
        isDisabled={disabled}
        menuIsOpen={menuIsOpen}
        onChange={(column) => {
          this.setState({
            category: column.value,
            subCategory: getSubCategories(attributes, column.value)[0],
            autoFocus: true,
            searchText: undefined,
          });
        }}
        onInputChange={(searchText) => {
          if (searchText) this.setState({ searchText });
        }}
        openMenuOnFocus
        options={options}
        placeholder={placeholder || 'Filter attributes'}
        selectedValue={value}
      />
    );
  };

  showSubCategoryBackButton = () => {
    if (this.props.category) return false;
    if (this.categories().length == 1) return false;

    return true;
  };

  showSubCategoryTabs = () => {
    const { disabledCategories } = this.props;
    const attributes = this.attributes();
    const { category } = this.state;
    const subCategories = getSubCategories(attributes, category);
    const disableCategory = disabledCategories.indexOf(category) > -1;
    if (disableCategory) return false;
    if (this.props.subCategory) return false;
    if (subCategories.length == 0) return false;

    return true;
  };

  renderSelectSubCategory = () => {
    const {
      classes,
      disabledCategories,
      menuIsOpen,
      classNamePrefix,
      disabled,
      placeholder,
    } = this.props;
    const attributes = this.attributes();
    let { category, subCategory, autoFocus } = this.state;

    const showBackButton = this.showSubCategoryBackButton();
    const disableCategory = disabledCategories.indexOf(category) > -1;
    const showTabs = this.showSubCategoryTabs();
    let options = disableCategory
      ? []
      : getItems(attributes, category, subCategory);

    options = orderBy(options, (option) => option.label.toLowerCase());
    options = orderBy(
      options,
      (option) => get(option, 'details.type') !== 'existence_boolean',
    );

    // Special grouping to prioritize existence-boolean items!
    const groupedOptions = [
      {
        label: 'existence boolean',
        options: options.filter(
          (o) => get(o, 'details.type') === 'existence_boolean',
        ),
      },
      {
        label: 'other',
        options: options.filter(
          (o) => get(o, 'details.type') !== 'existence_boolean',
        ),
      },
    ];

    // Because of some ref / lifecycle mounting issues, the voodoo below helps this component render properly
    // by putting it in a <div> element. I have yet to get to the bottom of this.

    return (
      <div>
        <Select
          autoFocus={autoFocus}
          classNamePrefix={classNamePrefix}
          components={{
            ...this.selectComponents(),
            NoOptionsMessage: ({ children, ...props }) => {
              if (disableCategory) return <SalesforceNotAllowedAtThisLevel />;

              return (
                <ReactSelectComponents.NoOptionsMessage {...props}>
                  {children}
                </ReactSelectComponents.NoOptionsMessage>
              );
            },
            Option: ({ children, ...props }) => (
              <ReactSelectComponents.Option {...props}>
                <div className={classes.option}>
                  {get(props, 'data.details.type') === 'existence_boolean' && (
                    <div className={classes.existenceBooleanIndicator}></div>
                  )}
                  {children}
                </div>
              </ReactSelectComponents.Option>
            ),
            GroupHeading: () => <></>,
            Group: (props) => (
              <ReactSelectComponents.Group
                {...props}
                className={classes.optionGroup}
                style={{ background: 'red', padding: 0 }}
              />
            ),
            Menu: (props) => (
              <ReactSelectComponents.Menu {...props}>
                <div className={classes.menuHeader}>
                  <div className={classes.menuHeaderTop}>
                    {showBackButton && (
                      <BackButton
                        className={classes.backButton}
                        onClick={() => {
                          this.setState({
                            category: undefined,
                            subCategory: undefined,
                            autoFocus: true,
                            searchText: undefined,
                          });
                        }}
                        rel="backButton"
                      />
                    )}
                    <AttributeCategoryIcon
                      category={category}
                      className={classes.groupHeadingIcon}
                    />
                    <span className={classes.headerTitle}>
                      {getAttributeLabel(category)}{' '}
                      {getAttributeLabel(subCategory)}
                    </span>
                    {this.betaCategories().includes(category) && (
                      <CKBadge
                        className={`text-gray-600 bg-gray-200 ${classes.badge} ml-2`}
                        variant="rounded"
                      >
                        Beta
                      </CKBadge>
                    )}
                  </div>

                  {showTabs && this.renderTabs()}
                </div>
                {props.children}
              </ReactSelectComponents.Menu>
            ),
          }}
          getOptionLabel={(option) => option.label}
          isDisabled={disabled}
          menuIsOpen={menuIsOpen}
          onChange={this.props.onChange}
          onInputChange={(searchText) => {
            if (searchText) this.setState({ searchText });
          }}
          openMenuOnFocus
          options={groupedOptions}
          placeholder={placeholder || 'Filter attributes'}
          value={this.selectedColumn()}
        />
      </div>
    );
  };

  render() {
    const { className } = this.props;
    const { category, searchText } = this.state;
    const showSearch = searchText !== undefined;
    const singleCategory = this.categories().length === 1;

    let contents = this.renderSelectCategory();

    if (singleCategory) {
      contents = this.renderSelectSubCategory();
    } else if (showSearch) {
      contents = this.renderSearch();
    } else if (category) {
      contents = this.renderSelectSubCategory();
    }

    return <div className={className}>{contents}</div>;
  }
}

BaseAttributeSelector.defaultProps = {
  classes: {},
  onChange: () => {},
  attributes: [],
  disabledCategories: [],
  disabled: false,
};

export const StyledAttributeSelector = withStyles(styles, {
  name: 'SearchBuilderBaseAttributeSelector',
})(BaseAttributeSelector);

BaseAttributeSelector.propTypes = {
  classNamePrefix: PropTypes.string,
  menuIsOpen: PropTypes.bool,
  classes: PropTypes.object,
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  category: PropTypes.string,
  subCategory: PropTypes.string,
  disabled: PropTypes.bool,
  disabledCategories: PropTypes.arrayOf(PropTypes.string),
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      category: PropTypes.string,
      subCategory: PropTypes.string,
    }),
  ),
};

export default StyledAttributeSelector;
