import { useMutation } from '@apollo/client';
import Switch from '@material-ui/core/Switch';

import Tooltip from '~/components/RedesignedTooltip';
import {
  trackDestinationDisabled,
  trackDestinationEnabled,
} from '~/lib/segmentAnalytics';

import Check from './Check.svg';
import mutation from './mutation.graphql';
import styles from './styles.style';

function DestinationEnabledSwitch(props) {
  const {
    className,
    destination,
    destination: { segment, enabled: destinationEnabled },
    classes,
    disabled,
  } = props;

  const handleChange = (toggleEnabled) => {
    const enabled = !destination.enabled;

    let { enabledDestinationsCount } = segment;
    enabledDestinationsCount += enabled ? 1 : -1;

    toggleEnabled({
      optimisticResponse: {
        __typename: 'Mutation',
        response: {
          __typename: 'DestinationsUpdatePayload',
          node: {
            __typename: 'Destination',
            id: destination.id,
            enabled,
            segment: {
              ...segment,
              enabledDestinationsCount,
            },
          },
        },
      },
      variables: {
        input: {
          id: destination.id,
          enabled,
        },
      },
    });
  };

  const trackToggleEnabled = ({
    response: {
      node: { enabled },
    },
  }) => {
    if (enabled) {
      trackDestinationEnabled(destination, segment);
    } else {
      trackDestinationDisabled(destination, segment);
    }
  };

  const [toggleEnabled] = useMutation(mutation, {
    onCompleted: trackToggleEnabled,
  });

  return (
    <Tooltip title={destinationEnabled ? 'Enabled' : 'Disabled'}>
      <Switch
        checked={!!destinationEnabled}
        checkedIcon={<Check className={classes.checkedIcon} />}
        classes={{
          switchBase: destinationEnabled ? classes.colorSwitchBase : '',
          checked: destinationEnabled ? classes.colorChecked : '',
          bar: destinationEnabled ? classes.greenBar : classes.bar,
          icon: destinationEnabled ? classes.enabledIcon : classes.icon,
        }}
        className={className}
        disabled={!!disabled}
        onChange={() => {
          handleChange(toggleEnabled);
        }}
      />
    </Tooltip>
  );
}

DestinationEnabledSwitch.propTypes = {
  className: PropTypes.string,
  destination: PropTypes.object.isRequired,
  classes: PropTypes.object,
  disabled: PropTypes.bool,
};

export default withStyles(styles)(DestinationEnabledSwitch);
