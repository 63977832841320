import classnames from 'classnames';
import { CKContainerProps, CKDetailGroup, CKNavigationBar } from 'clearkit';
import { useState } from 'react';

import { NavRouterItem } from '~/components/NavBar/NavRouterItem';

import { ProspectList } from '../../../generated/graphql';

type ListProps = CKContainerProps & {
  items: ProspectList[];
  title: string;
};

export const List = ({
  className,
  children,
  items,
  title,
  ...rest
}: ListProps) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <CKDetailGroup
      className={classnames(className, 'min-w-0')}
      {...rest}
      isOpen={isOpen}
      onToggle={() => setIsOpen(!isOpen)}
    >
      <CKDetailGroup.TriggerChevron className="flex justify-between w-full !pl-3">
        <strong className="font-medium">{title}</strong>
      </CKDetailGroup.TriggerChevron>
      <CKDetailGroup.Group className="space-y-1">
        {items.map((item, index) => (
          <NavRouterItem
            className="!font-normal"
            key={index}
            label={item.name}
            to={`/prospector/list/${item.id}`}
          >
            <CKNavigationBar.ItemLeft>{children}</CKNavigationBar.ItemLeft>
          </NavRouterItem>
        ))}
      </CKDetailGroup.Group>
    </CKDetailGroup>
  );
};
