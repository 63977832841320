import attributeTypes from './attributeTypes';
import { getComparator } from './nodeUtils';

export function isMultiColumnStringAttribute(attribute) {
  return attribute?.details?.type === attributeTypes.MULTI_COLUMN_STRING;
}

export function isRollupAttribute(attribute) {
  return attribute.details.type === 'nested_select';
}

export function isRollupNode(node) {
  if (node.parentAttribute) {
    return true;
  }

  return false;
}

export const changeMultiColumnStringComparator = (
  node,
  newComparator,
  attribute,
) => {
  const {
    details: { columns },
  } = attribute;

  // Clear out the subquery
  delete node.and;
  delete node.or;

  // Build a subquery
  const subQuery = columns.map((column) => ({
    [newComparator]: {
      attribute: column,
      value: '',
    },
  }));

  switch (newComparator) {
    case 'match_any':
    case 'contains_all':
    case 'contains_any':
    case 'exists':
      node['or'] = subQuery;
      break;
    case 'not_match_any':
    case 'not_contains_all':
    case 'not_exists':
      node['and'] = subQuery;
      break;
    default:
      break;
  }

  return node;
};

// Rollup fields are hard. Here are some of the names I'm using.
// {
//   "parentAttribute": "company->tech_categories",  // parent attribute
//   "comparator": "array_contains_all",  // comparator
//   "and": [
//     {
//       "array_contains_all": {  // subComparator
//         "value": "madison_logic", // single value in the dropdown
//         "attribute": "company->tech"  // that value's attribute
//       }
//     }
//   ]
// }

// For a rollup column, changing the operator means
// clearing the values, changing the key (if it's contains/not contains)
export const changeRollupComparator = (node, newComparator) => {
  let comparator = getRollupComparator(node);
  if (comparator === newComparator) return node;

  // Take all the previous sub-conditions, change their subComparator keys,
  // and we'll add them back depending on the comparator
  const previousConditions = node.and || node.or;
  const updatedConditions =
    previousConditions?.map((condition) => {
      const key = Object.keys(condition)[0];
      const value = condition[key];
      const newSubComparator = getRollupSubComparator(newComparator);
      condition = { [newSubComparator]: value };
      return condition;
    }) || [];

  delete node.and;
  delete node.or;
  delete node[comparator];

  const attribute = node.parentAttribute;
  node.comparator = newComparator;

  if (
    [
      'match_any',
      'not_match_any',
      'array_contains_all',
      'array_contains',
      'array_not_contains',
    ].includes(newComparator)
  ) {
    delete node[newComparator];
    const andOrOr = getRollupOperator(node);
    node[andOrOr] = updatedConditions;
  } else {
    // Changing to a blank / not-blank comparator
    node[newComparator] = { attribute };
  }

  return node;
};

export function getRollupComparator(node) {
  if (node.comparator) return node.comparator;

  if (node.or || node.and) {
    const andOrOr = node.or || node.and;
    if (andOrOr.length > 0) {
      const firstCriterion = andOrOr[0];
      const comparator = getComparator(firstCriterion);

      // Rollup nodes can have single match/not_match comparators
      // becuase they're only checking one value
      if (comparator === 'match') return 'match_any';
      if (comparator === 'not_match') return 'not_match_any';

      return comparator;
    }
  }

  if (node.or) return 'match_any';
  if (node.and) return 'not_match_any';
  if (node.exists) return 'exists';
  if (node.not_exists) return 'not_exists';
}

function getRollupSubComparator(comparator) {
  switch (comparator) {
    case 'match_any':
    case 'match_all':
      return 'match';
    case 'array_contains_all':
      return 'array_contains';
    case 'not_match_any':
      return 'not_match';
    default:
      return comparator;
  }
}

// Returns 'and' or 'or' depending on the comparator
export function getRollupOperator(node) {
  const comparator = getRollupComparator(node);

  switch (comparator) {
    case 'match_any':
    case 'array_contains':
      return 'or';
    case 'not_match_any':
    case 'array_not_contains':
    case 'array_contains_all':
      return 'and';
    default:
      return 'and';
  }
}

export function setRollupNodeProperties(node, properties) {
  const comparator = getRollupComparator(node);
  const subComparator = getRollupSubComparator(comparator);
  const andOrOr = getRollupOperator(node);

  node[andOrOr] = properties.map((property) => ({
    [subComparator]: property,
  }));
  return node;
}

export function getRollupNodeValue(node) {
  const attributes = node['and'] || node['or'];

  if (!attributes) return [];

  return attributes
    .map((a) => {
      const operator = getComparator(a);
      return a[operator];
    })
    .map(({ value, attribute }) => ({ value, attribute }));
}

export function getSubQuery(node) {
  return node.and || node.or;
}
