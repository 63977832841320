import MaterialTooltip from '@material-ui/core/Tooltip';

export default function RedesignedTooltip({ children, title, ...otherProps }) {
  if (!title) {
    return children;
  }

  return (
    <MaterialTooltip title={title} {...otherProps}>
      {children}
    </MaterialTooltip>
  );
}

RedesignedTooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
};
