import { CKSelectableItem, CKSelectPill } from 'clearkit';
import capitalize from 'lodash/capitalize';
import { FC } from 'react';

import InfoTooltip from '~/components/InfoTooltip';

type TimeDay = 'am' | 'pm';

type DeliveryScheduleProps = {
  frequency: string;
  scheduleDay: number;
  scheduleHour: number;
  scheduleStartOfPeriod: number;
  setFrequency: (value: string) => void;
  setScheduleDay: (value: number) => void;
  setScheduleHour: (newValue: number) => void;
  setScheduleStartOfPeriod: (newValue: number) => void;
};

export const DeliverySchedule: FC<DeliveryScheduleProps> = ({
  frequency,
  scheduleDay,
  scheduleHour,
  scheduleStartOfPeriod,
  setFrequency,
  setScheduleDay,
  setScheduleHour,
  setScheduleStartOfPeriod,
}) => {
  const handleHourAndAmPmChange = (hour: number, amPm: TimeDay) => {
    if (amPm === 'pm' && hour === 12) {
      // midday
      hour = 12;
    } else if (amPm === 'am' && hour === 12) {
      // midnight
      hour = 0;
    } else if (amPm === 'pm' && hour < 12) {
      // afternoon
      hour += 12;
    } else if (amPm === 'am' && hour > 12) {
      // morning
      hour -= 12;
    }

    setScheduleHour(hour);
  };

  const handleAmPmChange = (value: TimeDay) => {
    handleHourAndAmPmChange(scheduleHour, value);
  };

  const handleHourChange = (value: number) => {
    let amPm: TimeDay = 'am';

    if (scheduleHour === 0) {
      amPm = 'am';
    } else if (scheduleHour === 12) {
      amPm = 'pm';
    } else if (scheduleHour < 12) {
      amPm = 'am';
    } else if (scheduleHour > 12) {
      amPm = 'pm';
    }

    handleHourAndAmPmChange(value, amPm);
  };

  const frequencyItems = [
    { label: 'Hourly', value: 'hourly' },
    { label: 'Daily', value: 'daily' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' },
  ] as CKSelectableItem[];

  const scheduleDayItems = [
    { label: 'first', value: '0' },
    { label: 'last', value: '1' },
  ] as CKSelectableItem[];

  const dayItems = [
    { label: 'Sunday', value: '0' },
    { label: 'Monday', value: '1' },
    { label: 'Tuesday', value: '2' },
    { label: 'Wednesday', value: '3' },
    { label: 'Thursday', value: '4' },
    { label: 'Friday', value: '5' },
    { label: 'Saturday', value: '6' },
  ] as CKSelectableItem[];

  const amPmItems = [
    { label: 'am', value: 'am' },
    { label: 'pm', value: 'pm' },
  ] as CKSelectableItem[];

  const selectedDayItem = {
    value: (scheduleDay || 0).toString(),
    label: dayItems[scheduleDay || 0].label,
  } as CKSelectableItem;

  const hourMenuItems: CKSelectableItem[] = [];

  for (let i = 1; i <= 12; i++) {
    hourMenuItems.push({ value: i.toString(), label: i.toString() });
  }

  const amPmValue = scheduleHour >= 12 ? 'pm' : 'am';

  const scheduleHourValue = scheduleHour === 0 ? 12 : scheduleHour;
  const scheduleHourValueLabel = (scheduleHourValue >= 12
    ? scheduleHourValue - 12
    : scheduleHourValue
  ).toString();

  const containerClasses = 'flex flex-auto flex-grow-0 items-center gap-4';

  return (
    <>
      <div className="flex items-center mb-4">
        Schedule email digest
        <InfoTooltip title="Select how often the email digest should be sent." />
      </div>

      <div className="flex flex-wrap items-center gap-4">
        <CKSelectPill
          isFullWidth={false}
          items={frequencyItems}
          onChange={(newValue) => setFrequency(newValue.value)}
          placeholder="Select frequency"
          value={{ value: frequency, label: capitalize(frequency) }}
        />
        {frequency === 'monthly' && (
          <div className={`${containerClasses} -mr-2`}>
            <span className="flex-shrink-0">on the</span>
            <CKSelectPill
              items={scheduleDayItems}
              onChange={(newValue) => {
                setScheduleStartOfPeriod(parseInt(newValue.value));
              }}
              placeholder="Schedule day"
              value={{
                value: scheduleStartOfPeriod.toString(),
                label: scheduleDayItems[scheduleStartOfPeriod].label,
              }}
            />
            <CKSelectPill
              items={dayItems}
              onChange={(newValue) => {
                setScheduleDay(parseInt(newValue.value));
              }}
              placeholder="Select day"
              value={selectedDayItem}
            />
            <span className="flex-grow-0 flex-shrink-0">of the month</span>
          </div>
        )}

        {frequency === 'weekly' && (
          <div className={containerClasses}>
            on
            <CKSelectPill
              items={dayItems}
              onChange={(newValue) => {
                setScheduleDay(parseInt(newValue.value));
              }}
              placeholder="Select day"
              value={selectedDayItem}
            />
          </div>
        )}

        {frequency !== 'hourly' && (
          <div className={containerClasses}>
            at
            <CKSelectPill
              items={hourMenuItems}
              onChange={(newValue) =>
                handleHourChange(parseInt(newValue.value))
              }
              placeholder="Select hour"
              value={{
                value: scheduleHourValue.toString(),
                label: scheduleHourValueLabel,
              }}
            />
            <CKSelectPill
              items={amPmItems}
              onChange={(newValue) =>
                handleAmPmChange(newValue.value as TimeDay)
              }
              placeholder="Select am/pm"
              value={{
                value: amPmValue,
                label: amPmValue,
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};
