import classnames from 'classnames';
import {
  CKContainerProps,
  CKIconBox,
  CKSkeleton,
  CKTooltip,
  cloneChildren,
} from 'clearkit';
import { capitalize } from 'lodash';

type CRMTokenProps = CKContainerProps & {
  activeClassName: string;
  inCrm: boolean;
  isCrmConnected?: boolean;
  isLoading?: boolean;
  service: 'salesforce' | 'hubspot';
};

export const CRMToken = ({
  activeClassName,
  children,
  inCrm,
  isCrmConnected = false,
  isLoading = false,
  service,
}: CRMTokenProps) => {
  const notConnectedMessage = 'HubSpot integration is not enabled';
  const message = `Prospect ${!inCrm ? 'not ' : ''}in ${capitalize(service)}`;

  return (
    <CKSkeleton className="w-6 h-6 rounded" isLoading={isLoading}>
      <CKTooltip
        targetClassName="flex basis-auto shrink-0 grow-0"
        tooltip={isCrmConnected ? message : notConnectedMessage}
      >
        <CKIconBox className="!p-1">
          {cloneChildren({
            children,
            newProps: {
              className: classnames('text-gray-300', {
                [activeClassName]: inCrm,
              }),
              height: 16,
              width: 16,
            },
          })}
        </CKIconBox>
      </CKTooltip>
    </CKSkeleton>
  );
};
