import { ConfigConnectedIndicators as ConfigConnected } from '@clearkit/icons';
import { CKBox, CKMultilineTextField, CKTextField } from 'clearkit';
import capitalize from 'lodash/capitalize';

const WebhookConfigCard = ({
  objectType,
  currentAccount,
  accountConnector,
}) => {
  const webHookUrl = `https://marketo-webhooks.clearbit.com/v1/marketo/webhooks?clearbit_account_id=${currentAccount.id}&account_id=${accountConnector.remoteMeta.serviceId}&object_type=${objectType}&id={{${objectType}.Id}}&email={{${objectType}.Email Address}}&first_name={{${objectType}.First Name}}&last_name={{${objectType}.Last Name}}&anonymous_ip={{${objectType}.Anonymous IP}}`;
  return (
    <CKBox className="w-6/12 h-full max-w-md p-4 space-y-4" variant="card">
      <div className="py-4 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <div className="flex-shrink-0 rounded-full">
              <ConfigConnected />
            </div>
            <div className="text-black">
              <p className="text-sm font-medium capitalize">
                Receiving Webhooks
              </p>
              <p className="text-sm opacity-75">
                New {capitalize(objectType)} Enrichment Enabled
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="space-y-4">
        <div>
          <label className="block mb-1 text-sm text-gray-700" htmlFor="url">
            Webhook URL
          </label>
          <CKMultilineTextField
            className="w-full"
            data-testid="webhookUrl"
            name="url"
            readOnly
            rows={4}
            value={webHookUrl}
          ></CKMultilineTextField>
        </div>

        <div>
          <label
            className="block mb-1 text-sm text-gray-700"
            htmlFor="requestType"
          >
            Webhook Request Type
          </label>
          <CKTextField
            className="w-full"
            data-testid="requestType"
            id="requestType"
            name="requestType"
            placeholder="Enter value"
            readOnly
            type="text"
            value="GET"
          />
        </div>
        <div>
          <label
            className="block mb-1 text-sm text-gray-700"
            htmlFor="responseType"
          >
            Webhook Response Type
          </label>
          <CKTextField
            className="w-full"
            data-testid="responseType"
            id="responseType"
            name="responseType"
            placeholder="Enter value"
            readOnly
            type="text"
            value="None"
          />
        </div>

        <hr className="border-gray-100" />
        <div>
          <label className="block mb-1 text-sm text-gray-700" htmlFor="header">
            Webhook Header
          </label>
          <CKTextField
            className="w-full"
            data-testid="webhookHeader"
            id="header"
            name="header"
            readOnly
            type="text"
            value="Authorization"
          />
        </div>
        <div>
          <label className="block mb-1 text-sm text-gray-700" htmlFor="value">
            Webhook Authorization Header Value
          </label>
          <CKTextField
            className="w-full"
            data-testid="webhookHeaderValue"
            id="value"
            name="value"
            readOnly
            type="text"
            value={`Bearer ${accountConnector.webhookPassword}`}
          />
        </div>
      </div>
    </CKBox>
  );
};

export default WebhookConfigCard;
