import MenuItem from '@material-ui/core/MenuItem';

const CategoryHeader = ({ classes, icon, label, style }) => (
  <MenuItem
    classes={{
      root: classes.categoryHeaderRoot,
      disabled: '',
    }}
    disabled
    style={style}
  >
    {icon}
    {label}
  </MenuItem>
);

CategoryHeader.propTypes = {
  classes: PropTypes.shape({
    categoryHeaderRoot: PropTypes.string.isRequired,
    disabled: PropTypes.string,
  }).isRequired,
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  style: PropTypes.object,
};

export default CategoryHeader;
