import _ from 'lodash';

import { EMPTY_STATE_SEARCH } from '~/components/SearchBuilder/utils/constants';

export const ATTRIBUTE_TYPES_TO_REMOVE = ['existence_boolean'];

export const isEmptySearch = (providedSearch) => {
  const search = { ...providedSearch };
  const emptySearch = { ...EMPTY_STATE_SEARCH };

  // Ignore versions, they come back from some implementations of the
  // search builder but not others.
  delete search.version;
  delete emptySearch.version;

  return _.isEqual(search, emptySearch);
};
