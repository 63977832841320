import { Checkmark } from '@clearkit/icons';
import classnames from 'classnames';
import { CKContainerProps } from 'clearkit';
import { useEffect, useRef } from 'react';

type FauxCheckboxProps = CKContainerProps & {
  checked: boolean;
  isIndeterminate?: boolean;
};

export const FauxCheckbox = ({
  checked,
  className,
  isIndeterminate,
  ...rest
}: FauxCheckboxProps) => {
  const checkboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isIndeterminate && checkboxRef.current) {
      checkboxRef.current.indeterminate = isIndeterminate;
    }
  }, []);

  return (
    <span {...rest} className={classnames('ck-checkbox', className)}>
      <input
        className="sr-only !absolute ck-checkbox-input user-select-none"
        ref={checkboxRef}
        tabIndex={-1}
        type="checkbox"
      />
      <span className="ck-checkbox-label group">
        <span
          className={classnames('ck-checkbox-box -mt-1.5', {
            'ck-checkbox-box--selected': checked,
          })}
        >
          {!isIndeterminate ? <Checkmark height="14" width="14" /> : null}
        </span>
      </span>
    </span>
  );
};
