import { CKCardCollapsible } from 'clearkit';

import EmptyFieldMappingFields from '../EmptyFieldMappingFields';
import FieldMappingFooter from '../FieldMappingFooter';
import FieldMappingFormFields from '../FieldMappingFormFields';
import FieldMappingHeaders from '../FieldMappingHeaders';
import FieldMappingHeader from './FieldMappingHeader';

const BetaFieldMappingForm = ({
  integration,
  objectType,
  addMapping,
  areFieldMappingsInvalid,
  isUpdating,
  isSaving,
  onSave,
  setShowInvalidFields,
  fieldMappings,
  fieldErrors,
  sortedMappings,
  handleMappingChange,
  applyChangesToAllGroupMembers,
  removeMappingGroup,
  removeMapping,
  onClickRefreshAttributes,
  showInvalidFields,
  targetAttributes,
  sourceAttributes,
  setFieldMappings,
  savedFieldMappings,
  lastSavedMappings,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <CKCardCollapsible
      className="mt-10"
      isOpen={isOpen}
      onToggle={handleToggle}
    >
      <CKCardCollapsible.Header>
        <FieldMappingHeader
          fieldMappings={fieldMappings}
          integration={integration}
          objectType={objectType}
        />
      </CKCardCollapsible.Header>
      <CKCardCollapsible.Body>
        {fieldMappings.length === 0 ? (
          <EmptyFieldMappingFields
            integration={integration}
            objectType={objectType}
          />
        ) : (
          <div>
            <FieldMappingHeaders />
            <FieldMappingFormFields
              addMapping={addMapping}
              applyChangesToAllGroupMembers={applyChangesToAllGroupMembers}
              fieldErrors={fieldErrors}
              handleMappingChange={handleMappingChange}
              integration={integration}
              objectType={objectType}
              onClickRefreshAttributes={onClickRefreshAttributes}
              removeMapping={removeMapping}
              removeMappingGroup={removeMappingGroup}
              showInvalidFields={showInvalidFields}
              sortedMappings={sortedMappings}
              sourceAttributes={sourceAttributes}
              targetAttributes={targetAttributes}
            />
          </div>
        )}
        <CKCardCollapsible.Footer>
          <FieldMappingFooter
            addMapping={addMapping}
            areFieldMappingsInvalid={areFieldMappingsInvalid}
            fieldMappings={fieldMappings}
            isSaving={isSaving}
            isUpdating={isUpdating}
            lastSavedMappings={lastSavedMappings}
            onSave={onSave}
            savedFieldMappings={savedFieldMappings}
            setFieldMappings={setFieldMappings}
            setShowInvalidFields={setShowInvalidFields}
          />
        </CKCardCollapsible.Footer>
      </CKCardCollapsible.Body>
    </CKCardCollapsible>
  );
};

export default BetaFieldMappingForm;
