import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import snakeCase from 'lodash/snakeCase';

import { paginateData } from './graphql/pagination';

/*
 * https://github.com/apollographql/apollo-client/blob/master/src/core/networkStatus.ts
 */

const networkStatuses = {
  polling: 6,
};

const fetchPolicy = 'cache-and-network';
const partialRefetch = true;

/*
 * We want to continue polling unless we specifically get a GraphQL error, or
 * the server is returning 500s.
 *
 * In case of intermittent errors we show potentially stale data, ignore the
 * error, and continue polling.
 */

function isIgnorableError(error) {
  // We want to message GraphQL errors to the user
  if (error.graphQLErrors?.length) {
    return false;
  }

  // If we're getting 500s, there's no point in continuing to poll.
  if (error.networkError && error.networkError.statusCode === 500) {
    return false;
  }

  return true;
}

/**
 * A more opinionated `useQuery` that is backwards compatible.
 *
 * - abstracts out all edges, nodes, page info and flattens them out into `data`
 * - supports conditional polling via `pollInterval` as a function
 * - returns a `polling` property
 */

export function useFancyQuery(
  query,
  { fields, pollInterval, ...queryOptions } = {},
) {
  const pollHandler =
    typeof pollInterval === 'function' ? pollInterval : undefined;

  const normalizedPollInterval = pollHandler ? 0 : pollInterval;

  const {
    data,
    error,
    networkStatus,
    startPolling,
    stopPolling,
    ...queryResult
  } = useQuery(query, {
    fetchPolicy,
    partialRefetch,
    pollInterval: normalizedPollInterval,
    ...queryOptions,
  });

  const normalizedData = paginateData(data, { fields });
  const polling = networkStatus === networkStatuses.polling;

  useEffect(() => {
    if (!pollHandler) {
      return;
    }

    const pollHandlerInterval = pollHandler(normalizedData);

    if (pollHandlerInterval && (!error || isIgnorableError(error))) {
      if (!polling) startPolling(pollHandlerInterval);
    } else {
      stopPolling();
    }

    return () => {
      stopPolling();
    };
  }, [normalizedData, pollHandler, error, startPolling, stopPolling]);

  let normalizedError = error;

  if (error && isIgnorableError(error)) {
    if (process.env.NODE_ENV !== 'production') {
      /* eslint-disable-next-line no-console */
      console.warn(
        'Hello hello! useFancyQuery is deeming the next error "ignorable" because it has no status code',
      );
    }

    /* eslint-disable-next-line no-console */
    console.error(error);

    normalizedError = undefined;
  }

  return {
    data: normalizedData,
    error: normalizedError,
    networkStatus,
    polling,
    startPolling,
    stopPolling,
    ...queryResult,
  };
}

export function errorMessage(errors, name, format = true) {
  const error = (errors || []).find(({ field }) =>
    fieldMatch(field, name, format),
  );

  if (!error) return undefined;

  let message = get(error, 'messages[0]');

  return message;
}

export function hasError(errors, name, format = true) {
  return !!errorMessage(errors, name, format);
}

function fieldMatch(field, name, format) {
  return format ? field === snakeCase(name) : field === name;
}
