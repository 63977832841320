/**
 * This implements a client for the GraphQL API that works outside
 * of our code-generated hooks. We've implemented it this way because
 * we're managing fetching, error handling, and loading outside of the
 * React lifecycle in a state machine. This fits more cleanly into this
 * paradigm because we can initialize the machine without having to inject
 * the results of a hook into it.
 *
 * In the future we will likely want to codegen this client as well
 * because there are quite a few places where we do this and will continue
 * to do so.
 */
import { ApolloCache, FetchResult } from '@apollo/client';

import apollo from '~/lib/apollo';
import { trackEvent } from '~/lib/tracking';

import { convertExportFieldMappingToAttributeArray } from '../util';
import cancelProspectListExportMutation from './queries/cancelProspectListExport.graphql';
import copyProspectListMutation from './queries/copyProspectList.graphql';
import createProspectListMutation from './queries/createProspectList.graphql';
import destroyProspectListMutation from './queries/destroyProspectList.graphql';
import downloadProspectListExportMutation from './queries/downloadProspectListExport.graphql';
import exportProspectList from './queries/exportProspectList.graphql';
import prospectFiltersQuery from './queries/prospectFiltersQuery.graphql';
import prospectListExportQuery from './queries/prospectListExportQuery.graphql';
import prospectListExportsQuery from './queries/prospectListExportsQuery.graphql';
import prospectListQuery from './queries/prospectListQuery.graphql';
import prospectListsQuery from './queries/prospectListsQuery.graphql';
import prospectsQuery from './queries/prospectsQuery.graphql';
import updateProspectListMutation from './queries/updateProspectList.graphql';

function contextToQueryArguments(context: any) {
  return {
    countries: context.personQuery.countries ?? [],
    domains: context.companyQuery.domains ?? [],
    names: context.personQuery.names ?? [],
    roles: context.personQuery.roles ?? [],
    seniorities: context.personQuery.seniorities ?? [],
    titles: context.personQuery.titles ?? [],
    employeesRanges: context.companyQuery.employeesRanges ?? [],
    companyCountries: context.companyQuery.companyCountries ?? [],
    companyStates: context.companyQuery.companyStates ?? [],
    companyTags: context.companyQuery.companyTags ?? [],
    companyTech: context.companyQuery.companyTech ?? [],
    industries: context.companyQuery.industries ?? [],
    cities: context.personQuery.cities ?? [],
    companyCities: context.companyQuery.companyCities ?? [],
    states: context.personQuery.states ?? [],
  };
}

export async function fetchProspects(context: any) {
  const { data, error } = await apollo.query({
    query: prospectsQuery,
    variables: {
      ...contextToQueryArguments(context),
      page: context.currentPage ?? 1,
    },
  });

  if (error) {
    throw new Error('Error fetching prospects');
  }

  return data;
}

export async function fetchProspectList(context: any) {
  if (!context.id) {
    throw new Error('No prospect list ID provided');
  }

  const { data, error } = await apollo.query({
    query: prospectListQuery,
    variables: {
      id: context.id,
    },
  });

  if (error) {
    throw new Error('Error fetching prospect list');
  }

  trackEvent('prospect_list_loaded', {
    listId: context.id,
  });

  return data;
}

export async function fetchProspectLists(_context: any) {
  const { data, error } = await apollo.query({
    query: prospectListsQuery,
  });

  if (error) {
    throw new Error('Error fetching prospect lists');
  }

  return data;
}

export async function fetchProspectListExports(_context: any) {
  const { data, error } = await apollo.query({
    query: prospectListExportsQuery,
    fetchPolicy: 'no-cache',
  });

  if (error) {
    throw new Error('Error fetching prospect list exports');
  }

  return data;
}

export async function fetchProspectListExport(id: string) {
  const { data, error } = await apollo.query({
    query: prospectListExportQuery,
    variables: {
      id,
    },
  });

  if (error) {
    throw new Error('Error fetching prospect list export');
  }

  return data;
}

export async function fetchProspectFilters(_context: any) {
  const { data, error } = await apollo.query({
    query: prospectFiltersQuery,
  });

  if (error) {
    throw new Error('Error fetching prospect filters');
  }

  return data;
}

export async function updateProspectList(context: any) {
  const { errors } = await apollo.mutate({
    mutation: updateProspectListMutation,
    variables: {
      input: {
        id: context.id,
        name: context.nameEdit,
        ...contextToQueryArguments(context),
      },
    },
  });

  if (errors?.length) {
    throw new Error('Error updating prospect list');
  }

  trackEvent('prospect_list_updated', {
    listId: context.id,
    listName: context.nameEdit,
    source: context.source,
    ...contextToQueryArguments(context),
  });
}

export async function createProspectList(context: any) {
  const { data, errors } = await apollo.mutate({
    mutation: createProspectListMutation,
    update: updateProspectListCache,
    variables: {
      input: {
        name: context.nameEdit,
        ...contextToQueryArguments(context),
      },
    },
  });

  if (errors?.length || data?.createProspectList?.errors?.length) {
    throw new Error('Error creating prospect list');
  }

  trackEvent('prospect_list_created', {
    listName: context.nameEdit,
    source: context.source,
    ...contextToQueryArguments(context),
  });

  return data;
}

export async function createProspectListExport(context: any) {
  const prospectIds = context.selections.map((id: string) => id);

  const { data, errors } = await apollo.mutate({
    mutation: exportProspectList,
    variables: {
      input: {
        exportType: 'csv',
        prospectIds,
        prospectListId: context.id,
        ...contextToQueryArguments(context),
      },
    },
  });

  if (errors?.length) {
    throw new Error('Error creating prospect list export');
  }

  trackEvent('prospect_list_exported', {
    exportType: 'csv',
    count: prospectIds.length,
    listId: context.id,
    source: context.source,
    ...contextToQueryArguments(context),
  });

  return data;
}

export async function createProspectListSalesforceExport(
  context: any,
  event: any,
) {
  const prospectIds = context.selections.map((id: string) => id);

  const { data, errors } = await apollo.mutate({
    mutation: exportProspectList,
    variables: {
      input: {
        exportType: 'salesforce',
        prospectIds,
        prospectListId: context.id,
        ...contextToQueryArguments(context),
        objectType: event.recordType,
        mapping: convertExportFieldMappingToAttributeArray(event.mapping),
      },
    },
  });

  if (errors?.length) {
    throw new Error('Error creating prospect list export');
  }

  trackEvent('prospect_list_exported', {
    exportType: 'salesforce',
    count: prospectIds.length,
    listId: context.id,
    source: context.source,
    ...contextToQueryArguments(context),
  });

  return data;
}

export async function createProspectListHubspotExport(
  context: any,
  event: any,
) {
  const prospectIds = context.selections.map((id: string) => id);

  const { data, errors } = await apollo.mutate({
    mutation: exportProspectList,
    variables: {
      input: {
        exportType: 'hubspot',
        prospectIds,
        prospectListId: context.id,
        ...contextToQueryArguments(context),
        objectType: event.recordType,
        mapping: convertExportFieldMappingToAttributeArray(event.mapping),
      },
    },
  });

  if (errors?.length) {
    throw new Error('Error creating prospect list export');
  }

  trackEvent('prospect_list_exported', {
    exportType: 'hubspot',
    count: prospectIds.length,
    listId: context.id,
    source: context.source,
    ...contextToQueryArguments(context),
  });

  return data;
}

export async function cancelProspectListExport(_context: any, event: any) {
  const { data, errors } = await apollo.mutate({
    mutation: cancelProspectListExportMutation,
    variables: {
      input: {
        id: event.id,
      },
    },
  });

  if (errors?.length) {
    throw new Error('Error canceling prospect list export');
  }

  return data;
}

export async function downloadProspectListExport(_context: any, event: any) {
  const { data, errors } = await apollo.mutate({
    mutation: downloadProspectListExportMutation,
    variables: {
      input: {
        id: event.id,
      },
    },
  });

  if (errors?.length) {
    throw new Error('Error downloading prospect list export');
  }

  trackEvent('prospect_list_export_downloaded', {
    listId: event.id,
  });

  return data;
}

export async function copyProspectList(context: any) {
  const { data, errors } = await apollo.mutate({
    mutation: copyProspectListMutation,
    update: updateProspectListCache,
    variables: {
      input: {
        id: context.id,
        name: context.nameEdit,
      },
    },
  });

  if (errors?.length) {
    throw new Error('Error copying prospect list');
  }

  trackEvent('prospect_list_duplicated', {
    originalListId: context.id,
    newListName: context.nameEdit,
    ...contextToQueryArguments(context),
  });

  return data;
}

export async function destroyProspectList(context: any) {
  const { errors } = await apollo.mutate({
    mutation: destroyProspectListMutation,
    variables: {
      input: {
        id: context.id,
      },
    },
    update(cache) {
      const normalizedId = cache.identify({
        __typename: 'ProspectList',
        id: context.id,
      });

      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  if (errors?.length) {
    throw new Error('Error destroying prospect list');
  }

  trackEvent('prospect_list_deleted', { listId: context.id });
}

function updateProspectListCache(
  cache: ApolloCache<any>,
  data: FetchResult<any, any>,
) {
  const cachedData: any = cache.readQuery({
    query: prospectListsQuery,
  });

  cache.writeQuery({
    query: prospectListsQuery,
    data: {
      prospectLists: {
        nodes: [
          ...cachedData.prospectLists.nodes,
          data.data.createProspectList.node,
        ],
      },
    },
  });
}
