import {
  AddBlock as AddBlockIcon,
  AddHollow as AddHollowIcon,
} from '@clearkit/icons';
import { CKButton } from 'clearkit';

import { emptyNode } from '~/components/SearchBuilder/utils/constants';
import {
  insertNode,
  SearchContext,
} from '~/components/SearchBuilder/utils/searchUtils';

import { emptyGroup as emptyGroupDefault } from '../../utils/fixtures';
import AndOrDecorator from '../AndOrDecorator';
import styles from './styles';

export class UnwrappedQueryGroup extends React.Component {
  render() {
    const {
      children,
      classes,
      className,
      node,
      canAddGroup = true,
      onChange,
      search,
      emptyGroup,
    } = this.props;

    const { disabled } = this.context;
    const effectiveEmptyGroup = emptyGroup || emptyGroupDefault;

    return (
      <div className={classnames(className, classes.root)}>
        <div className={classes.queryGroupContents}>
          <AndOrDecorator node={node} onChange={onChange} search={search}>
            {children}
            <div className={classes.queryGroupActions}>
              {!disabled && (
                <div className="flex items-center px-4 py-1 gap-4">
                  <CKButton
                    data-testid="add-condition-button"
                    isDisabled={disabled}
                    leftIcon={<AddBlockIcon />}
                    onClick={() => {
                      const newSearch = insertNode(search, node, emptyNode);
                      onChange(newSearch);
                    }}
                    variant="tertiary"
                    variantColor="blue"
                  >
                    Add condition
                  </CKButton>
                  {canAddGroup && (
                    <CKButton
                      isDisabled={disabled}
                      leftIcon={<AddHollowIcon />}
                      onClick={() => {
                        const newSearch = insertNode(
                          search,
                          node,
                          effectiveEmptyGroup,
                        );
                        onChange(newSearch);
                      }}
                      variant="tertiary"
                      variantColor="blue"
                    >
                      Add group
                    </CKButton>
                  )}
                </div>
              )}
            </div>
          </AndOrDecorator>
        </div>
      </div>
    );
  }
}

UnwrappedQueryGroup.contextType = SearchContext;

UnwrappedQueryGroup.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  node: PropTypes.object,
  insertInto: PropTypes.func,
  updateNode: PropTypes.func,
  removeNode: PropTypes.func,
  canAddGroup: PropTypes.bool,
  onChange: PropTypes.func,
  search: PropTypes.object,
  emptyGroup: PropTypes.object,
};

export default withStyles(styles)(UnwrappedQueryGroup);
