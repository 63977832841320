import { CKButton, CKDrawer } from 'clearkit';
import { ReactNode, useEffect, useState } from 'react';

import { useCurrentUser } from '~/components/profile/useCurrentUser';
import history from '~/lib/history';
import { trackUpgradeFormSubmitted } from '~/lib/segmentAnalytics.js';

import { ContactValues, FormContact } from './FormContact';
import { Interest, PreselectedInterests } from './types';
import UpgradeAccountComplete from './UpgradeAccountComplete';

type UpgradeAccountFormProps = {
  backPath?: string;
  headline?: ReactNode;
  onClose?: () => void;
  preSelectedInterests?: PreselectedInterests;
};

const isValid = (values: Record<string, unknown>) => {
  if (values.other && !values.message) {
    return false;
  }

  return values.email !== '' && values.name !== '';
};

const UpgradeAccountForm = ({
  preSelectedInterests,
  backPath,
  headline,
  onClose,
}: UpgradeAccountFormProps) => {
  const { user, loading } = useCurrentUser();
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const hasKey = (key: Interest) => !!preSelectedInterests?.includes(key);

  const [values, setValues] = useState<ContactValues>({
    email: '',
    name: '',
    crmSize: '',
    enrichment: hasKey('enrichment'),
    forms: hasKey('forms'),
  });

  const handleChange = (key: string) => (value: string | boolean) => {
    setValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const handleSubmit = async () => {
    const { crmSize, email, name, message } = values;
    const interests = ['enrichment', 'forms'].filter(
      (key) => values[key as keyof typeof values] === true,
    );

    trackUpgradeFormSubmitted({ email, name, message, interests, crmSize });
    setHasSubmitted(true);
  };

  const valid = isValid(values);

  useEffect(() => {
    if (user?.name && user?.email) {
      setValues({
        ...values,
        email: user?.email || '',
        name: user?.name || '',
      });
    }
  }, [user]);

  return (
    <>
      <CKDrawer.Header>
        <CKDrawer.Heading>
          {headline || 'Get in touch to upgrade your Clearbit plan'}
        </CKDrawer.Heading>
      </CKDrawer.Header>
      <CKDrawer.Body className="p-6 bg-gray-0">
        {hasSubmitted ? (
          <UpgradeAccountComplete email={values.email} />
        ) : (
          <FormContact
            isLoading={loading}
            onChange={handleChange}
            values={values}
          />
        )}
      </CKDrawer.Body>
      {!hasSubmitted ? (
        <CKDrawer.Footer className="gap-3">
          <CKButton
            onClick={() => {
              backPath ? history.push(backPath) : onClose?.();
            }}
            variant="simple"
          >
            Cancel
          </CKButton>
          <CKButton
            isDisabled={!valid}
            onClick={handleSubmit}
            type="submit"
            variant="bold"
            variantColor="green"
          >
            Submit contact form
          </CKButton>
        </CKDrawer.Footer>
      ) : null}
    </>
  );
};

export default UpgradeAccountForm;
