import { SalesforceContactLeadsToggle } from './SalesforceContactLeadsToggle';
import { ExportType } from './SalesforceExportProvider/types';
import { useSalesforceExport } from './SalesforceExportProvider/useSalesforceExport';
import { SalesforceForm } from './SalesforceForm';

export const SalesforceExport = () => {
  const {
    exportType,
    setExportType,
    optionalFields,
    requiredFields,
    isLoading,
    setFieldMapping,
    fieldMapping,
  } = useSalesforceExport();

  const handleToggle = (value: string) => {
    setExportType(value as ExportType);
  };

  const handleFormChange = (value: any) => {
    setFieldMapping(value);
  };

  return (
    <div className="space-y-8">
      <SalesforceContactLeadsToggle
        onChange={handleToggle}
        value={exportType}
      />
      <section>
        <h2 className="mb-1 font-medium">Configure {exportType}s</h2>
        <p className="mb-4 text-sm text-gray-600">
          Choose the values to use for {exportType} data fields when creating
          these {exportType}s in Salesforce.
        </p>
        <SalesforceForm
          isLoading={isLoading}
          onChange={handleFormChange}
          optionalFields={optionalFields}
          requiredFields={requiredFields}
          value={fieldMapping}
        />
      </section>
    </div>
  );
};
