/* eslint-disable react/no-find-dom-node, react/no-string-refs */
import * as Sentry from '@sentry/browser';
import clone from 'lodash/clone';
import isEqual from 'lodash/isEqual';
import { Fragment } from 'react';
import ReactDOM from 'react-dom';

import { getAndOrOr } from '~/components/SearchBuilder/utils/nodeUtils';
import { replaceNode } from '~/components/SearchBuilder/utils/searchUtils';

import Caret from './Caret.svg';
import styles from './styles';

const curveWidth = 24;
// This is the height that we displace the whole line segment
// to make room for the button, when there's more than 2 children
const topOffset = -14;

class AndOrDecorator extends React.Component {
  state = {
    showLines: false,
    childDimensions: [],
    error: false,
  };

  componentDidMount() {
    this.calculateBoxes(this.calculateBoxes);
  }

  UNSAFE_componentWillReceiveProps = (newProps) => {
    this.receivedProps = !isEqual(newProps.node, this.props.node);
  };

  componentDidUpdate() {
    if (this.receivedProps) {
      this.receivedProps = false;
      this.calculateBoxes(this.calculateBoxes);
    }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);

      Sentry.captureException(error, errorInfo);
    });

    this.setState({ error: true });
  }

  onChange = () => {
    const { node, search } = this.props;
    const andOrOr = getAndOrOr(node);
    const key = andOrOr === 'and' ? 'or' : 'and';
    node[key] = clone(node[andOrOr]);
    delete node[andOrOr];
    const newSearch = replaceNode(search, node);
    this.props.onChange(newSearch);
  };

  lines = React.createRef();
  children = React.createRef();

  calculateBoxes = (callback = () => {}) => {
    let refs = Object.values(this.refs);

    // Ignore DIV elements, they're showing buttons and things
    refs = refs.filter((ref) => !ref.tagName);

    // Only show the lines if we have more than one row
    if (refs.length <= 1) return this.setState({ showLines: false });

    // Get the dimensions of all these boxes
    let childDimensions = refs.map((ref) => {
      const obj = ReactDOM.findDOMNode(ref);

      return {
        top: obj.offsetTop,
        height: obj.offsetHeight,
        bottom: obj.offsetTop + obj.offsetHeight,
      };
    });

    const totalHeight = Math.max(...childDimensions.map((b) => b.bottom));

    // We sort these child dimensions to curve the line on the last one
    childDimensions = childDimensions.sort((b) => b.top);

    this.setState(
      {
        childDimensions,
        totalHeight,
        showLines: true,
      },
      () => {
        callback();
        setTimeout(callback, 1);
      },
    );
  };

  renderLine = (box, i) => {
    const { classes } = this.props;
    const last = i === this.state.childDimensions.length - 1;
    const midpoint =
      2 * Math.round((box.bottom - box.height / 2 - topOffset) / 2);
    const key = `box-${i}`;

    if (last) {
      // Straight line with a curved indicator path (two separate elements)
      return (
        <g key={key}>
          <line
            className={classes.line}
            x1={curveWidth}
            x2={curveWidth}
            y1={0}
            y2={`${midpoint - curveWidth + 16}px`}
          />
          <path
            className={classes.line}
            d={`
              M${curveWidth}, ${midpoint - curveWidth + 16}
              Q${curveWidth},${midpoint} ${curveWidth + 10},${midpoint}
            `}
          />
          <line
            className={classes.line}
            x1={curveWidth + 9}
            x2={curveWidth + 24}
            y1={`${midpoint}px`}
            y2={`${midpoint}px`}
          />
        </g>
      );
    }

    // Straight line on the left, horizontal indicator line
    return (
      <g key={key}>
        <line
          className={classes.line}
          x1={curveWidth}
          x2={curveWidth * 2}
          y1={`${midpoint}px`}
          y2={`${midpoint}px`}
        />
        <line
          className={classes.line}
          x1={curveWidth}
          x2={curveWidth}
          y1={0}
          y2={`${midpoint}px`}
        />
      </g>
    );
  };

  render() {
    const { children, classes } = this.props;
    const { totalHeight, showLines, childDimensions, error } = this.state;

    if (error) {
      return (
        <div className={classes.error}>
          There was an error rendering this query.
        </div>
      );
    }

    const refChildren = React.Children.map(
      children,
      (element, idx) => element && React.cloneElement(element, { ref: idx }),
    );
    if (!showLines) return refChildren || <Fragment />;
    const label = getAndOrOr(this.props.node);

    return (
      <div className={classes.root}>
        <>
          <button className={classes.andOrButton} onClick={this.onChange}>
            {label} <Caret className={classes.caret} />
          </button>
          <svg className={classes.lines} height={`${totalHeight}px`}>
            {childDimensions.map((box, i) => this.renderLine(box, i))}
          </svg>
          <div className={classes.children} ref={this.children}>
            {refChildren}
          </div>
        </>
      </div>
    );
  }
}

AndOrDecorator.defaultProps = {
  onChange: () => {},
};

AndOrDecorator.propTypes = {
  onChange: PropTypes.func,
  node: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  search: PropTypes.object,
};

export default withStyles(styles)(AndOrDecorator);
