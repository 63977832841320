// eslint-disable-next-line clearbit-x/no-tree-shaking-imports
import { fade } from '@material-ui/core/styles/colorManipulator';

export default (theme) => ({
  root: {
    padding: '16px 16px 0',
    boxShadow:
      '0 0 0 1px rgba(155, 173, 188, .2), 0 1px 2px 1px rgba(0, 0, 0, .08)',
    borderRadius: 6,
    background: 'white',
  },

  queryGroup: {
    background: theme.editorStyles.tint,
    boxShadow: theme.editorStyles.shadow,
    padding: '0 !important',
    marginTop: '0 !important',
  },

  separator: {
    display: 'inline',
    margin: '0px 5px',
    height: '20px',
    width: '1px',
    cursor: 'default !important',
    borderLeft: '1px solid #fed4ca',
  },

  condition: {
    padding: '10px !important',
    background: theme.editorStyles.background,
    borderTop: `1px solid ${theme.editorStyles.border}`,
    boxShadow: theme.box.shadow,
    borderBottomRightRadius: 6,
    borderBottomLeftRadius: 6,
    position: 'relative',
  },

  primaryCondition: {
    padding: '8px 16px 8px !important',
    color: theme.editorStyles.primary,
  },

  primaryConditionDescription: {
    fontSize: '14px',
    display: 'flex',
    minHeight: '32px',
    alignItems: 'center',
  },

  secondaryCondition: {
    padding: '8px 16px 8px !important',
    background: theme.editorStyles.background,
    color: theme.editorStyles.primary,
    borderTop: `1px solid ${theme.editorStyles.border}`,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    position: 'relative',
  },

  actions: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },

  primaryConditionIndicator: {
    background: `${fade('#fff', 0.5)}`,
    border: `1px solid ${theme.editorStyles.border}`,
    borderRadius: 16,
    padding: '1px 8px',
    fontWeight: 500,
    color: theme.box.color,
    margin: '0 6px',
    '& svg': {
      position: 'relative',
      display: 'inline',
      width: 18,
      height: 18,
      marginRight: 2,
    },
  },

  conditions: {
    marginTop: 10,
    paddingTop: 16,
    marginBottom: 8,
    boxShadow: `0 0 0 1px ${fade(
      theme.editorStyles.primary,
      0.25,
    )}, 0 1px 1px ${fade(theme.editorStyles.primary, 0.1)}`,
  },

  addSecondaryConditionButton: {
    margin: '-2px -8px',
    '&::before': {
      background: 'none',
    },
    '&:hover::before': {
      background: `${fade(theme.editorStyles.tertiary, 0.1)} !important`,
    },
    color: `${theme.editorStyles.primary} !important`,
  },

  showCaret: {
    paddingTop: '12px !important',
    '&::after': {
      display: 'block',
      position: 'absolute',
      content: '""',
      width: 15,
      height: 15,
      left: 30,
      top: -8,
      borderBottomRightRadius: 3,
      background: theme.editorStyles.tint,
      transform: 'rotate(45deg)',
      borderTopWidth: 0,
      borderLeftWidth: 0,
      border: `1px solid ${theme.editorStyles.border}`,
      bottom: '-16px',
    },
  },

  queryGroupContents: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  queryGroupActions: {
    paddingBottom: 8,
    paddingTop: 4,
    marginTop: -4,
    marginLeft: -9,
  },
});
