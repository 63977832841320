import { NoResultsPeople } from '@clearkit/icons';

import { EmptyState } from './EmptyState';

export const EmptyStateLists = () => (
  <EmptyState>
    <EmptyState.Icon>
      <NoResultsPeople />
    </EmptyState.Icon>
    <EmptyState.Title>Create a new prospect list</EmptyState.Title>
    <EmptyState.Description>
      To find prospects from Clearbit’s prospecting dataset of over 250 million
      contacts, create a new prospect list.
    </EmptyState.Description>
  </EmptyState>
);
