import {
  CKInputChips,
  CKInputChipsProps,
  excludeChildrenByDisplayName,
  includeChildrenByDisplayName,
} from 'clearkit';
import { FC } from 'react';

import {
  componentDisplayNames,
  Filter,
  FilterInstanceComposition,
} from './Filter';

type FilterInputChipsComposition = FilterInstanceComposition;

const errorDisplayName = 'CKInputChips.ErrorMessage';
const {
  helpTextDisplayName,
  iconDisplayName,
  titleDisplayName,
} = componentDisplayNames;

export const FilterInputChips: FC<CKInputChipsProps> &
  FilterInputChipsComposition = ({
  children,
  className,
  onChange,
  value,
  ...rest
}) => {
  const handleClearFilter = () => {
    onChange([]);
  };

  return (
    <Filter
      className={className}
      filterCount={value?.length}
      onClearFilter={handleClearFilter}
      shouldStartOpen={value && value?.length > 0}
    >
      {excludeChildrenByDisplayName({
        children,
        componentDisplayName: [errorDisplayName],
      })}

      <Filter.Input className="pt-2 space-y-2">
        <CKInputChips
          {...rest}
          autoFocus
          maxHeight="12rem"
          onChange={onChange}
          shouldOverflow={false}
          value={value || []}
        >
          {includeChildrenByDisplayName({
            children,
            componentDisplayName: [errorDisplayName],
          })}
        </CKInputChips>
      </Filter.Input>
    </Filter>
  );
};

FilterInputChips.ErrorMessage = CKInputChips.ErrorMessage;
FilterInputChips.ErrorMessage.displayName = errorDisplayName;

FilterInputChips.HelpText = Filter.HelpText;
FilterInputChips.HelpText.displayName = helpTextDisplayName;

FilterInputChips.Icon = Filter.Icon;
FilterInputChips.Icon.displayName = iconDisplayName;

FilterInputChips.Title = Filter.Title;
FilterInputChips.Title.displayName = titleDisplayName;
