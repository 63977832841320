import Menu from '@material-ui/core/Menu';

import MenuItem from '~/components/RedesignedMenuItem';

import MoreIcon from './MoreIcon.svg';
import styles from './styles.js';

class MoreMenu extends React.Component {
  state = {
    menuOpen: false,
  };

  moreIcon = React.createRef();

  render() {
    const { classes, className, options } = this.props;
    const { menuOpen } = this.state;

    return (
      <>
        <a
          className={classnames(classes.moreMenu, className)}
          href="#none"
          onClick={(event) => {
            event.preventDefault();
            this.setState({ menuOpen: true });
          }}
          ref={this.moreIcon}
        >
          <MoreIcon />
        </a>

        <Menu
          anchorEl={this.moreIcon.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          getContentAnchorEl={null}
          onClose={() => {
            this.setState({ menuOpen: false });
          }}
          open={menuOpen}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {options.map((option, index) => (
            <MenuItem
              key={index}
              large
              onClick={() => {
                this.setState({ menuOpen: false });
                option.onClick();
              }}
              variant="primary"
            >
              {option.component}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
}

MoreMenu.defaultProps = {
  options: [],
};

MoreMenu.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
};

export default withStyles(styles)(MoreMenu);
