import { useEffect, useState } from 'react';

/*
 * Wade:
 *
 * Out of the box there is no react hook that lets multiple components share
 * state with one another. We need value overrides to persist across components,
 * so I stole this pattern for a global state hook from here:
 * https://javascript.plainenglish.io/state-management-with-react-hooks-no-redux-or-context-api-8b3035ceecf8
 *
 * It leverages useState setters as render triggers so that it will trigger updates from the
 * shared state across all components
 */

let listeners = [];
let overrides = {};

const triggerListeners = () => {
  listeners.forEach((listener) => {
    listener(overrides);
  });
};

const setOverride = (valueName, value) => {
  overrides = {
    ...overrides,
    [valueName]: value,
  };
  triggerListeners();
};

const _clearOverride = (valueName) => {
  overrides = {
    ...overrides,
  };
  delete overrides[valueName];
  triggerListeners();
};

export default function useDeveloperOverride(valueName) {
  const [_, newListener] = useState();
  useEffect(() => {
    listeners.push(newListener);

    return () => {
      listeners.splice(listeners.indexOf(newListener), 1);
    };
  }, []);

  function setOverrideValue(value) {
    setOverride(valueName, value);
  }

  function clearOverride() {
    _clearOverride(valueName);
  }

  let overrideValue = overrides[valueName];
  let isOverridden = overrideValue !== undefined;
  const value = (originalValue) => {
    return isOverridden ? overrideValue : originalValue;
  };

  return {
    value,
    isOverridden,
    overrideValue,
    clearOverride,
    setOverrideValue,
  };
}
