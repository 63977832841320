import {
  Companies,
  Facebook,
  Job,
  Linkedin,
  Location,
  Money,
  Tech,
  Twitter,
} from '@clearkit/icons';
import startCase from 'lodash/startCase';

import Group from './Group';

const CompanyEnrichment = ({ enrichment, search }) => {
  const valueForAttribute = (attribute) =>
    enrichment?.find(({ label }) => label === attribute)?.value;
  const attribute = (label) => ({ label, value: valueForAttribute(label) });

  return (
    <>
      <Group
        icon={Companies}
        search={search}
        sections={[
          ['Name', 'Legal Name', 'Founded Year'].map(attribute),
          [
            'Domain',
            'Domain Aliases',
            'Parent Domain',
            'Ultimate Parent Domain',
            'Phone',
          ].map(attribute),
          [
            {
              label: 'Description',
              value: valueForAttribute('Description'),
            },
            {
              label: 'Type',
              value: startCase(valueForAttribute('Type')),
            },
          ],
          ['Employees', 'Employees Range'].map(attribute),
          ['Alexa Global Rank', 'Alexa U.S. Rank', 'Clearbit Traffic Rank'].map(
            attribute,
          ),
          ['Email Provider'].map(attribute),
        ]}
        title="Company"
      />

      <Group
        icon={Job}
        search={search}
        sections={[
          [
            {
              ...attribute('Industry'),
              children: ['Sector', 'Industry Group', 'Sub Industry'].map(
                attribute,
              ),
            },
          ],
          ['Tags'].map(attribute),
          [
            {
              label: 'GICS 8 Digit Code',
              value: valueForAttribute('GICS 8 Digit Code'),
              tooltip:
                'Clearbit provides the first 2 digits of SIC, representing the company sector',
            },
            {
              label: 'SIC 2 Digit Code',
              value: valueForAttribute('SIC 2 Digit Code'),
              tooltip:
                'Clearbit provides the first 2 digits of SIC, representing the major group',
            },
            {
              label: 'SIC 4 Digit Codes',
              value: valueForAttribute('SIC 4 Digit Codes'),
            },
            {
              label: 'NAICS 2 Digit Code',
              value: valueForAttribute('NAICS 2 Digit Code'),
              tooltip:
                'Clearbit provides the first 2 digits of NAICS, representing the company sector',
            },
            {
              label: 'NAICS 6 Digit Codes',
              value: valueForAttribute('NAICS 6 Digit Codes'),
            },
            {
              label: 'NAICS 2022 6 Digit Codes',
              value: valueForAttribute('NAICS 2022 6 Digit Codes'),
            },
          ],
        ]}
        title={'Industry'}
      />
      <Group
        icon={Money}
        search={search}
        sections={[
          ['Annual Revenue', 'Estimated Annual Revenue'].map(attribute),
          ['Ticker', 'Market Cap'].map(attribute),
          ['Raised'].map(attribute),
          ['Fiscal Year End'].map(attribute),
        ]}
        title="Finances"
      />
      <Group
        icon={Location}
        search={search}
        sections={[
          ['Location', 'Time Zone', 'UTC Offset'].map(attribute),
          [
            'Street Number',
            'Street Name',
            'City',
            'State',
            'State Code',
            'Postal Code',
            'Country',
            'Country Code',
          ].map(attribute),
          ['Latitude', 'Longitude'].map(attribute),
        ]}
        title="Location"
      />
      <Group
        icon={Tech}
        search={search}
        sections={[
          ['Tech Categories'].map(attribute),
          [
            {
              label: 'Tech Tags',
              value: valueForAttribute('Tech')?.map((t) => startCase(t)),
            },
          ],
        ]}
        title="Tech"
      />
      <Group
        icon={Twitter}
        search={search}
        sections={[
          [
            'Twitter Handle',
            'Twitter Id',
            'Twitter Followers',
            'Twitter Following',
          ].map(attribute),
        ]}
        title="Twitter"
        type={'twitter'}
      />
      <Group
        icon={Facebook}
        search={search}
        sections={[['Facebook Handle', 'Facebook Likes'].map(attribute)]}
        title="Facebook"
        type={'facebook'}
      />
      <Group
        icon={Linkedin}
        search={search}
        sections={[['Linkedin Handle'].map(attribute)]}
        title="Linkedin"
        type={'linkedin'}
      />
    </>
  );
};

CompanyEnrichment.propTypes = {
  enrichment: PropTypes.array,
  search: PropTypes.string,
};

export default CompanyEnrichment;
