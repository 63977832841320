import {
  HubspotServices as Hubspot,
  Info,
  SalesforceServices as Salesforce,
  SegmentDestination,
  SlackServices as Slack,
} from '@clearkit/icons';
import { CKBannerNext, CKButton, CKIconStack } from 'clearkit';
import { Link } from 'react-router-dom';

import paths from '~/lib/paths';

const Logo = ({ service }) => {
  switch (service) {
    case 'hubspot':
      return <Hubspot />;
    case 'salesforce':
      return <Salesforce />;
    case 'slack':
      return <Slack />;
    default:
      return (
        <SegmentDestination className="text-gray-600" height={26} width={26} />
      );
  }
};

const ConnectorError = ({ service }) => {
  const ServiceName = () => <span className="capitalize">{service}</span>;

  return (
    <CKBannerNext variant="warning">
      <CKBannerNext.Icon>
        <CKIconStack
          badge={<Info className="fill-gradient-br-yellow" />}
          base={<Logo service={service} />}
        />
      </CKBannerNext.Icon>
      <CKBannerNext.Header>
        <ServiceName /> has been disconnected
      </CKBannerNext.Header>
      <CKBannerNext.Body>
        This audience needs access to fields in <ServiceName />. Reconnect{' '}
        <ServiceName /> to restore this audience.
      </CKBannerNext.Body>
      <CKBannerNext.CTA>
        <CKButton
          as={Link}
          to={paths.configuration(service)}
          variant="bold"
          variantColor="yellow"
        >
          Fix this issue
        </CKButton>
      </CKBannerNext.CTA>
    </CKBannerNext>
  );
};

export default ConnectorError;
