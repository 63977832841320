import { CKSearch, CKSearchProps, CKTooltip } from 'clearkit';
import debounce from 'lodash/debounce';
import React, { FC, KeyboardEvent, useEffect, useMemo, useState } from 'react';

const DEBOUNCE_INTERVAL = 700;
const MINIMUM_CHARACTERS = 3;

/**
 * This component is for the header of Company and People list pages,
 * whether in a Segment or not in a segment. It has a debounce (because searching is slow)
 * and a minimum character limit (because indexes have a minimum length)
 */

type PeopleCompaniesSearchFieldProps = CKSearchProps & {
  debounceInterval?: number;
};

const PeopleCompaniesSearchField: FC<PeopleCompaniesSearchFieldProps> = ({
  debounceInterval = DEBOUNCE_INTERVAL,
  onChange,
  placeholder,
  value,
  ...rest
}) => {
  const [searchTerm, setSearchTerm] = useState<string>(value || '');
  const [showingTooltip, setShowingTooltip] = useState(false);

  const checkValid = (value: string) => {
    const { length } = value;
    return length === 0 || length >= MINIMUM_CHARACTERS;
  };

  const handleSearch = (value: string) => {
    if (checkValid(value)) {
      onChange?.(value);
    }
  };

  const debouncedSearch = debounce(handleSearch, debounceInterval);
  const debouncedSetShowingTooltip = useMemo(
    () =>
      debounce((valid: boolean) => setShowingTooltip(valid), debounceInterval),
    [],
  );

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch(event.currentTarget.value);
    }
  };

  useEffect(() => {
    if (searchTerm) {
      debouncedSetShowingTooltip(!checkValid(searchTerm));
    }
  }, [searchTerm]);

  useEffect(() => {
    if (!value) {
      setShowingTooltip(false);
    }
  }, [value]);

  return (
    <CKTooltip
      hasHover={false}
      isVisible={showingTooltip}
      placement="bottom-start"
      targetClassName="block w-full min-w-0"
      tooltip="Please type at least 3 characters to search"
    >
      <CKSearch
        {...rest}
        onChange={(value) => {
          setSearchTerm(value);
          debouncedSearch(value);
        }}
        onKeyDown={handleKeyPress}
        placeholder={placeholder}
        value={searchTerm}
      />
    </CKTooltip>
  );
};

export default PeopleCompaniesSearchField;
