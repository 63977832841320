import { PageLayout } from '~/components/PageLayout';
import BillingUsageMetersList from '~/components/usage/BillingUsageMeters/List';
import { AccountFlagEnum } from '~/generated/graphql';
import { useFeatureFlag } from '~/hooks/account';

import { CreditsBasedUsageLayout } from './CreditBasedUsageLayout';

export const UsageOverviewLegacy = () => {
  const creditsBasedUsage = useFeatureFlag(
    AccountFlagEnum.AllowCreditsBasedUsage,
  ).enabled;

  return (
    <PageLayout.Content className="mb-12 space-y-6">
      <h2 className="text-base text-gray-600">
        <UsageDescription creditsBasedUsage={creditsBasedUsage} />
      </h2>
      {creditsBasedUsage ? (
        <CreditsBasedUsageLayout />
      ) : (
        <BillingUsageMetersList />
      )}
    </PageLayout.Content>
  );
};

type UsageDescriptionProps = {
  creditsBasedUsage: boolean;
};

function UsageDescription({ creditsBasedUsage }: UsageDescriptionProps) {
  if (creditsBasedUsage) {
    return (
      <>
        From records enriched in your CRM or MAP to the actions you power with
        data like Alerts we only count credits for what you use. Credits reset
        each month.&nbsp;
        <a href="https://help.clearbit.com/hc/en-us/articles/16764226936471-Understanding-Credits-and-Usage">
          Learn more about credits and usage.
        </a>
      </>
    );
  }

  return (
    <>
      View your active platform plans, usage, and historical usage trends. For
      information on API usage and legacy applications,{' '}
      <a href="https://dashboard.clearbit.com/dashboard">
        visit the API dashboard.
      </a>
    </>
  );
}
