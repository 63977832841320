import { useQuery } from '@apollo/client/react/hooks';

import { useCurrentUser } from '~/hooks/account';

import query from './query.graphql';
import WebhookConfigCard from './WebhookConfigCard';

const WebhookConfig = ({ integration }) => {
  const { data: user } = useCurrentUser({
    refetchOnWindowFocus: false,
    retry: false,
  });

  const { data: { accountConnector = null } = {} } = useQuery(query, {
    variables: {
      service: [integration],
    },
  });

  return (
    <div className="flex flex-col justify-between h-full bg-gray-0">
      <div className="flex flex-col mt-8 mb-10">
        <div className="flex flex-row justify-between px-10">
          <div className="flex flex-col mr-14">
            <p className="mb-2 text-base font-medium text-gray-800">
              Webhook configuration
            </p>
            <p className="max-w-3xl text-sm text-gray-600">
              You’ll need to configure a Webhook and Smart Campaign to notify
              Clearbit when there’s a new person to enrich your Marketo
              database. Follow the{' '}
              <a
                className="underline text-blue"
                href="https://help.clearbit.com/hc/en-us/articles/360002011454-Clearbit-for-Marketo"
                rel="noopener noreferrer"
                target="_blank"
              >
                Clearbit for Marketo setup guide
              </a>{' '}
              for a step-by-step instructions
            </p>
          </div>
          {user && accountConnector && (
            <WebhookConfigCard
              accountConnector={accountConnector}
              currentAccount={user.currentAccount}
              objectType="lead"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default WebhookConfig;
