import { CKButton, CKDrawer } from 'clearkit';
import { useState } from 'react';

import { ButtonContainer } from '~/components/unified/ButtonContainer';

import { CRMOptInModal } from '../Dialogs';
import { useProspector } from '../useProspector';
import { SalesforceExport } from './SalesforceExport';
import { SalesforceExportProvider } from './SalesforceExportProvider';
import { useSalesforceExport } from './SalesforceExportProvider/useSalesforceExport';

type ExportProspectSalesforceWrapperProps = {
  children: React.ReactNode;
};
export function ExportProspectSalesforceWrapper({
  children,
}: ExportProspectSalesforceWrapperProps) {
  return <SalesforceExportProvider>{children}</SalesforceExportProvider>;
}

export function ExportProspectSalesforce() {
  const { exportType, isValid } = useSalesforceExport();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [_state, send] = useProspector();

  const handleClose = () => send('CANCEL');
  const handleOpenModal = () => setIsModalOpen(true);

  return (
    <ExportProspectSalesforceWrapper>
      <CKDrawer.Header>
        <CKDrawer.Heading>Add prospects to Salesforce</CKDrawer.Heading>
        <p className="text-sm text-gray-600">
          Create new Salesforce contacts or leads from prospects revealed by
          Clearbit.
        </p>
      </CKDrawer.Header>
      <CKDrawer.Body className="p-8 bg-gray-0">
        <SalesforceExport />
      </CKDrawer.Body>
      <CKDrawer.Footer>
        <ButtonContainer>
          <CKButton onClick={handleClose}>Cancel</CKButton>
          <CKButton
            isDisabled={!isValid}
            onClick={handleOpenModal}
            variant="bold"
            variantColor="green"
          >
            Create {exportType}s in Salesforce
          </CKButton>
        </ButtonContainer>
        <CRMOptInModal isVisible={isModalOpen} service="salesforce" />
      </CKDrawer.Footer>
    </ExportProspectSalesforceWrapper>
  );
}
