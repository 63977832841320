import {
  Prospect,
  ProspectFilters,
  ProspectList,
  ProspectListExport,
} from '~/generated/graphql';

import { QueryType } from '../types';

export type MachineArguments = {
  addSuccessToast: (args: ToastArgs) => void;
  addErrorToast: (args: ToastArgs) => void;
  id?: string;
  isNewList?: boolean;
  companyQuery?: any;
  source?: string | null;
};

export type UpdateQueryEvent = {
  queryType: QueryType;
  type: 'UPDATE_QUERY';
  value: Record<string, any>;
};

export type UpdateNameEvent = {
  type: 'UPDATE_NAME';
  value: string;
};

export type ToastArgs = {
  description?: string;
  heading?: string;
};

export type SaveSalesforceExportEvent = {
  type: 'SAVE_SALESFORCE_EXPORT';
  recordType: 'contact' | 'lead';
  mapping: Record<string, string>;
};

export type SaveHubspotExportEvent = {
  type: 'SAVE_HUBSPOT_EXPORT';
  recordType: 'contact';
  mapping: Record<string, string>;
};

export const machineSchema = {
  events: {} as
    | { type: 'RETRY' }
    | { type: 'CANCEL' }
    | { type: 'CREATE_PROSPECT_LIST' }
    | { type: 'CREATE_CSV_EXPORT' }
    | { type: 'CREATE_SALESFORCE_EXPORT' }
    | { type: 'CREATE_HUBSPOT_EXPORT' }
    | { type: 'CREATE_HUBSPOT_EXPORT' }
    | { type: 'DESELECT_ALL' }
    | { type: 'DISCARD_CHANGES' }
    | { type: 'DELETE' }
    | { type: 'DESELECT_PROSPECT' }
    | { type: 'EXPORT' }
    | { type: 'CANCEL_EXPORT' }
    | { type: 'DOWNLOAD_EXPORT' }
    | { type: 'NEXT_PAGE' }
    | { type: 'PREVIOUS_PAGE' }
    | { type: 'PROSPECT_LIST_CHANGE' }
    | { type: 'RENAME' }
    | { type: 'RESTART' }
    | { type: 'SAVE' }
    | { type: 'DOWNLOAD_COMPLETE' }
    | { type: 'SELECT_ALL' }
    | { type: 'SELECT_ALL_PAGE' }
    | { type: 'SELECT_PROSPECT' }
    | { type: 'SET_NAME' }
    | { type: 'TOGGLE_EXPORT_DRAWER_PENDING' }
    | { type: 'TOGGLE_EXPORT_DRAWER_HISTORY' }
    | { type: 'TOGGLE_FILTER_PREVIEW' }
    | { type: 'TOGGLE_FILTER_SIDEBAR' }
    | SaveSalesforceExportEvent
    | SaveHubspotExportEvent
    | UpdateQueryEvent
    | UpdateNameEvent,
  context: {} as {
    addErrorToast: (args: ToastArgs) => void;
    addSuccessToast: (args: ToastArgs) => void;
    companyQuery: any; // TODO: Type from gql codegen
    currentPage: number;
    deselections: string[];
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    id?: string;
    isNewList?: boolean;
    isFilterPreviewOpen?: boolean;
    loadingPage: boolean;
    loadingExports: boolean;
    name: string;
    nameEdit?: string;
    pageEnd: number;
    pageStart: number;
    perPage: number;
    personQuery: any; // TODO: Type from gql codegen
    preferFilterPreviewOpen: boolean;
    previousTotalCompanies: number;
    previousTotalProspects: number;
    prospectFilters: null | ProspectFilters;
    prospectList: null | ProspectList;
    prospectLists: null | ProspectList[];
    prospectListExportsPending: null | ProspectListExport[];
    prospectListExportsHistory: null | ProspectListExport[];
    prospects: Prospect[];
    selectAll: boolean;
    selectAllPage: boolean;
    selectedProspectsCount: number;
    selections: string[];
    showFilterSidebar: boolean;
    totalCompanies: number;
    totalPages: number;
    totalProspects: number;
  },
};
