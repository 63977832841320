import { CKButton, CKButtonProps } from 'clearkit';
import { useState } from 'react';

import SidebarDrawer from '~/components/SidebarDrawer';
import UpgradeAccountForm from '~/components/UpgradeAccountForm';
import { PreselectedInterests } from '~/components/UpgradeAccountForm/types';

type UpgradeAccountFormButtonProps = CKButtonProps & {
  preSelectedInterests?: PreselectedInterests;
  headline?: string;
};

export function UpgradeAccountFormButton({
  preSelectedInterests,
  children,
  headline,
  ...rest
}: UpgradeAccountFormButtonProps) {
  const [showUpgradeForm, setShowUpgradeForm] = useState(false);

  return (
    <>
      <CKButton {...rest} onClick={() => setShowUpgradeForm(true)}>
        {children}
      </CKButton>
      <SidebarDrawer
        isVisible={showUpgradeForm}
        onToggle={() => {
          setShowUpgradeForm(!showUpgradeForm);
        }}
      >
        <UpgradeAccountForm
          headline={headline}
          onClose={() => {
            setShowUpgradeForm(false);
          }}
          preSelectedInterests={preSelectedInterests as PreselectedInterests}
        />
      </SidebarDrawer>
    </>
  );
}
