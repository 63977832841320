import { useQuery } from '@apollo/client/react/hooks';
import { CKPicker, CKTab, CKTabList, CKTabs } from 'clearkit';
import { useState } from 'react';

import GoogleLogo from '~/images/destinations/GoogleAnalytics.svg';
import { destinationTypeGoogleConversion } from '~/lib/destinations';
import { errorMessage, hasError } from '~/lib/graphql';
import { readableSingularSegmentType } from '~/lib/segments';
import Disconnected from '~/pages/destinations/components/Disconnected';
import TriggerType from '~/pages/destinations/components/TriggerType';

import ContentBox from '../../components/ContentBox';
import DebugConsole from '../../components/ConversionDebugConsole';
import FieldLabel from '../../components/FieldLabel';
import FormContentWrapper from '../../components/FormContentWrapper';
import FormHeader from '../../components/FormHeader';
import MoneyInput from '../../components/MoneyInput';
import query from './conversionTrackerQuery.graphql';

function GoogleConversion({
  errors,
  refetch,
  accountConnectors,
  destination,
  destinationOptions,
  segment,
  FormFooter,
}) {
  const accountConnector = accountConnectors.google;
  const isNew = !destination;
  const initialConfig = isNew ? {} : destination.config;

  const initialState = {
    adAccountId: initialConfig.googleAdAccountId || null,
    conversionTrackerId: initialConfig.conversionTrackerId || null,
    leadValue: initialConfig.leadValue || 0,
    leadValueCurrency: initialConfig.leadValueCurrency || 'USD',
    triggerType: isNew ? 'segment_enter' : destination.triggerType,
  };

  const [adAccountId, setAdAccountId] = useState(initialState.adAccountId);
  const [conversionTrackerId, setConversionTrackerId] = useState(
    initialState.conversionTrackerId,
  );
  const [leadValue, setLeadValue] = useState(initialState.leadValue);
  const [leadValueCurrency, setLeadValueCurrency] = useState(
    initialState.leadValueCurrency,
  );
  const [triggerType, setTriggerType] = useState(initialState.triggerType);

  const [tabIndex, setTabIndex] = useState(0);

  const { data: trackerData, loading: loadingTrackers } = useQuery(query, {
    skip: !adAccountId,
    variables: {
      adAccountId,
    },
  });
  const conversionTrackers = trackerData?.googleConversionTrackers || [];

  const getConversionTracker = (id) =>
    conversionTrackers.find((a) => a.id === id);
  const selectedConversionTracker = getConversionTracker(conversionTrackerId);

  /*
   * Whenever the ad account changes we need to clear the conversion tracker
   * selection so that we aren't using a tracker from the wrong account
   */
  const setAdAccountIdAndClearTracker = (adAccount) => {
    setAdAccountId(adAccount);
    setConversionTrackerId(null);
  };

  const setConversionValueDefaults = (trackerId) => {
    const tracker = getConversionTracker(trackerId);
    setLeadValue(tracker.defaultValue);
    setLeadValueCurrency(tracker.defaultCurrency);
  };

  if (!accountConnector) {
    return (
      <Disconnected
        FormFooter={FormFooter}
        Logo={GoogleLogo}
        onSuccess={refetch}
        service="google"
      />
    );
  }

  const currencies = destinationOptions.facebookCurrencyOptions;
  const adAccounts = accountConnector.adAccounts || [];
  const selectedAdAccount = adAccounts.find((a) => a.id === adAccountId);

  const humanizedSegmentType = readableSingularSegmentType(segment.type);
  const destinationFlowDescription = isNew
    ? null
    : 'Will send event to Google Ads';

  const accountPickerIsDisabled = adAccounts.length === 0;
  const accountSelectorPlaceholder = accountPickerIsDisabled
    ? 'No Google Ads accounts connected'
    : 'Choose a Google Ads account';

  const conversionTrackerIsDisabled =
    !adAccountId || loadingTrackers || conversionTrackers.length === 0;
  const conversionTrackerSelectorPlaceholder = !adAccountId
    ? 'Must select an Ads account'
    : loadingTrackers
    ? 'Loading Conversion Trackers'
    : conversionTrackers.length === 0
    ? 'No available Conversion Trackers'
    : 'Choose a Conversion Tracker';

  return (
    <>
      <FormHeader
        destinationFlowDescription={destinationFlowDescription}
        destinationName="Google Ads"
        destinationType={destinationTypeGoogleConversion}
        noBorder
        segmentCollection={segment.collection.name}
        segmentName={segment.name}
      />

      {isNew ? null : (
        <CKTabs index={tabIndex} isBordered onChange={setTabIndex}>
          <CKTabList className="flex-shrink-0 px-10 -mt-4">
            <CKTab label="Configure" value="form">
              Configure
            </CKTab>
            <CKTab label="Debug" value="debug">
              Debug
            </CKTab>
          </CKTabList>
        </CKTabs>
      )}

      {tabIndex === 0 ? (
        <>
          <FormContentWrapper>
            <h2 className="text-base font-medium text-gray-600">Trigger</h2>
            <span className="text-sm text-gray-600">
              Select the event that triggers this destination to run.{' '}
              <a
                href="https://help.clearbit.com/hc/en-us/articles/360023453694"
                rel="noopener noreferrer"
                style={{ textDecoration: 'underline' }}
                target="_blank"
              >
                Learn more
              </a>
            </span>

            <TriggerType
              errors={errors}
              handleChange={(_, val) => setTriggerType(val)}
              triggerType={triggerType}
              triggerTypes={[
                {
                  value: 'segment_enter',
                  label: (
                    <span>
                      When a {humanizedSegmentType} <strong>enters</strong> the
                      audience
                    </span>
                  ),
                },
              ]}
            />

            <h2 className="mb-6 text-base font-medium text-gray-600">
              Configure Google Ads Conversion
            </h2>
            <ContentBox>
              <div className="mb-4">
                <div className="flex mb-4">
                  <div className="flex-1 mr-2">
                    <FieldLabel
                      for="adwordsAccount-input"
                      label="Ads account *"
                    />
                    <CKPicker
                      id="adwordsAccount"
                      isDisabled={accountPickerIsDisabled}
                      items={adAccounts.map((adAccount) => ({
                        label: adAccount.displayName,
                        value: adAccount.id,
                      }))}
                      itemToString={(item) => (item ? item.label : '')}
                      onSelectedItemChange={({ selectedItem }) => {
                        setAdAccountIdAndClearTracker(selectedItem.value);
                      }}
                      placeholder={accountSelectorPlaceholder}
                      selectedItem={
                        !!selectedAdAccount && {
                          value: selectedAdAccount.id,
                          label: selectedAdAccount.displayName,
                        }
                      }
                      variant={
                        hasError(errors, 'googleAdAccountId') ? 'error' : null
                      }
                    />
                    {hasError(errors, 'googleAdAccountId') && (
                      <div className="text-red">
                        {errorMessage(errors, 'googleAdAccountId')}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="mb-4">
                <div className="flex-1 mr-2">
                  <FieldLabel
                    for="conversionTracker-input"
                    label="Conversion Tracker *"
                  />
                  <CKPicker
                    id="conversionTracker"
                    isDisabled={conversionTrackerIsDisabled}
                    items={conversionTrackers.map((tracker) => ({
                      label: tracker.name,
                      value: tracker.id,
                    }))}
                    itemToString={(item) => (item ? item.label : '')}
                    onSelectedItemChange={({ selectedItem }) => {
                      setConversionTrackerId(selectedItem.value);

                      if (isNew) {
                        setConversionValueDefaults(selectedItem.value);
                      }
                    }}
                    placeholder={conversionTrackerSelectorPlaceholder}
                    selectedItem={
                      !!selectedConversionTracker && {
                        value: selectedConversionTracker.id,
                        label: selectedConversionTracker.name,
                      }
                    }
                    variant={
                      hasError(errors, 'conversionTrackerId') ? 'error' : null
                    }
                  />
                  {hasError(errors, 'conversionTrackerId') && (
                    <div className="text-red">
                      {errorMessage(errors, 'conversionTrackerId')}
                    </div>
                  )}
                </div>
              </div>

              {selectedConversionTracker?.allowsCustomValues ? (
                <div className="mb-4">
                  <div className="flex">
                    <div className="flex-1 mr-2">
                      <FieldLabel for="leadValue" label="Lead value" />
                      <MoneyInput
                        className="w-full"
                        id="leadValue"
                        onChange={(val) => {
                          setLeadValue(val);
                        }}
                        placeholder="Lead value"
                        value={leadValue}
                        variant={hasError(errors, 'leadValue') ? 'error' : null}
                      />
                      {hasError(errors, 'leadValue') && (
                        <div className="text-red">
                          {errorMessage(errors, 'leadValue')}
                        </div>
                      )}
                    </div>

                    <div className="flex-1 ml-2 mr-2">
                      <FieldLabel
                        for="valueCurrency-input"
                        label="Value currency"
                      />
                      <CKPicker
                        id="valueCurrency"
                        items={currencies.map((currency) => ({
                          label: currency,
                          value: currency,
                        }))}
                        itemToString={(item) => (item ? item.label : '')}
                        onSelectedItemChange={({ selectedItem }) => {
                          setLeadValueCurrency(selectedItem.value);
                        }}
                        placeholder="Value currency"
                        selectedItem={
                          !!leadValueCurrency && {
                            value: leadValueCurrency,
                            label: leadValueCurrency,
                          }
                        }
                        variant={
                          hasError(errors, 'leadValueCurrency') ? 'error' : null
                        }
                      />
                      {hasError(errors, 'leadValueCurrency') && (
                        <div className="text-red">
                          {errorMessage(errors, 'leadValueCurrency')}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
            </ContentBox>
          </FormContentWrapper>
          <FormFooter
            destinationConfig={{
              config: {
                leadValue,
                leadValueCurrency,
                googleAdAccountId: adAccountId,
                conversionTrackerId,
              },
              triggerType,
            }}
          />
        </>
      ) : (
        <DebugConsole destination={destination} segment={segment} />
      )}
    </>
  );
}

GoogleConversion.propTypes = {
  destination: PropTypes.object,
  destinationOptions: PropTypes.object,
  errors: PropTypes.array.isRequired,
  refetch: PropTypes.func.isRequired,
  accountConnectors: PropTypes.shape({
    google: PropTypes.shape({
      adAccounts: PropTypes.array,
    }),
  }),
  segment: PropTypes.object,
};

export default GoogleConversion;
