export type CreditUsageProductType =
  | 'exports'
  | 'forms'
  | 'alerts'
  | 'crm enrichments'
  | 'platform prospector'
  | 'contacts created'
  | 'companies created';
export type CurrentPeriodTotalUsage = Record<
  string,
  { credits: number; uniques: number }
>;
export type UsageMetricGraph = Record<
  string,
  { modifier: number; metricSets: Record<string, unknown> }
>;

type meteredUnitInput =
  | CreditUsageProductType
  | 'discovery api'
  | 'prospector api'
  | 'person api'
  | 'company api'
  | 'reveal api';

export function meteredUnit(product: meteredUnitInput) {
  switch (product.toLocaleLowerCase()) {
    case 'exports':
      return `unique records exported`;
    case 'forms':
      return `forms enriched`;
    case 'alerts':
      return `alerts sent`;
    case 'discovery api':
      return 'discovery records';
    case 'prospector api':
      return 'prospector records';
    case 'person api':
      return 'person api calls';
    case 'company api':
      return 'company api calls';
    case 'crm enrichments':
      return 'unique records enriched';
    case 'reveal api':
      return 'reveal api calls';
    case 'platform prospector':
      return 'prospects exported';
    case 'contacts created':
      return 'contacts created';
    case 'companies created':
      return 'companies created';
    case 'prospector csv export':
      return 'contacts exported to CSV';
  }
}

export function ShouldDisplayCreditUsageTile(
  productFeature: string,
): productFeature is CreditUsageProductType {
  return [
    'exports',
    'forms',
    'alerts',
    'crm enrichments',
    'platform prospector',
    'contacts created',
    'companies created',
    'prospector csv export',
  ].includes(productFeature.toLocaleLowerCase());
}

export const usageHelpDeskLink =
  'https://help.clearbit.com/hc/en-us/articles/19677786369687-Sales-Intelligence-Understanding-Credits-Usage';
