import { UsageAltNav } from '@clearkit/icons';
import Helmet from 'react-helmet';

import LoadingArea from '~/components/LoadingArea';
import { PageLayout } from '~/components/PageLayout';
import { UsageOverviewEnrichment } from '~/components/usage/UsageOverviewEnrichment';
import { UsageOverviewLegacy } from '~/components/usage/UsageOverviewLegacy';
import { useEntitlements } from '~/hooks/account';

export const UsageOverview = () => {
  const { hasAccessEnrichmentHubspot, loading } = useEntitlements();
  const pageSubheading = hasAccessEnrichmentHubspot
    ? "Track your team's enrichment usage with Clearbit"
    : 'Understand your platform usage';

  return (
    <>
      <Helmet>
        <title>Usage</title>
      </Helmet>
      <PageLayout>
        <PageLayout.Header>
          <PageLayout.Tile>
            <UsageAltNav />
          </PageLayout.Tile>
          <PageLayout.Heading>Usage</PageLayout.Heading>
          <PageLayout.SubHeading>{pageSubheading}</PageLayout.SubHeading>
        </PageLayout.Header>
        <PageLayout.Body>
          {loading ? (
            <LoadingArea className="w-full h-full" loading />
          ) : (
            <>
              {hasAccessEnrichmentHubspot ? (
                <UsageOverviewEnrichment />
              ) : (
                <UsageOverviewLegacy />
              )}
            </>
          )}
        </PageLayout.Body>
      </PageLayout>
    </>
  );
};
