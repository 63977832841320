import {
  Clearbit as ClearbitIcon,
  EnrichmentBlock,
  Segment as SegmentsIcon,
  Sprocket as SprocketIcon,
  Trait as TraitIcon,
  View as EyeIcon,
} from '@clearkit/icons';

const selectedIcon = {
  pageview: <EyeIcon data-testid="eye-icon" />,
  clearbit: <ClearbitIcon data-testid="clearbit-icon" />,
  segment: <SegmentsIcon data-testid="segment-icon" />,
  system_traits: <SprocketIcon data-testid="sprocket-icon" />,
  custom_traits: <TraitIcon data-testid="trait-icon" />,
};

const getIconType = (attribute) => {
  if (!attribute) return null;

  const sourceType = attribute?.meta?.sourceType;
  if (sourceType === 'system_traits' || sourceType === 'custom_traits') {
    return selectedIcon[sourceType];
  }

  return selectedIcon[findName(attribute?.identifier)];
};

const findName = (name) =>
  Object.keys(selectedIcon).find((nameIcon) => !!name.match(nameIcon));

export default function ServiceIcon({ type }) {
  return (
    getIconType(type) || <EnrichmentBlock data-testid="enrichment-block-icon" />
  );
}
