import { DEFAULT_FIELDS } from './constants';

export const filterPresetFields = (fields: any) =>
  fields.filter((field: any) => !DEFAULT_FIELDS.includes(field.name));

export const generateValueKeys = (fields: any) => {
  const keys: any = {};

  fields.forEach((field: any) => {
    keys[field.name] = '';
  });

  return keys;
};
