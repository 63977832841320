const styles = () => ({
  colorSwitchBase: {
    '&$colorChecked': {
      '& + $greenBar': {
        backgroundColor: '#36C480',
      },
    },
  },

  icon: {
    background: '#9BADBC',
    width: 14,
    height: 14,
    left: -10,
    '&:after': {
      width: 6,
      height: 6,
    },
  },

  bar: {
    height: 10,
    marginTop: -5,
    marginLeft: -12,
    width: 25,
    background: 'rgba(155,173,188,0.40)',
    borderRadius: 6,
  },

  greenBar: {
    height: 10,
    marginTop: -5,
    marginLeft: -13,
    width: 25,
    borderRadius: 6,
  },

  enabledIcon: {},

  colorChecked: {
    '& $enabledIcon': {
      background: 'white',
    },
  },

  checkedIcon: {
    background: 'white',
    width: 20,
    height: 20,
    padding: 3,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '50%',
    justifyContent: 'center',
    border: '1px solid rgba(89,97,104,0.4)',
    boxShadow: '0 1px 2px 0 rgba(0,0,0,0.05)',
  },
});

export default styles;
