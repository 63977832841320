import { createContext, useEffect, useState } from 'react';

import { AccountFlagEnum } from '~/generated/graphql';
import { useCurrentAccount, useFeatureFlag } from '~/hooks/account';
import { useCreditUsage } from '~/hooks/account/useCreditUsage';
import { getCostPerMonth, planTierLabelMap } from '~/lib/selfServePlanHelpers';

import { PendingPlanChange } from './types';
import { cleanDate } from './utils';

type CurrentPlan = {
  costPerMonth: number;
  credits: number;
  isHubspotAccount: boolean;
  planLabel: string;
  renewDate?: Date;
  startDate?: Date;
};

type CurrentPlanHook = {
  accountName?: string;
  canAccountUpgrade?: boolean;
  currentPlan?: CurrentPlan;
  pendingPlanChange?: PendingPlanChange;
  setPendingPlanChange: (plan?: PendingPlanChange) => void;
  setCurrentPlan: (plan?: CurrentPlan) => void;
};

const RENEWAL_INTERVAL = 1;

export const CurrentPlanContext = createContext<CurrentPlanHook>({
  setPendingPlanChange: () => {},
  setCurrentPlan: () => {},
});

export const createRenewalDate = (startDate: Date | undefined) => {
  if (!startDate) {
    return undefined;
  }

  const renewDate = new Date(startDate);
  renewDate.setFullYear(renewDate.getFullYear() + RENEWAL_INTERVAL);
  return renewDate;
};

export const useCurrentPlan = () => {
  const { account } = useCurrentAccount();
  const { credits, planId, isHubspotAccount, isPaidAccount } = useCreditUsage();
  const [currentPlan, setCurrentPlan] = useState<CurrentPlan | undefined>(
    undefined,
  );
  const [pendingPlanChange, setPendingPlanChange] = useState<
    PendingPlanChange | undefined
  >(undefined);

  const accountCurrentPlan = account?.subscriptions?.find(
    (subscription) => subscription.planId === planId,
  );
  const downgradeLimit = accountCurrentPlan?.downgradeLimit;
  const downgradeDate = accountCurrentPlan?.downgradeDate;

  const startDate = accountCurrentPlan?.startDate
    ? new Date(cleanDate(accountCurrentPlan?.startDate))
    : undefined;
  const renewDate = createRenewalDate(startDate);

  const planLabel = planId
    ? planTierLabelMap[planId as keyof typeof planTierLabelMap]
    : 'Free';

  useEffect(() => {
    setCurrentPlan({
      costPerMonth: getCostPerMonth({
        credits,
        isHubspotAccount,
      }),
      credits,
      isHubspotAccount,
      planLabel,
      renewDate,
      startDate,
    });
  }, [accountCurrentPlan]);

  useEffect(() => {
    if (downgradeLimit) {
      setPendingPlanChange({
        costPerMonth:
          getCostPerMonth({
            credits: downgradeLimit ?? 0,
            isHubspotAccount,
          }) ?? 0,
        credits: downgradeLimit,
        effectiveDate: downgradeDate ? new Date(downgradeDate) : undefined,
        isDowngrading: true,
      });
    } else {
      setPendingPlanChange(undefined);
    }
  }, [accountCurrentPlan]);

  const accountName = account?.name;

  const bypassDisabledUpgrade = useFeatureFlag(
    AccountFlagEnum.AllowPlatformBypassDisabledUpgrade,
  ).enabled;
  const canAccountUpgrade = isPaidAccount || bypassDisabledUpgrade;

  return {
    accountName,
    canAccountUpgrade,
    currentPlan,
    pendingPlanChange,
    setCurrentPlan,
    setPendingPlanChange,
    startDate,
  } as CurrentPlanHook;
};
