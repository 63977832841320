import { CKLayoutGrid } from 'clearkit';

import { WhenProspector } from '../../policies/WhenProspector';
import { FetchErrorRetry } from '../CallsToAction/FetchErrorRetry';
import { NoAccess } from '../CallsToAction/NoAccess';
import {
  DeleteProspectList,
  ExportProspectsDrawer,
  RenameProspectList,
} from '../Dialogs';
import { Loading } from '../Loading';
import { ProspectListBody } from '../ProspectList/ProspectListBody';
import { SidebarFilters } from '../Sidebar/SidebarFilters';
import { SidebarLists } from '../Sidebar/SidebarLists';
import { useProspector } from '../useProspector';
import { ProspectorProvider } from '../useProspector/Provider';

export function ProspectorShowPage() {
  return (
    <WhenProspector cta={<NoAccess />}>
      <ProspectorProvider>
        <CKLayoutGrid className="w-full" gap="0" gridTemplateColumns="auto 1fr">
          <Prospector />
        </CKLayoutGrid>
      </ProspectorProvider>
    </WhenProspector>
  );
}

function Prospector() {
  const [state] = useProspector();

  const { showFilterSidebar } = state.context;

  if (state.matches('loading')) {
    return <Loading />;
  } else if (state.matches('error')) {
    return <FetchErrorRetry />;
  }

  return (
    <>
      {showFilterSidebar ? <SidebarFilters /> : <SidebarLists />}
      <ProspectListBody />
      <RenameProspectList />
      <DeleteProspectList />
      <ExportProspectsDrawer />
    </>
  );
}
