import { Email as EmailIcon } from '@clearkit/icons';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { CKInputChips, CKLayoutGrid, CKSelectNext } from 'clearkit';
import { useState } from 'react';

import MultiPicklist from '~/components/MultiPicklist';
import TextField from '~/components/TextField';
import { destinationTypeEmail } from '~/lib/destinations';
import { emailRegex } from '~/lib/email';
import { hasError } from '~/lib/graphql';
import {
  legacySegmentType,
  readableSegmentType,
  readableSingularSegmentType,
} from '~/lib/segments';
import DeliverTestButton from '~/pages/destinations/components/DeliverTestButton';
import SuppressionForm from '~/pages/destinations/components/SuppressionForm';
import TriggerType from '~/pages/destinations/components/TriggerType';
import defaultTriggerTypes from '~/pages/destinations/components/TriggerType/defaultValues';

import ContentBox from '../../components/ContentBox';
import FormContentWrapper from '../../components/FormContentWrapper';
import FormHeader from '../../components/FormHeader';
import sharedStyles from '../../New/styles';
import {
  getDeliveryTargets,
  HUBSPOT_TARGETS,
  SALESFORCE_TARGETS,
} from '../dynamicDeliveryTargets';
import { DeliverySchedule } from './DeliverySchedule';
import EmailDestinationDescription from './DestinationDescription';
import styles from './styles';

function EmailDestination({
  classes,
  errors,
  segment,
  attributes,
  destination,
  accountConnectors,
  FormFooter,
}) {
  let deliveryTargets = [
    {
      value: 'email',
      label: 'Email addresses',
      icon: <EmailIcon className="w-4 h-4 fill-gradient-br-blue" />,
    },
  ];

  if (accountConnectors.salesforce) {
    deliveryTargets = [
      ...deliveryTargets,
      ...getDeliveryTargets(SALESFORCE_TARGETS, segment.type),
    ];
  }
  if (accountConnectors.hubspot) {
    deliveryTargets = [
      ...deliveryTargets,
      ...getDeliveryTargets(HUBSPOT_TARGETS, segment.type),
    ];
  }

  const isNew = !destination;
  const initialConfig = isNew ? {} : destination.config;
  const initialState = {
    columns: initialConfig.columns || [],
    duplicateSuppressionTimeframe:
      initialConfig.duplicateSuppressionTimeframe || 0,
    frequency: initialConfig.frequency || 'hourly',
    scheduleDay: initialConfig.scheduleDay || 0,
    scheduleHour: initialConfig.scheduleHour || 9,
    scheduleStartOfPeriod: initialConfig.scheduleStartOfPeriod || 0,
    recipients: initialConfig.recipients || [],
    subject: initialConfig.subject || null,
    deliveryTarget: initialConfig.deliveryTarget || 'email',
    triggerType: isNew ? 'segment_enter' : destination.triggerType,
  };

  const [
    duplicateSuppressionTimeframe,
    setDuplicateSuppressionTimeframe,
  ] = useState(initialState.duplicateSuppressionTimeframe);
  const [frequency, setFrequency] = useState(initialState.frequency);
  const [recipients, setRecipients] = useState(initialState.recipients);
  const [subject, setSubject] = useState(initialState.subject);
  const [triggerType, setTriggerType] = useState(initialState.triggerType);
  const [columns, setColumns] = useState(initialState.columns);
  const [scheduleDay, setScheduleDay] = useState(initialState.scheduleDay);
  const [scheduleHour, setScheduleHour] = useState(initialState.scheduleHour);
  const [scheduleStartOfPeriod, setScheduleStartOfPeriod] = useState(
    initialState.scheduleStartOfPeriod,
  );

  const [deliveryTarget, setDeliveryTarget] = useState(
    initialState.deliveryTarget,
  );

  const destinationConfig = () => {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    return {
      config: {
        columns,
        duplicateSuppressionTimeframe,
        frequency,
        scheduleDay: scheduleDay || 0,
        scheduleHour: scheduleHour || 9,
        scheduleStartOfPeriod,
        recipients,
        deliveryTarget,
        subject,
        timeZone,
      },
      triggerType,
    };
  };

  const humanizedSegmentType = readableSingularSegmentType(segment.type);
  const destinationFlowDescription = destination ? (
    <EmailDestinationDescription config={destination.config} />
  ) : null;

  const selectedDeliveryTarget = deliveryTargets.find(
    (target) => target.value === deliveryTarget,
  );

  return (
    <>
      <FormHeader
        destinationFlowDescription={destinationFlowDescription}
        destinationName="Email Digest"
        destinationType={destinationTypeEmail}
        segmentCollection={segment.collection.name}
        segmentName={segment.name}
      />

      <FormContentWrapper>
        <h2 className={classes.subheading}>Trigger</h2>
        <span className={classes.triggerSubtext}>
          Select the event that triggers this Destination to run.{' '}
          <a
            href="https://help.clearbit.com/hc/en-us/articles/360023453694"
            rel="noopener noreferrer"
            style={{ textDecoration: 'underline' }}
            target="_blank"
          >
            Learn more
          </a>
        </span>

        <TriggerType
          errors={errors}
          handleChange={(_, value) => {
            setTriggerType(value);
          }}
          triggerType={triggerType}
          triggerTypes={defaultTriggerTypes(segment.type)}
        />

        <h2 className={classes.subheading}>Configure email options</h2>

        <ContentBox>
          <FormControl fullWidth variant="standard">
            <InputLabel required>Recipients</InputLabel>
            <>
              <CKLayoutGrid className="mb-4" gridTemplateColumns="1fr 2fr">
                <CKSelectNext
                  className="mt-2"
                  isDisabled={deliveryTargets.length === 1}
                  items={deliveryTargets}
                  onChange={(newValue) => setDeliveryTarget(newValue.value)}
                  placeholder="Select recipients"
                  value={{
                    value: deliveryTarget,
                    label: selectedDeliveryTarget.label,
                  }}
                >
                  <CKSelectNext.IconLeft>
                    {selectedDeliveryTarget.icon}
                  </CKSelectNext.IconLeft>
                </CKSelectNext>
                <EmailInput
                  className="mt-1"
                  errors={errors}
                  placeholder={
                    deliveryTarget === 'email'
                      ? 'Enter email addresses'
                      : 'Enter fallback email addresses'
                  }
                  recipients={recipients}
                  required={false}
                  segment={segment}
                  setRecipients={setRecipients}
                />
              </CKLayoutGrid>
              {deliveryTarget === 'email' ? null : (
                <p className="mb-4 text-sm">
                  Send and Email Digest dynamically to the assigned Owner within
                  your CRM. You can set one or more fallback email addresses in
                  case there is no defined owner.
                </p>
              )}
            </>
          </FormControl>

          <TextField
            className={classes.field}
            defaultValue={subject}
            error={hasError(errors, 'subject')}
            fullWidth
            InputProps={{
              placeholder: `New ${readableSegmentType(segment.type)} matched`,
            }}
            label="Email subject"
            onChange={(event) => {
              setSubject(event.target.value);
            }}
            required
            variant="standard"
          />

          <hr className={classes.break} />

          <SuppressionForm
            exclusion_timeframe={duplicateSuppressionTimeframe}
            onChange={({ exclusion_timeframe }) => {
              setDuplicateSuppressionTimeframe(exclusion_timeframe);
            }}
            typename={humanizedSegmentType}
          />

          <hr className={classes.break} />

          <DeliverySchedule
            {...{
              frequency,
              setFrequency,
              scheduleDay,
              setScheduleDay,
              scheduleHour,
              setScheduleHour,
              scheduleStartOfPeriod,
              setScheduleStartOfPeriod,
            }}
          />
        </ContentBox>
        <CustomizeAttributes
          {...{
            attributes,
            columns,
            setColumns,
            classes,
            segment,
          }}
        />

        <EmailTest
          {...{
            classes,
            segment,
            columns,
            duplicateSuppressionTimeframe,
            recipients,
            frequency,
            subject,
          }}
        />
      </FormContentWrapper>

      <FormFooter destinationConfig={destinationConfig()} />
    </>
  );
}

function CustomizeAttributes({
  attributes,
  columns,
  setColumns,
  classes,
  segment,
}) {
  return (
    <>
      <h2 className={classes.subheading}>Customize your CSV</h2>

      <p className={classes.columnsHelperText}>
        Select which attributes you want to include as columns in your CSV
      </p>

      <MultiPicklist
        allColumns={attributes}
        hubspotComingSoon
        onChange={(value) => {
          setColumns(value);
        }}
        type={legacySegmentType(segment.type)}
        value={columns}
      />
    </>
  );
}

function EmailInput({
  className,
  errors,
  recipients,
  setRecipients,
  placeholder,
  required = true,
  errorKey = 'recipients',
}) {
  const handleChange = (emails) => {
    const validEmails = emails.filter((email) => email.match(emailRegex));

    setRecipients(validEmails);
  };

  const showErrorMessage = hasError(errors, errorKey);
  return (
    <CKInputChips
      chipMaxWidth="30ch"
      className={className}
      maxHeight="10rem"
      onChange={handleChange}
      placeholder={placeholder}
      required={required}
      shouldOverflow={false}
      value={recipients}
    >
      {showErrorMessage ? (
        <CKInputChips.ErrorMessage>
          Enter a valid email address.
        </CKInputChips.ErrorMessage>
      ) : null}
    </CKInputChips>
  );
}

function EmailTest({
  classes,
  segment,
  columns,
  duplicateSuppressionTimeframe,
  recipients,
  frequency,
  subject,
}) {
  let [testRecipients, setTestRecipients] = useState(
    recipients[0] ? [recipients[0]] : [],
  );

  return (
    <>
      <h2 className={classes.subheading}>Test this destination</h2>
      <span className={classes.triggerSubtext}>
        Run a test by manually triggering this destination
      </span>
      <ContentBox>
        <DeliverTestButton
          config={{
            columns,
            duplicateSuppressionTimeframe,
            frequency,
            recipients: testRecipients,
            subject,
          }}
          destinationType="email"
          label="email"
          segmentId={segment.id}
          segmentType={segment.type}
        >
          <TextField
            FormControlProps={{
              classes: {
                root: classes.testRecipientTextField,
              },
            }}
            label="Test email recipient"
            onChange={(event) => setTestRecipients([event.target.value])}
            value={testRecipients}
            variant="outlined"
          />
        </DeliverTestButton>
      </ContentBox>
    </>
  );
}

EmailDestination.propTypes = {
  accountConnectors: PropTypes.shape({
    salesforce: PropTypes.object,
    hubspot: PropTypes.object,
  }),
  attributes: PropTypes.array.isRequired, //TODO: arrayOf
  classes: PropTypes.object.isRequired,
  destination: PropTypes.object,
  errors: PropTypes.array.isRequired,
  segment: PropTypes.object.isRequired,
  FormFooter: PropTypes.elementType.isRequired,
};

export default withStyles(
  (theme) => ({
    ...sharedStyles(theme),
    ...styles(theme),
  }),
  { name: 'SegmentDestinationNewEmail' },
)(EmailDestination);
