import { Trash as TrashIcon } from '@clearkit/icons';
import { CKButton } from 'clearkit';
import get from 'lodash/get';

import AttributeSelector from '~/components/SearchBuilder/components/AttributeSelector';
import {
  changeComparator,
  changeNodeAttribute,
  changeValue,
  getAndOrOr,
  getAttribute,
  getComparator,
  getValue,
  showValueForNode,
} from '~/components/SearchBuilder/utils/nodeUtils';
import {
  removeNode,
  replaceNode,
  SearchContext,
} from '~/components/SearchBuilder/utils/searchUtils';

import styles from '../styles';
import attributeTypes from '../utils/attributeTypes';
import {
  getRollupNodeValue,
  isMultiColumnStringAttribute,
  isRollupNode,
  setRollupNodeProperties,
} from '../utils/searchUtilsRollup';
import Comparator from './Comparator';
import Event from './Event/Event';
import Value from './Value';

class QueryNode extends React.Component {
  onChangeAttribute = (attribute) => {
    const { attributes, node, search, onChange } = this.props;
    const updatedNode = changeNodeAttribute(
      node,
      attribute,
      attributes,
      search,
    );
    const newSearch = replaceNode(search, updatedNode);
    onChange(newSearch);
  };

  replaceNode = (updatedNode) => {
    const { node, onChange, search } = this.props;
    const newSearch = replaceNode(search, node, updatedNode);
    onChange(newSearch);
  };

  onChangeComparator = (value) => {
    const { node, attributes } = this.props;
    const updatedNode = changeComparator(node, value, attributes);
    this.replaceNode(updatedNode);
  };

  onChangeValue = (value) => {
    const { node } = this.props;
    const { attributes, category, subCategory } = this.props;

    const attribute = getAttribute(node, attributes, category, subCategory);
    const multiColumnStringAttribute = isMultiColumnStringAttribute(attribute);
    const isRollup = isRollupNode(node);

    if (multiColumnStringAttribute) {
      this.changeMultiColumnStringValue(value);
    } else if (isRollup) {
      this.changeRollupValue(value);
    } else {
      const updatedNode = changeValue(node, value);
      this.replaceNode(updatedNode);
    }
  };

  changeRollupValue = (value) => {
    const { node } = this.props;
    const newNode = setRollupNodeProperties(node, value);
    this.replaceNode(newNode);
  };

  changeMultiColumnStringValue = (value) => {
    const { node, attributes } = this.props;
    const andOrOr = getAndOrOr(node);
    node[andOrOr] = node[andOrOr].map((subNode) =>
      changeValue(subNode, value, attributes),
    );
    this.replaceNode(node);
  };

  onChangeEvent = (updatedNode) => {
    this.replaceNode(updatedNode);
  };

  render() {
    const {
      classes,
      node,
      attributes,
      disabledCategories,
      search,
      onChange,
      category,
      subCategory,
      menusAreOpen,
    } = this.props;
    const { invalidNodes, disabled } = this.context;
    const invalid = invalidNodes.find((n) => n.__index === node.__index);

    const { event } = node;
    const attribute = getAttribute(node, attributes, category, subCategory);
    const attributeValue = get(attribute, 'value');
    const showValue = showValueForNode(node, attribute);
    const details = get(attribute, 'details', {});
    let comparator = getComparator(node);
    if (!attributeValue) comparator = false;

    let value = getValue(node);
    if (details.type === attributeTypes.NESTED_SELECT) {
      value = getRollupNodeValue(node);
    }

    return (
      <div
        className={classnames(classes.queryGroupLine, {
          [classes.invalidLine]: invalid,
        })}
        data-testid="QueryNode"
      >
        <AttributeSelector
          attributes={attributes}
          category={category}
          className={classes.attribute}
          classNamePrefix="attribute"
          disabled={disabled}
          disabledCategories={disabledCategories}
          key={attributeValue}
          menuIsOpen={menusAreOpen}
          onChange={this.onChangeAttribute}
          subCategory={subCategory}
          value={attributeValue}
        />
        {event ? (
          <Event
            hosts={details.hosts}
            node={node}
            onChange={this.onChangeEvent}
          />
        ) : (
          <>
            <Comparator
              attribute={attribute}
              className={classes.comparator}
              classNamePrefix="comparator"
              isDisabled={!attributeValue || disabled}
              menuIsOpen={menusAreOpen}
              onChange={this.onChangeComparator}
              value={comparator}
            />
            {showValue && (
              <Value
                attribute={attribute}
                className={classes.value}
                classNamePrefix="value"
                comparator={comparator}
                disabled={disabled}
                menuIsOpen={menusAreOpen}
                onChange={this.onChangeValue}
                options={details.values}
                type={details.type}
                value={value}
              />
            )}
          </>
        )}
        {!disabled && (
          <CKButton
            className="ml-2 text-gray-600"
            data-testid="QueryNode__delete"
            isDisabled={disabled}
            onClick={() => {
              const updatedSearch = removeNode(search, node);
              onChange(updatedSearch);
            }}
            variant="tertiary"
          >
            <TrashIcon />
          </CKButton>
        )}
      </div>
    );
  }
}

QueryNode.defaultProps = {
  classes: {},
  updateNode: () => {},
};

QueryNode.propTypes = {
  search: PropTypes.object,
  onChange: PropTypes.func,
  attributes: PropTypes.array,
  classes: PropTypes.object,
  node: PropTypes.object,
  disabledCategories: PropTypes.arrayOf(PropTypes.string),
  category: PropTypes.string,
  subCategory: PropTypes.string,
  invalid: PropTypes.bool,
  menusAreOpen: PropTypes.bool,
};

QueryNode.contextType = SearchContext;

export default withStyles(styles)(QueryNode);
