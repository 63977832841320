import { CKBox, CKSwitch } from 'clearkit';

import SearchBuilder from '~/components/SearchBuilder';

export const ConfigureROIMetricsStep = ({
  id,
  enabled,
  onToggle,
  attributes,
  search,
  onSearchChange,
}) => {
  return (
    <CKBox>
      <div className="p-4">
        <CKSwitch checked={enabled} id={id} onChange={onToggle}>
          Enable this step in the funnel
        </CKSwitch>
      </div>
      {enabled && (
        <div className="p-4 border-t border-gray-200">
          <SearchBuilder
            attributes={attributes}
            onChange={onSearchChange}
            search={search}
            type="Person"
          />
        </div>
      )}
    </CKBox>
  );
};

export default ConfigureROIMetricsStep;
