import { Hubspot } from '@clearkit/icons';
import { CKContainerProps, CKLayoutHorizontal } from 'clearkit';

import { ListItemCheckbox } from '~/components/ListItem/ListItemCheckbox';
import { ListItemGrid } from '~/components/ListItem/ListItemGrid';
import { Prospect } from '~/generated/graphql';

import { CRMToken } from './CRMToken';
import { ItemBlockCompany } from './ItemBlockCompany';
import { ItemBlockPerson } from './ItemBlockPerson';

type ListItemProps = Omit<CKContainerProps, 'onSelect'> & {
  prospect: Prospect;
  isSelected: boolean;
  isLoadingCrmPresence?: boolean;
  isHubSpotConnected?: boolean;
  isInHubSpot?: boolean;
  onSelect?: (id: string) => void;
};

export const ListItem = ({
  className,
  prospect,
  isHubSpotConnected = false,
  isInHubSpot = false,
  isLoadingCrmPresence = false,
  isSelected,
  onSelect,
}: ListItemProps) => {
  const { id } = prospect;
  const handleSelect = () => {
    onSelect?.(id);
  };

  return (
    <ListItemCheckbox
      className={className}
      id={id}
      isSelected={isSelected}
      onSelect={handleSelect}
    >
      <ListItemGrid gridTemplateColumns="4fr 3fr">
        <ItemBlockPerson prospect={prospect} />
        <ListItemGrid.Right>
          <ItemBlockCompany prospect={prospect} />
        </ListItemGrid.Right>
      </ListItemGrid>
      <CKLayoutHorizontal
        alignItems="start"
        className="pt-2 pl-3 text-gray-300 border-l border-gray-200"
        gap=".75rem"
        justifyContent="center"
      >
        <CRMToken
          activeClassName="text-hubspot"
          inCrm={isInHubSpot}
          isCrmConnected={isHubSpotConnected}
          isLoading={isLoadingCrmPresence}
          service="hubspot"
        >
          <Hubspot />
        </CRMToken>
      </CKLayoutHorizontal>
    </ListItemCheckbox>
  );
};
