import IconSecure from '~/images/onboarding/icon-secure.svg';

import styles from './styles.js';

const SecurityDisclaimer = ({ className, classes }) => {
  return (
    <div className={classnames(classes.disclaimerContainer, className)}>
      <IconSecure className={classes.disclaimerIcon} />
      <span className={classes.disclaimer}>
        Your data is kept securely by Clearbit.{' '}
        <a
          className={classes.disclaimerLink}
          href="https://help.clearbit.com/hc/en-us/articles/360022737373-Clearbit-X-Data-Privacy-and-Security"
          rel="noopener noreferrer"
          target="_blank"
        >
          Learn more
        </a>{' '}
        about how Clearbit uses your data.
      </span>
    </div>
  );
};

export default withStyles(styles)(SecurityDisclaimer);
