import lodash, { capitalize } from 'lodash';

// builds billing_usage usage_graph for usage breakdown based on inputed metric subset and external types
export function buildBreakdownUsageGraph(
  metricSubset: string,
  externalTypes: string[],
  title: string,
) {
  const baseGraph: { [key: string]: MetricGraph } = {
    [metricSubset]: { external_types: {} },
  };

  externalTypes?.forEach((externalType) => {
    baseGraph[metricSubset]['external_types'][externalType] = {};
  });
  return {
    [`${title}`]: {
      metric_sets: { billing_usage: { metric_subsets: baseGraph } },
    },
  };
}

type MetricGraph = {
  [key: string]: { [key: string]: Record<string, any> };
};
// Takes usageMetricGraph outputted from buildBreakdownUsageGraph and creates additional groupings based off of additionalBreakdownMetricValues
export function buildUsageMetricGraphCollection(
  usageMetricGraph: { [key: string]: MetricGraph },
  metricSubset: string,
  additionalBreakdownMetric: string,
  additionalBreakdownMetricValues: string[],
  title: string,
) {
  const results: Record<string, unknown> = {};
  additionalBreakdownMetricValues?.forEach((value) => {
    const baseGraph = lodash.cloneDeep(usageMetricGraph);
    baseGraph[title]['metric_sets']['billing_usage']['metric_subsets'][
      metricSubset
    ][additionalBreakdownMetric] = {
      [value]: {},
    };

    results[capitalize(value.split('_').join(' '))] = baseGraph[title];
  });
  return results;
}
