import { SearchContext } from '~/components/SearchBuilder/utils/searchUtils';
import Select from '~/components/Select';

const ValueBoolean = ({ className, value, onChange }) => {
  const { disabled } = useContext(SearchContext);
  return (
    <Select
      className={className}
      isDisabled={disabled}
      onChange={(value) => onChange(value.value)}
      options={['true', 'false'].map((v) => ({ label: v, value: v }))}
      placeholder="Select a value"
      value={value && { value, label: value }}
    />
  );
};

ValueBoolean.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
};

export default ValueBoolean;
