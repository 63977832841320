import {
  CheckmarkOrb,
  CloseOrb,
  Download,
  Error,
  SpinnerIndicators,
} from '@clearkit/icons';
import classnames from 'classnames';
import {
  CKButton,
  CKIconBox,
  CKLayoutGrid,
  CKLayoutHorizontal,
} from 'clearkit';
import { memo, useEffect, useRef, useState } from 'react';

import { ButtonContainer } from '~/components/unified/ButtonContainer';
import { useStatusToast } from '~/components/unified/hooks';
import {
  ProspectListExport,
  ProspectListExportStatusEnum,
} from '~/generated/graphql';
import { useCreditUsage } from '~/hooks/account/useCreditUsage';
import { EMPTY_LABEL } from '~/lib/constants';

import { CSVOverageModal } from '../Dialogs';
import { useProspector } from '../useProspector';
import { convertExportItemsToCSV } from './convertExportItemsToCSV';
import { Count } from './Count';
import { usePollAndExport } from './usePollAndExport';

type ExportItemProps = {
  exportItem: ProspectListExport;
};

type StatusType = ProspectListExportStatusEnum;

const StatusIcon = memo(({ status }: { status: StatusType }) => {
  const StatusIcon = () => {
    const size = { height: 20, width: 20 };

    if (status === 'purchased')
      return <CheckmarkOrb {...size} className="fill-gradient-br-green" />;
    if (status === 'pending' || status === 'completed')
      return <Download {...size} className="fill-gradient-br-green" />;
    if (status === 'failed')
      return <Error {...size} className="fill-gradient-br-yellow" />;
    if (status === 'canceled')
      return <CloseOrb {...size} className="fill-gradient-br-red" />;
    return <SpinnerIndicators {...size} />;
  };

  return (
    <CKIconBox className="self-start justify-center basis-auto grow-0 shrink-0">
      <StatusIcon />
    </CKIconBox>
  );
});

export const ExportItem = ({ exportItem }: ExportItemProps) => {
  const pollAndExport = usePollAndExport(exportItem.id);
  const { credits, loading, creditsPerProspect } = useCreditUsage();
  const { addErrorToast } = useStatusToast();
  const [_state, send] = useProspector();

  const [isPolling, setIsPolling] = useState(false);
  const [isOverageModalOpen, setIsOverageModalOpen] = useState(false);
  const csvRef = useRef<HTMLAnchorElement>(null);

  const { status } = exportItem;
  const totalCount = exportItem.metadata?.totalCount;
  const totalCreated = exportItem.metadata?.totalCreated;
  // When an export is processing we might not have the total created count yet, so fall back to total count
  const totalCountForCredits = totalCreated || totalCount || 0;
  const totalCreditsUsed = totalCountForCredits * creditsPerProspect;

  const timeStamp = new Date(exportItem.createdAt).toLocaleString('en-us', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    year: 'numeric',
  });

  const isCSV = exportItem.exportType === 'csv';
  const isCompleted = status === 'completed';
  const isPurchased = status === 'purchased';
  const isFinished = isCompleted || isPurchased;
  const isFailed = status === 'failed';
  const isPending = status === 'pending';
  const isProcessing = status === 'initializing' || status === 'exporting';
  const showDownload = (isFinished || isPending) && isCSV;
  const showCredits = isFinished || isPending;
  const isOverLimit = (totalCreditsUsed || 0) > credits;

  const labelClasses = 'font-medium whitespace-nowrap truncate';
  const itemDividerClasses = 'border-l border-gray-200 pl-6';

  const [showDiscardButton, setShowDiscardButton] = useState(isCompleted);

  useEffect(() => {
    setShowDiscardButton(isCompleted);
  }, [isCompleted]);

  function handleCancel() {
    send('CANCEL_EXPORT', { id: exportItem.id });
  }

  async function handleDownload() {
    if (status !== 'purchased') {
      setIsPolling(true);
      send('DOWNLOAD_EXPORT', { id: exportItem.id });
      setShowDiscardButton(false);
    }

    try {
      const data = await pollAndExport();

      const csv = convertExportItemsToCSV(data as ProspectListExport);
      const file = new Blob([csv], { type: 'text/csv' });
      const url = URL.createObjectURL(file);

      csvRef.current?.setAttribute('href', url);
      csvRef.current?.setAttribute(
        'download',
        'clearbit-prospector-export.csv',
      );
      csvRef.current?.click();
    } catch (e) {
      /**
       * @todo figure out why this isn't working, not seeing the toast
       */
      addErrorToast({
        heading: 'Error downloading export',
        description: 'Please try again',
      });
    }

    setIsPolling(false);
    send('DOWNLOAD_COMPLETE');
  }

  const ExportTypeDisplay = () => (
    <span
      className={classnames('text-xs text-gray-600', {
        uppercase: isCSV,
        capitalize: !isCSV,
      })}
    >
      {exportItem.exportType}
    </span>
  );

  const DownloadButtons = () => {
    return showDownload ? (
      <ButtonContainer className="self-start">
        {showDiscardButton ? (
          <CKButton
            isDisabled={isPolling}
            onClick={handleCancel}
            variant="simple"
            variantColor="red"
          >
            Discard
          </CKButton>
        ) : null}
        {!isOverLimit ? (
          <a className="sr-only" ref={csvRef}>
            CSV download trigger
          </a>
        ) : null}
        <CKButton
          isDisabled={loading}
          isLoading={isPolling}
          onClick={
            isOverLimit ? () => setIsOverageModalOpen(true) : handleDownload
          }
          variant="bold"
          variantColor="blue"
        >
          Download CSV
        </CKButton>
        <CSVOverageModal
          isVisible={isOverageModalOpen}
          onCancel={() => setIsOverageModalOpen(false)}
          selectedProspectsCount={totalCreditsUsed || 0}
        />
      </ButtonContainer>
    ) : null;
  };

  return (
    <CKLayoutGrid
      className="relative w-full min-w-0 px-8 py-6 text-sm grow shrink"
      gridTemplateColumns="auto minmax(14rem, 1fr) minmax(6rem, 1fr) minmax(6rem, 1fr) 16rem"
    >
      <StatusIcon status={exportItem.status} />
      <div>
        <h3 className={labelClasses}>
          {exportItem.prospectList?.name || EMPTY_LABEL}
        </h3>
        <ExportTypeDisplay />
        <div>{timeStamp}</div>
      </div>
      <div className={itemDividerClasses}>
        <Count
          as="div"
          className={labelClasses}
          count={totalCount || 0}
          label="prospect"
          labelPlural="prospects"
        />
        <div>
          {isProcessing ? (
            EMPTY_LABEL
          ) : (
            <Count count={totalCreated || 0} label="new" />
          )}
        </div>
      </div>
      <div>
        {showCredits ? (
          <div className={itemDividerClasses}>
            <h3 className={labelClasses}>
              Credits {isPurchased || isFailed ? 'used' : 'needed'}
            </h3>
            <div>
              {isProcessing ? (
                EMPTY_LABEL
              ) : (
                <Count count={totalCreditsUsed || 0} />
              )}
            </div>
          </div>
        ) : null}
      </div>
      <CKLayoutHorizontal
        className="basis-auto shrink-0 min-w-[8rem]"
        justifyContent="end"
      >
        <DownloadButtons />
        {isProcessing ? (
          <CKButton onClick={handleCancel} variant="simple" variantColor="red">
            Cancel
          </CKButton>
        ) : null}
      </CKLayoutHorizontal>
    </CKLayoutGrid>
  );
};
