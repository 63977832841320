import { paramaterize } from './helpers';

const DEFAULT_OPTIONS = {
  width: 920,
  height: 700,
  resizable: 'no',
  scrollbars: 'no',
  menubar: 'no',
  toolbar: 'no',
  location: 'no',
  status: 'no',
  title: 'Clearbit',
};

function toWindowOptions(options) {
  var key, optionsArr, value;
  optionsArr = [];
  for (key in options) {
    value = options[key];
    optionsArr.push([key, value].join('='));
  }
  return optionsArr.join(',');
}

export function open(url, options = {}) {
  var params, title;
  options = Object.assign({}, DEFAULT_OPTIONS, options);
  options.left = window.screen.width / 2 - options.width / 2;
  options.top = window.screen.height / 2 - options.height / 2;
  if (title === options.title) {
    delete options.title;
  }
  if (params === options.params) {
    delete options.params;
    url += '?' + paramaterize(params);
  }
  return window.open(url, options.title, toWindowOptions(options));
}
