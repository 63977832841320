import { useQuery } from '@apollo/client';
import {
  CheckmarkOrb,
  Clock,
  CloseOrb,
  Filter,
  Warning,
} from '@clearkit/icons';
import { CKBox } from 'clearkit';
import capitalize from 'lodash/capitalize';
import moment from 'moment';
import { Link } from 'react-router-dom';

import loader from '~/images/loader.png';
import {
  filters,
  filterToStatuses,
  readableFilter,
  readableType,
  statuses,
  types as enrichmentTypes,
} from '~/lib/dataEnrichment';
import paths from '~/lib/paths';

import Error from './Error';
import NoSearchResults from './NoSearchResults.svg';
import resultsQuery from './resultsQuery.graphql';
import startQuery from './startQuery.graphql';
import statusQuery from './statusQuery.graphql';
import styles from './styles';

function EnrichmentLogsResult({
  className,
  classes,
  enrichmentLogs,
  enrichmentLogsType,
}) {
  const TIMESTAMP_FORMAT = 'MMMM Do YYYY, h:mm:ss a';

  const enrichmentLogReadableType = readableType(enrichmentLogsType);

  return (
    <CKBox className={classnames('overflow-hidden', className)}>
      <ol className="divide-y divide-gray-200">
        {enrichmentLogs.map((enrichmentLog) => {
          const formattedTimestamp = moment(enrichmentLog.timestamp).format(
            TIMESTAMP_FORMAT,
          );
          const showTypeInfo = enrichmentLog.externalId != 'unknown';

          return (
            <li key={enrichmentLog.id}>
              <Link
                className="flex items-center p-4 hover:bg-gray-50"
                to={paths.dataEnrichmentLog(
                  enrichmentLog.type,
                  enrichmentLog.id,
                )}
              >
                <EnrichmentLogIcon
                  classes={classes}
                  enrichmentLog={enrichmentLog}
                />

                <span className="ml-3 text-base font-medium text-gray-800">
                  {enrichmentLog.identifier}
                </span>

                <span className="mx-3 text-sm text-gray-500">
                  {capitalize(enrichmentLog.status)}

                  {showTypeInfo ? (
                    <span className="text-gray-400">
                      {' / '}
                      {enrichmentLogReadableType} ID: {enrichmentLog.externalId}
                    </span>
                  ) : null}
                </span>

                <time
                  className="ml-auto text-sm font-normal text-gray-400"
                  dateTime={enrichmentLog.timestamp}
                >
                  {formattedTimestamp}
                </time>
              </Link>
            </li>
          );
        })}
      </ol>
    </CKBox>
  );
}

function EnrichmentLogsResultEmpty({ className, searchQuery }) {
  return (
    <div className={classnames('flex flex-col items-center', className)}>
      <NoSearchResults className="mb-2.5" />

      <p className="mb-2 text-gray-700 text-md">
        There are no records that match{' '}
        {searchQuery ? (
          <strong className="font-semibold">“{searchQuery}”</strong>
        ) : null}
      </p>
    </div>
  );
}

function EnrichmentLogIcon({ classes, enrichmentLog }) {
  switch (enrichmentLog.status) {
    case statuses.info:
      return <Warning className="text-yellow-400" />;
    case statuses.complete:
      return <CheckmarkOrb className="text-green-400" />;
    case statuses.error:
      return <CloseOrb className="text-red-400" />;
    case statuses.pending:
      return <Clock className="text-gray-400" />;
    default:
      return <div className={classes.iconPlaceholder} />;
  }
}

function EnrichmentLogsList({
  classes,
  searchQuery,
  enrichmentLogsType,
  setError,
  setShowPlaceholder,
}) {
  // Intervals in ms
  const POLLING_DELAY = 5000;
  const POLLING_INTERVAL = 2500;
  const POLLING_TIMEOUT = 60000;
  const SUCCEEDED = 'SUCCEEDED';

  const [enrichmentLogs, setEnrichmentLogs] = useState([]);
  const [searchFilter, _setSearchFilter] = useState(filters.all);
  const [queryExecutionId, setQueryExecutionId] = useState(undefined);
  const [queryExecutionStatus, setQueryExecutionStatus] = useState(undefined);
  const [isListError, setIsListError] = useState(false);
  const [loading, setLoading] = useState(!searchQuery);
  const [isPolling, setIsPolling] = useState(false);
  const [isPollingError, setIsPollingError] = useState(undefined);

  let pollingTimerId = undefined;
  const startResult = useQuery(startQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      query: searchQuery,
      status: filterToStatuses(searchFilter),
      type: enrichmentLogsType,
    },
    skip: !searchQuery,
  });

  const statusResult = useQuery(statusQuery, {
    fetchPolicy: 'no-cache',
    skip: !isPolling,
    notifyOnNetworkStatusChange: true,
    variables: {
      queryExecutionId: queryExecutionId,
    },
  });

  const logsResult = useQuery(resultsQuery, {
    skip: queryExecutionStatus !== SUCCEEDED,
    variables: {
      queryExecutionId: queryExecutionId,
    },
    onCompleted: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (
      enrichmentLogs.length === 0 &&
      searchQuery === undefined &&
      !isListError &&
      !loading
    ) {
      setShowPlaceholder(true);
    } else {
      setShowPlaceholder(false);
    }
  }, [enrichmentLogs, searchQuery, isListError, loading]);

  useEffect(() => {
    setIsListError(
      startResult.isError ||
        statusResult.isError ||
        logsResult.isError ||
        isPollingError,
    );
  }, [
    startResult.isError,
    statusResult.isError,
    logsResult.isError,
    isPollingError,
  ]);

  useEffect(() => {
    setError(isListError);
  }, [isListError]);

  useEffect(() => {
    if (startResult.data) {
      setQueryExecutionId(startResult.data.enrichmentLogs.queryExecutionId);
    }
  }, [startResult.data]);

  useEffect(() => {
    if (startResult.data) {
      setQueryExecutionId(startResult.data.enrichmentLogs.queryExecutionId);
    }
  }, [startResult.data]);

  useEffect(() => {
    if (statusResult.data) {
      setQueryExecutionStatus(statusResult.data.enrichmentLogsStatus.state);
    }
  }, [statusResult.data]);

  useEffect(() => {
    if (isPolling) {
      pollingTimerId = setTimeout(() => {
        setIsPolling(false);
        setIsPollingError(true);
      }, POLLING_TIMEOUT);

      return () => {
        clearTimeout(pollingTimerId);
      };
    } else {
      statusResult.stopPolling();
    }
  }, [isPolling]);

  useEffect(() => {
    if (queryExecutionStatus === SUCCEEDED) {
      setIsPolling(false);
      statusResult.stopPolling();
    }

    if (queryExecutionStatus === SUCCEEDED && pollingTimerId) {
      clearTimeout(pollingTimerId);
    }

    if (queryExecutionStatus === undefined) {
      setIsPolling(false);
      statusResult.stopPolling();
      clearTimeout(pollingTimerId);
    }
  }, [queryExecutionStatus]);

  useEffect(() => {
    if (queryExecutionId && queryExecutionStatus === undefined) {
      const delayTimerId = setTimeout(() => {
        setIsPolling(true);
      }, POLLING_DELAY);

      return () => {
        clearTimeout(delayTimerId);
      };
    }

    if (queryExecutionId == undefined) {
      setLoading(true);
    }
  }, [queryExecutionId]);

  useEffect(() => {
    if (isPolling && queryExecutionId && queryExecutionStatus === undefined) {
      statusResult.startPolling(POLLING_INTERVAL);
    }
  }, [isPolling]);

  useEffect(() => {
    if (logsResult.data) {
      setEnrichmentLogs(logsResult.data.enrichmentLogsResult);
    }
  }, [logsResult]);

  useEffect(() => {
    if (enrichmentLogs && enrichmentLogs.length > 0) {
      setLoading(false);
    }
  }, [enrichmentLogs]);

  return isListError ? (
    <Error
      className="mx-auto mt-16"
      loading={loading}
      refetch={startResult.refetch}
    />
  ) : (
    <>
      {searchQuery && (
        <h1 className="flex items-center mt-5 text-base font-medium text-gray-600">
          <Filter className="ml-4 mr-2 text-current" />
          Displaying most recent {readableFilter(searchFilter)} matching
          <strong className="flex items-center ml-1 font-semibold">
            “{searchQuery}”
          </strong>
        </h1>
      )}
      <EnrichmentLogsListBody
        classes={classes}
        enrichmentLogs={enrichmentLogs}
        enrichmentLogsType={enrichmentLogsType}
        loader={loader}
        loading={loading}
        searchQuery={searchQuery}
      />
    </>
  );
}

function EnrichmentLogsListBody({
  loading,
  searchQuery,
  classes,
  loader,
  enrichmentLogs,
  enrichmentLogsType,
}) {
  if (!searchQuery) {
    return (
      <EnrichmentLogsNoSearchQuery enrichmentLogsType={enrichmentLogsType} />
    );
  }
  if (loading && searchQuery) {
    return <img alt="" className={classes.loader} src={loader} />;
  } else if (enrichmentLogs.length > 0) {
    return (
      <EnrichmentLogsResult
        classes={classes}
        className="mt-5"
        enrichmentLogs={enrichmentLogs}
        enrichmentLogsType={enrichmentLogsType}
      />
    );
  } else {
    return (
      <EnrichmentLogsResultEmpty className="mt-40" searchQuery={searchQuery} />
    );
  }
}

function EnrichmentLogsNoSearchQuery({ enrichmentLogsType }) {
  return (
    <div className="flex flex-col items-center mt-10">
      <NoSearchResults className="mb-2.5" />
      <p>
        <strong className="mb-2 font-semibold text-gray-700">
          What do I do with this?
        </strong>
      </p>
      <p className="mb-2 text-gray-700 text-md">
        See all enrichment events for a specific{' '}
        {readableType(enrichmentLogsType)} record by searching for that{' '}
        {readableType(enrichmentLogsType)} ID, email address, or domain
      </p>
    </div>
  );
}
EnrichmentLogsList.propTypes = {
  classes: PropTypes.object,
  enrichmentLogsType: PropTypes.oneOf(Object.values(enrichmentTypes))
    .isRequired,
  searchQuery: PropTypes.string,
  setError: PropTypes.func,
  setShowPlaceholder: PropTypes.func,
};

EnrichmentLogsList.defaultProps = {
  setError: () => {},
  setShowPlaceholder: () => {},
};

export default withStyles(styles)(EnrichmentLogsList);
