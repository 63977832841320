import { CKCheckboxNext } from 'clearkit';

export type TermsOfServiceCheckProps = {
  tosAccepted: boolean;
  setTosAccepted: (tosAccepted: boolean) => void;
};

export default function TermsOfServiceCheck({
  tosAccepted,
  setTosAccepted,
}: TermsOfServiceCheckProps) {
  return (
    <CKCheckboxNext checked={tosAccepted} onChange={setTosAccepted}>
      <p>
        I have read and accepted the{' '}
        <a href="https://clearbit.com/legal" target="blank">
          Clearbit Terms of Service
        </a>{' '}
        and{' '}
        <a href="https://clearbit.com/privacy-policy" target="blank">
          Privacy Policy
        </a>
        .
      </p>
    </CKCheckboxNext>
  );
}
