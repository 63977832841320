import { HubspotForm } from './HubspotForm';

export function HubspotExport() {
  return (
    <div className="space-y-8">
      <section>
        <h2 className="mb-1 font-medium">Configure contacts</h2>
        <p className="mb-4 text-sm text-gray-600">
          Choose the values to use for contact data fields when creating these
          contacts in Hubspot.
        </p>
        <HubspotForm />
      </section>
    </div>
  );
}
