import Preview from './Preview.svg';

export default function EnrichmentLogsError({ className }) {
  return (
    <div className={classnames('flex flex-col items-center', className)}>
      <Preview className="mb-4" />

      <h1 className="mb-1 font-semibold text-gray-700 text-md">
        Unable to load matching logs
      </h1>

      <p className="mb-5 text-gray-600">Refresh the page to solve this.</p>
    </div>
  );
}
