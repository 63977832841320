import InfiniteLoader from '~/components/GraphInfiniteLoader';

import ListEventsItem from './EventsListItem';

const EventsList = ({
  events,
  hasNextPage,
  onLoadMore,
  loading,
  loadingMore,
  className,
}) => {
  const list = useRef();
  const [expandedRows, setExpandedRows] = useState({});

  const toggleListItem = (index) => {
    setExpandedRows({
      ...expandedRows,
      [index]: !expandedRows[index],
    });

    list.current.resetAfterIndex(index, true);
  };

  const rowExpanded = (index) => !!expandedRows[index];

  const getRowHeight = (index) => (rowExpanded(index) ? 340 : 64);

  return (
    <div className={classnames('flex flex-col flex-grow', className)}>
      <InfiniteLoader
        dynamicHeight
        hasNextPage={hasNextPage}
        listRef={list}
        loading={loading}
        loadingMore={loadingMore}
        nodes={events}
        onLoadMore={onLoadMore}
        rowHeight={(index) => getRowHeight(index)}
        rowRenderer={(event, index, style) => (
          <div key={event.id} style={style}>
            <ListEventsItem
              event={event}
              expand={() => toggleListItem(index)}
              expanded={rowExpanded(index)}
              index={index}
            />
          </div>
        )}
      />
    </div>
  );
};

EventsList.defaultProps = {
  events: {
    pageInfo: {
      hasNextPage: false,
    },
  },
};

EventsList.propTypes = {
  className: PropTypes.string,
  events: PropTypes.array.isRequired,
  eventTypes: PropTypes.array,
  hasNextPage: PropTypes.bool,
  id: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingMore: PropTypes.bool,
  onFilter: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default EventsList;
