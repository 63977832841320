import classnames from 'classnames';
import { CKContainerProps } from 'clearkit';

export const Description = ({
  children,
  className,
  ...rest
}: CKContainerProps<HTMLParagraphElement>) => (
  <p {...rest} className={classnames('text-gray-600 text-base', className)}>
    {children}
  </p>
);
