/* eslint-disable react-hooks/rules-of-hooks */
import classnames from 'classnames';
import {
  CKContainerProps,
  CKHTMLElement,
  CKHTMLElementProps,
  CKLayoutGrid,
} from 'clearkit';
import { createContext, CSSProperties, FC, useContext } from 'react';

type GridListProps = CKHTMLElementProps & {
  gridGap?: string;
  gridTemplateColumns?: string;
};

type GridListItemProps = CKContainerProps & {
  hasDivider?: boolean;
};

type GridListComposition = {
  Header: FC<CKContainerProps>;
  Item: FC<GridListItemProps>;
};

type GridListContextValues = {
  gridTemplateColumns?: string;
};

const GridListContext = createContext<GridListContextValues>({});

export const GridList: FC<GridListProps> & GridListComposition = ({
  children,
  gridTemplateColumns,
  gridGap = '1rem',
  ...rest
}) => (
  <GridListContext.Provider value={{ gridTemplateColumns }}>
    <CKHTMLElement
      {...rest}
      style={
        {
          '--layout-grid-template-columns': gridTemplateColumns,
          '--layout-grid-template-rows': 'initial',
          '--layout-gap': gridGap,
        } as CSSProperties
      }
    >
      {children}
    </CKHTMLElement>
  </GridListContext.Provider>
);

GridList.Header = ({ className, ...rest }) => {
  const { gridTemplateColumns } = useContext(GridListContext);

  return (
    <CKLayoutGrid
      {...rest}
      as="header"
      className={classnames('font-medium min-w-0', className)}
      gridTemplateColumns={gridTemplateColumns}
    />
  );
};
GridList.Header.displayName = 'GridList.Header';

GridList.Item = ({ className, hasDivider = true, ...rest }) => {
  const { gridTemplateColumns } = useContext(GridListContext);

  return (
    <CKLayoutGrid
      {...rest}
      className={classnames('min-w-0', className, {
        'border-b last:border-none pb-4': hasDivider,
      })}
      gridTemplateColumns={gridTemplateColumns}
    />
  );
};
GridList.Item.displayName = 'GridList.Item';
