function Check({ fill, height, width }) {
  return (
    <svg
      height={height}
      version="1.1"
      viewBox="0 0 12 12"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={fill} transform="translate(-311.000000, -124.000000)">
          <g>
            <g id="Group-34" transform="translate(40.000000, 102.000000)">
              <g id="Group-29" transform="translate(228.000000, 0.000000)">
                <g id="Group-28" transform="translate(42.000000, 0.000000)">
                  <path d="M7,34 C3.6862915,34 1,31.3137085 1,28 C1,24.6862915 3.6862915,22 7,22 C10.3137085,22 13,24.6862915 13,28 C13,31.3137085 10.3137085,34 7,34 Z M6.99454287,28.3579644 C6.92094912,28.4625622 6.82797415,28.552072 6.7206578,28.6216417 C6.25723164,28.9220658 5.63800866,28.7899265 5.33758456,28.3265003 L4.8919768,27.6391177 C4.84945911,27.5735311 4.79607794,27.5156677 4.73412453,27.4680112 C4.43289728,27.2362979 4.00086363,27.2926502 3.76915036,27.5938774 L3.64475205,27.7555952 C3.3682507,28.115047 3.3682507,28.615565 3.64475205,28.9750167 L5.35015275,31.1920376 C5.40294747,31.2606708 5.46443284,31.3221562 5.53306598,31.3749509 C5.97082012,31.7116848 6.59866677,31.6297918 6.93540073,31.1920376 L10.4210212,26.660731 C10.6975226,26.3012793 10.6975226,25.8007612 10.4210212,25.4413095 L10.2966229,25.2795917 C10.2525838,25.2223409 10.2001379,25.1720812 10.1410649,25.1305182 C9.81755941,24.9029036 9.3707883,24.9806386 9.14317373,25.3041441 L6.99454287,28.3579644 Z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

Check.defaultProps = {
  width: 12,
  height: 12,
  fill: '#9BADBC',
};

Check.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default Check;
