import { useQuery } from '@apollo/client';

import query from './useEventTypesQuery.graphql';

export function useEventTypes() {
  const { data = {}, loading, error } = useQuery(query, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  return {
    error,
    loading,
    eventTypes: data?.eventTypes || [],
  };
}
