import apollo from '~/lib/apollo';
import { trackEvent } from '~/lib/tracking';

import CreateTargetMarketQuery from '../queries/CreateTargetMarket.graphql';
import DestroyTargetMarketQuery from '../queries/DestroyTargetMarket.graphql';
import UpdateTargetMarketQuery from '../queries/UpdateTargetMarket.graphql';
import { Search } from './useTargetMarket';

export interface TargetMarket {
  id: string;
  name: string;
  search: Search;
}

export const createTargetMarket = async ({
  name,
  search,
}: Omit<TargetMarket, 'id'>): Promise<TargetMarket | undefined | false> => {
  const { data, errors } = await apollo.mutate({
    mutation: CreateTargetMarketQuery,
    variables: {
      input: { name, search },
    },
  });

  if (errors?.length) {
    trackEvent('target_market_creation_errored', { name, search, errors });

    // eslint-disable-next-line no-console
    console.error(errors);

    return false;
  }

  trackEvent('target_market_created', { name, search });

  return data?.createTargetMarket?.node;
};

export const updateTargetMarket = async ({
  id,
  search,
  name,
}: TargetMarket): Promise<TargetMarket | undefined | false> => {
  const { data, errors } = await apollo.mutate({
    mutation: UpdateTargetMarketQuery,
    variables: { input: { id, search, name } },
  });

  if (errors?.length) {
    trackEvent('target_market_update_errored', { id, errors });

    // eslint-disable-next-line no-console
    console.error(errors);

    return false;
  }

  trackEvent('target_market_updated', { id, search });

  return data?.updateTargetMarket?.node;
};

export const destroyTargetMarket = async (id: string): Promise<boolean> => {
  const { errors } = await apollo.mutate({
    mutation: DestroyTargetMarketQuery,
    variables: { input: { id } },
  });

  if (errors?.length) {
    trackEvent('target_market_deletion_errored', { id, errors });

    // eslint-disable-next-line no-console
    console.error(errors);

    return false;
  }

  trackEvent('target_market_deleted', { id });

  return true;
};
