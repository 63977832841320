import request from './request';

// Connectors

export function testFormMarketoConnection(id) {
  return request({
    path: `/v1/forms/${id}/connectors/marketo/test`,
    type: 'post',
  });
}

export function testFormHubspotConnection(id) {
  return request({
    path: `/v1/forms/${id}/connectors/hubspot/test`,
    type: 'post',
  });
}

export function getConnectors() {
  return request({
    path: `/v1/connectors`,
  });
}
