import { ClearbitServices, HubspotServices } from '@clearkit/icons';
import { CKIconBox, CKLayoutHorizontal } from 'clearkit';

export const ClearbitHubSpotConnection = () => {
  const iconClasses = 'w-6 h-6';

  return (
    <CKLayoutHorizontal justifyContent="center">
      <CKIconBox>
        <ClearbitServices className={iconClasses} />
      </CKIconBox>
      <div className="w-12 h-1 text-gray-400">
        <svg
          fill="currentColor"
          viewBox="0 0 32 2"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            d="M1 2a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm6 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm7-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm5 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm7-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm5 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
          />
        </svg>
      </div>
      <CKIconBox>
        <HubspotServices className={iconClasses} />
      </CKIconBox>
    </CKLayoutHorizontal>
  );
};
