import { useMutation } from '@apollo/client/react/hooks';
import { CKButton, CKModal } from 'clearkit';
import capitalize from 'lodash/capitalize';

import { readableObjectTypeName } from '~/lib/dataEnrichment';

import toggle from '../toggle.graphql';

const RemoveMappingModal = ({
  openDisableModal,
  setOpenDisableModal,
  integration,
  objectType,
  accountConnectorBackfillActive,
  isUpdating,
  disableFieldMappings,
  accountConnector,
  isActive,
  onToggleChange,
}) => {
  const [toggleFieldMapping, { loading: isToggling }] = useMutation(toggle, {
    onCompleted: () => {
      if (isActive) {
        onToggleChange(false);
      }
    },
    refetchQueries: ['FieldMappingForm'],
    variables: {
      input: {
        id: accountConnector?.id,
        targetObjectType: capitalize(objectType.name),
        enabled: !isActive,
      },
      targetObjectType: capitalize(objectType.name),
    },
  });

  async function handleToggle() {
    await toggleFieldMapping();
  }

  return (
    <CKModal
      aria-label="Modal"
      isVisible={openDisableModal}
      onToggle={() => setOpenDisableModal(false)}
      size="xs"
    >
      <CKModal.Header className="pb-2">
        <CKModal.Heading>
          {accountConnectorBackfillActive ? (
            'A backfill is currently running'
          ) : (
            <p>
              Field mappings are required for enriched data sync to work with{' '}
              {capitalize(integration)}{' '}
              {readableObjectTypeName(objectType.name)}.
            </p>
          )}
        </CKModal.Heading>
      </CKModal.Header>
      <CKModal.Body className="px-10 pt-2 pb-10">
        {accountConnectorBackfillActive ? (
          <div className="flex flex-col gap-4">
            <p>
              Any changes you make to field mappings will not be reflected in
              the current backfill and you’ll need to run another to have{' '}
              {readableObjectTypeName(objectType.name)} enriched with the field
              mappings you’ve added.
            </p>
            <p>
              {capitalize(objectType.name)}s and fields already enriched by
              Clearbit will retain that data.
            </p>
          </div>
        ) : (
          <p>
            {capitalize(integration)} {readableObjectTypeName(objectType.name)}{' '}
            previously enriched by Clearbit will retain that data.
          </p>
        )}
      </CKModal.Body>
      <CKModal.Footer className="gap-2">
        <CKButton
          isLoading={isUpdating}
          onClick={() => setOpenDisableModal(false)}
        >
          Dismiss
        </CKButton>
        <CKButton
          isLoading={isUpdating || isToggling}
          onClick={() => {
            if (isActive) {
              handleToggle();
            }
            disableFieldMappings();
          }}
          variant="bold"
          variantColor="red"
        >
          Remove mapping and disable data sync
        </CKButton>
      </CKModal.Footer>
    </CKModal>
  );
};

export default RemoveMappingModal;
