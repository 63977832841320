import startCase from 'lodash/startCase';

import { readableSingularSegmentType, segmentTypes } from '~/lib/segments';

export function destinationNameForType(destinationType) {
  switch (destinationType) {
    case destinationTypeSalesforce:
      return 'Salesforce tasks';
    case destinationTypeCustomHtml:
      return 'Custom HTML';
    case destinationTypeEmail:
      return 'Email digest';
    case destinationTypeFacebookConversion:
      return 'Facebook Conversions API';
    case destinationTypeGoogleConversion:
      return 'Google Offline Conversions Tracking';
    case destinationTypeSalesforceCreateAccount:
      return 'Salesforce accounts';
    case destinationTypeSalesforceCreateContact:
      return 'Salesforce contacts';
    case destinationTypeSalesforceCreateLead:
      return 'Salesforce leads';
    default:
      return startCase(destinationType);
  }
}

export function shortDescriptionForType(destinationType) {
  switch (destinationType) {
    case destinationTypeEmail:
      return 'Send matches via scheduled emails';
    case destinationTypeSalesforce:
      return 'Post to Chatter or create new Tasks';
    case destinationTypeSlack:
      return 'Send messages to Slack channels';
    case destinationTypeWebhook:
      return 'Post new matches to webhook URLs';
    case destinationTypeCustomHtml:
      return 'Trigger HTML/JS on audience match';
    case destinationTypeFacebookConversion:
      return 'Send new matches as a Facebook conversion event';
    case destinationTypeGoogleConversion:
      return 'Send new matches as a Google Adwords conversion event';
    default:
      return startCase(destinationType);
  }
}

export function destinationSubtitleForType(
  destinationType,
  typename,
  includeLink = true,
) {
  const segmentType = readableSingularSegmentType(typename);

  switch (destinationType) {
    case destinationTypeWebhook:
      return (
        <span>
          Post to a URL of your choice when a {segmentType} matches the
          audience.{' '}
          {includeLink && (
            <a
              className="ck-link"
              href="https://help.clearbit.com/hc/en-us/articles/360022031574-Webhook-Destination"
              rel="noopener noreferrer"
              target="_blank"
            >
              Learn more
            </a>
          )}
        </span>
      );
    case destinationTypeEmail:
      return (
        <span>
          Send a scheduled email with a CSV digest of{' '}
          {segmentType === 'person' ? 'people' : 'companies'} matching the
          audience.{' '}
          {includeLink && (
            <a
              className="ck-link"
              href="https://help.clearbit.com/hc/en-us/articles/360022232093-Email-Destination"
              rel="noopener noreferrer"
              target="_blank"
            >
              Learn more
            </a>
          )}
        </span>
      );
    case destinationTypeSlack:
      return (
        <span>
          Post to Slack when a {segmentType} matches the audience.{' '}
          {includeLink && (
            <a
              className="ck-link"
              href="https://help.clearbit.com/hc/en-us/articles/360022030174-Slack-Destination"
              rel="noopener noreferrer"
              target="_blank"
            >
              Learn more
            </a>
          )}
        </span>
      );
    case destinationTypeSalesforce:
      return (
        <span>
          Create a Salesforce Task or post to Chatter when a {segmentType}{' '}
          matches the audience.{' '}
          {includeLink && (
            <a
              className="ck-link"
              href="https://help.clearbit.com/hc/en-us/articles/360022031134-Salesforce-Destination"
              rel="noopener noreferrer"
              target="_blank"
            >
              Learn more
            </a>
          )}
        </span>
      );

    case destinationTypeCustomHtml:
      return (
        <span>
          Execute custom HTML/JS on your website when a visitor matches an
          audience.{' '}
          {includeLink && (
            <a
              className="ck-link"
              href="https://help.clearbit.com/hc/en-us/articles/360036261373-Custom-HTML-Destination-beta-"
              rel="noopener noreferrer"
              target="_blank"
            >
              Learn more
            </a>
          )}
        </span>
      );

    case destinationTypeFacebookConversion:
      return (
        <span>
          Send an event to Facebook when a {segmentType} matches the audience.{' '}
          {includeLink && (
            <a
              className="ck-link"
              href="https://help.clearbit.com/hc/en-us/articles/1500003200162"
              rel="noopener noreferrer"
              target="_blank"
            >
              Learn more
            </a>
          )}
        </span>
      );

    case destinationTypeGoogleConversion:
      return (
        <span>
          Send an event to Google Ads when a {segmentType} matches the audience.{' '}
          {includeLink && (
            <a
              className="ck-link"
              href="https://help.clearbit.com/hc/en-us/articles/1500003045801"
              rel="noopener noreferrer"
              target="_blank"
            >
              Learn more
            </a>
          )}
        </span>
      );
    case destinationTypeSalesforceCreateAccount:
      return (
        <span>
          Create a Salesforce Account when a company enters the audience that’s
          not in your CRM.{' '}
          {includeLink && (
            <a
              className="ck-link"
              href="https://help.clearbit.com/hc/en-us/articles/8719323175319-Set-Up-Auto-Creation-of-New-Account-Records-in-Salesforce"
              rel="noopener noreferrer"
              target="_blank"
            >
              Learn more
            </a>
          )}
        </span>
      );
    case destinationTypeSalesforceCreateContact:
      return (
        <span>
          Create a Salesforce Contacts automatically for specific personas when
          a company enters this audience.{' '}
          {includeLink && (
            <a
              className="ck-link"
              href="https://help.clearbit.com/hc/en-us/articles/8719321819927-Set-Up-Auto-Creation-of-New-Contact-Records-in-Salesforce"
              rel="noopener noreferrer"
              target="_blank"
            >
              Learn more
            </a>
          )}
        </span>
      );
    case destinationTypeSalesforceCreateLead:
      return (
        <span>
          Create Salesforce Leads automatically for specific personas when a
          company enters this audience.{' '}
          {includeLink && (
            <a
              className="ck-link"
              href="https://help.clearbit.com/hc/en-us/articles/8719266018711-Set-Up-Auto-Creation-of-New-Lead-Records-in-Salesforce"
              rel="noopener noreferrer"
              target="_blank"
            >
              Learn more
            </a>
          )}
        </span>
      );
    default:
  }
}

export function alertDestinationTypes() {
  return [
    destinationTypeEmail,
    destinationTypeSlack,
    destinationTypeWebhook,
    destinationTypeSalesforce,
  ];
}

export function adsDestinationTypes(segmentType) {
  if (segmentType === segmentTypes.person) {
    return [destinationTypeFacebookConversion, destinationTypeGoogleConversion];
  } else {
    return [];
  }
}

export function createAccountDestinationTypes(destinations) {
  if (!destinations) {
    return [];
  }

  return [destinationTypeSalesforceCreateAccount];
}

export function personalizationDestinationTypes() {
  return [destinationTypeCustomHtml];
}

export function alwaysAvailableOnPlatformTypes() {
  return [
    destinationTypeEmail,
    destinationTypeSlack,
    destinationTypeSalesforce,
  ];
}

export function operationsDestinationTypes() {
  return [destinationTypeWebhook];
}

export function conversionsDestinationTypes(segmentType) {
  return adsDestinationTypes(segmentType);
}

export function clearbitCaptureTypes(segmentType) {
  if (segmentType === segmentTypes.company) {
    return [
      destinationTypeSalesforceCreateAccount,
      destinationTypeSalesforceCreateContact,
      destinationTypeSalesforceCreateLead,
    ];
  }

  return [];
}

export const destinationTypeCustomHtml = 'custom_html';
export const destinationTypeEmail = 'email';
export const destinationTypeSlack = 'slack';
export const destinationTypeWebhook = 'webhook';
export const destinationTypeFacebookConversion = 'facebook_conversion';
export const destinationTypeGoogleConversion = 'google_conversion';
export const destinationTypeSalesforce = 'salesforce';
export const destinationTypeSalesforceCreateAccount =
  'create_external_records_salesforce_account';
export const destinationTypeSalesforceCreateContact =
  'create_external_records_salesforce_contact';
export const destinationTypeSalesforceCreateLead =
  'create_external_records_salesforce_lead';

export const destinationTypes = [
  destinationTypeCustomHtml,
  destinationTypeEmail,
  destinationTypeFacebookConversion,
  destinationTypeGoogleConversion,
  destinationTypeSalesforce,
  destinationTypeSalesforceCreateAccount,
  destinationTypeSalesforceCreateContact,
  destinationTypeSalesforceCreateLead,
  destinationTypeSlack,
  destinationTypeWebhook,
];
