import { CKButton, CKModal } from 'clearkit';

import { ButtonContainer } from '~/components/unified/ButtonContainer';
import { EMPTY_LABEL } from '~/lib/constants';
import { formatCostPerMonth } from '~/lib/selfServePlanHelpers';

import { BillingPendingPlanChange } from './BillingPendingChange';
import { PendingPlanChange } from './types';
import { useCurrentPlan } from './useCurrentPlan';
import { formatDate } from './utils';

type BillingChangeOptInModalProps = {
  handleCancel: () => void;
  handleConfirm: () => void;
  isLoading?: boolean;
  isOpen: boolean;
  pendingPlanChange: PendingPlanChange;
};

export function BillingChangeOptInModal({
  handleCancel,
  handleConfirm,
  isLoading,
  isOpen,
  pendingPlanChange,
}: BillingChangeOptInModalProps) {
  const { accountName, currentPlan } = useCurrentPlan();
  const effectiveDate =
    pendingPlanChange.effectiveDate || currentPlan?.renewDate;

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleConfirm();
    } else if (event.key === 'Escape') {
      handleCancel();
    } else {
      return;
    }
  };

  const AccountName = () => (
    <span>
      Your account — <strong>{accountName}</strong> —
    </span>
  );

  return (
    <CKModal hasHideOnEscape isVisible={isOpen} onKeyUp={handleKeyUp} size="md">
      <CKModal.Header>
        <CKModal.Heading>Confirm plan change</CKModal.Heading>
      </CKModal.Header>
      <CKModal.Body className="p-8 space-y-4">
        {pendingPlanChange.isDowngrading ? (
          <p className="text-base">
            <AccountName /> will be on the{' '}
            {currentPlan?.credits?.toLocaleString('en-us')} credits per month
            tier ({formatCostPerMonth(currentPlan?.costPerMonth || 0)}) until
            the end of your annual commitment, on{' '}
            {currentPlan?.renewDate
              ? formatDate(effectiveDate || currentPlan?.renewDate)
              : EMPTY_LABEL}
            .
          </p>
        ) : (
          <p className="text-base">
            <AccountName /> will be upgraded to{' '}
            {pendingPlanChange.credits?.toLocaleString('en-us')} credits per
            more tier immediately.
          </p>
        )}
        <BillingPendingPlanChange
          pendingPlanChange={pendingPlanChange}
          previousCostsPerMonth={currentPlan?.costPerMonth}
          previousCredits={currentPlan?.credits}
        />
      </CKModal.Body>
      <CKModal.Footer>
        <ButtonContainer>
          <CKButton onClick={handleCancel}>Dismiss change</CKButton>
          <CKButton
            isLoading={isLoading}
            onClick={handleConfirm}
            variant="bold"
            variantColor="green"
          >
            Confirm change
          </CKButton>
        </ButtonContainer>
      </CKModal.Footer>
    </CKModal>
  );
}
