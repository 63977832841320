import { CKLayoutGrid } from 'clearkit';
import { Helmet } from 'react-helmet';

import { HomeBannerConnectCRM } from '~/components/home/Banners';
import { HomeFooter } from '~/components/home/HomeFooter';
import { HomeHeader } from '~/components/home/HomeHeader';
import {
  HomeSectionCompanies,
  HomeSectionDataOverview,
  HomeSectionNotifications,
  HomeSectionOnboarding,
} from '~/components/home/HomeSection';
import {
  HomeSidebar,
  HomeSidebarIntegrations,
  HomeSidebarNews,
  HomeSidebarUsage,
} from '~/components/home/HomeSidebar';
import { useHomeIntegrations } from '~/components/home/hooks/useHomeIntegrations';
import {
  Account,
  AccountNotification,
  useHomePageQuery,
  User,
} from '~/generated/graphql';
import { useEntitlements } from '~/hooks/account';
import { WhenUsagePreview } from '~/hooks/account/Entitlements/WhenUsagePreview';

export const HomePage = () => {
  const { hasAccessEnrichment, hasAccessEnrichmentHubspot } = useEntitlements();
  const {
    hasCRMConnector,
    isOnboarding,
    loadingAccount,
  } = useHomeIntegrations();

  const { data, loading } = useHomePageQuery();

  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <div className="w-full h-full @container/page ck-scrollbar ck-scrollbar--vertical">
        <div className="h-full min-w-0 min-h-0 bg-white @4xl/page:grid grid-cols-[1fr_25rem]">
          <div className="min-h-full ck-scrollbar ck-scrollbar--vertical">
            <CKLayoutGrid
              className="min-w-0 min-h-full p-8 space-y-12"
              gridTemplateRows="1fr auto"
            >
              <div className="min-h-0 space-y-12">
                <HomeHeader
                  account={data?.account as Account}
                  hasAccountIssue={!!data?.accountNotifications.length}
                  isOnboarding={isOnboarding}
                  user={data?.viewer.user as User}
                />
                <HomeSectionNotifications
                  accountConnectors={
                    data?.accountConnectors as Account['connectors']
                  }
                  notifications={
                    data?.accountNotifications as AccountNotification[]
                  }
                />
                {hasAccessEnrichmentHubspot ? <HomeSectionCompanies /> : null}

                {!hasAccessEnrichmentHubspot && !loadingAccount ? (
                  <div className="relative space-y-12">
                    {!hasCRMConnector && hasAccessEnrichment ? (
                      <HomeBannerConnectCRM />
                    ) : null}
                    <HomeSectionDataOverview />
                  </div>
                ) : null}

                <HomeSectionOnboarding />
              </div>
              <HomeFooter />
            </CKLayoutGrid>
          </div>
          <HomeSidebar>
            {!loading ? (
              <>
                <WhenUsagePreview>
                  <HomeSidebarUsage />
                </WhenUsagePreview>
                <HomeSidebarIntegrations />
                <HomeSidebarNews news={data?.news} />
              </>
            ) : null}
          </HomeSidebar>
        </div>
      </div>
    </>
  );
};
