import { useMutation, useQuery } from '@apollo/client';
import { Build } from '@clearkit/icons';
import { CKBox, CKButton, CKCardCollapsible, CKModal } from 'clearkit';
import _ from 'lodash';
import capitalize from 'lodash/capitalize';
import isEqual from 'lodash/isEqual';
import { useMemo } from 'react';

import SearchBuilder, { validateSearch } from '~/components/SearchBuilder';
import {
  HUBSPOT_SUB_CATEGORIES,
  MARKETO_SUB_CATEGORIES,
  SALESFORCE_SUB_CATEGORIES,
} from '~/components/SearchBuilder/utils/constants';
import ToastsContainer from '~/containers/Toasts';
import { readableObjectTypeName, readableType } from '~/lib/dataEnrichment';
import {
  getAttributeType,
  getEmptyStateSearch,
  getEmptyStateSubObject,
} from '~/lib/selectiveEnrichment';

import EmptyState from '../FieldMappingForm/EmptyState.svg';
import disable from './disable.graphql';
import EmptyStateHubspot from './EmptyStateSVGs/EmptyStateHubspot.svg';
import EmptyStateSalesforce from './EmptyStateSVGs/EmptyStateSalesforce.svg';
import mutation from './mutation.graphql';
import query from './query.graphql';
import { ATTRIBUTE_TYPES_TO_REMOVE, isEmptySearch } from './utils';

const SelectiveEnrichmentConfig = ({
  accountConnector,
  integration,
  objectType,
}) => {
  const attributeType = getAttributeType(integration, objectType);
  const salesforceSubCategories = SALESFORCE_SUB_CATEGORIES[attributeType];
  const hubspotSubCategories = HUBSPOT_SUB_CATEGORIES[attributeType];
  const marketoSubCategories = MARKETO_SUB_CATEGORIES[attributeType];
  // Pull attributes + salesforce users to populate trait selector dropdown
  // accountConnector prop contains the saved selective enrichment filters
  const {
    data: { searchAttributes: attributes = [], salesforceUsers = [] } = {},
  } = useQuery(query, {
    returnPartialData: true,
    variables: {
      type: attributeType,
      integrationCategory: integration,
      collectionType: attributeType,
      salesforceSubCategories,
      hubspotSubCategories,
      marketoSubCategories,
    },
  });
  const savedSelectiveEnrichmentConfig =
    accountConnector?.selectiveEnrichmentFilters?.filters;
  const [filters, setFilters] = useState(savedSelectiveEnrichmentConfig);
  const [showInvalidFields, setShowInvalidFields] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const filtersAreValid = validateSearch(filters).length === 0;
  const input = useMemo(
    () => ({
      id: accountConnector?.id,
      externalObjectType: capitalize(objectType.name),
      filters,
    }),
    [accountConnector, filters, objectType],
  );

  const filteredAttributes = useMemo(
    () =>
      attributes?.filter(
        (attr) => !ATTRIBUTE_TYPES_TO_REMOVE.includes(attr?.details?.type),
      ),
    [objectType.name, attributes, filters],
  );

  const updateFilters = (fields) => {
    if (isEmptySearch(fields.search)) {
      setConfirmationModalOpen(true);
    } else {
      setFilters(fields.search);
    }
  };

  useEffect(() => {
    if (savedSelectiveEnrichmentConfig) {
      setFilters(savedSelectiveEnrichmentConfig);
    }
  }, [objectType.name, savedSelectiveEnrichmentConfig]);

  const [
    updateSelectiveEnrichmentConfig,
    { loading: isUpdating },
  ] = useMutation(mutation, {
    onCompleted: (data) => {
      if (data.errors?.length) {
        ToastsContainer.addWarning(
          'An error occurred when saving your selective enrichment criteria, please try again.',
        );
      } else {
        ToastsContainer.addSuccess('Selective enrichment saved');
      }
    },
    onError: () => {
      ToastsContainer.addWarning(
        'An error occurred when saving your selective enrichment criteria, please try again.',
      );
    },
    refetchQueries: ['FieldMappingForm'],
    variables: {
      input,
    },
  });

  const [
    deleteSelectiveEnrichmentConfig,
    { loading: isDeleting },
  ] = useMutation(disable, {
    onCompleted: () => {
      ToastsContainer.addSuccess('Selective enrichment removed');
      setFilters(null);
    },
    onError: () => {
      ToastsContainer.addWarning(
        'An error occurred when removing your selective enrichment criteria, please try again.',
      );
    },
    refetchQueries: ['FieldMappingForm'],
    variables: {
      input,
    },
  });

  const disableSelectiveEnrichment = () => {
    delete input.filters;
    deleteSelectiveEnrichmentConfig().then(() =>
      setConfirmationModalOpen(false),
    );
  };

  const areButtonsDisabled = useMemo(() => {
    if (savedSelectiveEnrichmentConfig) {
      return isEqual(filters, savedSelectiveEnrichmentConfig);
    }
    return isEqual(filters, undefined);
  }, [filters, savedSelectiveEnrichmentConfig]);

  return (
    <div className="px-4">
      <CKCardCollapsible className="relative z-10">
        <CKCardCollapsible.Header>
          <SelectiveEnrichmentHeader
            integration={integration}
            objectType={objectType}
          />
        </CKCardCollapsible.Header>
        <CKCardCollapsible.Body>
          <SelectiveEnrichmentBody
            attributes={filteredAttributes}
            attributeType={attributeType}
            filters={filters}
            integration={integration}
            objectType={objectType}
            salesforceUsers={salesforceUsers}
            setFilters={setFilters}
            showInvalidFields={showInvalidFields}
            updateFilters={updateFilters}
          />
          <CKCardCollapsible.Footer>
            <div className="flex items-center justify-end space-x-3">
              <CKButton
                isDisabled={areButtonsDisabled || isUpdating}
                onClick={() => setFilters(savedSelectiveEnrichmentConfig)}
                variant="simple"
                variantColor="default"
              >
                Cancel
              </CKButton>
              <SaveSelectiveEnrichmentConfigButton
                areButtonsDisabled={areButtonsDisabled}
                filters={filters}
                filtersAreValid={filtersAreValid}
                isLoading={isUpdating}
                setShowInvalidFields={setShowInvalidFields}
                updateSelectiveEnrichmentConfig={
                  updateSelectiveEnrichmentConfig
                }
              />
            </div>
          </CKCardCollapsible.Footer>
        </CKCardCollapsible.Body>
      </CKCardCollapsible>
      <DisableSelectiveEnrichmentModal
        confirmationModalOpen={confirmationModalOpen}
        deleteSelectiveEnrichmentConfig={disableSelectiveEnrichment}
        integration={integration}
        isLoading={isDeleting}
        objectType={objectType}
        setConfirmationModalOpen={setConfirmationModalOpen}
      />
    </div>
  );
};

function SaveSelectiveEnrichmentConfigButton({
  updateSelectiveEnrichmentConfig,
  filtersAreValid,
  setShowInvalidFields,
  isLoading,
  areButtonsDisabled,
}) {
  return (
    <div
      onMouseEnter={() => setShowInvalidFields(true)}
      onMouseLeave={() => setShowInvalidFields(false)}
    >
      <CKButton
        data-testid="saveSelectiveEnrichmentConfig"
        isDisabled={!filtersAreValid || isLoading || areButtonsDisabled}
        isLoading={isLoading}
        onClick={updateSelectiveEnrichmentConfig}
        variant="bold"
        variantColor="green"
      >
        Save
      </CKButton>
    </div>
  );
}

function SelectiveEnrichmentHeader({ integration, objectType }) {
  return (
    <>
      <div
        className="grid grid-cols-1fr-auto gap-4"
        data-testid="selectiveEnrichmentConfigCardHeader"
      >
        <div className="text-base">
          <h3 className="mb-2 font-medium">Selective Enrichment </h3>
          <p>
            Use selective enrichment to create an enrichment filter that will
            limit the sync of enriched data to your CRM to{' '}
            <span className="font-medium">
              {capitalize(integration)}{' '}
              {readableObjectTypeName(objectType.name)} that match criteria
              defined by you.
            </span>
          </p>
        </div>
      </div>
    </>
  );
}

function DisableSelectiveEnrichmentModal({
  deleteSelectiveEnrichmentConfig,
  isLoading,
  objectType,
  integration,
  confirmationModalOpen,
  setConfirmationModalOpen,
}) {
  return (
    <CKModal
      aria-label="Modal"
      isVisible={confirmationModalOpen}
      onToggle={() => setConfirmationModalOpen(false)}
      size="md"
    >
      <CKModal.Header className="pb-2">
        <CKModal.Heading>
          Are you sure you want to remove the selective enrichment conditions
          for {readableObjectTypeName(objectType.name)}?
        </CKModal.Heading>
      </CKModal.Header>
      <CKModal.Body className="px-10 pt-6 pb-8">
        <p>
          If no selective enrichment conditions are present, selective
          enrichment will be disabled, and Clearbit will enrich and sync data
          for <strong className="font-semibold">all</strong>{' '}
          {capitalize(integration)} {readableObjectTypeName(objectType.name)}.{' '}
          {capitalize(integration)} {readableObjectTypeName(objectType.name)}{' '}
          previously enriched by Clearbit will retain that data.
        </p>
      </CKModal.Body>
      <CKModal.Footer className="gap-2">
        <CKButton onClick={() => setConfirmationModalOpen(false)}>
          Dismiss
        </CKButton>
        <CKButton
          isLoading={isLoading}
          onClick={() => deleteSelectiveEnrichmentConfig(false)}
          variant="bold"
          variantColor="red"
        >
          Yes, remove and disable selective enrichment
        </CKButton>
      </CKModal.Footer>
    </CKModal>
  );
}

function SelectiveEnrichmentBody({
  integration,
  objectType,
  setFilters,
  attributeType,
  attributes,
  salesforceUsers,
  filters,
  updateFilters,
  showInvalidFields,
}) {
  return !filters ? (
    <EmptyStateSelectiveEnrichment
      integration={integration}
      objectType={objectType}
      setFilters={setFilters}
    />
  ) : (
    <SearchBuilder
      attributes={attributes}
      emptyGroup={getEmptyStateSubObject(integration, objectType)}
      isSelectiveEnrichmentConfig
      onChange={(search) => {
        updateFilters({ search });
      }}
      salesforceUsers={salesforceUsers}
      search={filters}
      showInvalidFields={showInvalidFields}
      type={attributeType}
    />
  );
}

function EmptyStateIcon({ integration }) {
  switch (integration) {
    case 'salesforce':
      return <EmptyStateSalesforce />;
    case 'hubspot':
      return <EmptyStateHubspot />;
    default:
      return <EmptyState />;
  }
}

function EmptyStateSelectiveEnrichment({
  integration,
  objectType,
  setFilters,
}) {
  return (
    <CKBox
      className="flex flex-col items-center p-10 m-10"
      variant={CKBox.Variant['tinted-frame']}
    >
      <EmptyStateIcon integration={integration} />
      <p className="text-base font-medium text-center text-gray-800 mt-7">
        Get selective around sync
      </p>
      <p className="max-w-md mt-2 text-sm text-center text-gray-600">
        Before you can use selective enrichment for {readableType(integration)}{' '}
        {readableObjectTypeName(objectType.name)}, you’ll first need to define
        the criteria around which {readableObjectTypeName(objectType.name)} we
        should sync.
      </p>
      <div className="mt-6">
        <CKButton
          className="mr-4"
          leftIcon={<Build />}
          onClick={() => {
            setFilters(getEmptyStateSearch(integration, objectType));
          }}
          variant="bold"
          variantColor="blue"
        >
          Set up selective enrichment for {readableType(integration)}{' '}
          {readableObjectTypeName(objectType.name)}
        </CKButton>
      </div>
    </CKBox>
  );
}

export default SelectiveEnrichmentConfig;
