import { CKBox, CKSelectNext, CKTextField } from 'clearkit';

import { errorMessage, hasError } from '~/lib/graphql';

import SalesforceField from './SalesforceField';

const TraitConfiguration = ({
  salesforceTraits,
  salesforceResourceType,
  errors,
  traits,
  mappedTraitLabels,
  setTraits,
  unsettableValues,
  companyAttributes = [],
  personAttributes = [],
  nameTraits = [],
  companyTraits = [],
}) => {
  const mappedOptionLabels = {
    'Person Employment Name': 'Company Name',
    'Person Employment Domain': 'Company Domain',
  };
  const updateAndMapTraits = (trait, { selectedItem }) => {
    let traitConfig = {};
    const { name } = trait;
    const { value, label } = selectedItem;
    const newTraits = traits.filter((oldTrait) => oldTrait.name !== name);

    traitConfig =
      trait.type === 'SELECT'
        ? { name, label, value }
        : { name, label, field: value };

    setTraits([...newTraits, traitConfig]);
  };

  const filterTraitsOptions = (options, traitsToFilter) => {
    return options.filter((option) => traitsToFilter.includes(option.label));
  };

  const traitSelected = (selectedTraits, activeTrait) => {
    const selectedTrait = selectedTraits.find(
      (trait) => trait.name === activeTrait.name,
    );

    return (
      !!selectedTrait && {
        value: selectedTrait.value || selectedTrait.field,
        label: selectedTrait.label,
      }
    );
  };

  const traitLabel = (trait) => {
    return (
      mappedOptionLabels[trait.label] ||
      mappedTraitLabels[trait.name] ||
      trait.label ||
      trait.name
    );
  };

  const enrichmentAttributes = [
    ...new Set([...personAttributes, ...companyAttributes]),
  ];

  const traitItems = (trait) => {
    switch (trait.name) {
      case 'Name':
      case 'LastName':
      case 'Last Name':
      case 'FirstName':
      case 'First Name':
        return filterTraitsOptions(enrichmentAttributes, nameTraits);
      case 'Company':
        return filterTraitsOptions(enrichmentAttributes, companyTraits);
      default:
        return enrichmentAttributes;
    }
  };

  const requiredTraits = salesforceTraits.filter((trait) => trait.required);
  const optionalTraits = salesforceTraits.filter((trait) => !trait.required);
  const renderTraits = (filteredTraits, key_prefix) => {
    return filteredTraits.map((trait, idx) => (
      <SalesforceField key={`${key_prefix}-${idx}`} label={traitLabel(trait)}>
        <CKSelectNext
          items={trait.type === 'SELECT' ? trait.options : traitItems(trait)}
          maxHeight="15rem"
          onChange={(value) =>
            updateAndMapTraits(trait, { selectedItem: { ...value } })
          }
          placeholder="Choose an option"
          value={traitSelected(traits, trait)}
        />
        {hasError(errors, `trait_${trait.name}`, false) && (
          <div className="mt-2 text-red">
            {errorMessage(errors, `trait_${trait.name}`, false)}
          </div>
        )}
      </SalesforceField>
    ));
  };

  return (
    <CKBox className="p-6 mt-3">
      <h3 className="mb-2">Required fields</h3>
      {renderTraits(requiredTraits, 'required')}
      {unsettableValues?.length &&
        unsettableValues.map(({ trait, value }, index) => (
          <SalesforceField key={`unsettable-${index}`} label={trait}>
            <CKTextField
              className="w-full"
              name={`unsettable-${index}`}
              readOnly
              value={value}
            />
          </SalesforceField>
        ))}
      {!!optionalTraits.length && (
        <>
          <h3 className="pt-6 mt-6 mb-4 border-t">
            Optional {salesforceResourceType.label} fields
          </h3>
          {renderTraits(optionalTraits, 'optional')}
        </>
      )}
    </CKBox>
  );
};

TraitConfiguration.propTypes = {
  salesforceTraits: PropTypes.array,
  salesforceResourceType: PropTypes.object,
  errors: PropTypes.array,
  nameTraits: PropTypes.array,
  companyTraits: PropTypes.array,
  traits: PropTypes.array,
  mappedTraitLabels: PropTypes.object,
  setTraits: PropTypes.func,
  unsettableValues: PropTypes.array,
  companyAttributes: PropTypes.array,
  personAttributes: PropTypes.array,
};

export default TraitConfiguration;
