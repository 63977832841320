import classnames from 'classnames';
import { CKLayoutHorizontal, CKLayoutHorizontalProps } from 'clearkit';

type ListItemProps = CKLayoutHorizontalProps;

export const ListItem = ({
  alignItems = 'start',
  children,
  className,
  wrap = 'nowrap',
  ...rest
}: ListItemProps) => {
  return (
    <CKLayoutHorizontal
      {...rest}
      alignItems={alignItems}
      className={classnames('px-4 py-3 ck-box @container', className)}
      wrap={wrap}
    >
      {children}
    </CKLayoutHorizontal>
  );
};
