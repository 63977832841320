import {
  ConnectHubspot,
  ConnectHubspotProps,
} from '~/components/Onboarding/ConnectHubspot';

import { OnboardingCard } from '../HubspotEnrichment/Onboarding/OnboardingCard';

export const OnboardingFormsCard = ({
  onSuccess,
  ...rest
}: ConnectHubspotProps) => {
  return (
    <OnboardingCard {...rest}>
      <ConnectHubspot onSuccess={onSuccess}>
        <header className="text-center space-y-4">
          <h1 className="font-semibold">Before you get started...</h1>
          <div className="m-auto text-sm text-gray-500 max-w-[35ch]">
            Clearbit will need a connection to HubSpot to be able to fill in any
            form fields.
          </div>
        </header>
      </ConnectHubspot>
    </OnboardingCard>
  );
};
