export default function segmentsForCollection(segments, collection, search) {
  if (!segments) return [];
  segments = segments.filter(
    (segment) => segment.collectionId === collection.id,
  );
  if (search) {
    segments = segments.filter(
      (segment) =>
        segment.name.toLowerCase().indexOf(search.toLowerCase()) > -1,
    );
  }
  return segments;
}
