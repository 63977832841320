import { FC } from 'react';
import { Redirect } from 'react-router';

import AuthLayout from '~/components/layouts/AuthLayout';

import { Spinner } from '../Spinner';
import ListOrganizations from './ListOrganizations';
import useDiscovery from './useDiscovery';

export type StytchTokenType = 'discovery' | 'discover_oauth';

const AuthDiscovery: FC = function() {
  const { data, isLoading } = useDiscovery();

  if (isLoading || !data) {
    return (
      <AuthLayout>
        <Spinner />
      </AuthLayout>
    );
  }

  switch (data.action) {
    case 'unauthorized':
      return <Redirect to={`/auth/login?error=${data.type}`} />;
    case 'discover':
      return (
        <AuthLayout>
          {data.action === 'discover' ? (
            <ListOrganizations accounts={data.discovered_accounts} />
          ) : null}
        </AuthLayout>
      );
  }
};

export default AuthDiscovery;
