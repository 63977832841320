import { Container } from 'unstated';

import { getConnectors } from '~/lib/client';

export default class ConnectorsContainer extends Container {
  state = {
    connectors: {},
    notification: null,
    loading: false,
  };

  constructor() {
    super();

    this.fetchConnectors();
  }

  fetchConnectors = () => {
    this.setState({ loading: true });

    return getConnectors().then(this.setConnectors);
  };

  setConnectors = (connectorArray) => {
    let connectors = {};

    connectorArray.map(
      (connector) => (connectors[connector.service] = connector),
    );

    this.setState({
      connectors,
      loading: false,
    });
  };

  activeSalesforceConnector = () =>
    this.state.connectors.salesforce || this.state.connectors.salesforcesandbox;

  activeHubspotConnector = () => this.state.connectors.hubspot;
}
