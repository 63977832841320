const styles = () => ({
  salesforceDisabledMessage: {
    textAlign: 'center',
    padding: '10px 30px',
    fontSize: 13,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& svg': {
      marginBottom: 10,
    },
  },

  salesforceDisabledMessageHeader: {
    fontWeight: '600',
    margin: 0,
    fontSize: 14,
  },
});

export default styles;
