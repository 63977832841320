import { Info } from '@clearkit/icons';
import { CKBannerNext, CKBannerVariant, CKButton, CKIconStack } from 'clearkit';
import { useState } from 'react';

import Logo from '~/components/ConfigurationLogo';
import SidebarDrawer from '~/components/SidebarDrawer';
import {
  AccountNotificationFragment,
  useResolveAccountNotificationMutation,
} from '~/generated/graphql';
import { Configuration, purposeForConnectorService } from '~/lib/configuration';
import { readableType } from '~/lib/dataEnrichment';
import history from '~/lib/history';
import paths, { SegmentRootPathKey } from '~/lib/paths';

import UpgradeAccountForm from '../UpgradeAccountForm';
import {
  buildAccountNotification,
  isDismissableNotification,
} from './accountNotificationsUtils';
export const USAGE_UPGRADE = 'usage_upgrade';

export type AccountNotificationProps = {
  accountNotification: AccountNotificationFragment;
  className?: string;
  fixIssue?: () => void;
  icon?: React.ReactNode;
  isFromEnrichmentPage?: boolean;
  isFullWidth?: boolean;
  showResolveButton?: boolean;
  variant?: CKBannerVariant;
};

export const AccountNotification: React.FC<AccountNotificationProps> = ({
  accountNotification,
  className,
  icon,
  isFullWidth,
  showResolveButton = true,
  fixIssue = () => history.push(paths.integrations()),
  isFromEnrichmentPage,
  variant = 'warning',
}) => {
  const [showUpgradeForm, setShowUpgradeForm] = useState(false);
  const [resolveNotificationLoading, setResolveNotificationLoading] = useState(
    false,
  );
  const [notificationResolved, setNotificationResolved] = useState(false);

  const [resolveNotification] = useResolveAccountNotificationMutation({
    variables: {
      input: {
        id: accountNotification.id,
      },
    },
    onCompleted: () => {
      setNotificationResolved(true);
      setResolveNotificationLoading(false);
    },
  });
  const {
    sourceName,
    notificationType,
    message,
    segmentType,
    segmentId,
    sourceId,
  } = accountNotification;

  let resolve = fixIssue;

  const {
    documentationLink,
    helpText,
    isAccountConnectorNotification,
    isDestinationNotification,
    isEnrichmentNotification,
    isUsageNotification,
    messageDescription,
    messageTitle,
    shouldRenderNotification,
  } = buildAccountNotification(
    message,
    notificationType,
    readableType(sourceName),
  );

  if (isDestinationNotification) {
    resolve = () =>
      history.push(
        paths.segmentDestinationEdit(
          segmentType as SegmentRootPathKey,
          segmentId,
          sourceId,
        ),
      );
  } else if (isUsageNotification) {
    const selfServePath = paths.selfServePlanUpgrade();
    resolve = () => window.open(selfServePath);
  } else if (documentationLink) {
    resolve = () => window.open(documentationLink);
  }

  if (!shouldRenderNotification(!!isFromEnrichmentPage)) {
    return null;
  }

  const action =
    notificationType === 'account_connector_auth' ? 'Reconnect' : 'Resolve';

  const hasNoMessage =
    ((!isAccountConnectorNotification && !isDestinationNotification) ||
      !sourceName) &&
    !isEnrichmentNotification &&
    !isUsageNotification;

  let messageText;

  if (isEnrichmentNotification) {
    messageText = `Please verify that the Clearbit integration user has permissions
      to read/write fields in ${readableType(sourceName)}.`;
  } else if (isUsageNotification) {
    messageText = messageDescription;
  } else {
    messageText = `${action} to continue ${purposeForConnectorService(
      sourceName as Configuration,
    )}`;
  }

  const hasMessagePlusTitle =
    (!!messageDescription && !!messageTitle) ||
    (!!messageDescription && !!messageText);

  if (notificationResolved) {
    return null;
  }
  return (
    <>
      <CKBannerNext
        className={className}
        data-testid="account-notification-container"
        isFullWidth={isFullWidth}
        variant={variant}
      >
        {(isAccountConnectorNotification || isDestinationNotification) &&
        icon ? (
          <CKBannerNext.Icon>
            <CKIconStack
              badge={<Info className="fill-gradient-br-yellow" />}
              base={<Logo type={sourceName as Configuration} />}
            />
          </CKBannerNext.Icon>
        ) : (
          <CKBannerNext.IconStatus>{icon || <Info />}</CKBannerNext.IconStatus>
        )}
        {messageTitle || hasMessagePlusTitle ? (
          <CKBannerNext.Header>
            {messageTitle || messageDescription}
          </CKBannerNext.Header>
        ) : null}
        <CKBannerNext.Body>
          {!hasNoMessage ? <span>{messageText}</span> : null}
          {!hasMessagePlusTitle ? (
            <span>
              {messageDescription ||
                `There was an error ${purposeForConnectorService(
                  sourceName as Configuration,
                )}`}
            </span>
          ) : null}
        </CKBannerNext.Body>

        {showResolveButton && !isEnrichmentNotification ? (
          <CKBannerNext.CTA>
            {isDismissableNotification(notificationType) ? (
              <CKButton
                isLoading={resolveNotificationLoading}
                onClick={() => {
                  setResolveNotificationLoading(true);
                  resolveNotification();
                }}
                size="small"
                variant="simple"
              >
                Dismiss
              </CKButton>
            ) : null}

            <CKButton
              data-testid="fix-issue-button"
              onClick={resolve}
              variant="bold"
              variantColor="yellow"
            >
              {helpText}
            </CKButton>
          </CKBannerNext.CTA>
        ) : null}
      </CKBannerNext>

      <SidebarDrawer
        isVisible={showUpgradeForm}
        onToggle={() => {
          setShowUpgradeForm(!showUpgradeForm);
        }}
      >
        <UpgradeAccountForm
          onClose={() => {
            setShowUpgradeForm(false);
          }}
        />
      </SidebarDrawer>
    </>
  );
};
