import { ConfigExternal } from '@clearkit/icons';
import { CKButton, CKLayoutGrid } from 'clearkit';

const OVERWRITE_DOCS_URL = `https://help.clearbit.com/hc/en-us/articles/4420681386135-Set-Up-Clearbit-Enrichment-Field-Mapping-for-Salesforce#:~:text=IF%3A%20the%20Data%20Overwrite%20toggle,or%20set%20to%20'overwrite'`;

const FieldMappingHeaders = () => {
  return (
    <CKLayoutGrid
      className="items-center mb-3 text-base font-medium text-gray-600"
      gridTemplateColumns="1fr 1fr 1fr 3rem"
    >
      <div>Source</div>
      <div className="pl-4">Target</div>
      <div className="flex items-center pl-8 gap-1">
        <CKButton
          as="a"
          href={OVERWRITE_DOCS_URL}
          target="_blank"
          variant="tertiary"
          variantColor="blue"
        >
          Overwrite preference
          <ConfigExternal className="inline-block ml-1" height={16} />
        </CKButton>
      </div>
    </CKLayoutGrid>
  );
};

export default FieldMappingHeaders;
