const shimmer = {
  borderRadius: 5,
  animationDuration: '2s',
  animationFillMode: 'forwards',
  animationIterationCount: 'infinite',
  animationName: 'placeHolderShimmer',
  animationTimingFunction: 'linear',
  background: 'linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%)',
  backgroundSize: '800px 104px',
  position: 'relative',
};

export default () => ({
  destination: {
    background: '#fff',
    borderRadius: 6,
    display: 'flex',
    height: 70,
    overflow: 'hidden',

    '&:first-child': {
      marginTop: 0,
    },
  },

  '@keyframes placeHolderShimmer': {
    '0%': {
      backgroundPosition: '-468px 0',
    },
    '100%': {
      backgroundPosition: '468px 0',
    },
  },

  disabledDestination: {
    background: '#FAFBFC',
    '& $destinationLogo': {
      opacity: 0.5,
    },
    '& $destinationDescription': {
      opacity: 0.5,
    },
  },

  destinationSwitchWrapper: {
    padding: '1px 2px',
    borderRight: '1px solid #e1e9f0',
    height: 71,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  destinationDetailsWrapper: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '9px 20px',
    minWidth: 0,
  },

  destinationSwitch: {
    margin: 0,
  },

  destinationLogo: {
    width: 30,
    height: 30,
    flex: '0 0 auto',
    margin: '0 18px 0 0',
  },

  destinationNameWrapper: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    flexShrink: 1,
    minWidth: 20,
    marginRight: 20,
  },

  destinationName: {
    color: '#27282a',
    fontSize: 16,
    fontWeight: 500,
    flexShrink: 0,
  },

  destinationDescription: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: '#596168',
    fontSize: 14,
    maxWidth: '100%',
    margin: '2px 10px 0 0',
    '& strong': {
      fontWeight: 600,
    },
  },

  destinationButton: {
    flex: '0 0 auto',
    margin: '0 0 0 auto',
    maxHeight: 32,
    height: 32,
    minHeight: 32,
    fontSize: 14,
  },

  destinationLoading: {
    justifyContent: 'center',
    alignItems: 'center',
    '& $destinationLogo': {
      ...shimmer,
      '& > *': {
        opacity: 0,
      },
    },
    '& $destinationName': {
      ...shimmer,
      width: '30%',
      height: 18,
      margin: '2px 0',
      animationDelay: '0.25s',
      color: 'transparent',
    },
    '& $destinationDescription': {
      ...shimmer,
      animationDelay: '0.25s',
      width: '50%',
      height: 18,
      margin: '2px 0 0',
      color: 'transparent',
    },
    '& $destinationButton': {
      position: 'relative',
      '&::after': {
        ...shimmer,
        animationDelay: '0.1s',
        borderRadius: 'inherit',
        display: 'block',
        position: 'absolute',
        content: '""',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      },
    },
    '& $destinationSwitch': {
      opacity: 0.3,
    },
  },

  separator: {
    borderTop: '1px solid #e7e8e9',
    border: 0,
    height: 1,
    width: '100%',
    marginTop: 0,
  },

  footer: {
    display: 'flex',
    alignItems: 'center',
    margin: '8px 16px',
  },

  footerText: {
    fontSize: 14,
    marginLeft: 9,
    position: 'relative',
    top: 1,
  },
});
