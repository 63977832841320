import { useHomeIntegrations } from '~/components/home/hooks/useHomeIntegrations';
import { Prospect } from '~/generated/graphql';

import { useProspectCrmPresence } from '../useProspector/useProspectorCRMPresence';
import { ListItem } from './ListItem';
import { LoadingList } from './LoadingList';

type ListProps = {
  isLoading?: boolean;
  onSelectItem: (id: string) => void;
  onDeselectItem: (id: string) => void;
  prospects: Prospect[];
  selectedProspects: string[];
};

export const List = ({
  isLoading,
  onSelectItem,
  onDeselectItem,
  prospects,
  selectedProspects,
}: ListProps) => {
  const prospectIds = prospects.map((prospect: any) => prospect.id);
  const { isInHubSpot, isLoadingCrmPresence } = useProspectCrmPresence(
    prospectIds,
  );
  const { hubspotConnector } = useHomeIntegrations();
  const isHubSpotConnected = hubspotConnector;

  return (
    <div className="space-y-4">
      {isLoading ? (
        <LoadingList />
      ) : (
        prospects.map((prospect) => {
          const isSelected = selectedProspects.includes(prospect.id);

          const handleSelect = () => {
            if (isSelected) {
              onDeselectItem(prospect.id);
            } else {
              onSelectItem(prospect.id);
            }
          };
          return (
            <ListItem
              isHubSpotConnected={!!isHubSpotConnected}
              isInHubSpot={isInHubSpot(prospect.id)}
              isLoadingCrmPresence={isLoadingCrmPresence}
              isSelected={isSelected}
              key={prospect.id}
              onSelect={handleSelect}
              prospect={prospect}
            />
          );
        })
      )}
    </div>
  );
};
