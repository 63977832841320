import { Enrichment } from '@clearkit/icons';
import { CKLayoutGridAuto } from 'clearkit';
import moment from 'moment';

import { useDataOverviewQuery } from '~/generated/graphql';
import { useEntitlements } from '~/hooks/account';

import { HomeDataOverviewCard } from '../HomeDataOverviewCard';
import { HomeHeading } from '../HomeHeading';
import { useCRMLabel } from '../hooks/useCRMLabel';
import { useHomeIntegrations } from '../hooks/useHomeIntegrations';

const TIME_PERIOD = 60;

export const HomeSectionDataOverview = () => {
  const start = moment()
    .subtract(TIME_PERIOD, 'days')
    .format('YYYY-MM-DD');
  const end = moment().format('YYYY-MM-DD');

  const { data, loading: isLoading } = useDataOverviewQuery({
    variables: { start, end },
  });

  const { hasAccessEnrichment } = useEntitlements();
  const { crmLabel } = useCRMLabel();
  const {
    hasCRMConnector,
    isHubSpotConnected,
    isSalesforceConnected,
    isTagConnected,
  } = useHomeIntegrations();

  const shouldShowData = hasCRMConnector;

  const rollupCounts = data?.rollupCounts;
  const visitorsTotalUniques = rollupCounts?.visitorsTotalUniques || 0;
  const enrichmentsByDomainTotalUniques =
    rollupCounts?.enrichmentsByDomainTotalUniques || 0;
  const enrichmentsByEmailTotalUniques =
    rollupCounts?.enrichmentsByEmailTotalUniques || 0;

  const TooltipEnriched = ({ type }: { type: 'person' | 'company' }) => {
    let service;

    if (isHubSpotConnected && isSalesforceConnected) {
      service = 'HubSpot and Salesforce';
    } else if (isHubSpotConnected) {
      service = 'HubSpot';
    } else if (isHubSpotConnected) {
      service = 'Salesforce';
    } else {
      service = crmLabel;
    }

    return (
      <span>
        Any {type} record that’s had new data appended to the record and synced
        to {service} in the past {TIME_PERIOD} days.
      </span>
    );
  };

  return (
    <section className="w-full grid gap-8 xl:gap-12">
      <div className="space-y-6">
        <HomeHeading>
          <Enrichment />
          Data Overview
        </HomeHeading>
        <CKLayoutGridAuto className="min-h-[10rem]">
          <HomeDataOverviewCard
            id="companies"
            isDisabled={!hasAccessEnrichment}
            isLoading={isLoading}
            overviewTotal={enrichmentsByDomainTotalUniques}
            shouldShowData={shouldShowData}
            title={`Companies enriched in ${crmLabel}`}
            variant="purple"
          >
            <HomeDataOverviewCard.Tooltip>
              <TooltipEnriched type="company" />
            </HomeDataOverviewCard.Tooltip>
          </HomeDataOverviewCard>
          <HomeDataOverviewCard
            id="people"
            isDisabled={!hasAccessEnrichment}
            isLoading={isLoading}
            overviewTotal={enrichmentsByEmailTotalUniques}
            shouldShowData={shouldShowData}
            title={`People enriched in ${crmLabel}`}
            variant="green"
          >
            <HomeDataOverviewCard.Tooltip>
              <TooltipEnriched type="person" />
            </HomeDataOverviewCard.Tooltip>
          </HomeDataOverviewCard>
          <HomeDataOverviewCard
            id="visitors"
            isDisabled={!isTagConnected}
            isLoading={isLoading}
            overviewTotal={visitorsTotalUniques}
            shouldShowData={isTagConnected}
            title="Visitors to your website"
            variant="blue"
          >
            <HomeDataOverviewCard.Tooltip>
              Unique visitors visiting your website in the past {TIME_PERIOD}{' '}
              days
            </HomeDataOverviewCard.Tooltip>
          </HomeDataOverviewCard>
        </CKLayoutGridAuto>
      </div>
    </section>
  );
};
