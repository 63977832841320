import InfiniteLoader from '~/components/GraphInfiniteLoader';
import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '~/components/Table';

import Pageview from './Pageview';

class Pageviews extends React.Component {
  render = () => {
    const {
      events,
      hasNextPage,
      onLoadMore,
      loading,
      loadingMore,
      className,
    } = this.props;

    return (
      <Table className={className}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Page</TableCell>
            <TableCell>Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <InfiniteLoader
            emptyBody="Nothing to show"
            hasNextPage={hasNextPage}
            loading={loading}
            loadingMore={loadingMore}
            nodes={events}
            onLoadMore={onLoadMore}
            rowHeight={58}
            rowRenderer={(event, index, style) => (
              <div key={event.id} role="listitem" style={style}>
                <Pageview event={event} key={event.id} />
              </div>
            )}
          />
        </TableBody>
      </Table>
    );
  };
}

Pageviews.defaultProps = {
  events: {
    pageInfo: {
      hasNextPage: false,
    },
  },
};

Pageviews.propTypes = {
  className: PropTypes.string,
  events: PropTypes.array.isRequired,
  hasNextPage: PropTypes.bool,
  id: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingMore: PropTypes.bool.isRequired,
  onLoadMore: PropTypes.func.isRequired,
};

export default Pageviews;
