export const PROSPECTS_PER_PAGE = 20;
export const PROSPECTS_LIMIT = 2500;
export const PROSPECTS_PAGE_LIMIT = 50;
export const MAX_COUNT_HIDE = 1000000;
export const MAX_COUNT_HIDE_LABEL = '1M+';
export const EMAIL_PROVIDER_ERROR_MESSAGE =
  'One of the provided domains is an email provider';
export const DOMAIN_LIMIT_ERROR_MESSAGE =
  'Please limit your search to 100 domains';
export const OVER_QUOTA_ERROR_MESSAGE = 'Insufficient credits';
