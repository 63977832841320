import { FitCompany } from '@clearkit/icons';
import { CKBox, CKButton, CKIconBox, CKLayoutHorizontal } from 'clearkit';
import { Link } from 'react-router-dom';

import { routes } from '~/components/app/appMainRoutes';

export const NoCompaniesEmptyState = () => (
  <CKBox className="p-6 space-y-6">
    <CKLayoutHorizontal alignItems="start" justifyContent="start" wrap="nowrap">
      <CKIconBox className="shrink-0">
        <FitCompany className="w-6 h-6 fill-gradient-br-red" />
      </CKIconBox>
      <div>
        <h3 className="mb-1 text-lg font-medium">No Target Market</h3>
        <p>
          You don't have any target markets set up. Add a target market to see
          companies in your addressable market.
        </p>
      </div>
    </CKLayoutHorizontal>
    <footer>
      <CKButton
        as={Link}
        to={routes.targetMarkets}
        variant="bold"
        variantColor="blue"
      >
        Set up your target market
      </CKButton>
    </footer>
  </CKBox>
);
