import { CKSelect, CKTooltip } from 'clearkit';
import { ReactNode } from 'react';

import { ConditionalOverwriteSelectProps } from './types';
import {
  getConditionalOverwriteOptions,
  isSystemFieldMapping,
  toConditionalOverwriteItem,
} from './util';

export function ConditionalOverwriteSelect({
  enableConditionalOverwrite,
  integration,
  mapping,
  onChange,
}: ConditionalOverwriteSelectProps) {
  const isAlwaysOverwrite =
    integration === 'marketo' && !enableConditionalOverwrite;
  const isDisabled = isSystemFieldMapping(mapping) || isAlwaysOverwrite;
  const value = isDisabled ? false : mapping.conditionalOverwrite;
  const conditionalOverwriteItem = toConditionalOverwriteItem(value);

  return (
    <div className="w-full">
      <WrapWithSystemTooltip
        isMarketo={isAlwaysOverwrite}
        showTooltip={isDisabled}
      >
        <CKSelect
          isDisabled={isDisabled}
          items={getConditionalOverwriteOptions()}
          onSelectedItemChange={({ selectedItem }) => {
            if (selectedItem?.value !== conditionalOverwriteItem.value) {
              onChange({
                conditionalOverwrite: selectedItem?.value === 'true',
              });
            }
          }}
          placeholder=""
          popoverPlacement="bottom-end"
          selectedItem={conditionalOverwriteItem}
        />
      </WrapWithSystemTooltip>
    </div>
  );
}

type WrapWithSystemTooltipProps = {
  isMarketo: boolean;
  showTooltip: boolean;
  children: ReactNode;
};
function WrapWithSystemTooltip({
  isMarketo,
  showTooltip,
  children,
}: WrapWithSystemTooltipProps) {
  if (!showTooltip) {
    return <>{children}</>;
  }

  return (
    <CKTooltip
      hasHover
      style={{
        display: 'block',
        width: '100%',
      }}
      tooltip={
        isMarketo
          ? 'Marketo currently supports "Always overwrite" only. Reach out to support@clearbit.com to learn more.'
          : 'System Traits can only be set to "Always overwrite".'
      }
    >
      {children}
    </CKTooltip>
  );
}
