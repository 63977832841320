import { useAccountUpdateSuppressionSettingsMutation } from '~/generated/graphql';

export function useUpdateSuppressionSettings() {
  const [
    updateSuppressionSettings,
  ] = useAccountUpdateSuppressionSettingsMutation();

  return {
    updateSuppressionSettings,
  };
}
