import { Prospector } from '@clearkit/icons';
import { CKButton, CKIconBox, CKLayoutHorizontal } from 'clearkit';
import { Link } from 'react-router-dom';

import { WhenProspector } from '../policies/WhenProspector';
import { prospectorPath } from '../prospector/util';
import { UpgradeAccountFormButton } from '../UpgradeAccountFormButton';
import { CaptureProspects } from './CaptureProspects';
import { ErrorBoundary } from './ErrorBoundary';
import { useProspects } from './useProspects';

type ProspectorPreviewProps = {
  domain: string;
  name?: string;
};

function ProspectorPreviewInternal({ domain, name }: ProspectorPreviewProps) {
  const { data } = useProspects(domain, {});

  const contactCount = data?.totalCount ?? 0;
  const contactLabel = contactCount === 1 ? 'prospect' : 'prospects';

  return (
    <div className="p-6 pb-0 mb-4 space-y-8">
      <CKLayoutHorizontal>
        <CKLayoutHorizontal alignItems="start" justifyContent="start">
          <CKIconBox>
            <Prospector className="w-5 h-5 fill-gradient-br-blue" />
          </CKIconBox>
          <div>
            <h3 className="font-medium">Prospector</h3>
            <p className="text-sm text-gray-600">
              Clearbit has contact data for {contactCount} {contactLabel} for
              you to discover at {name || 'this company'}.
            </p>
          </div>
        </CKLayoutHorizontal>
        <WhenProspector
          cta={
            <UpgradeAccountFormButton variant="simple" variantColor="blue">
              Get Prospector
            </UpgradeAccountFormButton>
          }
        >
          <CKButton
            as={Link}
            to={prospectorPath({
              domain,
            })}
            variant="simple"
            variantColor="blue"
          >
            Open in Prospector
          </CKButton>
        </WhenProspector>
      </CKLayoutHorizontal>
      <CaptureProspects prospects={data?.nodes} />
    </div>
  );
}

export function ProspectorPreview(props: ProspectorPreviewProps) {
  return (
    <ErrorBoundary>
      <ProspectorPreviewInternal {...props} />
    </ErrorBoundary>
  );
}
