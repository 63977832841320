import { PeopleNav, UsageAltNav } from '@clearkit/icons';
import classnames from 'classnames';
import { CKBox, CKButton, CKLayoutGrid, CKMeter, CKModal } from 'clearkit';
import { Link } from 'react-router-dom';

import { routes } from '~/components/app/appMainRoutes';
import { ButtonContainer } from '~/components/unified/ButtonContainer';
import { useCreditUsage } from '~/hooks/account/useCreditUsage';

import { UsageBlock } from './UsageBlock';
type CSVOverageModal = {
  isVisible: boolean;
  selectedProspectsCount: number;
  onCancel: () => void;
};

export const CSVOverageModal = ({
  isVisible,
  selectedProspectsCount,
  onCancel,
}: CSVOverageModal) => {
  const {
    credits,
    creditsUsed,
    creditsRemaining,
    creditsPerProspect,
    loading,
    hasUsage,
  } = useCreditUsage();

  const creditsNeeded = selectedProspectsCount * creditsPerProspect;

  const iconClasses = 'w-5 h-5 fill-gradient-br-blue';

  return (
    <CKModal
      hasHideOnEscape
      isVisible={isVisible}
      onToggle={onCancel}
      size="md"
    >
      <CKModal.Header>
        <CKModal.Heading>Download CSV</CKModal.Heading>
      </CKModal.Header>
      <CKModal.Body className="p-8 space-y-6">
        <p>
          Each contact exported to CSV by Clearbit Prospector requires{' '}
          {creditsPerProspect} credits. You don't have enough credits available
          to download this CSV.
        </p>
        <CKBox>
          <div className="p-6 space-y-6">
            <CKLayoutGrid gap="1.5rem" gridTemplateColumns="1fr 1fr">
              <UsageBlock>
                <UsageBlock.Icon>
                  <PeopleNav className={iconClasses} />
                </UsageBlock.Icon>
                <UsageBlock.Label>Prospects Selected</UsageBlock.Label>
                <UsageBlock.Count count={selectedProspectsCount} />
              </UsageBlock>
              <UsageBlock>
                <UsageBlock.Icon>
                  <UsageAltNav className={iconClasses} />
                </UsageBlock.Icon>
                <UsageBlock.Label>Credits Per Contact</UsageBlock.Label>
                <UsageBlock.Count count={creditsPerProspect} />
              </UsageBlock>
            </CKLayoutGrid>
            <CKLayoutGrid gap="1.5rem" gridTemplateColumns="1fr 1fr">
              <UsageBlock className="text-yellow-500">
                <UsageBlock.Icon>
                  <UsageAltNav
                    className={classnames(
                      iconClasses,
                      'fill-gradient-br-yellow',
                    )}
                  />
                </UsageBlock.Icon>
                <UsageBlock.Label>Credits Required</UsageBlock.Label>
                <UsageBlock.Count count={creditsNeeded} />
              </UsageBlock>
              {hasUsage ? (
                <UsageBlock className="text-yellow-500">
                  <UsageBlock.Icon>
                    <CKMeter
                      max={credits}
                      value={creditsUsed}
                      variant="warning"
                    />
                  </UsageBlock.Icon>
                  <UsageBlock.Label>Available Credits</UsageBlock.Label>
                  <UsageBlock.Count
                    count={creditsRemaining}
                    isLoading={loading}
                  />
                </UsageBlock>
              ) : null}
            </CKLayoutGrid>
          </div>
        </CKBox>
      </CKModal.Body>
      <CKModal.Footer>
        <ButtonContainer>
          <CKButton onClick={onCancel} variant="simple" variantColor="red">
            Cancel
          </CKButton>
          <CKButton
            as={Link}
            to={routes.plans}
            variant="bold"
            variantColor="green"
          >
            Upgrade Plan & Add Credits
          </CKButton>
        </ButtonContainer>
      </CKModal.Footer>
    </CKModal>
  );
};
