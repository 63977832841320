
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AccountNotification"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AccountNotification"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"message"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"notificationType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"sourceName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"resolved"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"segmentType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"segmentId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"sourceId"},"arguments":[],"directives":[]}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AccountNotifications"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AccountNotification"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resolved"},"arguments":[],"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"AccountNotification"},"directives":[]}]}}],"loc":{"start":0,"end":245}};
    doc.loc.source = {"body":"fragment AccountNotification on AccountNotification {\n  id\n  message\n  notificationType\n  sourceName\n  resolved\n  segmentType\n  segmentId\n  sourceId\n}\n\nfragment AccountNotifications on AccountNotification {\n  resolved\n  ...AccountNotification\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
