import { Error } from '@clearkit/icons';
import { CKBannerNext } from 'clearkit';

import { AuthBannerProps } from './bannerTypes';

export const BannerError = ({ children, isVisible }: AuthBannerProps) => {
  if (!isVisible) return null;

  return (
    <CKBannerNext className="w-full" variant="error">
      <CKBannerNext.IconStatus>
        <Error />
      </CKBannerNext.IconStatus>
      <CKBannerNext.Header>Something went wrong</CKBannerNext.Header>
      <CKBannerNext.Body>{children}</CKBannerNext.Body>
    </CKBannerNext>
  );
};
