export const FINISHED_STATUS = 'created';
export const INIT_STATUS = 'initializing';
export const NOT_CREATED_STATUS = 'notFound';
export const ERROR_STATUS = 'errored';

export const INVALID_TYPE = 'invalidType';

export const POLLING_INTERVAL = 10000;

export const GREAT_THRESHOLD = 1.2;
export const GOOD_THRESHOLD = 1.1;
export const BAD_THRESHOLD = 1;
export const TERRIBLE_THRESHOLD = 0.8;

export const CONTENT_PADDING = 'px-8';
