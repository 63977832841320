import CircularProgress from '@material-ui/core/CircularProgress';

import CompanyTile from '~/images/Tiles/Segment/Company.svg';
import PersonTile from '~/images/Tiles/Segment/Person.svg';
import { segmentTypes } from '~/lib/segments';

function Tile({ className, type, size = 50 }) {
  switch (type) {
    case segmentTypes.company:
      return <CompanyTile className={className} size={size} />;
    case segmentTypes.person:
      return <PersonTile className={className} size={size} />;
    default:
      return <CircularProgress className={className} size={size} />;
  }
}

Tile.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf([segmentTypes.company, segmentTypes.person]),
  size: PropTypes.number,
};

export default Tile;
