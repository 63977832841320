import ConnectionForm from '~/components/ConnectionForm';
import { authConnector } from '~/lib/client';
import {
  trackAuthStartedAccountConnector,
  trackConnectedAccountConnector,
} from '~/lib/segmentAnalytics';

const ConnectInterstitial = ({
  integration,
  connectorLocation,
  onSuccess = () => {},
}) => {
  const authSuccess = () => {
    trackConnectedAccountConnector(integration, connectorLocation);
    onSuccess();
  };

  const auth = (selectedIntegration) => {
    trackAuthStartedAccountConnector(integration, connectorLocation);

    const service =
      selectedIntegration === 'google' ? 'google_oauth2' : selectedIntegration;

    authConnector(service, () => authSuccess());
  };

  return (
    <div className="px-10 py-6">
      <ConnectionForm auth={auth} onSuccess={onSuccess} service={integration} />
    </div>
  );
};

export default ConnectInterstitial;
