import { CRMPresenceContext, useCRMPresence } from './useCRMPresence';

type CRMPresenceProviderProps = {
  children: React.ReactNode;
  domains: string[];
};

export const CRMPresenceProvider = ({
  domains,
  children,
}: CRMPresenceProviderProps) => {
  const {
    crmPresence,
    isHubSpotConnected,
    isLoading,
    isDomainPresent,
    setDomainPresence,
  } = useCRMPresence(domains);

  return (
    <CRMPresenceContext.Provider
      value={{
        crmPresence,
        isDomainPresent,
        isHubSpotConnected,
        isLoading,
        setDomainPresence,
      }}
    >
      {children}
    </CRMPresenceContext.Provider>
  );
};
