import classnames from 'classnames';
import { CKContainerProps } from 'clearkit';
import { FC } from 'react';

type CTAComposition = {
  Heading: FC<CKContainerProps>;
  Description: FC<CKContainerProps>;
};

export const CTA: FC<CKContainerProps> & CTAComposition = ({
  className,
  children,
  ...rest
}) => {
  return (
    <div {...rest} className={classnames(className, 'text-center space-y-4')}>
      {children}
    </div>
  );
};

CTA.Heading = ({ className, children, ...rest }) => {
  return (
    <h2 {...rest} className={classnames(className, 'font-medium')}>
      {children}
    </h2>
  );
};
CTA.Heading.displayName = 'CTA.Header';

CTA.Description = ({ className, children, ...rest }) => {
  return (
    <div {...rest} className={classnames(className, 'text-base text-gray-500')}>
      {children}
    </div>
  );
};
CTA.Description.displayName = 'CTA.Description';
