import classnames from 'classnames';
import { CKContainerProps } from 'clearkit';

export const ExportGroup = ({
  children,
  className,
  ...rest
}: CKContainerProps) => (
  <div {...rest} className={classnames('pt-4 space-y-4', className)}>
    {children}
  </div>
);
