import { CKLayoutGrid } from 'clearkit';

import { WhenProspector } from '../policies/WhenProspector';
import { FetchErrorRetry } from './CallsToAction/FetchErrorRetry';
import { NoAccess } from './CallsToAction/NoAccess';
import { CreateProspectList, ExportProspectsDrawer } from './Dialogs';
import { Loading } from './Loading';
import { Onboarding } from './Onboarding';
import { ProspectListBody } from './ProspectList/ProspectListBody';
import { SidebarFilters } from './Sidebar/SidebarFilters';
import { SidebarLists } from './Sidebar/SidebarLists';
import { useProspector } from './useProspector';
import { ProspectorProvider } from './useProspector/Provider';

export function ProspectorPage() {
  return (
    <WhenProspector cta={<NoAccess />}>
      <ProspectorProvider>
        <CKLayoutGrid className="w-full" gap="0" gridTemplateColumns="auto 1fr">
          <Prospector />
        </CKLayoutGrid>
      </ProspectorProvider>
    </WhenProspector>
  );
}

function Prospector() {
  const [state] = useProspector();

  if (state.matches('loading')) {
    return <Loading />;
  } else if (state.value === 'error') {
    return <FetchErrorRetry />;
  } else if (state.value === 'onboarding') {
    return <Onboarding />;
  }

  return (
    <>
      {state.context.showFilterSidebar ? <SidebarFilters /> : <SidebarLists />}
      <ProspectListBody />
      <CreateProspectList />
      <ExportProspectsDrawer />
    </>
  );
}
