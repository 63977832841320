const styles = () => ({
  content: {
    borderBottom: '1px solid rgba(155, 173, 188, 0.20)',
  },

  columnsHelperText: {
    fontSize: 14,
    marginTop: 7,
    height: 'auto',
    color: '#596168',
    marginBottom: '24px',
    '& span': {
      marginTop: 7,
      fontWeight: 500,
      display: 'block',
      alignItems: 'baseline',
      '& svg': {
        marginRight: 6,
      },
    },
  },

  field: {
    '&:last-of-type': {
      marginBottom: 10,
    },
  },

  formControl: {
    display: 'block',
  },

  select: {
    display: 'block',
    width: 340,
  },

  break: {
    margin: '20px -30px 24px -30px',
  },
});

export default styles;
