const styles = () => ({
  chipInput: {
    '& *::before': {
      backgroundColor: '#eff0f0',
      height: '1px !important',
    },
    '& *:hover::before': {
      height: '2px !important',
      backgroundColor: '#E1E9F0 !important',
    },
  },

  chipContainer: {
    minHeight: 38,
    marginBottom: 8,
  },

  subheading: {
    color: '#596168',
    flex: '0 0 auto',
    fontSize: 15,
    fontWeight: 500,
    margin: '0 0 7px',
  },

  triggerSubtext: {
    color: '#596168',
    fontSize: 14,
  },

  testRecipientTextField: {
    margin: '16px 0',
    width: 380,
  },

  field: {
    '&:last-of-type': {
      marginBottom: 10,
    },
  },
});

export default styles;
