import classnames from 'classnames';
import { CKLayoutHorizontal } from 'clearkit';
import { ReactNode } from 'react';

type FormSectionProps = {
  children: ReactNode;
  title: ReactNode;
  description: ReactNode;
  className?: string;
};

export default function FormSection({
  children,
  title,
  description,
  className,
}: FormSectionProps) {
  return (
    <CKLayoutHorizontal
      alignItems="start"
      className={classnames('w-full', className)}
      gap="1.5rem"
      wrap="wrap"
    >
      <div className="min-w-0 shrink-0 max-w-[35ch]">
        <div className="mb-2 text-base font-semibold">{title}</div>
        <div className="text-sm">{description}</div>
      </div>
      <div className="h-full min-w-0 grow">{children}</div>
    </CKLayoutHorizontal>
  );
}
