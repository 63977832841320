import styles from './styles';

const ContentBox = ({ children, className, classes, rel }) => (
  <div className={classnames(classes.wrapper, className)} rel={rel}>
    {children}
  </div>
);

const Section = ({ children, className, classes, variant, rel }) => (
  <div
    className={classnames(classes.section, className, {
      [classes.sectionHorizontal]: variant === 'horizontal',
      [classes.greyBackground]: variant === 'grey',
    })}
    rel={rel}
  >
    {children}
  </div>
);

const Group = ({ children, className, classes, variant, rel }) => (
  <div
    className={classnames(classes.group, className, {
      [classes.groupHalfWidth]: variant === 'half-width',
    })}
    rel={rel}
  >
    {children}
  </div>
);

const Icon = ({ children, className, classes, rel }) => (
  <div className={classnames(classes.icon, className)} rel={rel}>
    {children}
  </div>
);

const Break = ({ className, classes, rel }) => (
  <div className={classnames(classes.break, className)} rel={rel} />
);

const Title = ({ children, classes, className, rel }) => (
  <div className={classnames(classes.title, className)} rel={rel}>
    {children}
  </div>
);

Icon.propTypes = ContentBox.propTypes = Group.propTypes = Section.propTypes = Break.propTypes = Title.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  variant: PropTypes.string,
  className: PropTypes.string,
  rel: PropTypes.string,
};

const StyledIcon = withStyles(styles)(Icon);
const StyledGroup = withStyles(styles)(Group);
const StyledSection = withStyles(styles)(Section);
const StyledBreak = withStyles(styles)(Break);
const StyledTitle = withStyles(styles)(Title);

ContentBox.Icon = StyledIcon;
ContentBox.Group = StyledGroup;
ContentBox.Section = StyledSection;
ContentBox.Break = StyledBreak;
ContentBox.Title = StyledTitle;

export default withStyles(styles)(ContentBox);
