import {
  AddHollow,
  InfoHollow as InfoIcon,
  Trash as TrashIcon,
} from '@clearkit/icons';
import ThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { CKButton, CKTooltip } from 'clearkit';
import get from 'lodash/get';
import startCase from 'lodash/startCase';

import { getAndOrOr } from '~/components/SearchBuilder/utils/nodeUtils';
import {
  removeNode,
  replaceNode,
  SearchContext,
} from '~/components/SearchBuilder/utils/searchUtils';
import {
  isNodeNestable,
  nestNode,
} from '~/components/SearchBuilder/utils/thirdPartySearchUtils';
import { readableType } from '~/lib/dataEnrichment';

import { DEFAULT_GROUP, MAXIMUM_NEST_LEVEL } from '../../utils/constants';
import AnyQueryNode from '../AnyQueryNode';
import hubspot from './Hubspot';
import QueryGroup from './index';
import marketo from './Marketo';
import salesforce from './Salesforce';
import styles from './styles';

const services = {
  hubspot,
  salesforce,
  marketo,
};

const BASE_NEST_LEVEL = 1;

const getService = (serviceName) => {
  const service = services[serviceName];

  if (!service) {
    throw new Error(`Invalid service ${serviceName}`);
  }

  return service;
};

class ThirdPartyQueryGroup extends React.Component {
  addDependentCondition = () => {
    const {
      node,
      service,
      rootCategory,
      dependentCategory,
      onChange,
      search,
    } = this.props;
    const rootGroup = `${service}_${rootCategory}`;
    const dependentGroup = `${service}_${dependentCategory}`;
    node[rootGroup][dependentGroup] = DEFAULT_GROUP;
    const newSearch = replaceNode(search, node);
    onChange(newSearch);
  };

  addPrimaryCondition = () => {
    const { service, node, rootCategory, onChange, search } = this.props;
    const rootGroup = `${service}_${rootCategory}`;

    node[rootGroup] = {
      ...node[rootGroup],
      ...DEFAULT_GROUP,
    };
    const newSearch = replaceNode(search, node);
    onChange(newSearch);
  };

  nestNode = () => {
    const { service, node, onChange, search } = this.props;
    const newSearch = nestNode(search, node, {
      parent: `${service}_company`,
      child: `${service}_contact`,
    });
    onChange(newSearch);
  };

  render() {
    const {
      classes,
      className,
      node,
      insertInto,
      service: serviceName,
      rootCategory,
      dependentCategory,
      attributes,
      onChange,
      search,
      expanded,
      isSelectiveEnrichmentConfig,
    } = this.props;

    const { disabled } = this.context;
    const rootGroup = `${serviceName}_${rootCategory}`;
    const dependentGroup = `${serviceName}_${dependentCategory}`;
    const rootNode = get(node, [rootGroup]);
    const dependentNode = get(node, [rootGroup, dependentGroup], []);
    const andOrOr = getAndOrOr(rootNode);
    const rootNodes = get(rootNode, andOrOr);
    const dependentNodes = get(dependentNode, [getAndOrOr(dependentNode)]);

    const queryGroupProps = {
      ...this.props,
      className: classes.queryGroup,
      insertInto,
      category: serviceName,
      canAddGroup: isSelectiveEnrichmentConfig
        ? node.__nest < MAXIMUM_NEST_LEVEL
        : false,
    };

    const service = getService(serviceName);

    const showRemoveGroup = isSelectiveEnrichmentConfig
      ? node.__nest !== BASE_NEST_LEVEL
      : true;

    return (
      <div className={classnames(className, classes.root, classes.queryGroup)}>
        <div className={classes.primaryCondition}>
          <div className={classes.primaryConditionDescription}>
            {isSelectiveEnrichmentConfig ? 'Only enrich' : 'Has'}
            <span className={classes.primaryConditionIndicator}>
              {React.createElement(service.logo)} {readableType(serviceName)}{' '}
              {startCase(rootCategory)}
            </span>
            {rootNodes
              ? `where`
              : !disabled &&
                !isSelectiveEnrichmentConfig && (
                  <CKButton
                    leftIcon={<AddHollow />}
                    onClick={this.addPrimaryCondition}
                    variant="tertiary"
                    variantColor="blue"
                  >
                    Add {startCase(rootCategory)} condition
                  </CKButton>
                )}
            {!disabled && (
              <div className="flex items-center ml-auto gap-4">
                {isNodeNestable(node) && !isSelectiveEnrichmentConfig && (
                  <>
                    <CKButton
                      leftIcon={<AddHollow />}
                      onClick={this.nestNode}
                      variant="simple"
                      variantColor="blue"
                    >
                      Add Company condition
                    </CKButton>
                    <div className={classes.separator} />
                  </>
                )}
                <CKTooltip
                  placement="top-end"
                  tooltip={`Conditions within this group are scoped to the same ${startCase(
                    serviceName,
                  )} object`}
                >
                  <InfoIcon />
                </CKTooltip>
                {showRemoveGroup ? (
                  <CKButton
                    isDisabled={disabled}
                    onClick={() => {
                      const newSearch = removeNode(search, node);
                      onChange(newSearch);
                    }}
                    variant="tertiary"
                  >
                    <TrashIcon />
                  </CKButton>
                ) : null}
              </div>
            )}
          </div>
          {rootNodes && (
            <QueryGroup
              {...queryGroupProps}
              className={classes.conditions}
              node={rootNode}
              subCategory={rootCategory}
            >
              {rootNodes.map((node) => (
                <AnyQueryNode
                  attributes={attributes}
                  category={serviceName}
                  isSelectiveEnrichmentConfig={isSelectiveEnrichmentConfig}
                  key={node.__index}
                  node={node}
                  onChange={onChange}
                  search={search}
                  subCategory={rootCategory}
                />
              ))}
            </QueryGroup>
          )}
        </div>
        {!isSelectiveEnrichmentConfig &&
          expanded &&
          (!disabled || dependentNodes) && (
            <>
              <div
                className={classnames(classes.secondaryCondition, {
                  [classes.showCaret]: !!dependentNodes,
                })}
              >
                <div className={classes.primaryConditionDescription}>
                  {dependentNodes && (
                    <>
                      Where{' '}
                      <span className={classes.primaryConditionIndicator}>
                        {startCase(dependentCategory)}
                      </span>{' '}
                    </>
                  )}
                  {!disabled && !dependentNodes && (
                    <CKButton
                      className={classes.addSecondaryConditionButton}
                      leftIcon={<AddHollow />}
                      onClick={this.addDependentCondition}
                      variant="tertiary"
                      variantColor="blue"
                    >
                      Add {startCase(dependentCategory)} condition
                    </CKButton>
                  )}
                </div>
                {dependentNodes && (
                  <QueryGroup
                    {...queryGroupProps}
                    className={classes.conditions}
                    node={dependentNode}
                  >
                    {dependentNodes.map((node) => (
                      <AnyQueryNode
                        attributes={attributes}
                        category={serviceName}
                        key={node.__index}
                        node={node}
                        onChange={onChange}
                        search={search}
                        subCategory={dependentCategory}
                      />
                    ))}
                  </QueryGroup>
                )}
              </div>
            </>
          )}
      </div>
    );
  }
}

ThirdPartyQueryGroup.contextType = SearchContext;

ThirdPartyQueryGroup.propTypes = {
  classes: PropTypes.object,
  expanded: PropTypes.bool,
  className: PropTypes.string,
  node: PropTypes.object,
  insertInto: PropTypes.func,
  renderNode: PropTypes.func,
  rootCategory: PropTypes.string,
  dependentCategory: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
  attributes: PropTypes.array,
  selectiveEnrichmentSearch: PropTypes.bool,
  isSelectiveEnrichmentConfig: PropTypes.bool,
};

const StyledThirdPartyQueryGroup = withStyles(styles)(ThirdPartyQueryGroup);

export default (props) => (
  <ThemeProvider theme={getService(props.service).theme}>
    <StyledThirdPartyQueryGroup {...props} />
  </ThemeProvider>
);
