import { CheckmarkOrb, Go } from '@clearkit/icons';
import classnames from 'classnames';
import { CKContainerProps, CKLayoutHorizontal, CKSkeleton } from 'clearkit';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

type OnboardingItemProps = CKContainerProps & {
  isComplete: boolean;
  isDisabled?: boolean;
  isHidden?: boolean;
  isLoading?: boolean;
  number: number;
  title: ReactNode;
  to: string;
};

const NumberCircle = ({
  isComplete,
  isDisabled,
  number,
}: {
  isComplete: boolean;
  isDisabled?: boolean;
  number: number;
}) => {
  return (
    <CKLayoutHorizontal
      className={classnames(
        'border shrink-0 w-8 h-8 rounded-full text-xs leading-none',
        {
          'bg-gradient-to-b gradient-blue text-white border-blue-600':
            !isComplete && !isDisabled,
          'bg-green-100 border-green-200': isComplete,
          'bg-gray-100 border-gray-200 text-gray-500': isDisabled,
        },
      )}
      justifyContent="center"
    >
      {isComplete ? (
        <CheckmarkOrb className="fill-gradient-br-green" />
      ) : (
        `0${number}`
      )}
    </CKLayoutHorizontal>
  );
};

export const OnboardingItemDivider = () => (
  <div className="pl-6 my-2">
    <svg className="h-4" viewBox="0 0 3 17" xmlns="http://www.w3.org/2000/svg">
      <circle cx="1.5" cy="1.5" fill="#148CFC" r="1.5" />
      <circle cx="1.5" cy="8.5" fill="#148CFC" r="1.5" />
      <circle cx="1.5" cy="15.5" fill="#148CFC" r="1.5" />
    </svg>
  </div>
);

export const OnboardingItem = ({
  className,
  isComplete,
  isDisabled,
  isHidden = false,
  isLoading = false,
  number,
  title,
  to,
  ...rest
}: OnboardingItemProps) => {
  const isActive = !isComplete && !isDisabled;

  if (isHidden) {
    return null;
  }

  if (isLoading) {
    return <CKSkeleton className="h-12 rounded-full" isLoading />;
  }

  const Item = () => (
    <CKLayoutHorizontal
      {...rest}
      className={classnames(
        'p-2 border border-gray-200 rounded-full group-hover:bg-blue-0 bg-gray-0 text-sm text-gray-900 pr-6',
        className,
      )}
    >
      <CKLayoutHorizontal>
        <NumberCircle
          isComplete={isComplete}
          isDisabled={isDisabled}
          number={number}
        />
        <span
          className={classnames({
            'line-through': isComplete,
            'text-gray-500': !isActive,
          })}
        >
          {title}
        </span>
      </CKLayoutHorizontal>
      <Go
        className={classnames({
          'text-blue-500': isActive,
          'text-gray-300': !isActive,
        })}
      />
    </CKLayoutHorizontal>
  );

  return isActive ? (
    <Link
      className={classnames(
        'block group ck-focus-ring rounded-full',
        className,
      )}
      to={to}
    >
      <Item />
    </Link>
  ) : (
    <Item />
  );
};
