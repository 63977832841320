import get from 'lodash/get';
import isArray from 'lodash/isArray';
import moment from 'moment';

import DatePicker from '~/components/DatePicker';
import { SearchContext } from '~/components/SearchBuilder/utils/searchUtils';
import ClearbitSelect from '~/components/Select';
import TextField from '~/components/TextField';

import valueDateStyles from './styles';

const withinOptions = [
  { value: 'day', label: 'days' },
  { value: 'week', label: 'weeks' },
  { value: 'month', label: 'months' },
  { value: 'year', label: 'years' },
];

// TODO: Figure out how to calculate number/increment values here
// based on how the back-end will expect these values
const getInterval = (value) => get(value, 'interval');
const getAmount = (value) => get(value, 'amount');
const calculateValue = (amount, interval) => {
  return {
    interval,
    amount: parseInt(amount),
  };
};

class ValueDate extends React.Component {
  render() {
    const { classes = {}, className, onChange, value, comparator } = this.props;
    const { disabled } = this.context;

    let startDate, endDate;

    if (value) {
      const startValue = isArray(value) ? value[0] : value;
      const endValue = isArray(value) ? value[1] : value;

      startDate = moment(startValue || undefined)
        .utc()
        .format('YYYY-MM-DD');
      endDate = moment(endValue || undefined)
        .utc()
        .format('YYYY-MM-DD');
    }

    const interval = getInterval(value);
    const amount = getAmount(value);

    switch (comparator) {
      case 'date_between':
      case 'date_not_between':
        return (
          <div className={classnames(className, classes.datePickers)}>
            <DatePicker
              defaultStartDate={startDate}
              defaultValue={startDate}
              disabled={disabled}
              onChange={(startDate) => {
                onChange([startDate, endDate]);
              }}
              variant="outlined"
            />
            <div className={classes.and}>and</div>
            <DatePicker
              defaultStartDate={endDate}
              defaultValue={endDate}
              disabled={disabled}
              onChange={(endDate) => {
                onChange([startDate, endDate]);
              }}
              variant="outlined"
            />
          </div>
        );
      case 'date_in_last':
      case 'date_in_next':
        return (
          <div className={classnames(className, classes.withinContainer)}>
            <TextField
              disabled={disabled}
              FormControlProps={{
                classes: {
                  root: classes.withinText,
                  input: classes.largeInput,
                },
              }}
              inputProps={{ min: '1', step: '1' }}
              onChange={(event) => {
                const amount = event.target.value;
                const value = calculateValue(amount, interval);
                onChange(value);
              }}
              placeholder="1"
              type="number"
              value={amount}
            />
            <ClearbitSelect
              className={classes.withinSelect}
              disabled={disabled}
              getOptionLabel={(o) => o.label}
              getOptionValue={(o) => o.value}
              onChange={(option) => {
                const interval = option.value;
                const value = calculateValue(amount, interval);
                onChange(value);
              }}
              options={withinOptions}
              value={withinOptions.find(
                (option) => option.value === getInterval(value),
              )}
            />
          </div>
        );
      default:
        return (
          <DatePicker
            classes={{ inputRoot: className }}
            defaultStartDate={startDate}
            defaultValue={isArray(value) ? value[0] : value}
            disabled={disabled}
            key={value}
            onChange={(value) => onChange(value)}
            variant="outlined"
          />
        );
    }
  }
}

ValueDate.contextType = SearchContext;

ValueDate.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  comparator: PropTypes.string,
};

export default withStyles(valueDateStyles)(ValueDate);
