const styles = () => ({
  // search

  container: {
    display: 'flex',
    flex: 'none',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: '#596168',
    fontSize: 14,
    fontWeight: 400,
    paddingTop: 32,
    textAlign: 'center',
    '& svg': {
      marginBottom: 16,
    },
    '& p': {
      maxWidth: '80%',
      overflowWrap: 'break-word',
    },
    '& span': {
      fontWeight: 'bold',
    },
  },

  // selected

  displayPanelEmpty: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    backgroundColor: 'white',
  },

  emptySelectionText: {
    textAlign: 'center',
    margin: '16px 32px',
    color: '#596168',
    fontSize: 14,
    fontWeight: 600,
    '& p': {
      fontWeight: 500,
    },
  },

  emptyResetDefaultButton: {
    color: '#3386ee',
    fontSize: 13,
  },
});

export default styles;
