import { routes } from '~/components/app/appMainRoutes';

export const settingsRoutes = {
  settingsIndexRoute: '/settings',
  generalAccountRoute: '/settings/general/account',
  generalTeamsRoute: '/settings/general/teams',
  generalBillingRoute: '/settings/general/billing',
  teamGeneralRoute: '/settings/team/general',
  teamUsersRoute: '/settings/team/users',
  apiKeysRoute: '/settings/api/keys',
  apiLogsRoute: '/settings/api/logs',
  apiUsageRoute: '/settings/api/usage',
  apiDocsRoute: 'https://dashboard.clearbit.com/docs',
  configureHubspot: '/settings/configuration/hubspot',
  tagsJs: routes.tagsJs,
  hubSpotIntegration: '/settings/configuration/hubspot',
};
