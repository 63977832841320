import { CKTag } from 'clearkit';

import { FitLabel } from '~/components/IdealCustomerProfile/components/types';

import {
  getColorClassNamesFromFitVariant,
  getDescriptionFromFitVariant,
  getFitVariantFromFitScore,
} from '../../IdealCustomerProfile/components/util';

type FitTagProps = {
  score?: FitLabel;
  muted?: boolean;
};

export function FitTag({ score, muted }: FitTagProps) {
  const variant = getFitVariantFromFitScore(score);

  if (!variant) {
    return null;
  }

  return (
    <CKTag
      className={`${getColorClassNamesFromFitVariant(variant, {
        muted,
      })} px-2 overflow-hidden`}
    >
      <span
        className={`${getColorClassNamesFromFitVariant(variant, {
          muted,
        })} pr-2 mr-2 py-1 leading-none border-t-0 border-l-0 border-b-0 rounded-tr-none rounded-br-none`}
      >
        Fit Score
      </span>
      <span className="py-1 leading-none">
        {getDescriptionFromFitVariant(variant)}
      </span>
    </CKTag>
  );
}
