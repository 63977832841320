import {
  CKContainerProps,
  CKPickerNext,
  CKSelectableItem,
  CKSingleSelectProps,
} from 'clearkit';
import { FC } from 'react';

import {
  CRMExportFieldWrapper,
  iconDisplayName,
  labelDisplayName,
} from './CRMExportFieldWrapper';

export type CRMExportFieldProps = Omit<
  CKSingleSelectProps,
  'value' | 'onChange'
> & {
  isLoading?: boolean;
  onChange?: (value: string) => void;
  value: string;
};

type CRMExportFieldComposition = {
  IconSalesforce: FC<CKContainerProps>;
  IconHubspot: FC<CKContainerProps>;
  Label: FC<CKContainerProps>;
};

export const CRMExportField: FC<CRMExportFieldProps> &
  CRMExportFieldComposition = ({
  children,
  className,
  onChange,
  isLoading,
  items,
  value,
  ...rest
}) => {
  const handleChange = (newValue: CKSelectableItem) => {
    onChange?.(newValue.value);
  };

  return (
    <CRMExportFieldWrapper className={className} isLoading={isLoading}>
      {children}
      <CRMExportFieldWrapper.Input>
        <CKPickerNext
          {...rest}
          className="min-w-0 shrink"
          items={items}
          onChange={handleChange}
          value={{
            value,
            label: items?.find((item) => item.value === value)?.label as string,
          }}
        />
      </CRMExportFieldWrapper.Input>
    </CRMExportFieldWrapper>
  );
};

CRMExportField.Label = CRMExportFieldWrapper.Label;
CRMExportField.Label.displayName = labelDisplayName;

CRMExportField.IconSalesforce = CRMExportFieldWrapper.IconSalesforce;
CRMExportField.IconSalesforce.displayName = iconDisplayName;

CRMExportField.IconHubspot = CRMExportFieldWrapper.IconHubspot;
CRMExportField.IconHubspot.displayName = iconDisplayName;
