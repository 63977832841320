import classnames from 'classnames';
import {
  CKBannerNext,
  CKBox,
  CKContainerProps,
  includeChildrenByDisplayName,
} from 'clearkit';
import { FC } from 'react';

type UpgradePlanBannerComposition = {
  Icon: FC<CKContainerProps>;
  Header: FC<CKContainerProps>;
  Body: FC<CKContainerProps>;
  CTA: FC<CKContainerProps>;
};

const bodyComponentName = 'CKBannerNext.Body';
const ctaComponentName = 'CKBannerNext.CTA';
const headerComponentName = 'CKBannerNext.Header';
const iconComponentName = 'CKBannerNext.Icon';

export const UpgradePlanBanner: FC<CKContainerProps> &
  UpgradePlanBannerComposition = ({ children, className }) => {
  return (
    <CKBannerNext className={classnames('!py-4', className)}>
      <CKBannerNext.Icon>
        {includeChildrenByDisplayName({
          children,
          componentDisplayName: iconComponentName,
        })}
      </CKBannerNext.Icon>
      {includeChildrenByDisplayName({
        children,
        componentDisplayName: headerComponentName,
      })}
      {includeChildrenByDisplayName({
        children,
        componentDisplayName: bodyComponentName,
      })}
      {includeChildrenByDisplayName({
        children,
        componentDisplayName: ctaComponentName,
      })}
    </CKBannerNext>
  );
};

UpgradePlanBanner.Body = ({ children, ...rest }) => (
  <CKBannerNext.Body {...rest}>{children}</CKBannerNext.Body>
);
UpgradePlanBanner.Body.displayName = bodyComponentName;

UpgradePlanBanner.CTA = ({ children, ...rest }) => (
  <CKBannerNext.CTA {...rest}>{children}</CKBannerNext.CTA>
);
UpgradePlanBanner.CTA.displayName = ctaComponentName;

UpgradePlanBanner.Header = ({ children, ...rest }) => (
  <CKBannerNext.Header {...rest}>{children}</CKBannerNext.Header>
);
UpgradePlanBanner.Header.displayName = headerComponentName;

UpgradePlanBanner.Icon = ({ children, className, ...rest }) => (
  <CKBox {...rest} className={classnames('p-2', className)}>
    {children}
  </CKBox>
);
UpgradePlanBanner.Icon.displayName = iconComponentName;
