import startCase from 'lodash/startCase';

export const compareStringsWithStartCase = (haystack, needle) => {
  if (!haystack) return false;
  if (!needle) return true;

  if (
    haystack
      .toString()
      .toLowerCase()
      ?.includes(needle.toString().toLowerCase()) ||
    startCase(haystack.toString())
      .toLowerCase()
      .includes(needle.toString().toLowerCase())
  )
    return true;
};
