import classnames from 'classnames';
import {
  CKButton,
  CKButtonProps,
  CKContainerProps,
  CKIconBox,
  CKLayoutHorizontal,
  CKLayoutHorizontalProps,
  CKTooltip,
  cloneChildren,
} from 'clearkit';
import { AnchorHTMLAttributes } from 'react';

type TokenProps = CKContainerProps & {
  hasToken?: boolean;
  title?: React.ReactNode;
};

type TokenButtonProps = Omit<TokenProps, 'title'> & CKButtonProps;

type TokenLinkProps = Omit<TokenProps, 'title'> &
  AnchorHTMLAttributes<HTMLAnchorElement>;

const TokenWrapper = ({ children, className, hasToken }: TokenProps) => (
  <CKIconBox as="span" className={classnames('text-gray-900 !p-2', className)}>
    {cloneChildren({
      children,
      newProps: {
        className: classnames('w-3 h-3', {
          'opacity-20': !hasToken,
        }),
      },
    })}
  </CKIconBox>
);

export const Token = ({ children, hasToken, title }: TokenProps) => {
  return hasToken ? (
    <CKTooltip targetClassName="flex" tooltip={title}>
      <TokenWrapper hasToken={hasToken}>{children}</TokenWrapper>
    </CKTooltip>
  ) : (
    <TokenWrapper hasToken={hasToken}>{children}</TokenWrapper>
  );
};

export const TokenButton = ({
  children,
  className,
  hasToken,
  ...rest
}: TokenButtonProps) => {
  return hasToken ? (
    <CKButton
      {...rest}
      className={classnames(
        'flex rounded outline-none ck-focus-ring',
        className,
      )}
      variant="clear"
    >
      <TokenWrapper
        className={classnames('flex', {
          '!text-gray-800': hasToken,
        })}
        hasToken={hasToken}
      >
        {children}
      </TokenWrapper>
    </CKButton>
  ) : (
    <TokenWrapper className={className}>{children}</TokenWrapper>
  );
};

export const TokenLink = ({
  children,
  className,
  hasToken,
  href,
  ...rest
}: TokenLinkProps) => {
  return hasToken && href ? (
    <a
      {...rest}
      className={classnames(
        'flex rounded outline-none ck-focus-ring',
        className,
      )}
      href={href}
    >
      <TokenWrapper
        className={classnames({
          '!text-blue-500': hasToken,
        })}
        hasToken={hasToken}
      >
        {children}
      </TokenWrapper>
    </a>
  ) : (
    <TokenWrapper className={className}>{children}</TokenWrapper>
  );
};

export const TokenGroup = ({
  children,
  gap = '.5rem',
  justifyContent = 'start',
  ...rest
}: CKLayoutHorizontalProps) => (
  <CKLayoutHorizontal {...rest} gap={gap} justifyContent={justifyContent}>
    {children}
  </CKLayoutHorizontal>
);
