import { useQuery } from 'react-query';

type SuggestedProfileData = {
  email_address: string;
  user_first_name?: string;
  user_last_name?: string;
  account_name?: string;
};

export default function useSuggestedProfile(
  onSuccess: (data: SuggestedProfileData) => void,
) {
  return useQuery({
    queryFn: async () => {
      const response = await fetch('/auth/signup/suggested_profile');
      return response.json();
    },
    refetchOnWindowFocus: false,
    onSuccess,
  });
}
