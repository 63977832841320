import {
  ClearbitServices as Clearbit,
  EnrichmentBlock,
  HubspotServices as HubSpot,
  MarketoServices as Marketo,
  Restart as RestartIcon,
  SalesforceServices as Salesforce,
  Trash,
} from '@clearkit/icons';
import { CKAttributeSelector, CKButton } from 'clearkit';
import { useState } from 'react';

import { MappingRowContainer } from '~/components/MappingRowContainer';
import { findAttribute } from '~/lib/dataEnrichment';
import { isTypeMismatch } from '~/lib/isTypeMismatch';

export const RefreshButton = ({ onClickRefresh }) => {
  const [loading, setLoading] = useState(false);
  const onClick = async () => {
    setLoading(true);

    try {
      await onClickRefresh();
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
    } finally {
      setLoading(false);
    }
  };

  return (
    <CKButton
      isDisabled={loading}
      leftIcon={<RestartIcon />}
      onClick={onClick}
      variant="tertiary"
      variantColor="blue"
    >
      {loading ? 'Refreshing' : 'Refresh'}
    </CKButton>
  );
};

export default function FormFieldMappingRow({
  mapping,
  service,
  onChange,
  onRemove,
  sourceAttributes,
  targetAttributes,
  onClickRefreshAttributes,
  sourceErrors,
  targetErrors,
  showError,
}) {
  const mappingToAttribute = (mappingValue) => {
    if (!mappingValue) return null;

    const [sourceType, objectType, ...value] = mappingValue.split('.');

    return {
      sourceType,
      objectType,
      value: value.join('.'),
    };
  };

  const mappingAttributeToValue = (mappingAttribute) => {
    const hierarchy = [];
    hierarchy.push(mappingAttribute.meta.sourceType);
    hierarchy.push(mappingAttribute.meta.objectType);
    hierarchy.push(mappingAttribute.value);
    return hierarchy.join('.');
  };

  const selectedSourceAttribute = sourceAttributes
    ? findAttribute(
        mappingToAttribute(mapping.sourceAttribute),
        sourceAttributes,
      )
    : null;
  const selectedTargetAttribute = targetAttributes
    ? findAttribute(
        mappingToAttribute(mapping.targetAttribute),
        targetAttributes,
      )
    : null;

  const inErrorState = (sourceOrTarget) => {
    if (sourceOrTarget === 'source') {
      return !!sourceErrors || (!mapping.sourceAttribute && showError);
    } else if (sourceOrTarget === 'target') {
      return !!targetErrors || (!mapping.targetAttribute && showError);
    } else return false;
  };

  const renderServiceIcon = (service) => {
    switch (service) {
      case 'HubSpot':
        return <HubSpot height={18} width={18} />;
      case 'Marketo':
        return <Marketo height={18} width={18} />;
      case 'Pardot':
        return <Salesforce height={18} width={18} />;
      default:
        return <EnrichmentBlock height={18} width={18} />;
    }
  };

  const rootCategoryIdentifier = (service) => {
    switch (service) {
      case 'HubSpot':
        return 'category_hubspot';
      case 'Marketo':
        return 'category_marketo';
      case 'Pardot':
        return 'category_pardot';
      default:
        return 'category_enrichment';
    }
  };

  return (
    <MappingRowContainer>
      <CKAttributeSelector
        attributes={sourceAttributes || null}
        icon={<Clearbit height={18} width={18} />}
        isTypeMismatch={(attribute) =>
          isTypeMismatch(attribute, selectedTargetAttribute)
        }
        onSelectedItemChange={(selected) =>
          onChange({
            sourceAttribute: mappingAttributeToValue(selected),
          })
        }
        placeholder="Find an attribute"
        selectedAttribute={selectedSourceAttribute || {}}
        variant={inErrorState('source') && 'error'}
      />
      <CKAttributeSelector
        attributes={targetAttributes || null}
        detailDrawerPosition="left"
        icon={renderServiceIcon(service)}
        isTypeMismatch={(attribute) =>
          isTypeMismatch(selectedSourceAttribute, attribute)
        }
        onSelectedItemChange={(selected) =>
          onChange({
            targetAttribute: mappingAttributeToValue(selected),
          })
        }
        placeholder={`Choose a ${service} attribute`}
        rootCategoryIdentifier={rootCategoryIdentifier(service)}
        rootCategoryLabel={service}
        rootCategoryMeta={
          <RefreshButton onClickRefresh={onClickRefreshAttributes} />
        }
        selectedAttribute={selectedTargetAttribute || {}}
        variant={inErrorState('target') && 'error'}
      />
      <CKButton onClick={onRemove} variant="simple" variantColor="red">
        <Trash />
      </CKButton>
    </MappingRowContainer>
  );
}
