import ButtonBase from '@material-ui/core/ButtonBase';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { CKSearch } from 'clearkit';
import { startCase } from 'lodash';
import { AutoSizer, List } from 'react-virtualized';

import {
  AttributeCategoryIcon,
  DEFAULT_COLUMNS,
  getAttributeLabel,
} from '../AttributeCategories';
import CategorizedList from './CategorizedList';
import EmptySearchResults from './EmptySearchResults';
import EmptySelectedOptions from './EmptySelectedOptions';
import Option from './Option';
import styles from './styles';

class MultiPicklist extends React.Component {
  constructor(props) {
    super(props);

    this._defaultColumns = DEFAULT_COLUMNS[props.type];
    const columns = props.value.length > 0 ? props.value : this._defaultColumns;

    this.state = {
      category: columns[0].category,
      columns,
      filter: '',
      tabIndex: 0,
      subcategory: '',
    };
  }

  toggleSelection = (column) => {
    const { onChange } = this.props;
    const { columns } = this.state;

    const activeIndex = columns.findIndex(
      (selectedColumn) =>
        selectedColumn.category == column.category &&
        selectedColumn.value == column.value,
    );

    if (activeIndex === -1) {
      this.setState(
        (prevState) => ({
          columns: [...prevState.columns, column],
        }),
        () => onChange(this.state.columns),
      );
    } else {
      this.setState(
        (prevState) => ({
          columns: [
            ...prevState.columns.slice(0, activeIndex),
            ...prevState.columns.slice(activeIndex + 1),
          ],
        }),
        () => onChange(this.state.columns),
      );
    }
  };

  resetToDefault = () => {
    const { onChange } = this.props;

    this.setState(
      {
        columns: this._defaultColumns,
      },
      () => onChange(this.state.columns),
    );
  };

  isMatch = (left = '', right) =>
    left.toLowerCase().indexOf(right.toLowerCase()) !== -1;

  categories = () => {
    const { allColumns } = this.props;

    const categoryWeights = {
      clearbit: 1,
      'page view': 2,
      traits: 3,
      salesforce: 4,
      hubspot: 5,
      'fit score': 6,
    };

    return [...new Set(allColumns.map((c) => c.category))].sort((a, b) =>
      categoryWeights[a] > categoryWeights[b] ? 1 : -1,
    );
  };

  categoryObjects = () =>
    this.categories().map((category, index) => ({
      icon: <AttributeCategoryIcon category={category} />,
      subCategories: this.subCategoriesForCategory(category),
      value: category,
      weight: index,
    }));

  categoryHasSubcategories = (category) => {
    const { allColumns } = this.props;

    return allColumns.some(
      (column) => column.category === category && column.subCategory,
    );
  };

  categoryComingSoon = (category) => {
    const { hubspotComingSoon } = this.props;

    return hubspotComingSoon && category === 'hubspot';
  };

  subCategoriesForCategory = (category) => {
    const { allColumns } = this.props;

    return [
      ...new Set(
        allColumns.reduce(
          (subCategories, c) =>
            c.category === category && c.subCategory
              ? [...subCategories, c.subCategory]
              : [...subCategories],
          [],
        ),
      ),
    ].sort();
  };

  selectedCategoryColumns = (category) => {
    const { columns } = this.state;

    return columns.filter((c) => c.category === category);
  };

  searchResultsCategoryColumns = (category) =>
    this.getFilteredColumns().filter((c) => c.category === category);

  categoryColumns = () => {
    const { allColumns } = this.props;
    const { category, tabIndex } = this.state;

    if (this.categoryHasSubcategories(category)) {
      const subCategories = this.subCategoriesForCategory(category);
      return allColumns.filter(
        (c) =>
          c.subCategory === subCategories[tabIndex - 1] &&
          c.category === category,
      );
    }
    return allColumns.filter((c) => c.category === category);
  };

  getFilteredColumns = () => {
    const { allColumns } = this.props;
    const { filter } = this.state;

    if (!filter) return this.categoryColumns();

    return allColumns.filter((column) => this.isMatch(column.label, filter));
  };

  renderSubCategoryTabs = () => {
    const { category, tabIndex } = this.state;
    const { classes } = this.props;

    const tabs = this.subCategoriesForCategory(category).map(
      (subCategory, index) => (
        <Tab
          classes={{
            root: classes.categoryTabRoot,
            label: classes.categoryTabLabel,
          }}
          key={subCategory}
          label={startCase(subCategory)}
          value={index + 1}
        />
      ),
    );

    return (
      <Tabs
        classes={{
          root: classes.categoriesTabsRoot,
          flexContainer: classes.categoriesTabsContainer,
        }}
        onChange={(_event, tabIndex) => this.setState({ tabIndex })}
        value={tabIndex}
        variant="fullWidth"
      >
        {tabs}
      </Tabs>
    );
  };

  renderCategories = () => {
    const { classes } = this.props;
    const { category: selectedCategory, filter } = this.state;

    if (filter) return null;

    const categories = this.categories();
    return categories.map((category) => {
      const icon = <AttributeCategoryIcon category={category} />;
      const label = getAttributeLabel(category);
      const comingSoon = this.categoryComingSoon(category);

      return (
        <MenuItem
          classes={{
            root: classnames(classes.categoryMenuItemRoot, {
              [classes.categoryMenuItemComingSoon]: comingSoon,
            }),
            selected: classes.categoryMenuItemSelected,
          }}
          key={category}
          onClick={() => this.setState({ category, tabIndex: 1 })}
          selected={category === selectedCategory}
        >
          {icon}
          {label}
        </MenuItem>
      );
    });
  };

  renderOptions = () => {
    const { classes } = this.props;
    const { columns, filter, category, tabIndex } = this.state;
    let key;

    if (this.categoryComingSoon(category)) {
      return (
        <div className={classes.categoryComingSoonMessage}>
          <span>Coming soon</span>
        </div>
      );
    }

    if (this.categoryHasSubcategories(category)) {
      const subCategories = this.subCategoriesForCategory(category);

      key = subCategories[tabIndex - 1];
    }

    if (filter)
      return (
        <CategorizedList
          categories={this.categoryObjects()}
          classes={{
            categoryHeaderRoot: classes.categoryHeaderRoot,
            optionMenuItemRoot: classes.searchMenuItemRoot,
            optionMenuItemSelected: classes.optionMenuItemSelected,
            option: classes.option,
          }}
          columns={this.getFilteredColumns()}
          defaultColumns={this._defaultColumns}
          EmptyResults={<EmptySearchResults filter={filter} />}
          removable={false}
          resetToDefault={this.resetToDefault}
          selected={(column) =>
            columns.some(
              (selectedColumn) =>
                selectedColumn.value === column.value &&
                selectedColumn.category === column.category &&
                selectedColumn.subCategory === column.subCategory,
            )
          }
          toggleSelection={this.toggleSelection}
        />
      );

    const showTabs = this.categoryHasSubcategories(category);

    return (
      <div className={classes.options}>
        {showTabs && this.renderSubCategoryTabs()}
        <AutoSizer>
          {({ height, width }) => (
            <MenuList
              style={{
                width,
                height: showTabs ? height - 40 : height,
              }}
            >
              <List
                columns={columns}
                filter={filter}
                height={showTabs ? height - 40 : height}
                key={key}
                rowCount={this.categoryColumns().length}
                rowHeight={32}
                rowRenderer={this.renderOption}
                width={width}
              />
            </MenuList>
          )}
        </AutoSizer>
      </div>
    );
  };

  renderOption = ({ index, style }) => {
    const { classes } = this.props;
    const { columns } = this.state;

    const filteredColumns = this.getFilteredColumns();
    const column = filteredColumns[index];
    const selected = columns.some((colObj) => colObj.value === column.value);

    return (
      <Option
        classes={{
          root: classes.optionMenuItemRoot,
          selected: classes.optionMenuItemSelected,
          option: classes.option,
        }}
        column={column}
        key={column.value}
        onClick={() => this.toggleSelection(column)}
        selected={selected}
        style={style}
      />
    );
  };

  render() {
    const { classes, emptyWarning } = this.props;
    const { columns, filter } = this.state;

    const categoriesClasses = classnames(classes.categories, {
      [`${classes.categoriesCollapsed}`]: !!filter,
    });

    return (
      <div className={classes.root}>
        <div className={classes.selectPanel}>
          <div className={classes.searchContainer}>
            <CKSearch
              onChange={(filter) => this.setState({ filter })}
              placeholder="Find attribute"
              value={filter}
            />
          </div>
          <div className={classes.columnsContainer}>
            <div className={categoriesClasses}>{this.renderCategories()}</div>
            {this.renderOptions()}
          </div>
        </div>
        <div className={classes.displayPanel}>
          <CategorizedList
            categories={this.categoryObjects()}
            classes={{
              categoryHeaderRoot: classes.selectedCategoryHeaderRoot,
              optionMenuItemRoot: classes.selectedOptionMenuItemRoot,
              optionMenuItemSelected: classes.optionMenuItemSelected,
            }}
            columns={columns}
            defaultColumns={this._defaultColumns}
            EmptyResults={
              <EmptySelectedOptions
                emptyWarning={emptyWarning}
                resetToDefault={this.resetToDefault}
              />
            }
            removable
            resetToDefault={this.resetToDefault}
            selected={() => false}
            toggleSelection={this.toggleSelection}
          >
            {columns !== this._defaultColumns && (
              <>
                <div className={classes.resetDefault}>
                  <ButtonBase
                    className={classes.resetDefaultButton}
                    disableRipple
                    onClick={this.resetToDefault}
                  >
                    Reset to defaults
                  </ButtonBase>
                </div>
              </>
            )}
          </CategorizedList>
        </div>
      </div>
    );
  }
}

MultiPicklist.propTypes = {
  hubspotComingSoon: PropTypes.bool,
  allColumns: PropTypes.array.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    selectPanel: PropTypes.string.isRequired,
    searchContainer: PropTypes.string.isRequired,
  }).isRequired,
  emptyWarning: PropTypes.string,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      subCategory: PropTypes.string,
    }),
  ),
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

MultiPicklist.defaultProps = {
  hubspotComingSoon: false,
  allColumns: [],
  emptyWarning:
    "Without data, this destination won't function as expected. " +
    'Add some attributes from the list to the left.',
  value: [],
};

export default withStyles(styles, {
  name: 'AppMultiPicklist',
})(MultiPicklist);
