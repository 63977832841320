import { Job, Location, Seniority, User } from '@clearkit/icons';
import { CKBox } from 'clearkit';

import { FilterInputChips } from '../../Filters/FilterInputChips';
import { FilterMultiPicker } from '../../Filters/FilterMultiPicker';
import { useProspector } from '../useProspector';
import { FiltersPeople } from './types';

type FiltersPersonProps = {
  onChange: (value: FiltersPeople) => void;
};

export const SectionFiltersPeople = ({ onChange }: FiltersPersonProps) => {
  const [state] = useProspector();

  const filters = state.context.personQuery;

  const updateFilters = (key: keyof FiltersPeople) => (value: string[]) => {
    const newFilters = { ...filters, [key]: value };
    onChange(newFilters);
  };

  return (
    <div className="space-y-4">
      <h2 className="font-medium">People</h2>
      <CKBox className="p-4 divide-y space-y-3 divide-gray-200">
        <FilterMultiPicker
          canAddCustomValue
          items={state.context.prospectFilters?.title}
          onChange={updateFilters('titles')}
          value={filters.titles}
        >
          <FilterMultiPicker.Icon>
            <User />
          </FilterMultiPicker.Icon>
          <FilterMultiPicker.Title>Title Keywords</FilterMultiPicker.Title>
        </FilterMultiPicker>
        <FilterInputChips
          className="pt-3"
          onChange={updateFilters('names')}
          placeholder="Add first or last names"
          splitTokens={[',']}
          value={filters.names}
        >
          <FilterInputChips.Icon>
            <User />
          </FilterInputChips.Icon>
          <FilterInputChips.Title>Names</FilterInputChips.Title>
          <FilterInputChips.HelpText>
            Enter first or last name.
          </FilterInputChips.HelpText>
        </FilterInputChips>
        <FilterMultiPicker
          canAddCustomValue
          className="pt-3"
          items={state.context.prospectFilters?.role}
          onChange={updateFilters('roles')}
          value={filters.roles}
        >
          <FilterMultiPicker.Icon>
            <Job />
          </FilterMultiPicker.Icon>
          <FilterMultiPicker.Title>Roles</FilterMultiPicker.Title>
        </FilterMultiPicker>
        <FilterMultiPicker
          className="pt-3"
          hasFilter={false}
          items={state.context.prospectFilters?.seniority}
          onChange={updateFilters('seniorities')}
          value={filters.seniorities}
        >
          <FilterMultiPicker.Icon>
            <Seniority />
          </FilterMultiPicker.Icon>
          <FilterMultiPicker.Title>Seniorities</FilterMultiPicker.Title>
        </FilterMultiPicker>
        <FilterMultiPicker
          className="pt-3"
          items={state.context.prospectFilters?.countries}
          onChange={updateFilters('countries')}
          value={filters.countries}
        >
          <FilterMultiPicker.Icon>
            <Location />
          </FilterMultiPicker.Icon>
          <FilterMultiPicker.Title>Countries</FilterMultiPicker.Title>
        </FilterMultiPicker>
        <FilterInputChips
          className="pt-3"
          onChange={updateFilters('states')}
          placeholder="Add states, regions, or provinces"
          splitTokens={[',']}
          value={filters.states ?? []}
        >
          <FilterMultiPicker.Icon>
            <Location />
          </FilterMultiPicker.Icon>
          <FilterMultiPicker.Title>States / Regions</FilterMultiPicker.Title>
          <FilterInputChips.HelpText>
            Enter state, region, province (e.g., California or Ontario). Also
            accepts abbreviations (e.g., CA or ON).
          </FilterInputChips.HelpText>
        </FilterInputChips>
        <FilterInputChips
          className="pt-3"
          onChange={updateFilters('cities')}
          placeholder="Add cities"
          splitTokens={[',']}
          value={filters.cities ?? []}
        >
          <FilterMultiPicker.Icon>
            <Location />
          </FilterMultiPicker.Icon>
          <FilterMultiPicker.Title>Cities</FilterMultiPicker.Title>
        </FilterInputChips>
      </CKBox>
    </div>
  );
};
