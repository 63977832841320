import { EmptyStateLists } from '../ProspectList/EmptyStateLists';
import { SidebarLists } from '../Sidebar/SidebarLists';

export function Onboarding() {
  return (
    <>
      <SidebarLists />
      <EmptyStateLists />
    </>
  );
}
