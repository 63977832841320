import { ClearbitIdentity } from '@clearkit/icons';
import classNames from 'classnames';
import { CKLayoutVertical } from 'clearkit';
import { FC } from 'react';

export type AuthLayoutProps = {
  children: React.ReactNode;
};

const AuthLayout: FC<AuthLayoutProps> = function({ children }) {
  return (
    <CKLayoutVertical
      alignItems="center"
      className="w-full min-h-full bg-gray-0 ck-scrollbar ck-scrollbar--vertical"
      justifyContent="center"
    >
      <div className="flex items-center min-h-full py-6 max-w-[440px]">
        <CKLayoutVertical alignItems="center" gap="2rem">
          <a href="/">
            <ClearbitIdentity className="w-8 h-8" />
          </a>

          {children}
        </CKLayoutVertical>
      </div>
    </CKLayoutVertical>
  );
};

export default AuthLayout;

export type AuthLayoutHeaderProps = {
  header: React.ReactNode;
  description?: React.ReactNode;
  className?: string;
};

export function AuthLayoutHeader({
  className,
  header,
  description,
}: AuthLayoutHeaderProps) {
  return (
    <header className={classNames(className, 'text-center space-y-6')}>
      <h2 className="text-3xl">{header}</h2>
      {description && <p className="m-auto text-sm w-72">{description} </p>}
    </header>
  );
}

export function LayoutDivider({ text = 'or' }: { text?: string }) {
  return (
    <div className="flex items-center self-stretch gap-4">
      <div className="flex-grow h-px bg-gray-200"></div>
      <span className="text-xs font-medium text-gray-500 uppercase">
        {text}
      </span>
      <div className="flex-grow h-px bg-gray-200"></div>
    </div>
  );
}
