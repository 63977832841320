import ButtonBase from '@material-ui/core/ButtonBase';

import styles from './EmptyStates.style.js';
import { EmptyWarningIcon } from './icons/';

const EmptySelectedOptions = ({ classes, resetToDefault, emptyWarning }) => (
  <div className={classes.displayPanelEmpty}>
    <EmptyWarningIcon />
    <div className={classes.emptySelectionText}>
      You haven&apos;t selected any attributes!
      <p>{emptyWarning}</p>
      <ButtonBase
        className={classes.emptyResetDefaultButton}
        onClick={resetToDefault}
      >
        Reset to defaults
      </ButtonBase>
    </div>
  </div>
);

EmptySelectedOptions.propTypes = {
  classes: PropTypes.shape({
    displayPanelEmpty: PropTypes.string.isRequired,
    emptySelectionText: PropTypes.string.isRequired,
    emptyResetDefaultButton: PropTypes.string.isRequired,
  }),
  resetToDefault: PropTypes.func.isRequired,
};

export default withStyles(styles, {
  name: 'AppMultiPicklistEmptySelectedOptions',
})(EmptySelectedOptions);
