import { AccountFlagEnum } from '~/generated/graphql';
import { useFeatureFlag } from '~/hooks/account';
import { usePlatformAccess } from '~/hooks/account/usePlatformAccess';
import {
  alwaysAvailableOnPlatformTypes,
  clearbitCaptureTypes,
  conversionsDestinationTypes,
  operationsDestinationTypes,
} from '~/lib/destinations';

import { audienceSubTypesToShowAsDestinations } from './utils';

export default function useAvailableDestinations(segmentType) {
  const {
    acquisitions,
    capture,
    conversions,
    operations,
  } = usePlatformAccess();

  let destinations = alwaysAvailableOnPlatformTypes();

  const hasWebhookAccess = useFeatureFlag(
    AccountFlagEnum.AllowPlatformWebhookDestination,
  ).enabled;

  if (capture) {
    destinations = destinations.concat(clearbitCaptureTypes(segmentType));
  }

  if (operations || hasWebhookAccess) {
    destinations = destinations.concat(operationsDestinationTypes());
  }

  if (conversions || acquisitions) {
    destinations = destinations.concat(
      conversionsDestinationTypes(segmentType),
    );
  }

  if (acquisitions) {
    destinations = destinations.concat(
      audienceSubTypesToShowAsDestinations[segmentType],
    );
  }

  return destinations;
}
