import {
  destinationTypeSalesforce,
  destinationTypeSalesforceCreateAccount,
} from '~/lib/destinations';
import { readableSegmentType } from '~/lib/segments';

import styles from './styles';
import WarningIcon from './warning.svg';

const Notice = ({ classes, destinationType, segmentType }) => {
  switch (destinationType) {
    case destinationTypeSalesforce:
      return (
        <div className={classes.salesforceNotice}>
          <WarningIcon className={classes.icon} />
          This destination will only be triggered for{' '}
          {readableSegmentType(segmentType)} with associated Salesforce records
        </div>
      );
    case destinationTypeSalesforceCreateAccount:
      return (
        <div className={classes.createExternalRecordNotice}>
          <WarningIcon className={classes.icon} />
          We will only create a new account if the website domain doesn't exist
          in your Salesforce.
        </div>
      );
    default:
      return null;
  }
};

export default withStyles(styles)(Notice);
