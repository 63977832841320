import { CKTooltip } from 'clearkit';
import moment from 'moment';

const format = 'dddd, MMM D, YYYY, h:mm:ss A';

export default function TimeAgo({ time, ...props }) {
  const timeAgo = moment(time).fromNow();
  const title = moment(time).format(format);

  return (
    <CKTooltip
      className="whitespace-normal"
      tooltip={title}
      tooltipMaxWidth="22ch"
    >
      <time dateTime={time} {...props}>
        {timeAgo}
      </time>
    </CKTooltip>
  );
}

TimeAgo.propTypes = {
  time: PropTypes.string.isRequired,
};
