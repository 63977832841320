import { AddBlock } from '@clearkit/icons';
import castArray from 'lodash/castArray';
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import toString from 'lodash/toString';
import trim from 'lodash/trim';
import uniq from 'lodash/uniq';

import MultiValueRemove from '~/components/NestedMultiselect/components/MultiValueRemove';
import ValueContainer from '~/components/NestedMultiselect/components/ValueContainer';
import { SearchContext } from '~/components/SearchBuilder/utils/searchUtils';
import CreatableSelect from '~/components/Select/CreatableSelect';

const sanitizeStrings = (strings) => {
  let result = castArray(strings);
  result = flatten(result);
  result = result.map(toString).map(trim);
  result = uniq(result);
  result = compact(result);
  return result;
};

const valuesToSelectOptions = (values) => {
  values = sanitizeStrings(values);
  return compact(values).map((value) => ({ label: value, value }));
};

const selectOptionsToValues = (options) => {
  let values = options.map((s) => s.value);
  values = sanitizeStrings(values);
  return values;
};

class ValueMultiStringSelect extends React.Component {
  handleChange = (value, type) => {
    if (!value) value = [];
    const values = selectOptionsToValues(value);

    switch (type.action) {
      case 'create-option':
      case 'select-option':
      case 'deselect-option':
      case 'remove-value':
      case 'pop-value':
        this.props.onChange(values);
        break;
      default:
        break;
    }

    this.setState({ inputValue: '' });
  };

  render() {
    const { className, value = [], options = [] } = this.props;
    const { disabled } = this.context;

    return (
      <CreatableSelect
        className={className}
        formatCreateLabel={(inputValue) => (
          <span className="flex items-center">
            <AddBlock className="mr-2 text-blue-500" />
            <span className="leading-none ck-nudge-d-1">
              Add &ldquo;{inputValue}&rdquo;
            </span>
          </span>
        )}
        noOptionsMessage={() => 'Start typing to add options'}
        {...this.props}
        backspaceRemovesValue
        closeMenuOnSelect={false}
        components={{
          MultiValueRemove,
          ValueContainer,
        }}
        expandSection={this.expandSection}
        handleSelectOption={this.handleSelectOption}
        isClearable={false}
        isDisabled={disabled}
        isMulti
        isSearchable
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        options={valuesToSelectOptions(options)}
        placeholder="Type to add options"
        toggleSection={this.toggleSection}
        updateSuggestions={this.updateSuggestions}
        value={valuesToSelectOptions(value)}
      />
    );
  }
}

ValueMultiStringSelect.contextType = SearchContext;

ValueMultiStringSelect.propTypes = {
  className: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    ),
  ]),
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func,
};

ValueMultiStringSelect.defaultProps = {
  onChange: () => {},
  value: [],
};

export default ValueMultiStringSelect;
