import { Mutation, Query } from '@apollo/client/react/components';
import { Ellipsis as EllipsisIcon, Lock } from '@clearkit/icons';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import { CKButton } from 'clearkit';
import startCase from 'lodash/startCase';
import { Link } from 'react-router-dom';
import { Subscribe } from 'unstated';

import ConfirmDeleteModal from '~/components/ConfirmDeleteModal';
import MenuItem from '~/components/RedesignedMenuItem';
import Tooltip from '~/components/RedesignedTooltip';
import ToastsContainer from '~/containers/Toasts';
import UserContainer from '~/containers/User';
import paths from '~/lib/paths';
import {
  segmentActionLocations,
  trackSegmentCloned,
  trackSegmentDeleted,
} from '~/lib/segmentAnalytics';

import CloneIcon from './Clone.svg';
import cloneSegmentMutation from './cloneSegmentMutation.graphql';
import CsvExportDialog from './CsvExportDialog';
import DeleteIcon from './Delete.svg';
import deleteSegmentMutation from './deleteSegmentMutation.graphql';
import { ExportCsvMenuItem } from './ExportCsvMenuItem';
import ImportIcon from './Import.svg';
import RefreshIcon from './Refresh.svg';
import segmentActionsDeleteQuery from './segmentActionsDeleteQuery.graphql';

class SegmentActionsMenu extends React.Component {
  constructor(props) {
    super(props);
    this.anchorRef = React.createRef();
  }

  state = {
    open: false,
    confirmDeleteOpen: false,
    exportCsvOpen: false,
  };

  showMenu = (event) => {
    event.preventDefault();

    this.setState({ open: true });
  };

  closeMenu = () => {
    this.setState({
      open: false,
    });
  };

  handleRefresh = () => {
    this.props.onRefresh();

    this.setState({ open: false });
  };

  handleDeleteComplete = () => {
    const { onDelete, segment, location } = this.props;

    trackSegmentDeleted(segment, location);
    ToastsContainer.addSuccess('Audience deleted');

    return this.setState({ open: false }, () => onDelete && onDelete());
  };

  handleCloneComplete = ({ response: segment }) => {
    const { onClone, location, segment: clonedSegment } = this.props;

    trackSegmentCloned(segment, clonedSegment, location);
    ToastsContainer.addSuccess('Audience cloned');

    return this.setState({ open: false }, () => onClone(segment.node));
  };

  exportCsvDialog = () => {
    this.setState({ exportCsvOpen: true, open: false });
  };

  closeExportCsvDialog = () => {
    this.setState({ exportCsvOpen: false });
  };

  renderImportCsvMenuItem = () => {
    const { type } = this.props;

    return (
      <Subscribe to={[UserContainer]}>
        {(userContainer) => {
          const objectAction = 'export';
          const objectType = 'csv';
          const hasPermission = userContainer.hasPermissionTo({
            objectAction,
            objectType,
          });

          if (hasPermission) {
            return (
              <MenuItem
                component={Link}
                key="import"
                large
                onClick={this.importCsv}
                to={paths.importCsv(type)}
              >
                <ListItemIcon>
                  <ImportIcon />
                </ListItemIcon>
                Import {type === 'Person' ? 'people' : 'companies'} from CSV...
              </MenuItem>
            );
          }

          return (
            <Tooltip
              key="import"
              placement="left"
              style={{ cursor: 'pointer' }}
              title={
                "You don't have permission to access this feature. Contact your team Admin for access."
              }
            >
              <div>
                <MenuItem disabled key="import" large>
                  <ListItemIcon>
                    <Lock />
                  </ListItemIcon>
                  Import {type === 'Person' ? 'people' : 'companies'} from
                  CSV...
                </MenuItem>
              </div>
            </Tooltip>
          );
        }}
      </Subscribe>
    );
  };

  renderCloneSegmentMenuItem = () => {
    const { segment, type } = this.props;

    return (
      <Mutation
        key="clone"
        mutation={cloneSegmentMutation}
        onCompleted={this.handleCloneComplete}
        refetchQueries={['SegmentsMenu']}
        variables={{ input: { segmentId: segment.id, type } }}
      >
        {(cloneSegment, { _loading }) => (
          <MenuItem large onClick={cloneSegment} variant="primary">
            <ListItemIcon>
              <CloneIcon />
            </ListItemIcon>
            Clone Audience
          </MenuItem>
        )}
      </Mutation>
    );
  };

  renderRefreshSegmentMenuItem = () => {
    // Don't render this in the Segment list menus
    if (!this.props.onRefresh) {
      return null;
    }

    return (
      <MenuItem
        disabled={this.props.segment?.searchDependencyErrors}
        large
        onClick={this.handleRefresh}
        variant="primary"
      >
        <ListItemIcon>
          <RefreshIcon />
        </ListItemIcon>
        Refresh Audience
      </MenuItem>
    );
  };

  renderDeleteSegmentMenuItem = () => (
    <MenuItem
      key="delete"
      onClick={() => this.setState({ open: false, confirmDeleteOpen: true })}
      variant="destructive"
    >
      <ListItemIcon>
        <DeleteIcon />
      </ListItemIcon>
      Delete Audience
    </MenuItem>
  );

  render() {
    const { exportCsv, importCsv, segment, searchQuery, type } = this.props;
    const { confirmDeleteOpen, exportCsvOpen, open } = this.state;

    const buttonComponent = this.props.buttonComponent || (
      <CKButton className="flex w-12">
        <EllipsisIcon height={32} />
      </CKButton>
    );

    const button = React.cloneElement(buttonComponent, {
      onClick: this.showMenu,
    });

    return (
      <span ref={this.anchorRef}>
        {button}

        <Menu
          anchorEl={this.anchorRef?.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          getContentAnchorEl={null}
          onClose={this.closeMenu}
          open={open}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {importCsv && this.renderImportCsvMenuItem()}

          {exportCsv ? (
            <ExportCsvMenuItem
              key="export"
              onClick={this.exportCsvDialog}
              segment={segment}
              type={type}
            />
          ) : null}

          {segment
            ? [
                this.renderCloneSegmentMenuItem(),
                <Divider key="divider-1" />,
                this.renderRefreshSegmentMenuItem(),
                <Divider key="divider-2" />,
                this.renderDeleteSegmentMenuItem(),
              ]
            : null}
        </Menu>

        {confirmDeleteOpen && (
          <Query
            query={segmentActionsDeleteQuery}
            variables={{ segmentId: segment.id, segmentType: segment.type }}
          >
            {({ loading, error, data }) => {
              if (error || loading) return null;

              const {
                segment: { destinations },
              } = data;

              const destinationsForDelete = destinations.map(
                (destination) =>
                  `${startCase(destination.type)} - ${destination.description}`,
              );

              return (
                <Mutation
                  mutation={deleteSegmentMutation}
                  onCompleted={this.handleDeleteComplete}
                  refetchQueries={['SegmentsMenu', 'SegmentActivity']}
                  variables={{
                    input: {
                      id: segment.id,
                      type,
                    },
                  }}
                >
                  {(deleteSegment, { _loading }) => (
                    <ConfirmDeleteModal
                      associatedItemNames={destinationsForDelete}
                      canDelete
                      closeModal={() =>
                        this.setState({ confirmDeleteOpen: false })
                      }
                      deleteItem={deleteSegment}
                      open
                      subTitle="You won’t be able to recover this audience once it’s been deleted."
                      type="segment"
                    />
                  )}
                </Mutation>
              );
            }}
          </Query>
        )}

        {exportCsvOpen ? (
          <CsvExportDialog
            onClose={this.closeExportCsvDialog}
            open={exportCsvOpen}
            searchQuery={searchQuery}
            segment={segment}
            type={type}
          />
        ) : null}
      </span>
    );
  }
}

SegmentActionsMenu.propTypes = {
  onRefresh: PropTypes.func,
  exportCsv: PropTypes.bool,
  importCSV: PropTypes.bool,
  onClone: PropTypes.func,
  onDelete: PropTypes.func,
  buttonComponent: PropTypes.node,
  segment: PropTypes.shape({
    __typename: PropTypes.string,
    node: PropTypes.string,
    id: PropTypes.string,
  }),
  searchQuery: PropTypes.string,
  type: PropTypes.oneOf(['Company', 'Person']),
  location: PropTypes.oneOf(Object.values(segmentActionLocations)).isRequired,
};

SegmentActionsMenu.defaultProps = {
  exportCsv: false,
  importCsv: false,
  open: false,
};

export default SegmentActionsMenu;
