const styles = () => ({
  break: {
    margin: '20px -30px 30px -30px',
  },

  subheading: {
    color: '#596168',
    flex: '0 0 auto',
    fontSize: 15,
    fontWeight: 500,
    margin: '0 0 7px',
  },

  triggerSubtext: {
    color: '#596168',
    fontSize: 14,
  },

  testUrl: {
    display: 'block',
    margin: '8px 0',
    color: '#2b9ffd',
    fontWeight: '500',
  },
});

export default styles;
