import { CKIconBox, CKMeter, CKMeterProps } from 'clearkit';

import { MeterCompleteCircle } from './MeterCompleteCircle';
import { MeterErrorCircle } from './MeterErrorCircle';

type MeterBoxProps = CKMeterProps & {
  isComplete?: boolean;
  isError?: boolean;
};

export const MeterBox = ({
  className,
  isComplete,
  isError,
  max,
  size = 30,
  value,
  variant = 'x',
  ...rest
}: MeterBoxProps) => {
  return (
    <CKIconBox className={className}>
      {isComplete ? (
        <MeterCompleteCircle />
      ) : isError ? (
        <MeterErrorCircle />
      ) : (
        <CKMeter
          {...rest}
          max={max}
          size={size}
          value={value}
          variant={variant}
        />
      )}
    </CKIconBox>
  );
};
