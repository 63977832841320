const styles = () => ({
  root: {
    height: 58,
  },

  eventPersonName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  personAvatar: {
    marginRight: 10,
  },
});

export default styles;
