export const THIRD_PARTY_KEY_PREFIXES = ['hubspot_', 'salesforce_', 'marketo_'];

// Salesforce contact can be used as primary object in the case of selective enrichment but is used
// as a dependent object for audience builder
export const PRIMARY_SALESFORCE_KEYS = [
  'salesforce_account',
  'salesforce_lead',
  'salesforce_contact',
];

export const PRIMARY_MARKETO_KEYS = ['marketo_lead'];
export const DEPENDENT_MARKETO_KEYS = ['marketo_lead'];

export const PRIMARY_HUBSPOT_KEYS = ['hubspot_company', 'hubspot_contact'];
export const DEPENDENT_HUBSPOT_KEYS = ['hubspot_deal', 'hubspot_contact'];

export const DEPENDENT_SALESFORCE_KEYS = [
  'salesforce_opportunity',
  'salesforce_contact',
];

export const SALESFORCE_RESOURCE_TYPE = {
  LEAD: {
    objectName: 'salesforce_lead',
    label: 'lead',
  },
  ACCOUNT: {
    objectName: 'salesforce_account',
    label: 'account',
  },
  CONTACT: {
    objectName: 'salesforce_contact',
    label: 'contact',
  },
};

export const SALESFORCE_SUB_CATEGORIES = {
  Company: ['account', 'opportunity'],
  Person: ['account', 'contact', 'lead'],
};

export const HUBSPOT_SUB_CATEGORIES = {
  Company: ['company', 'deal'],
  Person: ['company', 'contact'],
};

export const MARKETO_SUB_CATEGORIES = {
  Company: ['lead'],
  Person: ['lead'],
};

export const NESTABLE_NODE_KEYS = ['hubspot_contact'];

export const EMPTY_STATE_SEARCH = {
  and: [{ and: [{ match: { attribute: '' } }] }],
  version: 2,
};

export const DEFAULT_QUERY_PART = { match: { attribute: '' } };

export const DEFAULT_GROUP = { and: [DEFAULT_QUERY_PART] };

export const DEFAULT_EVENT_PART = {
  event: {
    path: '',
    count: 1,
    timeframe: 86400,
    type: 'page',
    match: 'all',
  },
};

export const emptyNode = {
  match: {
    attribute: undefined,
    value: undefined,
  },
};

export const MAXIMUM_NEST_LEVEL = 3;
