const ACTIONS = {
  CSV_IMPORT: 'csv_import',
  CSV_EXPORT: 'csv_export',
  DESTINATION_SALESFORCE_CREATE: 'destination_salesforce_create',
  DESTINATION_SALESFORCE_UPDATE: 'destination_salesforce_update',
  DESTINATION_WEBHOOK_CREATE: 'destination_webhook_create',
  DESTINATION_WEBHOOK_UPDATE: 'destination_webhook_update',
  ENRICHMENT_SALESFORCE_UPDATE: 'enrichment_salesforce_update',
};

const PERMISSIONS = {
  ACQUISITIONS: 'acquisitions',
  CONVERSIONS: 'conversions',
  OPERATIONS: 'operations',
};

let requirements = {};
requirements[ACTIONS.CSV_IMPORT] = [PERMISSIONS.OPERATIONS];
requirements[ACTIONS.CSV_EXPORT] = [PERMISSIONS.OPERATIONS];

requirements[ACTIONS.DESTINATION_SALESFORCE_CREATE] = [PERMISSIONS.OPERATIONS];
requirements[ACTIONS.DESTINATION_SALESFORCE_UPDATE] = [PERMISSIONS.OPERATIONS];

requirements[ACTIONS.DESTINATION_WEBHOOK_CREATE] = [PERMISSIONS.OPERATIONS];
requirements[ACTIONS.DESTINATION_WEBHOOK_UPDATE] = [PERMISSIONS.OPERATIONS];

requirements[ACTIONS.ENRICHMENT_SALESFORCE_UPDATE] = [PERMISSIONS.OPERATIONS];

const HasRequiredPermissions = ({ action, permissions }) => {
  if (permissions == undefined) {
    return false;
  }

  const requiredPermissions = requirements[action];

  const meetsPermissionRequirement = (requiredPermission) => {
    return permissions?.[requiredPermission] == true;
  };

  // NOTE: If there are any requiredPermissions, ensure the user has at least
  // one of the required permissions.
  if (requiredPermissions) {
    return requiredPermissions.some(meetsPermissionRequirement);
  }

  // NOTE: There are no requiredPermissions, so users have access by default.
  return true;
};

export { HasRequiredPermissions, ACTIONS, PERMISSIONS };
