import { CKButton } from 'clearkit';

import { authConnector } from '~/lib/client';
import {
  connectorLocations,
  trackAuthStartedAccountConnector,
} from '~/lib/segmentAnalytics';

import SlackNotification from './SlackNotification.svg';
import styles from './styles';

class SlackConnectorStatus extends React.Component {
  auth = () => {
    const { onSuccess } = this.props;
    const connectorType = 'slack';

    trackAuthStartedAccountConnector(
      connectorType,
      connectorLocations.destination,
    );

    authConnector(connectorType, () => onSuccess());
  };

  renderHeading = (connected) => {
    const { classes, accountConnector } = this.props;

    if (connected) {
      const {
        remoteMeta: {
          info: { team },
        },
      } = accountConnector;
      return (
        <span className={classes.heading}>
          Clearbit is connected to your {team} Slack organization
        </span>
      );
    }

    return (
      <span className={classes.heading}>
        Your Clearbit account is not connected to Slack.
      </span>
    );
  };

  renderSubheading = (connected) => {
    const { classes, webhookUrl } = this.props;

    if (connected) {
      return (
        <span className={classes.subheading}>
          Clearbit will continue to send messages to{' '}
          <span className={classes.legacyWebhookUrl}>{webhookUrl}</span> until
          you choose a Slack channel you’d like messages posted to.
        </span>
      );
    }

    return (
      <span className={classes.subheading}>
        Slack destinations are moving from webhooks to using
        <br />
        the Slack API, and require authentication to your Slack organization.
      </span>
    );
  };

  render() {
    const { accountConnector, classes } = this.props;
    const connected = accountConnector && accountConnector.status === 'active';

    return (
      <div className={classnames(classes.root)}>
        <SlackNotification style={{ marginRight: -8 }} width={32} />
        <div className={classnames(classes.textContainer)}>
          {this.renderHeading(connected)}
          {this.renderSubheading(connected)}
        </div>
        <div className={classes.actions}>
          {!connected && (
            <CKButton onClick={this.auth} variant="bold" variantColor="blue">
              <span>Connect Slack</span>
            </CKButton>
          )}
        </div>
      </div>
    );
  }
}

SlackConnectorStatus.propTypes = {
  onSuccess: PropTypes.func,
  classes: PropTypes.object,
  webhookUrl: PropTypes.string,
  accountConnector: PropTypes.shape({
    status: PropTypes.string,
    remoteMeta: PropTypes.shape({
      info: PropTypes.shape({
        team: PropTypes.string,
      }),
    }),
  }),
};

export default withStyles(styles, {
  name: 'AppDestinationSlackConnectorStatus',
})(SlackConnectorStatus);
