const styles = () => ({
  menuList: {
    listStyleType: 'none',
  },

  sectionTitle: {
    fontSize: 14,
    fontWeight: 500,
    color: '#596168',
    marginLeft: 8,
  },

  optionLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexGrow: 1,
  },

  checkboxRoot: {
    padding: 0,
    marginRight: 0,
    paddingRight: 7,
    marginBottom: 0,
  },

  caretWrapper: {
    cursor: 'unset',
    opacity: 0,
    marginRight: 11,
    position: 'relative',
    bottom: '2px',
  },

  caret: {
    transition: 'ease transform 0.1s',
    transform: 'rotate(-90deg)',
  },

  rotated: {
    transform: 'rotate(0deg)',
  },

  leafNode: {
    paddingLeft: 21,
  },

  showCaret: {
    opacity: 1,
    cursor: 'pointer',
  },

  activeSuggestion: {
    backgroundColor: '#fafbfc',
  },
});

export default styles;
