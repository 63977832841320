import {
  FacebookServices as FacebookIcon,
  GoogleServices as GoogleIcon,
} from '@clearkit/icons';

import { audienceServices } from '~/lib/audiences';

const icons = {
  [audienceServices.facebook]: FacebookIcon,
  [audienceServices.google]: GoogleIcon,
};

export default function AudienceServiceIcon({ audience, className, size }) {
  const Icon = icons[audience.service];

  if (!Icon) {
    return null;
  }

  return <Icon className={className} style={{ height: size, width: size }} />;
}

AudienceServiceIcon.propTypes = {
  audience: PropTypes.object.isRequired,
  className: PropTypes.string,
  size: PropTypes.number,
};
