import classnames from 'classnames';
import {
  CKContainerProps,
  CKLayoutGrid,
  CKLayoutGridProps,
  CKLayoutHorizontal,
  cloneChildren,
  excludeChildrenByDisplayName,
  includeChildrenByDisplayName,
} from 'clearkit';
import { FC, forwardRef, Ref } from 'react';
import Helmet from 'react-helmet';

import { BreadcrumbHeading, BreadcrumbProps } from './BreadcrumbHeading';

type PageLayoutProps = CKLayoutGridProps & {
  title?: string;
};

type TileProps = CKContainerProps & {
  variant?: 'blue' | 'clear' | 'green' | 'red' | 'purple' | 'gray';
};

type PageLayoutBodyProps = CKContainerProps & {
  ref?: Ref<HTMLDivElement>;
};

export type PageLayoutComposition = {
  Actions: FC<CKContainerProps>;
  Body: FC<PageLayoutBodyProps>;
  Breadcrumb: FC<BreadcrumbProps>;
  Content: FC<CKContainerProps>;
  Header: FC<CKContainerProps>;
  HeaderWrapper: FC<CKContainerProps>;
  Heading: FC<CKContainerProps>;
  SubHeading: FC<CKContainerProps>;
  Footer: FC<CKContainerProps>;
  Tile: FC<TileProps>;
};

export const PageLayout: FC<PageLayoutProps> & PageLayoutComposition = ({
  className,
  children,
  gridTemplateColumns = 'auto',
  gridTemplateRows = 'auto 1fr auto',
  gap = '0',
  title,
  ...rest
}) => (
  <>
    {title ? (
      <Helmet>
        <title>{title}</title>
      </Helmet>
    ) : null}
    <CKLayoutGrid
      {...rest}
      className={classnames('h-full w-full', className)}
      gap={gap}
      gridTemplateColumns={gridTemplateColumns}
      gridTemplateRows={gridTemplateRows}
    >
      {children}
    </CKLayoutGrid>
  </>
);

PageLayout.Actions = (props) => <div {...props} />;
PageLayout.Actions.displayName = 'PageLayout.Actions';

PageLayout.Breadcrumb = (props) => <BreadcrumbHeading {...props} />;
PageLayout.Breadcrumb.displayName = 'PageLayout.Breadcrumb';

PageLayout.Body = forwardRef(
  ({ className, ...rest }, ref: Ref<HTMLDivElement>) => (
    <div
      {...rest}
      className={classnames(
        'bg-gray-0 ck-scrollbar ck-scrollbar--vertical',
        className,
      )}
      ref={ref}
    />
  ),
);
PageLayout.Body.displayName = 'PageLayout.Body';

PageLayout.Content = ({ className, ...rest }) => (
  <div
    className={classnames('xl:p-10 p-6 min-h-0 min-w-0', className)}
    {...rest}
  />
);
PageLayout.Content.displayName = 'PageLayout.Content';

PageLayout.Footer = ({ className, ...rest }) => (
  <footer
    className={classnames('xl:px-10 p-6 border-t border-gray-200', className)}
    {...rest}
  />
);
PageLayout.Footer.displayName = 'PageLayout.Footer';

PageLayout.Header = ({ children, ...rest }) => (
  <PageLayout.HeaderWrapper {...rest}>
    <CKLayoutHorizontal alignItems="start" className="mb-8">
      {includeChildrenByDisplayName({
        children,
        componentDisplayName: 'PageLayout.Tile',
      })}
      <CKLayoutHorizontal className="grow">
        <div>
          {includeChildrenByDisplayName({
            children,
            componentDisplayName: [
              'PageLayout.Heading',
              'PageLayout.SubHeading',
              'PageLayout.Breadcrumb',
            ],
          })}
        </div>
        {includeChildrenByDisplayName({
          children,
          componentDisplayName: ['PageLayout.Actions'],
        })}
      </CKLayoutHorizontal>
    </CKLayoutHorizontal>
    {excludeChildrenByDisplayName({
      children,
      componentDisplayName: [
        'PageLayout.Actions',
        'PageLayout.Breadcrumb',
        'PageLayout.Heading',
        'PageLayout.SubHeading',
        'PageLayout.Tile',
      ],
    })}
  </PageLayout.HeaderWrapper>
);
PageLayout.Header.displayName = 'PageLayout.Header';

PageLayout.HeaderWrapper = ({ children, className, ...rest }) => (
  <header
    className={classnames(
      'xl:px-10 p-6 pb-0 border-b border-gray-200',
      className,
    )}
    {...rest}
  >
    {children}
  </header>
);
PageLayout.HeaderWrapper.displayName = 'PageLayout.HeaderWrapper';

PageLayout.Heading = ({ children, className, ...rest }) => (
  <h1 className={classnames('font-semibold text-2xl', className)} {...rest}>
    {children}
  </h1>
);
PageLayout.Heading.displayName = 'PageLayout.Heading';

PageLayout.SubHeading = ({ children, className, ...rest }) => (
  <h2 className={classnames('text-base text-gray-600', className)} {...rest}>
    {children}
  </h2>
);
PageLayout.SubHeading.displayName = 'PageLayout.SubHeading';

PageLayout.Tile = ({ children, className, variant = 'blue', ...rest }) => (
  <div
    className={classnames(
      'h-[3.125rem] w-[3.125rem] rounded-lg flex shrink-0 items-center justify-center border',
      className,
      {
        [`border-${variant}-100`]: variant !== 'clear',
        [`bg-${variant}-0`]: variant !== 'clear',
      },
    )}
    {...rest}
  >
    {cloneChildren({
      children,
      newProps: {
        className: `h-6 w-6 fill-gradient-br-${variant}`,
      },
    })}
  </div>
);
PageLayout.Tile.displayName = 'PageLayout.Tile';
