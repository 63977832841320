export default () => ({
  root: {
    border: '1px solid transparent',
    color: '#596168',

    '& a': {
      color: '#596168',
    },

    '&:hover, &:focus, &.focused': {
      backgroundColor: '#FAFBFC',
      borderColor: '#E1E9F0',
      fontWeight: 500,
    },

    '&.selected': {
      color: '#148CFC',
      backgroundColor: 'rgb(239, 246, 255)',
      borderColor: '#98D5FD',
      fontWeight: 500,

      '& a': {
        color: '#148CFC',
      },
    },
  },
});
