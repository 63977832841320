import partition from 'lodash/partition';
import round from 'lodash/round';
import sortBy from 'lodash/sortBy';
import moment from 'moment';

import {
  BAD_THRESHOLD,
  GOOD_THRESHOLD,
  GREAT_THRESHOLD,
  TERRIBLE_THRESHOLD,
} from '../constants';
import { AttributeVariant, FitLabel, FitVariant } from './types';

export function formatTimestamp(timestamp: string) {
  return moment(timestamp, moment.ISO_8601).format('MMM D, YYYY h:mma');
}

export function getAttributeVariantFromDifferential(value: number) {
  if (value > GREAT_THRESHOLD) {
    return 'movedUp';
  } else if (value > GOOD_THRESHOLD) {
    return 'up';
  } else if (value < TERRIBLE_THRESHOLD) {
    return 'movedDown';
  } else if (value < BAD_THRESHOLD) {
    return 'down';
  }

  return null;
}

export function getColorClassNamesFromAttributeVariant(
  variant?: AttributeVariant,
) {
  if (variant === 'up') {
    return 'text-green-500 bg-green-100 border border-green-200';
  } else if (variant === 'movedUp') {
    return 'text-white bg-green';
  } else if (variant === 'down') {
    return 'text-red-900 bg-red-100';
  } else if (variant === 'movedDown') {
    return 'text-white bg-red';
  } else {
    return 'text-gray-900 bg-gray-100';
  }
}

export function getFitVariantFromFitScore(value?: FitLabel): FitVariant {
  if (!value) {
    return null;
  }

  if (value === 'A' || value === 'very_good' || value === 'good') {
    return 'good';
  } else if (value === 'B' || value === 'average') {
    return 'average';
  } else if (value === 'C' || value === 'poor') {
    return 'poor';
  } else {
    return 'average';
  }
}

type FitVariantColorClassNamesOptions = {
  muted?: boolean;
};
export function getColorClassNamesFromFitVariant(
  variant?: FitVariant,
  _options?: FitVariantColorClassNamesOptions,
) {
  if (variant === 'great' || variant === 'good') {
    return 'text-green-500 bg-green-100 border border-green-200 font-medium';
  } else if (variant === 'poor') {
    return 'bg-gray-100 text-gray-500 border border-gray-300 font-medium';
  } else {
    return 'bg-blue-100 text-blue-400 border border-blue-200 font-medium';
  }
}

export function getDescriptionFromFitVariant(variant?: FitVariant) {
  if (variant === 'great' || variant === 'good') {
    return 'A';
  } else if (variant === 'average') {
    return 'B';
  } else if (variant === 'poor') {
    return 'C';
  } else {
    return '';
  }
}

type PartitionOptions = {
  sort?: boolean;
};
export function partitionAttributesByDifferential(
  attributes: any,
  options?: PartitionOptions,
) {
  const [negative, positive] = partition(
    attributes,
    (value) => value.differential < BAD_THRESHOLD,
  );

  if (options?.sort) {
    return [
      sortBy(negative, (value) => value.differential),
      sortBy(positive, (value) => value.differential).reverse(),
    ];
  }

  return [negative, positive];
}

export function calculateLikelihoodPercentageFromDifferential(
  differential: number,
) {
  if (differential < BAD_THRESHOLD) {
    return toPercentage(1 - differential);
  }

  return toPercentage(differential - 1);
}

function toPercentage(value: number) {
  return `${round(value * 100, 2)}%`;
}
