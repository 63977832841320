const categoryHeaderRoot = {
  height: 24,
  color: '#9badbc',
  backgroundColor: 'white',
  fontSize: 14,
  fontWeight: 500,
  border: 'none',
  opacity: 1,
  padding: 0,
  flex: '0 0 auto',
  '&:hover': {
    backgroundColor: 'unset',
  },
  '&:first-of-type': {
    borderRadius: '7px 7px 0 0',
  },
  '& svg': {
    margin: '0 12px 0 16px',
  },
};

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flex: '1 1 auto',
    justifyContent: 'space-between',
    overflow: 'hidden',
    margin: '24px 0',
  },

  selectPanel: {
    height: 370,
    width: '50%',
    marginRight: 20,
    backgroundColor: 'white',
    border: '1px solid rgb(225, 233, 240)',
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
    borderRadius: 7,
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',
    overflow: 'hidden',
  },

  searchContainer: {
    padding: 8,
    borderBottom: '1px solid rgb(225, 233, 240)',
  },

  columnsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: '1 1 auto',
    backgroundColor: 'white',
    height: '100%',
    borderRadius: '0 0 7px 7px',
  },

  categories: {
    flex: '0 1 auto',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
    borderRight: '1px solid rgb(225, 233, 240)',
    width: 135,
    transition: 'width 150ms cubic-bezier(0.4, 0, 0.2, 1)',
  },

  categoriesCollapsed: {
    width: 0,
    borderRight: 'none',
  },

  categoriesTabsRoot: {
    minHeight: 40,
    height: 40,
  },

  categoriesTabsContainer: {
    flex: 'none',
  },

  categoryTabRoot: {
    minWidth: 'unset',
    flex: '1 0 auto',
    minHeight: 40,
    height: 40,
    padding: 0,
  },

  categoryTabLabel: {
    fontSize: 14,
  },

  categoryMenuItemRoot: {
    height: 24,
    padding: '6px 6px 6px 17px',
    border: 'none',
    color: 'rgb(89, 97, 104)',
    fontSize: 14,
    fontWeight: 500,
    '&:hover, &$categoryMenuItemSelected': {
      backgroundColor: '#fafbfc',
      '&:hover': {
        backgroundColor: '#fafbfc',
      },
    },
    '& svg': {
      marginRight: 6,
    },
  },

  categoryMenuItemComingSoon: {
    opacity: 0.6,
  },

  categoryMenuItemSelected: {},

  categoryComingSoonMessage: {
    display: 'flex',
    flex: '1 0 auto',
    maxWidth: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  options: {
    display: 'flex',
    flex: '1 0 auto',
    flexDirection: 'column',
    maxWidth: '100%',
  },

  categoryHeaderRoot: {
    ...categoryHeaderRoot,
  },

  searchMenuItemRoot: {
    padding: 0,
    border: 'none',
    '&:hover': {
      backgroundColor: '#f3f9fe',
    },
    '&$optionMenuItemSelected': {
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: '#fdf4f2',
        color: '#cf371e',
      },
    },

    '& div': {
      padding: '0px 16px 0px 44px',
    },

    '& #addIcon': {
      display: 'none',
    },
    '&:hover #addIcon': {
      display: 'flex',
    },
  },

  optionMenuItemRoot: {
    backgroundColor: 'white',
    padding: 0,
    border: 'none',
    '&:hover': {
      backgroundColor: '#f3f9fe',
    },

    '& #addIcon': {
      display: 'none',
    },
    '&:hover #addIcon': {
      display: 'flex',
    },

    '&$optionMenuItemSelected': {
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: '#fdf4f2',
      },
    },
  },

  optionMenuItemSelected: {
    backgroundColor: 'white',
    color: '#9badbc',
    '&:focus': {
      backgroundColor: '#fdf4f2',
    },
    '&:hover #addIcon': {
      display: 'none',
    },
  },

  optionMenuItemFocused: {
    backgroundColor: '#f3f9fe',
    '& #addIcon': {
      display: 'flex',
    },
  },

  displayPanel: {
    height: 370,
    width: '50%',
    backgroundColor: 'white',
    border: '1px solid rgb(225, 233, 240)',
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
    borderRadius: '7px 7px 7px 7px',
    overflowY: 'scroll',
    position: 'relative',
  },

  selectedCategoryHeaderRoot: {
    ...categoryHeaderRoot,
    color: '#596168',
  },

  selectedOptionMenuItemRoot: {
    padding: 0,
    border: 'none',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#fdf4f2',
      color: '#cf371e',
      '& svg': {
        fill: '#cf371e',
      },
    },
    '& svg': {
      fill: '#9badbc',
    },
  },

  resetDefault: {
    height: 50,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderRadius: '0 0 7px 7px',
    borderTop: '1px solid #e1e9f0',
  },

  resetDefaultButton: {
    color: '#3386ee',
    marginRight: 16,
    fontSize: 13,
    fontFamily: theme.typography.fontFamily,
  },
});

export default styles;
