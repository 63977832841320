import { Info } from '@clearkit/icons';
import { CKBannerNext } from 'clearkit';

import { useAccountSettings } from '~/components/api/useAccountSettings';

export const SuppressionBanner = () => {
  const { suppressionSettings } = useAccountSettings();
  const hasGDPRSuppression = suppressionSettings?.prospector;

  return hasGDPRSuppression ? (
    <div className="px-6 pt-6">
      <CKBannerNext>
        <CKBannerNext.Icon>
          <Info className="fill-gradient-br-blue" />
        </CKBannerNext.Icon>
        <CKBannerNext.Header>Suppression Settings Enabled</CKBannerNext.Header>
        <CKBannerNext.Body className="text-gray-500">
          In compliance with GDPR and data privacy settings, contacts located in
          the EU (or with locations unknown) will not be returned in Prospector
          results.
        </CKBannerNext.Body>
      </CKBannerNext>
    </div>
  ) : null;
};
