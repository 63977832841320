import moment from 'moment';

export const ExportSalesPipeline = ({ snapshots }) => {
  return (
    <div>
      {snapshots?.map((snapshot) => {
        return (
          <a
            className="flex items-center px-10 py-4 bg-white border-b border-gray-200 space-x-8"
            href={snapshot.exportProxyUrl}
            key={snapshot.date}
          >
            {moment(snapshot.date).format('MMM Do YYYY')}
          </a>
        );
      })}
    </div>
  );
};

export default ExportSalesPipeline;
