import { CKButton } from 'clearkit';
import { Link } from 'react-router-dom';

import Logo from './404.svg';
import styles from './styles.js';

function MissingPage({ classes, className }) {
  return (
    <div className={classnames(className, classes.root)}>
      <Logo />
      <p className={classes.title}>Sorry, we're unable to find that page.</p>

      <p>
        We couldn't find any content at this address. If you're sure it should
        exist,{' '}
        <a href="mailto:support@clearbit.com">contact Clearbit support</a>.
      </p>

      <CKButton as={Link} to="/" variant="simple" variantColor="blue">
        Go to Home
      </CKButton>
    </div>
  );
}

MissingPage.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
};

export default withStyles(styles)(MissingPage);
