import { Route, Switch } from 'react-router-dom';
import { Transition, TransitionGroup } from 'react-transition-group';

import SidebarDrawer from '~/components/SidebarDrawer';
import { useEntitlements } from '~/hooks/account';
import history from '~/lib/history';
import paths, { SegmentRootPathKey } from '~/lib/paths';
import { segmentTypes } from '~/lib/segments';
import CompanyFromDomain from '~/pages/companies/CompanyFromDomain';
import Company from '~/pages/companies/Show';
import EnrichmentLog from '~/pages/enrichment/LogsShow';
import Person from '~/pages/people/Show';

import ErrorHandler from '../errorHandler';
import {
  AnyStringRegex,
  concatEnrichmentTypes,
  routes,
  UUIDRegex,
} from './appMainRoutes';
import { GatedRouteEntitlement } from './GatedRouteEntitlement';

export const RoutesSidebar = () => {
  const {
    hasAccessCompanies,
    hasAccessPeople,
    hasAccessEnrichment,
  } = useEntitlements();
  return (
    <Switch>
      <Route
        path={[`${routes.visitorsDetails}/:domain(${AnyStringRegex})`]}
        render={({
          match: {
            params: { domain },
          },
        }) => (
          <SidebarDrawer isVisible onToggle={() => history.goBack()} size="xxl">
            <CompanyFromDomain domain={domain} />
          </SidebarDrawer>
        )}
      />
      <Route
        path={[
          `/companies/segments/:segmentId/:id(${UUIDRegex})`,
          `/companies/segments/:segmentId/:id(${AnyStringRegex})`,
          `/people/segments/:segmentId/:id(${UUIDRegex})`,
          `/companies/:id(${UUIDRegex})`,
          `/companies/:id`,
          `/enrichment/:integration(${concatEnrichmentTypes})/logs/:id(${UUIDRegex})`,
          `/people/:id(${UUIDRegex})`,
          `/people-new/:id(${UUIDRegex})`,
          `/enrichment/:integration(${concatEnrichmentTypes})/logs`,
        ]}
        render={({ location, match }) => (
          <TransitionGroup>
            <Transition key={match.path} timeout={500} unmountOnExit>
              {(state) => (
                <ErrorHandler>
                  <Switch location={location}>
                    {/*
                     * This route is a noop. It is only here to prevent the company 'any regex' domain
                     * route from being matched and throwing a sidebar on top of the destination config page.
                     */}
                    <GatedRouteEntitlement
                      exact
                      hasAccess={hasAccessCompanies}
                      path={[
                        `/companies/segments/:id(${UUIDRegex})/edit`,
                        `/companies/segments/:segmentId(${UUIDRegex})/destinations/:id(${UUIDRegex})?/:action?`,
                        `/companies/segments/:segmentId(${UUIDRegex})/destinations/:action?/:product?`,
                      ]}
                    />

                    <GatedRouteEntitlement
                      exact
                      hasAccess={hasAccessCompanies}
                      path={[
                        `/companies/:id(${UUIDRegex})`,
                        `/companies/:id`,
                        `/companies/segments/:segmentId(${UUIDRegex})/:id(${UUIDRegex})`,
                        `/companies/segments/:segmentId(${UUIDRegex})/:id(${AnyStringRegex})`,
                      ]}
                      render={({
                        match: {
                          params: { id, segmentId },
                        },
                      }: any) => (
                        <SidebarDrawer
                          isVisible={state === 'entered'}
                          onToggle={() => {
                            history.push(
                              paths.segment(
                                segmentTypes.company as SegmentRootPathKey,
                                segmentId,
                              ),
                            );
                          }}
                          size="xxl"
                        >
                          <Company id={id} />
                        </SidebarDrawer>
                      )}
                    />

                    <GatedRouteEntitlement
                      exact
                      hasAccess={hasAccessEnrichment}
                      path={[
                        `/enrichment/:integration(${concatEnrichmentTypes})/logs/:id(${UUIDRegex})`,
                      ]}
                      render={({
                        match: {
                          params: { integration, id },
                        },
                      }) => (
                        <SidebarDrawer
                          isVisible={state === 'entered'}
                          onToggle={() => {
                            history.push(
                              paths.dataEnrichmentLogs(integration as string),
                            );
                          }}
                        >
                          <EnrichmentLog id={id} type={integration} />
                        </SidebarDrawer>
                      )}
                    />

                    <GatedRouteEntitlement
                      exact
                      hasAccess={hasAccessPeople}
                      path={[
                        `/people/:id(${UUIDRegex})`,
                        `/people/segments/:segmentId(${UUIDRegex})/:id(${UUIDRegex})`,
                      ]}
                      render={({
                        match: {
                          params: { id, segmentId },
                        },
                      }: any) => (
                        <SidebarDrawer
                          isVisible={state === 'entered'}
                          onToggle={() => {
                            history.push(
                              paths.segment(
                                segmentTypes.person as SegmentRootPathKey,
                                segmentId,
                              ),
                            );
                          }}
                          size="xxl"
                        >
                          <Person id={id} />
                        </SidebarDrawer>
                      )}
                    />
                  </Switch>
                </ErrorHandler>
              )}
            </Transition>
          </TransitionGroup>
        )}
      />
    </Switch>
  );
};
