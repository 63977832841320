import {
  ApolloQueryResult,
  FetchResult,
  MutationFunctionOptions,
  OperationVariables,
} from '@apollo/client';
import { CKButton, CKLayoutHorizontal, CKTooltip } from 'clearkit';

import MultiPicklist from '~/components/MultiPicklist';
import { ButtonContainer } from '~/components/unified/ButtonContainer';
import { Export, Segment } from '~/generated/graphql';

import { CsvExportHistoryRow } from '../CsvExportHistoryRow';

type CsvExportSettingsProps = {
  type: 'Company' | 'Person';
  attributes: any;
  setColumns: (value: React.SetStateAction<string[] | undefined>) => void;
  columns: string[] | undefined;
  segment: Segment | undefined;
  existingExport: Export | undefined;
  existingExportLoading: boolean;
  refetchExport: () => Promise<ApolloQueryResult<any>>;
};

export function CsvExportSettingsBody({
  type,
  attributes,
  setColumns,
  existingExportLoading,
  columns,
  existingExport,
  refetchExport,
}: CsvExportSettingsProps) {
  const emptyWarning =
    "You can't export an empty CSV. Add some attributes from the list to the left.";

  return (
    <>
      {existingExport ? (
        <CsvExportHistoryRow
          csvExport={existingExport as Export}
          loading={existingExportLoading}
          refetchExport={refetchExport}
        />
      ) : null}

      <p className="mt-4">
        A default set of data attributes is included the CSV export. You can
        customize the attributes that are included and specify your own below.
      </p>
      <MultiPicklist
        allColumns={attributes}
        emptyWarning={emptyWarning}
        hubspotComingSoon
        onChange={(columns: any) => {
          setColumns(columns);
        }}
        type={type}
        value={columns}
      />
    </>
  );
}

type CsvExportSettingsFooterProps = {
  onClose: () => void;
  columns: string[] | undefined;
  exportCsv: (
    options?: MutationFunctionOptions<any, OperationVariables> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  handleCompleted: (segment: any, columns: any) => void;
  searchQuery: string | undefined;
  type: 'Company' | 'Person';
  segment: Segment | undefined;
  showUsageHelpDoc?: boolean;
  existingExport: Export | undefined;
};

export function CsvExportSettingsFooter({
  onClose,
  exportCsv,
  handleCompleted,
  searchQuery,
  type,
  segment,
  showUsageHelpDoc = false,
  columns,
  existingExport,
}: CsvExportSettingsFooterProps) {
  return (
    <CKLayoutHorizontal>
      <div>
        {showUsageHelpDoc ? (
          <a
            href="https://help.clearbit.com/hc/en-us/articles/16764226936471-Understanding-Credits-and-Usage"
            rel="noopener noreferrer"
            target="_blank"
          >
            How does a CSV export impact my usage?
          </a>
        ) : null}
      </div>
      <ButtonContainer>
        <CKButton onClick={onClose} variant="simple">
          Dismiss
        </CKButton>
        <CsvExportButton
          columns={columns}
          existingExport={existingExport}
          exportCsv={exportCsv}
          handleCompleted={handleCompleted}
          searchQuery={searchQuery}
          segment={segment}
          type={type}
        />
      </ButtonContainer>
    </CKLayoutHorizontal>
  );
}

type CsvExportButtonProps = {
  columns: string[] | undefined;
  exportCsv: (
    options?: MutationFunctionOptions<any, OperationVariables> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  handleCompleted: (segment: any, columns: any) => void;
  searchQuery: string | undefined;
  type: 'Company' | 'Person';
  segment: Segment | undefined;
  existingExport: Export | undefined;
};

function CsvExportButton({
  columns,
  exportCsv,
  segment,
  searchQuery,
  handleCompleted,
  type,
  existingExport,
}: CsvExportButtonProps) {
  if (existingExport) {
    return (
      <CKTooltip tooltip="Unable to start a new export while preparing CSV">
        <CKButton isDisabled>Begin export</CKButton>
      </CKTooltip>
    );
  }
  return (
    <CKButton
      isDisabled={(columns?.length || 0) === 0}
      onClick={() =>
        exportCsv({
          variables: {
            input: {
              columns,
              searchQuery,
              type,
              ...(segment?.id && { segmentId: segment.id }),
            },
          },
        }).then(() => handleCompleted(segment, columns))
      }
      variant="bold"
      variantColor="blue"
    >
      Begin export
    </CKButton>
  );
}
