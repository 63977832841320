import {
  Companies,
  Facebook,
  Hubspot as HubSpot,
  Job,
  Linkedin,
  Location,
  Money,
  Salesforce,
  Tech,
  Trait,
  Twitter,
} from '@clearkit/icons';

const GroupLogo = ({ title, type, className }) => {
  const icons = {
    Company: Companies,
    Industry: Job,
    Finances: Money,
    Location: Location,
    'Custom Traits': Trait,
    Tech: Tech,
    Twitter: Twitter,
    Facebook: Facebook,
    Linkedin: Linkedin,
    HubSpot: HubSpot,
    Salesforce: Salesforce,
  };

  if (icons[type]) return React.createElement(icons[type], { className });
  if (icons[title]) return React.createElement(icons[title], { className });
  return <div className={className} />;
};

GroupLogo.propTypes = {
  title: PropTypes.node,
  className: PropTypes.string,
};

export default GroupLogo;
