// eslint-disable-next-line clearbit-x/no-tree-shaking-imports
import { fade } from '@material-ui/core/styles/colorManipulator';

import { Theme } from '../types';

const theme = (outerTheme: Theme) => ({
  ...outerTheme,

  editorStyles: {
    primary: outerTheme.palette.marketo[500],
    secondary: outerTheme.palette.marketo[400],
    tertiary: outerTheme.palette.marketo[300],
    border: `${fade(outerTheme.palette.marketo[200], 0.4)}`,
    tint: outerTheme.palette.marketo[100],
    background: outerTheme.palette.marketo[0],
    shadow: `0 0 0 1px ${fade(
      outerTheme.palette.marketo[200],
      0.4,
    )}, 0 1px 2px 1px rgba(0, 0, 0, .08)`,
  },
});

export default theme;
