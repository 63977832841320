import { CKAvatar } from 'clearkit';
import gravatarUrl from 'gravatar-url';

import TwoLineMenuItem from './TwoLineMenuItem';

const SalesforceLeadListItem = ({
  salesforceLead,
  salesforceAccountConnector,
}) => {
  return (
    <TwoLineMenuItem
      externalLinkClass="text-blue-500"
      href={`${salesforceAccountConnector.externalInstanceUrl}/${salesforceLead.externalId}`}
      icon={
        <CKAvatar
          className="shadow-sm"
          size={32}
          src={gravatarUrl(salesforceLead.email)}
        />
      }
      subtitle={<>Owned by {salesforceLead?.owner?.name || 'Unknown User'}</>}
      title={salesforceLead.name}
    />
  );
};

export default SalesforceLeadListItem;
