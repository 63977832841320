import { CKButton } from 'clearkit';
import startCase from 'lodash/startCase';
import { Subscribe } from 'unstated';

import ConnectorsContainer from '~/containers/Connectors';
import { authConnector } from '~/lib/client';
import {
  connectorLocations,
  trackAuthStartedAccountConnector,
  trackConnectedAccountConnector,
} from '~/lib/segmentAnalytics';

import FormContentWrapper from '../../components/FormContentWrapper';
import styles from './styles';

const HeadingText = ({ name, status }) => {
  if (status === 'disconnected') {
    return `${name} has been disconnected.`;
  }

  return `You have not set up a ${name} connection yet.`;
};

const SubHeadingText = ({ name, status }) => {
  if (status === 'disconnected') {
    return `Reconnect to ${name} to modify this destination`;
  }

  return `Connect to ${name} to continue setting up this destination`;
};

const ButtonText = ({ name, status }) => {
  if (status === 'disconnected') {
    return `Reconnect ${name}`;
  }

  return `Connect ${name}`;
};

class Disconnected extends React.Component {
  authSuccess = () => {
    const {
      connectorsContainer,
      onSuccess,
      service: connectorType,
    } = this.props;

    trackConnectedAccountConnector(
      connectorType,
      connectorLocations.destination,
    );

    connectorsContainer.fetchConnectors();
    onSuccess();
  };

  auth = () => {
    const { service: connectorType } = this.props;

    trackAuthStartedAccountConnector(
      connectorType,
      connectorLocations.destination,
    );

    authConnector(this.getService(), () => this.authSuccess(connectorType));
  };

  getService() {
    const { service } = this.props;
    if (service === 'google') return 'google_oauth2';

    return service;
  }

  render() {
    const {
      connectorsContainer: {
        state: { loading },
      },
      service,
      Logo,
      classes,
      connectorStatus,
    } = this.props;

    const name = startCase(service);

    return (
      <div className={classes.disconnectedContainer}>
        {Logo && <Logo height="60" width="60" />}
        <span className={classes.heading}>
          <HeadingText name={name} status={connectorStatus} />
        </span>
        <span className={classes.subheading}>
          <SubHeadingText name={name} status={connectorStatus} />
        </span>
        <CKButton
          isDisabled={loading}
          onClick={this.auth}
          type="button"
          variant="bold"
          variantColor="blue"
        >
          <span>
            <ButtonText name={name} status={connectorStatus} />
          </span>
        </CKButton>
      </div>
    );
  }
}

Disconnected.propTypes = {
  connectorStatus: PropTypes.string,
  connectorsContainer: PropTypes.object.isRequired,
  service: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  Logo: PropTypes.elementType,
  classes: PropTypes.object,
};

const WrappedDisconnected = ({ FormFooter, ...props }) => (
  <Subscribe to={[ConnectorsContainer]}>
    {(connectorsContainer) => (
      <>
        <FormContentWrapper>
          <Disconnected connectorsContainer={connectorsContainer} {...props} />
        </FormContentWrapper>
        {FormFooter ? <FormFooter submitDisabled /> : null}
      </>
    )}
  </Subscribe>
);

export default withStyles(styles)(WrappedDisconnected);
