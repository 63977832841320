import { CKDrawer } from 'clearkit';

import ExportSalesPipelineUI from '~/components/audiences/ExportSalesPipeline';
import LoadingArea from '~/components/LoadingArea';
import { useFancyQuery } from '~/lib/graphql';

import query from './query.graphql';
import styles from './styles';

const ExportSalesPipeline = () => {
  const { data, loading } = useFancyQuery(query, {
    variables: {
      dateWindow: 'trailing_30_days',
    },
  });

  return (
    <div className="flex flex-col flex-1 overflow-hidden">
      <CKDrawer.Header>
        <CKDrawer.Heading>Export Sales Pipeline snapshots</CKDrawer.Heading>
        <p className="text-sm">Admin only</p>
      </CKDrawer.Header>
      <CKDrawer.Body className="bg-gray-0">
        {loading ? (
          <LoadingArea className="h-64" loading />
        ) : (
          <ExportSalesPipelineUI snapshots={data?.salesPipeline?.snapshots} />
        )}
      </CKDrawer.Body>
    </div>
  );
};

export default withStyles(styles)(ExportSalesPipeline);
