import { useMutation, useQuery } from '@apollo/client';
import {
  AddHollow as AddIcon,
  CheckmarkOrb as CheckIcon,
  GoExternal,
  Info as InfoIcon,
  Refresh,
} from '@clearkit/icons';
import {
  CKBadge,
  CKBox,
  CKButton,
  CKLayoutGrid,
  CKLayoutHorizontal,
  CKTextField,
} from 'clearkit';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';

import BooleanToggleSection from '~/components/configuration/BooleanToggleSection';
import FormSection from '~/components/configuration/FormSection';
import LoadingArea from '~/components/LoadingArea';
import { WhenLegacyForms } from '~/hooks/account/Entitlements/WhenLegacyForms';
import { configurationNameForType } from '~/lib/configuration';

import addAllowedSiteMutation from './addAllowedSite.graphql';
import AllowedSiteMenu from './AllowedSiteMenu';
import query from './query.graphql';
import removeAllowedSiteMutation from './removeAllowedSite.graphql';
import updateConfigurationMutation from './updateConfiguration.graphql';

const IdentifyHelpLinks = {
  hubspot:
    'https://help.clearbit.com/hc/en-us/articles/4420179541911-Set-Up-Clearbit-Forms-for-HubSpot-',
  marketo: 'https://help.clearbit.com/hc/en-us/articles/4420189078807',
  pardot:
    'https://help.clearbit.com/hc/en-us/articles/4420193089047-Set-Up-Clearbit-Forms-for-Pardot-',
};

const Description = ({ integration }) => {
  if (integration === 'marketo') {
    return (
      <p>
        Form shortening in Marketo is powered by Marketo form's visibility
        rules. Read the{' '}
        <a
          href="https://help.clearbit.com/hc/en-us/articles/4420189078807"
          rel="noopener noreferrer"
          target="_blank"
        >
          forms installation guide
        </a>{' '}
        to complete the setup.
      </p>
    );
  } else {
    return 'When enabled, form fields that clearbit can autofill remain hidden, keeping forms to just fields without data.';
  }
};

export default function FormFieldMappings({ integration }) {
  const {
    data: { formConfiguration = { initialLoading: true } } = {},
    loading,
    refetch,
  } = useQuery(query, {
    variables: {
      integration,
    },
    notifyOnNetworkStatusChange: true,
  });

  const [removeAllowedSite] = useMutation(removeAllowedSiteMutation);
  const [addAllowedSite, { loading: addSiteLoading }] = useMutation(
    addAllowedSiteMutation,
  );
  const [updateConfiguration] = useMutation(updateConfigurationMutation);

  const allowedSites = formConfiguration?.allowedSites || [];

  const [isAddingDomain, setIsAddingDomain] = useState(false);
  const [addSiteErrors, setAddSiteErrors] = useState([]);
  const [newSiteUrl, setNewSiteUrl] = useState('');

  function updateFlags(update) {
    return updateConfiguration({
      variables: {
        input: {
          integration,
          autofill: formConfiguration?.autofill,
          formShortening: formConfiguration?.formShortening,
          identifyOnSubmit: formConfiguration?.identifyOnSubmit,
          ...update,
        },
      },
    });
  }

  return (
    <LoadingArea loading={loading && formConfiguration?.initialLoading}>
      <div className="divide-y divide-gray-200">
        <FormSection
          className="mb-9"
          description={
            <>
              <p className="mb-8 text-gray-600">
                Specify the sites where Clearbit form shortening and autofill
                should work. Enter a specific URL (e.g. example.com or
                example.com/blog) to autofill or shorten forms at that address,
                or enter a wildcard (e.g. example.com/* or *.example.com/*) to
                allow autofill and shortening for all forms on that domain.
              </p>
              <CKButton
                as={'a'}
                href="https://help.clearbit.com/hc/en-us/articles/4420176895127"
                rel="noopener noreferrer"
                rightIcon={<GoExternal />}
                target="_blank"
                variantColor="blue"
              >
                Forms installation guide
              </CKButton>
            </>
          }
          title="Forms setup and allowed sites"
        >
          <CKBox>
            <div className="px-6 py-3 text-base font-medium text-gray-600 border-b border-gray-200">
              Allowed sites
            </div>
            <div className="divide-y divide-gray-100">
              {allowedSites.map((siteInfo) => (
                <CKLayoutGrid
                  className="items-center w-full px-6 py-2"
                  gridTemplateColumns="1fr 18ch auto"
                  key={siteInfo.url}
                >
                  <div className="overflow-hidden">
                    <CKBadge className="max-w-full truncate">
                      {siteInfo.siteUrl}
                    </CKBadge>
                  </div>
                  <CKLayoutHorizontal
                    className={classnames('shrink-0 min-w-0', {
                      'text-green': siteInfo.eventsReceived,
                      'text-yellow': !siteInfo.eventsReceived,
                    })}
                    gap="0.25rem"
                    justifyContent="start"
                    wrap="nowrap"
                  >
                    {siteInfo.eventsReceived ? (
                      <>
                        <CheckIcon className="fill-gradient-br-green" />
                        <span className="text-sm font-medium">
                          Receiving events
                        </span>
                      </>
                    ) : (
                      <>
                        <InfoIcon className="right-px fill-gradient-br-yellow" />
                        <span className="text-sm font-medium">
                          Not receiving events
                        </span>
                      </>
                    )}
                  </CKLayoutHorizontal>
                  <AllowedSiteMenu
                    onDelete={() => {
                      removeAllowedSite({
                        variables: {
                          input: {
                            integration,
                            id: siteInfo.id,
                          },
                        },
                      });
                    }}
                  />
                </CKLayoutGrid>
              ))}
            </div>
            <CKBox.Footer className="px-6 py-3">
              {isAddingDomain ? (
                <>
                  <div className="flex gap-2">
                    <CKTextField
                      className="flex-grow"
                      onChange={(e) => {
                        setNewSiteUrl(e.target.value);
                      }}
                      value={newSiteUrl}
                      variant={addSiteErrors.length > 0 ? 'error' : undefined}
                    ></CKTextField>

                    <CKButton
                      isDisabled={isEmpty(newSiteUrl)}
                      isLoading={addSiteLoading}
                      onClick={async () => {
                        let { data } = await addAllowedSite({
                          variables: {
                            input: {
                              integration,
                              siteUrl: newSiteUrl,
                            },
                          },
                        });

                        const { errors } = data.response;
                        const siteErrors = errors.find(
                          (e) => e.field === 'allowed_site',
                        );

                        if (siteErrors) {
                          setAddSiteErrors(siteErrors.messages);
                        } else {
                          setIsAddingDomain(false);
                          setNewSiteUrl('');
                        }
                      }}
                      variant="bold"
                      variantColor="blue"
                    >
                      Save
                    </CKButton>
                    <CKButton
                      onClick={() => {
                        setIsAddingDomain(false);
                        setNewSiteUrl('');
                      }}
                    >
                      Cancel
                    </CKButton>
                  </div>
                  {addSiteErrors.length > 0 && (
                    <div className="my-2 text-red-600">
                      <ul className="ml-6 list-disc">
                        {addSiteErrors.map((message) => (
                          <li key={message}>{message}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </>
              ) : (
                <CKLayoutHorizontal className="shrink-0 basis-auto">
                  <CKButton
                    leftIcon={<AddIcon />}
                    onClick={() => {
                      setIsAddingDomain(true);
                    }}
                    variantColor="blue"
                  >
                    Add domain
                  </CKButton>

                  {allowedSites.length ? (
                    <CKButton
                      className="flex items-center"
                      isDisabled={!allowedSites.length}
                      isLoading={loading}
                      leftIcon={<Refresh />}
                      onClick={() => refetch()}
                      variantColor="blue"
                    >
                      Test implementation
                    </CKButton>
                  ) : null}
                </CKLayoutHorizontal>
              )}
            </CKBox.Footer>
          </CKBox>
        </FormSection>
        <FormSection
          className="pt-9"
          description={
            <p className="text-gray-600">
              Choose whether you'd like to have Clearbit autofill form fields,
              keep form fields hidden if Clearbit has data for them, and
              leverage form submissions to track website activity.
            </p>
          }
          title="Form settings"
        >
          <CKBox>
            <div className="divide-y divide-gray-100">
              <BooleanToggleSection
                checked={!!formConfiguration?.autofill}
                description={
                  <p>
                    When enabled, Clearbit will enrich an email address and
                    autofill {configurationNameForType(integration)} forms with
                    data in{' '}
                    <Link to={`/forms/${integration}/mappings`}>
                      mapped form fields
                    </Link>
                    .
                  </p>
                }
                id="form_autofill"
                onChange={async (value) => {
                  await updateFlags({ autofill: value });
                }}
                title="Form field autofill"
              />
              <BooleanToggleSection
                checked={formConfiguration?.formShortening}
                description={<Description integration={integration} />}
                id="form_shortening"
                isDisabled={
                  integration === 'marketo'
                    ? true
                    : !formConfiguration?.autofill
                }
                onChange={async (value) => {
                  await updateFlags({ formShortening: value });
                }}
                title="Form shortening"
              />
              <WhenLegacyForms>
                <BooleanToggleSection
                  checked={formConfiguration?.identifyOnSubmit}
                  description={
                    <p>
                      Automatically track web activity for users who submit a
                      Clearbit-enabled form.{' '}
                      <a href={IdentifyHelpLinks[integration]}>Learn more</a>.
                    </p>
                  }
                  id="form_identify"
                  isDisabled={!formConfiguration?.autofill}
                  onChange={async (value) => {
                    await updateFlags({ identifyOnSubmit: value });
                  }}
                  title="Track web activity"
                />
              </WhenLegacyForms>
            </div>
            <div className="flex items-center px-6 py-3 font-medium border-t border-gray-200 rounded-b bg-gray-50">
              <InfoIcon className="relative mr-1 text-gray bottom-px right-px" />
              <span className="text-sm italic text-gray">
                {integration === 'marketo'
                  ? `Tracking web activity depends on autofill and requires it to
                  be enabled`
                  : `Form shortening and tracking web activity depend on autofill
                  and require it to be enabled`}
              </span>
            </div>
          </CKBox>
        </FormSection>
      </div>
    </LoadingArea>
  );
}
