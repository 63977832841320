import { ApolloQueryResult } from '@apollo/client';
import {
  CheckmarkOrb,
  CloseOrb,
  Error,
  SpinnerIndicators,
} from '@clearkit/icons';
import { CKBox, CKButton, CKLayoutGrid, CKSkeleton, CKTooltip } from 'clearkit';
import moment from 'moment';
import { Link } from 'react-router-dom';

import {
  Export,
  ExportStatusEnum,
  useCancelExportCsvSegmentMutation,
} from '~/generated/graphql';

type CsvExportHistoryRowProps = {
  csvExport: Export | undefined;
  className?: string;
  loading?: boolean;
  refetchExport: () => Promise<ApolloQueryResult<any>>;
};
export function CsvExportHistoryRow({
  csvExport,
  className,
  loading,
  refetchExport,
}: CsvExportHistoryRowProps) {
  return (
    <CKBox className={`min-h-[62px] p-4 ${className}`}>
      <CKLayoutGrid gap="0.5rem" gridTemplateColumns="10rem auto 6.5rem">
        <div className="flex items-center justify-left">
          <ExportStatus csvExport={csvExport} loading={loading} />
        </div>
        <ExportInfo csvExport={csvExport} loading={loading} />
        <ExportCta
          csvExport={csvExport}
          loading={loading}
          refetchExport={refetchExport}
        />
      </CKLayoutGrid>
    </CKBox>
  );
}

type ExportInfoProps = {
  csvExport: Export | undefined;
  loading?: boolean;
};
function ExportInfo({ csvExport, loading }: ExportInfoProps) {
  if (loading || !csvExport) {
    return <CKSkeleton height={36} />;
  }
  return (
    <div className="flex items-center justify-center">
      Started {moment(csvExport?.createdAt).format('lll')} by{' '}
      {csvExport?.userName}
    </div>
  );
}

type ExportCtaProps = {
  loading?: boolean;
  csvExport: Export | undefined;
  refetchExport: () => Promise<ApolloQueryResult<any>>;
};

function ExportCta({ loading, csvExport, refetchExport }: ExportCtaProps) {
  const [cancelExport] = useCancelExportCsvSegmentMutation({
    variables: {
      input: {
        exportId: csvExport?.id as string,
      },
    },
    onCompleted: () => {
      refetchExport();
    },
  });
  if (loading || !csvExport) {
    return <CKSkeleton height={36} />;
  }
  switch (csvExport.status) {
    case ExportStatusEnum.Queued:
    case ExportStatusEnum.Uploading:
      // Hide cancel button for now will fast follow will cancel functionality
      return (
        <CKButton
          onClick={() => cancelExport()}
          variant="simple"
          variantColor="red"
        >
          Cancel
        </CKButton>
      );
    case ExportStatusEnum.Success:
      if (csvExport.expired) {
        return (
          <CKTooltip tooltip="CSV download expired">
            <CKButton isDisabled>Download</CKButton>
          </CKTooltip>
        );
      } else {
        return (
          <CKButton
            as={Link}
            className="w-full"
            target="_blank"
            to={csvExport.downloadUrl as string}
          >
            Download
          </CKButton>
        );
      }

    default:
      return <></>;
  }
}

type ExportStatusProps = {
  loading?: boolean;
  csvExport: Export | undefined;
};
const iconHeight = 18;
const iconMarginClass = 'mr-2';
function ExportStatus({ csvExport, loading }: ExportStatusProps) {
  if (loading || !csvExport) {
    return (
      <>
        <CKSkeleton
          className={`${iconMarginClass} rounded-full`}
          height={iconHeight}
          width={iconHeight}
        />
        <CKSkeleton height={36} width={120} />
      </>
    );
  }
  switch (csvExport.status) {
    case ExportStatusEnum.Queued:
    case ExportStatusEnum.Uploading:
      return (
        <>
          <SpinnerIndicators className="ml-[-6px]" height={iconHeight} />
          Preparing CSV...
        </>
      );
    case ExportStatusEnum.Error:
      return (
        <>
          <Error
            className={`${iconMarginClass}`}
            color="#DE2612"
            height={iconHeight}
          />
          Export Failed
        </>
      );
    case ExportStatusEnum.Success:
      return (
        <>
          <CheckmarkOrb
            className={`${iconMarginClass}`}
            color="#1DC463"
            height={iconHeight}
          />
          CSV export ready
        </>
      );
    case ExportStatusEnum.Skipped:
    case ExportStatusEnum.Cancelled:
      return (
        <>
          <CloseOrb
            className={`${iconMarginClass}`}
            color="#FCA000"
            height={iconHeight}
          />
          Export cancelled
        </>
      );

    default:
      return <></>;
  }
}
