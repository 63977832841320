import { QueryType } from '../types';
import { useProspector } from '../useProspector';
import { SectionFiltersCompanies } from './SectionFiltersCompanies';
import { SectionFiltersPeople } from './SectionFiltersPeople';
import { FiltersCompanies, FiltersPeople } from './types';

export const ProspectorFilters = () => {
  const [_state, send] = useProspector();

  const updateFilters = (queryType: QueryType) => (
    value: FiltersCompanies | FiltersPeople,
  ) => {
    send({
      type: 'UPDATE_QUERY',
      queryType,
      value,
    });
  };

  return (
    <div className="p-6 space-y-8">
      <SectionFiltersPeople onChange={updateFilters('person')} />
      <SectionFiltersCompanies onChange={updateFilters('company')} />
    </div>
  );
};
