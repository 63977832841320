import { CKCheckboxNext, CKCheckboxNextProps } from 'clearkit';

type BillingTOSProps = CKCheckboxNextProps & {
  tosError: boolean;
};

export const BillingTOS = ({
  tosError,
  checked,
  onChange,
}: BillingTOSProps) => {
  return (
    <>
      <CKCheckboxNext checked={checked} onChange={onChange}>
        I have read and agree to the{' '}
        <a
          className="ck-link"
          href="https://clearbit.com/legal"
          rel="noopener noreferrer"
          target="_blank"
        >
          Clearbit TOS
        </a>{' '}
        and{' '}
        <a
          className="ck-link"
          href="https://clearbit.com/privacy-policy"
          rel="noopener noreferrer"
          target="_blank"
        >
          privacy policy
        </a>
      </CKCheckboxNext>
      {tosError ? (
        <p className="text-sm text-red">
          You must agree to terms to change your plan
        </p>
      ) : null}
    </>
  );
};
