import InfoTooltip from '~/components/InfoTooltip';
import { readableSingularSegmentType, segmentTypes } from '~/lib/segments';

export default function triggerTypeValues(typename) {
  return [
    {
      value: 'segment_enter',
      label: (
        <span>
          When a {readableSingularSegmentType(typename)} <strong>enters</strong>{' '}
          the audience
        </span>
      ),
    },
    {
      value: 'segment_exit',
      label: (
        <span>
          When a {readableSingularSegmentType(typename)} <strong>exits</strong>{' '}
          the audience
        </span>
      ),
    },
    {
      value: 'page',
      label: (
        <div className="flex items-center">
          <span>
            On <strong>page view</strong>{' '}
          </span>
          <InfoTooltip
            title={`The destination is triggered every time ${
              typename === segmentTypes.company
                ? 'someone at the company'
                : 'a person'
            } hits your website.`}
          />
        </div>
      ),
    },
  ];
}
