export function gridYLinesBuilder(maxValue: number) {
  const scaleValue = maxValue !== 0 ? maxValue : 1000;

  const gridYLines = [
    0,
    scaleValue * 0.25,
    scaleValue * 0.5,
    scaleValue * 0.75,
    scaleValue,
    scaleValue * 1.25,
  ];

  return gridYLines;
}
