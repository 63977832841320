const styles = () => ({
  root: {
    overflow: 'auto',
  },
  noMatches: {
    padding: '6px 0 24px',
    fontSize: 14,
    color: 'rgba(255, 255, 255, .5)',
    textAlign: 'center',
    width: '100%',
    display: 'block',
  },
  searchTerm: {
    color: 'rgba(255, 255, 255, .8)',
  },
});

export default styles;
