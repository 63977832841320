import { CKAvatar } from 'clearkit';
import get from 'lodash/get';

import { TableCell, TableRow } from '~/components/Table';
import { timeFromNow } from '~/lib/formatters';

import styles from './Pageview.style';

class Pageview extends React.Component {
  render() {
    const { event, classes } = this.props;
    const eventSearch = get(event, 'properties.search', '');
    const eventPath = get(event, 'properties.path');
    let eventUrl = get(event, 'properties.url', '');

    // Remove the # search from the end
    if (eventUrl.endsWith(eventSearch)) {
      eventUrl = eventUrl.substr(0, eventUrl.length - eventSearch.length);
    }

    // Take slash off the end
    if (eventUrl.endsWith(eventPath)) {
      eventUrl = eventUrl.substr(0, eventUrl.length - eventPath.length);
    }

    eventUrl = eventUrl.replace(/http(s)?:\/\//, '');

    return (
      <TableRow className={classes.root} key={event.id}>
        <TableCell
          ellipsis
          innerClassName={classes.eventPersonName}
          title={get(event, 'person.name') || 'Anonymous'}
        >
          <div className="flex items-center gap-3">
            <CKAvatar size={24} src={get(event, 'person.avatar')} />
            <span>{get(event, 'person.name') || 'Anonymous'}</span>
          </div>
        </TableCell>

        <TableCell
          ellipsis
          title={eventUrl && `https://${eventUrl}${eventPath}`}
        >
          {eventUrl ? (
            <a
              href={`https://${eventUrl}${eventPath}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {eventUrl && <span className="text-gray-300">{eventUrl}</span>}
              {eventPath}
            </a>
          ) : (
            <>&mdash;</>
          )}
        </TableCell>

        <TableCell ellipsis title={event.timestamp}>
          {timeFromNow(event.timestamp)}
        </TableCell>
      </TableRow>
    );
  }
}

Pageview.propTypes = {
  classes: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
};

export default withStyles(styles)(Pageview);
