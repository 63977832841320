import {
  Segment as SegmentIcon,
  SegmentDestination as SegmentWithDestinationIcon,
} from '@clearkit/icons';

import styles from './styles.style';

function SegmentListIcon({ segment, selected, classes, className }) {
  const {
    enabledDestinationsCount,
    audiences: { totalCount },
  } = segment;

  if (enabledDestinationsCount === 0 && totalCount === 0) {
    return (
      <SegmentIcon
        className={classnames(className, classes.icon, {
          'fill-gradient-br-blue': selected,
          'fill-current text-gray-400': !selected,
        })}
      />
    );
  }

  return (
    <SegmentWithDestinationIcon
      className={classnames(
        className,
        classes.icon,
        classes.segmentWithDestinationsIcon,
        {
          [classes.segmentIconSelected]: selected,
          [classes.segmentIconUnselected]: !selected,
          'fill-gradient-br-blue': selected,
          'fill-current text-gray-400': !selected,
        },
      )}
    />
  );
}

SegmentListIcon.propTypes = {
  segment: PropTypes.shape({
    enabledDestinationsCount: PropTypes.number,
    audiences: PropTypes.shape({
      totalCount: PropTypes.number,
    }),
  }).isRequired,
  classes: PropTypes.object,
  className: PropTypes.string,
};

export default withStyles(styles)(SegmentListIcon);
