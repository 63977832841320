import { Activity } from '@clearkit/icons';

import {
  AccountNotifications,
  ENRICHMENT_TYPES,
} from '~/components/AccountNotifications';
import { AccountConnector, AccountNotification } from '~/generated/graphql';

import { HomeConnectionBanner } from '../Banners/HomeBannerConnectionError';
import { HomeHeading } from '../HomeHeading';

type HomeSectionNotificationsProps = {
  accountConnectors?: AccountConnector[];
  notifications?: AccountNotification[];
};

export const HomeSectionNotifications = ({
  accountConnectors,
  notifications,
}: HomeSectionNotificationsProps) => {
  /**
   * Don't render this section if there are no
   * notifications or disconnected services.
   */
  if (!notifications?.length && !accountConnectors?.length) {
    return null;
  }

  const notificationsWithoutEnrichmentType = notifications?.filter(
    (notification) => !ENRICHMENT_TYPES.includes(notification.notificationType),
  );

  if (
    !notificationsWithoutEnrichmentType?.length &&
    !accountConnectors?.length
  ) {
    return null;
  }

  const disconnectedServices =
    accountConnectors?.map((connector) => connector.service) || [];

  const filteredNotifications = notifications?.filter(
    (notification) =>
      !disconnectedServices.includes(
        notification.sourceName as AccountConnector['service'],
      ),
  );

  return (
    <section className="space-y-6">
      <HomeHeading>
        <Activity /> System Notifications
      </HomeHeading>
      {accountConnectors?.map((connector) => {
        return (
          <HomeConnectionBanner connector={connector} key={connector.id} />
        );
      })}
      {filteredNotifications ? (
        <AccountNotifications notifications={filteredNotifications} />
      ) : null}
    </section>
  );
};
