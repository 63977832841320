import { CKCheckbox, CKSelectNext, CKTextField } from 'clearkit';

import InfoTooltip from '~/components/InfoTooltip';

const SECONDS = {
  months: 2592000,
  weeks: 604800,
  days: 86400,
};

const durationItems = [
  { label: 'months', value: 'months' },
  { label: 'weeks', value: 'weeks' },
  { label: 'days', value: 'days' },
];

class SuppressionForm extends React.Component {
  constructor(props) {
    super(props);

    const suppressionFormState = this.getSuppressionFormState();

    this.state = {
      number: suppressionFormState.number,
      duration: suppressionFormState.duration,
      enabled: suppressionFormState.number > 0,
    };
  }

  toggleSuppression = () => {
    this.setState(
      (prevState) => {
        const enabled = !prevState.enabled;

        return {
          enabled,
        };
      },
      () => {
        this.calculateSeconds({
          number: this.state.enabled ? 1 : 0,
          duration: 'days',
        });
      },
    );
  };

  calculateSeconds = (formState) => {
    const numOfSeconds = SECONDS[formState.duration] * formState.number;

    this.setState(formState, () => {
      this.props.onChange({ exclusion_timeframe: numOfSeconds });
    });
  };

  getSuppressionFormState = () => {
    const { exclusion_timeframe } = this.props;

    if (!exclusion_timeframe || exclusion_timeframe === 0)
      return { number: 0, duration: 'days' };

    let result = {
      number: 0,
      duration: 'days',
    };
    let divisor;

    for (let key in SECONDS) {
      divisor = exclusion_timeframe / SECONDS[key];

      if (Number.isInteger(divisor) && divisor >= 1) {
        result = {
          number: divisor,
          duration: key,
        };
        break;
      }
    }

    return result;
  };

  render() {
    const { typename } = this.props;
    const { enabled } = this.state;

    return (
      <div>
        <CKCheckbox
          checked={enabled}
          id="toggle-supression"
          label={
            <span className="flex items-center">
              <span>Enable delivery suppression</span>
              <InfoTooltip
                title={`Reduce the noise - limit how often you are notified of the same ${typename}`}
              />
            </span>
          }
          onChange={this.toggleSuppression}
        />

        {enabled && (
          <div>
            <div className="my-3 mr-3">
              Only include {typename === 'person' ? 'people' : 'companies'} that
              haven’t been included in the past
            </div>

            <div className="flex gap-4">
              <CKTextField
                className="w-28"
                onChange={(e) =>
                  this.calculateSeconds({
                    ...this.state,
                    number: parseInt(e.target.value, 10) || '',
                  })
                }
                value={this.state.number}
              />

              <CKSelectNext
                className="w-36"
                items={durationItems}
                onChange={(newValue) =>
                  this.calculateSeconds({
                    ...this.state,
                    duration: newValue.value,
                  })
                }
                placeholder="Select a duration"
                value={{
                  value: this.state.duration,
                  label: this.state.duration,
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

SuppressionForm.propTypes = {
  schedule_period: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  exclusion_timeframe: PropTypes.number.isRequired,
};

export default SuppressionForm;
