const styles = () => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    '&:hover .tooltip': {
      visibility: 'visible !important',
    },
  },

  hidden: {
    height: '18px',
    opacity: 0,
  },

  question: {
    display: 'inline',
    marginLeft: '5px',
    backgroundColor: '#c0c8d1',
    borderRadius: '50%',
    color: 'white',
    fontSize: 14,
    height: '18px',
    textAlign: 'center',
    userSelect: 'none',
    width: '18px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#a9b0b8',
    },
  },

  tooltip: {
    fontSize: 14,
    padding: '10px 15px',
  },
});

export default styles;
