import { audienceSubtypes } from '~/lib/audiences';

import CompanySegmentProspectsTile from './CompanySegmentProspects.svg';
import CompanySegmentProspectsSmallTile from './CompanySegmentProspectsSmall.svg';
import ContactsTile from './Contacts.svg';
import ContactsSmallTile from './ContactsSmall.svg';
import ProspectsTile from './Prospects.svg';
import ProspectsSmallTile from './ProspectsSmall.svg';
import VisitorsTile from './Visitors.svg';
import VisitorsSmallTile from './VisitorsSmall.svg';

const tiles = {
  default: {
    [audienceSubtypes.companySegmentProspects]: CompanySegmentProspectsTile,
    [audienceSubtypes.contacts]: ContactsTile,
    [audienceSubtypes.managedVisitors]: VisitorsTile,
    [audienceSubtypes.prospects]: ProspectsTile,
    [audienceSubtypes.visitors]: VisitorsTile,
  },
  small: {
    [audienceSubtypes.companySegmentProspects]: CompanySegmentProspectsSmallTile,
    [audienceSubtypes.contacts]: ContactsSmallTile,
    [audienceSubtypes.managedVisitors]: VisitorsSmallTile,
    [audienceSubtypes.prospects]: ProspectsSmallTile,
    [audienceSubtypes.visitors]: VisitorsSmallTile,
  },
};

export default function AudiencesTile({ audience, className, size }) {
  const sizeVariant = size > 28 ? 'default' : 'small';
  const Tile = tiles[sizeVariant][audience?.subtype] || 'div';

  return <Tile className={className} style={{ height: size, width: size }} />;
}

AudiencesTile.propTypes = {
  audience: PropTypes.object,
  className: PropTypes.string,
  size: PropTypes.number.isRequired,
};
