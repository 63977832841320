import { ReactNode } from 'react';

import { useCurrentAccount, useEntitlements } from '~/hooks/account';

/**
 * Determine whether the current account has access to prospector export list.
 *
 * @returns {object} whether the user has access to prospector exports list
 */
export function useProspectorExportLists() {
  const { loading, hasAccessProspectorExports } = useEntitlements();

  return {
    hasExportListAccess: hasAccessProspectorExports,
    loading,
  };
}

/**
 * Determine whether the current account has access to prospector exports.
 *
 * @returns {boolean} whether the user has access to prospector exports
 */
export function useProspectorExports() {
  const { hasAccessProspectorExports } = useEntitlements();

  return hasAccessProspectorExports;
}

/**
 * Determine whether the current account has access to prospector CRM exports.
 *
 * @returns {boolean} whether the user has access to prospector CRM exports
 */
export function useProspectorCRMExports() {
  const { hasAccessProspectorCRMExports } = useEntitlements();

  return hasAccessProspectorCRMExports;
}

/**
 * Determine whether the current account has access to prospector hubspot exports.
 *
 * @returns {boolean} whether the user has access to prospector hubspot exports
 */
export function useProspectorHubspotExports() {
  const hasCRMExportAccess = useProspectorCRMExports();
  const { account } = useCurrentAccount();

  if (!hasCRMExportAccess || !account) {
    return false;
  }

  return !!account.connectors?.find(
    (connector: any) => connector.service === 'hubspot',
  );
}

/**
 * Determine whether the current account has access to prospector salesforce exports.
 *
 * @returns {boolean} whether the user has access to prospector salesforce exports
 */
export function useProspectorSalesforceExports() {
  const hasCRMExportAccess = useProspectorCRMExports();
  const { account } = useCurrentAccount();

  if (!hasCRMExportAccess || !account) {
    return false;
  }

  return !!account.connectors?.find(
    (connector: any) =>
      connector.service === 'salesforce' ||
      connector.service === 'salesforcesandbox',
  );
}

type WhenProspectorProps = {
  children: ReactNode;
  cta?: ReactNode;
};
/**
 * A component which reveals UI for users that have access to prospector.
 * If a user doesn't have access to prospector, it can optionally display a CTA.
 *
 * @example
 * <WhenProspector>
 *  <div>Prospector, folks!</div>
 * </WhenProspector>
 *
 * @param {ReactNode} children the UI to reveal if the user has access to prospector
 * @param {ReactNode} cta optional CTA to display if the user doesn't have access to prospector
 * @returns {ReactNode | null} rendered children if the user has prospector access
 */
export function WhenProspector({ children, cta = null }: WhenProspectorProps) {
  const { hasAccessProspector } = useEntitlements();

  if (hasAccessProspector) {
    return <>{children}</>;
  }

  return <>{cta}</>;
}
