/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import { useMutation } from '@apollo/client';
import {
  AddBlock,
  SpinnerMonoIndicators as SpinnerMono,
} from '@clearkit/icons';
import { CKButton, CKCheckboxNext, CKSelectNext } from 'clearkit';
import { snakeCase, uniqBy } from 'lodash';
import map from 'lodash/map';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

import { ConfirmDialogBase } from '~/components/ConfirmDialog';
import Footer from '~/components/Footer';
import GraphqlError from '~/components/GraphqlError';
import InfoTooltip from '~/components/InfoTooltip';
import Layout from '~/components/layouts/DefaultLayout';
import LoadingArea from '~/components/LoadingArea';
import LoadingBar, { useLoadingBarState } from '~/components/LoadingBar';
import { TraitIcon } from '~/components/MultiPicklist/icons';
import Pill from '~/components/Pill';
import Select from '~/components/Select';
import CreatableSelect from '~/components/Select/CreatableSelect';
import TextField from '~/components/TextField';
import ToastsContainer from '~/containers/Toasts';
import ImportTile from '~/images/Tiles/Import.svg';
import { useFancyQuery } from '~/lib/graphql';
import history from '~/lib/history';
import paths from '~/lib/paths';

import ArrrowRight from './ArrrowRight.svg';
import ClearIcon from './Clear.svg';
import createCSVSegmentQuery from './createSegment.graphql';
import CsvSourceIcon from './CsvSourceIcon.svg';
import ErrorIcon from './Error.svg';
import styles from './map.style';
import parse from './parse.graphql';
import query from './query.graphql';
import Success from './Success.svg';
import { DOMAIN_REGEX, EMAIL_REGEX } from './validations';
import WarningIcon from './Warning.svg';

const SKIP_TRAIT_VALUE = '';

const primaryAttributes = {
  Company: 'domain',
  Person: 'email',
};

const isDevelopment = process.env.NODE_ENV === 'development';

const collectionNodesToOptions = (collections) => {
  if (!collections) return [];

  return map(map(collections.edges, 'node'), ({ id, name }) => ({
    value: id,
    label: name,
  }));
};

const primaryIdentifierRegex = (type) => {
  switch (type) {
    case 'Company':
      return DOMAIN_REGEX;
    case 'Person':
      return EMAIL_REGEX;
  }
};

const ImportLoadingBar = ({ className }) => {
  const loadingBarProps = useLoadingBarState({ maxPercentage: 75 });
  return <LoadingBar className={className} {...loadingBarProps} />;
};

const MapCsv = ({ classes, id, type }) => {
  const singular = type === 'Company' ? 'company' : 'person';
  const plural = type === 'Company' ? 'companies' : 'people';
  const mainColumn = type === 'Person' ? 'person email' : 'company domain';

  const pollInterval = (data) => {
    return data?.csvImport?.status === 'importing' ? 5_000 : false;
  };

  const {
    data,
    loading,
    error,
    refetch,
    polling,
    networkStatus,
  } = useFancyQuery(query, {
    pollInterval,
    variables: {
      id,
      type: singular,
      attributeType: type,
      collectionType: type,
    },
  });

  const { searchAttributes = [], collections, csvImport = {} } = data || {};
  const columns = csvImport.sampleData ? csvImport.sampleData[0] : [];
  const isSuccessful = csvImport?.status === 'success';
  const isImporting = csvImport?.status === 'importing';
  const isAnalyzed = csvImport?.status === 'analyzed';

  const [createSegment, setCreateSegment] = useState(true);
  const [primaryIdentifierColumn, setPrimaryIdentifierColumn] = useState(
    undefined,
  );
  const [clearbitCsvSource, setClearbitCsvSource] = useState('');
  const [segmentName, setSegmentName] = useState('');
  const [collection, setCollection] = useState(undefined);
  const [map, setMap] = useState([]);
  const [validations, setValidations] = useState({});
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [newDefaultOptions, setNewDefaultOptions] = useState([]);

  const [parseCsvImport] = useMutation(parse, {
    refetchQueries: ['ImportCsvIndexPage'],
  });

  const handleCSVSegmentCreateComplete = ({ response: { node } }) => {
    history.push(paths.segment(type, node.segmentId));
  };

  const [createCSVSegment, { loading: isCreatingSegment }] = useMutation(
    createCSVSegmentQuery,
    {
      onCompleted: handleCSVSegmentCreateComplete,
    },
  );

  const setSearchAttribute = (column, attribute) => {
    const value = attribute.value?.replace('traits->', '');

    setMap((prevMap) => {
      const newMap = prevMap.filter(
        (traitMap) => traitMap.column !== column.label,
      );

      return [
        ...newMap,
        {
          column: column.label,
          trait: value,
          ...(attribute.__isNew__ && { __isNew__: true }),
        },
      ];
    });
  };

  const traitOptions = [
    { label: 'Skip', value: SKIP_TRAIT_VALUE },
    ...searchAttributes,
    ...newDefaultOptions,
  ];

  const prevCsvStatusRef = useRef();

  useEffect(() => {
    if (!!data?.searchAttributes && isAnalyzed) {
      let newlyCreatedOptions = [];

      const primaryAttribute = primaryAttributes[type];
      let identifiedPrimaryIdentifier;

      columns.forEach((column) => {
        const columnLabel = column.label.toLowerCase();

        if (columnLabel === primaryAttribute) {
          identifiedPrimaryIdentifier = column;
        }

        let trait = searchAttributes.find(
          (attribute) => attribute.label.toLowerCase() === columnLabel,
        );

        if (!trait) {
          if (columnLabel !== primaryAttribute) {
            trait = {
              value: snakeCase(column.label),
              label: column.label,
              __isNew__: true,
            };

            newlyCreatedOptions.push(trait);
          } else {
            trait = { label: 'Skip', value: SKIP_TRAIT_VALUE };
          }
        }

        setSearchAttribute(column, trait);
      });

      if (!identifiedPrimaryIdentifier) {
        identifiedPrimaryIdentifier = columns.find((column) =>
          column.label.toLowerCase().includes(primaryAttribute),
        );
      }

      setPrimaryIdentifierColumn(identifiedPrimaryIdentifier);
      setNewDefaultOptions([...newlyCreatedOptions]);
    }
  }, [data?.csvImport, data?.searchAttributes]);

  useEffect(() => {
    if (data?.csvImport) {
      setClearbitCsvSource(data.csvImport?.sourceName);
      setSegmentName(data.csvImport?.sourceName);
      if (data.csvImport?.mapping) {
        setMap(data.csvImport?.mapping);
        setPrimaryIdentifierColumn(
          columns.find(
            (column) =>
              column.label === data?.csvImport.primaryIdentifierColumn,
          ),
        );
      }
      prevCsvStatusRef.current = data?.csvImport?.status;
    }
  }, [data?.csvImport]);

  const prevStatus = prevCsvStatusRef.current;

  useEffect(() => {
    if (prevStatus === 'importing' && isSuccessful) {
      if (csvImport?.segmentId) {
        ToastsContainer.addSuccess(
          `A new audience was created with ${csvImport?.rowsMatchedCount} ${plural}`,
        );
        history.push(paths.segment(type, csvImport.segmentId));
      } else {
        ToastsContainer.addSuccess(
          `${csvImport.rowsMatchedCount} ${plural} were imported or updated, and ${csvImport.rowsSkippedCount} ${plural} were skipped.`,
        );
        history.push(type === 'Company' ? paths.companies() : paths.people());
      }
    }
  }, [csvImport?.status]);

  const validate = () => {
    const mapWithRemovedSkips = map.filter(
      (traitMap) => traitMap.trait !== SKIP_TRAIT_VALUE,
    );
    const firstFiveRows = csvImport.sampleData || [];

    return {
      clearbitCsvSource: !clearbitCsvSource,
      primaryIdentifierColumn: !primaryIdentifierColumn,
      map: Object.keys(map).length !== Object.keys(columns).length,
      collection: createSegment && !collection,
      segmentName: createSegment && !segmentName,
      mappedTraitTwice:
        uniqBy(mapWithRemovedSkips, 'trait').length !==
        mapWithRemovedSkips.length,
      hasInCorrectData: !firstFiveRows.some((row) => {
        const primaryColumn = row.find(
          (column) => column.label === primaryIdentifierColumn?.label,
        );

        if (!primaryColumn?.value) return false;

        const columnValue = primaryColumn.value.normalize().trim();

        if (type === 'Company') {
          return (
            DOMAIN_REGEX.test(columnValue) && !EMAIL_REGEX.test(columnValue)
          );
        }

        return primaryIdentifierRegex(type).test(columnValue);
      }),
    };
  };

  const handleImport = async () => {
    setConfirmModalOpen(false);

    await parseCsvImport({
      variables: {
        input: {
          id: csvImport.id,
          mapping: map,
          primaryIdentifierColumn: primaryIdentifierColumn?.label,
          sourceName: clearbitCsvSource,
          createSegment,
          segmentName,
          ...(collection?.value && {
            collectionId: collection.value,
          }),
        },
      },
    });

    refetch();
  };

  if (error) return <GraphqlError error={error} refetch={refetch} />;

  return (
    <Layout
      accessoryView={
        isImporting ? (
          undefined
        ) : (
          <>
            <div className={classes.infoView}>
              <div>
                <Success />
                Successfully checked and verified&nbsp;
                <strong style={{ fontWeight: 500 }}>
                  {csvImport?.filename}
                </strong>
              </div>
              <div>
                <Link to={paths.importCsv(type)}>Choose a different file</Link>
              </div>
            </div>
            {!validations.primaryIdentifierColumn &&
              validations.hasInCorrectData && (
                <div className={classes.importError}>
                  <ErrorIcon />
                  <div className={classes.errorDetail}>
                    <span className={classes.errorTitle}>
                      We were unable to import the CSV because the {mainColumn}{' '}
                      column you identified doesn't seem to contain{' '}
                      {type === 'Company' ? 'domain names' : 'emails'}.
                    </span>
                    <span className={classes.errorSubtitle}>
                      Check your configuration below and be sure that your{' '}
                      {mainColumn} column is identified correctly before
                      retrying the import.
                    </span>
                  </div>
                </div>
              )}
            {validations.mappedTraitTwice && (
              <div className={classes.importError}>
                <ErrorIcon />
                <div className={classes.errorDetail}>
                  <span className={classes.errorTitle}>
                    We were unable to import the CSV because there are two or
                    more columns mapped to the same trait.
                  </span>
                  <span className={classes.errorSubtitle}>
                    Check your mapping below and be sure that each column maps
                    to a unique trait.
                  </span>
                </div>
              </div>
            )}
          </>
        )
      }
      border
      shadow
      tile={<ImportTile />}
      title={`Import ${plural} from CSV`}
    >
      <Helmet>
        <title>{`Import ${plural} from CSV`}</title>
      </Helmet>
      {(() => {
        if (loading && !polling && networkStatus !== 4) {
          return <LoadingArea loading />;
        }

        return (
          <>
            <div className={classes.root}>
              <div className={classes.sectionTitle}>
                <p>Map CSV columns to new or existing custom traits</p>
                <p>
                  Identify which columns from the CSV you wish to map to custom
                  traits.{' '}
                  <a
                    href="https://help.clearbit.com/hc/en-us/articles/1500005069962-Upload-CSV-files-to-Clearbit-X"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Learn more
                  </a>
                </p>
              </div>
              <div
                className={classnames(classes.box, {
                  [classes.disabledOverlay]: isSuccessful || isImporting,
                })}
              >
                <div className={classes.boxSectionHorizontal}>
                  <div className={classes.boxSectionHalf}>
                    <div className={classes.fieldLabel}>
                      Identify the{' '}
                      {type === 'Company' ? 'company domain' : 'email'} column
                      <InfoTooltip
                        title={`Choose the column that contains the ${mainColumn}`}
                      />
                    </div>
                    <div rel="primary-identifier-column">
                      <Select
                        error={!!validations.primaryIdentifierColumn}
                        formatOptionLabel={({ label, value }) => (
                          <div>
                            {label}{' '}
                            <span className={classes.optionValuePreview}>
                              {value}
                            </span>
                          </div>
                        )}
                        isDisabled={isSuccessful || isImporting}
                        onChange={(option) => {
                          const {
                            primaryIdentifierColumn: _deletedPrimaryIdentifierColumn,
                            hasInCorrectData: _deletedHasInCorrectData,
                            ...newValidations
                          } = validations;
                          setValidations(newValidations);
                          setPrimaryIdentifierColumn(option);
                        }}
                        options={columns}
                        value={primaryIdentifierColumn}
                      />
                    </div>
                  </div>
                  <div className={classes.boxSectionHalf}>
                    <div className={classes.fieldLabel}>
                      <CsvSourceIcon />
                      Clearbit CSV Source
                      <InfoTooltip
                        title={`This value is used for a new custom trait that will be added to each imported ${singular}`}
                      />
                    </div>
                    <TextField
                      disabled={isSuccessful || isImporting}
                      error={!!validations.clearbitCsvSource}
                      fullWidth
                      onChange={(e) => {
                        const {
                          clearbitCsvSource: _clearbitCsvSource,
                          segmentName: _segmentName,
                          ...newValidations
                        } = validations;

                        setValidations(newValidations);
                        if (segmentName === clearbitCsvSource) {
                          setSegmentName(e.target.value);
                        }
                        setClearbitCsvSource(e.target.value);
                      }}
                      value={clearbitCsvSource}
                    />
                    {isAnalyzed &&
                      csvImport?.sourceNameUsed &&
                      clearbitCsvSource === csvImport?.sourceName && (
                        <div className={classes.warningBanner}>
                          <div className={classes.iconContainer}>
                            <WarningIcon height={18} width={18} />
                          </div>
                          <span>
                            <strong>This source already exists.</strong> If you
                            continue, the records in this CSV will be added to
                            the same source.
                          </span>
                        </div>
                      )}
                  </div>
                </div>
                <div className={classes.boxSection}>
                  <div
                    className={classnames(classes.column, classes.columnHeader)}
                  >
                    <div className={classes.csvColumns}>
                      <p>CSV columns</p>
                    </div>
                    <div className={classes.attributeSelector}>
                      <p>Map to a custom trait</p>
                    </div>
                  </div>

                  {columns.map((column) => {
                    const { label, value } = column;
                    const currentMapping = map.find(
                      (traitMap) => label === traitMap.column,
                    );

                    return (
                      <div className={classes.column} key={label}>
                        <div className={classes.csvColumns}>
                          <div className={classes.titleValue}>
                            <span title={label}>{label}</span>
                          </div>
                          <div className={classes.titleValue}>
                            <span title={value}>{value}</span>
                          </div>
                          <ArrrowRight className={classes.arrowRight} />
                        </div>
                        <div className={classes.attributeSelector}>
                          <CreatableSelect
                            backspaceRemovesValue
                            error={validations.map && !currentMapping}
                            formatCreateLabel={(inputValue) => (
                              <span>
                                Create new trait named &ldquo;{inputValue}
                                &rdquo;
                              </span>
                            )}
                            formatOptionLabel={(option, meta) => {
                              if (option.value === SKIP_TRAIT_VALUE) {
                                return (
                                  <div
                                    className={classnames(
                                      classes.option,
                                      meta.context === 'value'
                                        ? classes.optionValue
                                        : classes.optionMenu,
                                    )}
                                  >
                                    <ClearIcon style={{ marginRight: 8 }} />
                                    {option.label}
                                  </div>
                                );
                              }

                              if (
                                option.__isNew__ &&
                                meta.context === 'value'
                              ) {
                                return (
                                  <div
                                    className={classnames(
                                      classes.option,
                                      classes.newOptionValue,
                                    )}
                                  >
                                    {option.value && (
                                      <TraitIcon
                                        className={classes.traitIconBlue}
                                      />
                                    )}
                                    {option.label}
                                    <Pill
                                      className={classes.newAdornment}
                                      color="blue"
                                    >
                                      NEW
                                    </Pill>
                                  </div>
                                );
                              }

                              if (option.__isNew__ && meta.context === 'menu') {
                                return (
                                  <div
                                    className={classnames(
                                      classes.option,
                                      classes.newOptionMenu,
                                    )}
                                  >
                                    <AddBlock
                                      style={{
                                        marginRight: 10,
                                      }}
                                    />
                                    {option.label}
                                  </div>
                                );
                              }

                              return (
                                <div
                                  className={classnames(
                                    classes.option,
                                    meta.context === 'value'
                                      ? classes.optionValue
                                      : classes.optionMenu,
                                  )}
                                >
                                  {option.value && (
                                    <TraitIcon style={{ marginRight: 8 }} />
                                  )}
                                  {option.label}
                                </div>
                              );
                            }}
                            isDisabled={isSuccessful || isImporting}
                            onChange={(searchAttribute) => {
                              const {
                                map: _deletedMap,
                                mappedTraitTwice: _deletedMappedTraitTwice,
                                ...newValidations
                              } = validations;
                              setValidations(newValidations);
                              setSearchAttribute(column, searchAttribute);
                            }}
                            options={traitOptions}
                            placeholder="Type to add a custom trait"
                            styles={{
                              option: (styles, optionProps) => {
                                const { data } = optionProps;
                                const isFirstOption =
                                  data.value === SKIP_TRAIT_VALUE;
                                const { customizedStyles } = CreatableSelect;

                                return {
                                  ...customizedStyles.option(
                                    styles,
                                    optionProps,
                                  ),
                                  borderBottom: isFirstOption
                                    ? '1px solid #9BADBC'
                                    : '',
                                };
                              },
                            }}
                            value={traitOptions.find(
                              (option) =>
                                option.value.replace('traits->', '') ===
                                snakeCase(currentMapping?.trait),
                            )}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className={classes.sectionTitle}>
                <p>Create {singular} audience</p>
                <p>
                  After importing the {plural} a new audience will automatically
                  be created based on the <strong>Clearbit CSV Source</strong>{' '}
                  attribute
                </p>
              </div>
              <div
                className={classnames(classes.box, {
                  [classes.disabledOverlay]:
                    !!csvImport?.segmentId || isImporting || isCreatingSegment,
                })}
              >
                {!isSuccessful && (
                  <div className={classes.boxSectionHorizontal}>
                    <div
                      className={classes.boxSectionFull}
                      onClick={() => {
                        if (csvImport?.segmentId || isImporting) return;

                        const {
                          segmentName: _segmentName,
                          collection: _collection,
                          ...newValidations
                        } = validations;
                        setValidations(newValidations);
                        setCreateSegment(!createSegment);
                      }}
                    >
                      <CKCheckboxNext
                        checked={createSegment}
                        classes={{ root: classes.checkbox }}
                        disabled={csvImport?.segmentId || isImporting}
                      >
                        Create a new {singular} audience from the {plural} being
                        imported
                      </CKCheckboxNext>
                    </div>
                  </div>
                )}
                {createSegment && (
                  <>
                    <div className={classes.boxSectionHorizontal}>
                      <div className={classes.boxSectionHalf}>
                        <div className={classes.fieldLabel}>Segment name</div>
                        <TextField
                          disabled={
                            isImporting ||
                            isCreatingSegment ||
                            csvImport?.segmentId
                          }
                          error={!!validations.segmentName}
                          fullWidth
                          onChange={(e) => {
                            const {
                              segmentName: _,
                              ...newValidations
                            } = validations;
                            setValidations(newValidations);
                            setSegmentName(e.target.value);
                          }}
                          value={segmentName}
                        />
                      </div>

                      <div className={classes.boxSectionHalf}>
                        <div className={classes.fieldLabel}>Collection</div>
                        <div rel="select-collection">
                          <CKSelectNext
                            isDisabled={
                              isImporting ||
                              loading ||
                              isCreatingSegment ||
                              csvImport?.segmentId
                            }
                            items={collectionNodesToOptions(collections)}
                            onChange={(option) => {
                              const {
                                collection: _,
                                ...newValidations
                              } = validations;
                              setValidations(newValidations);
                              setCollection(option);
                            }}
                            placeholder="Select a collection"
                            value={collection}
                            variant={
                              validations.collection ? 'error' : 'default'
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {isDevelopment && (
                <pre>
                  {JSON.stringify(
                    {
                      type,
                      map,
                      id,
                      primaryIdentifierColumn,
                      clearbitCsvSource,
                      createSegment,
                      segmentName,
                      collection,
                    },
                    null,
                    2,
                  )}
                </pre>
              )}
            </div>
            <Footer
              left={
                isImporting && (
                  <div className={classes.importingStatusContainer}>
                    <ImportLoadingBar className={classes.loadingBar} />
                    <div className={classes.importDetail}>
                      <span>
                        Importing {plural} (
                        {csvImport.rowsMatchedCount +
                          csvImport.rowsSkippedCount}{' '}
                        so far)
                      </span>
                      <span className={classes.rowsProcessed}>
                        You can safely navigate away from this page.
                      </span>
                    </div>
                  </div>
                )
              }
              right={
                <>
                  <CKButton
                    as={Link}
                    isDisabled={
                      isImporting ||
                      isCreatingSegment ||
                      (isSuccessful && !!csvImport.segmentId)
                    }
                    to={`/${plural}/import`}
                  >
                    Cancel
                  </CKButton>
                  <CKButton
                    className={classes.submitButton}
                    isDisabled={
                      isImporting ||
                      isCreatingSegment ||
                      (isSuccessful && !!csvImport.segmentId)
                    }
                    onClick={() => {
                      const newValidations = validate();

                      if (
                        Object.values(newValidations).some((value) => value)
                      ) {
                        setValidations(newValidations);
                        ToastsContainer.addError(
                          'There was an error when kicking off the CSV import',
                        );
                        return;
                      }

                      if (isSuccessful && !csvImport.segmentId) {
                        return createCSVSegment({
                          variables: {
                            input: {
                              id: csvImport.id,
                              segmentName,
                              collectionId: collection.value,
                            },
                          },
                        });
                      }

                      setConfirmModalOpen(true);
                    }}
                    variant="bold"
                    variantColor="blue"
                  >
                    {(() => {
                      if (isImporting || isCreatingSegment) {
                        return (
                          <SpinnerMono
                            className={classes.circularProgress}
                            height={16}
                            width={16}
                          />
                        );
                      }

                      if (isSuccessful && !csvImport.segmentId) {
                        return <span>Create audience</span>;
                      }

                      return <span>Import {plural}</span>;
                    })()}
                  </CKButton>
                </>
              }
            />
          </>
        );
      })()}
      <ConfirmDialogBase
        confirmText={`Import ${plural}`}
        message="Importing this CSV is irreversible. Are you sure you want to continue?"
        onCancel={() => setConfirmModalOpen(false)}
        onConfirm={handleImport}
        open={confirmModalOpen}
        title="Are you sure?"
      />
    </Layout>
  );
};

MapCsv.propTypes = {
  classes: PropTypes.object,
  type: PropTypes.string,
  id: PropTypes.string,
  filename: PropTypes.string,
  matches: PropTypes.number,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

export default withStyles(styles)(MapCsv);
