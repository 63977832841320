import { CKLayoutGridAuto, CKLayoutVertical } from 'clearkit';

import { buildBreakdownUsageGraph } from '~/components/usage/Details/helpers';
import {
  ProductFeature,
  SubscriptionBillingTermEnum,
  UsageBreakdownGranularityEnum,
} from '~/generated/graphql';

import CumulativeUsageChart from '../Charts/CumulativeUsageChart';
import MultiSeriesCumulativeUsageChart from '../Charts/MultiSeriesCumulativeUsageChart';

type CreditBasedLayoutProps = {
  feature: ProductFeature;
};

export function CreditBasedUsageDetailsLayout({
  feature,
}: CreditBasedLayoutProps) {
  return (
    <>
      <div className="space-y-8">
        <p className="text-base">{feature.description}</p>
        <CKLayoutGridAuto autoRepeat="auto-fit" gap="1.5rem">
          <CKLayoutVertical gap="1.5rem">
            <MultiSeriesCumulativeUsageChart
              chartColors={['dataVizA', 'dataVizE', 'dataVizD', 'dataVizC']}
              creditBreakdownChart
              endDate={feature.currentPeriodEndDate}
              granularity={UsageBreakdownGranularityEnum.Day}
              hasLegend={false}
              meteredUnit={feature.meteredUnit}
              startDate={feature.currentPeriodStartDate}
              title="Current Period Usage"
              usageMetricGraphCollection={feature.usageMetricGraph}
              usageTerm={SubscriptionBillingTermEnum.Monthly}
            />
          </CKLayoutVertical>
        </CKLayoutGridAuto>
      </div>

      {Boolean(feature.metricSubsets?.length) &&
      feature.metricSubsets?.length > 1 ? (
        <div className="w-full pt-8">
          <h2 className="text-base font-medium">
            {feature.detailedBreakdownTitle}
          </h2>
          <p className="mb-6 text-base text-gray-600">
            {feature.detailedBreakdownDescription}
          </p>

          <div className="py-4 gap-6 grid grid-cols-1 xl:grid-cols-2">
            {feature.metricSubsets?.map((metricSubset, index) => {
              return (
                <div
                  className="flex flex-col gap-6"
                  key={`${metricSubset.metricSubset}-${index}`}
                >
                  {usageBreakdownChart(feature, metricSubset)}
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
}

function usageBreakdownChart(feature: ProductFeature, metricSubsetDetail: any) {
  const usageMetricGraph = buildBreakdownUsageGraph(
    metricSubsetDetail.metricSubset,
    metricSubsetDetail.externalTypes as string[],
    metricSubsetDetail.title,
  );
  return (
    <CumulativeUsageChart
      endDate={feature.currentPeriodEndDate}
      granularity={UsageBreakdownGranularityEnum.Day}
      includeLimitMarker={false}
      meteredUnit={feature.meteredUnit}
      name={metricSubsetDetail?.title?.toLocaleLowerCase()}
      startDate={feature.currentPeriodStartDate}
      title={`${metricSubsetDetail.title}`}
      usageLimit={feature.usageLimit}
      usageMetricGraph={usageMetricGraph}
      usageTerm={feature.usageTerm}
    />
  );
}
