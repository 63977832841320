import { Lock } from '@clearkit/icons';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Subscribe } from 'unstated';

import MenuItem from '~/components/RedesignedMenuItem';
import Tooltip from '~/components/RedesignedTooltip';
import UserContainer from '~/containers/User';
import {
  AccountFlagEnum,
  Segment,
  SegmentLegacyTypeEnum,
} from '~/generated/graphql';
import { useFeatureFlag } from '~/hooks/account';
import {
  useHasLegacyPlan,
  useHasOperationsAccess,
} from '~/hooks/account/usePlatformAccess';

import ExportIcon from '../Export.svg';

type PermissionPayloadType = {
  objectAction: string;
  objectType: string;
};

type UserPermissionsType = {
  hasPermissionTo: (arg0: PermissionPayloadType) => boolean;
};

type ExportCsvMenuProps = {
  segment: Segment;
  onClick: () => void;
  type: SegmentLegacyTypeEnum;
};
export function ExportCsvMenuItem({ segment, onClick }: ExportCsvMenuProps) {
  const hasOperations = useHasOperationsAccess();
  const hasLegacyPlan = useHasLegacyPlan();
  const allowCsvExport = useFeatureFlag(AccountFlagEnum.AllowPlatformCsvExport)
    .enabled;

  const exportAccess = hasOperations || hasLegacyPlan || allowCsvExport;

  return (
    <Subscribe to={[UserContainer]}>
      {(userContainer: unknown) => {
        const objectAction = 'export';
        const objectType = 'csv';
        const hasPermission = (userContainer as UserPermissionsType).hasPermissionTo(
          {
            objectAction,
            objectType,
          },
        );

        const hasExportPermission = exportAccess || hasPermission;
        // Render disabled button if action is quotaEnforced, user doesnt have access, or there is an existing export for this segment already
        return !hasExportPermission ? (
          <Tooltip
            placement="left"
            style={{ cursor: 'pointer' }}
            title={tootlTipMessage(hasExportPermission)}
          >
            <div>
              <MenuItem disabled large>
                <ListItemIcon>
                  <Lock />
                </ListItemIcon>
                Export {segment && 'Audience'} CSV...
              </MenuItem>
            </div>
          </Tooltip>
        ) : (
          <MenuItem large onClick={() => onClick()}>
            <ListItemIcon>
              <ExportIcon />
            </ListItemIcon>
            Export {segment && 'Audience'} CSV...
          </MenuItem>
        );
      }}
    </Subscribe>
  );
}

const tootlTipMessage = (hasPermission: boolean) => {
  if (!hasPermission) {
    return "You don't have permission to access this feature. Contact your team Admin for access.";
  }
};
