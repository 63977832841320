import classnames from 'classnames';
import { CKBox, CKBoxProps } from 'clearkit';

export type AuthBoxProps = CKBoxProps;

export const AuthBox = ({ children, className, ...rest }: AuthBoxProps) => {
  return (
    <CKBox className={classnames('w-full p-6', className)} {...rest}>
      {children}
    </CKBox>
  );
};
