import { View } from '@clearkit/icons';
import { CKLayoutHorizontalProps } from 'clearkit';
import { Link, useLocation } from 'react-router-dom';

import { HubspotCrmPresence } from '~/components/AddHubSpot/HubspotCrmPresence';
import { TokenButton, TokenGroup } from '~/components/ListItem/Tokens';
import { trackEvent } from '~/lib/tracking';

import { TargetMarketsCompany } from '../types';

type CompanyLinksProps = CKLayoutHorizontalProps & {
  company: TargetMarketsCompany;
};

export const CompanyLinks = ({
  children,
  company,
  ...rest
}: CompanyLinksProps) => {
  const { domain } = company;
  const { pathname } = useLocation();

  const handleItemClick = () => {
    trackEvent('target_market_company_clicked', { domain });
  };

  return (
    <TokenGroup {...rest} gap="1rem">
      <HubspotCrmPresence domain={domain} justifyContent="end" />
      <TokenButton
        aria-label="View company"
        as={Link}
        hasToken
        onClick={handleItemClick}
        to={`${pathname}/details/${domain}`}
      >
        <View />
      </TokenButton>
      {children}
    </TokenGroup>
  );
};
