import GraphqlError from '~/components/GraphqlError';
import PageviewsList from '~/components/Sidebar/RedesignedPageviewsList';

import { usePersonEvents } from './usePersonEvents';

const PAGEVIEWS_EVENT_TYPE = 'page';

type PersonEventsListProps = {
  person: any;
  path?: string;
  className?: string;
};
export function PersonPageviewsList({
  person,
  path,
  className,
}: PersonEventsListProps) {
  const {
    error,
    loading,
    data,
    refetch,
    fetchMore,
    loadingFirstPage,
  } = usePersonEvents({ person, path, type: PAGEVIEWS_EVENT_TYPE });

  const { eventsHasNextPage, events } = data;

  if (error) {
    return <GraphqlError error={error} loading={loading} refetch={refetch} />;
  }

  return (
    <PageviewsList
      className={className}
      events={events}
      hasNextPage={eventsHasNextPage}
      id={person.id}
      loading={loadingFirstPage}
      loadingMore={loading}
      onLoadMore={fetchMore}
      path={path}
    />
  );
}
