import { CKButton, CKLayoutGridAuto } from 'clearkit';
import { useState } from 'react';

import AuthLayout, { AuthLayoutHeader } from '~/components/layouts/AuthLayout';

import { AuthBox } from '../AuthBox';
import TermsOfServiceCheck from '../shared/TermsOfServiceCheck';
import TextFieldWithValidations from '../shared/TextFieldWithValidations';
import useSuggestedProfile from '../shared/useSuggestedProfile';
import { Spinner } from '../Spinner';
import useCreateProfileAndSignIn from './useCreateProfileAndSignIn';

export type FormValidationErrors = {
  first_name?: string[];
  last_name?: string[];
};

export default function CreateHubspotProfile() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [tosAccepted, setTosAccepted] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    FormValidationErrors
  >({});

  const { isLoading, mutateAsync } = useCreateProfileAndSignIn();

  const { isLoading: suggestionLoading } = useSuggestedProfile(
    (suggestions) => {
      setFirstName(suggestions.user_first_name || '');
      setLastName(suggestions.user_last_name || '');
    },
  );

  if (suggestionLoading) {
    return (
      <AuthLayout>
        <Spinner />
      </AuthLayout>
    );
  }

  const isDisabled = !firstName.trim() || !lastName.trim() || !tosAccepted;

  async function createAccount() {
    const { isSuccess, error, redirectPath } = await mutateAsync({
      firstName,
      lastName,
    });

    if (isSuccess) {
      setValidationErrors({});
      window.location.pathname = redirectPath || '/';
      return;
    }

    if (error.action === 'unauthorized') {
      window.location.href = `/auth/login?error=${error.type}`;
      return;
    }

    if (error.error?.type === 'validation') {
      setValidationErrors(error.error.errors);
    }
  }

  return (
    <AuthLayout>
      <AuthLayoutHeader
        className="sm:w-96"
        description="Provide your details below and we'll create your account."
        header={
          <div className="w-full font-normal text-center">
            Finish setting up your account
          </div>
        }
      />
      <AuthBox>
        <form
          onSubmit={(e) => {
            createAccount();
            e.preventDefault();
          }}
        >
          <div className="flex flex-col gap-6">
            <CKLayoutGridAuto gap="1.5rem">
              <TextFieldWithValidations
                autoComplete="given-name"
                id="given-name"
                name="first_name"
                onChange={setFirstName}
                placeholder="Enter your first name"
                validationErrors={validationErrors}
                value={firstName}
              >
                <TextFieldWithValidations.Label>
                  First Name
                </TextFieldWithValidations.Label>
              </TextFieldWithValidations>

              <TextFieldWithValidations
                autoComplete="family-name"
                id="family-name"
                name="last_name"
                onChange={setLastName}
                placeholder="Enter your last name"
                validationErrors={validationErrors}
                value={lastName}
              >
                <TextFieldWithValidations.Label>
                  Last Name
                </TextFieldWithValidations.Label>
              </TextFieldWithValidations>

              <TermsOfServiceCheck
                setTosAccepted={setTosAccepted}
                tosAccepted={tosAccepted}
              />
            </CKLayoutGridAuto>
            <div>
              <CKButton
                isDisabled={isDisabled}
                isLoading={isLoading}
                type="submit"
                variant="bold"
                variantColor="blue"
              >
                Create account
              </CKButton>
            </div>
          </div>
        </form>
      </AuthBox>
    </AuthLayout>
  );
}
