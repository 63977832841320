import { configurations, googleAccountTypes } from '~/lib/configuration';

import TypeToggle from '../TypeToggle';
import Googole360Icon from './Google360.svg';
import GoogleAnalyticsIcon from './GoogleAnalytics.svg';
import GoogleSignInButton from './GoogleSignInButton';
import styles from './styles';

const types = [googleAccountTypes.standard, googleAccountTypes.google360];
const learnMoreLink =
  'https://help.clearbit.com/hc/en-us/articles/4405689303319-Connect-Google-Ads-Manager-to-Clearbit';

const GoogleConnectionForm = ({ classes, onAuth, className }) => {
  const [type, setType] = useState(0);

  return (
    <div className={className}>
      <div className={classes.description}>
        <span>
          Connect your Google Analytics account to enrich your Google Analytics
          data with Clearbit. Head to our Google Analytics{' '}
          <a
            className={classes.link}
            href={learnMoreLink}
            rel="noopener noreferrer"
            target="_blank"
          >
            documentation
          </a>{' '}
          to learn more.
        </span>
      </div>
      <hr className={classes.break} />
      <p className={classes.headline}>
        Choose the type of Google Analytics account you’re connecting to.
      </p>
      <TypeToggle
        className={classes.typeToggle}
        onChange={(_, value) => {
          setType(value);
        }}
        TypeOneLogo={GoogleAnalyticsIcon}
        typeOneText="Standard"
        TypeTwoLogo={Googole360Icon}
        typeTwoText="Analytics 360 Suite"
        value={type}
      />
      <div className={classes.buttons}>
        <GoogleSignInButton
          onClick={() => onAuth(configurations.google, types[type])}
        />
      </div>
    </div>
  );
};

GoogleConnectionForm.propTypes = {
  classes: PropTypes.shape({}),
  className: PropTypes.string,
  onAuth: PropTypes.func,
  onSkip: PropTypes.func,
};

GoogleConnectionForm.defaultProps = {
  onAuth: () => {},
  onSkip: () => {},
  classes: {},
};

export default withStyles(styles)(GoogleConnectionForm);
