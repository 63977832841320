import { CheckmarkOrb } from '@clearkit/icons';

import { MeterIcon, MeterIconProps } from './MeterIcon';

type CompleteCircleProps = MeterIconProps;

export const MeterCompleteCircle = ({ ...rest }: CompleteCircleProps) => (
  <MeterIcon {...rest} background="fill-gradient-br-green">
    <CheckmarkOrb />
  </MeterIcon>
);
