import useInterval from '~/hooks/useInterval';

import styles from './styles';

const progressSpeeds = {
  superfast: 100,
  fast: 125,
  normal: 150,
  slow: 175,
};

const LoadingBar = ({ classes, className, percentage, ...rest }) => (
  <div className={classnames(className, classes.loadingBarWrapper)}>
    <div
      className={classes.loadingBarInner}
      style={{ width: percentage + '%' }}
      {...rest}
    />
  </div>
);

const useLoadingBarState = ({
  onFinishedLoading,
  maxPercentage,
  speed,
  deferLoading,
} = {}) => {
  const [isLoading, setIsLoading] = useState(!deferLoading);
  const [percentage, setPercentage] = useState(0);
  const [steps, setSteps] = useState(0);
  const progressSpeed = progressSpeeds[speed] || progressSpeeds.normal;

  const finishLoading = () => setPercentage(100);
  const startLoading = () => {
    setSteps(0);
    setPercentage(0);
    setIsLoading(true);
  };

  useInterval(() => {
    if (isLoading && percentage < (maxPercentage || 100)) {
      const newPercentage = 100 * (1 - Math.exp(-1 * (steps / progressSpeed)));

      setSteps(steps + 1);
      setPercentage(newPercentage);
    }
  }, progressSpeed);

  const onTransitionEnd = () => {
    percentage === 100 &&
      typeof onFinishedLoading === 'function' &&
      onFinishedLoading();
  };

  return {
    startLoading,
    finishLoading,
    onTransitionEnd,
    percentage,
  };
};

LoadingBar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  percentage: PropTypes.number,
};

export { useLoadingBarState };
export default withStyles(styles)(LoadingBar);
