import { useMutation } from 'react-query';

import * as client from '~/lib/client';

export default function useSwitchAccounts() {
  return useMutation(client.switchAccount, {
    onSuccess: () => {
      client.flushAccountData();
    },
  });
}
