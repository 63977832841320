// eslint-disable-next-line clearbit-x/no-tree-shaking-imports
import { darken, fade } from '@material-ui/core/styles/colorManipulator';

export default (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },

  error: {
    paddingBottom: 20,
  },

  children: {
    flexGrow: 1,
    position: 'relative',
    marginTop: 34,
  },

  childrenTwo: {
    paddingTop: 0,
  },

  lines: {
    width: 44,
    flexShrink: 0,
    position: 'relative',
    top: 20,
    transform: 'translate(0.5px, 0.5px)',
  },

  linesTwo: {
    width: 80,
    top: 3,
    transform: 'translate(0.5px, 0.5px)',
  },

  line: {
    stroke: theme.editorStyles.tertiary,
    width: '1px',
    fill: 'none',
  },

  andOrButton: {
    height: 21,
    minWidth: 44,
    minHeight: 0,
    padding: '0px 8px',
    position: 'absolute',
    background: theme.editorStyles.tertiary,
    boxShadow: `0 1px 2px 0px ${fade(theme.editorStyles.primary, 0.1)}`,
    border: `1px solid ${theme.editorStyles.primary}`,
    borderRadius: 10,
    fontSize: 13,
    color: '#FFFFFF',
    transition: 'background ease .2s, box-shadow ease .2s',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '20px',

    '&:hover': {
      background: theme.editorStyles.tertiary,
      boxShadow: `0 1px 2px 0px ${fade(theme.editorStyles.primary, 0.4)}`,
    },

    '&:focus': {
      border: `1px solid ${theme.editorStyles.primary}`,
      outline: 'none !important',
    },

    '&:active': {
      background: darken(theme.editorStyles.tertiary, 0.1),
    },
  },

  caret: {
    marginLeft: 4,
  },

  andOrButtonTwo: {
    left: 5,
  },
});
