import { Go, UsageAltNav } from '@clearkit/icons';
import { CKButton, CKLayoutHorizontal, CKSkeleton } from 'clearkit';
import { Link } from 'react-router-dom';

import { routes } from '~/components/app/appMainRoutes';
import { AccountFlagEnum } from '~/generated/graphql';
import { useCreditUsage } from '~/hooks/account/useCreditUsage';
import useFeatureFlag from '~/hooks/account/useFeatureFlag';

import { HomeHeading } from '../HomeHeading';
import { MeterBox } from '../MeterBox';

export const Metric = ({
  value,
}: {
  value?: string | number | null | undefined;
}) => (
  <span className="mr-1 font-medium">{value?.toLocaleString('en-us')}</span>
);

export const HomeSidebarUsage = () => {
  const {
    credits,
    creditsUsed,
    freeTierDefaultCredits,
    hasUsage,
    hasCredits,
    isPaidAccount,
    loading,
  } = useCreditUsage();

  const LastInvoiceFailed = useFeatureFlag(AccountFlagEnum.LastInvoiceFailed)
    .enabled;

  if (!hasUsage || !hasCredits) {
    return null;
  }

  const displayCredits =
    isPaidAccount && LastInvoiceFailed ? freeTierDefaultCredits : credits;

  return (
    <section className="space-y-8">
      <CKLayoutHorizontal as="header">
        <HomeHeading>
          <UsageAltNav height={16} width={16} /> Credits & Usage
        </HomeHeading>
        <CKButton
          as={Link}
          rightIcon={<Go />}
          to={routes.usage}
          variant="tertiary"
          variantColor="blue"
        >
          View Usage
        </CKButton>
      </CKLayoutHorizontal>
      <CKLayoutHorizontal justifyContent="start">
        <MeterBox
          isError={hasCredits && creditsUsed > credits}
          max={!hasCredits ? 1 : displayCredits}
          value={hasCredits ? creditsUsed : 0}
        />
        {hasUsage || loading ? (
          <div className="space-y-1">
            <CKSkeleton
              className="h-6 w-[14ch] ck-skeleton--white"
              isLoading={loading}
              label="loading"
            >
              <p>
                <Metric value={creditsUsed} />
                credits used
              </p>
            </CKSkeleton>
            <CKSkeleton
              className="h-6 w-[16ch] ck-skeleton--white"
              isLoading={loading}
              label="loading"
            >
              <p>
                <Metric value={hasCredits ? displayCredits : 'Unlimited'} />
                credits available
              </p>
            </CKSkeleton>
          </div>
        ) : (
          <div className="text-sm">No Usage Data Available</div>
        )}
      </CKLayoutHorizontal>
    </section>
  );
};
