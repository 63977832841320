import * as Sentry from '@sentry/browser';

import history from '~/lib/history';

import JavascriptError from '.';

class JavascriptErrorHandler extends React.Component {
  state = {
    error: false,
  };

  componentDidMount() {
    this.listener = history.listen(this.reset);
    this.setState({ error: false });
  }

  UNSAFE_componentWillReceiveProps() {
    this.error.current = null;
    this.setState({ error: false });
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });

    if (window.location.hostname !== 'localhost') {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);

        Sentry.captureException(error);
      });
    }
  }

  componentWillUnmount() {
    if (this.listener) this.listener();
  }

  error = React.createRef();

  reset = () => {
    if (this.state.error) {
      this.setState({ error: false });
    }
  };

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return <JavascriptError error={error} />;
    }
    return children;
  }
}

JavascriptErrorHandler.propTypes = {
  classes: PropTypes.object.isRequired,
};

const wrapError = (WrappedComponent) => (props) => {
  return (
    <JavascriptErrorHandler>
      <WrappedComponent {...props} />
    </JavascriptErrorHandler>
  );
};

export default wrapError;
