import { Team } from '@clearkit/icons';
import {
  CKButton,
  CKCompanyLogo,
  CKDropdown,
  CKDropdownChevron,
  CKLayoutHorizontal,
  CKSkeleton,
} from 'clearkit';

import AccountSwitch from '~/components/AccountSwitch';
import { Account, Viewer } from '~/generated/graphql';

type HomeHeaderProps = {
  account?: Account;
  hasAccountIssue?: boolean;
  isOnboarding?: boolean;
  user?: Viewer['user'];
};

export const HomeHeader = ({
  account,
  hasAccountIssue,
  isOnboarding,
  user,
}: HomeHeaderProps) => {
  const hasData = account?.name && user?.name;
  const now = new Date();
  const time = now.getHours();

  let timeOfDay = 'morning';

  if (time >= 12 && time < 18) {
    timeOfDay = 'afternoon';
  } else if (time >= 18 && time < 24) {
    timeOfDay = 'evening';
  }

  const welcomeMessage = () => {
    let message = `good ${timeOfDay}`;

    if (isOnboarding) {
      message = "let's get you set up";
    } else if (hasAccountIssue) {
      message = "there's an issue that needs your attention";
    }

    return message;
  };

  const userGreeting = () => {
    let greeting: string | null = 'Hi';

    if (user?.firstName) {
      greeting = `${user.firstName}`;
    }

    return greeting;
  };

  return (
    <CKLayoutHorizontal
      alignItems="start"
      as="header"
      justifyContent="start"
      wrap="nowrap"
    >
      <CKCompanyLogo
        className="rounded-lg"
        domain={account?.domain || ''}
        size={48}
      />
      <div className="leading-tight min-h-14 grow shrink">
        <h1 className="mb-3 text-[1.625rem]">
          <span className="font-semibold">{userGreeting()}</span>,{' '}
          {welcomeMessage()}
        </h1>
        <CKSkeleton
          className="h-6 w-[20ch]"
          isLoading={!hasData}
          label="loading"
        >
          {hasData ? (
            <CKDropdown popoverWidth="30ch">
              <CKDropdown.Trigger>
                <CKButton variant="tertiary">
                  <span className="flex items-center gap-1">
                    <Team className="w-4 h-4 text-blue-500" />
                    {account.name}
                    <CKDropdownChevron className="!self-center" />
                  </span>
                </CKButton>
              </CKDropdown.Trigger>
              <CKDropdown.Menu className="!p-0">
                <AccountSwitch
                  accounts={user.accounts || []}
                  currentAccountId={account.id}
                />
              </CKDropdown.Menu>
            </CKDropdown>
          ) : null}
        </CKSkeleton>
      </div>
    </CKLayoutHorizontal>
  );
};
