import { GoogleServices } from '@clearkit/icons';
import { CKButton } from 'clearkit';

type GoogleSignInButtonProps = {
  className?: string;
  onClick: () => void;
};

function GoogleSignInButton({
  className = '',
  onClick,
}: GoogleSignInButtonProps) {
  return (
    <CKButton
      className={`${className} border-[#4285F4] bg-[#4285F4] text-white text-md border h-10`}
      leftIcon={
        <GoogleServices className="relative p-2 bg-white h-9 w-9 ml-[5px]" />
      }
      onClick={onClick}
      variant="clear"
    >
      <span className="px-3 py-2">Sign in with Google</span>
    </CKButton>
  );
}

export default GoogleSignInButton;
