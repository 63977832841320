import isEqual from 'lodash/isEqual';
import some from 'lodash/some';
import uniqWith from 'lodash/uniqWith';
import { createFilter } from 'react-select';

import Select from '~/components/Select';

import Input from './components/Input';
import Menu from './components/Menu';
import MenuList from './components/MenuList';
import {
  checkOption,
  flattenOptions,
  getSelectedOptions,
  matchValue,
  uncheckOption,
} from './components/MenuList/checkboxLogic';
import MultiValueRemove from './components/MultiValueRemove';
import ValueContainer from './components/ValueContainer';
import styles, { selectStyles } from './styles';

class NestedMultiselect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOptions: [],
      sectionsOpen: [],
      suggestions: [],
      activeSuggestion: -1,
      inputValue: '',
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      props,
      state: {
        ...state,
        activeSuggestion: -1,
      },
    };
  }

  handleChange = (value, type) => {
    switch (type.action) {
      case 'select-option':
        if (!type.name) return;
        this.addOption(value);
        break;
      case 'deselect-option':
        this.removeOption(value);
        break;
      case 'remove-value':
        this.removeOption(type.removedValue);
        break;
      case 'pop-value':
        this.removeOption(type.removedValue);
        break;
      default:
        break;
    }

    this.setState({ inputValue: '' });
  };

  handleSelectOption = (shouldCheck, option) => {
    if (shouldCheck) this.expandSection(option);

    return this.handleChange(option, {
      action: shouldCheck ? 'select-option' : 'deselect-option',
      name: this.props.getOptionLabel(option),
    });
  };

  expandSection = (option) => {
    const { sectionsOpen } = this.state;
    const newSectionsOpen = uniqWith([...sectionsOpen, option], isEqual);
    const options = flattenOptions(this.props.options, newSectionsOpen);

    this.setState({
      sectionsOpen: newSectionsOpen,
      options,
    });
  };

  collapseSection = (option) => {
    const { sectionsOpen } = this.state;
    const newSectionsOpen = sectionsOpen.filter(
      (sectionOpen) => !isEqual(sectionOpen, option),
    );
    const options = flattenOptions(this.props.options, newSectionsOpen);
    this.setState({
      options,
      sectionsOpen: newSectionsOpen,
    });
  };

  toggleSection = (option) => {
    let { sectionsOpen } = this.state;

    if (!some(sectionsOpen, option)) {
      this.expandSection(option);
    } else {
      this.collapseSection(option);
    }
  };

  handleKeyDown = (event) => {
    let { activeSuggestion } = this.state;
    const { suggestions, inputValue } = this.state;
    const suggestion = suggestions[activeSuggestion];
    if (suggestions.length > 0 && activeSuggestion === -1) {
      activeSuggestion = 0;
    }
    let match;
    let remainder;

    switch (event.key) {
      case 'ArrowUp':
        activeSuggestion = Math.max(--activeSuggestion, 0);
        break;
      case 'ArrowDown':
        activeSuggestion = Math.min(++activeSuggestion, suggestions.length - 1);
        break;
      case 'ArrowRight':
        if (inputValue.length === 0 || !suggestion) return;
        match = matchValue(suggestion.label, inputValue);
        remainder = suggestion.label.slice(match.index + match[0].length);
        this.handleInputChange(inputValue + remainder);
        break;
      case 'Enter':
      case 'Tab':
        this.handleReturn(suggestion);
        break;
      default:
        break;
    }

    this.setState({ activeSuggestion });
  };

  handleReturn = (option) => {
    if (!option) return;
    this.handleSelectOption(true, option);
    this.setState({ inputValue: '' });
  };

  handleInputChange = (inputValue) => {
    this.setState({ inputValue });
  };

  removeOption = (option) => {
    if (!option) return;
    const { options, onChange, getOptionValue } = this.props;
    const selectedOptions = this.selectedOptions();
    const newSelectedOptions = uncheckOption(options, selectedOptions, option);
    onChange(newSelectedOptions.map(getOptionValue));
  };

  addOption = (option) => {
    if (!option) return;
    const { options, onChange, getOptionValue } = this.props;
    const selectedOptions = this.selectedOptions();
    const newSelectedOptions = checkOption(options, selectedOptions, option);
    onChange(newSelectedOptions.map(getOptionValue));
  };

  updateSuggestions = (suggestions) => {
    this.setState({ suggestions });
  };

  selectedOptions = () => {
    const { options, value = [], getOptionValue } = this.props;
    return getSelectedOptions(options, value, getOptionValue);
  };

  render() {
    const { classes, options, title, menuListActions, ...props } = this.props;
    const {
      suggestions,
      activeSuggestion,
      inputValue,
      sectionsOpen,
    } = this.state;

    return (
      <Select
        {...props}
        activeSuggestion={activeSuggestion}
        backspaceRemovesValue
        classes={classes}
        closeMenuOnSelect={false}
        components={{
          MenuList,
          Menu,
          MultiValueRemove,
          ValueContainer,
          Input,
        }}
        expandSection={this.expandSection}
        filterOption={createFilter({ ignoreAccents: false })}
        handleSelectOption={this.handleSelectOption}
        inputValue={inputValue}
        isClearable={false}
        isMulti
        isSearchable
        menuListActions={menuListActions}
        menuListTitle={title}
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        onKeyDown={this.handleKeyDown}
        options={options}
        placeholder="Choose options"
        sectionsOpen={sectionsOpen}
        styles={selectStyles}
        suggestions={suggestions}
        toggleSection={this.toggleSection}
        updateSuggestions={this.updateSuggestions}
        value={this.selectedOptions()}
      />
    );
  }
}

NestedMultiselect.propTypes = {
  classes: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
};

NestedMultiselect.defaultProps = {
  getOptionLabel: (option) => option.label,
  getOptionValue: (option) => option.value,
  value: [],
  title: 'Choose options',
};

export default withStyles(styles)(NestedMultiselect);
