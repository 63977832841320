export const selectStyles = {
  option: (base) => ({
    ...base,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#596168',
    fontSize: 14,
    paddingTop: 4,
    paddingLeft: 13,
    paddingBottom: 3,
    cursor: 'pointer',
    '&:hover': {
      background: '#fafbfc',
    },
    ':active': {
      background: '#eee',
    },
  }),

  input: (base) => ({
    ...base,
    paddingRight: 0,
    fontSize: 15,
  }),
};

const styles = () => ({});

export default styles;
