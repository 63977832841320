import { useUserUpdateProfileMutation } from '~/generated/graphql';

export function useUpdateProfile() {
  const [updateProfile, { loading }] = useUserUpdateProfileMutation();

  return {
    updateProfile,
    loading,
  };
}
