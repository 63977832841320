import { CKSelectableItem } from 'clearkit';

import { FieldMappingGroup, FieldMappingItem } from './types';

// Conditional overwrite can come in as a boolean (from the db) or a string (from the select)
type ConditionalOverwrite = true | false | 'true' | 'false';
export function getConditionalOverwriteLabel(
  conditionalOverwrite: ConditionalOverwrite,
) {
  if (!conditionalOverwrite || conditionalOverwrite === 'false') {
    return 'Always overwrite';
  }

  return 'Conditionally overwrite';
}

export function toConditionalOverwriteItem(value = false) {
  return {
    value: value.toString(),
    label: getConditionalOverwriteLabel(value),
  };
}

const OVERWRITE_OPTIONS: CKSelectableItem[] = [
  toConditionalOverwriteItem(true),
  toConditionalOverwriteItem(false),
];
export function getConditionalOverwriteOptions() {
  return OVERWRITE_OPTIONS;
}

const CLEARBIT_SYSTEM_TRAITS_TYPE = 'system_traits';
export function isSystemFieldMapping(
  mapping: FieldMappingItem | FieldMappingGroup,
) {
  return (
    (mapping as FieldMappingItem).sourceType === CLEARBIT_SYSTEM_TRAITS_TYPE
  );
}
