const styles = () => ({
  facebookNotice: {
    minHeight: 58,
    borderTop: '1px solid #edc2f8',
    borderBottom: '1px solid #edc2f8',
    backgroundColor: '#fcf7fd',
    color: '#c363e5',
    fontSize: 14,
    padding: '12px 40px',
    display: 'flex',
    alignItems: 'center',
    marginTop: '-1px',
  },

  salesforceNotice: {
    minHeight: 57,
    backgroundColor: '#fafbfc',
    marginTop: '-1px',
    borderBottom: '1px solid rgb(225, 233, 240)',
    borderTop: '1px solid rgb(225, 233, 240)',
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
    padding: '12px 40px',
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    color: '#596168',
  },
  createExternalRecordNotice: {
    minHeight: 57,
    backgroundColor: '#fafbfc',
    marginTop: '-1px',
    borderBottom: '1px solid rgb(225, 233, 240)',
    borderTop: '1px solid rgb(225, 233, 240)',
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
    padding: '12px 24px',
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    color: '#596168',
  },
  icon: {
    marginRight: 9,
  },
});

export default styles;
