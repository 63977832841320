import MaterialTab from '@material-ui/core/Tab';
import MaterialTabs from '@material-ui/core/Tabs';
import { Link } from 'react-router-dom';

import EditIcon from './Edit.svg';
import ShowIcon from './Show.svg';
import styles from './styles';

function Tabs({ children, classes, ...props }) {
  return (
    <MaterialTabs
      classes={{
        indicator: classes.tabsIndicator,
      }}
      {...props}
    >
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          classes: {
            root: classes.tabRoot,
            labelContainer: classes.tabLabelContainer,
          },
        }),
      )}
    </MaterialTabs>
  );
}

Tabs.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
};

const icons = {
  show: ShowIcon,
  edit: EditIcon,
};

Tabs.Tab = function Tab({ disabled, icon, label, to, ...props }) {
  const component = to ? Link : undefined;

  const disabledWithFallback =
    disabled === undefined ? (to ? false : true) : disabled;

  const Icon = icons[icon];

  return (
    <MaterialTab
      component={component}
      disabled={disabledWithFallback}
      label={
        <>
          {Icon && <Icon />}
          {label}
        </>
      }
      to={to}
      {...props}
    />
  );
};

Tabs.Tab.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.oneOf(['show', 'edit']),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  to: PropTypes.string,
};

export default withStyles(styles)(Tabs);
