import { Error } from '@clearkit/icons';

import { EmptyState } from '../ProspectList/EmptyState';

export function FetchErrorRetry() {
  return (
    <>
      <div />
      <EmptyState>
        <EmptyState.Icon>
          <Error className="w-8 h-8 fill-gradient-br-red" />
        </EmptyState.Icon>
        <EmptyState.Title>
          There was a problem getting prospector data
        </EmptyState.Title>
        <EmptyState.Description>Try refreshing the page</EmptyState.Description>
      </EmptyState>
    </>
  );
}
