import { CKTextFieldNext, CKTextFieldNextProps } from 'clearkit';
import { FC } from 'react';

export type TextFieldWithValidationsProps = CKTextFieldNextProps & {
  name: string;
  validationErrors: Record<string, string[] | undefined>;
};

type TextFieldWithValidationsComposition = {
  Label: typeof CKTextFieldNext.Label;
};

const TextFieldWithValidations: FC<TextFieldWithValidationsProps> &
  TextFieldWithValidationsComposition = function({
  children,
  name,
  validationErrors,
  variant,
  ...rest
}) {
  const errors = validationErrors[name];

  return (
    <CKTextFieldNext {...rest} variant={errors ? 'error' : variant}>
      {errors?.map((message) => (
        <CKTextFieldNext.ErrorMessage>{message}</CKTextFieldNext.ErrorMessage>
      ))}
      {children}
    </CKTextFieldNext>
  );
};

TextFieldWithValidations.Label = CKTextFieldNext.Label;
TextFieldWithValidations.Label.displayName = 'CKTextFieldNext.Label';

export default TextFieldWithValidations;
