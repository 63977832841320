import { CKPicker } from 'clearkit';
import uniq from 'lodash/uniq';

import FieldLabel from '../../components/FieldLabel';

const EventTypePicker = ({ selectedEventType, options, onChange, error }) => {
  const [eventTypeOptions, setEventTypeOptions] = useState([
    { label: selectedEventType, value: selectedEventType },
  ]);

  useEffect(() => {
    const newOptions = options.map((eventType) => ({
      label: eventType,
      value: eventType,
    }));

    setEventTypeOptions(uniq([...eventTypeOptions, ...newOptions]));
  }, [options]);

  return (
    <>
      <FieldLabel for="eventType-input" label="Event type *" />
      <CKPicker
        id="eventType"
        isCreateable
        items={eventTypeOptions}
        itemToString={(item) => item.label}
        onCreateItem={(option) => {
          if (option) {
            setEventTypeOptions([...eventTypeOptions, option]);
            onChange(option.value);
          }
        }}
        onSelectedItemChange={({ inputValue }) => {
          onChange(inputValue);
        }}
        placeholder="Choose conversion event type"
        selectedItem={{ label: selectedEventType, value: selectedEventType }}
        variant={error ? 'error' : null}
      />
    </>
  );
};

export default EventTypePicker;
