const styles = () => ({
  sourceInput: {
    whiteSpace: 'pre',
    fontFamily:
      'SF Mono,Monaco,Inconsolata,Fira Mono,Droid Sans Mono,Source Code Pro,monospace',
    fontSize: 14,
  },

  subheading: {
    color: '#596168',
    flex: '0 0 auto',
    fontSize: 15,
    fontWeight: 500,
    margin: '0 0 7px',
  },

  triggerSubtext: {
    color: '#596168',
    fontSize: 14,
  },

  field: {
    '&:last-of-type': {
      marginBottom: 10,
    },
  },
});

export default styles;
