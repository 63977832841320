import {
  CKNavigationBar,
  CKNavigationItemProps,
  excludeChildrenByDisplayName,
  includeChildrenByDisplayName,
} from 'clearkit';
import { FC } from 'react';
import { NavLink, Route } from 'react-router-dom';

type NavRouterItemProps = Omit<CKNavigationItemProps, 'href' | 'as'> & {
  to: string;
  exact?: boolean;
  isActive?: boolean;
  isVisible?: boolean;
};

export const NavRouterItem: FC<NavRouterItemProps> = ({
  children,
  to,
  exact,
  isActive: isActiveProp,
  isVisible = true,
  ...rest
}) => {
  return isVisible ? (
    <Route exact={exact} path={to}>
      {({ match }) => {
        const isActive =
          isActiveProp !== undefined ? isActiveProp : !!(to && match);

        return (
          <CKNavigationBar.Item
            {...rest}
            as={NavLink}
            isActive={isActive}
            to={to}
          >
            <CKNavigationBar.ItemLeft>
              {excludeChildrenByDisplayName({
                children,
                componentDisplayName: 'CKNavigationBar.ItemRight',
              })}
            </CKNavigationBar.ItemLeft>
            {includeChildrenByDisplayName({
              children,
              componentDisplayName: 'CKNavigationBar.ItemRight',
            })}
          </CKNavigationBar.Item>
        );
      }}
    </Route>
  ) : null;
};
