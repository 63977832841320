import { Ellipsis as MoreIcon } from '@clearkit/icons';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import { Link } from 'react-router-dom';

import MenuItem from '~/components/RedesignedMenuItem';
import paths from '~/lib/paths';

import DeleteIcon from './Delete.svg';
import EditIcon from './Edit.svg';
import NewSegmentPlusIcon from './NewSegmentMenuPlusIcon.svg';

class MoreMenu extends React.Component {
  state = {
    menuOpen: false,
  };

  moreIcon = React.createRef();

  render() {
    const { type, collection, className } = this.props;
    const { menuOpen } = this.state;

    return (
      <>
        <a
          className={className}
          href="#none"
          onClick={(event) => {
            event.preventDefault();
            this.setState({ menuOpen: true });
          }}
          ref={this.moreIcon}
        >
          <MoreIcon />
        </a>

        <Menu
          anchorEl={this.moreIcon.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          getContentAnchorEl={null}
          onClose={() => {
            this.setState({ menuOpen: false });
          }}
          open={menuOpen}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem
            component={Link}
            large
            onClick={() => {
              this.setState({ menuOpen: false });
            }}
            to={{
              pathname: paths.segmentNew(type),
              state: { collection_id: collection.id },
            }}
            variant="primary"
          >
            <ListItemIcon>
              <NewSegmentPlusIcon />
            </ListItemIcon>
            New Audience
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              this.setState({ menuOpen: false });
              this.props.onRename();
            }}
            variant="primary"
          >
            <ListItemIcon>
              <EditIcon style={{ width: 14 }} />
            </ListItemIcon>
            Rename Collection
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.setState({ menuOpen: false });
              this.props.onDelete();
            }}
            variant="destructive"
          >
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            Delete Collection...
          </MenuItem>
        </Menu>
      </>
    );
  }
}

MoreMenu.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['Company', 'Person']).isRequired,
  collection: PropTypes.object.isRequired,
  onRename: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default MoreMenu;
