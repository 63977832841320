import { CKSelectableItem } from 'clearkit';

import { RecordType } from './useEnrichment/machineTypes';

export const clearbitType = (type: RecordType) =>
  type === 'company' ? 'company' : 'person';

export const isCompanyEnrichmentDisabled = (state: any, type: RecordType) => {
  const { companyPropertiesCount } = state.context;

  return (
    !isMasterEnrichmentEnabled() ||
    (type === 'company' && companyPropertiesCount === 0)
  );
};

export const isContactEnrichmentDisabled = (state: any, type: RecordType) => {
  const { contactPropertiesCount } = state.context;

  return (
    !isMasterEnrichmentEnabled() ||
    (type === 'contact' && contactPropertiesCount === 0)
  );
};

export const isDrawerVisible = (state: any) => {
  return (
    state.matches('confirmDisablingEnrichment') ||
    state.matches('disablingEnrichment') ||
    state.matches('editingMappings') ||
    state.matches('enablingEnrichmentDestination') ||
    state.matches('refreshingProperties') ||
    state.matches('savingMappings')
  );
};

export const isLoading = (state: any) => {
  return (
    state.matches('loading') ||
    state.matches('loadingSchema') ||
    state.matches('loadingMappings')
  );
};

const isMasterEnrichmentEnabled = () => {
  /**
   * @todo replace this with the actual value from the API
   */
  // const {  } = state.context;

  return true;
};

export const lookupSelectableItem = (
  value: string,
  options: CKSelectableItem[],
) => {
  return options?.find((option) => option.value === value);
};

export const pluralizeType = (type: RecordType) =>
  type === 'company' ? 'companies' : 'contacts';
