const styles = (theme) => ({
  root: {
    padding: '0px 40px 40px',
    height: '100%',

    '& a': {
      textDecoration: 'underline',
    },
  },

  title: {
    fontSize: 15,
    fontWeight: 500,
    paddingBottom: 16,
  },

  heading: {
    fontSize: 15,
    fontWeight: 500,
  },

  '@keyframes ants': {
    to: {
      backgroundPosition: '100% 100% ',
    },
  },

  dropTarget: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 40,
    marginBottom: 16,
    borderRadius: 8,
    color: '#7B8B99',
    border: '1px solid transparent',
    background: `
      linear-gradient(white, white) padding-box,
      repeating-linear-gradient(
        -45deg, rgba(192, 208, 223) 0%,
        rgba(192, 208, 223) 25%,
        transparent 0,
        transparent 50%
      ) 0 / .6em .6em`,

    '&:before': {
      width: '100%',
      height: '100%',
      background: 'rgba(240, 244, 248, 0.5)',
      pointerEvents: 'none',
      content: '""',
      display: 'block',
      position: 'absolute',
      borderRadius: 'inherit',
      zIndex: 1,
    },

    '& *': {
      zIndex: 2,
    },

    '& p': {
      margin: '8px 0 0 0',
    },
  },

  dropTargetDragging: {
    animation: 'ants 30s linear infinite',
  },

  body: {
    fontSize: 14,
    '& strong': {
      fontWeight: 500,
    },
  },

  progressBar: {
    margin: '20px auto 0',
    width: 240,
  },

  text: {
    textAlign: 'center',
    margin: '10px 0',
  },

  actions: {
    margin: '10px 0 0',
  },

  loader: {
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FFFFFF',
    border: '1px solid #E1E9F0',
    borderRadius: 5,
  },

  spinner: {
    width: 24,
    height: 24,
  },

  csvImport: {
    padding: '12px 24px',
    border: '1px solid #e1e9f0',
    borderBottom: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 15,
    color: '#7B8B99',
    ':not(&) + &': {
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
    '&:last-of-type': {
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      borderBottom: '1px solid #e1e9f0',
    },
    '& svg': {
      marginRight: 4,
      flexShrink: 0,
    },
    '& span': {
      '& a': {
        textDecoration: 'none',
      },
      '&:nth-child(2)': {
        fontWeight: '500',
        color: '#27282A',
      },
      '&:last-child': {
        marginLeft: 'auto',
        marginRight: 0,
      },
    },
  },

  rowCountPill: {
    marginLeft: 8,
    fontSize: 11,
    fontWeight: 600,
    borderRadius: 9,
    padding: '2px 5px 2px',
  },

  userText: {
    marginLeft: 16,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },

  sourceName: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },

  inProgressLoader: {
    width: 10,
    height: 10,
  },

  success: {
    color: theme.palette.text.success,
  },

  error: {
    color: theme.palette.text.error,
  },

  moreMenu: {
    background: 'rgb(240, 244, 248)',
    borderRadius: 6,
    border: '1px solid rgba(8, 56, 100, 0.1)',
    boxShadow: '0px 0px 1px 0px rgba(0, 0, 0, 0.05)',
    height: 30,
    width: 40,

    '& svg': {
      margin: 0,
    },
  },

  menuItem: {
    display: 'flex',
    alignItems: 'center',
  },

  moreIcon: {
    marginRight: 7,

    '& path': {
      fill: '#596168',
    },
  },

  importingOptions: {
    display: 'flex',
    alignItems: 'center',
  },

  errorOptions: {
    display: 'flex',
    alignItems: 'center',
  },

  errorBreak: {
    border: '0.5px solid #e1e9f0;',
    margin: '32px 0 12px',
  },

  errorIcon: {
    width: 32,
    height: 32,
    borderRadius: 5,
    background: '#ffffff',
    border: '1px solid #e1e9f0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& svg': {
      height: 22,
      width: 22,
    },
  },

  importingLoader: {
    marginRight: 17,
  },

  importStatusContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 17,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
});

export default styles;
