import { CKCompanyLogo } from 'clearkit';

import TwoLineMenuItem from './TwoLineMenuItem';

const SalesforceAccountMenuItem = ({
  salesforceAccount,
  salesforceAccountConnector,
}) => (
  <TwoLineMenuItem
    externalLinkClass="text-salesforce"
    href={`${salesforceAccountConnector.externalInstanceUrl}/${salesforceAccount.externalId}`}
    icon={
      <CKCompanyLogo
        className="shadow-sm"
        domain={salesforceAccount.websiteDomain}
        size={32}
      />
    }
    subtitle={<>Owned by {salesforceAccount?.owner?.name || 'Unknown User'}</>}
    title={
      <>
        <span className="font-semibold">{salesforceAccount.name}</span> account
      </>
    }
  />
);

SalesforceAccountMenuItem.propTypes = {
  salesforceAccount: PropTypes.shape({
    opportunities: PropTypes.array,
    name: PropTypes.string,
    externalId: PropTypes.string,
    websiteDomain: PropTypes.string,
  }),
  salesforceAccountConnector: PropTypes.shape({
    externalInstanceUrl: PropTypes.string,
  }),
};

export default SalesforceAccountMenuItem;
