import { routes } from '../app/appMainRoutes';

const root = routes.enrichment;
const mappingRoute = `${root}/mappings`;

export const enrichmentRoutes = {
  root,
  company: `${mappingRoute}/company`,
  contact: `${mappingRoute}/contact`,
};

export const MAX_LIST_ENRICHMENTS_PER_HOUR = 3;
export const MAX_LIST_ENRICHMENTS_LIMIT_MESSAGE =
  'You have reached the list enrichment limit this hour. Please try again later.';
