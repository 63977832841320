import some from 'lodash/some';

import { AccountNotificationFragment } from '~/generated/graphql';

const ACCOUNT_CONNECTOR_TYPES = [
  'account_connector_auth',
  'account_connector_confirm_user',
  'account_connector_terms',
  'salesforce_user_api_disabled',
];

export const ENRICHMENT_TYPES = ['account_connector_enrichment_error'];
const USAGE_TYPES = ['usage_credits_over_quota'];

const DESTINATION_TYPES = [
  'destination_archived_slack_channel',
  'google_conversion_action_not_found',
];

type NotificationTypes = AccountNotificationFragment['notificationType'][number];

const documentationLinks = {
  account_connector_terms:
    'https://help.clearbit.com/hc/en-us/articles/360043723133-Troubleshooting-Facebook-Ads',
  salesforce_user_api_disabled:
    'https://help.clearbit.com/hc/en-us/articles/360056896793-Troubleshooting-Salesforce-Syncs',
  salesforce_sync_error:
    'https://help.clearbit.com/hc/en-us/articles/360056896793-Troubleshooting-Salesforce-Syncs',
  salesforce_org_api_disabled:
    'https://help.clearbit.com/hc/en-us/articles/360056896793-Troubleshooting-Salesforce-Syncs',
};

const isEnrichment = (notificationType: NotificationTypes) =>
  ENRICHMENT_TYPES.some((type) => notificationType === type);

const isUsage = (notificationType: NotificationTypes) =>
  USAGE_TYPES.some((type) => notificationType === type);

export const isDismissableNotification = (
  notificationType: NotificationTypes,
) => {
  switch (notificationType) {
    case 'usage_credits_over_quota':
      return true;
  }
};
export function buildAccountNotification(
  message: string | undefined | null,
  notificationType: NotificationTypes,
  readableSourceName = 'Salesforce',
) {
  const isEnrichmentNotification = isEnrichment(notificationType);
  const isUsageNotification = isUsage(notificationType);
  const pickType = (types: NotificationTypes[]) =>
    types.some((type) => notificationType == type);
  const documentationLink =
    documentationLinks[notificationType as keyof typeof documentationLinks];
  const messageDescription = !isEnrichmentNotification ? message : null;

  let messageTitle;
  switch (true) {
    case isEnrichmentNotification:
      messageTitle = `Clearbit was unable to update your fields in ${readableSourceName}.`;
      break;
    case isUsageNotification:
      messageTitle = `Your account has reached it's credit limit`;
      break;
    default:
      messageTitle = null;
  }

  const buildHelpText = () => {
    if (isUsageNotification) {
      return 'Upgrade plan';
    }

    return documentationLink ? 'Read the docs' : 'Fix this issue';
  };

  return {
    shouldRenderNotification: (isFromEnrichmentPage: boolean) => {
      return isFromEnrichmentPage
        ? isEnrichmentNotification
        : !isEnrichmentNotification;
    },
    isEnrichmentNotification,
    isAccountConnectorNotification: pickType(ACCOUNT_CONNECTOR_TYPES),
    isDestinationNotification: pickType(DESTINATION_TYPES),
    documentationLink,
    helpText: buildHelpText(),
    messageDescription,
    messageTitle,
    isUsageNotification,
  };
}

type OptionsType = {
  isFromEnrichmentPage?: boolean | undefined;
};

export function hasNotifications(
  notifications: AccountNotificationFragment[],
  options: OptionsType = {},
) {
  if (!some(notifications)) {
    return false;
  }

  if (options.isFromEnrichmentPage) {
    return some(notifications, (notification) =>
      isEnrichment(notification.notificationType),
    );
  }

  const notificationsWithoutEnrichment = notifications.filter(
    (notification) => !isEnrichment(notification.notificationType),
  );

  return some(notificationsWithoutEnrichment);
}
