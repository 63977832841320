import { CKLayoutGridAuto, CKLayoutVertical } from 'clearkit';

import {
  buildBreakdownUsageGraph,
  buildUsageMetricGraphCollection,
} from '~/components/usage/Details/helpers';
import {
  AccountFlagEnum,
  ProductFeature,
  SubscriptionBillingTermEnum,
} from '~/generated/graphql';
import { useFeatureFlag } from '~/hooks/account';

import CumulativeUsageChart from '../Charts/CumulativeUsageChart';
import MonthlyUsageChart from '../Charts/MonthlyUsageChart';
import MultiSeriesCumulativeUsageChart from '../Charts/MultiSeriesCumulativeUsageChart';
import UsageDetailCard from '../UsageDetailCard';

type DefaultLayoutProps = {
  feature: ProductFeature;
};

export function DefaultLayout({ feature }: DefaultLayoutProps) {
  const showDetailedBreakdown = useFeatureFlag(
    AccountFlagEnum.AllowPlatformUsageDetailedBreakdown,
  ).enabled;

  const featureUsageKey = Object.keys(feature.usageMetricGraph)[0];

  return (
    <>
      <div className="space-y-8">
        <p className="text-base">{feature.description}</p>
        <CKLayoutGridAuto autoRepeat="auto-fit" gap="1.5rem">
          <UsageDetailCard
            currentPeriodEndDate={feature.currentPeriodEndDate}
            currentPeriodTotalUsage={feature.currentPeriodTotalUsage}
            meteredUnit={feature.meteredUnit}
            usageLimit={feature.usageLimit}
          />
          <CKLayoutVertical gap="1.5rem">
            {feature.usageTerm === SubscriptionBillingTermEnum.Monthly ? (
              <MonthlyUsageChart
                endDate={feature.currentPeriodEndDate}
                meteredUnit={feature.meteredUnit}
                name={featureUsageKey}
                startDate={feature.currentPeriodStartDate}
                usageLimit={feature.usageLimit}
                usageMetricGraph={feature.usageMetricGraph}
                usageTerm={feature.usageTerm}
              />
            ) : (
              <CumulativeUsageChart
                endDate={feature.currentPeriodEndDate}
                meteredUnit={feature.meteredUnit}
                name={featureUsageKey}
                startDate={feature.currentPeriodStartDate}
                usageLimit={feature.usageLimit}
                usageMetricGraph={feature.usageMetricGraph}
                usageTerm={feature.usageTerm}
              />
            )}
          </CKLayoutVertical>
        </CKLayoutGridAuto>
      </div>

      {showDetailedBreakdown &&
      Boolean(feature.metricSubsets?.length) &&
      feature.metricSubsets?.length > 1 ? (
        <div className="w-full pt-8">
          <h2 className="text-base font-medium">
            {feature.detailedBreakdownTitle}
          </h2>
          <p className="mb-6 text-base text-gray-600">
            {feature.detailedBreakdownDescription}
          </p>

          <div className="py-4 gap-6 grid grid-cols-1 xl:grid-cols-2">
            {feature.metricSubsets?.map((metricSubset, index) => {
              return (
                <div
                  className="flex flex-col gap-6"
                  key={`${metricSubset.metricSubset}-${index}`}
                >
                  {usageBreakdownChart(feature, metricSubset)}
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
}

function usageBreakdownChart(feature: ProductFeature, metricSubsetDetail: any) {
  const usageMetricGraph = buildBreakdownUsageGraph(
    metricSubsetDetail.metricSubset,
    metricSubsetDetail.externalTypes as string[],
    metricSubsetDetail.title,
  );
  if (metricSubsetDetail.additionalBreakdownMetric) {
    return (
      <MultiSeriesCumulativeUsageChart
        endDate={feature.currentPeriodEndDate}
        meteredUnit={feature.meteredUnit}
        startDate={feature.currentPeriodStartDate}
        title={metricSubsetDetail.title}
        usageLimit={feature.usageLimit}
        usageMetricGraphCollection={buildUsageMetricGraphCollection(
          usageMetricGraph,
          metricSubsetDetail.metricSubset,
          metricSubsetDetail.additionalBreakdownMetric,
          metricSubsetDetail.additionalBreakdownMetricValues as string[],
          metricSubsetDetail.title,
        )}
        usageTerm={feature.usageTerm}
      />
    );
  } else if (feature.usageTerm === SubscriptionBillingTermEnum.Monthly) {
    return (
      <MonthlyUsageChart
        endDate={feature.currentPeriodEndDate}
        includeLimitMarker={false}
        meteredUnit={feature.meteredUnit}
        name={metricSubsetDetail.title}
        startDate={feature.currentPeriodStartDate}
        title={metricSubsetDetail.title}
        usageLimit={feature.usageLimit}
        usageMetricGraph={usageMetricGraph}
        usageTerm={feature.usageTerm}
      />
    );
  } else {
    return (
      <CumulativeUsageChart
        endDate={feature.currentPeriodEndDate}
        includeLimitMarker={false}
        meteredUnit={feature.meteredUnit}
        name={metricSubsetDetail.title}
        startDate={feature.currentPeriodStartDate}
        title={metricSubsetDetail.title}
        usageLimit={feature.usageLimit}
        usageMetricGraph={usageMetricGraph}
        usageTerm={feature.usageTerm}
      />
    );
  }
}
