import { CompaniesNav, TeamNav } from '@clearkit/icons';
import {
  // CKActionMenu,
  CKButton,
  CKContainerProps,
  CKLayoutGridAuto,
  CKLayoutHorizontal,
  CKRotatingIcon,
  // CKPopover,
} from 'clearkit';

import { ItemCount } from '~/components/ListItem/ItemCount';
import { PageLayout } from '~/components/PageLayout';
import { ProspectFilters } from '~/generated/graphql';

import {
  MAX_COUNT_HIDE,
  MAX_COUNT_HIDE_LABEL,
} from '../useProspector/constants';
import { hideListSmall } from './constants';
import { FilterPreview } from './FilterPreview';
import { PaginationButtons } from './PaginationButtons';

const countElementsInObject = (object: []) =>
  Object.values(object)
    .filter((value) => Array.isArray(value))
    .reduce(
      (total: number, currentArray: string[]) => total + currentArray.length,
      0,
    );

type ListHeaderProps = CKContainerProps & {
  companyQuery?: any;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  isFilterPreviewOpen?: boolean;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
  onToggleFilterPreview: () => void;
  onToggleFilterSidebar: () => void;
  pageEnd?: number;
  pageStart?: number;
  personQuery?: any;
  previousTotalCompanies?: number;
  previousTotalProspects?: number;
  prospectFilters: ProspectFilters;
  totalCompanies?: number;
  totalProspects?: number;
};

// const ActionMenu = (
//   <CKActionMenu>
//     <CKActionMenu.Item>Display all</CKActionMenu.Item>
//     <CKActionMenu.Item>Display only selected</CKActionMenu.Item>
//   </CKActionMenu>
// );

export const ListHeader = ({
  companyQuery,
  isFilterPreviewOpen = true,
  hasNextPage,
  hasPreviousPage,
  pageStart,
  pageEnd,
  onNextPage,
  onPreviousPage,
  onToggleFilterPreview,
  onToggleFilterSidebar,
  previousTotalCompanies = 0,
  previousTotalProspects = 0,
  personQuery,
  prospectFilters,
  totalProspects = 0,
  totalCompanies = 0,
}: ListHeaderProps) => {
  const filterCount =
    countElementsInObject(personQuery) + countElementsInObject(companyQuery);

  return (
    <PageLayout.HeaderWrapper className="py-3 pb-3 xl:py-3 space-y-4">
      <CKLayoutHorizontal className="@item/list:flex-nowrap">
        <CKLayoutHorizontal
          className="basis-full grow"
          gap="1.5rem"
          justifyContent="start"
        >
          <CKButton
            isDisabled={filterCount === 0}
            leftIcon={<CKRotatingIcon isOpen={isFilterPreviewOpen} />}
            onClick={onToggleFilterPreview}
            variant="tertiary"
            variantColor="blue"
          >
            {filterCount} filters
          </CKButton>
          <CKLayoutGridAuto
            autoRepeat="auto-fit"
            className="min-w-0 basis-auto shrink grow max-w-[26rem]"
            minWidth="8rem"
          >
            <ItemCount
              end={totalCompanies}
              hideCountAbove={MAX_COUNT_HIDE}
              hideCountLabel={MAX_COUNT_HIDE_LABEL}
              label={totalCompanies === 1 ? 'company' : 'companies'}
              start={previousTotalCompanies}
            >
              <CompaniesNav height={18} width={18} />
            </ItemCount>
            <ItemCount
              end={totalProspects}
              hideCountAbove={MAX_COUNT_HIDE}
              hideCountLabel={MAX_COUNT_HIDE_LABEL}
              label={totalProspects === 1 ? 'prospect' : 'prospects'}
              start={previousTotalProspects}
            >
              <TeamNav height={18} width={18} />
            </ItemCount>
          </CKLayoutGridAuto>
        </CKLayoutHorizontal>
        <CKLayoutHorizontal
          className="basis-auto shrink-0"
          justifyContent="end"
        >
          {/* <CKPopover popover={ActionMenu} wrapperClassName="relative">
            </CKLayoutHorizontal>
            <CKPopover.Trigger
              className="!text-sm"
              variant="tertiary"
              variantColor="blue"
            >
              <span className={hideListSmall}>Displaying</span>
              {pageStart} - {pageEnd}
            </CKPopover.Trigger>
          </CKPopover> */}
          <div className="text-sm text-gray-600">
            <span className={hideListSmall}>Displaying</span>{' '}
            <span>
              {pageStart} – {pageEnd}
            </span>
          </div>
          <PaginationButtons
            hasNextPage={hasNextPage}
            hasPreviousPage={hasPreviousPage}
            onNextPage={onNextPage}
            onPreviousPage={onPreviousPage}
          />
        </CKLayoutHorizontal>
      </CKLayoutHorizontal>
      <FilterPreview
        companyQuery={companyQuery}
        isOpen={isFilterPreviewOpen}
        onEditFilters={onToggleFilterSidebar}
        personQuery={personQuery}
        prospectFilters={prospectFilters}
      />
    </PageLayout.HeaderWrapper>
  );
};
