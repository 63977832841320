import { ExportType } from './types';

export const DEFAULT_EXPORT_TYPE: ExportType = 'contact';
export const DEFAULT_FIELD_MAPPING = {};
export const DEFAULT_EXPORT_CONTEXT = {
  isValid: true,
  isLoading: true,
  exportType: DEFAULT_EXPORT_TYPE,
  fieldMapping: DEFAULT_FIELD_MAPPING,
  optionalFields: [],
  requiredFields: [],
  requiredFieldKeys: [],
  setExportType: () => {},
  setFieldMapping: () => {},
};

export const DEFAULT_FIELDS = [
  'Company',
  'Email',
  'FirstName',
  'LastName',
  'LeadSource',
];
