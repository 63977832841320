import { useMachine } from '@xstate/react';
import { createContext, ReactNode, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Interpreter, State } from 'xstate';

import { useStatusToast } from '~/components/unified/hooks';
import useQueryParams from '~/hooks/useQueryParams';

import { machine as createMachine } from './machine';

/**
 * @todo type the events properly. Wasn't having any luck there, but
 * at least have context typed.
 * @todo type the context properly. Wasn't having any luck there, but
 */
export type ProspectorMachine = [
  // State<typeof machine.context, any, any, any>,
  // Interpreter<typeof machine.context, any, any, any>['send'],
  State<any, any, any, any>,
  Interpreter<any, any, any, any>['send'],
];
/**
 * @todo type this properly. I couldn't even get GPT-4 to do it, so
 * not going to fight it while we try to get the basic glue in place.
 *
 * Most importantly, in `useProspector` we cast context back to
 * ProspectorMachine so all the TS/DX goodness remains.
 */
export const ProspectorContext = createContext<any>([]);

type ProspectorProviderProps = {
  children: ReactNode;
};
export function ProspectorProvider({ children }: ProspectorProviderProps) {
  const { addSuccessToast, addErrorToast } = useStatusToast();
  const params = useParams() as { id: string };
  const queryParams = useQueryParams();
  const isNewList = queryParams.has('new');
  const domainsFromUrlParam = queryParams.get('domain');
  const source = queryParams.get('source');

  const machine = useMemo(() => {
    const companyQuery = domainsFromUrlParam
      ? { domains: domainsFromUrlParam.split(',') }
      : {};

    return createMachine({
      id: params.id,
      isNewList: isNewList,
      addSuccessToast,
      addErrorToast,
      companyQuery,
      source,
    });
  }, [params.id, isNewList, domainsFromUrlParam, source]);
  const [state, send] = useMachine(machine);

  useEffect(() => {
    send('PROSPECT_LIST_CHANGE', { id: params.id });
  }, [params.id]);

  return (
    <ProspectorContext.Provider value={[state, send]}>
      {children}
    </ProspectorContext.Provider>
  );
}
