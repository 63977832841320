import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { CKBox, CKRadio } from 'clearkit';
import { RadioGroup } from 'reakit/Radio';

import { hasError } from '~/lib/graphql';

import styles from './styles';

const TriggerType = ({
  classes,
  className,
  errors,
  handleChange,
  triggerType,
  triggerTypes,
}) => (
  <CKBox className={classnames('p-6 mt-3 mb-6', className)}>
    <FormControl
      className={classes.formControl}
      error={hasError(errors, 'triggerType')}
      required
    >
      {hasError(errors, 'triggerType') && (
        <FormHelperText className={classes.errorText}>
          You must choose a trigger type
        </FormHelperText>
      )}

      <RadioGroup
        aria-label="trigger type"
        onChange={(event) => {
          handleChange('triggerType', event.target.value);
        }}
        value={triggerType}
      >
        <div className="flex flex-col space-y-2">
          {triggerTypes.map(({ value, label }) => (
            <CKRadio
              checked={triggerType === value}
              classes={{ root: classes.radioRoot }}
              id={value}
              isDisabled={triggerTypes.length <= 1}
              key={value}
              label={label}
              value={value}
            />
          ))}
        </div>
      </RadioGroup>
    </FormControl>
  </CKBox>
);

TriggerType.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  errors: PropTypes.array,
  handleChange: PropTypes.func,
  triggerType: PropTypes.string,
  triggerTypes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.node,
    }),
  ),
};

export default withStyles(styles)(TriggerType);
