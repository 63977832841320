import { Container } from 'unstated';

const defaultState = () => ({
  title: '',
  text: '',
  showConfirmDialog: false,
  confirmDialogTitle: '',
  confirmDialogText: '',
  onDialogConfirm: null,
  onDialogCancel: null,
});

class ConfirmDialogContainer extends Container {
  state = {
    ...defaultState(),
  };

  reset = (customState = {}) => {
    this.setState({ ...defaultState(), ...customState });
  };

  showConfirmDialog = ({
    title,
    text,
    confirm,
    cancel = this.hideConfirmDialog,
  }) => {
    clearTimeout(this.hideConfirmDialogTimeout);
    this.setState({
      show: true,
      title: title,
      text: text,
      confirm: () => {
        confirm();
        this.hideConfirmDialog();
      },
      cancel: cancel,
    });
  };

  hideConfirmDialog = () => {
    this.setState(
      {
        show: false,
      },
      () => {
        this.hideConfirmDialogTimeout = setTimeout(() => {
          this.setState({ ...defaultState() });
        }, 500);
      },
    );
  };
}

export default ConfirmDialogContainer;
