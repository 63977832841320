import useCurrentAccount from './useCurrentAccount';
import useDeveloperOverride from './useDeveloperOverride';

export type PlatformAccess = {
  legacyPlan: boolean;
  acquisitions: boolean;
  capture: boolean;
  conversions: boolean;
  operations: boolean;
  usage: boolean;
};

export function usePlatformAccess(): PlatformAccess {
  const { account, loading } = useCurrentAccount();
  const overrides = usePlatformAccessOverrides();

  if (loading || !account) {
    return {
      legacyPlan: true,
      acquisitions: false,
      capture: false,
      conversions: false,
      operations: false,
      usage: false,
    };
  }

  const platformAccess = account.platformAccess;

  return {
    legacyPlan: overrides.legacyPlan.value(platformAccess.legacyPlan),
    acquisitions: overrides.acquisitions.value(platformAccess.acquisitions),
    capture: overrides.capture.value(platformAccess.capture),
    conversions: overrides.conversions.value(platformAccess.conversions),
    operations: overrides.operations.value(platformAccess.operations),
    usage: overrides.usage.value(platformAccess.usage),
  };
}

export function usePlatformAccessOverrides() {
  return {
    legacyPlan: useDeveloperOverride('PLATFORM_ACCESS_LEGACY_PLAN'),
    acquisitions: useDeveloperOverride('PLATFORM_ACCESS_ACQUISITIONS'),
    capture: useDeveloperOverride('PLATFORM_ACCESS_CAPTURE'),
    conversions: useDeveloperOverride('PLATFORM_ACCESS_CONVERSIONS'),
    operations: useDeveloperOverride('PLATFORM_ACCESS_OPERATIONS'),
    usage: useDeveloperOverride('PLATFORM_ACCESS_USAGE'),
  };
}

export function useHasAcquisitionsAccess() {
  return usePlatformAccess().acquisitions;
}

export function useHasCaptureAccess() {
  return usePlatformAccess().capture;
}

export function useHasConversionsAccess() {
  return usePlatformAccess().conversions;
}

export function useHasOperationsAccess() {
  return usePlatformAccess().operations;
}

export function useHasUsageAccess() {
  return usePlatformAccess().usage;
}

export function useHasLegacyPlan() {
  return usePlatformAccess().legacyPlan;
}
