import { Ellipsis as EllipsisIcon, Trash as DeleteIcon } from '@clearkit/icons';
import { CKActionMenu, CKButton, CKPopover } from 'clearkit';

function AllowedSiteMenu({ className, onDelete }) {
  return (
    <CKPopover
      aria-label="Popover"
      baseId="allowed-site-popover"
      className="z-10"
      placement="bottom-end"
      popover={
        <CKActionMenu>
          <CKActionMenu.Item
            icon={<DeleteIcon />}
            isDestructive
            onClick={onDelete}
          >
            Remove domain
          </CKActionMenu.Item>
        </CKActionMenu>
      }
    >
      <CKButton className={className} size="small">
        <EllipsisIcon />
      </CKButton>
    </CKPopover>
  );
}

export default AllowedSiteMenu;
