import { AddBlock } from '@clearkit/icons';
import { CKButton } from 'clearkit';
import { Link } from 'react-router-dom';

import { trackEvent } from '~/lib/tracking';

import { NEW_TARGET_MARKET_LIST } from '../constants';

export const ButtonNewList = () => {
  const trackNewTargetMarket = () => {
    trackEvent('target_market_new_list_clicked');
  };

  return (
    <CKButton
      as={Link}
      leftIcon={<AddBlock />}
      onClick={trackNewTargetMarket}
      to={NEW_TARGET_MARKET_LIST}
      variant="bold"
      variantColor="blue"
    >
      New list
    </CKButton>
  );
};
