import { TargetMarketFiltersType } from './types';

export const ONBOARDING_STEPS = [1, 2, 3, 4, 5];
export const ONBOARDING_ICP_AUDIENCE_NAME = 'Main ICP';

export const DEFAULT_FILTERS: TargetMarketFiltersType = {
  businessModel: ['B2B'],
  country: ['United States'],
  employees: [],
  industry: ['Consumer Services', 'IT Services', 'Professional Services'],
  raised: [],
  tag: [],
  tech: [],
  type: [],
};
