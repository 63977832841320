import {
  ClearbitIdentity as ClearbitIcon,
  FacebookServices as FacebookIcon,
  G2Services as G2Icon,
  GoogleAdsServices as GoogleAdsIcon,
  GoogleAnalyticsServices as GoogleAnalyticsIcon,
  GoogleTagmanagerServices as GoogleTagmanagerIcon,
  HubspotServices as HubspotIcon,
  MarketoServices as MarketoIcon,
  SalesforceServices as PardotIcon,
  SalesforceServices as SalesforceIcon,
  SegmentServices as SegmentIcon,
  SlackServices as SlackIcon,
} from '@clearkit/icons';
import { capitalize } from 'lodash';
import { ReactNode } from 'react';

import { AccountConnectorServiceEnum } from '~/generated/graphql';

type allServiceTypes =
  | AccountConnectorServiceEnum
  | 'pardot'
  | 'google_analytics'
  | 'google_tag_manager'
  | 'google_ads'
  | 'clearbit_tag';

export function getIntegrationNameFromService(service: string) {
  if (service === AccountConnectorServiceEnum.Salesforcesandbox) {
    return AccountConnectorServiceEnum.Salesforce;
  }

  return service;
}

export function getIntegrationIcon(service: allServiceTypes) {
  switch (service) {
    case AccountConnectorServiceEnum.Salesforce:
      return <SalesforceIcon />;
    case AccountConnectorServiceEnum.Hubspot:
      return <HubspotIcon />;
    case AccountConnectorServiceEnum.Marketo:
      return <MarketoIcon />;
    case AccountConnectorServiceEnum.Slack:
      return <SlackIcon />;
    case 'pardot':
      return <PardotIcon />;
    case 'google_analytics':
      return <GoogleAnalyticsIcon />;
    case 'google_tag_manager':
      return <GoogleTagmanagerIcon />;
    case 'clearbit_tag':
      return <ClearbitIcon />;
    case 'google_ads':
      return <GoogleAdsIcon />;
    case AccountConnectorServiceEnum.Facebook:
      return <FacebookIcon />;
    case AccountConnectorServiceEnum.Segment:
      return <SegmentIcon />;
    case AccountConnectorServiceEnum.G2:
      return <G2Icon />;
    default:
      return null;
  }
}

export type IntegrationConfiguration = {
  accountConnector?: any;
  icon: ReactNode;
  configuredPath: string;
  service: AccountConnectorServiceEnum;
  name: string;
  disabled: boolean;
};

export function getIntegrationConfigs(
  integrations: AccountConnectorServiceEnum[],
  accountConnectors: any[],
  baseConfiguredPath: string,
): IntegrationConfiguration[] {
  return integrations.map((service) => {
    const matchingAc = accountConnectors.find((ac) => ac.service === service);
    return {
      accountConnector: matchingAc,
      icon: getIntegrationIcon(service),
      name: capitalize(service),
      configuredPath: `${baseConfiguredPath}/${service}`,
      service: service,
    } as IntegrationConfiguration;
  });
}
