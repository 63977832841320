import { useMutation } from 'react-query';

export default function useCreateProfileAndSignIn() {
  return useMutation(
    async (params: {
      firstName: string | undefined;
      lastName: string | undefined;
    }) => {
      const response = await fetch('/auth/signup/profile_for_hubspot', {
        method: 'POST',
        body: JSON.stringify({
          first_name: params.firstName,
          last_name: params.lastName,
          anonymous_id: window.analytics?.user?.()?.anonymousId?.(),
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        return {
          isSuccess: true,
          redirectPath: getPath(response.url),
        };
      } else {
        try {
          const data = await response.json();
          return { isSuccess: false, error: data };
        } catch (e) {
          return { isSuccess: false, error: { action: 'unauthorized' } };
        }
      }
    },
  );
}

function getPath(url: string) {
  const parsedUrl = new URL(url);
  return parsedUrl.pathname;
}
