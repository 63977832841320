import classnames from 'classnames';
import {
  CKButton,
  CKButtonProps,
  CKLayoutHorizontal,
  CKLayoutHorizontalProps,
  CKSkeleton,
  CKTooltip,
} from 'clearkit';

import { CRMPresence } from '~/components/CRMPresence/CRMPresence';
import { HubSpotBadge } from '~/components/CRMPresence/HubSpotBadge';
import { useAddHubspot } from '~/components/CRMPresence/useAddHubspot';

type HubspotCrmPresenceProps = CKLayoutHorizontalProps & {
  domain: string;
  name?: string;
};

const AddButton = ({
  children = 'Add to HubSpot',
  ...props
}: CKButtonProps) => (
  <CKButton {...props} variant="tertiary" variantColor="blue">
    {children}
  </CKButton>
);

export const HubspotCrmPresence = ({
  className,
  domain,
  justifyContent = 'start',
  name,
  ...rest
}: HubspotCrmPresenceProps) => {
  const { onAddHubSpot, isLoading } = useAddHubspot();

  const handleAddHubSpot = async () => {
    await onAddHubSpot([{ domain, name }]);
  };

  return (
    <CRMPresence domain={domain}>
      <CKLayoutHorizontal
        {...rest}
        className={classnames('w-[14ch]', className)}
        justifyContent={justifyContent}
      >
        <CRMPresence.WhenPresent>
          <HubSpotBadge />
        </CRMPresence.WhenPresent>
        <CRMPresence.WhenNotPresent>
          <AddButton isLoading={isLoading} onClick={handleAddHubSpot} />
        </CRMPresence.WhenNotPresent>
        <CRMPresence.WhenLoading>
          <CKSkeleton className="w-full h-[1.375rem]" isLoading />
        </CRMPresence.WhenLoading>
        <CRMPresence.WhenNotConnected>
          <CKTooltip tooltip="Connect HubSpot to add company">
            <AddButton isDisabled />
          </CKTooltip>
        </CRMPresence.WhenNotConnected>
        <CRMPresence.WhenNoAccess>
          <div />
        </CRMPresence.WhenNoAccess>
      </CKLayoutHorizontal>
    </CRMPresence>
  );
};
