import { dollars } from '~/lib/formatters';

import TwoLineMenuItem from './TwoLineMenuItem';

const HubspotDealMenuItem = ({ deal, owner }) => (
  <TwoLineMenuItem
    externalLinkClass="text-hubspot"
    href={`https://app.hubspot.com/contacts/${deal.hubspotCompany?.externalAccountId}/deal/${deal['id']}`}
    subtitle={
      <>
        {owner && <>Owned by {owner?.ownerName || 'Unknown Owner'}</>}
        <>{deal.amount && <> &mdash; {dollars(deal.amount)}</>}</>
      </>
    }
    title={deal.name}
  />
);

export default HubspotDealMenuItem;
