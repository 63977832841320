import { Lock } from '@clearkit/icons';
import { CKButton, CKTooltip } from 'clearkit';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { Subscribe } from 'unstated';

import UserContainer from '~/containers/User';
import { destinationNameForType } from '~/lib/destinations';
import paths from '~/lib/paths';
import EnabledSwitch from '~/pages/destinations/components/EnabledSwitch';
import Logo from '~/pages/destinations/components/Logo';

import { description, timePeriod } from '../utils';
import styles from './styles';

const Destination = ({
  destination,
  classes,
  slackAccountConnector,
  loading,
  className,
  segment,
}) => {
  return (
    <Subscribe to={[UserContainer]}>
      {(userContainer) => {
        const objectType = 'destination';
        const objectSubtype = destination.type;
        const objectAction = 'update';

        let hasPermission = userContainer.hasPermissionTo({
          objectAction,
          objectType,
          objectSubtype,
        });

        return (
          <li
            className={classnames(className, classes.destination, {
              [classes.destinationLoading]: loading,
            })}
            key={destination.id}
          >
            <div className={classes.destinationSwitchWrapper}>
              {hasPermission ? (
                <EnabledSwitch
                  className={classes.destinationSwitch}
                  destination={destination}
                  disabled={!!loading}
                />
              ) : (
                <CKTooltip
                  placement="bottom"
                  tooltip="
                      You don't have permission to access this feature. Contact
                      your team Admin for access."
                >
                  <div>
                    <EnabledSwitch
                      className={classes.destinationSwitch}
                      destination={destination}
                      disabled={!hasPermission}
                    />
                  </div>
                </CKTooltip>
              )}
            </div>
            <div className={classes.destinationDetailsWrapper}>
              <Logo
                className={classes.destinationLogo}
                destinationType={destination.type}
              />
              <div className={classes.destinationNameWrapper}>
                <span className={classes.destinationName}>
                  {destinationNameForType(destination.type)}{' '}
                </span>

                <span className={classes.destinationDescription}>
                  {!loading && (
                    <>
                      {description(destination, slackAccountConnector, classes)}{' '}
                      {timePeriod(
                        get(
                          destination,
                          'config.duplicateSuppressionTimeframe',
                        ),
                        segment?.type,
                      )}
                    </>
                  )}
                </span>
              </div>
              {hasPermission ? (
                <CKButton
                  as={Link}
                  className={classes.destinationButton}
                  data-testid="SegmentDestination__configure"
                  isDisabled={!!loading}
                  size="small"
                  to={paths.segmentDestinationEdit(
                    segment?.type,
                    segment?.id,
                    destination.id,
                  )}
                >
                  Configure
                </CKButton>
              ) : (
                <CKTooltip
                  placement="bottom"
                  tooltip="You don't have permission to access this feature. Contact
                      your team Admin for access."
                >
                  <div>
                    <CKButton
                      className={classes.destinationButton}
                      data-testid="SegmentDestination__unlock"
                      isDisabled
                      leftIcon={<Lock />}
                      size="small"
                    >
                      Unlock {destinationNameForType(destination.type)}
                    </CKButton>
                  </div>
                </CKTooltip>
              )}
            </div>
          </li>
        );
      }}
    </Subscribe>
  );
};

Destination.propTypes = {
  loading: PropTypes.bool,
  classes: PropTypes.object,
  className: PropTypes.string,
  destination: PropTypes.object,
  slackAccountConnector: PropTypes.object,
  segment: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
  }),
};

export default withStyles(styles)(Destination);
