import { CKLayoutHorizontal } from 'clearkit';

import {
  FormConfigurationProps,
  FormsPage,
} from '~/components/forms/FormsPage';
import { NoAccessContactCTA } from '~/components/forms/NoAccessContactCTA';
import { OnboardingFormsCard } from '~/components/forms/OnboardingFormsCard';
import { useEnrichmentOnboarding } from '~/components/HubspotEnrichment/useEnrichment/useEnrichmentOnboarding';
import { PageLayout } from '~/components/PageLayout/PageLayout';
import { useEntitlements } from '~/hooks/account';

const Forms = ({
  integration,
  selectedTab = 'configuration',
}: FormConfigurationProps) => {
  const {
    hasAccessFormsHubspotEditing,
    loading: loadingEntitlements,
  } = useEntitlements();
  const {
    hasAccessEnrichmentHubspotEditing,
    isOnboarding,
    refetch,
  } = useEnrichmentOnboarding();

  if (loadingEntitlements) return null;

  if (hasAccessEnrichmentHubspotEditing && isOnboarding) {
    return (
      <PageLayout.Body className="w-full h-full">
        <CKLayoutHorizontal className="h-full" justifyContent="center">
          <OnboardingFormsCard
            onSuccess={() => {
              refetch();
            }}
          />
        </CKLayoutHorizontal>
      </PageLayout.Body>
    );
  }

  return hasAccessFormsHubspotEditing ? (
    <FormsPage integration={integration} selectedTab={selectedTab} />
  ) : (
    <NoAccessContactCTA />
  );
};

export default Forms;
