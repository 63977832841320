import {
  DEFAULT_GROUP,
  NESTABLE_NODE_KEYS,
  PRIMARY_HUBSPOT_KEYS,
  PRIMARY_MARKETO_KEYS,
  PRIMARY_SALESFORCE_KEYS,
  THIRD_PARTY_KEY_PREFIXES,
} from './constants';
import { replaceNode } from './searchUtils';

export function nestNode(search, node, options = {}) {
  const { parent, child } = options;

  node[parent] = {
    [child]: node[child],
    ...DEFAULT_GROUP,
    __index: node.__index,
    __nest: node.__nest,
  };
  delete node[child];

  return replaceNode(search, node);
}

export function getThirdPartyKey(node) {
  if (!node) return false;
  let keys = Object.keys(node);

  return keys.find((key) => {
    return THIRD_PARTY_KEY_PREFIXES.find((prefix) => {
      return key.indexOf(prefix) === 0;
    });
  });
}

export function getRootCategory(node) {
  const key = getThirdPartyKey(node);

  return key.split('_')[1];
}

export function getService(node) {
  const key = getThirdPartyKey(node);

  return key.split('_')[0];
}

export function isThirdPartyNode(node) {
  return !!getThirdPartyKey(node);
}

export function isSalesforceNode(node) {
  const salesforceKey = getThirdPartyKey(node);
  return PRIMARY_SALESFORCE_KEYS.indexOf(salesforceKey) > -1;
}

export function isHubspotNode(node) {
  const hubspotKey = getThirdPartyKey(node);
  return PRIMARY_HUBSPOT_KEYS.indexOf(hubspotKey) > -1;
}

export function isMarketoNode(node) {
  const marketoKey = getThirdPartyKey(node);
  return PRIMARY_MARKETO_KEYS.indexOf(marketoKey) > -1;
}

export function getDependentCategory(node, type) {
  const service = getService(node);

  switch (service) {
    case 'hubspot':
      return type === 'Person' ? 'contact' : 'deal';
    case 'salesforce':
      return type === 'Person' ? 'contact' : 'opportunity';
    case 'marketo':
      return 'lead';
    default:
      return null;
  }
}

export function isNodeExpanded(node) {
  const rootCategory = getRootCategory(node);
  const service = getService(node);

  switch (service) {
    case 'hubspot':
      return rootCategory === 'company';
    case 'salesforce':
      return rootCategory === 'account';
    case 'marketo':
      return rootCategory === 'lead';
    default:
      return null;
  }
}

export function isNodeNestable(node) {
  const key = getThirdPartyKey(node);

  return NESTABLE_NODE_KEYS.includes(key);
}
