import classnames from 'classnames';
import { CKContainerProps, cloneChildren } from 'clearkit';

export type MeterIconProps = CKContainerProps & {
  size?: number;
  background?: string;
};

export const MeterIcon = ({
  background,
  size = 30,
  children,
  ...rest
}: MeterIconProps) => (
  <span
    {...rest}
    className="relative flex items-center justify-center"
    style={{ width: size, height: size }}
  >
    <svg
      className={classnames('absolute w-full h-full', background)}
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15 0c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15C0 6.716 6.716 0 15 0Zm0 4C8.925 4 4 8.925 4 15s4.925 11 11 11 11-4.925 11-11S21.075 4 15 4Z" />
    </svg>
    {cloneChildren({
      children,
      newProps: {
        className: classnames('w-4 h-4', background),
      },
    })}
  </span>
);
