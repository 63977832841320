import moment from 'moment';

export const audienceServices = {
  facebook: 'facebook',
  google: 'google',
  managedGoogle: 'managed_google',
};

export const audienceStatuses = {
  active: 'active',
  error: 'error',
  inProgress: 'in_progress',
  inactive: 'inactive',
  initializing: 'initializing',
};

export const audienceSubtypes = {
  companySegmentProspects: 'company_segment_prospects',
  contacts: 'contacts',
  managedVisitors: 'managed_visitors',
  prospects: 'prospects',
  visitors: 'visitors',
};

export const previewableAudienceSubtypes = [
  audienceSubtypes.companySegmentProspects,
  audienceSubtypes.contacts,
  audienceSubtypes.prospects,
];

export function audienceIsPreviewable(audience) {
  return previewableAudienceSubtypes.includes(audience.subtype);
}

export const clonableAudienceSubtypes = [
  audienceSubtypes.companySegmentProspects,
  audienceSubtypes.prospects,
];

export function audienceIsClonable(audience) {
  return clonableAudienceSubtypes.includes(audience.subtype);
}

export function audienceHealth(size) {
  if (size === null) return null;
  if (size === 0) return 0;
  if (size <= 2_000) return 1;
  if (size <= 10_000) return 2;
  if (size <= 20_000) return 3;
  if (size < 200_000) return 4;

  return 5;
}

export function audienceHealthLabel(size) {
  const health = audienceHealth(size);
  const labels = ['Empty', 'Tiny', 'Small', 'Medium', 'Normal', 'Extra-large'];

  return `${labels[health] || 'Unknown'} audience size`;
}

export function audienceSubtypeServices(subtype) {
  const subtypeServices = {
    [audienceSubtypes.companySegmentProspects]: [audienceServices.facebook],
    [audienceSubtypes.contacts]: [
      audienceServices.facebook,
      audienceServices.managedGoogle,
    ],
    [audienceSubtypes.managedVisitors]: [audienceServices.managedGoogle],
    [audienceSubtypes.prospects]: [audienceServices.facebook],
    [audienceSubtypes.visitors]: [
      audienceServices.facebook,
      audienceServices.google,
    ],
  };

  return subtypeServices[subtype] || [];
}

export function audienceStatusMessage(audience) {
  switch (audience.status) {
    case audienceStatuses.active:
      return audienceStatusMessageActive(audience);
    case audienceStatuses.initializing:
    case audienceStatuses.inProgress:
      return `Syncing in progress (${audience.pushProgress}%)`;
    case audienceStatuses.error:
      return 'There is an issue with this audience';
    case audienceStatuses.inactive:
      return 'Audience is inactive';
  }
}

function audienceStatusMessageActive(audience) {
  if (audience.pushedAt) {
    return `Last sync ${moment(audience.pushedAt).fromNow()}`;
  }

  return 'Audience is active';
}

export function isPolling(status) {
  return [audienceStatuses.inProgress, audienceStatuses.initializing].includes(
    status,
  );
}

export function readableAudienceEstimatedSize(size, zeroSize) {
  if (size === null) {
    return zeroSize || 'N/A';
  }

  if (size === 0) {
    return zeroSize || `${size}`;
  }

  if (size < 1_000) {
    return `${size}`;
  }

  if (size < 1_000_000) {
    return `${+(size / 1_000).toFixed(1)}k`;
  }

  return `${+(size / 1_000_000).toFixed(1)}m`;
}

export function readableAudienceService(service) {
  const readableAudienceServices = {
    [audienceServices.facebook]: 'Facebook',
    [audienceServices.google]: 'Google',
  };

  return readableAudienceServices[service];
}

export function readableAudiencePushRate(pushRate) {
  switch (pushRate) {
    case 86400:
      return 'daily';
    case 604800:
      return 'weekly';
    default:
      return null;
  }
}

export function readableAudienceSubtype(subtype) {
  const readableAudienceSubtypes = {
    [audienceSubtypes.companySegmentProspects]: 'Prospect',
    [audienceSubtypes.contacts]: 'Contact',
    [audienceSubtypes.managedVisitors]: 'Managed Site Visitor',
    [audienceSubtypes.prospects]: 'Prospect',
    [audienceSubtypes.visitors]: 'Site Visitor',
  };

  return readableAudienceSubtypes[subtype];
}

const ADS_STANDARD_PLAN = 'x_ads_standard';
const ADS_PROSPECTING_PLAN = 'x_ads_prospecting';
const PLATFORM_ADDON_ACQUISITIONS_PLAN = 'platform_addon_acquisitions';

export function audienceSubtypeEnabled(plans = [], option) {
  if (plans.includes(PLATFORM_ADDON_ACQUISITIONS_PLAN)) {
    return true;
  }

  switch (option) {
    case audienceSubtypes.companySegmentProspects:
      return plans.includes(ADS_PROSPECTING_PLAN);
    case audienceSubtypes.contacts:
      return plans.includes(ADS_STANDARD_PLAN);
    case audienceSubtypes.managedVisitors:
      return plans.includes(ADS_STANDARD_PLAN);
    case audienceSubtypes.prospects:
      return plans.includes(ADS_PROSPECTING_PLAN);
    case audienceSubtypes.visitors:
      return plans.includes(ADS_STANDARD_PLAN);
    default:
      return false;
  }
}
