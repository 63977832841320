import { CKBox, CKSelectableItem } from 'clearkit';

import { SalesforceTrait } from '~/generated/graphql';

import { ExportGroup } from './ExportGroup';
import { ExportHeader } from './ExportHeader';
import { SalesforceDefaultFields } from './SalesforceDefaultFields';
import { ExportFieldMapping } from './SalesforceExportProvider/types';
import { SalesforceField } from './SalesforceField';

type SalesforceContactFormProps = {
  isLoading?: boolean;
  optionalFields: SalesforceTrait[];
  requiredFields: SalesforceTrait[];
  value: ExportFieldMapping;
  onChange: (value: any) => void;
};

export const SalesforceForm = ({
  value: formValue,
  onChange,
  optionalFields,
  requiredFields,
  isLoading,
}: SalesforceContactFormProps) => {
  const updateFields = (key: keyof ExportFieldMapping) => (value: string) => {
    const newFields = { ...formValue, [key]: value };
    onChange(newFields);
  };

  const ItemGroup = ({ fields }: { fields: SalesforceTrait[] }) => {
    return (
      <>
        {fields.map((field: SalesforceTrait) => {
          const { options, label, name } = field;
          const fieldKey = name as keyof ExportFieldMapping;

          return (
            <SalesforceField
              isLoading={isLoading}
              items={options as CKSelectableItem[]}
              key={name}
              onChange={updateFields(fieldKey)}
              placeholder={`Select a ${label}`}
              value={formValue[fieldKey]}
            >
              <SalesforceField.Label>{label}</SalesforceField.Label>
            </SalesforceField>
          );
        })}
      </>
    );
  };

  return (
    <CKBox className="p-4 space-y-6 divide-y divide-gray-200">
      {requiredFields.length > 0 ? (
        <ExportGroup className="pt-0">
          <ExportHeader>Required Fields</ExportHeader>
          <ItemGroup fields={requiredFields} />
        </ExportGroup>
      ) : null}
      {optionalFields.length > 0 ? (
        <ExportGroup>
          <ExportHeader>Optional Fields</ExportHeader>
          <ItemGroup fields={optionalFields} />
        </ExportGroup>
      ) : null}
      <SalesforceDefaultFields />
    </CKBox>
  );
};
