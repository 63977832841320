import { SpinnerMonoIndicators as SpinnerMono } from '@clearkit/icons';
import { CKSwitch, CKSwitchProps } from 'clearkit';
import { ReactNode } from 'react';

export type ConfigurationBooleanToggleSectionProps = CKSwitchProps & {
  title: string;
  description: ReactNode;
  checked: boolean;
  isLoading?: boolean;
  onChange: (checked: boolean) => void;
};

export default function ConfigurationBooleanToggleSection({
  title,
  description,
  checked,
  isLoading,
  onChange = () => {},
  ...props
}: ConfigurationBooleanToggleSectionProps) {
  const isChecked: boolean = isLoading ? !checked : checked;
  const disabled = isLoading || props.disabled;

  if (!props.id) {
    // eslint-disable-next-line no-console
    console.error(
      `You did not include an 'id' property for the Boolean Toggle Section called ${title}. This will probably not work`,
    );
  }
  return (
    <div className="flex flex-row py-6 pl-6 pr-8 gap-6">
      <div className="flex-grow">
        <h3 className="mb-2 text-base font-medium text-gray-800">{title}</h3>

        <div className="text-base text-gray-600">{description}</div>
      </div>

      <div className="flex items-center flex-shrink-0 gap-2">
        {isLoading && <SpinnerMono className="w-5 h-5" />}
        <div className="w-24">
          <CKSwitch
            checked={isChecked}
            disabled={disabled}
            onChange={(event) => {
              const ischecked = (event.target as HTMLInputElement).checked;
              onChange(ischecked);
            }}
            srOnly={false}
            {...props}
          >
            {isChecked ? 'Enabled' : 'Disabled'}
          </CKSwitch>
        </div>
      </div>
    </div>
  );
}
