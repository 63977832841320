import { Prospector } from '@clearkit/icons';
import { CKBox, CKIconBox, CKLayoutHorizontal } from 'clearkit';

import { useHomeIntegrations } from '~/components/home/hooks/useHomeIntegrations';
import { useEntitlements } from '~/hooks/account';

import { GetStarted } from '../CallsToAction';
import { ConnectHubSpotCTA } from '../CallsToAction/ConnectHubSpotCTA';

export const SidebarCTAs = () => {
  const { hasAccessEnrichment } = useEntitlements();
  const { hubspotConnector } = useHomeIntegrations();
  const isConnected = hubspotConnector;

  const CTAGroup = () => {
    if (isConnected || hasAccessEnrichment) {
      return (
        <CKLayoutHorizontal
          className="text-center"
          justifyContent="center"
          wrap="wrap"
        >
          <CKLayoutHorizontal
            className="basis-full shrink-0"
            justifyContent="center"
          >
            <CKIconBox>
              <Prospector />
            </CKIconBox>
          </CKLayoutHorizontal>
          <div className="divide-y divide-gray-200 space-y-8">
            <GetStarted />
          </div>
        </CKLayoutHorizontal>
      );
    } else {
      return <ConnectHubSpotCTA />;
    }
  };

  return (
    <div className="px-6 py-0.5">
      <CKBox className="p-6">
        <CTAGroup />
      </CKBox>
    </div>
  );
};
