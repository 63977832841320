import { integrationTypes } from '~/lib/dataEnrichment';

export const UUIDRegex =
  '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}';

export const AnyStringRegex = '.+';
export const concatEnrichmentTypes = integrationTypes.join('|');

export const routes = {
  advertising: '/audiences',
  batch: '/batches',
  companies: '/companies',
  formsHubspot: '/forms/hubspot',
  enrichment: '/enrichment',
  getStarted: '/get-started',
  home: '/',
  integrations: '/integrations',
  lookup: '/lookup',
  people: '/people',
  plans: '/plans',
  plansUpgrade: '/plans/upgrade',
  prospector: '/prospector',
  settings: '/settings',
  support: 'https://help.clearbit.com/hc/en-us/',
  tagsJs: '/settings/configuration/tagsjs',
  usage: '/usage',
  targetMarkets: '/target-markets',
  targetMarketsDetails: '/target-markets/details',
  visitors: '/visitors',
  visitorsDetails: '/visitors/details',
} as const;
