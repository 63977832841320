import { AttributeColumn } from './types';

// The icons span the different icon categories (glyphs, services, and identity)
// in clearkit, each of which has their own default size. So, this explicitly sets
// the width to the current default glyph icon size.
export const ATTRIBUTE_CATEGORY_ICON_WIDTH = 16;

type SearchColumn = {
  Person: AttributeColumn[];
  Company: AttributeColumn[];
};

export const DEFAULT_COLUMNS: SearchColumn = {
  Person: [
    { value: 'email', label: 'Email', category: 'clearbit' },
    {
      value: 'name',
      label: 'Name',
      category: 'clearbit',
    },
    {
      value: 'person->employment->title',
      label: 'Person Employment Title',
      category: 'clearbit',
    },
    { value: 'company->name', label: 'Company Name', category: 'clearbit' },
  ],

  Company: [
    { value: 'company->name', label: 'Company Name', category: 'clearbit' },
    { value: 'company->domain', label: 'Company Domain', category: 'clearbit' },
    {
      value: 'company->metrics->employees_range',
      label: 'Company Employees Range',
      category: 'clearbit',
    },
    { value: 'last_visit_at', label: 'Last Visit At', category: 'page view' },
    {
      value: 'last_visit_path',
      label: 'Last Visit Path',
      category: 'page view',
    },
  ],
};
