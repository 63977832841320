import { Caret } from '@clearkit/icons';
import moment from 'moment';

import { flattenObjectForDisplay, formatColumnLabel } from '~/lib/helpers';

import styles from './EventsListItem.style';

const formatTimestamp = (time) => moment(time).format('MMM Do YYYY, h:mm:ss a');

const formatLabel = (key) =>
  key
    .split(':')
    .map((keyPart) => <span key={keyPart}>{formatColumnLabel(keyPart)} </span>);

const ListEventsItem = ({ event, classes, expanded, expand }) => {
  const renderDetailTable = () => {
    const flatEvent = flattenObjectForDisplay(event);
    return Object.keys(flatEvent).map((key) => (
      <div className="flex px-4 m-1" key={key}>
        <div className={classnames('w-6/12', classes.label)}>
          {formatLabel(key)}
        </div>
        <div className="w-6/12">{flatEvent[key]}</div>
      </div>
    ));
  };

  return (
    <div className="h-full pt-4 mx-4">
      <div
        className={classnames(
          'flex flex-col rounded h-full',
          classes.eventItem,
          {
            'ck-box': expanded,
            'bg-gray-0 hover:bg-blue-0 hover:bg-blue-0 transition-colors duration-200 ease': !expanded,
          },
        )}
      >
        <div
          className="flex items-center justify-between p-3"
          onClick={expand}
          role="button"
          tabIndex="0"
        >
          <span className="flex items-center">
            <Caret
              className={classnames('text-gray mr-2 transform', {
                '-rotate-90': !expanded,
              })}
            />
            <span className={classes.name}>{event.type}</span>
          </span>
          <span className={classes.date}>
            {formatTimestamp(event.timestamp)}
          </span>
        </div>
        {expanded && (
          <div
            className={classnames(
              classes.detailContainerExpanded,
              'flex flex-col h-full',
            )}
          >
            {renderDetailTable()}
          </div>
        )}
      </div>
    </div>
  );
};

ListEventsItem.propTypes = {
  classes: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  expand: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
};

export default withStyles(styles)(ListEventsItem);
