import { useMutation } from 'react-query';

export default function useVerifyTOTPCode() {
  return useMutation(
    async ({
      code,
      recoveryCode,
    }: {
      code?: string;
      recoveryCode?: string;
    }) => {
      const response = await fetch(`/auth/mfa/verify`, {
        method: 'POST',
        body: JSON.stringify({ code, recovery_code: recoveryCode }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 401) {
        throw new Error(await response.json());
      }

      if (response.ok) {
        return { isSuccess: true };
      } else {
        const data = await response.json();
        return { isSuccess: false, error: data.error };
      }
    },
  );
}
