import classnames from 'classnames';
import { CKButton, CKButtonVariantColorType, CKModal } from 'clearkit';
import { Subscribe } from 'unstated';

import ConfirmDialogContainer from '~/containers/ConfirmDialog';

type ConfirmDialogBaseProps = {
  buttonDisabled?: boolean;
  cancelText?: string;
  confirmText?: React.ReactNode;
  contentClasses?: any;
  confirmButtonVariant?: CKButtonVariantColorType;
  message: React.ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
  open: boolean;
  title: React.ReactNode;
};

export const ConfirmDialogBase: React.FC<ConfirmDialogBaseProps> = ({
  buttonDisabled = false,
  cancelText = 'Cancel',
  confirmText = 'Continue',
  confirmButtonVariant = 'red',
  contentClasses,
  message,
  onConfirm,
  onCancel,
  open,
  title,
}) => (
  <CKModal isVisible={open} onToggle={onCancel}>
    <CKModal.Header>
      <CKModal.Heading>{title}</CKModal.Heading>
    </CKModal.Header>
    {message ? (
      <CKModal.Body className="px-8 py-6">{message}</CKModal.Body>
    ) : null}

    <CKModal.Footer
      className={classnames('flex gap-4', {
        ...contentClasses,
      })}
    >
      {onCancel === null ? null : (
        <CKButton onClick={onCancel} variant="simple">
          {cancelText}
        </CKButton>
      )}
      <CKButton
        isDisabled={!!buttonDisabled}
        onClick={onConfirm}
        variant="bold"
        variantColor={confirmButtonVariant}
      >
        {confirmText}
      </CKButton>
    </CKModal.Footer>
  </CKModal>
);

const ConfirmDialog = () => (
  <Subscribe to={[ConfirmDialogContainer]}>
    {(container) => {
      const { show, title, confirm, cancel, text } = container.state;

      return (
        <ConfirmDialogBase
          message={text}
          onCancel={cancel}
          onConfirm={confirm}
          open={show}
          title={title}
        />
      );
    }}
  </Subscribe>
);

export default ConfirmDialog;
