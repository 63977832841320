
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SiteConfigurationFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FormConfiguration"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"autofill"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"formShortening"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"identifyOnSubmit"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"allowedSites"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"siteUrl"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"lastEventAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"eventsReceived"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":185}};
    doc.loc.source = {"body":"fragment SiteConfigurationFields on FormConfiguration {\n  id\n  autofill\n  formShortening\n  identifyOnSubmit\n  allowedSites {\n    id\n    siteUrl\n    lastEventAt\n    eventsReceived\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
