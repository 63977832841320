import styles from '../../styles';
import attributeTypes from '../../utils/attributeTypes';
import ValueBoolean from './ValueBoolean';
import ValueDate from './ValueDate';
import ValueMultiSelect from './ValueMultiSelect';
import NestedMultiSelect from './ValueNestedMultiSelect';
import ValueNumber from './ValueNumber';
import ValueStringSelect from './ValueStringSelect';
import ValueText from './ValueText';

export default withStyles(styles, {
  name: 'ValueField',
})(({ type, ...props }) => {
  switch (type) {
    case attributeTypes.BOOLEAN:
      return <ValueBoolean {...props} />;
    case attributeTypes.NUMBER:
      return <ValueNumber {...props} />;
    case attributeTypes.SELECT:
    case attributeTypes.MULTI_SELECT:
      return <ValueMultiSelect {...props} />;
    case attributeTypes.NESTED_SELECT:
      return <NestedMultiSelect {...props} />;
    case attributeTypes.DATETIME:
      return <ValueDate {...props} />;
    case attributeTypes.STRING:
    case attributeTypes.MULTI_COLUMN_STRING:
    case attributeTypes.KEYWORD_STRING:
      return <ValueStringSelect {...props} />;
    default:
      return <ValueText {...props} />;
  }
});
