import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const ToggleRadioButtonGroup = (props) => {
  const { onChange: onChangeProp, children, ...rest } = props;

  const onChange = (event, value) => {
    if (value === null || value === undefined) return;
    onChangeProp(event, value);
  };

  return (
    <ToggleButtonGroup onChange={onChange} {...rest}>
      {children}
    </ToggleButtonGroup>
  );
};

ToggleRadioButtonGroup.propTypes = {
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default ToggleRadioButtonGroup;
