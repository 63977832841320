import { InfoHollow as InfoIcon } from '@clearkit/icons';
import { CKTooltip } from 'clearkit';

export default function InfoTooltip({ title }) {
  return (
    <CKTooltip placement="top" tooltip={title}>
      <div className="ml-2 text-gray-600 outline-none">
        <InfoIcon height="16" width="16" />
      </div>
    </CKTooltip>
  );
}

InfoTooltip.propTypes = {
  title: PropTypes.string.isRequired,
};
