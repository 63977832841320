import {
  AddHollow,
  Hubspot,
  PeopleNav,
  RemoveHollow,
  Salesforce,
  SpinnerMonoIndicators,
} from '@clearkit/icons';
import classnames from 'classnames';
import {
  CKActionMenu,
  CKButton,
  CKLayoutHorizontal,
  CKPopover,
} from 'clearkit';

import { FauxCheckbox } from '~/components/ListItem/FauxCheckbox';
import { ItemCount } from '~/components/ListItem/ItemCount';
import { PageLayout } from '~/components/PageLayout';
import {
  useProspectorCRMExports,
  useProspectorExportLists,
  useProspectorExports,
  useProspectorHubspotExports,
  useProspectorSalesforceExports,
} from '~/components/policies/WhenProspector';
import { PROSPECTS_LIMIT } from '~/components/prospector/useProspector/constants';
import { ButtonContainer } from '~/components/unified/ButtonContainer';
import { UpgradeAccountFormButton } from '~/components/UpgradeAccountFormButton';

type ListFooterProps = {
  hasInitializingExports?: boolean;
  isCreatingExport?: boolean;
  onDeselectAll: () => void;
  onExport: () => void;
  onExportSalesforce: () => void;
  onExportHubspot: () => void;
  onExportDrawerToggle: () => void;
  onSelectAll: () => void;
  onSelectAllPage: () => void;
  selectAllChecked: boolean;
  selectedProspectsCount: number;
  totalProspects: number;
};

export const ListFooter = ({
  hasInitializingExports,
  isCreatingExport,
  onDeselectAll,
  onExport,
  onExportSalesforce,
  onExportHubspot,
  onExportDrawerToggle,
  onSelectAll,
  selectAllChecked,
  onSelectAllPage,
  selectedProspectsCount,
  totalProspects,
}: ListFooterProps) => {
  const hasExportAccess = useProspectorExports();
  const {
    hasExportListAccess,
    loading: loadingExportList,
  } = useProspectorExportLists();
  const hasCRMExportAccess = useProspectorCRMExports();
  /**
   * @todo Reenable this when we have a way to select all prospects
   * for export
   * https://app.asana.com/0/1205525521365723/1205892043881075/f
   */
  // const showSelectAll = totalProspects <= PROSPECTS_LIMIT;
  const showSelectAll = false;

  const hasSalesforce = useProspectorSalesforceExports();
  const hasHubspot = useProspectorHubspotExports();

  const SelectAllMenu = () => (
    <CKActionMenu>
      <CKActionMenu.Item leftIcon={<AddHollow />} onClick={onSelectAllPage}>
        Select all prospects on this page
      </CKActionMenu.Item>
      {showSelectAll ? (
        <CKActionMenu.Item
          isDisabled={!showSelectAll}
          leftIcon={showSelectAll ? <AddHollow /> : null}
          onClick={onSelectAll}
        >
          {showSelectAll ? (
            <span>
              Select all{' '}
              <strong className="font-medium">
                {totalProspects.toLocaleString('en-US')}{' '}
              </strong>
            </span>
          ) : (
            `Too many prospects to select all (max ${PROSPECTS_LIMIT.toLocaleString(
              'en-US',
            )})`
          )}
        </CKActionMenu.Item>
      ) : null}
      <CKActionMenu.Divider />
      <CKActionMenu.Item
        isDisabled={!selectAllChecked && !selectedProspectsCount}
        leftIcon={<RemoveHollow />}
        onClick={onDeselectAll}
      >
        Deselect all
      </CKActionMenu.Item>
    </CKActionMenu>
  );

  const SelectAll = () => {
    const checked = selectAllChecked || !!selectedProspectsCount;
    const isIndeterminate = checked && selectedProspectsCount < totalProspects;

    return (
      <CKPopover popover={<SelectAllMenu />} popoverWidth="22rem">
        <CKPopover.Trigger className="!px-3">
          <FauxCheckbox checked={checked} isIndeterminate={isIndeterminate} />
        </CKPopover.Trigger>
      </CKPopover>
    );
  };

  const iconProps = {
    height: 18,
    width: 18,
  };

  const AddToMenu = () => {
    return (
      <CKActionMenu>
        {hasSalesforce ? (
          <CKActionMenu.Item
            className="group"
            leftIcon={
              <Salesforce
                className="group-hover:text-white text-salesforce"
                {...iconProps}
              />
            }
            onClick={onExportSalesforce}
          >
            Salesforce
          </CKActionMenu.Item>
        ) : null}
        <CKActionMenu.Item
          className="group"
          isDisabled={!hasHubspot}
          leftIcon={
            <Hubspot
              className={classnames(' group-hover:text-white', {
                'text-hubspot': hasHubspot,
              })}
              {...iconProps}
            />
          }
          onClick={onExportHubspot}
        >
          Hubspot
        </CKActionMenu.Item>
      </CKActionMenu>
    );
  };

  const CRMExportButtons = () => {
    if (hasSalesforce && hasHubspot) {
      return (
        <CKPopover
          isDisabled={isCRMExportDisabled}
          popover={<AddToMenu />}
          popoverPlacement="top-end"
        >
          <CKPopover.Trigger variantColor="blue">
            Add prospects to...
          </CKPopover.Trigger>
        </CKPopover>
      );
    } else if (hasSalesforce) {
      return (
        <CKButton
          isDisabled={isCRMExportDisabled}
          leftIcon={<Salesforce className="text-salesforce" {...iconProps} />}
          onClick={onExportSalesforce}
        >
          Add to Salesforce
        </CKButton>
      );
    } else if (hasHubspot) {
      return (
        <CKButton
          isDisabled={isCRMExportDisabled}
          leftIcon={<Hubspot className="text-hubspot" {...iconProps} />}
          onClick={onExportHubspot}
        >
          Add to Hubspot
        </CKButton>
      );
    } else {
      return null;
    }
  };

  const isExportDisabled =
    !selectedProspectsCount ||
    isCreatingExport ||
    hasInitializingExports ||
    !hasExportListAccess;
  const isCSVExportDisabled = !hasExportAccess || isExportDisabled;
  const isCRMExportDisabled = !hasCRMExportAccess || isExportDisabled;

  return (
    <PageLayout.Footer className="py-3">
      <CKLayoutHorizontal className="text-sm" gap=".5rem">
        <CKLayoutHorizontal>
          <SelectAll />
          <ItemCount
            end={selectedProspectsCount}
            label={`${
              selectedProspectsCount === 1 ? 'prospect' : 'prospects'
            } selected`}
          >
            <PeopleNav height={18} width={18} />
          </ItemCount>
        </CKLayoutHorizontal>
        <ButtonContainer>
          <CKButton
            isDisabled={isCSVExportDisabled}
            isLoading={isCreatingExport}
            onClick={onExport}
            variantColor="blue"
          >
            Download to CSV
          </CKButton>
          <CRMExportButtons />
          {hasExportListAccess || loadingExportList ? (
            <CKButton
              isDisabled={!hasExportAccess || loadingExportList}
              leftIcon={
                hasInitializingExports ? (
                  <SpinnerMonoIndicators className="w-4 h-4" />
                ) : null
              }
              onClick={onExportDrawerToggle}
              variant="tertiary"
              variantColor="blue"
            >
              Exports
            </CKButton>
          ) : (
            <UpgradeAccountFormButton variant="bold" variantColor="green">
              Contact us
            </UpgradeAccountFormButton>
          )}
        </ButtonContainer>
      </CKLayoutHorizontal>
    </PageLayout.Footer>
  );
};
