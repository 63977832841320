import { routes } from '~/components/app/appMainRoutes';
import { enrichmentRoutes } from '~/components/HubspotEnrichment/constants';

import { useHomeIntegrations } from '../hooks/useHomeIntegrations';
import { ItemConnectCRM } from './ItemConnectCRM';
import { OnboardingChecklist } from './OnboardingChecklist';
import { OnboardingItem, OnboardingItemDivider } from './OnboardingItem';

export const ListEnrichment = () => {
  const {
    hasCompanyEnrichment,
    hasContactEnrichment,
    hasEnrichmentMappings,
    isHubSpotConnected,
    loadingAccount,
    loadingHubspotEnrichment,
  } = useHomeIntegrations();

  const isLoading = loadingAccount || loadingHubspotEnrichment;

  return (
    <OnboardingChecklist title="Get started with Enrichment">
      <ItemConnectCRM number={1} />
      <OnboardingItemDivider />
      <OnboardingItem
        isComplete={hasEnrichmentMappings}
        isDisabled={!isHubSpotConnected}
        isLoading={isLoading}
        number={2}
        title="Set up enrichment mappings"
        to={enrichmentRoutes.company}
      />
      <OnboardingItemDivider />
      <OnboardingItem
        isComplete={hasCompanyEnrichment}
        isDisabled={!isHubSpotConnected}
        isLoading={isLoading}
        number={3}
        title="Enable company enrichment"
        to={routes.enrichment}
      />
      <OnboardingItemDivider />
      <OnboardingItem
        isComplete={hasContactEnrichment}
        isDisabled={!isHubSpotConnected}
        isLoading={isLoading}
        number={4}
        title="Enable contact enrichment"
        to={routes.enrichment}
      />
    </OnboardingChecklist>
  );
};
