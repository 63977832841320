import {
  destinationTypeEmail,
  destinationTypeFacebookConversion,
  destinationTypeGoogleConversion,
  destinationTypeSalesforce,
  destinationTypeSalesforceCreateAccount,
  destinationTypeSalesforceCreateContact,
  destinationTypeSalesforceCreateLead,
  destinationTypeSlack,
  destinationTypeWebhook,
  personalizationDestinationTypes,
} from '~/lib/destinations';
import { segmentTypes } from '~/lib/segments';

import { audienceSubTypesToShowAsDestinations } from './utils';

const destinationGroups = [
  {
    key: 'capture',
    title: 'Clearbit Capture',
    description:
      'Automatically create accounts, contacts, leads, or tasks in Salesforce.',
    link:
      'https://help.clearbit.com/hc/en-us/articles/360022031134-Set-Up-the-Salesforce-Destination',

    items: {
      company: [
        destinationTypeSalesforceCreateAccount,
        destinationTypeSalesforceCreateContact,
        destinationTypeSalesforceCreateLead,
      ],
    },
  },
  {
    key: 'alerting',
    title: 'Alerting and notifications',
    description: 'Send alerts and notifications to your teams.',
    items: {
      all: [
        destinationTypeEmail,
        destinationTypeSlack,
        destinationTypeWebhook,
        destinationTypeSalesforce,
      ],
    },
  },
  {
    key: 'conversions',
    title: 'Conversion events',
    description: 'Send conversion events to advertising networks.',
    items: {
      person: [
        destinationTypeFacebookConversion,
        destinationTypeGoogleConversion,
      ],
    },
  },
  {
    key: 'advertising',
    title: 'Advertising audiences',
    description:
      'Send audience matches to Facebook for effective, targeted advertising.',
    audiences: true,
    items: {
      person: audienceSubTypesToShowAsDestinations[segmentTypes.person],
      company: audienceSubTypesToShowAsDestinations[segmentTypes.company],
    },
  },
  {
    key: 'personalization',
    title: 'Personalization',
    description: 'Customize your website in realtime.',
    items: {
      company: personalizationDestinationTypes(),
    },
  },
];

export default destinationGroups;
