import theme from '~/components/app/theme';

export const backgroundColors = {
  twitter: theme.palette.twitter[0],
  linkedin: theme.palette.linkedin[0],
  facebook: theme.palette.facebook[0],
  hubspot: theme.palette.hubspot[0],
  salesforce: theme.palette.salesforce[0],
};

export const borderColors = {
  twitter: theme.palette.twitter[100],
  linkedin: theme.palette.linkedin[100],
  facebook: theme.palette.facebook[100],
  hubspot: theme.palette.hubspot[100],
  salesforce: theme.palette.salesforce[100],
};

export const keyColors = {
  twitter: theme.palette.twitter[500],
  linkedin: theme.palette.linkedin[500],
  facebook: theme.palette.facebook[500],
  hubspot: theme.palette.hubspot[500],
  salesforce: theme.palette.salesforce[500],
};
