import {
  disabledCategoriesForNode,
  getAndOrOr,
  isQueryGroup,
} from '~/components/SearchBuilder/utils/nodeUtils';
import {
  getDependentCategory,
  getRootCategory,
  getService,
  isNodeExpanded,
  isThirdPartyNode,
} from '~/components/SearchBuilder/utils/thirdPartySearchUtils';

import styles from '../styles';
import { MAXIMUM_NEST_LEVEL } from '../utils/constants';
import QueryGroup from './QueryGroup';
import ThirdPartyQueryGroup from './QueryGroup/ThirdPartyQueryGroup';
import QueryNode from './QueryNode';

let StyledAnyQueryNode;

class AnyQueryNode extends React.Component {
  renderQueryGroup = (node) => {
    const { classes, search, onChange, attributes, emptyGroup } = this.props;
    const andOrOr = getAndOrOr(node);

    return (
      <QueryGroup
        attributes={attributes}
        canAddGroup={node.__nest < MAXIMUM_NEST_LEVEL}
        className={classes.queryGroup}
        emptyGroup={emptyGroup}
        key={node.__index}
        node={node}
        onChange={onChange}
        search={search}
      >
        {node[andOrOr] &&
          node[andOrOr].map((node) => (
            <StyledAnyQueryNode
              {...this.props}
              key={node.__index}
              node={node}
            />
          ))}
      </QueryGroup>
    );
  };

  renderThirdPartyNode = (node) => {
    const {
      classes,
      type,
      onChange,
      attributes,
      search,
      isSelectiveEnrichmentConfig,
      emptyGroup,
    } = this.props;
    const dependentCategory = getDependentCategory(node, type);
    const rootCategory = getRootCategory(node);
    const service = getService(node);
    const expanded = isNodeExpanded(node);

    return (
      <ThirdPartyQueryGroup
        attributes={attributes}
        className={classes.queryGroup}
        dependentCategory={dependentCategory}
        emptyGroup={emptyGroup}
        expanded={expanded}
        isSelectiveEnrichmentConfig={isSelectiveEnrichmentConfig}
        key={node.__index}
        node={node}
        onChange={onChange}
        rootCategory={rootCategory}
        search={search}
        service={service}
      />
    );
  };

  render = () => {
    const { node, search, category } = this.props;

    let disabledCategories = disabledCategoriesForNode(search, node).filter(
      (_category) => _category !== category,
    );

    if (isQueryGroup(node)) {
      return this.renderQueryGroup(node);
    }

    if (isThirdPartyNode(node)) {
      return this.renderThirdPartyNode(node);
    }

    return (
      <QueryNode
        {...this.props}
        disabledCategories={disabledCategories}
        key={node.__index}
        node={node}
        search={search}
      />
    );
  };
}

AnyQueryNode.propTypes = {
  category: PropTypes.string,
  type: PropTypes.string,
  classes: PropTypes.object,
  search: PropTypes.object,
  onChange: PropTypes.func,
  attributes: PropTypes.array,
  node: PropTypes.object,
  isSelectiveEnrichmentConfig: PropTypes.bool,
  emptyGroup: PropTypes.object,
};

StyledAnyQueryNode = withStyles(styles)(AnyQueryNode);

export default StyledAnyQueryNode;
