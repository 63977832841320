export default () => ({
  pathInput: {
    flex: '1 1 100px',
    marginRight: 12,
    paddingRight: 2,
    alignItems: 'center',
    '&::placeholder': {
      opacity: 1,
      color: '#9BADBC',
    },
    '&::-webkit-input-placeholder': {
      opacity: 1,
      color: '#9BADBC',
    },
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,

    '& > input': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },

  pathOnly: {
    flexGrow: 1,
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
  },

  timeframeInput: {
    width: '90px',
  },

  timeframeContainer: {
    minWidth: 220,
    '& > div': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },

  countContainer: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  host: {
    marginRight: 16,
    flex: '1 0 auto',
    minWidth: 120,
  },

  hostWithPath: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    marginRight: -1,
    flex: '1 1 100px',

    '& > div': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },

  countInput: {
    flex: '0 0 51px',
    marginRight: -1,
    '& input': {
      padding: '0 2px 0 2px',
      textAlign: 'center',
    },
  },

  countLabel: {
    display: 'inline-flex',
    flex: '0 0 43px',
    fontSize: '12px',
    fontWeight: 500,
    color: '#414953',
    margin: 'auto 8px',
    textAlign: 'center',
  },
});
