import { useEffect, useState } from 'react';

declare global {
  interface Window {
    GetTelemetryID?: (token: string) => Promise<string>;
  }
}

const STYTCH_PUBLIC_TOKEN =
  window.location.host === 'app.clearbit.com'
    ? 'public-token-live-b38ba3ce-9a13-483a-9348-7f558668bdfb'
    : 'public-token-test-84b36dc7-84e1-403b-9e0b-9ebbbc441f01';

export default function useTelemetryId() {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    const existingTelemetryScript = document.getElementById(
      'stytchTelemetryAPI',
    );
    if (existingTelemetryScript) {
      setScriptLoaded(true);
      return;
    }

    const telemetryScript = document.createElement('script');

    telemetryScript.src = 'https://elements.stytch.com/telemetry.js';
    telemetryScript.id = 'stytchTelemetryAPI';
    telemetryScript.async = true;
    telemetryScript.onload = () => {
      setScriptLoaded(true);
    };

    const head = document.getElementsByTagName('head')[0];
    const firstScript = head.getElementsByTagName('script')[0];
    head.insertBefore(telemetryScript, firstScript);
  }, []);

  async function getTelemetryId(): Promise<string> {
    if (!scriptLoaded) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(getTelemetryId());
        }, 100);
      });
    }

    if (!window.GetTelemetryID) {
      throw new Error('Telemetry script not correctly initialized');
    }

    return await window.GetTelemetryID(STYTCH_PUBLIC_TOKEN);
  }

  return { getTelemetryId };
}
