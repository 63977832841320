import Checkbox from '@material-ui/core/Checkbox';
import get from 'lodash/get';
import includes from 'lodash/includes';
import last from 'lodash/last';
import some from 'lodash/some';
import { components } from 'react-select';
import { FixedSizeList as List } from 'react-window';

import Caret from './Caret.svg';
import {
  flattenOptions,
  getSelectedState,
  levelForOption,
  matchValue,
  states,
} from './checkboxLogic.js';
import Checked from './Checked.svg';
import DisabledChecked from './DisabledChecked.svg';
import Some from './Some.svg';
import styles from './styles.js';
import Unchecked from './Unchecked.svg';

const PADDING_TOP = 5;

const iconForState = (state) => {
  switch (state) {
    case states.ON:
      return <Checked />;
    case states.OFF:
      return <Unchecked />;
    case states.SOME:
      return <Some />;
    case states.GREY:
      return <DisabledChecked />;
    default:
      return state;
  }
};

class MenuList extends React.Component {
  constructor(props) {
    super(props);
    const {
      options,
      selectProps: { sectionsOpen },
    } = props;

    this.state = {
      options: flattenOptions(options, sectionsOpen),
    };
  }

  componentDidUpdate(prevProps) {
    const {
      options,
      selectProps: {
        inputValue: currentValue,
        updateSuggestions,
        sectionsOpen,
      },
    } = this.props;

    const lastValue = last(this.props.selectProps.value)?.value;
    if (lastValue) {
      const list = this.list.current;
      const index = this.state.options.findIndex((a) => a.value === lastValue);
      const scrollPosition = index * list.props.itemSize;
      const { scrollOffset } = list.state;

      if (Math.abs(scrollPosition - scrollOffset) > list.props.height) {
        this.list.current.scrollToItem(index + 5);
      }
    }

    const {
      selectProps: {
        inputValue: previousValue,
        sectionsOpen: previousSectionsOpen,
      },
    } = prevProps;

    if (sectionsOpen !== previousSectionsOpen) {
      const newOptions = flattenOptions(options, sectionsOpen);
      this.setState({ options: newOptions });
    }

    if (currentValue !== previousValue) {
      let newOptions = options;
      if (currentValue.length > 0) {
        newOptions = this.filterMatchingBranches(options, currentValue);
      }
      updateSuggestions(this.suggestions(options, currentValue));

      newOptions = flattenOptions(newOptions);
      this.setState({ options: newOptions });
    }
  }

  renderBoldMatch(match, string) {
    if (!match) return string;
    const length = match[0].length;
    const index = match.index;

    return (
      <span>
        {string.slice(0, index)}
        <strong>{string.slice(index, index + length)}</strong>
        {string.slice(index + length)}
      </span>
    );
  }

  suggestions(options, inputValue) {
    const {
      selectProps: { getOptionLabel },
    } = this.props;

    return flattenOptions(options).filter((option) => {
      const optionLabel = getOptionLabel(option);
      const match = matchValue(optionLabel, inputValue);
      return match && match.index === 0;
    });
  }

  filterMatchingBranches(options, inputValue, level = 0) {
    const {
      selectProps: { getOptionLabel },
    } = this.props;

    if (!options) return [];

    const filteredOptions = options.reduce((array, option) => {
      const optionLabel = getOptionLabel(option);
      const match = matchValue(optionLabel, inputValue);

      if (!option.children && match) {
        array.push(option);
        return array;
      }

      let children = this.filterMatchingBranches(
        option.options,
        inputValue,
        level + 1,
      );

      if ((inputValue.length > 0 && match) || children.length) {
        return array.concat(Object.assign({}, option, { options: children }));
      }
      return array;
    }, []);

    return filteredOptions;
  }

  renderOption = (index, style) => {
    const {
      classes,
      options: defaultOptions,
      selectProps: {
        value,
        inputValue,
        activeSuggestion,
        sectionsOpen,
        handleSelectOption,
        toggleSection,
        getOptionLabel,
        getOptionValue,
        suggestions,
      },
      ...props
    } = this.props;

    const isNested = defaultOptions.some(
      (o) => o.options && o.options.length > 0,
    );

    const { options } = this.state;
    const option = options[index];
    const optionValue = getOptionValue(option);
    const optionLabel = getOptionLabel(option);
    const hasQuery = inputValue.length > 0;
    const match = hasQuery && matchValue(optionLabel, inputValue);
    const state = getSelectedState(option, defaultOptions, value);
    const icon = iconForState(state);

    const isActiveSuggestion =
      suggestions[activeSuggestion] && suggestions[activeSuggestion] === option;

    const open = some(sectionsOpen, option);
    const shouldCheck = includes([states.OFF, states.SOME], state);
    const level = levelForOption(option, defaultOptions, getOptionValue);
    const paddingLeftOffset = 13;
    const paddingLeft = 25 * level + paddingLeftOffset;

    return (
      <components.Option
        {...props}
        innerProps={{
          onClick: () => {
            handleSelectOption(shouldCheck, option);
          },
          style: {
            ...style,
            top: style.top + PADDING_TOP,
            paddingLeft,
          },
          className: classnames({
            [classes.activeSuggestion]: isActiveSuggestion,
          }),
        }}
        key={optionValue}
      >
        {isNested && (
          <div
            className={classnames(classes.caretWrapper, {
              [classes.showCaret]: get(option, 'options.length', 0) > 0,
            })}
            onClick={(e) => {
              e.stopPropagation();
              toggleSection(option);
            }}
            onKeyDown={() => toggleSection(option)}
            role="button"
            tabIndex={0}
          >
            <Caret
              className={classnames(classes.caret, {
                [classes.rotated]: open,
              })}
            />
          </div>
        )}
        <Checkbox
          checked
          checkedIcon={icon}
          classes={{ root: classes.checkboxRoot }}
          icon={icon}
        />
        <span className={classes.optionLabel}>
          {this.renderBoldMatch(match, optionLabel)}
        </span>
      </components.Option>
    );
  };

  list = React.createRef();

  render() {
    const { classes } = this.props;

    let { options } = this.state;
    return (
      <List
        className={classes.menuList}
        height={240}
        itemCount={options.length}
        itemSize={28}
        ref={this.list}
      >
        {({ index, style }) => this.renderOption(index, style)}
      </List>
    );
  }
}

MenuList.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.array,
  selectProps: PropTypes.shape({
    inputValue: PropTypes.string,
    menuListTitle: PropTypes.string,
    sectionsOpen: PropTypes.array,
    menuListIcon: PropTypes.node,
  }),
  setValue: PropTypes.func.isRequired,
};

MenuList.defaultProps = {
  value: [],
};

const ForwardRefMenuList = React.forwardRef((props, ref) => (
  <MenuList innerRef={ref} {...props} />
));
ForwardRefMenuList.displayName = 'MenuList';

const StyledMenuList = withStyles(styles)(ForwardRefMenuList);

export default StyledMenuList;
