import { CKField, CKTextField } from 'clearkit';
import { ChangeEvent } from 'react';

const DEFAULT_RECORDS_MIN = 1;
const DEFAULT_RECORDS_MAX = 20;
export const DEFAULT_MAX_RECORDS = 5;

type MaxRecordsProps = {
  label: string;
  value: number;
  onChange: (newValue: number) => void;
};
export function MaxRecordsField({ label, value, onChange }: MaxRecordsProps) {
  function handleValueChange(e: ChangeEvent<HTMLInputElement>) {
    const newValue = parseInt(e.target.value);
    return onChange(newValue);
  }

  function handleBlur() {
    if (value > DEFAULT_RECORDS_MAX) {
      return onChange(DEFAULT_RECORDS_MAX);
    }
    if (value < DEFAULT_RECORDS_MIN) {
      return onChange(DEFAULT_RECORDS_MIN);
    }
  }
  /**
   * @todo remove ts-ignore when https://github.com/clearbit/clearkit/pull/605 is merged
   */
  return (
    <CKField label={label}>
      <CKTextField
        // @ts-ignore
        max={DEFAULT_RECORDS_MAX}
        // @ts-ignore
        min={DEFAULT_RECORDS_MIN}
        onBlur={handleBlur}
        onChange={handleValueChange}
        type="number"
        value={String(value)}
      />
    </CKField>
  );
}
