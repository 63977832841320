import { GoExternal, Salesforce } from '@clearkit/icons';
import map from 'lodash/map';

import { paginateField } from '~/lib/graphql/pagination';

import { keyColors } from './colors';
import Group from './Group';

const color = keyColors.salesforce;

const SalesforceGroup = ({
  search,
  type,
  properties = {},
  externalId,
  externalInstanceUrl,
  extraSections,
  name,
  ...rest
}) => (
  <Group
    {...rest}
    actionLink={
      <a
        className="inline-flex align-center"
        href={`${externalInstanceUrl}/${externalId}`}
        onClick={(e) => e.stopPropagation()}
        rel="noopener noreferrer"
        style={{ color }}
        target="_blank"
      >
        View in Salesforce
        <GoExternal className="mt-px ml-2" />
      </a>
    }
    icon={Salesforce}
    search={search}
    sections={[
      [
        { label: 'ID', value: externalId },
        {
          label: `${type} Name`,
          value: name,
        },
      ],
      extraSections,
      map(properties, (value, label) => ({ label, value })),
    ]}
    title={`Salesforce ${type} – ${name}`}
    type="salesforce"
  />
);

SalesforceGroup.propTypes = {
  search: PropTypes.string,
  type: PropTypes.string.isRequired,
  properties: PropTypes.object.isRequired,
  externalId: PropTypes.string.isRequired,
  externalInstanceUrl: PropTypes.string.isRequired,
  extraSections: PropTypes.array,
};

const SalesforceGroups = ({
  salesforceAccounts,
  salesforceContacts,
  salesforceLeads,
  search,
  salesforceAccountConnector,
}) => {
  return (
    <>
      {salesforceAccounts.map((salesforceAccount, i) => {
        const { externalId, properties, name } = salesforceAccount;
        const opportunities = paginateField(
          salesforceAccount,
          'salesforceOpportunities',
        );
        return (
          <>
            <SalesforceGroup
              externalId={externalId}
              externalInstanceUrl={
                salesforceAccountConnector.externalInstanceUrl
              }
              extraSections={[{ label: 'Opportunities', value: opportunities }]}
              key={i}
              name={name}
              properties={properties}
              search={search}
              type="Account"
            />

            {opportunities?.map(({ externalId, properties, name }, i) => (
              <SalesforceGroup
                externalId={externalId}
                externalInstanceUrl={
                  salesforceAccountConnector.externalInstanceUrl
                }
                key={i}
                name={name}
                properties={properties}
                search={search}
                type="Opportunity"
              />
            ))}
          </>
        );
      })}
      {salesforceLeads.map(({ externalId, properties, name }, i) => (
        <SalesforceGroup
          externalId={externalId}
          externalInstanceUrl={salesforceAccountConnector.externalInstanceUrl}
          key={i}
          name={name}
          properties={properties}
          search={search}
          type="Lead"
        />
      ))}
      {salesforceContacts.map(({ externalId, properties, name }, i) => (
        <SalesforceGroup
          externalId={externalId}
          externalInstanceUrl={salesforceAccountConnector.externalInstanceUrl}
          key={i}
          name={name}
          properties={properties}
          search={search}
          type="Contact"
        />
      ))}
    </>
  );
};

SalesforceGroups.propTypes = {
  salesforceAccounts: PropTypes.arrayOf(PropTypes.object),
  salesforceContacts: PropTypes.arrayOf(PropTypes.object),
  salesforceLeads: PropTypes.arrayOf(PropTypes.object),
  search: PropTypes.string,
  salesforceAccountConnector: PropTypes.shape({
    externalInstanceUrl: PropTypes.string,
  }),
};

export default SalesforceGroups;
