import { CKLayoutGrid, CKLayoutHorizontal } from 'clearkit';
import { cloneElement, Fragment } from 'react';
import { Link } from 'react-router-dom';

import LoadingArea from '~/components/LoadingArea';

import GraphqlError from '../../GraphqlError';
import RightArrow from './RightArrow.svg';
import styles from './styles';

function Layout({
  accessoryView,
  actions,
  border,
  breadcrumb,
  children,
  className,
  classes,
  darkHeader,
  deprecationWarning,
  error,
  loading,
  refetch,
  search,
  shadow,
  subtitle,
  tabs,
  tile,
  title,
}) {
  return (
    <div className={classes.layout}>
      <header
        className={classnames(classes.header, {
          [classes.border]: border || shadow,
          [classes.shadow]: shadow,
          [classes.darkHeader]: darkHeader,
        })}
      >
        <CKLayoutGrid className="p-8" gridTemplateColumns="1fr auto">
          <CKLayoutHorizontal justifyContent="start" wrap="nowrap">
            {tile &&
              cloneElement(tile, {
                className: classnames(tile.props.className, classes.tile),
              })}
            <div className="min-w-0 overflow-hidden">
              <h1 className="flex items-center w-full font-semibold text-[1.5rem] whitespace-nowrap">
                {breadcrumb?.map(({ title, path = '' }, index) => (
                  <Fragment key={`#{title}-${index}`}>
                    {path ? (
                      <Link className={classes.back} to={path}>
                        {title}
                      </Link>
                    ) : (
                      <span className={classes.backSpan}>{title}</span>
                    )}
                    <RightArrow className={classes.arrow} />
                  </Fragment>
                ))}
                <span className="truncate">{title}</span>
              </h1>
              {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
            </div>
          </CKLayoutHorizontal>
          <CKLayoutHorizontal justifyContent="start">
            {search && <div className="relative">{search}</div>}
            {actions && <div className={classes.actions}>{actions}</div>}
          </CKLayoutHorizontal>
        </CKLayoutGrid>

        {tabs && <div className={classes.tabs}>{tabs}</div>}
        {deprecationWarning && (
          <div className={classes.deprecationWarning}>{deprecationWarning}</div>
        )}
        {accessoryView && (
          <div className={classes.accessoryView}>{accessoryView}</div>
        )}
      </header>

      <div className={classnames(classes.contents, className)}>
        {error ? (
          <GraphqlError error={error} loading={loading} refetch={refetch} />
        ) : loading === false ? (
          children
        ) : (
          <LoadingArea loading={loading}>{children}</LoadingArea>
        )}
      </div>
    </div>
  );
}

Layout.propTypes = {
  actions: PropTypes.node,
  accessoryView: PropTypes.node,
  border: PropTypes.bool,
  breadcrumb: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string,
    }),
  ),
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  darkHeader: PropTypes.bool,
  deprecationWarning: PropTypes.node,
  error: PropTypes.object,
  loading: PropTypes.bool,
  refetch: PropTypes.func,
  search: PropTypes.node,
  shadow: PropTypes.bool,
  subtitle: PropTypes.node,
  tabs: PropTypes.node,
  tile: PropTypes.node,
  title: PropTypes.string.isRequired,
};

export default withStyles(styles)(Layout);
