import { CKContainerProps } from 'clearkit';

import { CTA } from './CTA';

export function GetStarted(props: CKContainerProps) {
  return (
    <CTA {...props}>
      <CTA.Heading>Get started with Prospector</CTA.Heading>
      <CTA.Description>
        Create a new prospect list to hone in on available prospects and add
        them to your CRM or to a CSV for download.
      </CTA.Description>
    </CTA>
  );
}
