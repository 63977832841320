import { useQuery } from '@apollo/client';
import {
  Job as RoleIcon,
  Location as LocationIcon,
  Security,
  Seniority as SeniorityIcon,
  String as TitleIcon,
} from '@clearkit/icons';
import { CKBox, CKDrawer } from 'clearkit';
import React, { useState } from 'react';

import { SALESFORCE_RESOURCE_TYPE } from '~/components/SearchBuilder/utils/constants';
import SalesforceLogo from '~/images/destinations/Salesforce.svg';
import { destinationTypeSalesforceCreateLead } from '~/lib/destinations';
import { readableSingularSegmentType } from '~/lib/segments';
import Disconnected from '~/pages/destinations/components/Disconnected';
import FormHeader from '~/pages/destinations/components/FormHeader';
import TriggerType from '~/pages/destinations/components/TriggerType';

import {
  DEFAULT_MAX_RECORDS,
  MaxRecordsField,
} from '../components/MaxRecordsField';
import PredicateSelector from '../components/PredicateSelector';
import TraitConfiguration from '../components/TraitConfiguration';
import query from './query.graphql';

function removeEmpty(object) {
  return Object.keys(object)
    .filter((k) => object[k]?.length > 0)
    .reduce((a, k) => ({ ...a, [k]: object[k] }), {});
}

function CreateExternalRecordsLeadSalesforce({
  errors,
  refetch,
  accountConnectors,
  destination,
  segment,
  FormFooter,
}) {
  const accountConnector = accountConnectors.salesforce;
  const isNew = !destination;
  const initialConfig = isNew ? {} : destination.config;
  const humanizedSegmentType = readableSingularSegmentType(segment.type);

  const initialState = {
    traits: initialConfig.traits || [],
    triggerType: isNew ? 'segment_enter' : destination.triggerType,
    prospectSearch: {
      role: initialConfig.prospectSearch?.role || [],
      seniority: initialConfig.prospectSearch?.seniority || [],
      title: initialConfig.prospectSearch?.title || [],
      country: initialConfig.prospectSearch?.country || [],
      pageSize: initialConfig.prospectSearch?.pageSize || DEFAULT_MAX_RECORDS,
    },
  };

  const nameTraits = [
    'Person First Name',
    'Person Last Name',
    'Person Full Name',
    'Person Name',
    'Person Given Name',
    'Person Family Name',
  ];
  const companyTraits = ['Person Employment Name', 'Person Employment Domain'];
  const mappedTraitLabels = {
    OwnerId: 'Lead Owner',
    Type: 'Lead Type',
  };
  const [traits, setTraits] = useState(initialState.traits);
  const [triggerType, setTriggerType] = useState(initialState.triggerType);

  const [role, setRole] = useState(initialState.prospectSearch.role);
  const [seniority, setSeniority] = useState(
    initialState.prospectSearch.seniority,
  );
  const [title, setTitle] = useState(initialState.prospectSearch.title);
  const [companyLocation, setCompanyLocation] = useState(
    initialState.prospectSearch.country,
  );
  const [maxRecords, setMaxRecords] = useState(
    initialState.prospectSearch.pageSize,
  );

  const { data, loading } = useQuery(query);

  if (!accountConnector || accountConnector?.status !== 'active') {
    return (
      <Disconnected
        FormFooter={FormFooter}
        Logo={SalesforceLogo}
        onSuccess={refetch}
        service="salesforce"
      />
    );
  }

  if (loading) {
    return null;
  }

  const headingClasses = 'mb-1 text-base font-medium text-gray-900';
  const subHeadingClasses =
    'mt-2 overflow-hidden text-sm text-gray-600 text-ellipsis whitespace-nowrap';

  return (
    <>
      <FormHeader
        customTitle={'Create new leads in Salesforce'}
        destinationFlowDescription={'Configuration Destination'}
        destinationName="Salesforce"
        destinationType={destinationTypeSalesforceCreateLead}
        segmentCollection={segment.collection.name}
        segmentName={segment.name}
        subtitle={
          'Discover people at companies in this audience and automatically create Salesforce leads'
        }
      />
      <CKDrawer.Body className="p-6 bg-gray-0">
        <h2 className={headingClasses}>Trigger</h2>
        <h3 className={subHeadingClasses}>
          Choose when this destination will be run.{' '}
          <a
            className="ck-link"
            href="https://help.clearbit.com/hc/en-us/articles/360023453694"
            rel="noopener noreferrer"
            target="_blank"
          >
            Learn more
          </a>
        </h3>

        <TriggerType
          className="!p-5"
          errors={errors}
          handleChange={(_, val) => setTriggerType(val)}
          isDisabled
          triggerType={triggerType}
          triggerTypes={[
            {
              value: 'segment_enter',
              label: (
                <span className="text-gray-600">
                  When a {humanizedSegmentType} <strong>enters</strong> the
                  audience
                </span>
              ),
            },
          ]}
        />

        <h2 className={headingClasses}>
          Prospect for people to create as leads
        </h2>
        <h3 className={subHeadingClasses}>
          For companies in this audience, discover people not in your CRM who
          match the following criteria.
        </h3>

        <CKBox className="flex p-6 mt-3 mb-6 gap-4">
          <PredicateSelector
            icon={<RoleIcon />}
            onSelectedItemsChange={setRole}
            options={data.prospectFilters.role}
            placeholder="Choose roles"
            selectedItems={role}
            typeName="role"
          />
          <PredicateSelector
            icon={<SeniorityIcon />}
            onSelectedItemsChange={setSeniority}
            options={data.prospectFilters.seniority}
            placeholder="Choose seniorities"
            selectedItems={seniority}
            typeName="seniority"
          />
          <PredicateSelector
            icon={<TitleIcon />}
            onSelectedItemsChange={setTitle}
            options={data.prospectFilters.title}
            placeholder="Choose titles"
            selectedItems={title}
            typeName="title"
          />
          <PredicateSelector
            icon={<LocationIcon />}
            onSelectedItemsChange={setCompanyLocation}
            options={data.prospectFilters.country}
            placeholder="Choose company locations"
            selectedItems={companyLocation}
            style={{ display: 'none' }}
            typeName="company location"
          />
          <MaxRecordsField
            label="Max leads"
            onChange={setMaxRecords}
            value={maxRecords}
          />
          <hr />
          <div className="flex text-sm italic text-gray-500 gap-2">
            <Security />
            <span>
              For GDPR compliance, we currently do not support prospecting for
              persons identified as being based in the EU.
            </span>
          </div>
        </CKBox>

        <h2 className={headingClasses}>Create Salesforce leads</h2>
        <h3 className={subHeadingClasses}>
          Choose the values to use for data fields when creating new leads in
          Salesforce.
        </h3>

        <TraitConfiguration
          companyAttributes={data.companyAttributes}
          companyTraits={companyTraits}
          errors={errors}
          mappedTraitLabels={mappedTraitLabels}
          nameTraits={nameTraits}
          personAttributes={data.personAttributes}
          salesforceResourceType={SALESFORCE_RESOURCE_TYPE.LEAD}
          salesforceTraits={data.salesforceTraits}
          setTraits={setTraits}
          traits={traits}
          unsettableValues={[
            {
              trait: 'Lead email address',
              value: 'Person email address',
            },
            {
              trait: 'Lead source',
              value: 'Clearbit created lead',
            },
          ]}
        />
      </CKDrawer.Body>

      <FormFooter
        destinationConfig={{
          config: {
            traits,
            prospect_search: {
              ...removeEmpty({
                role,
                title,
                seniority,
                country: companyLocation,
              }),
              page_size: maxRecords,
            },
          },
          triggerType,
        }}
      />
    </>
  );
}

export default CreateExternalRecordsLeadSalesforce;
