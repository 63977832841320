import { People } from '@clearkit/icons';
import {
  CKBox,
  CKLayoutHorizontal,
  CKMultilineTextFieldNext,
  CKSelectableItem,
  CKSelectNext,
  CKSkeleton,
  CKTextFieldNext,
} from 'clearkit';

import { lookupSelectableItem } from '../HubspotEnrichment/utils';
import { UpgradePlanItem } from './UpgradePlanItem';

export type ContactValues = {
  email: string;
  name: string;
  crmSize: string;
  enrichment: boolean;
  forms: boolean;
  message?: string;
};

type RequestAccessFormProps = {
  isLoading: boolean;
  onChange: (key: string) => (value: string | boolean) => void;
  values: ContactValues;
};

const crmSizeOptions = [
  {
    value: 'small',
    label: 'Less than 10,000 contacts',
  },
  {
    value: 'large',
    label: 'More than 10,000 contacts',
  },
  {
    value: 'note_sure',
    label: 'Not Sure',
  },
] as CKSelectableItem[];

export function FormContact({
  isLoading,
  onChange,
  values,
}: RequestAccessFormProps) {
  return (
    <CKBox className="p-6 space-y-6 divide-y divide-gray-200">
      <CKLayoutHorizontal className="h-[62.5px]">
        <CKSkeleton className="h-full grow" isLoading={isLoading}>
          <CKTextFieldNext
            className="grow"
            isRequired
            onChange={onChange('email')}
            value={values.email as string}
          >
            <CKTextFieldNext.Label>Email address</CKTextFieldNext.Label>
          </CKTextFieldNext>
        </CKSkeleton>
        <CKSkeleton className="h-full grow" isLoading={isLoading}>
          <CKTextFieldNext
            className="grow"
            isRequired
            onChange={onChange('name')}
            value={values.name as string}
          >
            <CKTextFieldNext.Label>Name</CKTextFieldNext.Label>
          </CKTextFieldNext>
        </CKSkeleton>
      </CKLayoutHorizontal>

      <div className="pt-6 space-y-6">
        <h3 className="font-medium text-gray-800">
          What are you interested in? Check all that apply.
        </h3>
        <div className="space-y-4">
          <UpgradePlanItem
            checked={values.enrichment}
            onChange={onChange('enrichment')}
          >
            <UpgradePlanItem.Title>
              Enriching companies and contacts in HubSpot
            </UpgradePlanItem.Title>
            <UpgradePlanItem.Description>
              Send rich firmographic company and contact data to HubSpot.
            </UpgradePlanItem.Description>
          </UpgradePlanItem>
          <UpgradePlanItem checked={values.forms} onChange={onChange('forms')}>
            <UpgradePlanItem.Title>
              Better form conversion and lead capture
            </UpgradePlanItem.Title>
            <UpgradePlanItem.Description>
              Use Clearbit Forms to fill in the blanks and dynamically show form
              fields for improved lead capture and form conversion.
            </UpgradePlanItem.Description>
          </UpgradePlanItem>
        </div>
        <CKSelectNext
          items={crmSizeOptions}
          onChange={(newValue) => onChange('crmSize')(newValue.value)}
          placeholder="Select your CRM size"
          value={lookupSelectableItem(values.crmSize, crmSizeOptions)}
        >
          <CKSelectNext.Label className="mb-3">
            How many contacts are in your CRM?
          </CKSelectNext.Label>
          <CKSelectNext.IconLeft>
            <People />
          </CKSelectNext.IconLeft>
        </CKSelectNext>
      </div>
      <section className="py-6">
        <h3 className="mb-1 font-medium">Optional: Add a message</h3>
        <p>
          Don't see what you're looking for? That's ok. Let us know anything
          else you'd like to share around what you're aiming to do, and we'll
          get in touch!
        </p>
        <CKMultilineTextFieldNext
          className="mt-4"
          data-testid="message"
          onChange={onChange('message')}
          placeholder="Let us know a little more about what you're aiming to accomplish and if you have any additional requirements."
          rel="message"
          rows={5}
          value={values.message as string}
        />
      </section>
    </CKBox>
  );
}
