import moment from 'moment';

import DateChevronLeft from './DateChevronLeft.svg';
import DateChevronRight from './DateChevronRight.svg';

const DayButton = ({ className, date, onClick }) => (
  <button
    className={className}
    onClick={() => onClick(date.format())}
    type="button"
  >
    {date.format('D')}
  </button>
);

DayButton.propTypes = {
  className: PropTypes.string.isRequired,
  date: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: moment(props.startDate),
    };
  }

  nextMonth = () => {
    this.setState({ date: moment(this.state.date).add(1, 'month') });
  };

  previousMonth = () => {
    this.setState({ date: moment(this.state.date).subtract(1, 'month') });
  };

  handleDateSelected = (datestamp) => {
    const selectedDate = moment(datestamp);

    if (this.dateValid(selectedDate)) {
      this.props.dateChange(selectedDate);
    }
  };

  dateValid = (date) => {
    const { minDate, maxDate } = this.props;
    let valid = true;

    if (minDate) {
      valid = date.isAfter(minDate, 'day');
      if (!valid) return false;
    }

    if (maxDate) {
      valid = date.isBefore(moment(maxDate).add(1, 'day'), 'day');
      if (!valid) return false;
    }

    return true;
  };

  daysOfMonth = () => {
    const { classes, selectedDate } = this.props;
    let date = moment(this.state.date)
      .startOf('month')
      .startOf('week')
      .clone();
    const endOfMonth = moment(this.state.date)
      .endOf('month')
      .endOf('week');

    let renderDays = [];

    while (date <= endOfMonth) {
      const isDisabled = !this.dateValid(date.clone());
      const selected = selectedDate.isSame(date.clone(), 'day');
      const outOfMonth = !this.state.date.isSame(date.clone(), 'month');
      const buttonClassName = classnames(classes.date, {
        [classes.dateSelected]: selected,
        [classes.dateDisabled]: isDisabled,
        [classes.dateOutOfMonth]: outOfMonth,
      });

      renderDays = [
        ...renderDays,
        <DayButton
          className={buttonClassName}
          date={date.clone()}
          key={date.format('YYYYMMDD')}
          onClick={this.handleDateSelected}
        />,
      ];
      date.add(1, 'day');
    }

    return renderDays;
  };

  render() {
    const { classes } = this.props;
    const days = this.daysOfMonth();

    return (
      <div className={classes.calendarRoot}>
        <div className={classes.monthControls}>
          <button
            className={classnames(classes.monthButton, classes.monthPrevious)}
            onClick={this.previousMonth}
            type="button"
          >
            <DateChevronLeft height="9px" width="5px" />
          </button>
          <div className={classes.monthLabel}>
            {moment(this.state.date).format('MMMM YYYY')}
          </div>
          <button
            className={classnames(classes.monthButton, classes.monthNext)}
            onClick={this.nextMonth}
            type="button"
          >
            <DateChevronRight height="9px" width="5px" />
          </button>
        </div>
        <div className={classes.monthDates}>{days}</div>
      </div>
    );
  }
}

Calendar.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedDate: PropTypes.object.isRequired,
  dateChange: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
};
