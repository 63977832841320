import { useCurrentUser } from '~/components/profile/useCurrentUser';
import { useCurrentAccountQuery } from '~/generated/graphql';

export default function useCurrentAccount() {
  const { data, loading, refetch } = useCurrentAccountQuery();
  const currentUser = useCurrentUser();

  return {
    account: data?.account,
    currentUser,
    loading,
    refetch,
  };
}
