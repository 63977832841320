import {
  Facebook as FacebookIcon,
  Linkedin as LinkedInIcon,
  Twitter as TwitterIcon,
} from '@clearkit/icons';
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import some from 'lodash/some';
import startCase from 'lodash/startCase';
import { useState } from 'react';
import { FlagIcon, FlagIconProps } from 'react-flag-kit';

import { ProspectorPreview } from '~/components/CapturePreview/ProspectorPreview';
import {
  Company,
  HubspotCompany,
  SalesforceAccountConnection,
  SalesforceAccountConnector,
} from '~/generated/graphql';
import { formatNumberWithSuffix } from '~/lib/formatters';
import { paginateField } from '~/lib/graphql/pagination';

import OpportunitiesLeadsCallout from './OpportunitiesLeadsCallout';
import PageviewGraph from './PageviewGraph';

type CompanyDetailsProps = {
  salesforceAccountConnector?: SalesforceAccountConnector;
  salesforceAccounts?: SalesforceAccountConnection[];
  hubspotOwners?: any[];
  hubspotCompanies?: HubspotCompany[];
  company: Company;
  hideProspectorPreview?: boolean;
};

const CompanyDetails = ({
  company,
  salesforceAccounts,
  salesforceAccountConnector,
  hubspotOwners,
  hubspotCompanies,
  hideProspectorPreview,
}: CompanyDetailsProps) => {
  const {
    city,
    country,
    countryCode,
    description,
    employeesRange,
    estimatedAnnualRevenue,
    facebook,
    subIndustry,
    linkedin,
    location,
    marketCap,
    raised,
    sector,
    tags,
    ticker,
    twitter,
    type,
  } = company;

  const change_pageviews_week_over_week =
    company?.traits?.change_pageviews_week_over_week;

  const openOpportunities = flatten(
    salesforceAccounts?.map((account) =>
      paginateField(account, 'salesforceOpportunities').filter(
        (opportunity: any) => !opportunity.properties.IsClosed,
      ),
    ),
  );

  // Make the company a key on each deal
  const deals = flatten(
    hubspotCompanies?.map((hubspotCompany) => {
      if (!hubspotCompany) return [];
      const hubspotDeals = paginateField(hubspotCompany, 'deals');
      return (
        hubspotDeals?.map((deal: any) => ({ ...deal, hubspotCompany })) || []
      );
    }),
  );

  // Filter deals by whether they're open
  const openDeals = deals.filter((d) => !d.closed);

  const [showingMore, setShowingMore] = useState(false);

  return (
    <>
      {some([
        employeesRange,
        type,
        estimatedAnnualRevenue,
        raised,
        ticker,
        marketCap,
        twitter,
        facebook,
        linkedin,
      ]) && (
        <div className="flex flex-shrink-0 px-10 py-5 text-sm border-b border-gray-200 hide-if-empty">
          <div className="flex flex-col flex-shrink-0 pr-8">
            <span className="mb-1 text-xs">Employees</span>
            <span>{employeesRange || <>&mdash;</>}</span>
          </div>
          {type && (
            <div className="flex flex-col flex-shrink-0 pr-8">
              <span className="mb-1 text-xs">Type</span>
              <span>{startCase(type)}</span>
            </div>
          )}
          {estimatedAnnualRevenue && (
            <div className="flex flex-col flex-shrink-0 pr-8">
              <span className="mb-1 text-xs">Annual Revenue</span>
              <span>{estimatedAnnualRevenue}</span>
            </div>
          )}
          {raised && (
            <div className="flex flex-col flex-shrink-0 pr-8">
              <span className="mb-1 text-xs">Raised</span>
              <span>${formatNumberWithSuffix(raised)}</span>
            </div>
          )}

          {ticker && (
            <div className="flex flex-col flex-shrink-0 pr-8">
              <span className="mb-1 text-xs">Ticker</span>
              <span>{ticker}</span>
            </div>
          )}

          {marketCap && (
            <div className="flex flex-col flex-shrink-0 pr-8">
              <span className="mb-1 text-xs">Market Cap</span>
              <span>${formatNumberWithSuffix(marketCap)}</span>
            </div>
          )}

          <div className="flex flex-row items-center justify-center ml-auto">
            {twitter && (
              <a
                className="ml-4 text-twitter"
                href={`https://twitter.com/${twitter}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <TwitterIcon style={{ width: 20, height: 20 }} />
              </a>
            )}
            {facebook && (
              <a
                className="ml-4 text-facebook"
                href={`https://facebook.com/${facebook}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FacebookIcon style={{ width: 20, height: 20 }} />
              </a>
            )}
            {linkedin && (
              <a
                className="ml-4 text-linkedin"
                href={`https://linkedin.com/${linkedin}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <LinkedInIcon style={{ width: 20, height: 20 }} />
              </a>
            )}
          </div>
        </div>
      )}
      {some([
        country,
        countryCode,
        sector,
        subIndustry,
        description,
        tags,
        change_pageviews_week_over_week,
      ]) && (
        <>
          <div className="flex flex-row justify-between flex-shrink-0 w-full px-10 pt-6 text-sm border-b border-gray-200 pb-7 bg-gray-0">
            <div className="w-full">
              {country && countryCode && (
                <div className="pb-4 border-b border-gray-200">
                  <div className="flex flex-row items-center">
                    <div>
                      <FlagIcon
                        className="relative mr-2 -top-0.5"
                        code={countryCode as FlagIconProps['code']}
                        size={20}
                      />
                    </div>
                    {compact([city, country]).join(', ')}
                  </div>
                  <p className="pt-1 text-xs text-gray">{location}</p>
                </div>
              )}
              {(sector || subIndustry) && (
                <div className="py-4 border-b border-gray-200">
                  {sector && (
                    <p className="p-0">
                      <span className="block mb-2 text-xs text-gray min-w-[80px]">
                        Sector
                      </span>{' '}
                      {sector}
                    </p>
                  )}
                  {subIndustry && (
                    <p className="p-0">
                      <span className="block text-xs text-gray min-w-[80px]">
                        Sub-Industry
                      </span>{' '}
                      {subIndustry}
                    </p>
                  )}
                </div>
              )}
              {description && (
                <div className="mt-4">
                  <span className="block mb-2 text-xs text-gray min-w-[80px]">
                    Description
                  </span>
                  <p className="pb-1">
                    {showingMore ? description : description?.substr(0, 100)}
                  </p>
                  {!showingMore && description?.length > 100 && (
                    <p>
                      <button
                        className="text-xs link-button"
                        onClick={() => setShowingMore(true)}
                      >
                        Show more
                      </button>
                    </p>
                  )}
                </div>
              )}
              {tags && (
                <div className="mt-4">
                  <span className="block mb-2 text-xs text-gray">Tags</span>
                  <div className="flex flex-wrap">
                    {tags.map((tag) => (
                      <span className="my-1 mr-2 ck-tag" key={tag}>
                        {tag}
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="flex flex-col w-8/12 pl-8 space-y-4">
              {typeof change_pageviews_week_over_week !== 'undefined' && (
                <PageviewGraph
                  className="relative w-full p-0 overflow-hidden ck-box"
                  percentageChange={change_pageviews_week_over_week || 0}
                />
              )}
              {(openOpportunities?.length > 0 || deals?.length > 0) && (
                <OpportunitiesLeadsCallout
                  className="relative w-full"
                  hubspotDeals={openDeals}
                  hubspotOwners={hubspotOwners}
                  salesforceAccountConnector={salesforceAccountConnector}
                  salesforceOpportunities={openOpportunities}
                />
              )}
            </div>
          </div>

          {!hideProspectorPreview && (
            <ProspectorPreview
              domain={company.domain as string}
              name={company.name as string}
            />
          )}
        </>
      )}
    </>
  );
};

export default CompanyDetails;
