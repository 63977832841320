import { Clock, Export } from '@clearkit/icons';
import {
  CKDrawer,
  CKTab,
  CKTabList,
  CKTabPanel,
  CKTabPanels,
  CKTabs,
} from 'clearkit';

import { useProspector } from '../useProspector';
import { ExportsListHistory, ExportsListPending } from '.';

export function ExportProspectList() {
  const [_state, send] = useProspector();

  const handleTabChange = (index: number) => {
    if (index === 1) {
      send('TOGGLE_EXPORT_DRAWER_HISTORY');
    } else {
      send('TOGGLE_EXPORT_DRAWER_PENDING');
    }
  };

  return (
    <>
      <CKTabs className="flex flex-col h-full" onChange={handleTabChange}>
        <CKDrawer.Header className="pb-0">
          <CKDrawer.Heading>Prospector Exports</CKDrawer.Heading>
          <p className="mb-6 text-sm text-gray-600">
            Exports from Clearbit Prospector to CSV or to your connected CRM.
          </p>
          <CKTabList>
            <CKTab icon={<Export />} label="Manage account">
              Exports
            </CKTab>
            <CKTab icon={<Clock />} label="Your teams">
              Export history
            </CKTab>
          </CKTabList>
        </CKDrawer.Header>
        <CKDrawer.Body className="bg-gray-0">
          <CKTabPanels className="h-full">
            <CKTabPanel className="h-full">
              <ExportsListPending />
            </CKTabPanel>
            <CKTabPanel className="h-full">
              <ExportsListHistory />
            </CKTabPanel>
          </CKTabPanels>
        </CKDrawer.Body>
      </CKTabs>
    </>
  );
}
