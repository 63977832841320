import { CKLayoutGrid, CKLayoutGridProps } from 'clearkit';

export const MappingRowContainer = ({
  children,
  ...rest
}: CKLayoutGridProps) => (
  <CKLayoutGrid {...rest} gap="1.5rem" gridTemplateColumns="1fr 1fr 3rem">
    {children}
  </CKLayoutGrid>
);
