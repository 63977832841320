import classnames from 'classnames';
import {
  CKLayoutGridAuto,
  CKLayoutHorizontal,
  CKTextFieldNext,
} from 'clearkit';
import { formatCreditCard, formatDate, getCreditCardType } from 'cleave-zen';
import { forwardRef, useImperativeHandle, useState } from 'react';

type Props = {
  className?: string;
  errors?: Partial<FormData>;
};

export type CustomStripeCCInputRef = {
  getFormData: () => FormData;
};

export type FormData = {
  fullName: string;
  billingAddress: string;
  city: string;
  state: string;
  zipcode: string;
  creditCardNumber: string;
  expiration: string;
  cvv: string;
};

const CustomStripeCCInput = forwardRef<CustomStripeCCInputRef, Props>(
  ({ className, errors }, ref) => {
    const [formData, setFormData] = useState<FormData>({
      fullName: '',
      billingAddress: '',
      city: '',
      state: '',
      zipcode: '',
      creditCardNumber: '',
      expiration: '',
      cvv: '',
    });

    const formatValue = (name: string, value: string) => {
      let cardType;
      let maxLength;

      switch (name) {
        case 'creditCardNumber':
          return formatCreditCard(value);
        case 'expiration':
          return formatDate(value, {
            delimiter: '/',
            datePattern: ['m', 'y'],
          });
        case 'cvv':
          cardType = getCreditCardType(formData.creditCardNumber);
          maxLength = cardType === 'amex' ? 4 : 3;
          return value.substring(0, maxLength);
        default:
          return value;
      }
    };

    const handleChange = (name: string, value: string) => {
      const formattedValue = formatValue(name, value);
      setFormData((prev) => ({ ...prev, [name]: formattedValue }));
    };

    useImperativeHandle(ref, () => ({
      getFormData: () => formData,
    }));

    return (
      <div className={classnames(className, 'space-y-4')}>
        <CKLayoutGridAuto minWidth="15rem">
          <CKTextFieldNext
            name="fullName"
            onChange={(value) => handleChange('fullName', value)}
            value={formData.fullName}
            variant={errors?.fullName ? 'error' : undefined}
          >
            <CKTextFieldNext.Label>Name on card</CKTextFieldNext.Label>
            {errors?.fullName && (
              <CKTextFieldNext.ErrorMessage>
                {errors?.fullName}
              </CKTextFieldNext.ErrorMessage>
            )}
          </CKTextFieldNext>
          <CKTextFieldNext
            name="billingAddress"
            onChange={(value) => handleChange('billingAddress', value)}
            placeholder="Street address"
            value={formData.billingAddress}
            variant={errors?.billingAddress ? 'error' : undefined}
          >
            <CKTextFieldNext.Label>Billing address</CKTextFieldNext.Label>
            {errors?.billingAddress && (
              <CKTextFieldNext.ErrorMessage>
                {errors?.billingAddress}
              </CKTextFieldNext.ErrorMessage>
            )}
          </CKTextFieldNext>
        </CKLayoutGridAuto>
        <CKLayoutHorizontal justifyContent="start">
          <CKTextFieldNext
            className="grow"
            name="city"
            onChange={(value) => handleChange('city', value)}
            value={formData.city}
            variant={errors?.city ? 'error' : undefined}
          >
            <CKTextFieldNext.Label>City</CKTextFieldNext.Label>
            {errors?.city && (
              <CKTextFieldNext.ErrorMessage>
                {errors?.city}
              </CKTextFieldNext.ErrorMessage>
            )}
          </CKTextFieldNext>
          <CKLayoutGridAuto
            autoRepeat="auto-fit"
            className="grow"
            minWidth="5rem"
          >
            <CKTextFieldNext
              name="state"
              onChange={(value) => handleChange('state', value)}
              value={formData.state}
              variant={errors?.state ? 'error' : undefined}
            >
              <CKTextFieldNext.Label>State</CKTextFieldNext.Label>
              {errors?.state && (
                <CKTextFieldNext.ErrorMessage>
                  {errors?.state}
                </CKTextFieldNext.ErrorMessage>
              )}
            </CKTextFieldNext>
            <CKTextFieldNext
              name="zipcode"
              onChange={(value) => handleChange('zipcode', value)}
              value={formData.zipcode}
              variant={errors?.zipcode ? 'error' : undefined}
            >
              <CKTextFieldNext.Label>Zipcode</CKTextFieldNext.Label>
              {errors?.zipcode && (
                <CKTextFieldNext.ErrorMessage>
                  {errors?.zipcode}
                </CKTextFieldNext.ErrorMessage>
              )}
            </CKTextFieldNext>
          </CKLayoutGridAuto>
        </CKLayoutHorizontal>
        <CKLayoutHorizontal justifyContent="start">
          <CKTextFieldNext
            className="grow"
            name="creditCardNumber"
            onChange={(value) => handleChange('creditCardNumber', value)}
            value={formData.creditCardNumber}
            variant={errors?.creditCardNumber ? 'error' : undefined}
          >
            <CKTextFieldNext.Label>Credit card number</CKTextFieldNext.Label>
            {errors?.creditCardNumber && (
              <CKTextFieldNext.ErrorMessage>
                {errors?.creditCardNumber}
              </CKTextFieldNext.ErrorMessage>
            )}
          </CKTextFieldNext>
          <CKLayoutGridAuto
            autoRepeat="auto-fit"
            className="grow"
            minWidth="5rem"
          >
            <CKTextFieldNext
              name="expiration"
              onChange={(value) => handleChange('expiration', value)}
              placeholder="MM/YY"
              value={formData.expiration}
              variant={errors?.expiration ? 'error' : undefined}
            >
              <CKTextFieldNext.Label>Expiration</CKTextFieldNext.Label>
              {errors?.expiration && (
                <CKTextFieldNext.ErrorMessage>
                  {errors?.expiration}
                </CKTextFieldNext.ErrorMessage>
              )}
            </CKTextFieldNext>
            <CKTextFieldNext
              name="cvv"
              onChange={(value) => handleChange('cvv', value)}
              value={formData.cvv}
              variant={errors?.cvv ? 'error' : undefined}
            >
              <CKTextFieldNext.Label>CVV</CKTextFieldNext.Label>
              {errors?.cvv && (
                <CKTextFieldNext.ErrorMessage>
                  {errors?.cvv}
                </CKTextFieldNext.ErrorMessage>
              )}
            </CKTextFieldNext>
          </CKLayoutGridAuto>
        </CKLayoutHorizontal>
      </div>
    );
  },
);

export default CustomStripeCCInput;
