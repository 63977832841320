export default () => ({
  datePickers: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '32%',
  },

  and: {
    paddingLeft: 12,
    paddingRight: 12,
  },

  withinContainer: {
    display: 'flex',
  },

  withinText: {
    minWidth: '40%',
    flexGrow: '1',
    marginRight: 10,
  },

  withinSelect: {
    width: '50%',
    display: 'inline-block',
  },
});
