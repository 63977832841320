import { Mutation } from '@apollo/client/react/components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CKButton } from 'clearkit';
import isUrl from 'is-url';

import Check from '~/images/destinations/Check';
import { emailRegex } from '~/lib/email';
import { errorList } from '~/lib/graphql/helpers';

import deliverTestDestination from './mutation.graphql';
import styles from './styles';
import Warning from './Warning';

const sentCopy = {
  email: 'Test email sent',
  slack: 'Slack alert delivered',
  webhook: 'Webhook delivered',
};

class DeliverTestButton extends React.Component {
  state = {
    error: null,
    sent: false,
  };

  testDestination = (deliverTestDestination) => {
    const {
      config,
      config: { message, subject },
      segmentId,
      segmentType,
      destinationType: type,
    } = this.props;

    this.setState({ sent: false, error: null });

    deliverTestDestination({
      variables: {
        input: {
          config: {
            ...config,
            ...(subject && { subject: `[TEST] ${subject}` }),
            ...(message && { message: `[TEST] ${message}` }),
            frequency: 'daily',
          },
          segmentId,
          segmentType,
          type,
        },
      },
    });
  };

  testComplete = ({ response }) => {
    if (response.errors.length > 0) {
      const error = errorList(response.errors);
      return this.setState({ error: error });
    }

    this.setState({ sent: true });
  };

  validConfig = () => {
    const {
      destinationType,
      config: { recipients, subject, url, channels, webhookUrl },
    } = this.props;

    switch (destinationType) {
      case 'webhook':
        return isUrl(url);
      case 'slack':
        return channels || webhookUrl;
      case 'email':
        return subject && recipients.every((email) => email.match(emailRegex));
      default:
        return false;
    }
  };

  renderButtonInner = (sending) => {
    const {
      classes: { buttonText, loader },
    } = this.props;
    const { sent } = this.state;

    if (sending) {
      return (
        <span className={buttonText}>
          <CircularProgress className={loader} color="inherit" size={16} />
          Running Test
        </span>
      );
    }

    if (sent) {
      return <span>Run test again</span>;
    }

    return <span>Run test</span>;
  };

  renderSent = () => {
    const copy = sentCopy[this.props.destinationType];

    return (
      <>
        <Check fill="#1dc463" height={16} width={16} />
        <span>{copy}</span>
      </>
    );
  };

  renderError = (error) => (
    <>
      <Warning fill="#de2612" height={16} width={16} />
      <span>{error}</span>
    </>
  );

  render() {
    const { children, classes } = this.props;
    const { error, sent } = this.state;

    return (
      <>
        {children}
        <div className={classes.testButtonContainer}>
          <Mutation
            mutation={deliverTestDestination}
            onCompleted={this.testComplete}
          >
            {(deliverTestDestination, { loading }) => (
              <CKButton
                isDisabled={!this.validConfig() || loading}
                onClick={() => this.testDestination(deliverTestDestination)}
                type="button"
                variant="simple"
              >
                {this.renderButtonInner(loading)}
              </CKButton>
            )}
          </Mutation>

          {sent && this.renderSent()}
          {error && this.renderError(error)}
        </div>
      </>
    );
  }
}

DeliverTestButton.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.shape({
    buttonText: PropTypes.string.isRequired,
    loader: PropTypes.string.isRequired,
    testButtonContainer: PropTypes.string.isRequired,
  }).isRequired,
  config: PropTypes.shape({
    columns: PropTypes.array,
    url: PropTypes.string,
    message: PropTypes.string,
    subject: PropTypes.string,
    recipients: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  destinationType: PropTypes.string.isRequired,
  segmentId: PropTypes.string.isRequired,
  segmentType: PropTypes.string.isRequired,
};

export default withStyles(styles)(DeliverTestButton);
