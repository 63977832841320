import queryString from 'query-string';

import { getIntegrationNameFromService } from '~/pages/integrations/list/IntegrationSection/helpers';

import { AccountConnectorService } from './configuration';

const segmentRootPaths = {
  CompanySegment: '/companies',
  PersonSegment: '/people',
  Company: '/companies',
  Person: '/people',
} as const;

export type SegmentRootPathKey = keyof typeof segmentRootPaths;

export default {
  audiences() {
    return '/audiences';
  },

  audienceNew(subtype: string, query = {}) {
    const base = `${this.audiences()}/new`;

    if (!subtype) {
      return base;
    }

    const subTypeBase = `${base}/${subtype.replace(/_/g, '-')}`;

    if (Object.keys(query).length) {
      return `${subTypeBase}?${queryString.stringify(query)}`;
    }

    return subTypeBase;
  },

  audienceNewSyncStep(subtype: string, query = {}) {
    const base = this.audienceNew(subtype);

    if (Object.keys(query).length) {
      return `${base}/sync?${queryString.stringify(query)}`;
    }

    return `${base}/sync`;
  },

  audience(id: string) {
    if (!id) {
      return;
    }

    return `${this.audiences()}/${id}`;
  },

  audienceEdit(id: string) {
    if (!id) {
      return;
    }

    return `${this.audience(id)}/edit`;
  },

  audienceClone(id: string) {
    if (!id) {
      return;
    }

    return `${this.audience(id)}/clone`;
  },

  audienceCloneSyncStep(id: string) {
    const base = this.audienceClone(id);

    return `${base}/sync`;
  },

  configurations() {
    return '/settings/configuration';
  },

  integrations() {
    return '/integrations';
  },

  appendMinimalConfigurationToConfigurationUrl(url: string) {
    if (!window?.location?.search) return url;

    // this query param is used when opening configuration page as a popup from the free tools, like in the onboarding process
    const requiresMinimalConfiguration = window.location.search.includes(
      'minimal_configuration=true',
    );

    const query = requiresMinimalConfiguration
      ? '?navigation=hide&minimal_configuration=true'
      : '';

    return `${url}${query}`;
  },

  configuration(
    typeOrTypename?: string,
    skipMinimalConfigurationCheck?: boolean,
  ) {
    const service = getIntegrationNameFromService(
      typeOrTypename as AccountConnectorService,
    );

    if (skipMinimalConfigurationCheck) {
      if (service) {
        return `${this.configurations()}/${service}`;
      }

      return `${this.configurations()}`;
    }

    if (service) {
      return this.appendMinimalConfigurationToConfigurationUrl(
        `${this.configurations()}/${service}`,
      );
    }

    return this.appendMinimalConfigurationToConfigurationUrl(
      `${this.configurations()}`,
    );
  },

  configurationConnect(typeOrTypename: string, configuration?: string) {
    if (configuration === 'google_ads') {
      typeOrTypename = 'google_ads';
    }

    return this.appendMinimalConfigurationToConfigurationUrl(
      `${this.configuration(typeOrTypename, true)}/connect`,
    );
  },

  companies() {
    return '/companies';
  },

  company(id: string, segmentId?: string) {
    return segmentId
      ? `/companies/segments/${segmentId}/${id}`
      : `/companies/${id}`;
  },

  dataEnrichmentIndex() {
    return '/enrichment';
  },

  dataEnrichmentAccess() {
    return '/enrichment/access';
  },

  dataEnrichmentAccessRequest() {
    return '/enrichment/access/request';
  },

  dataEnrichmentConnect(integration: string) {
    return `/enrichment/${integration}/connect`;
  },

  dataEnrichment(integration: string) {
    return `/enrichment/${integration}`;
  },

  dataEnrichmentLogs(integration: string) {
    return `/enrichment/${integration}/logs`;
  },

  dataEnrichmentLog(integration: string, id: string) {
    return `${this.dataEnrichmentLogs(integration)}/${id}`;
  },

  dataEnrichmentObject(integration: string, objectType: string) {
    return `/enrichment/${integration}/${objectType}`;
  },

  formsIntegration(integration: string) {
    return `/forms/${integration}`;
  },

  importCsv(type: SegmentRootPathKey) {
    return `/import${segmentRootPaths[type]}`;
  },

  mapCsv(type: SegmentRootPathKey, id: string) {
    return `${segmentRootPaths[type]}/import/${id}`;
  },

  people() {
    return `/people`;
  },

  person(id: string, segmentId?: string) {
    return segmentId ? `/people/segments/${segmentId}/${id}` : `/people/${id}`;
  },

  segments(typeOrTypename: SegmentRootPathKey) {
    return segmentRootPaths[typeOrTypename];
  },

  segmentNew(typeOrTypename: SegmentRootPathKey) {
    const rootPath = segmentRootPaths[typeOrTypename];
    return `${rootPath}/segments/new`;
  },

  segment(typeOrTypename: SegmentRootPathKey, id?: string | null) {
    const rootPath = segmentRootPaths[typeOrTypename];
    if (!id) return `${rootPath}`;
    return `${rootPath}/segments/${id}`;
  },

  segmentEdit(typeOrTypename: SegmentRootPathKey, id: string) {
    return `${this.segment(typeOrTypename, id)}/edit`;
  },

  segmentDestinations(
    typeOrTypename: SegmentRootPathKey,
    segmentId?: string | null,
  ) {
    return `${this.segment(typeOrTypename, segmentId)}/destinations`;
  },

  selfServePlanUpgrade() {
    return '/settings/team/plans/upgrade';
  },

  segmentDestinationNew(
    typeOrTypename: SegmentRootPathKey,
    segmentId: string,
    { type }: { type: string },
  ) {
    return `${this.segmentDestinations(
      typeOrTypename,
      segmentId,
    )}/new?type=${type}`;
  },

  segmentDestinationEdit(
    typeOrTypename: SegmentRootPathKey,
    segmentId?: string | null,
    id?: string | null,
  ) {
    return `${this.segmentDestinations(typeOrTypename, segmentId)}/${id}/edit`;
  },

  segmentDestinationContact(
    typeOrTypename: SegmentRootPathKey,
    segmentId: string,
    id: string,
  ) {
    return `${this.segmentDestinations(
      typeOrTypename,
      segmentId,
    )}/${id}/contact-success`;
  },

  visitorReportsNew(domain?: string) {
    return '/visitors' + (domain ? `/${domain}` : '');
  },
};
