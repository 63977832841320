import { useEntitlements } from '~/hooks/account';

import GatedRoute, { GatedRouteProps } from './GatedRoute';

type GatedRouteEntitlementProps = Omit<GatedRouteProps, 'loading'>;

export const GatedRouteEntitlement = (props: GatedRouteEntitlementProps) => {
  const { loading } = useEntitlements();

  return <GatedRoute {...props} loading={loading} />;
};
