import Papa from 'papaparse';

const ROWS_TO_PARSE = 10;

export const DOMAIN_REGEX = RegExp(
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
);

export const EMAIL_REGEX = RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

const CONTAINS_COMPANY_DATA = 'containsCompanyData';
const CONTAINS_PERSON_DATA = 'containsPersonData';
const MISSING_EMAIL = 'missingEmail';
const MISSING_DOMAIN = 'missingDomain';

const COMPANY_TYPE = 'Company';
const PERSON_TYPE = 'Person';

export const validateCSV = async (file, type) => {
  return new Promise((resolve, _reject) => {
    Papa.parse(file, {
      preview: ROWS_TO_PARSE,
      complete: (result) => {
        const rows = result.data;
        resolve(getErrors(rows, type));
      },
    });
  });
};

export const getErrors = (rows, type) => {
  const containsPersonData = rowsContainEmail(rows);
  const containsCompanyData = rowsContainDomain(rows);

  if (type === COMPANY_TYPE) {
    if (containsPersonData && !containsCompanyData) {
      return CONTAINS_PERSON_DATA;
    }

    if (!containsPersonData && !containsCompanyData) {
      return MISSING_DOMAIN;
    }
  }

  if (type === PERSON_TYPE) {
    if (containsCompanyData && !containsPersonData) {
      return CONTAINS_COMPANY_DATA;
    }

    if (!containsPersonData && !containsCompanyData) {
      return MISSING_EMAIL;
    }
  }

  return null;
};

export const rowsContainDomain = (rows) => {
  const validateCell = (value) => {
    return DOMAIN_REGEX.test(value) && !EMAIL_REGEX.test(value);
  };

  return rows.some((row) => row.some(validateCell));
};

export const rowsContainEmail = (rows) => {
  const validateCell = (value) => {
    return EMAIL_REGEX.test(value);
  };

  return rows.some((row) => row.some(validateCell));
};
