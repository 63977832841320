import { AddBlock, SpinnerIndicators } from '@clearkit/icons';
import { CKButton, CKLayoutHorizontal, CKLayoutVertical } from 'clearkit';

import { ButtonContainer } from '~/components/unified/ButtonContainer';

import { useProspector } from '../useProspector';
import { isLoading, isWaitingForNewList } from '../util';
import { ListManagementMenu } from './ListManagementMenu';
import ProspectorLists from './ProspectorLists';
import { ProspectorSidebar } from './ProspectorSidebar';
import { SidebarBreadcrumbs } from './SidebarBreadcrumbs';
import { SidebarCTAs } from './SidebarCTAs';
import { SuppressionBanner } from './SuppressionBanner';

const LoadingArea = () => (
  <CKLayoutVertical
    alignItems="center"
    className="w-full h-full p-20"
    justifyContent="center"
  >
    <SpinnerIndicators height={40} width={40} />
  </CKLayoutVertical>
);

export const SidebarLists = () => {
  const [state, send] = useProspector();
  const { id, prospectList, prospectLists } = state.context;

  const hasLists = prospectLists?.length > 0;
  const loading = isLoading(state);
  const onToggleFilterSidebar = () => send('TOGGLE_FILTER_SIDEBAR');

  const Sidebar = () => {
    if (loading) return <LoadingArea />;

    return hasLists ? (
      <ProspectorLists list={prospectLists || []} />
    ) : (
      <SidebarCTAs />
    );
  };

  return (
    <ProspectorSidebar>
      <ProspectorSidebar.Heading>Prospector</ProspectorSidebar.Heading>
      {id ? (
        <SidebarBreadcrumbs loading={loading} title={prospectList?.name} />
      ) : null}

      <Sidebar />
      <ProspectorSidebar.Footer className="!p-0 space-y-6 divide-y divide-gray-200">
        <SuppressionBanner />
        <CKLayoutHorizontal className="px-6 py-3">
          <CKButton
            isDisabled={isWaitingForNewList(state)}
            leftIcon={<AddBlock />}
            onClick={() => send('CREATE_PROSPECT_LIST')}
            variant="bold"
            variantColor="blue"
          >
            New List
          </CKButton>
          {id ? (
            <ButtonContainer>
              <CKButton
                onClick={onToggleFilterSidebar}
                variant="simple"
                variantColor="blue"
              >
                Edit
              </CKButton>
              <ListManagementMenu />
            </ButtonContainer>
          ) : null}
        </CKLayoutHorizontal>
      </ProspectorSidebar.Footer>
    </ProspectorSidebar>
  );
};
