export const emptyGroup = {
  and: [
    {
      match: {
        attribute: undefined,
        value: undefined,
      },
    },
  ],
};

export const emptySalesforceGroup = {
  salesforce_account: emptyGroup,
};

export const dummySearch = {
  and: [
    {
      and: [
        {
          gte: {
            attribute: 'company->metrics->estimated_annual_revenue',
            value: 'remove me',
          },
        },
        {
          gte: {
            attribute: 'company->metrics->estimated_annual_revenue',
            value: '200000',
          },
        },
        {
          gte: {
            attribute: 'company->metrics->estimated_annual_revenue',
            value: '200000',
          },
        },
      ],
    },
    {
      or: [
        {
          or: [
            {
              gte: {
                attribute: 'company->metrics->estimated_annual_revenue',
                value: '200000',
              },
            },
            {
              gte: {
                attribute: 'company->metrics->estimated_annual_revenue',
                value: '200000',
              },
            },
          ],
        },
      ],
    },
  ],
};

export const searches = [emptyGroup, dummySearch];
