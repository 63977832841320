import classnames from 'classnames';
import filter from 'lodash/filter';

import { AccountNotificationsFragment } from '~/generated/graphql';

import { AccountNotification } from './AccountNotification';

type AccountNotificationsProps = {
  className?: string;
  notifications: AccountNotificationsFragment[];
  isFromEnrichmentPage?: boolean;
  isFullWidth?: boolean;
};

export const AccountNotifications: React.FC<AccountNotificationsProps> = ({
  className,
  notifications,
  isFromEnrichmentPage = false,
  isFullWidth,
}) => {
  const unresolvedNotifications = filter(
    notifications,
    (notification) => !notification?.resolved,
  );

  if (unresolvedNotifications.length === 0) {
    return null;
  }

  return (
    <div className={classnames('space-y-5', className)}>
      {unresolvedNotifications.map((notification) =>
        notification ? (
          <AccountNotification
            accountNotification={notification}
            isFromEnrichmentPage={isFromEnrichmentPage}
            isFullWidth={isFullWidth}
            key={notification.id}
          />
        ) : null,
      )}
    </div>
  );
};
