import { CKButton, CKModal } from 'clearkit';

import {
  Audience,
  Collection,
  Destination,
  Segment,
} from '~/generated/graphql';

type Type = 'audience' | 'collection' | 'destination' | 'segment' | null;

type AssociatedItemsWarningProps = {
  type: Type;
  childType: string | undefined;
  associatedItemNames: string[];
};

const AssociatedItemsWarning: React.FC<AssociatedItemsWarningProps> = ({
  childType,
  type,
  associatedItemNames,
}) => (
  <>
    <p className="mb-6">
      This will also delete any {childType} associated with this {type}.
    </p>

    <p className="mb-2 font-semibold">
      The following {childType} will also be deleted:
    </p>

    {associatedItemNames.map((item, index) => (
      <div key={index}>{item}</div>
    ))}
  </>
);

type Item = Segment | Collection | Audience | Destination;

type ConfirmDeleteModalProps = {
  open: boolean;
  closeModal: () => void;
  deleteItem: (type: Type, item: Item) => void;
  item: Item;
  subTitle: string;
  associatedItemNames: string[];
  canDelete: boolean;
  type: Type;
};

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
  associatedItemNames,
  canDelete,
  closeModal,
  deleteItem,
  open,
  item,
  subTitle,
  type,
}) => {
  const childTypeMap = {
    segment: 'destinations',
    collection: 'audiences and destinations',
  };
  const displayType = type === 'segment' ? 'audience' : type;

  const handleDelete = () => {
    deleteItem(type, item);
    closeModal();
  };

  const handleCancel = () => {
    closeModal();
  };

  const showAssociatedItemsWarning =
    associatedItemNames && associatedItemNames.length > 0 && canDelete;

  return (
    <CKModal isVisible={open} size="md">
      <CKModal.Header>
        <CKModal.Heading>
          {canDelete
            ? `Are you sure you want to delete this ${displayType}?`
            : `You cannot delete your last ${displayType}`}
        </CKModal.Heading>
      </CKModal.Header>

      {subTitle || showAssociatedItemsWarning ? (
        <CKModal.Body className="px-8 py-6">
          <div>{subTitle}</div>

          {showAssociatedItemsWarning ? (
            <AssociatedItemsWarning
              associatedItemNames={associatedItemNames}
              childType={childTypeMap[type as keyof typeof childTypeMap]}
              type={type}
            />
          ) : null}
        </CKModal.Body>
      ) : null}
      <CKModal.Footer className="flex gap-4">
        <CKButton onClick={handleCancel} variant="simple">
          Cancel
        </CKButton>

        {canDelete && (
          <CKButton onClick={handleDelete} variant="bold" variantColor="red">
            Delete {displayType}
          </CKButton>
        )}
      </CKModal.Footer>
    </CKModal>
  );
};

export default ConfirmDeleteModal;
