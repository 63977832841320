import { GoExternal, Hubspot as HubSpot } from '@clearkit/icons';
import map from 'lodash/map';

import { paginateField } from '~/lib/graphql/pagination';

import { keyColors } from './colors';
const color = keyColors.hubspot;
import Group from './Group';

const HubspotGroups = ({ search, hubspotCompanies }) => (
  <>
    {hubspotCompanies?.map((hubspotCompany, i) => {
      const { externalAccountId, id, name, properties } = hubspotCompany;
      const deals = paginateField(hubspotCompany, 'deals');
      return (
        <>
          <Group
            actionLink={
              <a
                className="inline-flex align-center"
                href={`https://app.hubspot.com/contacts/${externalAccountId}/company/${id}`}
                onClick={(e) => e.stopPropagation()}
                rel="noopener noreferrer"
                style={{ color }}
                target="_blank"
              >
                View in Hubspot
                <GoExternal className="ml-2" />
              </a>
            }
            icon={HubSpot}
            key={i}
            search={search}
            sections={[
              [{ label: 'Account Name', value: name }],
              [{ label: 'Deals', value: deals }],
              map(properties, (value, label) => {
                if (label === 'deals') return false;
                if (label === 'properties') return false;

                return { label, value };
              }),
            ]}
            title={name}
            type={'hubspot'}
          />
          {deals?.map((deal) => (
            <Group
              actionLink={
                <a
                  className="inline-flex align-center"
                  href={`https://app.hubspot.com/contacts/${externalAccountId}/deal/${deal.id}`}
                  onClick={(e) => e.stopPropagation()}
                  rel="noopener noreferrer"
                  style={{ color }}
                  target="_blank"
                >
                  View in Hubspot
                  <GoExternal className="ml-2" />
                </a>
              }
              icon={HubSpot}
              key={deal.id}
              search={search}
              sections={[
                [
                  { label: 'Name', value: deal.name },
                  { label: 'Amount', value: deal.amount },
                  {
                    label: 'Owner',
                    value: deal.owner?.name,
                  },
                ],
                map(deal.properties, (value, label) => ({ label, value })),
              ]}
              title={`Hubspot Deal – ${deal.name}`}
              type="hubspot"
            />
          ))}
        </>
      );
    })}
  </>
);

HubspotGroups.propTypes = {
  hubspotCompanies: PropTypes.arrayOf(
    PropTypes.shape({
      externalAccountId: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
      properties: PropTypes.object,
      deals: PropTypes.array,
    }),
  ),
  search: PropTypes.string,
};

export default HubspotGroups;
