import Input from '@material-ui/core/Input';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';

import { SearchContext } from '~/components/SearchBuilder/utils/searchUtils';
import Select from '~/components/Select';

import { DEFAULT_EVENT_PART } from '../../utils/constants';

const ALL_PATHS_ATTRIBUTE = 'all';

export class BareEventCondition extends React.Component {
  onPathChange = (event) => {
    const { node } = this.props;
    const { match } = node.event;

    let path = event.target.value;

    if (!path.startsWith('/') && match === 'start_with') {
      path = `/${path}`;
    }

    node.event.path = path;
    this.props.onChange(node);
  };

  onPathBlur = (event) => {
    const { node } = this.props;

    let path = event.target.value;
    path = this.removeTrailingSlash(path);
    node.event.path = path;
    this.props.onChange(node);
  };

  onHostChange = (option) => {
    const { node } = this.props;

    node.event.host = option.value;
    this.props.onChange(node);
  };

  onCountChange = (countVal) => {
    const { node } = this.props;

    let count = parseInt(countVal, 10) || '';
    if (count) count = Math.min(count, 10000);

    if (count < -100) return;

    node.event.count = count;
    this.props.onChange(node);
  };

  getTimeValue = () => {
    const { node } = this.props;
    if (node.event.change) {
      return node.event.change;
    }

    return node.event.timeframe;
  };

  getOptionLabel = (timeframe, count) => {
    return `${
      parseInt(count, 10) === 1 ? 'visit' : 'visits'
    } in the last ${timeframe}`;
  };

  removeTrailingSlash = (value) => {
    if (value === '/') return '/';
    return value.replace(/\/+$/, '');
  };

  onTimeframeChange = (option) => {
    const { node } = this.props;
    if (typeof option.value === 'string') {
      node.event.change = option.value;
      node.event.timeframe = null;
    } else {
      node.event.timeframe = option.value;
      node.event.change = null;
    }
    this.props.onChange(node);
  };

  render = () => {
    const { classes, node, hosts } = this.props;
    const { disabled } = this.context;
    const { match, path, count, host } = node.event;
    const showingHost = hosts && hosts.length > 1;
    const hidePath = match === ALL_PATHS_ATTRIBUTE;

    let selectableHosts = [
      { label: 'All domains', value: null },
      ...uniq(compact([...hosts, host])).map((option) => ({
        label: option,
        value: option,
      })),
    ];
    const selectedHost = selectableHosts.find(
      (option) => option.value === host,
    );
    const selectableTimeframes = [
      {
        label: this.getOptionLabel('day', count),
        value: 86400,
      },
      {
        label: this.getOptionLabel('week', count),
        value: 86400 * 7,
      },
      {
        label: this.getOptionLabel('month', count),
        value: 86400 * 30,
      },
      {
        label: '% increase in the last week',
        value: 'increase',
      },
      {
        label: '% decrease in the last week',
        value: 'decrease',
      },
    ];
    const selectedTimeframe = selectableTimeframes.find(
      (option) => option.value === this.getTimeValue(),
    );

    return (
      <>
        <Select
          className={classnames(classes.host, {
            [classes.hostWithPath]: !hidePath,
          })}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          isDisabled={!showingHost || disabled}
          isSearchable={false}
          maxMenuHeight={380}
          onChange={(e) => this.onHostChange(e)}
          options={selectableHosts}
          placeholder="Any domain"
          value={selectedHost}
        />
        {!hidePath && (
          <Input
            className={classnames(classes.pathInput)}
            disabled={disabled}
            onBlur={this.onPathBlur}
            onChange={this.onPathChange}
            placeholder="/pricing"
            value={path}
          />
        )}
        <Input
          className={classnames(classes.countInput, classes.countContainer)}
          disabled={disabled}
          key="count"
          onChange={(e) => this.onCountChange(e.target.value)}
          type="number"
          value={count}
          variant="outlined"
        />
        <Select
          className={classnames(
            classes.timeframeInput,
            classes.timeframeContainer,
          )}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          isDisabled={disabled}
          isSearchable={false}
          key="timeframe"
          maxMenuHeight={380}
          onChange={(e) => this.onTimeframeChange(e)}
          options={selectableTimeframes}
          placeholder="Select a value"
          value={selectedTimeframe}
        />
      </>
    );
  };
}

BareEventCondition.contextType = SearchContext;

BareEventCondition.defaultProps = {
  classes: {},
  node: DEFAULT_EVENT_PART,
};

BareEventCondition.propTypes = {
  node: PropTypes.shape({
    event: PropTypes.object.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.object,
  host: PropTypes.string,
  hosts: PropTypes.arrayOf(PropTypes.string),
};

export default BareEventCondition;
