function fetchWithProgress(url, opts = {}, onProgress) {
  return new Promise((res, rej) => {
    var xhr = new XMLHttpRequest();
    xhr.open(opts.method || 'get', url);
    for (var k in opts.headers || {}) xhr.setRequestHeader(k, opts.headers[k]);
    xhr.onload = (e) => res(e.target);
    xhr.onerror = rej;
    if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress;
    xhr.send(opts.body);
  });
}

export function uploadFileToS3(presignedUrl, file, progressCallback) {
  return fetchWithProgress(
    presignedUrl,
    {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
      },
    },
    ({ loaded, total }) => {
      if (progressCallback && loaded && total) {
        progressCallback((loaded / total) * 100);
      }
    },
  ).then((response) => {
    if (response.status !== 200) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }

    return response;
  });
}
