import { useState } from 'react';
import uuid from 'uuid/v4';

const Email = (props) => {
  const id = useId();
  const gradientId = `email-icon-gradient-${id}`;

  return (
    <svg
      height={26}
      version="1.1"
      viewBox="0 0 26 26"
      width={26}
      xlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <linearGradient id={gradientId} x1="0%" x2="100%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#4DB1FD"></stop>
          <stop offset="100%" stopColor="#148CFC"></stop>
        </linearGradient>
      </defs>
      <circle cx="13" cy="13" fill={`url(#${gradientId})`} r="13"></circle>
      <path
        d="M5.37846222,10.6252689 L12.4854195,14.8908631 C12.8021426,15.0809603 13.1978658,15.0809742 13.5146023,14.8908994 L20.6210304,10.6263052 C20.7394134,10.5550759 20.89308,10.5934801 20.9641716,10.7119457 C20.9875015,10.7508221 20.9998272,10.795308 20.9998311,10.8406474 C21,11.208054 21,11.4980004 21,11.7104864 L21,17 C21,18.1045695 20.1045695,19 19,19 L7,19 C5.8954305,19 5,18.1045695 5,17 C4.99980679,14.6138153 4.99980679,12.8241768 4.99980679,11.6310844 L4.99980679,10.8396232 C4.99980679,10.7015521 5.1117356,10.5896232 5.24980679,10.5896232 C5.29513024,10.5896232 5.3396011,10.6019445 5.37846222,10.6252689 Z M19,7 C19.7433091,7 20.3919099,7.4054942 20.7366621,8.00734229 C20.7537702,8.03720851 20.7703185,8.07026435 20.7863069,8.10650981 C20.9897103,8.56767459 20.8181264,9.10814442 20.3859554,9.36751697 L13.5145911,13.4914478 C13.1978616,13.6815368 12.802137,13.6815391 12.4854053,13.4914537 L5.6155552,9.36853908 C5.20576031,9.12265759 5.02725261,8.62117863 5.18946889,8.17165061 C5.21188717,8.10952587 5.23463779,8.05804676 5.25772074,8.01721328 C5.60101366,7.40993212 6.25263444,7 7,7 L19,7 Z"
        fill="#FFFFFF"
      ></path>
    </svg>
  );
};

const useId = () => {
  const [id] = useState(uuid());
  return id;
};

export default Email;
