import Radio from '@material-ui/core/Radio';
import ToggleButton from '@material-ui/lab/ToggleButton';

import ToggleRadioButtonGroup from '~/components/ToggleRadioButtonGroup';
import RadioChecked from '~/pages/destinations/components/TriggerType/RadioChecked.svg';
import RadioUnchecked from '~/pages/destinations/components/TriggerType/RadioUnchecked.svg';

import styles from './styles';

const TypeToggle = ({
  classes,
  value,
  className,
  onChange,
  typeOneText,
  typeTwoText,
  TypeOneLogo,
  TypeTwoLogo,
}) => {
  return (
    <ToggleRadioButtonGroup
      classes={{ root: classes.groupRoot }}
      className={className}
      exclusive
      onChange={onChange}
      selected
      value={value}
    >
      <ToggleButton
        classes={{
          root: classnames(classes.buttonRoot, {
            [classes.buttonRootSelected]: value === 0,
          }),
        }}
        value={0}
      >
        <div className={classes.toggleButtonContainer}>
          <div
            className={classnames(classes.radioConatiner, {
              [classes.rootContainerSelected]: value === 0,
            })}
          >
            <Radio
              checked={value === 0}
              checkedIcon={<RadioChecked />}
              classes={{ root: classes.radioRoot }}
              icon={<RadioUnchecked />}
            />
            {typeOneText}
          </div>
          {TypeOneLogo && (
            <TypeOneLogo
              className={classnames({ [classes.grayLogo]: value !== 0 })}
            />
          )}
        </div>
      </ToggleButton>
      <ToggleButton
        classes={{
          root: classnames(classes.buttonRoot, {
            [classes.buttonRootSelected]: value === 1,
          }),
        }}
        value={1}
      >
        <div className={classes.toggleButtonContainer}>
          <div
            className={classnames(classes.radioConatiner, {
              [classes.rootContainerSelected]: value === 1,
            })}
          >
            <Radio
              checked={value === 1}
              checkedIcon={<RadioChecked />}
              classes={{
                root: classes.radioRoot,
              }}
              icon={<RadioUnchecked />}
            />
            {typeTwoText}
          </div>
          {TypeTwoLogo && (
            <TypeTwoLogo
              className={classnames({ [classes.grayLogo]: value !== 1 })}
            />
          )}
        </div>
      </ToggleButton>
    </ToggleRadioButtonGroup>
  );
};

export default withStyles(styles)(TypeToggle);
