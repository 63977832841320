import { useState } from 'react';
import { useMutation } from 'react-query';

import useQueryParams from '~/hooks/useQueryParams';

import { LoginErrorCode } from './types';

function useStartMagicLinkFlowQuery() {
  return useMutation(
    async ({
      email,
      onboardingFlow,
    }: {
      email: string;
      onboardingFlow: string | undefined;
    }) => {
      const params: Record<string, string> = { email_address: email };

      if (onboardingFlow) {
        params.onboarding_flow = onboardingFlow;
      }

      const response = await fetch(`/auth/stytch/magic_link`, {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        return { success: true };
      }

      const body = await response.json();

      if (body.error) {
        return {
          success: false,
          error: body.error,
        };
      }

      throw new Error('An unknown error occurred.');
    },
  );
}

function identify(email: string) {
  if (!window.analytics) {
    return;
  }

  try {
    window.analytics.identify({ email });
  } catch (e) {
    // Ignore
  }
}

export default function useStartMagicLinkFlow(
  onboardingFlow?: string | undefined,
) {
  const [email, setEmail] = useState('');
  const {
    mutateAsync,
    isLoading: isCreatingMagicLink,
  } = useStartMagicLinkFlowQuery();
  const queryParams = useQueryParams();
  const initialError = queryParams.get('error') as LoginErrorCode | null;

  const [error, setError] = useState(initialError);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string[]>
  >({});
  const [sentEmail, setSentEmail] = useState<string | null>();

  const createMagicLink = async function() {
    if (!emailIsValid(email)) {
      return;
    }

    identify(email);

    const response = await mutateAsync({ email, onboardingFlow });

    if (response.success) {
      setSentEmail(email);
      setError(null);
      setValidationErrors({});

      setEmail('');
    } else {
      setSentEmail(null);
      switch (response.error.type) {
        case 'validation':
          setValidationErrors(response.error.errors);
          break;
        default:
          throw new Error('An unknown error occurred.');
      }
    }
  };

  function formHandler(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    createMagicLink();
  }

  return {
    createMagicLink,
    email,
    error,
    formHandler,
    isCreatingMagicLink,
    sentEmail,
    setEmail,
    validationErrors,
  };
}

export function emailIsValid(email: string | undefined): boolean {
  return !!email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export function buildErrorMessage(errorCode: LoginErrorCode | null): string {
  switch (errorCode) {
    case null || undefined:
      return '';
    case 'magic_link_invalid':
      return 'Your email link is invalid or has expired. Please try again.';
    case 'intermediate_session_not_found':
      return 'Your login session expired. Please try again.';
    case 'not_found':
      return 'Your session expired. Please try again.';
    case 'unauthorized':
      return 'There was a problem signing you in. Please try again.';
    default:
      return 'There was a problem signing you in. Please try again.';
  }
}
