import { useMutation } from '@apollo/client';
import { CKButton, CKDrawer } from 'clearkit';
import isNil from 'lodash/isNil';

import ConfigureROIMetricsUI from '~/components/audiences/ConfigureROIMetrics';
import LoadingArea from '~/components/LoadingArea';
import { isValidSearch } from '~/components/SearchBuilder/utils/validateSearch';
import { useFancyQuery } from '~/lib/graphql';
import history from '~/lib/history';

import updateMutation from './mutation.graphql';
import query from './query.graphql';

const ConfigureROIMetrics = () => {
  const [mqlConfiguration, setMqlConfiguration] = React.useState(null);
  const [leadsConfiguration, setLeadsConfiguration] = React.useState(null);

  const { data } = useFancyQuery(query, {
    returnPartialData: true,
    fetchPolicy: 'network-only',
  });

  const [updateConfiguration, { loading: isSubmitting }] = useMutation(
    updateMutation,
    {
      refetchQueries: ['AudiencesPage'],
      onCompleted: () => {
        history.push('/audiences');
      },
    },
  );

  const handleUpdate = () => {
    updateConfiguration({
      variables: {
        input: {
          mqlStageEnabled: mqlConfiguration.enabled,
          mqlStageSearch: mqlConfiguration.search,
          leadStageEnabled: leadsConfiguration.enabled,
          leadStageSearch: leadsConfiguration.search,
        },
      },
    });
  };

  useEffect(() => {
    if (
      data?.salesPipeline &&
      isNil(mqlConfiguration) &&
      isNil(leadsConfiguration)
    ) {
      setMqlConfiguration(data.salesPipeline.stages.mqls);
      setLeadsConfiguration(data.salesPipeline.stages.leads);
    }
  }, [data]);

  const handleCancel = () => {
    history.push('/audiences');
  };

  const showSegmentBuilder = leadsConfiguration && mqlConfiguration;

  const handleMqlChange = (change) => {
    setMqlConfiguration(change);
  };

  const handleLeadsChange = (change) => {
    setLeadsConfiguration(change);
  };

  const isValidMqlConfiguration = mqlConfiguration?.enabled
    ? isValidSearch(mqlConfiguration?.search)
    : true;
  const isValidLeadsConfiguration = leadsConfiguration?.enabled
    ? isValidSearch(leadsConfiguration?.search)
    : true;

  const isValidConfiguration =
    isValidMqlConfiguration && isValidLeadsConfiguration;

  return (
    <div className="flex flex-col flex-1 overflow-hidden">
      <CKDrawer.Header>
        <CKDrawer.Heading>Configure ROI metrics</CKDrawer.Heading>
        <p className="text-sm">
          Define your Leads &amp; MQL metrics with a custom query.
        </p>
      </CKDrawer.Header>
      <CKDrawer.Body className="bg-gray-0">
        {showSegmentBuilder ? (
          <ConfigureROIMetricsUI
            leadsConfiguration={leadsConfiguration}
            mqlConfiguration={mqlConfiguration}
            onLeadsChange={handleLeadsChange}
            onMqlChange={handleMqlChange}
            searchAttributes={data?.searchAttributes}
          />
        ) : (
          <LoadingArea className="h-64" loading />
        )}
      </CKDrawer.Body>
      <CKDrawer.Footer>
        <div className="flex items-center space-x-3">
          <CKButton isDisabled={isSubmitting} onClick={handleCancel}>
            Cancel
          </CKButton>
          <CKButton
            isDisabled={!isValidConfiguration}
            isLoading={isSubmitting}
            onClick={handleUpdate}
            variant="bold"
            variantColor="green"
          >
            Save changes
          </CKButton>
        </div>
      </CKDrawer.Footer>
    </div>
  );
};

export default ConfigureROIMetrics;
