import { Caret } from '@clearkit/icons';
import startCase from 'lodash/startCase';

import Tooltip from '~/components/Tooltip';

import AttributeValue from './AttributeValue';
import styles from './styles';

const Attribute = ({ attribute, showAttribute, classes, search }) => {
  const [open, setOpen] = useState(false);

  const isOpen = open || !!search;

  if (!showAttribute(attribute)) return null;

  const { label, value, tooltip, children } = attribute;
  return (
    <>
      <div
        className={classnames(
          classes.label,
          'flex flex-row justify-start py-1',
        )}
        key={label}
      >
        <div
          className={classnames(
            'flex flex-row flex-shrink-0 mr-4 items-center text-xs',
          )}
          style={{ width: '30%' }}
        >
          {children && (
            <div className="flex-shrink-0 mr-1 text-gray-500">
              <Caret
                className={classnames(
                  'cursor-pointer transform',
                  isOpen ? 'rotate-0' : '-rotate-90',
                )}
                onClick={() => setOpen(!isOpen)}
              />
            </div>
          )}
          {startCase(label)}

          {tooltip && (
            <Tooltip hovered title={tooltip}>
              ?
            </Tooltip>
          )}
        </div>
        <div className={classes.value}>
          <AttributeValue label={label} value={value} />
        </div>
      </div>

      {isOpen &&
        children?.map((child) => (
          <Attribute
            attribute={child}
            classes={classes}
            search={search}
            showAttribute={showAttribute}
          />
        ))}
    </>
  );
};

Attribute.propTypes = {
  attribute: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  showAttribute: PropTypes.func,
  classes: PropTypes.object,
};

export default withStyles(styles)(Attribute);
