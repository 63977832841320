import { useContext, useState } from 'react';

import { useCreateHubspotCompanyMutation } from '~/generated/graphql';

import { CRMPresenceContext } from './useCRMPresence';

type Company = {
  domain: string;
  name?: string;
};

export const useAddHubspot = () => {
  const [hasValidationError, setHasValidationError] = useState(false);
  const { setDomainPresence } = useContext(CRMPresenceContext);
  const [
    createHubspotCompany,
    { loading: isLoading, error },
  ] = useCreateHubspotCompanyMutation();

  const onAddHubSpot = async (companies: Company[]) => {
    const input = companies[0];

    const result = await createHubspotCompany({
      variables: {
        input,
      },
    });

    if (result?.errors?.length) {
      setHasValidationError(true);
    } else {
      setDomainPresence?.({ domain: input.domain, isPresent: true });
      setHasValidationError(false);
    }
  };

  return {
    error,
    hasValidationError,
    isLoading,
    onAddHubSpot,
  };
};
