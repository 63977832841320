import { useEffect, useState } from 'react';

import { useStatusToast } from '~/components/unified/hooks';
import {
  useTargetMarketCompaniesQuery,
  useTargetMarketsQuery,
} from '~/generated/graphql';
import useQueryParams from '~/hooks/useQueryParams';
import history from '~/lib/history';

import {
  createTargetMarket,
  destroyTargetMarket,
  TargetMarket,
  updateTargetMarket,
} from './logic';

export type Search = {
  country?: string[];
  description?: string[];
  employees?: string[];
  industry?: string[];
  raised?: string[];
  tag?: string[];
  tech?: string[];
  type?: string[];
};

export type SearchQuery = Search | null;

export type UseTargetMarket = {
  handleDeleteTargetMarket?: () => void;
  handleDiscardChanges?: () => void;
  handleUpdateTargetMarket: (name?: string) => void;
  isEditing: boolean;
  isLoading: boolean;
  isNewList: boolean;
  isSaveModalOpen: boolean;
  modifiedNotSaved?: boolean;
  search?: SearchQuery;
  selectedTargetMarket?: TargetMarket | undefined;
  setIsEditing: (isEditing: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  setIsSaveModalOpen: (show: boolean) => void;
  setSearch: (search: SearchQuery) => void;
  targetMarketCompanies?: any;
  targetMarketCompaniesLoading?: boolean;
  targetMarkets?: TargetMarket[];
};

export const useTargetMarket = ({ id }: { id?: string | undefined }) => {
  const { addSuccessToast, addErrorToast } = useStatusToast();
  const {
    data,
    loading: targetMarketsLoading,
    refetch,
  } = useTargetMarketsQuery();
  const queryParams = useQueryParams();

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(targetMarketsLoading);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState<boolean>(false);
  const [selectedTargetMarket, setSelectedTargetMarket] = useState<
    TargetMarket | undefined
  >(undefined);
  const [search, setSearch] = useState<SearchQuery>(null);
  const [modifiedNotSaved, setModifiedNotSaved] = useState(false);

  const isNewList = queryParams.get('new') === 'true';

  const {
    data: targetMarketCompanies,
    loading: targetMarketCompaniesLoading,
  } = useTargetMarketCompaniesQuery({
    variables: {
      country: search?.country || [],
      description: search?.description || [],
      employees: search?.employees || [],
      industry: search?.industry || [],
      raised: search?.raised || [],
      tag: search?.tag || [],
      tech: search?.tech || [],
      type: search?.type || [],
    },
  });

  const targetMarkets: TargetMarket[] =
    data?.targetMarkets?.targetMarkets || [];

  useEffect(() => {
    if (id && targetMarkets && !targetMarketsLoading) {
      const newSelectedTargetMarket = targetMarkets.find(
        (market) => market.id === id,
      ) as TargetMarket;

      setSelectedTargetMarket(newSelectedTargetMarket);
      setSearch(newSelectedTargetMarket?.search);
      setIsLoading(false);
    } else if (isNewList) {
      setIsEditing(true);
      setSearch(null);
    }
  }, [id]);

  const handleDiscardChanges = () => {
    if (selectedTargetMarket) {
      setSearch(selectedTargetMarket.search);
    } else {
      setSearch(null);
    }

    history.push({
      search: '',
    });

    setIsEditing(false);
  };

  const handleUpdateTargetMarket = async (name?: string) => {
    if (!search) return;

    setIsLoading(true);

    if (selectedTargetMarket) {
      const nameToUse = name || selectedTargetMarket.name;
      const updatedTargetMarket = {
        ...selectedTargetMarket,
        name: nameToUse,
        search,
      };

      const succeeded = await updateTargetMarket(updatedTargetMarket);

      if (succeeded === false) {
        addErrorToast({
          heading: 'Target Market not updated',
          description: `Sorry, there was an error updating ${nameToUse}. Please try again.`,
        });

        closeModal();

        return;
      }

      addSuccessToast({
        heading: 'Target Market updated',
        description: `You updated ${nameToUse}.`,
      });

      setSelectedTargetMarket(updatedTargetMarket);

      refetch();
      closeModal();

      return;
    }

    const nameToUse = name || `'Target Market #${targetMarkets.length + 1}`;

    const createdTargetMarket = await createTargetMarket({
      name: nameToUse,
      search,
    });

    if (createdTargetMarket === false) {
      addErrorToast({
        heading: 'Target Market not saved',
        description: `Sorry, there was an error saving ${nameToUse}. Please try again.`,
      });

      closeModal();
      refetch();

      return;
    }

    if (createdTargetMarket?.id) {
      addSuccessToast({
        heading: 'Target Market saved',
        description: `You saved ${nameToUse}.`,
      });

      closeModal();
      refetch();

      setSearch(createdTargetMarket.search);
      setSelectedTargetMarket(createdTargetMarket);
      setIsEditing(false);

      return;
    }

    const all = await refetch();
    const newTargetMarket: TargetMarket | undefined =
      all.data?.targetMarkets?.targetMarkets?.[0];

    if (!newTargetMarket) {
      addErrorToast({
        heading: 'New Target Market not created',
        description: `Sorry, there was an error creating ${nameToUse}. Please try again.`,
      });

      closeModal();

      return;
    }

    setSearch(newTargetMarket.search);

    addSuccessToast({
      heading: 'Target Market saved',
      description: `You saved ${nameToUse}.`,
    });

    closeModal();
  };

  const closeModal = () => {
    if (isSaveModalOpen) setIsSaveModalOpen(false);

    setIsLoading(false);
  };

  const handleDeleteTargetMarket = async () => {
    if (!selectedTargetMarket) return;

    setIsLoading(true);

    const succeeded = await destroyTargetMarket(selectedTargetMarket.id);

    if (!succeeded) {
      addErrorToast({
        heading: 'Target Market not deleted',
        description: `Sorry, there was an error deleting ${selectedTargetMarket.name}. Please try again.`,
      });

      setIsLoading(false);
      refetch();

      return;
    }

    addSuccessToast({
      heading: 'Target Market deleted',
      description: `You deleted ${selectedTargetMarket.name}.`,
    });

    setSearch(null);
    setIsEditing(false);
    setIsLoading(false);

    refetch();
  };

  const updateSearch = (search: SearchQuery) => {
    setSearch(search);
    setModifiedNotSaved(true);
  };

  return {
    handleDeleteTargetMarket,
    handleDiscardChanges,
    handleUpdateTargetMarket,
    isEditing,
    isLoading,
    isNewList,
    isSaveModalOpen,
    modifiedNotSaved,
    search,
    selectedTargetMarket,
    setIsEditing,
    setIsLoading,
    setIsSaveModalOpen,
    setSearch: updateSearch,
    targetMarketCompanies,
    targetMarketCompaniesLoading,
    targetMarkets,
  } as UseTargetMarket;
};
