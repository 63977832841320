import {
  Event as Events,
  Filter,
  FilterActive,
  View as Pageview,
} from '@clearkit/icons';
import {
  CKActionMenu,
  CKButton,
  CKDropdown,
  CKSearch,
  CKTab,
  CKTabList,
  CKTabs,
} from 'clearkit';

import Select from '~/components/Select';
import { CompanyEventsList } from '~/components/Sidebar/CompanyEventsList/EventsList.tsx';
import { CompanyPageviewsList } from '~/components/Sidebar/CompanyEventsList/PageviewsList.tsx';
import { useEventTypes } from '~/components/Sidebar/hooks/useEventTypes.ts';

const CompanyActivity = ({ company, className }) => {
  const [type, setType] = useState();
  const [path, setPath] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const [excludingAnonymous, setExcludingAnonymous] = useState(false);
  const { eventTypes } = useEventTypes();

  const toggleHideAnonymous = () => {
    setExcludingAnonymous(!excludingAnonymous);
  };

  return (
    <div className={className}>
      <div className="flex justify-between px-8 py-6 border-b border-gray-200 gap-4 align-center bg-gray-0">
        <CKTabs index={tabIndex} isSegmented onChange={setTabIndex}>
          <CKTabList aria-label="My tabs">
            <CKTab icon={<Pageview />} id="page" label="Pageviews">
              Pageviews
            </CKTab>
            <CKTab icon={<Events />} id="events" label="Events">
              Events
            </CKTab>
          </CKTabList>
        </CKTabs>

        {tabIndex === 0 && (
          <div className="flex justify-end flex-1 gap-4">
            <CKSearch
              className="flex-grow max-w-md"
              onChange={(value) => {
                if (value && !value.startsWith('/')) {
                  value = `/${value}`;
                }
                setPath(value);
              }}
              placeholder="Search by path..."
              value={path}
            />
            <CKDropdown
              className="!w-min"
              placement="bottom-end"
              popoverWidth="30ch"
            >
              <CKDropdown.Trigger>
                <CKButton>
                  {excludingAnonymous ? (
                    <FilterActive className="text-blue" />
                  ) : (
                    <Filter />
                  )}
                </CKButton>
              </CKDropdown.Trigger>
              <CKDropdown.Menu>
                <CKActionMenu visible>
                  <CKActionMenu.Checkbox
                    checked={excludingAnonymous}
                    id="toggle-anonymous"
                    onChange={toggleHideAnonymous}
                  >
                    Hide anonymous pageviews
                  </CKActionMenu.Checkbox>
                </CKActionMenu>
              </CKDropdown.Menu>
            </CKDropdown>
          </div>
        )}

        {tabIndex === 1 && (
          <Select
            className="flex-grow w-6/12 max-w-md"
            getOptionLabel={(option) => option || 'All events'}
            getOptionValue={(option) => option || 'All'}
            isDisabled={!eventTypes?.length}
            onChange={(value) => setType(value)}
            options={['', ...eventTypes]}
            placeholder="Filter by event type"
            value={[type || 'All events']}
          />
        )}
      </div>
      <div className="flex flex-col flex-grow">
        {tabIndex === 0 && (
          <CompanyPageviewsList
            className="px-8"
            company={company}
            excludingAnonymous={excludingAnonymous}
            path={path}
          />
        )}
        {tabIndex === 1 && (
          <CompanyEventsList
            className="flex flex-col flex-grow px-4"
            company={company}
            type={type}
          />
        )}
      </div>
    </div>
  );
};

CompanyActivity.propTypes = {
  company: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default CompanyActivity;
