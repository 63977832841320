import { CKTag } from 'clearkit';

import { FitLabel } from '~/components/IdealCustomerProfile/components/types';
import {
  getColorClassNamesFromFitVariant,
  getDescriptionFromFitVariant,
  getFitVariantFromFitScore,
} from '~/components/IdealCustomerProfile/components/util';

type FitTagProps = {
  score?: FitLabel;
  muted?: boolean;
};

export function FitTagCompact({ score, muted }: FitTagProps) {
  const variant = getFitVariantFromFitScore(score);

  if (!variant) {
    return null;
  }

  return (
    <CKTag
      className={`${getColorClassNamesFromFitVariant(variant, {
        muted,
      })} leading-none py-1 px-2 font-medium`}
    >
      {getDescriptionFromFitVariant(variant)}
    </CKTag>
  );
}
