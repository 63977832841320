import MenuList from '@material-ui/core/MenuList';
import { AutoSizer, List } from 'react-virtualized';

import { getAttributeSectionHeader } from '../AttributeCategories';
import styles from './CategorizedList.style';
import CategoryHeader from './CategoryHeader';
import Option from './Option';

const CategorizedList = ({
  categories,
  children,
  classes,
  columns,
  EmptyResults,
  removable,
  selected,
  toggleSelection,
}) => {
  const categoryChildren = (category) =>
    columns.filter((column) => column.category === category);

  const subCategoryChildren = (category, subCategory) =>
    columns.filter(
      (column) =>
        column.category === category && column.subCategory === subCategory,
    );

  let list = categories.reduce((selectedOptions, category) => {
    const children = categoryChildren(category.value);
    if (children.length === 0) return selectedOptions;

    if (category.subCategories.length > 0) {
      const subCategories = category.subCategories.reduce(
        (subCategoryOptions, subCategory) => {
          const categoryHeader = (
            <CategoryHeader
              classes={{
                categoryHeaderRoot: classes.categoryHeaderRoot,
              }}
              disabled
              icon={category.icon}
              key={`${category.value}-${subCategory}`}
              label={getAttributeSectionHeader(
                `${category.value}->${subCategory}`,
              )}
            />
          );

          const optionsForSubCategory = subCategoryChildren(
            category.value,
            subCategory,
          );

          if (optionsForSubCategory.length === 0) return subCategoryOptions;

          const children = optionsForSubCategory.map((column) => {
            return (
              <Option
                classes={{
                  root: classes.optionMenuItemRoot,
                  selected: classes.optionMenuItemSelected,
                  option: classes.option,
                }}
                column={column}
                key={`${column.subCategory}-${column.value}`}
                onClick={() => toggleSelection(column)}
                removable={removable}
                selected={selected(column)}
              />
            );
          });

          return [
            ...subCategoryOptions,
            categoryHeader,
            ...children,
            <div
              className={classes.categorySeparator}
              key={`separator-${category.value}-${subCategory}`}
            />,
          ];
        },
        [],
      );
      return [...selectedOptions, ...subCategories];
    }

    const categoryHeader = (
      <CategoryHeader
        classes={{
          categoryHeaderRoot: classes.categoryHeaderRoot,
        }}
        disabled
        icon={category.icon}
        key={category.value}
        label={getAttributeSectionHeader(category.value)}
      />
    );

    const categoryOptions = children.map((column) => (
      <Option
        classes={{
          root: classes.optionMenuItemRoot,
          option: classes.option,
          selected: classes.optionMenuItemSelected,
        }}
        column={column}
        key={column.value}
        onClick={() => toggleSelection(column)}
        removable={removable}
        selected={selected(column)}
      />
    ));

    return [
      ...selectedOptions,
      categoryHeader,
      ...categoryOptions,
      <div
        className={classes.categorySeparator}
        key={`separator-${category.value}`}
      />,
    ];
  }, []);

  if (list.length === 0) return EmptyResults;

  list.pop();

  const itemWithStyle = (index, style) => {
    const component = list[index];
    return React.cloneElement(component, { style });
  };

  const rowHeight = ({ index }) => {
    const component = list[index];
    if (component.type === 'div') return 1;

    return component.props.category ? 49 : 32;
  };

  return (
    <div className={classes.root}>
      <AutoSizer>
        {({ height, width }) => (
          <MenuList
            style={{
              width,
              height: children ? height - 50 : height,
            }}
          >
            <List
              columns={columns}
              height={children ? height - 50 : height}
              rowCount={list.length}
              rowHeight={({ index }) => rowHeight({ index })}
              rowRenderer={({ index, style }) => itemWithStyle(index, style)}
              width={width}
            />
          </MenuList>
        )}
      </AutoSizer>
      {children}
    </div>
  );
};

CategorizedList.propTypes = {
  classes: PropTypes.shape({
    categoryHeaderRoot: PropTypes.string.isRequired,
    categorySeparator: PropTypes.string.isRequired,
    optionMenuItemRoot: PropTypes.string.isRequired,
    optionMenuItemSelected: PropTypes.string.isRequired,
    root: PropTypes.string.isRequired,
  }).isRequired,
  columns: PropTypes.array.isRequired,
  EmptyResults: PropTypes.node.isRequired,
  selected: PropTypes.func.isRequired,
  toggleSelection: PropTypes.func.isRequired,
  categories: PropTypes.array,
  children: PropTypes.node,
  removable: PropTypes.bool,
};

CategorizedList.defaultProps = {
  categories: [],
  children: null,
  removable: false,
};

export default withStyles(styles, {
  name: 'AppMultiPicklistCategorizedList',
})(CategorizedList);
