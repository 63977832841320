import { CheckmarkOrb } from '@clearkit/icons';
import { CKBox, CKLayoutVertical } from 'clearkit';

type UpgradeAccountCompleteProps = {
  email: string;
  backPath?: string;
};

function UpgradeAccountComplete({ email }: UpgradeAccountCompleteProps) {
  return (
    <CKBox className="h-full p-6">
      <CKLayoutVertical
        alignItems="center"
        className="p-6 text-center"
        justifyContent="start"
      >
        <CKBox className="flex w-12 h-12 p-1">
          <CheckmarkOrb className="w-6 h-6 m-auto fill-gradient-br-green" />
        </CKBox>
        <p className="max-w-[30ch]">
          Success! A member of the Clearbit team will be in touch via email at{' '}
          <span className="font-medium">{email}</span>.
        </p>
      </CKLayoutVertical>
    </CKBox>
  );
}

export default UpgradeAccountComplete;
