import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import startCase from 'lodash/startCase';

import InfoTooltip from '~/components/InfoTooltip';
import TextField from '~/components/RedesignedTextField';
import SalesforceLogo from '~/images/destinations/Salesforce.svg';
import { destinationTypeSalesforce } from '~/lib/destinations';
import { hasError } from '~/lib/graphql';
import { readableSingularSegmentType, segmentTypes } from '~/lib/segments';
import Disconnected from '~/pages/destinations/components/Disconnected';
import Notice from '~/pages/destinations/components/Notice';
import SuppressionForm from '~/pages/destinations/components/SuppressionForm';
import TriggerType from '~/pages/destinations/components/TriggerType';
import defaultTriggerTypes from '~/pages/destinations/components/TriggerType/defaultValues';
import RadioChecked from '~/pages/destinations/components/TriggerType/RadioChecked.svg';
import RadioUnchecked from '~/pages/destinations/components/TriggerType/RadioUnchecked.svg';

import ContentBox from '../../components/ContentBox';
import FormContentWrapper from '../../components/FormContentWrapper';
import FormHeader from '../../components/FormHeader';
import sharedStyles from '../../New/styles';
import styles from './styles';

const placeholderValue = '';

class Salesforce extends React.Component {
  state = {
    body: null,
    duplicateSuppressionTimeframe: 0,
    object: null,
    subject: null,
    triggerType: 'segment_enter',
  };

  UNSAFE_componentWillMount() {
    if (this.props.destination) {
      this.setState({
        ...this.props.destination.config,
        triggerType: this.props.destination.triggerType,
      });
    }
  }

  handleFieldChange = (name, value) => {
    this.setState({ [name]: value });
  };

  get destinationConfig() {
    const {
      body,
      duplicateSuppressionTimeframe,
      object,
      subject,
      triggerType,
    } = this.state;

    return {
      config: {
        body,
        duplicateSuppressionTimeframe,
        object,
        subject,
      },
      triggerType,
    };
  }

  render() {
    const {
      accountConnectors,
      classes,
      errors,
      refetch,
      segment,
      destination,
      FormFooter,
    } = this.props;
    const accountConnector = accountConnectors.salesforce;

    if (!accountConnector || accountConnector?.status !== 'active') {
      return (
        <Disconnected
          connectorStatus={accountConnector?.status}
          FormFooter={FormFooter}
          Logo={SalesforceLogo}
          onSuccess={refetch}
          service="salesforce"
        />
      );
    }

    const {
      object,
      triggerType,
      subject,
      body,
      duplicateSuppressionTimeframe,
    } = this.state;

    const objectValue = object || placeholderValue;
    const triggerTypeValue = triggerType || placeholderValue;

    const humanizedSegmentType = readableSingularSegmentType(segment.type);
    const destinationFlowDescription = destination
      ? `Will post a ${startCase(destination.config.object)} message`
      : null;

    return (
      <>
        <FormHeader
          destinationFlowDescription={destinationFlowDescription}
          destinationName="Salesforce"
          destinationType={destinationTypeSalesforce}
          segmentCollection={segment.collection.name}
          segmentName={segment.name}
        />
        <Notice destinationType="salesforce" segmentType={segment.type} />
        <FormContentWrapper>
          <h2 className={classes.subheading}>Trigger</h2>
          <span className={classes.triggerSubtext}>
            Choose when this Destination will be run.{' '}
            <a
              href="https://help.clearbit.com/hc/en-us/articles/360023453694"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline' }}
              target="_blank"
            >
              Learn more
            </a>
          </span>

          <TriggerType
            errors={errors}
            handleChange={this.handleFieldChange}
            triggerType={triggerTypeValue}
            triggerTypes={defaultTriggerTypes(segment.type)}
          />

          <h2 className={classes.subheading}>Configure Salesforce options</h2>

          <ContentBox>
            <FormControl
              className={classes.formControl}
              error={hasError(errors, 'object')}
              required
            >
              {hasError(errors, 'object') && (
                <FormHelperText className={classes.errorText}>
                  You must choose a Salesforce object
                </FormHelperText>
              )}
              <RadioGroup
                className={classes.group}
                onChange={(event) => {
                  this.handleFieldChange('object', event.target.value);
                }}
                value={objectValue}
              >
                <FormControlLabel
                  classes={{
                    root: classes.formControlLabelRoot,
                    label: classes.formControlLabel,
                  }}
                  control={
                    <Radio
                      checkedIcon={<RadioChecked />}
                      classes={{ root: classes.radioRoot }}
                      icon={<RadioUnchecked />}
                    />
                  }
                  label={
                    <span className={classes.radioLabel}>
                      Post message to Salesforce chatter
                      <InfoTooltip
                        title={`Post to Chatter when a ${humanizedSegmentType} matches an audience.`}
                      />
                    </span>
                  }
                  value="chatter"
                />
                <FormControlLabel
                  classes={{
                    root: classes.formControlLabelRoot,
                    label: classes.formControlLabel,
                  }}
                  control={
                    <Radio
                      checkedIcon={<RadioChecked />}
                      classes={{ root: classes.radioRoot }}
                      icon={<RadioUnchecked />}
                    />
                  }
                  label={
                    <span className={classes.radioLabel}>
                      Create a Salesforce Task{' '}
                      <InfoTooltip
                        title={`
                      Create a Task assigned to the ${
                        segment.type === segmentTypes.company
                          ? 'Account'
                          : 'Lead or Contact'
                      } Owner when a ${humanizedSegmentType} matches an audience
                      `}
                      />
                    </span>
                  }
                  value="task"
                />
              </RadioGroup>
            </FormControl>

            <hr className={classes.break} style={{ marginTop: 24 }} />

            <div className={classes.formContainer}>
              {objectValue === 'task' && (
                <TextField
                  className={classes.field}
                  defaultValue={subject}
                  error={hasError(errors, 'subject')}
                  fullWidth
                  label="Task Subject"
                  onChange={(event) => {
                    this.handleFieldChange('subject', event.target.value);
                  }}
                  placeholder="Enter task subject"
                  variant="standard"
                />
              )}

              <TextField
                className={classes.field}
                defaultValue={body}
                error={hasError(errors, 'body')}
                fullWidth
                label="Message body"
                multiline
                onChange={(event) => {
                  this.handleFieldChange('body', event.target.value);
                }}
                placeholder="Enter text for the message"
                rows="8"
                variant="standard"
              />

              <span className={classes.markdownCopy}>
                Markdown tags are supported.{' '}
                <a
                  className={classes.link}
                  href="https://help.clearbit.com/hc/en-us/articles/360022534154-Liquid-Templating"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Learn more
                </a>
              </span>
            </div>

            <hr className={classes.break} />

            <SuppressionForm
              exclusion_timeframe={duplicateSuppressionTimeframe}
              onChange={({ exclusion_timeframe }) => {
                this.handleFieldChange(
                  'duplicateSuppressionTimeframe',
                  exclusion_timeframe,
                );
              }}
              typename={humanizedSegmentType}
            />
          </ContentBox>
        </FormContentWrapper>
        <FormFooter destinationConfig={this.destinationConfig} />
      </>
    );
  }
}

Salesforce.propTypes = {
  accountConnectors: PropTypes.shape({
    salesforce: PropTypes.object.isRequired,
  }),
  classes: PropTypes.object.isRequired,
  destination: PropTypes.object,
  errors: PropTypes.array.isRequired,
  refetch: PropTypes.func.isRequired,
  segment: PropTypes.object.isRequired,
};

export default withStyles(
  (theme) => ({
    ...sharedStyles(theme),
    ...styles(theme),
  }),
  { name: 'SegmentDestinationNewPageSalesforce' },
)(Salesforce);
