import client from '../apollo';
import { AccountConnectorService } from '../configuration';
import { open as openPopup } from '../popup';
import request, { endpoint } from './request';

export function authConnector(
  service: AccountConnectorService,
  successCallback: (data: { type: 'clearbitAuthComplete' }) => void,
  errorCallback: (data: { type: 'clearbitAuthError'; message: string }) => void,
) {
  openPopup(`${endpoint}/auth/${service}`);

  const onMessageFunc = (event: MessageEvent) => {
    if (event.data.type === 'clearbitAuthComplete' && successCallback) {
      successCallback(event.data);
    } else if (event.data.type === 'clearbitAuthError' && errorCallback) {
      errorCallback(event.data);
    }

    window.removeEventListener('message', onMessageFunc, false);
  };

  window.onmessage = onMessageFunc;
}

export function getCurrentUser() {
  return request({
    path: '/v1/users/current',
  });
}

export function getCurrentAccount() {
  return request({
    path: '/v1/accounts/current',
  });
}

export function isAuthRoute() {
  return window.location.pathname.startsWith('/auth/');
}

type BaseErrorResponse = {
  type: 'auth';
};

type SimpleErrorResponse = {
  subtype?: undefined;
};

export type MFARequiredErrorResponse = BaseErrorResponse & {
  subtype: 'mfa_required';
};

export type MFASetupErrorResponse = BaseErrorResponse & {
  subtype: 'mfa_setup_required';
};

export type CreateProfileErrorResponse = BaseErrorResponse & {
  subtype: 'create_profile';
};

export type MissingAuthFactorErrorResponse = BaseErrorResponse & {
  subtype: 'missing_auth_factor';
  desired_account: string;
};

export type LoginErrorResponse = BaseErrorResponse & {
  subtype: 'login_error';
  message_code: string;
};

export type AuthErrorResponse =
  | SimpleErrorResponse
  | MFARequiredErrorResponse
  | MFASetupErrorResponse
  | CreateProfileErrorResponse
  | MissingAuthFactorErrorResponse
  | LoginErrorResponse;

export function authUser(error: AuthErrorResponse) {
  switch (error.subtype) {
    case 'create_profile':
      window.location.pathname = '/auth/signup/profile';
      return;
    case 'login_error':
      window.location.pathname = `/auth/login?error=${error.message_code}`;
      return;
    case 'missing_auth_factor':
      window.location.pathname = `/auth/login/${error.desired_account}/upgrade_auth_factors`;
      return;
    case 'mfa_required':
      window.location.pathname = '/auth/mfa';
      return;
    case 'mfa_setup_required':
      window.location.assign(
        `${window.location.origin}/auth?mfa_setup_required=true`,
      );
      return;
  }

  if (isAuthRoute()) return;

  if (window.localStorage.getItem('USE_STYTCH_AUTH') === 'true') {
    window.location.pathname = '/auth/login';
    return;
  }

  window.location.pathname = '/auth';
}

export function logoutUser() {
  client.resetStore();
  window.location.pathname = '/logout';
}
