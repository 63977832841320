import NestedMultiSelect from '~/components/NestedMultiselect';
import { SearchContext } from '~/components/SearchBuilder/utils/searchUtils';

export const NestedValueMultiSelect = ({
  className,
  options,
  onChange,
  value,
  menuIsOpen,
  classNamePrefix,
}) => {
  const { disabled } = useContext(SearchContext);

  return (
    <NestedMultiSelect
      className={className}
      classNamePrefix={classNamePrefix}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => {
        return {
          value: option.value,
          attribute: option.attribute,
          toString: () => `${option.attribute}: ${option.value}`,
        };
      }}
      isDisabled={disabled}
      menuIsOpen={menuIsOpen}
      onChange={(newValues) => {
        const sanitized = newValues.map((newValue) => ({
          value: newValue.value,
          attribute: newValue.attribute,
        }));
        onChange(sanitized);
      }}
      options={options}
      title="Choose options"
      value={value}
    />
  );
};

NestedValueMultiSelect.propTypes = {
  menuIsOpen: PropTypes.bool,
  classNamePrefix: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    ),
  ]),
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onChange: PropTypes.func,
};

NestedValueMultiSelect.defaultProps = {
  onChange: () => {},
  value: [],
};

export default NestedValueMultiSelect;
