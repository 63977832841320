import classnames from 'classnames';
import {
  CKBox,
  CKContainerProps,
  CKLayoutHorizontal,
  includeChildrenByDisplayName,
} from 'clearkit';
import { FC } from 'react';

export type HomeBannerHeaderProps = CKContainerProps;

type HomeBannerHeaderComposition = {
  Left: FC<CKContainerProps>;
  Message: FC<CKContainerProps>;
  Right: FC<CKContainerProps>;
};

const componentDisplayNameMessage = 'HomeBannerHeader.Message';
const componentDisplayNameLeft = 'HomeBannerHeader.Left';
const componentDisplayNameRight = 'HomeBannerHeader.Right';

export const HomeBannerHeader: FC<HomeBannerHeaderProps> &
  HomeBannerHeaderComposition = ({ children, className, ...rest }) => {
  return (
    <CKBox
      {...rest}
      className={classnames(
        className,
        'p-4 !bg-white/40 backdrop-blur-lg sticky top-0 z-50',
      )}
      variant="card"
    >
      <CKLayoutHorizontal wrap="nowrap">
        <CKLayoutHorizontal justifyContent="start" wrap="nowrap">
          {includeChildrenByDisplayName({
            children,
            componentDisplayName: componentDisplayNameLeft,
          })}

          {includeChildrenByDisplayName({
            children,
            componentDisplayName: componentDisplayNameMessage,
          })}
        </CKLayoutHorizontal>
        {includeChildrenByDisplayName({
          children,
          componentDisplayName: componentDisplayNameRight,
        })}
      </CKLayoutHorizontal>
    </CKBox>
  );
};

HomeBannerHeader.Message = ({ className, ...rest }) => (
  <p {...rest} className={classnames('text-sm flex-1', className)} />
);
HomeBannerHeader.Message.displayName = componentDisplayNameMessage;

HomeBannerHeader.Left = ({ className, ...rest }) => (
  <div {...rest} className={classnames('flex-0', className)} />
);
HomeBannerHeader.Left.displayName = componentDisplayNameLeft;

HomeBannerHeader.Right = ({ className, ...rest }) => (
  <div
    {...rest}
    className={classnames('grow-0 shrink-0 basis-auto', className)}
  />
);
HomeBannerHeader.Right.displayName = componentDisplayNameRight;
