import {
  AddHollow,
  Go,
  SpinnerMonoIndicators as Spinner,
  Team,
} from '@clearkit/icons';
import classnames from 'classnames';
import { CKBadge, CKButton } from 'clearkit';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import { settingsRoutes } from '~/components/settings/settingsRoutes';
import history from '~/lib/history';

type AccountSwitchProps = {
  accounts: any[];
  currentAccountId: string;
};

const AccountSwitch: FC<AccountSwitchProps> = ({
  accounts,
  currentAccountId,
}) => {
  const [loadingAccount, setLoadingAccount] = useState<string | undefined>();
  const handleManageTeam = () => {
    history.push(settingsRoutes.teamGeneralRoute);
  };

  const buttonClasses =
    'block w-full px-4 py-1 text-left text-current group hover:bg-blue-0';

  return (
    <div className="py-3 overflow-y-auto text-sm font-medium text-gray-900 border-b border-gray-200 max-h-[60vh]">
      <ul className="grid gap-1">
        {accounts.map((account) => (
          <li key={account.id}>
            <form
              action={`/auth/switch_account/${account.id}`}
              method="POST"
              onSubmit={(e) => {
                setLoadingAccount(account.id);

                if (currentAccountId === account.id) {
                  e.preventDefault();
                  handleManageTeam();
                }
              }}
            >
              <button className={buttonClasses} type="submit">
                <span className="flex items-start w-full gap-2">
                  <Team
                    className={classnames('h-4 w-4 text-gray-600 mt-0.5', {
                      'fill-gradient-br-blue': currentAccountId === account.id,
                    })}
                  />
                  <span className="grow">
                    <span className="block">{account.name}</span>
                    {currentAccountId === account.id && (
                      <span className="flex items-center ck-link !no-underline gap-1">
                        Manage team
                        <Go className="w-4 h-4 opacity-0 group-hover:opacity-100 transition-opacity duration-150 ease-out" />
                      </span>
                    )}
                  </span>
                  {currentAccountId === account.id && (
                    <CKBadge className="bg-gray-500" variant="rounded">
                      Current
                    </CKBadge>
                  )}
                  {loadingAccount === account.id && (
                    <Spinner className="w-4 h-4" />
                  )}
                </span>
              </button>
            </form>
          </li>
        ))}
        <li>
          <CKButton
            as={Link}
            className={buttonClasses}
            to="/settings/general/teams"
            variant="clear"
          >
            <span className="flex items-center gap-2">
              <AddHollow className="text-gray-600" />
              Manage teams
            </span>
          </CKButton>
        </li>
      </ul>
    </div>
  );
};

export default AccountSwitch;
