export default (theme) => ({
  root: {
    margin: '0 40px 24px',
  },

  label: {
    fontSize: 13,
    lineHeight: '20px',
    color: theme.palette.list.label,
    minWidth: 180,
    flexShrink: 0,
    paddingRight: 16,
  },

  value: {
    fontSize: 14,
    color: theme.palette.list.value,
    flexGrow: 0,
  },

  attributeHeader: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    padding: '24px 24px 0',
    margin: '0 -24px',
    background: '#fff',
    boxShadow: '0 10px 8px 2px #fff',
  },

  search: {
    width: '100%',
  },

  tabs: {
    marginTop: 12,
  },
});
