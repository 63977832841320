const styles = () => ({
  newAudienceButtonIcon: {
    marginRight: 6,
  },

  newAudienceButton: {
    marginTop: 'auto',
    marginBottom: 24,
    fontSize: 14,

    '&:hover::before': {
      backgroundColor: '#fff',
    },
  },
});

export default styles;
