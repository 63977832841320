import {
  Companies,
  Config as GearIcon,
  HubspotServices as Hubspot,
  MarketoServices as Marketo,
  People,
  SalesforceServices,
  Search,
} from '@clearkit/icons';

export const typePerson = 'person';
export const typeCompany = 'company';

export const integrationTypeSalesforce = 'salesforce';
export const integrationTypeHubSpot = 'hubspot';
export const integrationTypeMarketo = 'marketo';

export const types = {
  salesforce: integrationTypeSalesforce,
  hubspot: integrationTypeHubSpot,
  marketo: integrationTypeMarketo,
};

export const statuses = {
  complete: 'complete',
  error: 'error',
  info: 'info',
  pending: 'pending',
  skipped: 'skipped',
};

export const filters = {
  all: 'all',
  complete: 'complete',
  incomplete: 'incomplete',
};

export const subTypes = {
  account: 'account',
  contact: 'contact',
  company: 'company',
  lead: 'lead',
  webhook: 'webhook',
  logs: 'logs',
};
export const integrationSubObjectTypes = {
  [integrationTypeSalesforce]: [
    subTypes.account,
    subTypes.contact,
    subTypes.lead,
  ],
  [integrationTypeHubSpot]: [subTypes.contact, subTypes.company],
  [integrationTypeMarketo]: [subTypes.lead],
};
export function filterToStatuses(filter) {
  switch (filter) {
    case filters.complete:
      return [statuses.complete, statuses.error, statuses.info];
    case filters.incomplete:
      return [statuses.pending, statuses.processing];
  }
}

export function readableFilter(filter) {
  switch (filter) {
    case filters.all:
      return 'enrichments';
    case filters.complete:
      return 'complete enrichments';
    case filters.incomplete:
      return 'incomplete enrichments';
  }
}

export function readableType(type) {
  switch (type) {
    case types.salesforce:
      return 'Salesforce';
    case types.hubspot:
      return 'HubSpot';
    case types.marketo:
      return 'Marketo';
  }
}

export function readableObjectTypeName(name) {
  switch (name) {
    case 'company':
      return 'companies';
    default:
      return name + 's';
  }
}

export function sourceIcon(type, pixels = 18) {
  switch (type) {
    case types.salesforce:
      return <SalesforceServices height={pixels} width={pixels} />;
    case types.hubspot:
      return <Hubspot height={pixels} width={pixels} />;
    case types.marketo:
      return <Marketo height={pixels} width={pixels} />;
  }
}

export const tabTypeAccount = {
  name: subTypes.account,
  // object type - company/person
  type: typeCompany,
  icon: <Companies />,
  label: 'Account',
};
export const tabTypeCompany = {
  name: subTypes.company,
  type: typeCompany,
  icon: <Companies />,
  label: 'Company',
};
export const tabTypeContact = {
  name: subTypes.contact,
  type: typePerson,
  icon: <People />,
  label: 'Contact',
};
export const tabTypeLead = {
  name: subTypes.lead,
  type: typePerson,
  icon: <People />,
  label: 'Lead',
};
export const tabTypeWebhook = {
  name: subTypes.webhook,
  icon: <GearIcon />,
  label: 'Settings',
};
export const tabTypeEnrichmentLogs = {
  name: subTypes.logs,
  icon: <Search />,
  label: 'Enrichment Logs',
};

export const integrationTypes = [
  integrationTypeSalesforce,
  integrationTypeHubSpot,
  integrationTypeMarketo,
];

// a bit of a misnomer now but don't want to refactor everyone this is called right now, but this
// is a collection of tabs (+ their info) for various integrations types
export const integrationTabs = {
  [integrationTypeSalesforce]: [
    tabTypeAccount,
    tabTypeContact,
    tabTypeLead,
    tabTypeEnrichmentLogs,
  ],
  [integrationTypeHubSpot]: [
    tabTypeContact,
    tabTypeCompany,
    tabTypeEnrichmentLogs,
  ],
  [integrationTypeMarketo]: [
    tabTypeLead,
    tabTypeEnrichmentLogs,
    tabTypeWebhook,
  ],
};

export const findAttribute = (value, attributes) => {
  if (!value) {
    return null;
  }

  for (let index = 0; index < attributes.length; index++) {
    const attribute = attributes[index];

    if (
      attribute.value === value.value &&
      attribute.meta.objectType === value.objectType &&
      attribute.meta.sourceType === value.sourceType
    ) {
      return attribute;
    }

    if (attribute.children && attribute.children.length > 0) {
      const foundAttribute = findAttribute(value, attribute.children);

      if (foundAttribute) {
        return foundAttribute;
      }
    }
  }
};

export const attributeForFinder = (mapping, sourceOrTarget) => {
  return {
    value: mapping[`${sourceOrTarget}Field`],
    objectType: mapping[`${sourceOrTarget}ObjectType`],
    sourceType: mapping[`${sourceOrTarget}Type`],
  };
};

export const backendSupportedDataType = (type) => {
  switch (type) {
    case 'boolean':
      return 'bool';
    default:
      return type;
  }
};
