import { routes } from '../app/appMainRoutes';

export function isLoading(state: any) {
  return (
    state.matches('loading') ||
    state.matches('loadingProspectList') ||
    state.matches('loadingProspects') ||
    state.matches('loadingProspectLists')
  );
}

export function isWaitingForNewList(state: any) {
  return isLoading(state) || state.matches('loadingProspectFilters');
}

export function isExporting(state: any) {
  return (
    state.matches('exporting') ||
    state.matches('exportHistory') ||
    state.matches('exportIdle') ||
    state.matches('exportingSalesforce') ||
    state.matches('configuringSalesforceExport') ||
    state.matches('configuringHubspotExport') ||
    state.matches('cancelingExport') ||
    state.matches('downloading') ||
    state.matches('downloadingExport')
  );
}

export function isExportingSalesforce(state: any) {
  return (
    state.matches('exportingSalesforce') ||
    state.matches('configuringSalesforceExport')
  );
}

export function isExportingHubspot(state: any) {
  return (
    state.matches('exportingHubspot') ||
    state.matches('configuringHubspotExport')
  );
}

export const hasAnyFilters = ({
  personQuery,
  companyQuery,
}: {
  companyQuery: Record<string, string[] | null>;
  personQuery: Record<string, string[] | null>;
}) => {
  const hasCompanyFilters = Object.values(companyQuery).some(
    (value) => value && value.length > 0,
  );
  const hasPersonFilters = Object.values(personQuery).some(
    (value) => value && value.length > 0,
  );

  return hasCompanyFilters || hasPersonFilters;
};

function getDomain(input: string) {
  try {
    const url = new URL(input);
    return url.hostname;
  } catch (error) {
    return input;
  }
}

export function extractTopLevelDomain(url: string) {
  const domain = getDomain(url);
  const topLevelDomain = domain.replace(/^(www\.)?/, '');

  return topLevelDomain;
}

export function convertExportFieldMappingToAttributeArray(
  exportFieldMapping: Record<string, string>,
) {
  return Object.entries(exportFieldMapping).map(([key, val]) => ({
    sourceAttribute: val,
    targetAttribute: key,
  }));
}

export type ProspectorSource = 'visitors' | 'target_markets';

export function prospectorPath({
  source,
  domain,
}: {
  source?: ProspectorSource;
  domain?: string | string[];
}) {
  let path = `${routes.prospector}?new=true`;

  if (source) {
    path += `&source=${source}`;
  }

  if (domain) {
    path += `&domain=${domain}`;
  }

  return path;
}
