import { Caret, Hubspot, Salesforce } from '@clearkit/icons';
import { CKActionMenu, CKButton, CKPopover } from 'clearkit';

import HubspotCompanyListItem from '~/components/ProfileHeaderSalesforceHubspotMenuItem/hubspotCompany';
import SalesforceAccountMenuItem from '~/components/ProfileHeaderSalesforceHubspotMenuItem/salesforceAccount';
import SalesforceOpportunityListItem from '~/components/ProfileHeaderSalesforceHubspotMenuItem/salesforceOpportunity';
import { paginateField } from '~/lib/graphql/pagination';

import styles from './styles';

const Actions = ({
  classes = {},
  hubspotOwners,
  hubspotCompanies,
  salesforceAccounts,
  salesforceAccountConnector,
}) => {
  return (
    <div className="flex flex-row ml-4">
      {salesforceAccountConnector && salesforceAccounts?.length > 0 && (
        <>
          <CKPopover
            aria-label="Popover"
            className="z-10 overflow-y-auto popover-max-height"
            gutter={4}
            placement="bottom-end"
            popover={
              <CKActionMenu
                aria-label="Default Menu"
                hideOnClickOutside={false}
                visible
              >
                {salesforceAccounts?.map((salesforceAccount, i) => {
                  const salesforceOpportunities = paginateField(
                    salesforceAccount,
                    'salesforceOpportunities',
                  ).filter((opportunity) => !opportunity.properties.IsClosed);

                  return (
                    <>
                      <SalesforceAccountMenuItem
                        key={salesforceAccount.externalId}
                        salesforceAccount={salesforceAccount}
                        salesforceAccountConnector={salesforceAccountConnector}
                      />

                      {salesforceOpportunities?.length > 0 && (
                        <>
                          <span className="px-4 text-sm font-medium text-gray">
                            {salesforceOpportunities?.length}{' '}
                            {salesforceOpportunities?.length == 1
                              ? 'opportunity'
                              : 'opportunities'}
                          </span>

                          {salesforceOpportunities.map(
                            (salesforceOpportunity) => (
                              <SalesforceOpportunityListItem
                                key={salesforceOpportunity.externalId}
                                salesforceAccountConnector={
                                  salesforceAccountConnector
                                }
                                salesforceOpportunity={salesforceOpportunity}
                              />
                            ),
                          )}
                        </>
                      )}

                      {i !== salesforceAccounts.length - 1 && (
                        <CKActionMenu.Divider />
                      )}
                    </>
                  );
                })}
              </CKActionMenu>
            }
          >
            <CKButton
              className={classnames(classes.salesforceButton, 'ml-4')}
              leftIcon={<Salesforce className="mr-1" />}
              rightIcon={<Caret className="-mr-1" />}
            >
              Salesforce
            </CKButton>
          </CKPopover>
        </>
      )}
      {hubspotCompanies?.length > 0 && (
        <>
          <CKPopover
            aria-label="Popover"
            className="z-10 overflow-y-auto popover-max-height"
            gutter={4}
            placement="bottom-end"
            popover={
              <CKActionMenu
                aria-label="Default Menu"
                hideOnClickOutside={false}
                visible
              >
                {hubspotCompanies.map((hubspotCompany, i) => (
                  <>
                    <HubspotCompanyListItem
                      hubspotCompany={hubspotCompany}
                      hubspotOwners={hubspotOwners}
                      key={hubspotCompany.id}
                    />
                    {i !== hubspotCompanies.length - 1 && (
                      <CKActionMenu.Divider />
                    )}
                  </>
                ))}
              </CKActionMenu>
            }
          >
            <CKButton
              className={classnames(classes.hubspotButton, 'ml-4')}
              leftIcon={<Hubspot className="mr-1" />}
              rightIcon={<Caret className="-mr-1" />}
            >
              HubSpot
            </CKButton>
          </CKPopover>
        </>
      )}
    </div>
  );
};

Actions.propTypes = {
  classes: PropTypes.object,
  hubspotCompanies: PropTypes.array,
  salesforceAccounts: PropTypes.array,
  hubspot: PropTypes.object,
  salesforce: PropTypes.object,
  hubspotOwners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  ),
  salesforceAccountConnector: PropTypes.shape({
    externalInstanceUrl: PropTypes.string,
  }),
};

export default withStyles(styles)(Actions);
