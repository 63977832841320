import { ClearbitIdentity } from '@clearkit/icons';
import * as Sentry from '@sentry/browser';
import { CKBox, CKLayoutHorizontal } from 'clearkit';

import history from '~/lib/history';

export default class ErrorHandler extends React.Component {
  state = {
    error: false,
  };

  componentDidMount() {
    this.listener = history.listen(this.reset);
  }

  componentDidCatch(error, errorInfo) {
    if (window.location.hostname !== 'localhost') {
      this.setState({ error });

      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);

        Sentry.captureException(error);
      });
    }
  }

  componentWillUnmount() {
    if (this.listener) this.listener();
  }

  reset = () => {
    if (this.state.error) {
      this.setState({ error: false });
    }
  };

  onClickBack() {
    history.back();
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return (
        <CKLayoutHorizontal
          className="w-full min-h-full p-12 text-base text-gray-600 bg-gray-0"
          justifyContent="center"
        >
          <CKBox className="items-center p-8 space-y-6 w-[20rem]">
            <ClearbitIdentity className="w-12 h-12" />
            <div className="text-center space-y-3">
              <h2 className="font-medium text-gray-900 text-[1.325rem]">
                There was a problem
              </h2>
              <div>We could not load this page.</div>
              <div>
                <a
                  className="ck-link"
                  href=""
                  onClick={() => this.onClickBack()}
                >
                  Go back
                </a>{' '}
                to try again.
              </div>
            </div>
          </CKBox>
        </CKLayoutHorizontal>
      );
    } else {
      return children;
    }
  }
}
