const styles = (theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    minHeight: 0,
    margin: '0 -11px',
  },

  sidebar: {
    margin: '22px 0',
  },

  tableHead: {
    flex: 'none',
    userSelect: 'none',
  },

  /**
   * Remove overflow auto because it causes the Pageviews table to
   * tweak out in CKDrawer.
   *  */
  tableBody: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    position: 'relative',
  },

  tableRow: {
    display: 'flex',
    flex: '0 0 auto',
  },

  tableRowTypeBodyHover: {
    cursor: 'pointer',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

    '&:hover': {
      backgroundColor: '#fafafa',
    },
  },

  tableRowTypeBodySelected: {
    backgroundColor: 'rgba(0,0,0,.04)',
  },

  tableRowAnimateIn: {
    animationDuration: '0.35s',
    animationName: 'rowExpandIn',
  },

  tableRowInheritColor: {
    '& div': {
      color: 'inherit',
    },
  },

  '@keyframes rowExpandIn': {
    from: {
      height: '20px',
    },

    to: {
      height: '62px',
    },
  },

  tableCell: {
    flex: '1 1 0',
    padding: '20px 12px',
    borderBottom: '1px solid #F3F4F5',
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
    lineHeight: '1.4',
    flexShrink: 0,
  },

  tableCellSlim: {
    padding: '20px 24px 20px 0',
  },

  tableCellEllipsis: {
    flex: '1 1 auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    minWidth: 0,
  },

  tableCellTypeHead: {
    fontSize: 13,
    fontWeight: 600,
    color: '#9badbc',
    margin: 0,
  },

  tableCellTypeBody: {
    fontSize: 15,
    color: '#27282a',
  },

  tableCellLink: {
    color: theme.palette.blue[500],
  },

  tableCellSub: {},

  tableCellSmall: {
    maxWidth: 130,
  },

  tableCellTiny: {
    maxWidth: 60,
  },

  tableCellLeftAligned: {
    paddingLeft: '4px',
  },

  tableCellRightAligned: {
    justifyContent: 'flex-end',
  },
});

export default styles;
