import { isEqual, uniq } from 'lodash';
import { assign, createMachine } from 'xstate';

import history from '~/lib/history';

import {
  cancelProspectListExport,
  createProspectList,
  createProspectListExport,
  createProspectListHubspotExport,
  createProspectListSalesforceExport,
  //copyProspectList,
  destroyProspectList,
  downloadProspectListExport,
  fetchProspectFilters,
  fetchProspectList,
  fetchProspectListExports,
  fetchProspectLists,
  fetchProspects,
  updateProspectList,
} from './client';
import {
  DOMAIN_LIMIT_ERROR_MESSAGE,
  EMAIL_PROVIDER_ERROR_MESSAGE,
  OVER_QUOTA_ERROR_MESSAGE,
  PROSPECTS_PAGE_LIMIT,
  PROSPECTS_PER_PAGE,
} from './constants';
import {
  MachineArguments,
  machineSchema,
  UpdateNameEvent,
  UpdateQueryEvent,
} from './machineTypes';

export const machine = (context: MachineArguments) =>
  createMachine(
    {
      context: {
        companyQuery: {},
        ...context,
        currentPage: 1,
        deselections: [],
        hasNextPage: true,
        hasPreviousPage: false,
        isFilterPreviewOpen: true,
        preferFilterPreviewOpen: true,
        loadingExports: true,
        loadingPage: true,
        name: '',
        nameEdit: '',
        pageEnd: PROSPECTS_PER_PAGE,
        pageStart: 0,
        perPage: PROSPECTS_PER_PAGE,
        personQuery: {},
        previousTotalCompanies: 0,
        previousTotalProspects: 0,
        prospectFilters: null,
        prospectList: null,
        prospectListExportsPending: [],
        prospectListExportsHistory: [],
        prospectLists: null,
        prospects: [],
        selectAll: false,
        selectAllPage: false,
        selectedProspectsCount: 0,
        selections: [],
        showFilterSidebar: false,
        totalCompanies: 0,
        totalPages: 1,
        totalProspects: 0,
      },
      id: 'ProspectList',
      initial: 'loading',
      states: {
        loading: {
          invoke: {
            src: 'fetchProspectLists',
            id: 'fetchingProspectLists',
            onError: [
              {
                target: 'error',
              },
            ],
            onDone: [
              {
                cond: 'hasProspectList',
                target: 'loadingProspectList',
                actions: 'setProspectLists',
              },
              {
                target: 'loadingProspects',
                actions: 'setProspectLists',
              },
            ],
          },
        },

        loadingProspectList: {
          invoke: {
            src: 'fetchProspectList',
            id: 'fetchingProspectList',
            onError: [
              {
                target: 'error',
                cond: 'hasProspectList',
              },
              {
                target: 'loadingProspects',
              },
            ],
            onDone: [
              {
                target: 'loadingProspects',
                actions: 'setProspectList',
              },
            ],
          },
        },

        loadingProspects: {
          invoke: {
            src: 'fetchProspects',
            id: 'fetchingProspects',
            onError: [
              {
                target: 'error',
              },
            ],
            onDone: [
              {
                target: 'loadingProspectFilters',
                actions: 'setProspects',
              },
            ],
          },
        },

        loadingProspectFilters: {
          invoke: {
            src: 'fetchProspectFilters',
            id: 'fetchingProspectFilters',
            onError: [
              {
                target: 'error',
              },
            ],
            onDone: [
              {
                target: 'configuring',
                actions: 'setProspectFilters',
              },
            ],
          },
        },

        error: {
          on: {
            RETRY: {
              target: 'loading',
            },
          },
        },

        configuring: {
          on: {
            UPDATE_QUERY: {
              actions: 'updateQuery',
              target: 'refreshing',
            },
            CREATE_PROSPECT_LIST: {
              actions: [
                'createNewProspectList',
                'clearSelections',
                'toggleFilterPreview',
                'toggleShowFilterSidebar',
              ],
            },
            CREATE_CSV_EXPORT: {
              target: 'creatingExport',
            },
            CREATE_SALESFORCE_EXPORT: {
              target: 'configuringSalesforceExport',
            },
            CREATE_HUBSPOT_EXPORT: {
              target: 'configuringHubspotExport',
            },
            DISCARD_CHANGES: {
              actions: ['discardChanges', 'toggleShowFilterSidebar'],
              target: 'loadingProspectList',
            },
            RENAME: {
              target: 'renaming',
            },
            DELETE: {
              target: 'confirmingDelete',
            },
            SAVE: [
              {
                /**
                 * @todo make this go to 'confirmingSave' and wire up the modal stuff
                 */
                target: 'saving',
                cond: 'hasProspectList',
              },
              {
                target: 'confirmingCreate',
              },
            ],
            SELECT_PROSPECT: {
              actions: ['selectProspect', 'updateSelectedProspectsCount'],
            },
            DESELECT_PROSPECT: {
              actions: ['deselectProspect', 'updateSelectedProspectsCount'],
            },
            SELECT_ALL: {
              actions: ['selectAll', 'updateSelectedProspectsCount'],
            },
            DESELECT_ALL: {
              actions: ['deselectAll', 'updateSelectedProspectsCount'],
            },
            SELECT_ALL_PAGE: {
              actions: ['selectAllPage', 'updateSelectedProspectsCount'],
            },
            NEXT_PAGE: {
              actions: 'setNextPage',
              cond: 'hasNextPage',
              target: 'refreshing',
            },
            PREVIOUS_PAGE: {
              actions: 'setPreviousPage',
              cond: 'hasPreviousPage',
              target: 'refreshing',
            },
            TOGGLE_EXPORT_DRAWER_PENDING: {
              target: 'exporting',
            },
            TOGGLE_EXPORT_DRAWER_HISTORY: {
              target: 'exporting',
            },
            TOGGLE_FILTER_PREVIEW: {
              actions: 'toggleFilterPreview',
            },
            TOGGLE_FILTER_SIDEBAR: {
              actions: 'toggleShowFilterSidebar',
            },
            PROSPECT_LIST_CHANGE: {
              actions: ['changeProspectList', 'clearSelections'],
              target: 'loading',
            },
          },
        },

        refreshing: {
          invoke: {
            src: 'fetchProspects',
            id: 'refreshingProspects',
            onError: [
              {
                target: 'configuring',
                actions: 'addQueryErrorToast',
                cond: 'isProspectQueryError',
              },
              {
                target: 'error',
              },
            ],
            onDone: [
              {
                target: 'configuring',
                actions: 'setProspects',
              },
            ],
          },
          on: {
            UPDATE_QUERY: {
              actions: 'updateQuery',
              target: 'refreshing',
            },
            DISCARD_CHANGES: {
              actions: ['discardChanges', 'toggleShowFilterSidebar'],
              target: 'loadingProspectList',
            },
            SELECT_ALL: {
              actions: 'selectAll',
            },
            DESELECT_ALL: {
              actions: 'deselectAll',
            },
          },
        },

        confirmingSave: {
          on: {
            CANCEL: {
              actions: 'clearName',
              target: 'configuring',
            },
            SAVE: {
              target: 'saving',
            },
            UPDATE_NAME: {
              actions: 'setNameEdit',
            },
          },
        },

        saving: {
          invoke: {
            src: 'updateProspectList',
            id: 'saving',
            onDone: {
              actions: 'addSaveSuccessToast',
              target: 'loading',
            },
            onError: {
              actions: 'addSaveErrorToast',
              target: 'configuring',
            },
          },
        },

        confirmingCreate: {
          on: {
            CANCEL: {
              actions: 'clearName',
              target: 'configuring',
            },
            SAVE: {
              target: 'creating',
            },
            UPDATE_NAME: {
              actions: 'setNameEdit',
            },
          },
        },

        creating: {
          invoke: {
            src: 'createProspectList',
            id: 'saving',
            onDone: {
              actions: [
                'addCreateSuccessToast',
                'setNewProspectList',
                'navigateToProspectList',
              ],
              target: 'loadingProspectList',
            },
            onError: {
              actions: 'addCreateErrorToast',
              target: 'confirmingCreate',
            },
          },
        },

        renaming: {
          on: {
            CANCEL: {
              actions: 'clearName',
              target: 'configuring',
            },
            SAVE: {
              target: 'saving',
            },
            UPDATE_NAME: {
              actions: 'setNameEdit',
            },
          },
        },

        confirmingDelete: {
          on: {
            CANCEL: {
              target: 'configuring',
            },
            DELETE: {
              target: 'deleting',
            },
          },
        },

        deleting: {
          invoke: {
            src: 'destroyProspectList',
            id: 'deleting',
            onDone: {
              actions: ['addDeleteSuccessToast', 'navigateToProspectRoot'],
              target: 'loading',
            },
            onError: {
              actions: 'addDeleteErrorToast',
              target: 'confirmingDelete',
            },
          },
        },

        confirming: {
          on: {
            CANCEL: {
              target: 'configuring',
            },
            EXPORT: {
              target: 'exporting',
            },
          },
        },

        creatingExport: {
          invoke: {
            src: 'createProspectListExport',
            id: 'creatingProspectListExport',
            onDone: [
              {
                target: 'configuring',
                cond: 'isOverQuotaCreate',
                actions: 'addCreateOverQuotaToast',
              },
              {
                target: 'exporting',
              },
            ],
            onError: {
              actions: 'addCreateExportErrorToast',
              target: 'configuring',
            },
          },
        },

        exporting: {
          invoke: {
            src: 'fetchProspectListExports',
            id: 'fetchingProspectListExports',
            onDone: {
              target: 'exportHistory',
              actions: ['setProspectListExports', 'clearSelections'],
            },
            onError: {
              target: 'error',
            },
          },
          on: {
            CANCEL: {
              target: 'configuring',
            },
            DOWNLOAD_EXPORT: {
              target: 'downloadingExport',
            },
          },
        },

        /**
         * @todo we should combine exportIdle and exportHistory with the active
         * tab stored in context.
         */
        exportIdle: {
          on: {
            CANCEL_EXPORT: {
              target: 'cancelingExport',
            },
            DOWNLOAD_EXPORT: {
              target: 'downloadingExport',
            },
            CANCEL: {
              target: 'configuring',
            },
          },
        },

        exportHistory: {
          after: {
            2000: 'exporting',
          },
          on: {
            CANCEL_EXPORT: {
              target: 'cancelingExport',
            },
            DOWNLOAD_EXPORT: {
              target: 'downloadingExport',
            },
            CANCEL: {
              target: 'configuring',
            },
          },
        },

        configuringSalesforceExport: {
          on: {
            SAVE_SALESFORCE_EXPORT: {
              target: 'exportingSalesforce',
            },
            CANCEL: {
              target: 'configuring',
            },
          },
        },

        exportingSalesforce: {
          invoke: {
            src: 'createProspectListSalesforceExport',
            id: 'creatingProspectListSalesforceExport',
            onDone: {
              target: 'exporting',
            },
            onError: {
              target: 'error',
            },
          },
        },

        configuringHubspotExport: {
          on: {
            SAVE_HUBSPOT_EXPORT: {
              target: 'exportingHubspot',
            },
            CANCEL: {
              target: 'configuring',
            },
          },
        },

        exportingHubspot: {
          invoke: {
            src: 'createProspectListHubspotExport',
            id: 'creatingProspectListHubspotExport',
            onDone: {
              target: 'exporting',
            },
            onError: {
              target: 'error',
            },
          },
        },

        cancelingExport: {
          invoke: {
            src: 'cancelProspectListExport',
            id: 'cancelingProspectListExports',
            onDone: {
              target: 'exporting',
            },
            onError: {
              target: 'error',
            },
          },
        },

        downloadingExport: {
          invoke: {
            src: 'downloadProspectListExport',
            id: 'downloadingProspectListExports',
            onDone: [
              {
                target: 'downloading',
                cond: 'isOverQuotaDownload',
                actions: 'addDownloadOverQuotaToast',
              },
              {
                target: 'downloading',
              },
            ],
            onError: {
              target: 'error',
            },
          },
        },

        downloading: {
          on: {
            CANCEL: {
              target: 'configuring',
            },
            DOWNLOAD_COMPLETE: {
              target: 'exportHistory',
            },
          },
        },

        finished: {
          on: {
            RESTART: {
              target: 'loading',
            },
          },
        },
      },
      schema: machineSchema,
      predictableActionArguments: true,
      preserveActionOrder: true,
    },
    {
      actions: {
        changeProspectList: assign((_context, event: any) => {
          return {
            loading: true,
            loadingPage: true,
            id: event.id,
          };
        }),
        createNewProspectList: () => {
          history.push({
            pathname: '/prospector/',
            search: '?new=true',
          });
        },
        updateQuery: assign((_context, event: UpdateQueryEvent) => {
          if (event.queryType === 'person') {
            return {
              currentPage: 1,
              loadingPage: true,
              personQuery: event.value,
            };
          } else if (event.queryType === 'company') {
            return {
              currentPage: 1,
              loadingPage: true,
              companyQuery: event.value,
            };
          } else {
            throw new Error('Invalid query type');
          }
        }),
        setNameEdit: assign((_context, event: UpdateNameEvent) => {
          return {
            nameEdit: event.value,
          };
        }),
        clearName: assign((_context) => {
          return {
            nameEdit: '',
          };
        }),
        toggleShowFilterSidebar: assign((context) => {
          return {
            showFilterSidebar: !context.showFilterSidebar,
            isFilterPreviewOpen:
              context.preferFilterPreviewOpen &&
              context.showFilterSidebar === true,
          };
        }),
        toggleFilterPreview: assign((context) => {
          return {
            isFilterPreviewOpen: !context.isFilterPreviewOpen,
            preferFilterPreviewOpen: !context.preferFilterPreviewOpen,
          };
        }),
        discardChanges: assign((context) => {
          if (!context.id) {
            history.push('/prospector');

            return {
              personQuery: {},
              companyQuery: {},
              isNewList: false,
            };
          }

          return {
            isNewList: false,
          };
        }),
        setNextPage: assign((context) => {
          return {
            loadingPage: true,
            selectAllPage: false,
            currentPage: context.currentPage + 1,
          };
        }),
        setPreviousPage: assign((context) => {
          return {
            loadingPage: true,
            selectAllPage: false,
            currentPage: context.currentPage - 1,
          };
        }),
        selectProspect: assign((context, event: any) => {
          const prospectIds = context.prospects.map((prospect) => prospect.id);

          if (context.selectAll) {
            return {
              selectAllPage: false,
              deselections: context.deselections.filter(
                (id) => id !== event.id,
              ),
            };
          } else {
            const selections = uniq([...context.selections, event.id]);

            return {
              selectAllPage: isEqual(prospectIds, selections),
              selections,
            };
          }
        }),
        deselectProspect: assign((context, event: any) => {
          if (context.selectAll) {
            return {
              selectAllPage: false,
              deselections: uniq([...context.deselections, event.id]),
            };
          } else {
            return {
              selectAllPage: false,
              selections: context.selections.filter((id) => id !== event.id),
            };
          }
        }),
        selectAll: assign((_context) => {
          return {
            selectAll: true,
            selections: [],
            deselections: [],
          };
        }),
        deselectAll: assign((_context) => {
          return {
            selectAll: false,
            selectAllPage: false,
            selections: [],
            deselections: [],
          };
        }),
        selectAllPage: assign((context) => {
          const selections = context.prospects.map((prospect) => prospect.id);

          if (context.selectAll) {
            return {
              selectAllPage: false,
              deselections: context.deselections.filter(
                (id) => !selections.includes(id),
              ),
            };
          } else {
            const allSelections = uniq([...context.selections, ...selections]);

            return {
              selectAllPage: true,
              selectedProspectsCount: allSelections.length,
              selections: allSelections,
            };
          }
        }),
        clearSelections: assign((_context) => {
          return {
            selectAll: false,
            selectAllPage: false,
            selections: [],
            deselections: [],
            selectedProspectsCount: 0,
          };
        }),
        updateSelectedProspectsCount: assign((context) => {
          return {
            selectedProspectsCount: context.selectAll
              ? context.totalProspects - context.deselections.length
              : context.selections.length,
          };
        }),
        // TODO: Properly type this based on gql query
        setProspects: assign((context, event: any) => {
          const totalProspects = event.data.prospects.totalCount;
          const totalCompanies = event.data.prospects.totalCompanies;
          const totalPages = Math.ceil(totalProspects / context.perPage) || 1;

          const currentPage =
            context.currentPage > totalPages ? totalPages : context.currentPage;

          const pageStart = (currentPage - 1) * context.perPage + 1;
          const pageEnd = pageStart + context.perPage - 1;

          return {
            currentPage,
            hasNextPage:
              context.currentPage < totalPages &&
              context.currentPage < PROSPECTS_PAGE_LIMIT,
            hasPreviousPage: context.currentPage > 1,
            loadingPage: false,
            pageEnd: pageEnd > totalProspects ? totalProspects : pageEnd,
            pageStart: totalProspects === 0 ? 0 : pageStart,
            previousTotalCompanies: context.totalCompanies,
            previousTotalProspects: context.totalProspects,
            prospects: event.data.prospects.nodes,
            showFilterSidebar: context.isNewList || context.showFilterSidebar,
            totalCompanies,
            totalPages,
            totalProspects,
          };
        }),
        setProspectList: assign((context, event: any) => {
          const prospectListQuery = event.data.prospectList.query ?? {};

          return {
            currentPage: 1,
            loadingPage: true,
            name: event.data.prospectList.name ?? 'New Prospect List',
            prospectList: event.data.prospectList,
            personQuery: {
              countries: prospectListQuery.countries,
              names: prospectListQuery.names,
              roles: prospectListQuery.roles,
              seniorities: prospectListQuery.seniorities,
              titles: prospectListQuery.titles,
              cities: prospectListQuery.cities,
              states: prospectListQuery.states,
            },
            companyQuery: {
              domains: prospectListQuery.domains,
              companyCountries: prospectListQuery.companyCountries,
              companyStates: prospectListQuery.companyStates,
              employeesRanges: prospectListQuery.employeesRanges,
              companyTags: prospectListQuery.companyTags,
              companyTech: prospectListQuery.companyTech,
              industries: prospectListQuery.industries,
              companyCities: prospectListQuery.companyCities,
            },
          };
        }),
        setProspectLists: assign((_context, event: any) => {
          return {
            prospectLists: event.data.prospectLists.nodes,
          };
        }),
        setProspectListExports: assign((_context, event: any) => {
          return {
            loadingExports: false,
            prospectListExportsHistory: event.data.purchasedExports.nodes,
            prospectListExportsPending: event.data.pendingExports.nodes,
          };
        }),
        setProspectFilters: assign((_context, event: any) => {
          return {
            prospectFilters: event.data.prospectFilters,
          };
        }),
        setNewProspectList: assign((_context, event: any) => {
          return {
            id: event.data.createProspectList.node.id,
          };
        }),
        navigateToProspectList: (_context, event: any) => {
          history.push(
            `/prospector/list/${event.data.createProspectList.node.id}`,
          );
        },
        navigateToProspectRoot: assign((_context, _event: any) => {
          history.push('/prospector');

          return {
            isNewList: false,
          };
        }),
        addQueryErrorToast: assign((context, event: any) => {
          if (event.data.message === EMAIL_PROVIDER_ERROR_MESSAGE) {
            context.addErrorToast({
              heading: 'Error fetching prospects',
              description: EMAIL_PROVIDER_ERROR_MESSAGE,
            });
          } else if (event.data.message === DOMAIN_LIMIT_ERROR_MESSAGE) {
            context.addErrorToast({
              heading: 'Error fetching prospects',
              description: DOMAIN_LIMIT_ERROR_MESSAGE,
            });
          }

          return {
            loadingPage: false,
          };
        }),
        addSaveSuccessToast: (context) => {
          context.addSuccessToast({
            heading: 'Prospect list saved',
            description: `You saved ${context.nameEdit || context.name}`,
          });
        },
        addSaveErrorToast: (context) => {
          context.addErrorToast({
            heading: 'Error saving prospect list',
            description: `We couldn't save ${context.nameEdit || context.name}`,
          });
        },
        addCreateSuccessToast: (context) => {
          context.addSuccessToast({
            heading: 'Prospect list created',
            description: `You created ${context.nameEdit}`,
          });
        },
        addCreateErrorToast: (context) => {
          context.addErrorToast({
            heading: 'Error creating prospect list',
            description: `We couldn't create ${context.nameEdit}`,
          });
        },
        addCreateExportErrorToast: (context) => {
          context.addErrorToast({
            heading: 'Error creating export list',
            description: `We couldn't create an export for ${context.name ||
              'these prospects'}`,
          });
        },
        addDeleteSuccessToast: (context) => {
          context.addSuccessToast({
            heading: 'Prospect list deleted',
            description: `You deleted ${context.name}`,
          });
        },
        addDeleteErrorToast: (context) => {
          context.addErrorToast({
            heading: 'Error deleting prospect list',
            description: `We couldn't delete ${context.name}`,
          });
        },
        addCreateOverQuotaToast: (context) => {
          context.addErrorToast({
            heading: 'Unable to export prospect list',
            description:
              'You are over your export quota for this billing period',
          });
        },
        addDownloadOverQuotaToast: (context) => {
          context.addErrorToast({
            heading: 'Unable to download prospect list',
            description:
              'You are over your download quota for this billing period',
          });
        },
      },
      services: {
        createProspectListExport,
        createProspectListSalesforceExport,
        createProspectListHubspotExport,
        cancelProspectListExport,
        downloadProspectListExport,
        fetchProspects,
        fetchProspectList,
        fetchProspectLists,
        fetchProspectListExports,
        fetchProspectFilters,
        updateProspectList,
        createProspectList,
        //        copyProspectList,
        destroyProspectList,
      },
      guards: {
        hasNextPage: (context) => {
          return (
            context.currentPage < context.totalPages &&
            context.currentPage < PROSPECTS_PAGE_LIMIT
          );
        },
        hasPreviousPage: (context) => {
          return context.currentPage > 1;
        },
        hasProspectList: (context) => {
          return !!context.id;
        },
        isOverQuotaCreate: (_context, event: any) => {
          const errors = event.data.createProspectListExport.errors;

          if (!errors?.length) {
            return false;
          }

          const error = errors[0];
          return error.messages.includes(OVER_QUOTA_ERROR_MESSAGE);
        },
        isOverQuotaDownload: (_context, event: any) => {
          const errors = event.data.downloadProspectListExport.errors;

          if (!errors?.length) {
            return false;
          }

          const error = errors[0];
          return error.messages.includes(OVER_QUOTA_ERROR_MESSAGE);
        },
        isProspectQueryError: (_context, event: any) => {
          return (
            event.data.message === EMAIL_PROVIDER_ERROR_MESSAGE ||
            event.data.message === DOMAIN_LIMIT_ERROR_MESSAGE
          );
        },
      },
      delays: {},
    },
  );
