import { useQuery } from 'react-query';

import request from '~/lib/client/request';
import { User } from '~/models/users';

function fetchAccount() {
  return request({
    path: `/v1/accounts/current`,
  });
}

function fetchCurrentUser() {
  return request({
    path: `/v1/users/current`,
  });
}

function fetchAccounts() {
  return request({
    path: `/v1/accounts`,
  });
}

async function fetchUserData() {
  const account = await fetchAccount();
  const accounts = await fetchAccounts();
  const user = await fetchCurrentUser();

  return new User({ account, accounts, user });
}

export default function useCurrentUser(options = {}) {
  return useQuery('user', fetchUserData, options);
}
