import { ErrorBanner, ErrorBannerProps } from '~/components/ErrorBanner';

const JavascriptError: React.FC<ErrorBannerProps> = ({
  refetch = window.location.reload,
  ...rest
}) => {
  return <ErrorBanner {...rest} isJavaScript refetch={refetch} />;
};

export default JavascriptError;
