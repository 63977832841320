import { routes } from '~/components/app/appMainRoutes';

import { useHomeIntegrations } from '../hooks/useHomeIntegrations';
import { ItemConnectCRM } from './ItemConnectCRM';
import { OnboardingChecklist } from './OnboardingChecklist';
import { OnboardingItem, OnboardingItemDivider } from './OnboardingItem';

export const ListTargetMarkets = () => {
  const {
    hasTargetMarketAddedCompanies,
    hasTargetMarketDefinedIcp,
    loadingTargetMarkets,
  } = useHomeIntegrations();

  /**
   * @todo Show add companies item when we add the functionality
   */

  const isLoading = loadingTargetMarkets;

  return (
    <OnboardingChecklist title="Get started with Target Markets">
      <OnboardingItem
        isComplete={hasTargetMarketDefinedIcp}
        isLoading={isLoading}
        number={1}
        title="Define your ICP"
        to={routes.targetMarkets}
      />
      <OnboardingItemDivider />
      <ItemConnectCRM number={2} />
      <OnboardingItem
        isComplete={hasTargetMarketAddedCompanies}
        isDisabled={!hasTargetMarketDefinedIcp}
        isHidden
        isLoading={isLoading}
        number={3}
        title="Add companies in your market to your CRM"
        to={routes.targetMarkets}
      />
    </OnboardingChecklist>
  );
};
