import { useState } from 'react';

import { useImpersonationWarningQuery } from '~/generated/graphql';

function isImpersonatingUser(email: string) {
  const impersonationCookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith(`clearbit.last_impersonation_admin=`));

  if (!impersonationCookie) {
    return false;
  }

  const adminEmail = decodeURIComponent(impersonationCookie.split('=')[1]);

  return email !== adminEmail;
}

export default function ImpersonationWarning() {
  const [isLegacyImpersonated, setIsLegacyImpersonated] = useState<boolean>(
    false,
  );
  const { loading, data } = useImpersonationWarningQuery({
    onCompleted: ({ viewer }) => {
      if (
        viewer.isImpersonating === undefined ||
        viewer.isImpersonating === null
      ) {
        setIsLegacyImpersonated(isImpersonatingUser(viewer.user.email));
      } else {
        setIsLegacyImpersonated(viewer.isImpersonating);
      }
    },
  });

  if (loading || !data?.viewer) {
    return null;
  }

  if (
    data.viewer.isImpersonating === undefined ||
    data.viewer.isImpersonating === null
  ) {
    if (!isLegacyImpersonated) {
      return null;
    }

    return (
      <div className="absolute top-0 left-0 right-0 z-50 p-1 text-center text-white bg-red-400 h-7">
        Warning: Logged in with a customer account. Use your powers wisely.
        <a
          className="ml-2 font-semibold text-white underline"
          href="/logout?impersonation=true"
        >
          Sign out of customer account
        </a>
      </div>
    );
  } else {
    if (data.viewer.isImpersonating === false) {
      return null;
    }

    return (
      <div className="absolute top-0 left-0 right-0 z-50 p-1 text-center text-white bg-red-400 h-7">
        Warning: Logged into a customer account. Use your powers wisely.
        <a
          className="ml-2 font-semibold text-white underline"
          href={`/auth/end_impersonation`}
        >
          Sign out of customer account
        </a>
      </div>
    );
  }
}
