import { ArrowRight } from '@clearkit/icons';
import classnames from 'classnames';
import { CKContainerProps, CKLayoutHorizontal } from 'clearkit';
import { FC } from 'react';

import {
  CRMExportFieldWrapper,
  inputDisplayName,
  labelDisplayName,
} from './CRMExportFieldWrapper';

type SalesforceDefaultMappingComposition = {
  Label: FC<CKContainerProps>;
  Value: FC<CKContainerProps>;
};

export const SalesforceDefaultMapping: FC<CKContainerProps> &
  SalesforceDefaultMappingComposition = ({ children, ...rest }) => {
  return (
    <CRMExportFieldWrapper {...rest}>
      <CRMExportFieldWrapper.IconSalesforce />
      {children}
    </CRMExportFieldWrapper>
  );
};

SalesforceDefaultMapping.Label = ({ className, ...rest }) => (
  <CRMExportFieldWrapper.Label
    {...rest}
    className={classnames(className, 'self-start')}
  />
);
SalesforceDefaultMapping.Label.displayName = labelDisplayName;

SalesforceDefaultMapping.Value = ({ children, className, ...rest }) => (
  <CKLayoutHorizontal
    alignItems="start"
    className={classnames(className, 'self-start')}
    gap="4rem"
    justifyContent="start"
    {...rest}
  >
    <ArrowRight className="text-gray" />
    <span>{children}</span>
  </CKLayoutHorizontal>
);
SalesforceDefaultMapping.Value.displayName = inputDisplayName;
