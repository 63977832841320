import compact from 'lodash/compact';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import some from 'lodash/some';

export const states = {
  ON: 1,
  OFF: 2,
  SOME: 3,
  GREY: 4,
};

// Get the indent level fro an option

export function levelForOption(option, options) {
  let result = -1;
  const recurse = (options, level) => {
    if (result !== -1) return;

    if (includes(options, option)) {
      result = level;
    }

    options
      .filter((o) => o.options)
      .forEach((subOption) => {
        recurse(subOption.options, level + 1);
      });
  };
  recurse(options, 0);
  return result;
}

// Check / uncheck

export function checkOption(options, selectedOptions, option) {
  if (includes(selectedOptions, option)) return;

  let newSelectedOptions = [...selectedOptions, option];
  newSelectedOptions = rollup(options, newSelectedOptions);
  return compact(newSelectedOptions);
}

export function uncheckOption(options, selectedOptions, option) {
  // Remove the clicked option
  let newSelectedOptions = selectedOptions.filter((o) => !isEqual(o, option));

  const parent = findParent(option, options);
  const parentState = getSelectedState(parent, options, selectedOptions);

  const allSiblingsAreSelected =
    parentState === states.GREY || parentState === states.ON;

  if (allSiblingsAreSelected) {
    newSelectedOptions = uncheckOption(options, selectedOptions, parent);
    const otherSiblings = parent.options.filter((o) => !isEqual(o, option));
    newSelectedOptions = [...newSelectedOptions, ...otherSiblings];
  }

  return newSelectedOptions;
}

// Turn value array into selected options array
// TODO: Fix this so it returns the results in the correct order

export function getSelectedOptions(options, values, getOptionValue) {
  let results = [];

  const recurse = (options) => {
    let result = values.map((value) => {
      return options.find((option) => {
        const optionValue = getOptionValue(option);
        delete optionValue.toString;
        return isEqual(optionValue, value);
      });
    });

    options
      .filter((option) => option.options)
      .forEach((option) => {
        recurse(option.options);
      });

    results = results.concat(result);
  };

  recurse(options);

  results = compact(results);

  // Sort results by values.
  // TODO: Refactor this whole function
  // results = values.map((value) =>
  //   results.find((result) => getOptionValue(result) === getOptionValue(value)),
  // );

  return results;
}

// Roll up selected state to parents

export function rollup(options, values) {
  options.forEach((option) => {
    if (option.options && option.options.length > 0) {
      values = [...rollup(option.options, values)];
      if (areAllOptionsSelected(option, values)) {
        option.options.forEach((subOption) => {
          values = values.filter((v) => !isEqual(v, subOption));
        });
        if (!some(values, option)) {
          values = [...values, option];
        }
      }
    }
  });

  return values;
}

// Make a big array of options for the nested select

export function flattenOptions(options, sectionsOpen) {
  const result = [];
  let level = 0;
  const recurse = (options, level) => {
    if (!options) return;
    options.forEach((option) => {
      result.push(option);
      if (option.options && (!sectionsOpen || some(sectionsOpen, option))) {
        recurse(option.options, level + 1);
      }
    });
  };
  recurse(options, level);
  return result;
}

export function findParent(needle, haystack) {
  let result = false;
  haystack.forEach((option) => {
    if (!option.options) return false;
    if (option.options.indexOf(needle) > -1) {
      result = option;
      return result;
    }
    const descendant = findParent(needle, option.options);
    if (descendant) {
      result = descendant;
      return;
    }
  });
  return result;
}

export function areAllOptionsSelected(option, values) {
  if (values.indexOf(option) > -1) return true;

  let allChildrenSelected = true;

  const recurse = (options) => {
    options.forEach((child) => {
      if (child.options && child.options.length === 0) {
        const selected = includes(values, child);
        if (!selected) {
          allChildrenSelected = false;
        }
        return selected;
      }

      // if (values.indexOf(child) == -1) {
      if (!includes(values, child)) {
        if (child.options) return recurse(child.options);
        allChildrenSelected = false;
        return false;
      }
    });
  };

  recurse(option.options);

  return allChildrenSelected;
}

export function areSomeOptionsSelected(option, options, values) {
  if (!option.options) return false;
  if (option.options.length === 0) return false;
  return option.options.find((o) => {
    if (includes(values, o)) return true;
    return areSomeOptionsSelected(o, options, values);
  });
}

export function getSelectedState(option, options, selectedOptions) {
  if (find(selectedOptions, option)) {
    return states.ON;
  }

  const parent = findParent(option, options);

  const hasOptions = option.options && option.options.length > 0;

  if (hasOptions) {
    if (areAllOptionsSelected(option, selectedOptions)) {
      return states.ON;
    }
    if (areSomeOptionsSelected(option, options, selectedOptions)) {
      return states.SOME;
    }
  }

  if (
    parent &&
    getSelectedState(parent, options, selectedOptions) === states.GREY
  ) {
    return states.GREY;
  }

  // const selectedValues = values;
  if (parent && includes(selectedOptions, parent)) {
    return states.GREY;
  }

  const optionValue = option;

  if (!includes(selectedOptions, optionValue)) {
    return states.OFF;
  }
  return states.ON;
}

export function matchValue(value, inputValue) {
  let match = null;

  try {
    match = value.toLowerCase().match(inputValue.trim().toLowerCase());
  } catch (e) {
    return null;
  }

  return match;
}

// Test utils

export function getOptionAndChildrenAsArray(option, result = []) {
  if (option.options) {
    const children = option.options.map((o) =>
      getOptionAndChildrenAsArray(o, result),
    );

    result = [option, ...children];
  } else {
    result = [option, ...result];
  }
  return flatten(result);
}

export function getRecursiveChildren(option, values = []) {
  if (!option.options) return values;
  option.options.forEach((subOption) => {
    values = [...values, subOption, ...getRecursiveChildren(subOption, values)];
  });
  return values;
}
