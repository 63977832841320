import { useEffect, useRef } from 'react';

import { PageLayout } from '~/components/PageLayout';

import { PageWrapper } from '../PageWrapper';
import { useProspector } from '../useProspector';
import { List, ListFooter, ListHeader } from '.';
import { EmptyStateFilters } from './EmptyStateFilters';

export function ProspectListBody() {
  const [state, send] = useProspector();

  const {
    companyQuery,
    deselections,
    hasPreviousPage,
    hasNextPage,
    isFilterPreviewOpen,
    loadingPage,
    pageEnd,
    pageStart,
    personQuery,
    previousTotalCompanies,
    previousTotalProspects,
    prospects,
    prospectFilters,
    selectedProspectsCount,
    totalCompanies,
    totalProspects,
  } = state.context;

  const handleDeselectAll = () => send('DESELECT_ALL');
  const handleDeselectItem = (id: string) => send('DESELECT_PROSPECT', { id });
  const handleCreateExport = () => send('CREATE_CSV_EXPORT');
  const handleCreateExportSalesforce = () => send('CREATE_SALESFORCE_EXPORT');
  const handleCreateExportHubspot = () => send('CREATE_HUBSPOT_EXPORT');
  const handleExportDrawerToggle = () => send('TOGGLE_EXPORT_DRAWER_PENDING');
  const handleSelectAll = () => send('SELECT_ALL');
  const handleSelectAllPage = () => send('SELECT_ALL_PAGE');
  const handleSelectItem = (id: string) => send('SELECT_PROSPECT', { id });

  const bodyRef = useRef<HTMLDivElement>(null);

  const selectAllChecked: boolean =
    state.context.selectAll || state.context.selectAllPage;

  const selectedProspects = selectAllChecked
    ? prospects
        .map((prospect: any) => prospect.id)
        .filter((id: string) => !deselections.includes(id))
    : state.context.selections;

  useEffect(() => {
    if (bodyRef.current) {
      bodyRef.current.scrollTop = 0;
    }
  }, [loadingPage, pageEnd, pageStart]);

  const hasItems = prospects.length > 0;
  const isLoading = state.matches('loading');
  const showLoading = loadingPage || isLoading;

  /**
   * @todo Reenable this when we have pull list of exports
   */

  // const hasInitializingExports =
  //   prospectListExportsPending.filter(
  //     (exportItem: any) => exportItem.status === 'initializing',
  //   ).length > 0;

  const hasInitializingExports = false;

  const isCreatingExport = state.matches('creatingExport');

  return (
    <PageWrapper>
      <ListHeader
        companyQuery={companyQuery}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        isFilterPreviewOpen={isFilterPreviewOpen}
        onNextPage={() => send('NEXT_PAGE')}
        onPreviousPage={() => send('PREVIOUS_PAGE')}
        onToggleFilterPreview={() => send('TOGGLE_FILTER_PREVIEW')}
        onToggleFilterSidebar={() => send('TOGGLE_FILTER_SIDEBAR')}
        pageEnd={pageEnd}
        pageStart={pageStart}
        personQuery={personQuery}
        previousTotalCompanies={previousTotalCompanies}
        previousTotalProspects={previousTotalProspects}
        prospectFilters={prospectFilters}
        totalCompanies={totalCompanies}
        totalProspects={totalProspects}
      />
      <PageLayout.Body ref={bodyRef}>
        <PageLayout.Content className="min-h-full py-4 space-y-4 xl:py-6 grid">
          {hasItems || showLoading ? (
            <List
              isLoading={showLoading}
              onDeselectItem={handleDeselectItem}
              onSelectItem={handleSelectItem}
              prospects={prospects}
              selectedProspects={selectedProspects}
            />
          ) : (
            <EmptyStateFilters className="h-full" />
          )}
        </PageLayout.Content>
      </PageLayout.Body>
      <ListFooter
        hasInitializingExports={hasInitializingExports}
        isCreatingExport={isCreatingExport}
        onDeselectAll={handleDeselectAll}
        onExport={handleCreateExport}
        onExportDrawerToggle={handleExportDrawerToggle}
        onExportHubspot={handleCreateExportHubspot}
        onExportSalesforce={handleCreateExportSalesforce}
        onSelectAll={handleSelectAll}
        onSelectAllPage={handleSelectAllPage}
        selectAllChecked={selectAllChecked}
        selectedProspectsCount={selectedProspectsCount}
        totalProspects={totalProspects}
      />
    </PageWrapper>
  );
}
