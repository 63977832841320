import { CKButton } from 'clearkit';

import TypeToggle from '../TypeToggle';
import Production from './Production.svg';
import Sandbox from './Sandbox.svg';
import styles from './styles';

const types = ['salesforce', 'salesforcesandbox'];
const learnMoreLink =
  'https://help.clearbit.com/hc/en-us/articles/360010028354-Syncing-your-Salesforce-data-with-Clearbit';

const SalesforceConnectionForm = ({
  classes,
  allowSkip,
  onAuth,
  onSkip,
  className,
}) => {
  const [type, setType] = useState(0);

  return (
    <div className={className}>
      <div className={classes.description}>
        <span>
          Sync your sales data with Clearbit to instantly build powerful sales
          and marketing workflows.{' '}
          <a className={classes.link} href={learnMoreLink}>
            Learn more
          </a>
          .
        </span>
      </div>
      <hr className={classes.break} />
      <p className={classes.headline}>
        Choose which Salesforce environment you want to connect to.
      </p>
      <TypeToggle
        className={classes.typeToggle}
        onChange={(_, value) => {
          setType(value);
        }}
        TypeOneLogo={Production}
        typeOneText="Production"
        TypeTwoLogo={Sandbox}
        typeTwoText="Sandbox"
        value={type}
      />
      <div className={classes.buttons}>
        <CKButton
          onClick={() => onAuth(types[type])}
          variant="bold"
          variantColor="blue"
        >
          <span>Connect Salesforce</span>
        </CKButton>
        {allowSkip && (
          <CKButton onClick={onSkip} variant="secondary">
            <span>I don&apos;t use Salesforce</span>
          </CKButton>
        )}
      </div>
    </div>
  );
};

SalesforceConnectionForm.propTypes = {
  allowSkip: PropTypes.bool,
  classes: PropTypes.shape({}),
  className: PropTypes.string,
  onAuth: PropTypes.func,
  onSkip: PropTypes.func,
};

SalesforceConnectionForm.defaultProps = {
  allowSkip: false,
  onAuth: () => {},
  onSkip: () => {},
  classes: {},
};

export default withStyles(styles)(SalesforceConnectionForm);
