import classnames from 'classnames';
import {
  CKContainerProps,
  CKIconBox,
  CKLayoutHorizontal,
  cloneChildren,
  excludeChildrenByDisplayName,
  includeChildrenByDisplayName,
} from 'clearkit';
import { FC } from 'react';

import { PageLayout } from '~/components/PageLayout';

type EmptyStateComposition = {
  Icon: FC<CKContainerProps>;
  Title: FC<CKContainerProps>;
  Description: FC<CKContainerProps>;
};

const iconDisplayName = 'EmptyState.Icon';

export const EmptyState: FC<CKContainerProps> & EmptyStateComposition = ({
  children,
  className,
  ...rest
}) => (
  <PageLayout.Content
    {...rest}
    className={classnames(
      'flex items-center justify-center h-full text-center space-y-6',
      className,
    )}
  >
    <div className="max-w-[60ch] space-y-4">
      <CKLayoutHorizontal justifyContent="center">
        {includeChildrenByDisplayName({
          children,
          componentDisplayName: iconDisplayName,
        })}
      </CKLayoutHorizontal>
      <div>
        {excludeChildrenByDisplayName({
          children,
          componentDisplayName: iconDisplayName,
        })}
      </div>
    </div>
  </PageLayout.Content>
);

EmptyState.Icon = ({ children, ...rest }) => (
  <CKIconBox {...rest}>
    {cloneChildren({
      children,
      newProps: {
        className: 'w-8 h-8',
      },
    })}
  </CKIconBox>
);
EmptyState.Icon.displayName = iconDisplayName;

EmptyState.Description = ({ className, ...rest }) => (
  <div {...rest} className={`text-gray-600 ${className}`} />
);
EmptyState.Description.displayName = 'EmptyState.Description';

EmptyState.Title = ({ children, className, ...rest }) => (
  <h1 {...rest} className={classnames('mb-2 text-lg font-medium', className)}>
    {children}
  </h1>
);
EmptyState.Title.displayName = 'EmptyState.Title';
