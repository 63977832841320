const styles = () => ({
  newCollectionButton: {
    display: 'flex',
    color: 'rgba(255, 255, 255, .5)',
    flex: '0 0 auto',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '29px',
    paddingLeft: 4,
    width: '100%',
    borderRadius: 6,
    transition: 'color, ease .2s, background-color ease .2s',

    '&:focus, &:hover': {
      color: '#fff',
      backgroundColor: 'rgba(255, 255, 255, .1)',

      '& $newCollectionIcon': {
        opacity: 1,
      },
    },

    '& span': {
      paddingTop: 1,
    },
  },

  newCollectionInput: {
    width: '100%',

    '& input': {
      width: '100%',

      '&.ck-field-single': {
        paddingLeft: 38,
      },
    },

    '& .ck-field-icon': {
      height: '100%',
    },

    '& $newCollectionIcon': {
      marginLeft: 17,
    },
  },

  legacyCollectionInput: {
    '& input': {
      color: '#fff',
      height: 30,
      fontSize: '14px',
      fontWeight: 500,
      background: 'rgba(0, 0, 0, .05)',
      boxShadow:
        '0 0 0 1px rgba(39, 40, 42, .2), inset 0 2px 1px rgba(39, 40, 42, .05), 0 0 0 4px rgba(213, 118, 240, 0)',

      '&:focus': {
        background: 'rgba(0, 0, 0, .1)',
        boxShadow:
          '0 0 0 1px rgba(193, 126, 241, 1), inset 0 2px 1px rgba(39, 40, 42, .05), 0 0 0 4px rgba(213, 118, 240, .3)',

        '& ~ .ck-field-icon $newCollectionIcon': {
          fill: '#fff',
        },
      },

      '&::placeholder': {
        color: 'rgba(255, 255, 255, 0.4)',
      },

      '&::-webkit-input-placeholder': {
        color: 'rgba(255, 255, 255, 0.4)',
      },
    },
  },

  newCollectionIcon: {
    marginLeft: -6,
    marginRight: 8,
    flexShrink: 0,
    transition: 'fill ease .2s',
  },
});

export default styles;
