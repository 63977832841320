import request from './request';

export function getTagsSettings() {
  return request({
    path: `/v1/tags/settings`,
  });
}

export function updateTagsSettings(settings) {
  return request({
    path: `/v1/tags/settings`,
    type: 'PATCH',
    json: { settings },
  });
}
