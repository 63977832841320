import request from './request';

// People Columns

export function updatePersonColumns({ columns, segmentId }) {
  const path = segmentId
    ? `/v1/people/segments/${segmentId}/columns`
    : `/v1/people/columns`;

  return request({
    path,
    type: 'put',
    json: { columns },
  });
}
