import { NoResults as NoResultsIcon } from '@clearkit/icons';
import classnames from 'classnames';
import { CKSearch } from 'clearkit';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import GraphqlError from '~/components/GraphqlError';
import LoadingArea from '~/components/LoadingArea';
import Tile from '~/components/SegmentTile';
import TimeAgo from '~/components/TimeAgo';
import { useCompanySegmentsPageQuery } from '~/generated/graphql';
import { paginateData } from '~/lib/graphql/pagination';
import paths from '~/lib/paths';

type CompanySegmentsProps = {
  className?: string;
  company: {
    id: string;
  };
};

const CompanySegments = ({
  className,
  company: { id },
}: CompanySegmentsProps) => {
  const [search, setSearch] = useState('');

  const { data, error, loading, refetch } = useCompanySegmentsPageQuery({
    variables: { id },
    returnPartialData: true,
  });

  if (!data?.company?.segments && loading) return <LoadingArea loading />;
  if (error || !data) return <GraphqlError error={error} refetch={refetch} />;

  const { company } = data;
  const segments = paginateData(company, { fields: ['segments'] })?.segments;

  let filteredSegments = segments;
  if (search) {
    filteredSegments = segments.filter((segment: any) =>
      segment.name.toLowerCase().includes(search.toLowerCase()),
    );
  }

  return (
    <div
      className={classnames(
        'flex flex-col flex-grow flex-shrink min-h-0 h-full bg-gray-0',
        className,
      )}
    >
      <GraphqlError className="mb-4" error={error} refetch={refetch} />
      <div className="flex flex-col flex-shrink min-h-0 px-4 py-6">
        <div className="px-4">
          {segments?.length === 0 ? (
            <div className="flex flex-col items-center pt-8 mx-auto text-center w-xs">
              <NoResultsIcon className="text-gray-200" height={40} width={40} />
              <p className="mt-3 mb-1 text-base text-gray-600">
                This company doesn't appear in any of your audiences.
              </p>
            </div>
          ) : (
            <CKSearch
              className="mb-5"
              onChange={setSearch}
              placeholder="Search for a segment"
              value={search}
            />
          )}
        </div>

        <div className="flex flex-col flex-shrink min-h-0 px-4 py-1 overflow-auto">
          {filteredSegments?.map((segment: any) => {
            const destinationsAndAudiencesCount =
              segment.destinationsCount + segment.audiences.totalCount;

            return (
              <Link
                className="flex flex-row p-4 mb-4 text-gray-700 bg-white rounded-md ck-box"
                key={segment.id}
                to={paths.segment(segment.type, segment.id)}
              >
                <Tile className="mr-4" type={segment.type} />

                <div className="flex flex-col">
                  <span className="font-semibold">{segment.name}</span>
                  <span>{segment.collection.name}</span>
                </div>

                <div className="flex flex-col ml-auto text-sm leading-relaxed text-right">
                  {destinationsAndAudiencesCount > 0 && (
                    <span>{destinationsAndAudiencesCount} destinations</span>
                  )}
                  <span>
                    Updated <TimeAgo time={segment.updatedAt} />
                  </span>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CompanySegments;
