import { Custom } from '@clearkit/icons';
import { CKContainerProps } from 'clearkit';
import { ReactNode } from 'react';

import { HomeHeading } from '../HomeHeading';

type OnboardingChecklistProps = CKContainerProps & {
  title: ReactNode;
};

export const OnboardingChecklist = ({
  children,
  title,
  ...rest
}: OnboardingChecklistProps) => {
  return (
    <section {...rest}>
      <header className="mb-4">
        <HomeHeading>
          <Custom /> {title}
        </HomeHeading>
      </header>
      {children}
    </section>
  );
};
