import { Redirect, Route, RouteProps } from 'react-router-dom';

import LoadingArea from '../LoadingArea';

export type GatedRouteProps = RouteProps & {
  hasAccess?: boolean;
  loading: boolean;
  fallbackPath?: string;
};

const GatedRoute = ({
  children,
  hasAccess = true,
  loading,
  fallbackPath = '/',
  ...rest
}: GatedRouteProps) => {
  if (loading) return <LoadingArea />;

  if (!hasAccess)
    return (
      <Route {...rest}>
        <Redirect to={fallbackPath} />
      </Route>
    );

  return <Route {...rest}>{children}</Route>;
};

export default GatedRoute;
