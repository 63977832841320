import { CKLayoutGrid } from 'clearkit';
import Helmet from 'react-helmet';
import { Route, useParams } from 'react-router-dom';

import { routes } from '~/components/app/appMainRoutes';
import SidebarDrawer from '~/components/SidebarDrawer';
import { useTargetMarket } from '~/components/TargetMarkets/hooks/useTargetMarket';
import history from '~/lib/history';

import CompaniesList from '../../components/TargetMarkets/CompaniesList';
import { Sidebar } from '../../components/TargetMarkets/Sidebar';
import CompanyFromDomain from '../companies/CompanyFromDomain';

type DetailsParams = {
  domain: string;
  id?: string;
};

const TargetMarketsPage = () => {
  const MainPage = () => {
    const { id } = useParams<DetailsParams>();
    const useTargetMarketProps = useTargetMarket({ id });

    return (
      <CKLayoutGrid className="w-full" gap="0" gridTemplateColumns="auto 1fr">
        <Sidebar {...useTargetMarketProps} />

        <CompaniesList {...useTargetMarketProps} />
      </CKLayoutGrid>
    );
  };

  return (
    <>
      <Helmet>
        <title>Target Markets</title>
      </Helmet>

      <Route
        exact
        path={[
          `${routes.targetMarketsDetails}/:domain`,
          `${routes.targetMarkets}/list/:id/details/:domain`,
        ]}
        render={({
          match: {
            params: { domain },
          },
        }) => (
          <>
            <MainPage />
            <SidebarDrawer
              isVisible
              onToggle={() => history.goBack()}
              size="xxl"
            >
              <CompanyFromDomain domain={domain} />
            </SidebarDrawer>
          </>
        )}
      />

      <Route exact path={`${routes.targetMarkets}/list/:id`}>
        <MainPage />
      </Route>

      <Route exact path={routes.targetMarkets}>
        <MainPage />
      </Route>
    </>
  );
};

export default TargetMarketsPage;
