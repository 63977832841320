export class User {
  state = {
    user: {},
    accounts: [],
    account: {},
  };

  constructor({ account, accounts, user }) {
    this.state.user = user;
    this.state.accounts = accounts;
    this.state.account = account;
  }

  get accounts() {
    return this.state.accounts;
  }

  get currentAccountId() {
    return this.state.user.account_id;
  }

  get currentAccount() {
    return this.state.accounts.find(
      (account) => account.id === this.currentAccountId,
    );
  }

  get hasMultipleAccounts() {
    return this.state.accounts.length > 1;
  }

  hasPermission = (permission) => {
    if (!this.state.account) return false;

    return !!this.state.account.permissions[permission];
  };

  hasFlag = (flag) => {
    if (!this.state.account) return false;

    return this.state.account.flags.indexOf(flag) > -1;
  };

  domainForAccount = (account) => {
    if (account && account.domain) {
      return account.domain;
    }

    if (this.state.user) {
      const email = this.state.user.email;
      return email.replace(/.*@/, '');
    }

    return '';
  };

  get accountDomain() {
    return this.domainForAccount(this.state.account);
  }

  get hasNoAccess() {
    return !this.hasPermission('can_access_platform');
  }

  get hasInternalAccess() {
    return this.hasFlag('x_clearbit_internal');
  }

  get hasEngagementAccess() {
    return this.hasInternalAccess || this.hasFlag('x_engagement');
  }

  get shouldShowAudiences() {
    return this.hasPermission('can_access_ads');
  }

  get shouldShowDashboard() {
    return this.hasPermission('can_access_platform');
  }

  get shouldShowSettings() {
    return this.hasPermission('can_access_platform');
  }

  get shouldShowPeople() {
    return this.hasPermission('can_access_platform');
  }

  get shouldShowCompanies() {
    return this.hasPermission('can_access_platform');
  }

  get shouldShowICP() {
    return this.hasFlag('x_fit_score');
  }

  get shouldShowDataEnrichment() {
    return this.hasFlag('x_data_enrichment');
  }

  get shouldShowDataEnrichmentLogs() {
    return this.hasFlag('x_data_enrichment_logs');
  }

  get shouldShowForms() {
    return this.hasFlag('x_unified_forms');
  }

  get firstName() {
    return this.state.user.first_name;
  }

  get email() {
    return this.state.user.email;
  }

  get id() {
    return this.state.user.id;
  }

  getshouldShowDestinationGroup = (group) => {
    const permissions = {
      ads: 'can_access_ads',
      alerting: 'can_access_alerting',
      personalization: 'can_access_personalization',
    };

    const permission = permissions[group];
    return this.hasPermission(permission);
  };

  get shouldShowSetup() {
    return this.hasPermission('can_access_platform');
  }
}
