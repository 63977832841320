import { Event as Events, View as Pageview } from '@clearkit/icons';
import { CKSearch, CKTab, CKTabList, CKTabs } from 'clearkit';

import Select from '~/components/Select';
import { useEventTypes } from '~/components/Sidebar/hooks/useEventTypes.ts';
import { PersonEventsList } from '~/components/Sidebar/PersonEventsList/EventsList.tsx';
import { PersonPageviewsList } from '~/components/Sidebar/PersonEventsList/PageviewsList.tsx';

const PersonActivity = ({ person, className }) => {
  const [type, setType] = useState();
  const [path, setPath] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const { eventTypes } = useEventTypes();

  return (
    <div className={className}>
      <CKTabs index={tabIndex} isSegmented onChange={setTabIndex}>
        <div className="flex flex-row justify-between px-8 py-6 border-b border-gray-200 align-center bg-gray-0">
          <CKTabList aria-label="My tabs">
            <CKTab icon={<Pageview />} id="page" label="Pageviews">
              Pageviews
            </CKTab>
            <CKTab icon={<Events />} id="events" label="Events">
              Events
            </CKTab>
          </CKTabList>

          {tabIndex === 0 && (
            <CKSearch
              className="flex-grow w-6/12 max-w-md"
              onChange={(value) => {
                if (value && !value.startsWith('/')) {
                  value = `/${value}`;
                }
                setPath(value);
              }}
              placeholder="Search by path..."
              value={path}
            />
          )}

          {tabIndex === 1 && (
            <Select
              className="flex-grow w-6/12 max-w-md"
              getOptionLabel={(option) => option || 'All events'}
              getOptionValue={(option) => option || 'All'}
              isDisabled={!eventTypes?.length}
              onChange={(value) => setType(value)}
              options={['', ...eventTypes]}
              placeholder="Filter by event type"
              value={[type || 'All events']}
            />
          )}
        </div>
      </CKTabs>

      <div className="flex flex-col flex-grow">
        {tabIndex === 0 && (
          <PersonPageviewsList className="px-8" path={path} person={person} />
        )}
        {tabIndex === 1 && (
          <PersonEventsList
            className="flex flex-col flex-grow px-4"
            person={person}
            type={type}
          />
        )}
      </div>
    </div>
  );
};

PersonActivity.propTypes = {
  person: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default PersonActivity;
