import { CKButton, CKModal, CKTextFieldNext } from 'clearkit';
import { useEffect, useState } from 'react';

import { ButtonContainer } from '~/components/unified/ButtonContainer';

import { TargetMarket } from '../hooks/logic';

const SaveListModal = ({
  isOpen,
  handleCancel,
  handleSave,
  selected,
  loading,
}: {
  isOpen: boolean;
  handleCancel: () => void;
  handleSave: (name: string) => void;
  selected?: TargetMarket;
  loading?: boolean;
}) => {
  const [name, setName] = useState(selected?.name || '');

  useEffect(() => {
    setName(selected?.name || '');
  }, [selected, isOpen]);

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (loading) return;

    if (event.key === 'Enter' && name) {
      handleSave(name);
    } else if (event.key === 'Escape') {
      handleCancel();
    } else {
      return;
    }
  };

  return (
    <CKModal hasHideOnEscape isVisible={isOpen} onToggle={handleCancel}>
      <CKModal.Header>
        <CKModal.Heading>Save this list</CKModal.Heading>
      </CKModal.Header>
      <CKModal.Body className="p-8 space-y-4">
        <p>
          Give this list a name and we&apos;ll save its filters so you can
          continue to discover new target accounts.
        </p>

        <CKTextFieldNext
          autoFocus
          isDisabled={loading}
          onChange={(value) => setName(value)}
          onKeyUp={handleKeyUp}
          placeholder="Name your list"
          value={name}
        />
      </CKModal.Body>

      <CKModal.Footer>
        <ButtonContainer>
          <CKButton onClick={handleCancel}>Cancel</CKButton>

          <CKButton
            isDisabled={!name || loading}
            onClick={() => handleSave(name)}
            variant="bold"
            variantColor="blue"
          >
            Save
          </CKButton>
        </ButtonContainer>
      </CKModal.Footer>
    </CKModal>
  );
};

export default SaveListModal;
