import { routes } from '~/components/app/appMainRoutes';

import { TargetMarketsCompany } from './types';

export const NEW_TARGET_MARKET_LIST = `${routes.targetMarkets}?new=true`;

export const PLACEHOLDER_COMPANY: TargetMarketsCompany = {
  id: '',
  domain: '',
  name: 'Placeholder',
  country: 'Placeholder',
  industry: 'Placeholder',
  employeesRange: 'Placeholder',
  linkedin: 'Placeholder',
  twitter: 'Placeholder',
  crunchbase: 'Placeholder',
};

export const EMPLOYEE_RANGES = [
  { label: '1-10', value: '1~10' },
  { label: '11-50', value: '11~50' },
  { label: '51-250', value: '51~250' },
  { label: '251-1K', value: '251~1000' },
  { label: '1K-5K', value: '1000~5000' },
  { label: '5K-10K', value: '5000~10000' },
  { label: '10K-50K', value: '10000~50000' },
  { label: '50K-100K', value: '50000~100000' },
  { label: '100K+', value: '100000~' },
];

export const BUSINESS_MODELS = [
  {
    label: 'B2B',
    value: 'B2B',
  },
  {
    label: 'B2C',
    value: 'B2C',
  },
];

export const COMPANY_TYPES = [
  { label: 'Education', value: 'education' },
  { label: 'Government', value: 'government' },
  { label: 'Non-profit', value: 'nonprofit' },
  { label: 'Private', value: 'private' },
  { label: 'Public', value: 'public' },
];

export const RAISED_RANGES = [
  { label: '$0-$1M', value: '0~1000000' },
  { label: '$1M-$10M', value: '1000001~10000000' },
  { label: '$10M-$50M', value: '10000001~50000000' },
  { label: '$50M-$100M', value: '50000001~100000000' },
  { label: '$100M+', value: '100000001~' },
];

export const SAMPLE_COMPANIES: TargetMarketsCompany[] = [
  {
    id: 'google.com',
    domain: 'google.com',
    name: 'Google',
    industry: 'Technology',
    employeesRange: '10K-50K',
    country: 'United States',
    linkedin: 'company/google',
    twitter: 'google',
    crunchbase: 'organization/google',
  },
  {
    id: 'hubspot.com',
    domain: 'hubspot.com',
    name: 'HubSpot',
    industry: 'Technology',
    employeesRange: '5K-10K',
    country: 'United States',
    linkedin: 'company/hubspot',
    twitter: 'hubspot',
    crunchbase: 'organization/hubspot',
  },
  {
    id: 'github.com',
    domain: 'github.com',
    name: 'GitHub',
    industry: 'Technology',
    employeesRange: '10K-50K',
    country: 'United States',
    linkedin: 'company/github',
    twitter: 'github',
    crunchbase: 'organization/github',
  },
  {
    id: 'clearbit.com',
    domain: 'clearbit.com',
    name: 'Clearbit',
    industry: 'Technology',
    employeesRange: '251-1K',
    country: 'United States',
    linkedin: 'company/clearbit',
    twitter: 'clearbit',
    crunchbase: 'organization/clearbit',
  },
  {
    id: 'ibm.com',
    domain: 'ibm.com',
    name: 'IBM',
    industry: 'Technology',
    employeesRange: '10K-50K',
    country: 'United States',
    linkedin: 'company/ibm',
    twitter: 'ibm',
    crunchbase: 'organization/ibm',
  },
  {
    id: 'apple.com',
    domain: 'apple.com',
    name: 'Apple',
    industry: 'Technology',
    employeesRange: '10K-50K',
    country: 'United States',
    linkedin: 'company/apple',
  },
  {
    id: 'microsoft.com',
    domain: 'microsoft.com',
    name: 'Microsoft',
    industry: 'Technology',
    employeesRange: '10K-50K',
    country: 'United States',
    linkedin: 'company/microsoft',
    twitter: 'microsoft',
    crunchbase: 'organization/microsoft',
  },
  {
    id: 'meta.com',
    domain: 'meta.com',
    name: 'Meta',
    industry: 'Technology',
    employeesRange: '10K-50K',
    country: 'United States',
    linkedin: 'company/meta',
  },
  {
    id: 'twitter.com',
    domain: 'twitter.com',
    name: 'Twitter',
    industry: 'Technology',
    employeesRange: '10K-50K',
    country: 'United States',
    linkedin: 'company/twitter',
    twitter: 'twitter',
    crunchbase: 'organization/twitter',
  },
  {
    id: 'linkedin.com',
    domain: 'linkedin.com',
    name: 'LinkedIn',
    industry: 'Technology',
    employeesRange: '251-1K',
    country: 'United States',
    linkedin: 'company/linkedin',
    twitter: 'linkedin',
    crunchbase: 'organization/linkedin',
  },
  {
    id: 'amazon.com',
    domain: 'amazon.com',
    name: 'Amazon',
    industry: 'Technology',
    employeesRange: '10K-50K',
    country: 'United States',
    linkedin: 'company/amazon',
    twitter: 'amazon',
  },
  {
    id: 'netflix.com',
    domain: 'netflix.com',
    name: 'Netflix',
    industry: 'Technology',
    employeesRange: '251-1K',
    country: 'United States',
    linkedin: 'company/netflix',
    twitter: 'netflix',
  },
  {
    id: 'zoom.us',
    domain: 'zoom.us',
    name: 'Zoom',
    industry: 'Technology',
    employeesRange: '251-1K',
    country: 'United States',
    linkedin: 'company/zoom-video-communications',
  },
  {
    id: 'salesforce.com',
    domain: 'salesforce.com',
    name: 'Salesforce',
    industry: 'Technology',
    employeesRange: '10K-50K',
    country: 'United States',
    linkedin: 'company/salesforce',
    twitter: 'salesforce',
  },
  {
    id: 'stripe.com',
    domain: 'stripe.com',
    name: 'Stripe',
    industry: 'Technology',
    employeesRange: '251-1K',
    country: 'United States',
    linkedin: 'company/stripe',
    twitter: 'stripe',
    crunchbase: 'organization/stripe',
  },
  {
    id: 'twilio.com',
    domain: 'twilio.com',
    name: 'Twilio',
    industry: 'Technology',
    employeesRange: '1K-5K',
    country: 'United States',
    linkedin: 'company/twilio',
    twitter: 'twilio',
    crunchbase: 'organization/twilio',
  },
  {
    id: 'atlassian.com',
    domain: 'atlassian.com',
    name: 'Atlassian',
    industry: 'Technology',
    employeesRange: '1K-5K',
    country: 'United States',
    linkedin: 'company/atlassian',
    twitter: 'atlassian',
    crunchbase: 'organization/atlassian',
  },
  {
    id: 'asana.com',
    domain: 'asana.com',
    name: 'Asana',
    industry: 'Technology',
    employeesRange: '1K-5K',
    country: 'United States',
    linkedin: 'company/asana',
    twitter: 'asana',
    crunchbase: 'organization/asana',
  },
  {
    id: 'squareup.com',
    domain: 'squareup.com',
    name: 'Square',
    industry: 'Technology',
    employeesRange: '1K-5K',
    country: 'United States',
    linkedin: 'company/square',
    twitter: 'square',
    crunchbase: 'organization/square',
  },
  {
    id: 'okta.com',
    domain: 'okta.com',
    name: 'Okta',
    industry: 'Technology',
    employeesRange: '1K-5K',
    country: 'United States',
    linkedin: 'company/okta',
    twitter: 'okta',
    crunchbase: 'organization/okta',
  },
  {
    id: 'slack.com',
    domain: 'slack.com',
    name: 'Slack',
    industry: 'Technology',
    employeesRange: '1K-5K',
    country: 'United States',
    linkedin: 'company/slack',
    twitter: 'slackhq',
    crunchbase: 'organization/slack',
  },
  {
    id: 'dropbox.com',
    domain: 'dropbox.com',
    name: 'Dropbox',
    industry: 'Technology',
    employeesRange: '1K-5K',
    country: 'United States',
    linkedin: 'company/dropbox',
    twitter: 'dropbox',
    crunchbase: 'organization/dropbox',
  },
  {
    id: 'atlassian.com',
    domain: 'atlassian.com',
    name: 'Atlassian',
    industry: 'Technology',
    employeesRange: '1K-5K',
    country: 'United States',
    linkedin: 'company/atlassian',
    twitter: 'atlassian',
    crunchbase: 'organization/atlassian',
  },
  {
    id: 'workday.com',
    domain: 'workday.com',
    name: 'Workday',
    industry: 'Technology',
    employeesRange: '5K-10K',
    country: 'United States',
    linkedin: 'company/workday',
    twitter: 'workday',
    crunchbase: 'organization/workday',
  },
  {
    id: 'snowflake.com',
    domain: 'snowflake.com',
    name: 'Snowflake',
    industry: 'Technology',
    employeesRange: '1K-5K',
    country: 'United States',
    linkedin: 'company/snowflake-computing',
    twitter: 'snowflakedb',
    crunchbase: 'organization/snowflake-computing',
  },
  {
    id: 'oracle.com',
    domain: 'oracle.com',
    name: 'Oracle',
    industry: 'Technology',
    employeesRange: '10K-50K',
    country: 'United States',
    linkedin: 'company/oracle',
    twitter: 'oracle',
    crunchbase: 'organization/oracle',
  },
  {
    id: 'adobe.com',
    domain: 'adobe.com',
    name: 'Adobe',
    industry: 'Technology',
    employeesRange: '10K-50K',
    country: 'United States',
    linkedin: 'company/adobe',
    twitter: 'adobe',
    crunchbase: 'organization/adobe-systems',
  },
  {
    id: 'zendesk.com',
    domain: 'zendesk.com',
    name: 'Zendesk',
    industry: 'Technology',
    employeesRange: '1K-5K',
    country: 'United States',
    linkedin: 'company/zendesk',
    twitter: 'zendesk',
    crunchbase: 'organization/zendesk',
  },
  {
    id: 'shopify.com',
    domain: 'shopify.com',
    name: 'Shopify',
    industry: 'Technology',
    employeesRange: '1K-5K',
    country: 'Canada',
    linkedin: 'company/shopify',
    twitter: 'shopify',
    crunchbase: 'organization/shopify',
  },
];
