import { CKCompanyLogo, CKLayoutHorizontal } from 'clearkit';

import { Prospect } from '~/generated/graphql';

import { AVATAR_IMAGE_SIZE } from './constants';

export const ItemBlockCompany = ({ prospect }: { prospect: Prospect }) => (
  <CKLayoutHorizontal alignItems="start" justifyContent="start" wrap="nowrap">
    <CKCompanyLogo size={AVATAR_IMAGE_SIZE} src={prospect.companyLogo ?? ''} />
    <div className="text-xs text-gray-600">
      <h3 className="font-medium text-gray-900 truncate whitespace-nowrap">
        {prospect.companyName}
      </h3>
      <p className="truncate whitespace-nowrap">{prospect.companyDomain}</p>
    </div>
  </CKLayoutHorizontal>
);
