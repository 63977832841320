const styles = (theme) => ({
  eventItem: {
    fontSize: 14,
  },

  name: {
    color: theme.palette.blue[500],
    fontWeight: 500,
  },

  label: {
    color: theme.palette.list.label,
  },

  detailContainer: {
    background: 'white',
    position: 'relative',
    overflowY: 'scroll',
    height: '0px',
  },

  detailContainerExpanded: {
    position: 'relative',
    overflowY: 'scroll',
    transition: 'height 0.15s ease',
    maxHeight: 260,
    overflow: 'auto',
  },

  date: {
    color: '#596168',
  },
});

export default styles;
