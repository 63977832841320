import Disabled from './disabled.svg';
import styles from './styles';

const SalesforceNotAllowedAtThisLevel = ({ classes }) => (
  <div className={classes.salesforceDisabledMessage}>
    <Disabled />
    <p className={classes.salesforceDisabledMessageHeader}>
      You can’t nest Salesforce conditions at this level.
    </p>
  </div>
);

SalesforceNotAllowedAtThisLevel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const StyledAttributeSelector = withStyles(styles, {
  name: 'SearchBuilderhBaseAttributeSelector',
})(SalesforceNotAllowedAtThisLevel);

export default StyledAttributeSelector;
