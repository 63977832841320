import { useQuery } from '@apollo/client';

import query from './query.graphql';

const toFilterArray = (filter: string | null) => {
  if (filter) {
    return [filter];
  }

  return [];
};

export function useProspects(domain: string, filters: any) {
  const role = toFilterArray(filters.role);
  const seniority = toFilterArray(filters.seniority);

  const { data, loading, error } = useQuery(query, {
    variables: {
      domain,
      role,
      seniority,
    },
  });

  return { data: data?.prospects, loading, error };
}
