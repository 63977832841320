import { CKContainerProps } from 'clearkit';
import { FC } from 'react';

import { CRMExportField, CRMExportFieldProps } from './CRMExportField';
import { labelDisplayName } from './CRMExportFieldWrapper';

type SalesforceFieldProps = CRMExportFieldProps;
type SalesforceFieldComposition = {
  Label: FC<CKContainerProps>;
};

export const SalesforceField: FC<SalesforceFieldProps> &
  SalesforceFieldComposition = ({ children, ...rest }) => {
  return (
    <CRMExportField {...rest}>
      <CRMExportField.IconSalesforce />
      {children}
    </CRMExportField>
  );
};

SalesforceField.Label = CRMExportField.Label;
SalesforceField.Label.displayName = labelDisplayName;
