import { useEntitlements } from '~/hooks/account';

import { useCRMLabel } from '../hooks/useCRMLabel';
import { useHomeIntegrations } from '../hooks/useHomeIntegrations';
import { OnboardingItem } from './OnboardingItem';

type ItemConnectCRMProps = {
  number: number;
};

export const ItemConnectCRM = ({ number }: ItemConnectCRMProps) => {
  const {
    isHubSpotConnected,
    hasCRMConnector,
    loadingAccount,
  } = useHomeIntegrations();
  const { hasAccessSettingsIntegrations } = useEntitlements();
  const { crmLabel, integrationsRoute } = useCRMLabel();

  const isComplete = hasAccessSettingsIntegrations
    ? isHubSpotConnected
    : hasCRMConnector;
  const isLoading = loadingAccount;
  const title = `Connect ${crmLabel}`;

  return (
    <OnboardingItem
      isComplete={isComplete}
      isLoading={isLoading}
      number={number}
      title={title}
      to={integrationsRoute}
    />
  );
};
