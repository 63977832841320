import { Go, Sparkles } from '@clearkit/icons';
import { CKButton, CKLayoutHorizontal, CKSkeleton } from 'clearkit';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import { News as NewsType } from '~/generated/graphql';

import { HomeHeading } from '../HomeHeading';

type ArticleType = {
  createdAt: string;
  link: string;
  teaser: string;
  title: string;
};

const CHANGELOG_URL = 'https://clearbit.com/changelog';

export const HomeSidebarNews = ({ news }: { news?: NewsType }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [latestUpdate, setLatestUpdate] = useState<ArticleType>({
    title: '',
    link: '',
    createdAt: '',
    teaser: '',
  });

  useEffect(() => {
    if (news?.articles?.length) {
      const latestArticle = news.articles[0];
      const date = moment(latestArticle.createdAt).utc();

      setLatestUpdate({
        createdAt: date.fromNow(),
        link: `${CHANGELOG_URL}/${date.format('YYYY-MM-DD')}`,
        teaser: latestArticle.teaser,
        title: latestArticle.title,
      });
      setIsLoading(false);
    }
  }, [news]);

  return (
    <section className="pt-8 space-y-8">
      <CKLayoutHorizontal as="header">
        <HomeHeading>
          <Sparkles height={16} width={16} /> Latest Update
        </HomeHeading>

        <CKButton
          href={CHANGELOG_URL}
          rightIcon={<Go />}
          target="_blank"
          variant="tertiary"
          variantColor="blue"
        >
          View Changelog
        </CKButton>
      </CKLayoutHorizontal>
      <div className="space-y-2">
        <div className="space-y-2">
          <CKSkeleton
            className="h-5 w-[15ch]"
            isLoading={isLoading}
            label="loading"
          >
            <h3 className="font-medium">{latestUpdate.title}</h3>
          </CKSkeleton>
          <CKSkeleton
            className="h-[19px] w-[10ch]"
            isLoading={isLoading}
            label="loading"
          >
            <time className="text-sm">{latestUpdate.createdAt}</time>
          </CKSkeleton>
        </div>
        <CKSkeleton className="h-7" isLoading={isLoading} label="loading">
          <div className="leading-relaxed text-[.875rem]">
            <ReactMarkdown>{latestUpdate.teaser}</ReactMarkdown>
          </div>
        </CKSkeleton>
      </div>
    </section>
  );
};
