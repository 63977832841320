import { FitCompany, Folder, Segment } from '@clearkit/icons';
import classnames from 'classnames';
import {
  CKActionMenu,
  CKBox,
  CKContainerProps,
  CKDropdownChevron,
  CKHeightTransition,
  CKIconBox,
  CKLayoutHorizontal,
  CKPopover,
} from 'clearkit';
import { useState } from 'react';

import { FiltersCompanies } from '../../Filters/FiltersCompanies';
import { useProspector } from '../useProspector';
import { MenuItem } from './MenuItem';
import { collections, targetMarketList } from './mockData';
import { FiltersCompanies as FiltersCompaniesType } from './types';

type ProspectorFiltersPersonProps = {
  onChange: (value: FiltersCompaniesType) => void;
};

type AudienceItemProps = CKContainerProps<HTMLSpanElement> & {
  count: number;
  title: string;
};

const AudienceItem = ({
  className,
  children,
  count,
  title,
  ...rest
}: AudienceItemProps) => (
  <CKLayoutHorizontal
    className={classnames(className, 'p-1')}
    {...rest}
    as="span"
  >
    <CKLayoutHorizontal as="span" justifyContent="start">
      <CKIconBox>
        <FitCompany className="fill-gradient-br-blue" />
      </CKIconBox>
      <span>
        <span className="block font-medium">{title}</span>
        <span>{count.toLocaleString('en-US')}</span>
      </span>
    </CKLayoutHorizontal>
    {children}
  </CKLayoutHorizontal>
);

export const SectionFiltersCompanies = ({
  onChange,
}: ProspectorFiltersPersonProps) => {
  const [state] = useProspector();

  const hasTAM = false;

  // Temporarily always show company filters until we wire in TAM/segments
  const [isOpen, setIsOpen] = useState(!hasTAM);

  const filters = state.context.companyQuery;

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const prospectCount = state.context.companyQuery.prospectCount || 0;

  const Menu = () => (
    <CKActionMenu className="w-full">
      <CKActionMenu.Group groupId="Target Markets">
        <CKActionMenu.NestedItem leftIcon={<FitCompany />}>
          Target Markets
        </CKActionMenu.NestedItem>
        <CKActionMenu.GroupHeader>
          <FitCompany />
          Target Markets
        </CKActionMenu.GroupHeader>
        {targetMarketList.map((item) => (
          <MenuItem icon={<FitCompany />} item={item} key={item.title} />
        ))}
      </CKActionMenu.Group>
      <CKActionMenu.Group groupId="Segments">
        <CKActionMenu.NestedItem leftIcon={<Segment />}>
          Segments
        </CKActionMenu.NestedItem>
        <CKActionMenu.GroupHeader>
          <Segment />
          Segments
        </CKActionMenu.GroupHeader>
        {collections.map((item) => (
          <CKActionMenu.Group groupId={item.title} key={item.title}>
            <CKActionMenu.NestedItem leftIcon={<Folder />}>
              {item.title}
            </CKActionMenu.NestedItem>
            <CKActionMenu.GroupHeader>
              <Folder />
              {item.title}
            </CKActionMenu.GroupHeader>
            {item.segments.map((segment) => (
              <MenuItem icon={<Segment />} item={segment} key={segment.title} />
            ))}
          </CKActionMenu.Group>
        ))}
      </CKActionMenu.Group>
    </CKActionMenu>
  );

  return (
    <div className="space-y-4">
      <h2 className="font-medium">Companies</h2>
      {hasTAM ? (
        <CKPopover
          maxHeight="20rem"
          popover={<Menu />}
          popoverWidth="19rem"
          wrapperClassName="w-full"
        >
          <button
            className="w-full text-left rounded ck-focus-ring focus:outline-none"
            onClick={handleToggle}
          >
            <CKBox className="w-full p-4">
              <AudienceItem count={prospectCount} title="Addressable Market">
                <CKDropdownChevron />
              </AudienceItem>
            </CKBox>
          </button>
        </CKPopover>
      ) : null}
      <CKHeightTransition isOpen={isOpen}>
        <div className="p-1 pb-4">
          <CKBox className="p-4">
            <FiltersCompanies
              filters={filters}
              onChange={onChange}
              prospectFilters={state.context.prospectFilters}
            />
          </CKBox>
        </div>
      </CKHeightTransition>
    </div>
  );
};
