import { DirectionBack, DirectionForward } from '@clearkit/icons';
import classnames from 'classnames';
import { CKLayoutHorizontal } from 'clearkit';

type PaginationButtonsProps = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
};

export const PaginationButtons = ({
  hasNextPage,
  hasPreviousPage,
  onNextPage,
  onPreviousPage,
}: PaginationButtonsProps) => {
  const buttonClasses = 'px-2 py-1 ck-box text-blue hover:bg-gray-0';
  const disabledButtonClasses =
    'text-gray-300 bg-gray-1 cursor-not-allowed hover:bg-white';

  return (
    <CKLayoutHorizontal gap="0" justifyContent="start">
      <button
        aria-label="Previous page"
        className={classnames(buttonClasses, 'rounded-r-none', {
          [disabledButtonClasses]: !hasPreviousPage,
        })}
        disabled={!hasPreviousPage}
        onClick={onPreviousPage}
        title="Previous page"
      >
        <DirectionBack />
      </button>
      <button
        aria-label="Next page"
        className={classnames(buttonClasses, 'rounded-l-none', {
          [disabledButtonClasses]: !hasNextPage,
        })}
        disabled={!hasNextPage}
        onClick={onNextPage}
        title="Next page"
      >
        <DirectionForward />
      </button>
    </CKLayoutHorizontal>
  );
};
