import { useQuery } from '@apollo/client';

import { AccountNotifications } from '~/components/AccountNotifications';
import { BannerUpgradePlan } from '~/components/CTABanner/BannerUpgradePlan';
import ConnectorLayout from '~/components/layouts/ConnectorLayout';
import { FREE_TIER } from '~/constants/plans';
import { AccountFlagEnum } from '~/generated/graphql';
import { useFeatureFlag } from '~/hooks/account';
import Tile from '~/images/Tiles/Enrichment.svg';
import {
  integrationTabs as objectTypes,
  readableType,
} from '~/lib/dataEnrichment';
import { connectorLocations } from '~/lib/segmentAnalytics';

import { IntegrationTabs, useIntegrationTabState } from '../IntegrationTabs';
import { TabName } from '../IntegrationTabs/types';
import FieldMappingForm from './FieldMappingForm';
import query from './query.graphql';
import WebhookConfig from './WebhookConfigTab';

export type IntegrationEnum = keyof typeof objectTypes;
export type ObjectTypeName = typeof objectTypes[IntegrationEnum][number]['name'];

type EnrichmentShowProps = {
  integration: IntegrationEnum;
  objectType: ObjectTypeName;
};

export const EnrichmentShow = ({
  integration,
  objectType = objectTypes[integration][0].name,
}: EnrichmentShowProps) => {
  const { currentTab } = useIntegrationTabState({
    integration,
    tabName: objectType as TabName, // TODO: Must remove this casting when we fix TabName enum in useIntegrationTabState
  });
  const {
    data: { accountNotifications },
  } = useQuery(query, {
    returnPartialData: true,
    variables: {
      service: integration,
    },
  });

  const enrichmentUIEnabled = useFeatureFlag(
    AccountFlagEnum.AllowDataEnrichmentPreview,
  ).enabled;
  const enrichmentDisabled = useFeatureFlag(AccountFlagEnum.XDataEnrichment)
    .disabled;

  // userPreviewingFeature - a free_tier user can accerss the UI for CRM enrichment but cant actually enable the feature
  const userPreviewingFeature = enrichmentUIEnabled && enrichmentDisabled;

  const breadcrumbProps = FREE_TIER.PAGES.ENRICHMENT.CONFIGURATION;

  return (
    <ConnectorLayout
      breadcrumb={[
        {
          title: breadcrumbProps.TITLE,
          path: breadcrumbProps.PATH,
        },
      ]}
      connectorLocation={connectorLocations.enrichment}
      integration={integration}
      subtitle={`Sync Clearbit data to ${readableType(integration)}`}
      tabs={
        <IntegrationTabs
          integration={integration}
          tabName={objectType as TabName}
        />
      }
      tile={<Tile />}
    >
      <AccountNotifications
        className="mx-10 mt-6 mb-2"
        isFromEnrichmentPage
        notifications={accountNotifications}
      />

      {userPreviewingFeature ? (
        <BannerUpgradePlan className="mx-10 my-10" />
      ) : null}
      <div className="h-full px-8">
        {currentTab.name === 'webhook' ? (
          <WebhookConfig integration={integration} />
        ) : (
          <FieldMappingForm integration={integration} objectType={currentTab} />
        )}
      </div>
    </ConnectorLayout>
  );
};

export default EnrichmentShow;
