const styles = {
  root: {
    padding: '8px 16px',
    background: '#efefef',
    marginTop: 16,
    fontSize: 16,
  },

  button: {
    cursor: 'pointer',
    userSelect: 'none',
    '&:focus': {
      outline: 'none',
    },
  },

  pre: {
    border: '1px solid #dfdfdf',
    padding: 8,
    marginTop: 8,
    marginBottom: 8,
    fontSize: 10,
    background: 'rgba(255, 255, 255, 0.1)',
  },

  object: {
    '& + &': {
      marginTop: 8,
    },
  },

  chevron: {
    transform: 'rotate(180deg)',
    marginRight: 8,
  },

  openChevron: {
    transform: 'rotate(-90deg)',
  },
};

export default styles;
