import { CKDrawer } from 'clearkit';

import { ExportProspectHubspot } from '../Exports/ExportProspectHubspot';
import { ExportProspectList } from '../Exports/ExportProspectList';
import { ExportProspectSalesforce } from '../Exports/ExportProspectSalesforce';
import { useProspector } from '../useProspector';
import {
  isExporting,
  isExportingHubspot,
  isExportingSalesforce,
} from '../util';

export function ExportProspectsDrawer() {
  const [state, send] = useProspector();

  const isOpen = isExporting(state);

  const handleClose = () => send('CANCEL');

  return (
    <CKDrawer isVisible={isOpen} onToggle={handleClose} size="xxl">
      <DrawerContent />
    </CKDrawer>
  );
}

function DrawerContent() {
  const [state] = useProspector();

  const isSalesforce = isExportingSalesforce(state);
  const isHubspot = isExportingHubspot(state);

  if (isSalesforce) {
    return <ExportProspectSalesforce />;
  } else if (isHubspot) {
    return <ExportProspectHubspot />;
  } else {
    return <ExportProspectList />;
  }
}
