import { EnrichmentAttribute } from '~/generated/graphql';

import { Integration, IntegrationResource, ObjectType } from './types';

function clearbitTypesForMapping(type: IntegrationResource) {
  switch (type) {
    case 'account':
    case 'company':
      return ['clearbit_company'];
    default:
      return ['clearbit_company', 'clearbit_person'];
  }
}

function hasSegments(integration: Integration) {
  if (integration === 'marketo') {
    return false;
  }

  return true;
}

export function getSourceTypes(
  integration: Integration,
  objectType: ObjectType,
) {
  const clearbitTypes = clearbitTypesForMapping(objectType.name);
  const hasSegmentSource = hasSegments(integration);

  return [
    ...clearbitTypes,
    `custom_traits_${objectType.type}`,
    `reveal_${objectType.type}`,
    hasSegmentSource ? `segment_${objectType.type}` : null,
    `system_traits_${objectType.type}`,
  ].filter(Boolean);
}

export function getServicesForIntegration(integration: Integration) {
  switch (integration) {
    case 'salesforce':
      return ['salesforce', 'salesforcesandbox'];
    default:
      return [integration];
  }
}

const CLEARBIT_SYSTEM_TRAITS_TYPE = 'system_traits';

export function isSystemAttribute(attribute: EnrichmentAttribute) {
  return attribute.meta?.sourceType === CLEARBIT_SYSTEM_TRAITS_TYPE;
}
