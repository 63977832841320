import { ExportsPendingEmptyState } from '../Exports';
import { useProspector } from '../useProspector';
import { ExportsList } from './ExportsList';

export const ExportsListPending = () => {
  const [state] = useProspector();

  const items = state.context.prospectListExportsPending;

  return (
    <ExportsList isLoading={state.context.loadingExports} items={items}>
      <ExportsList.Title>Pending Exports</ExportsList.Title>
      <ExportsList.Description>
        Exports to CSV or to your CRM that are still in-progress, or CSV exports
        that are ready but have not been downloaded. You have not been charged
        credits for Prospector CSV exports that have not yet been downloaded.
      </ExportsList.Description>
      <ExportsList.EmptyState>
        <ExportsPendingEmptyState />
      </ExportsList.EmptyState>
    </ExportsList>
  );
};
