import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MuiTextField from '@material-ui/core/TextField';

import styles from './TextField.style';

const OUTLINED_INPUTS = {
  InputLabelProps: {
    variant: 'outlined',
    shrink: false,
    disableAnimation: true,
  },
};

let OutlinedTextField = (props) => {
  const {
    classes,
    FormControlProps,
    InputLabelProps,
    InputProps,
    fullWidth,
    margin,
    focused: _focused,
    ...rest
  } = props;
  const { formLabelRoot, formLabelFocused } = classes;

  const formLabelClasses = {
    root: formLabelRoot,
    focused: formLabelFocused,
    outlined: null,
  };

  return (
    <FormControl {...FormControlProps} fullWidth={fullWidth} margin={margin}>
      {props.label && (
        <InputLabel
          FormLabelClasses={{ ...formLabelClasses }}
          required={props.required}
          {...InputLabelProps}
          {...OUTLINED_INPUTS.InputLabelProps}
        >
          {props.label}
        </InputLabel>
      )}

      <Input {...rest} {...InputProps} />

      {props.helperText && (
        <FormHelperText margin="normal" {...rest.FormHelperTextProps}>
          {props.helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

OutlinedTextField.propTypes = {
  label: PropTypes.string,
  classes: PropTypes.shape({
    formLabelRoot: PropTypes.string.isRequired,
    formLabelFocused: PropTypes.string.isRequired,
  }),
  InputLabelProps: PropTypes.object,
  fullWidth: PropTypes.bool,
  margin: PropTypes.string,
  helperText: PropTypes.string,
  required: PropTypes.bool,
};

OutlinedTextField = withStyles(styles)(OutlinedTextField);

const TextField = (props) =>
  props.variant === 'standard' ? (
    <MuiTextField
      {...props}
      InputLabelProps={{
        ...props.InputLabelProps,
        shrink: false,
      }}
    />
  ) : (
    <OutlinedTextField {...props} />
  );

TextField.defaultProps = {
  variant: 'outlined',
};

TextField.propTypes = {
  variant: PropTypes.string,
};

export default TextField;
