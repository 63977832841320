export const FREE_TIER = {
  PAGES: {
    ENRICHMENT: {
      CONFIGURATION: {
        TITLE: 'Integrations',
        PATH: '/integrations',
      },
    },
  },
} as const;
