import classnames from 'classnames';
import { CKBox, CKContainerProps } from 'clearkit';

export const OnboardingCard = ({
  className,
  children,
  ...rest
}: CKContainerProps) => {
  return (
    <CKBox {...rest} className={classnames('w-[30rem]', className)}>
      {children}
    </CKBox>
  );
};
