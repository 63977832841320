import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import { Subscribe } from 'unstated';

import { SearchContext } from '~/components/SearchBuilder/utils/searchUtils';
import UserContainer from '~/containers/User';

import { DEFAULT_EVENT_PART } from '../../utils/constants';
import NewEvent from './NewEvent';
import styles from './styles';

const ALL_PATHS_ATTRIBUTE = 'all';

export class BareEventCondition extends React.Component {
  onPathChange = (event) => {
    const { node } = this.props;
    const { match } = node.event;

    let path = event.target.value;

    if (!path.startsWith('/') && match === 'start_with') {
      path = `/${path}`;
    }

    node.event.path = path;
    this.props.onChange(node);
  };

  onPathBlur = (event) => {
    const { node } = this.props;

    let path = event.target.value;
    path = this.removeTrailingSlash(path);
    node.event.path = path;
    this.props.onChange(node);
  };

  onHostChange = (host) => {
    const { node } = this.props;

    node.event.host = host;
    this.props.onChange(node);
  };

  onCountChange = (countVal) => {
    const { node } = this.props;

    let count = parseInt(countVal, 10) || '';
    if (count) count = Math.min(count, 10000);
    node.event.count = count;
    this.props.onChange(node);
  };

  removeTrailingSlash = (value) => {
    if (value === '/') return '/';
    return value.replace(/\/+$/, '');
  };

  onTimeframeChange = (timeframe) => {
    const { node } = this.props;
    node.event.timeframe = timeframe;
    this.props.onChange(node);
  };

  render = () => {
    const { classes, node, hosts } = this.props;
    const { disabled } = this.context;
    const { match, path, count, timeframe, host } = node.event;
    const showingHost = hosts && hosts.length > 1;
    const hidePath = match === ALL_PATHS_ATTRIBUTE;

    let selectableHosts = uniq(compact([...hosts, host]));

    return (
      <Subscribe to={[UserContainer]}>
        {(container) => (
          <>
            {container.hasEngagementAccess() ? (
              <NewEvent {...this.props} />
            ) : (
              <>
                <Select
                  className={classnames(classes.host, {
                    [classes.hostWithPath]: !hidePath,
                  })}
                  disabled={!showingHost || disabled}
                  displayEmpty
                  onChange={(e) => this.onHostChange(e.target.value)}
                  value={host}
                >
                  <MenuItem>Any domain</MenuItem>
                  {selectableHosts.map((host) => (
                    <MenuItem key={host} value={host}>
                      {host}
                    </MenuItem>
                  ))}
                </Select>
                {!hidePath && (
                  <Input
                    className={classnames(classes.pathInput, {
                      [classes.pathOnly]: !showingHost,
                    })}
                    disabled={disabled}
                    onBlur={this.onPathBlur}
                    onChange={this.onPathChange}
                    placeholder="/pricing"
                    value={path}
                  />
                )}
                <Input
                  className={classes.countInput}
                  disabled={disabled}
                  key="count"
                  onChange={(e) => this.onCountChange(e.target.value)}
                  value={count}
                  variant="outlined"
                />
                <p className={classes.countLabel}>
                  {parseInt(count, 10) === 1 ? 'time' : 'times'} in the last
                </p>
                <Select
                  className={classes.timeframeInput}
                  disabled={disabled}
                  key="timeframe"
                  onChange={(e) => this.onTimeframeChange(e.target.value)}
                  value={timeframe}
                >
                  <MenuItem key="day" value={86400}>
                    Day
                  </MenuItem>
                  <MenuItem key="week" value={86400 * 7}>
                    Week
                  </MenuItem>
                  <MenuItem key="month" value={86400 * 30}>
                    Month
                  </MenuItem>
                </Select>
              </>
            )}
          </>
        )}
      </Subscribe>
    );
  };
}

BareEventCondition.contextType = SearchContext;

BareEventCondition.defaultProps = {
  classes: {},
  node: DEFAULT_EVENT_PART,
};

BareEventCondition.propTypes = {
  node: PropTypes.shape({
    event: PropTypes.object.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.object,
  host: PropTypes.string,
  hosts: PropTypes.arrayOf(PropTypes.string),
};

export default withStyles(styles)(BareEventCondition);
