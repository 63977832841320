import classnames from 'classnames';
import { CKContainerProps, CKCountUp, CKLayoutHorizontal } from 'clearkit';

import { hideListSmall } from '../prospector/ProspectList/constants';

type ItemCountProps = CKContainerProps & {
  duration?: number;
  hideCountAbove?: number;
  hideCountLabel?: string;
  end: number;
  label?: string;
  start?: number;
};

export const ItemCount = ({
  className,
  children,
  duration = 1000,
  end,
  hideCountAbove,
  hideCountLabel,
  label,
  start,
}: ItemCountProps) => {
  const showCount = (hideCountAbove && end < hideCountAbove) || !hideCountAbove;

  return (
    <CKLayoutHorizontal
      className={classnames('min-w-0 text-sm', className)}
      gap="0.5rem"
      justifyContent="start"
    >
      {children}
      <span>
        {showCount ? (
          <CKCountUp duration={duration} end={end} start={start || end} />
        ) : (
          hideCountLabel
        )}{' '}
        {label ? <span className={hideListSmall}>{label}</span> : null}
      </span>
    </CKLayoutHorizontal>
  );
};
