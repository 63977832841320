const styles = () => ({
  root: {
    borderRadius: 7,
    display: 'flex',
    flexDirection: 'row',
    margin: '0 0 12px 0',
    padding: '12px 13px 11px 17px',
    color: '#1070C9',
    background: '#F2FAFE',
    boxShadow:
      '0 1px 2px 0 rgba(0,0,0,0.05), inset 0 0 0 1px rgba(152,213,253,0.56)',
  },

  actions: {
    display: 'flex',
    flexDirection: 'vertical',
  },

  legacyWebhookUrl: {
    fontWeight: 600,
  },

  textContainer: {
    flex: '0 0 476px',
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginLeft: 17,
  },

  heading: {
    fontSize: 15,
    fontWeight: 500,
    marginBottom: 5,
  },

  subheading: {
    fontSize: 14,
  },
});

export default styles;
