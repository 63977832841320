import { CKButton, CKLayoutGridAuto } from 'clearkit';
import { useState } from 'react';

import AuthLayout, { AuthLayoutHeader } from '~/components/layouts/AuthLayout';

import { AuthBox } from '../AuthBox';
import TermsOfServiceCheck from '../shared/TermsOfServiceCheck';
import TextFieldWithValidations from '../shared/TextFieldWithValidations';
import useTelemetryId from '../shared/useTelemetryId';
import useCreateProfileAndSignIn from './useCreateProfileAndSignIn';

type CreateProfileValidationErrors = {
  first_name?: string[];
  last_name?: string[];
};

export default function CreateProfile() {
  const [firstName, setUserFirstName] = useState('');
  const [lastName, setUserLastName] = useState('');
  const [tosAccepted, setTosAccepted] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    CreateProfileValidationErrors
  >({});

  const isDisabled = firstName === '' || lastName === '' || !tosAccepted;

  const { isLoading, mutateAsync } = useCreateProfileAndSignIn();
  const { getTelemetryId } = useTelemetryId();

  async function createAccount() {
    const telemetryId = await getTelemetryId();

    const { isSuccess, error } = await mutateAsync({
      firstName,
      lastName,
      telemetryId,
    });

    if (isSuccess) {
      setValidationErrors({});
      window.location.href = '/';
      return;
    }

    if (error.action === 'unauthorized') {
      window.location.href = `/auth/login?error=${error.type}`;
      return;
    }

    if (error.error?.type === 'validation') {
      setValidationErrors(error.error.errors);
    }
  }

  return (
    <AuthLayout>
      <AuthLayoutHeader
        description="Provide your details below and we'll create your account."
        header={
          <div className="w-full font-semibold text-center">
            Create your Clearbit profile
          </div>
        }
      />
      <AuthBox>
        <div className="flex flex-col gap-6">
          <CKLayoutGridAuto gap="1.5rem">
            <TextFieldWithValidations
              autoComplete="given-name"
              id="given-name"
              name="first_name"
              onChange={setUserFirstName}
              placeholder="Enter your first name"
              validationErrors={validationErrors}
              value={firstName}
            >
              <TextFieldWithValidations.Label>
                First Name
              </TextFieldWithValidations.Label>
            </TextFieldWithValidations>
            <TextFieldWithValidations
              autoComplete="family-name"
              id="family-name"
              name="last_name"
              onChange={setUserLastName}
              placeholder="Enter your last name"
              validationErrors={validationErrors}
              value={lastName}
            >
              <TextFieldWithValidations.Label>
                Last Name
              </TextFieldWithValidations.Label>
            </TextFieldWithValidations>

            <TermsOfServiceCheck
              setTosAccepted={setTosAccepted}
              tosAccepted={tosAccepted}
            />
          </CKLayoutGridAuto>
          <div>
            <CKButton
              isDisabled={isDisabled}
              isLoading={isLoading}
              onClick={createAccount}
              variant="bold"
              variantColor="blue"
            >
              Create profile
            </CKButton>
          </div>
        </div>
      </AuthBox>

      <div>
        <div className="text-sm text-center text-gray-600">
          <span>Need help?</span>
          <a
            className="pl-2 font-medium text-blue-600 hover:text-blue-500"
            href="mailto:support@clearbit.com"
          >
            Contact us at support@clearbit.com
          </a>
        </div>
      </div>
    </AuthLayout>
  );
}
