import ContentBox from '~/components/ContentBox';
import { AccountFlagEnum } from '~/generated/graphql';
import { useFeatureFlag, usePlatformAccess } from '~/hooks/account';

import destinationGroups from './destinationsConfigurations';
import PlanDestinationGroup from './PlanDestinationGroup';
import useAvailableDestinations from './useAvailableDestinations';

export default function PlatformPlanDestinations({ segment }) {
  const enabledDestinations = useAvailableDestinations(segment.type);

  const { operations } = usePlatformAccess();

  const allowWebhookAccesss = useFeatureFlag(
    AccountFlagEnum.AllowPlatformWebhookDestination,
  ).enabled;

  /* we are currently not promoting advertising to any customers so hiding for now */
  const shouldSkipCtas = operations || allowWebhookAccesss;

  return (
    <>
      {destinationGroups.map((group) => (
        <PlanDestinationGroup
          audiences={!!group.audiences}
          description={group.description}
          enabled={enabledDestinations}
          items={group.items}
          key={group.title}
          segment={segment}
          title={group.title}
        />
      ))}

      {shouldSkipCtas ? null : (
        <div className="flex flex-col pt-4 gap-4">
          <ContentBox.Title className=" text-gray-600">
            <h2 className="mb-1 text-base font-medium">
              Do more with Clearbit
            </h2>
            <p className="text-sm">
              Contact our success team to learn how we can help you achieve your
              goals by putting data to work.
            </p>
          </ContentBox.Title>
        </div>
      )}
    </>
  );
}
