const styles = () => ({
  layout: {
    display: 'flex',
    flexGrow: 1,
    minHeight: 0,
    flexDirection: 'column',
    overflow: 'auto',
    minWidth: 0,
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
    position: 'sticky',
    top: 0,
    background: 'white',
    flexShrink: 0,
    zIndex: 11,
  },

  darkHeader: {
    background: '#FAFBFC',
  },

  border: {
    borderBottom: '1px solid #e1e9f0',
  },

  shadow: {
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.05)',
  },

  tabs: {
    /*
     * Negative margins are generally not recommended but in this case it seems
     * a good enough compromise. The alternative is to define all margins for
     * each case to support tabs here./
     */
    margin: '-10px 40px -1px',
    zIndex: 10,
  },

  accessoryView: {
    padding: 16,
    margin: '0 24px',
    borderTop: '1px solid rgba(225,233,240,0.50)',
  },

  deprecationWarning: {
    margin: '0 24px',
    padding: '0px 16px 24px 16px',
  },

  contents: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
  },

  tile: {
    maxHeight: 50,
    flexShrink: 0,
  },

  badge: {
    padding: '4px 6px',
    width: '37px',
    height: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    lineheight: '16px',
  },

  subtitle: {
    display: 'flex',
    alignItems: 'center',
    color: '#596168',
    fontSize: 15,
    margin: '-5px 0',
    whiteSpace: 'pre',
    textOverflow: 'ellipsis',
    flexGrow: 1,
    overflow: 'hidden',
    minHeight: 32,

    '& strong': {
      fontWeight: 600,
    },
  },

  actions: {
    display: 'flex',
    flex: '0 0 auto',
  },

  back: {
    fontSize: 24,
    color: '#596168',
    fontWeight: 400,
    '&:hover': {
      textDecoration: 'underline',
    },
  },

  backSpan: {
    fontSize: 24,
    color: '#596168',
    fontWeight: 400,
  },

  arrow: {
    display: 'inline-block',
    verticalAlign: 'middle',
    position: 'relative',
    top: -2,
    margin: '0 10px',
  },
});

export default styles;
