import classnames from 'classnames';
import {
  CKContainerProps,
  CKLayoutGrid,
  CKLayoutHorizontal,
  excludeChildrenByDisplayName,
  includeChildrenByDisplayName,
} from 'clearkit';
import { FC } from 'react';
import { Link } from 'react-router-dom';

type ProspectorSidebarProps = CKContainerProps;

type BreadcrumbsProps = CKContainerProps;

type ProspectorSidebarComposition = {
  Breadcrumbs: FC<BreadcrumbsProps>;
  Footer: FC<CKContainerProps>;
  Heading: FC<CKContainerProps>;
};

const breadcrumbsDisplayName = 'ProspectorSidebar.Breadcrumbs';
const footerDisplayName = 'ProspectorSidebar.Footer';
const headingDisplayName = 'ProspectorSidebar.Heading';

export const ProspectorSidebar: FC<ProspectorSidebarProps> &
  ProspectorSidebarComposition = ({ children, className, ...rest }) => {
  return (
    <CKLayoutGrid
      {...rest}
      as="aside"
      className={classnames(className, 'w-[22rem] border-r border-gray-200')}
      gap="0"
      gridTemplateRows="1fr auto"
    >
      <div className="ck-scrollbar ck-scrollbar--vertical">
        <header className="p-6 pt-5 space-y-2 ck-header-border-scroll">
          <div className="min-h-[1.325rem]">
            {includeChildrenByDisplayName({
              children,
              componentDisplayName: breadcrumbsDisplayName,
            })}
          </div>

          {includeChildrenByDisplayName({
            children,
            componentDisplayName: headingDisplayName,
          })}
        </header>
        <div>
          {excludeChildrenByDisplayName({
            children,
            componentDisplayName: [
              breadcrumbsDisplayName,
              footerDisplayName,
              headingDisplayName,
            ],
          })}
        </div>
      </div>
      {includeChildrenByDisplayName({
        children,
        componentDisplayName: footerDisplayName,
      })}
    </CKLayoutGrid>
  );
};

ProspectorSidebar.Breadcrumbs = ({ children, className, ...rest }) => (
  <CKLayoutHorizontal
    className={classnames(className, 'text-sm  text-gray-600 overflow-hidden')}
    gap=".5rem"
    justifyContent="start"
    wrap="nowrap"
    {...rest}
  >
    <Link
      className="font-medium !text-blue basis-auto shrink-0"
      to="/prospector"
    >
      Prospector
    </Link>
    <span>/</span>
    <span className="block min-w-0 font-medium text-gray-900 truncate basis-full shrink grow whitespace-nowrap">
      {children}
    </span>
  </CKLayoutHorizontal>
);
ProspectorSidebar.Breadcrumbs.displayName = breadcrumbsDisplayName;

ProspectorSidebar.Footer = ({ children, className, ...rest }) => (
  <footer
    className={classnames(className, 'px-6 py-3 border-t border-gray-200')}
    {...rest}
  >
    {children}
  </footer>
);
ProspectorSidebar.Footer.displayName = footerDisplayName;

ProspectorSidebar.Heading = ({ children, className, ...rest }) => (
  <h1
    {...rest}
    className={classnames('font-semibold text-[1.5rem]', className)}
  >
    {children}
  </h1>
);
ProspectorSidebar.Heading.displayName = headingDisplayName;
