import { DELIVERY_TARGET_LABELS } from '../dynamicDeliveryTargets';

function slackChannelName(channelId, users = [], channels = []) {
  const dynamicTargetLabel = DELIVERY_TARGET_LABELS[channelId];
  if (dynamicTargetLabel) {
    return dynamicTargetLabel;
  }

  const recipient = [...users, ...channels].find(
    (channel) => channel.id === channelId,
  );
  const isChannel = channelId[0] === 'C';
  if (!recipient) return `An unknown ${isChannel ? 'channel' : 'user'}`;
  if (isChannel) return `#${recipient.name}`;
  return `@${recipient.profile.displayName}`;
}

export default function SlackDestinationDescription({
  config,
  accountConnector,
}) {
  const { channels, webhookUrl } = config;

  if (channels) {
    if (!accountConnector) return ' ';

    const {
      remoteMeta: { channels: slackChannels, users: slackUsers } = {},
    } = accountConnector;

    const firstChannel = channels[0];

    return (
      <>
        Will post a message to{' '}
        <strong>
          {slackChannelName(firstChannel, slackUsers, slackChannels)}
        </strong>
        {channels.length > 1 && (
          <>
            {' '}
            and{' '}
            <strong>
              {channels.length - 1} other channel
              {channels.length > 2 ? 's' : ''}
            </strong>
          </>
        )}
      </>
    );
  }

  return (
    <>
      Will post a message to <strong>{webhookUrl}</strong>
    </>
  );
}
