import { Caret } from '@clearkit/icons';
import flatten from 'lodash/flatten';

import { compareStringsWithStartCase } from '~/lib/strings';

import Attribute from './Attribute';
import { backgroundColors, borderColors, keyColors } from './colors';
import GroupLogo from './GroupLogo';
import styles from './styles';

const Group = ({ title, sections, icon, type, search, actionLink }) => {
  const [open, setOpen] = useState(!!search);
  const showDetails = search || open;
  const collapsible = ['salesforce', 'hubspot'].includes(type);
  const backgroundColor = backgroundColors[type] || '#FAFBFC';
  const borderColor = borderColors[type] || '#ECEDEE';

  const showGroup = (group) => Object.values(group).some(showAttributes);

  const showAttributes = (attributes) =>
    flatten(attributes).some(showAttribute);

  const attributeComparator = (text) =>
    compareStringsWithStartCase(text, search);

  const showAttribute = (attribute) => {
    if ([attribute.label, attribute.value].some(attributeComparator))
      return true;

    if (attribute.children) {
      if (attribute.children.some(showAttribute)) {
        return true;
      }
    }
  };

  if (!showGroup(sections)) {
    return null;
  }

  return (
    <div
      className="mt-4 border rounded"
      key={title}
      style={{ backgroundColor, borderColor }}
    >
      <div
        className={classnames(
          'pt-3 px-3 flex items-center text-gray-600 text-sm font-medium outline-none rounded-sm',
          collapsible && 'pb-3 ck-focus-ring',
        )}
        onClick={() => setOpen(!open)}
        role={collapsible ? 'button' : 'display'}
        tabIndex="0"
      >
        {collapsible && (
          <Caret
            className={classnames(
              'transform mr-2',
              showDetails ? 'rotate-0' : '-rotate-90',
            )}
          />
        )}
        <span className="relative ml-1 mr-2" style={{ color: keyColors[type] }}>
          {icon ? icon() : <GroupLogo title={title} type={type} />}
        </span>
        {title}
        <div className="ml-auto">{actionLink}</div>
      </div>

      {(!collapsible || showDetails) &&
        sections.map(
          (attributes, i) =>
            showAttributes(attributes, search) && (
              <div
                className={classnames(
                  'mx-2 py-2 px-2 border-light border-gray-200',
                  {
                    'border-b':
                      i !== sections.filter(showAttributes).length - 1,
                  },
                )}
                key={i}
              >
                {attributes.map((attribute, j) => (
                  <Attribute
                    attribute={attribute}
                    key={`${i}-${j}`}
                    search={search}
                    showAttribute={showAttribute}
                  />
                ))}
              </div>
            ),
        )}
    </div>
  );
};

Group.propTypes = {
  title: PropTypes.string,
  sections: PropTypes.array,
  icon: PropTypes.func,
  type: PropTypes.string,
  showAttribute: PropTypes.func,
  showAttributes: PropTypes.func,
  search: PropTypes.string,
  actionLink: PropTypes.node,
};

export default withStyles(styles)(Group);
