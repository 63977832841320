import { ONBOARDING_ICP_AUDIENCE_NAME } from '~/components/Onboarding/constants';
import { useTargetMarketsQuery } from '~/generated/graphql';

export const useMainICP = () => {
  const {
    data: targetMarketsData,
    loading: loadingTargetMarkets,
  } = useTargetMarketsQuery();

  const mainICP =
    !loadingTargetMarkets && targetMarketsData?.targetMarkets?.targetMarkets
      ? targetMarketsData?.targetMarkets?.targetMarkets.find(
          (targetMarket: any) =>
            targetMarket.name === ONBOARDING_ICP_AUDIENCE_NAME,
        )
      : null;

  const firstTargetMarket =
    targetMarketsData?.targetMarkets?.targetMarkets?.[0];
  const firstTargetMarketId = firstTargetMarket?.id;

  const mainICPSearch = mainICP?.search;
  const mainICPId = mainICP?.id;
  const hasTargetMarketDefinedIcp = !!mainICPId;

  return {
    firstTargetMarket,
    firstTargetMarketId,
    hasTargetMarketDefinedIcp,
    loadingTargetMarkets,
    mainICPId,
    mainICPSearch,
  };
};
