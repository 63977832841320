import {
  Clock as ActivityIcon,
  Segment as SegmentsIcon,
  User as PersonIcon,
} from '@clearkit/icons';
import { CKAvatar, CKDrawer, CKTab, CKTabList, CKTabs } from 'clearkit';
import some from 'lodash/some';
import { Helmet } from 'react-helmet';

import AttributeBrowser from '~/components/AttributeBrowser';
import GraphqlError from '~/components/GraphqlError';
import wrapError from '~/components/JavascriptError/JavascriptErrorHandler';
import LoadingArea from '~/components/LoadingArea';
import MissingPage from '~/components/MissingPage';
import { useFancyQuery } from '~/lib/graphql';
import { paginateData } from '~/lib/graphql/pagination';

import Actions from './Actions';
import Activity from './Activity';
import PersonDetails from './Details';
import query from './query.graphql';
import Segments from './Segments';

const Person = ({ id }) => {
  const {
    data,
    data: { person, salesforceAccountConnector, hubspotAccountConnector } = {},
    error,
    loading,
    refetch,
  } = useFancyQuery(query, {
    variables: { id },
    returnPartialData: true,
  });

  const [tabIndex, setTabIndex] = useState(0);
  const overflowScrollRef = React.useRef();

  const handleTabChange = (stickyHeaderBounds) => {
    const stickyHeaderGap = 28;
    const customOffset =
      stickyHeaderBounds.top - stickyHeaderBounds.height - stickyHeaderGap;
    if (overflowScrollRef.current) {
      overflowScrollRef.current.scrollTo({ top: customOffset });
    }
  };

  if (error) {
    return (
      <div className="x-sidebar">
        <GraphqlError error={error} refetch={refetch} />;
      </div>
    );
  }

  if (!loading && !person && !error)
    return <MissingPage className="x-sidebar" />;

  if ((loading && !data) || !person)
    return <LoadingArea className="x-sidebar" loading />;

  const {
    salesforceAccounts,
    salesforceContacts,
    salesforceLeads,
    hubspotCompanies,
  } = paginateData(person, {
    fields: [
      'salesforceAccounts',
      'salesforceContacts',
      'salesforceLeads',
      'hubspotCompanies',
    ],
  });

  return (
    <div className="x-sidebar">
      <Helmet title={person.name} />
      <CKDrawer.Header className="border-none">
        <div className="flex items-center flex-1">
          <div className="mr-4">
            <CKAvatar className="shadow-sm" size={56} src={person.avatar} />
          </div>

          <div>
            <CKDrawer.Heading>{person.name || 'Loading...'}</CKDrawer.Heading>
            {person.email ? (
              <a
                className="text-blue"
                href={`mailto://${person.email}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {person.email}
              </a>
            ) : (
              <span>&mdash;</span>
            )}
          </div>

          <div className="ml-auto">
            <Actions
              hubspotCompanies={hubspotCompanies}
              hubspotOwners={hubspotAccountConnector?.owners}
              person={person}
              salesforceAccountConnector={salesforceAccountConnector}
              salesforceAccounts={salesforceAccounts}
              salesforceContacts={salesforceContacts}
              salesforceLeads={salesforceLeads}
            />
          </div>
        </div>
      </CKDrawer.Header>

      <CKTabs
        aria-label="Person tabs"
        className="flex flex-col flex-grow min-h-0"
        index={tabIndex}
        isBordered
        onChange={setTabIndex}
      >
        <CKTabList
          aria-label="Person tabs"
          className="flex-shrink-0 px-10 -mt-4"
        >
          <CKTab icon={<PersonIcon />}>Person details</CKTab>
          <CKTab icon={<ActivityIcon />}>Events</CKTab>
          <CKTab icon={<SegmentsIcon />}>Audiences</CKTab>
        </CKTabList>

        {tabIndex === 0 && (
          <div className="overflow-y-auto" ref={overflowScrollRef}>
            <PersonDetails person={person} />

            <LoadingArea loading={!some([person.traits, person.enrichment])}>
              <AttributeBrowser
                enrichment={person.enrichment}
                hubspotCompanies={hubspotCompanies}
                hubspotOwners={hubspotAccountConnector?.owners}
                object={person}
                onTabChange={handleTabChange}
                salesforceAccountConnector={salesforceAccountConnector}
                salesforceAccounts={salesforceAccounts}
                salesforceContacts={salesforceContacts}
                salesforceLeads={salesforceLeads}
                traits={person.traits}
              />
            </LoadingArea>
          </div>
        )}
        {tabIndex === 1 && (
          <Activity className="flex flex-col flex-grow" person={person} />
        )}
        {tabIndex === 2 && (
          <Segments className="flex flex-col flex-grow" person={person} />
        )}
      </CKTabs>
    </div>
  );
};

Person.propTypes = {
  id: PropTypes.string.isRequired,
};

export default wrapError(Person);
