// eslint-disable-next-line clearbit-x/no-tree-shaking-imports
import { fade } from '@material-ui/core/styles/colorManipulator';

export const selectTheme = (theme) => ({
  ...theme,
  borderRadius: 6,
  colors: {
    ...theme.colors,
    primary25: '#FAFBFC',
    primary: '#4DB1FD',
  },
});

export const errorStyles = {
  control: (base, _state) => ({
    ...base,
    boxShadow:
      'inset 0 0 0 1px rgba(234, 57, 39, 0.8), inset 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 0 0 0px rgba(234, 57, 39, 0);',
    borderWidth: 'unset',
    borderStyle: 'none',
  }),
};

export default {
  menuList: (base) => ({
    ...base,
    paddingTop: 8,
    paddingBottom: 8,
  }),

  menu: (base) => ({
    ...base,
    overflow: 'hidden',
    marginTop: 4,
    minWidth: '100%',
    width: 'auto',
    maxWidth: '110%',
  }),

  option: (styles, { isSelected, isFocused }) => ({
    ...styles,
    background: isSelected ? '#dbf1fe' : isFocused ? '#FAFBFC' : 'white',
    color: '#414953',
    fontSize: 14,
    alignItems: 'center',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    width: '100%',
    ':active': {
      background: '#FAFBFC',
    },
  }),

  multiValue: () => ({
    display: 'flex',
    margin: 2,
    minWidth: 0,
    boxSizing: 'border-box',
    borderRadius: 4,
    backgroundColor: '#dbf1fe',
  }),

  multiValueLabel: () => ({
    borderRadius: 2,
    color: '#27282a',
    fontSize: 14,
    overflow: 'hidden',
    padding: 3,
    paddingLeft: 6,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
  }),

  clearValueIndicator: () => ({
    background: 'red',
  }),

  multiValueRemove: () => ({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 4,
    paddingRight: 4,
    borderRadius: 2,

    ':hover': {},
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }),

  indicatorsContainer: () => ({
    display: 'flex',
    alignSelf: 'stretch',
    alignItems: 'center',
  }),

  clearIndicator: () => ({
    display: 'flex',
    alignItems: 'center',
    color: '#9badbc',
    cursor: 'pointer',
  }),

  dropdownIndicator: () => ({
    cursor: 'pointer',
    color: '#9badbc',
    alignItems: 'center',
    display: 'flex',
    padding: '6px 8px',
    transition: 'color 150ms',
    boxSizing: 'border-box',
  }),

  input: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),

  valueContainer: (base) => ({
    ...base,
    padding: '2px 6px',
  }),

  singleValue: (base) => ({
    ...base,
    display: 'flex',
    flex: 1,
    minWidth: 0,
  }),

  placeholder: (base) => ({
    ...base,
    color: '#9BADBC',
  }),

  control: (base, state) => ({
    ...base,
    cursor: 'pointer',
    minHeight: 36,
    fontSize: '15px',
    background: state.isDisabled ? '#FAFBFC' : '#FFF',
    border: 'none',
    borderRadius: 6,
    transition: 'box-shadow .2s ease',
    boxShadow: state.isFocused
      ? `inset 0 0 0 1px ${fade('#148CFC', 0.9)},
      inset 0 2px 2px 0 ${fade('#000', 0.05)},
      0 0 0 2px ${fade('#98D5FD', 0.5)}`
      : `inset 0 0 0 1px ${fade('#083864', 0.1)},
      inset 0 2px 2px 0 ${fade('#000', 0.05)},
      0 0 0 0px ${fade('#98D5FD', 0)}`,
    '&:hover': {
      boxShadow: state.isFocused
        ? `inset 0 0 0 1px ${fade('#148CFC', 0.9)},
        inset 0 2px 2px 0 ${fade('#000', 0.05)},
        0 0 0 2px ${fade('#98D5FD', 0.5)}`
        : `inset 0 0 0 1px ${fade('#083864', 0.2)},
        inset 0 2px 2px 0 ${fade('#000', 0.05)},
        0 0 0 2px ${fade('#98D5FD', 0)}`,
    },
  }),
};
