import { FC } from 'react';
import { useParams } from 'react-router-dom';

import AuthLayout, { AuthLayoutHeader } from '~/components/layouts/AuthLayout';
import { useCurrentUser } from '~/hooks/account';

import { SignInWithHubspot } from '../shared/SignInWithHubspot';
import { Spinner } from '../Spinner';
import useUpgradeAuthFactors from './useUpgradeAuthFactors';

const SignUp: FC = function() {
  const { accountId } = useParams<{ accountId: string }>();
  const { data, isLoading } = useUpgradeAuthFactors(accountId);

  if (isLoading || !data) {
    return (
      <AuthLayout>
        <Spinner />
      </AuthLayout>
    );
  }

  let Component: () => JSX.Element;

  if (data.type === 'magic_link') {
    Component = MagicLinkSent;
  } else if (data.type === 'sso') {
    Component = SSORedirectInProgress;
  } else if (data.type === 'hubspot') {
    Component = HubSpotRedirect;
  } else {
    throw new Error('Unsupported auth factor type');
  }

  return (
    <AuthLayout>
      <Component />
    </AuthLayout>
  );
};

function MagicLinkSent() {
  const { data: user, isLoading } = useCurrentUser();

  if (isLoading || !user) {
    return <Spinner />;
  }

  return (
    <AuthLayoutHeader
      className="w-full"
      description={`We've sent an email to ${user.email}. Follow the link in that email to verify your information and switch to this account.`}
      header="Additional authentication required"
    />
  );
}

function SSORedirectInProgress() {
  return (
    <>
      <AuthLayoutHeader
        className="w-full"
        description="Sign in via your authentication provider to switch to this account."
        header="Redirecting to your identity provider"
      />
      <Spinner />
    </>
  );
}

function HubSpotRedirect() {
  return (
    <>
      <AuthLayoutHeader
        description="This account is managed by HubSpot. Sign in to switch to this account."
        header={<span className="font-semibold">Sign in with HubSpot</span>}
      />
      <SignInWithHubspot />
    </>
  );
}

export default SignUp;
