import classnames from 'classnames';
import { CKBadge, CKContainerProps, CKLayoutHorizontal } from 'clearkit';
import { FC } from 'react';

import { ProspectFilterOption } from '~/generated/graphql';

type FilterChipsProps = CKContainerProps & {
  options?: ProspectFilterOption[];
  title: string;
  filters: string[];
};

type FilterChipsComposition = {
  Icon: FC<CKContainerProps>;
};

export const FilterChip: FC<FilterChipsProps> & FilterChipsComposition = ({
  children,
  filters = [],
  options,
  title,
  ...rest
}) => {
  const filterLength = filters ? filters?.length : 0;
  const filterOverflow = filterLength - 1;

  if (filterLength === 0) return null;

  const firstFilter =
    options?.find((option) => option.value === filters[0])?.label ?? filters[0];

  return (
    <div
      {...rest}
      className="inline-flex text-xs font-medium text-blue-700 border border-blue-100 rounded px-1.5 divide-x divide-blue-100 bg-blue-0 gap-1.5"
    >
      {children}
      <CKLayoutHorizontal className={classnames({ 'pl-1.5': !!children })}>
        {title}
      </CKLayoutHorizontal>
      <CKLayoutHorizontal as="span" className="pl-1.5 py-0.5" gap=".5rem">
        <span className="truncate max-w-[24ch]">{firstFilter}</span>
        {filterOverflow > 0 ? (
          <CKBadge className="leading-tight rounded py-2px px-1.5 !text-xs">
            +{filterOverflow}
          </CKBadge>
        ) : null}
      </CKLayoutHorizontal>
    </div>
  );
};

FilterChip.Icon = ({ children, className, ...rest }) => (
  <span
    {...rest}
    className={classnames('text-blue-500 self-center', className)}
  >
    {children}
  </span>
);
FilterChip.Icon.displayName = 'FilterChip.Icon';
