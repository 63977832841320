import * as Sentry from '@sentry/browser';
import { FC, useEffect } from 'react';

import AuthLayout, { AuthLayoutHeader } from '~/components/layouts/AuthLayout';
import { useCookie } from '~/hooks/useCookie';
import useQueryParams from '~/hooks/useQueryParams';

import { SignInWithHubspot } from '../shared/SignInWithHubspot';

const SignUp: FC = function() {
  const params = useQueryParams();
  const plan = params.get('plan') || 'free';
  const [_, setCookie] = useCookie('onboarding_options', {
    sameSite: 'Strict',
    secure: true,
  });

  useEffect(() => {
    try {
      setCookie(
        JSON.stringify({
          plan,
        }),
      );
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [plan]);

  return (
    <AuthLayout>
      <AuthLayoutHeader
        className="w-full"
        header={
          <div className="w-full font-normal">Create your Clearbit account</div>
        }
      />

      <SignInWithHubspot buttonText="Sign up with HubSpot" />

      <div className="flex flex-col items-center">
        <div className="mb-6 text-sm text-center text-gray-600">
          <span>Already have an account?</span>
          <a
            className="pl-2 font-medium text-blue-600 hover:text-blue-500"
            href="/auth/login"
          >
            Sign in
          </a>
        </div>

        <div>
          <a
            className="text-sm font-medium text-blue-600 hover:text-blue-500"
            href="https://app.hubspot.com/signup-hubspot/"
          >
            Create a HubSpot account
          </a>
        </div>
      </div>
    </AuthLayout>
  );
};

export default SignUp;
