import Hubspot from '~/components/MultiPicklist/icons/HubspotIcon.svg';
import Salesforce from '~/components/MultiPicklist/icons/SalesforceIcon.svg';

export const SALESFORCE_TARGETS = {
  shared: [
    {
      value: 'salesforce_account',
      label: 'Salesforce account owners',
      icon: <Salesforce />,
    },
  ],
  person: [
    {
      value: 'salesforce_contact',
      label: 'Salesforce contact owners',
      icon: <Salesforce />,
    },
    {
      value: 'salesforce_lead',
      label: 'Salesforce lead owners',
      icon: <Salesforce />,
    },
  ],
  company: [],
};

export const HUBSPOT_TARGETS = {
  shared: [
    {
      value: 'hubspot_company',
      label: 'Hubspot company owners',
      icon: <Hubspot />,
    },
  ],
  person: [
    {
      value: 'hubspot_contact',
      label: 'Hubspot contact owners',
      icon: <Hubspot />,
    },
  ],
  company: [],
};

let targetLabels = {
  email: 'Email addresses',
};

[SALESFORCE_TARGETS, HUBSPOT_TARGETS].forEach((targetList) => {
  Object.keys(targetList).forEach((key) => {
    targetList[key].forEach((item) => {
      targetLabels[item.value] = item.label;
    });
  });
});

export const DELIVERY_TARGET_LABELS = targetLabels;

export function getDeliveryTargets(targetOptions, segmentType) {
  let targets = [...targetOptions.shared];

  if (segmentType === 'PersonSegment') {
    targets = [...targets, ...targetOptions.person];
  } else {
    targets = [...targets, ...targetOptions.company];
  }

  return targets;
}
