import { Sparkles } from '@clearkit/icons';
import { CKBannerNextProps, CKButton } from 'clearkit';
import { Link } from 'react-router-dom';

import { routes } from '~/components/app/appMainRoutes';
import { UpgradePlanBanner } from '~/components/CTABanner/UpgradePlanBanner';
import { AccountFlagEnum } from '~/generated/graphql';
import useFeatureFlag from '~/hooks/account/useFeatureFlag';

export const BannerUpgradePlan = (props: CKBannerNextProps) => {
  const showUpgradeBanner = useFeatureFlag(
    AccountFlagEnum.AllowShowUpgradeToPaidPlanCta,
  ).enabled;

  if (!showUpgradeBanner) {
    return null;
  }

  return (
    <UpgradePlanBanner {...props}>
      <UpgradePlanBanner.Icon>
        <Sparkles className="fill-gradient-br-yellow" />
      </UpgradePlanBanner.Icon>
      <UpgradePlanBanner.Header>Need more credits?</UpgradePlanBanner.Header>
      <UpgradePlanBanner.Body>
        We've designed plans that scale to meet the needs of your teams and
        business. Need more enterprise controls? We've got those too.
      </UpgradePlanBanner.Body>
      <UpgradePlanBanner.CTA>
        <CKButton
          as={Link}
          to={routes.plans}
          variant="bold"
          variantColor="blue"
        >
          Upgrade now
        </CKButton>
      </UpgradePlanBanner.CTA>
    </UpgradePlanBanner>
  );
};
