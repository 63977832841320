import { CreditsBasedTierEnum, ProductFeatureEnum } from '~/generated/graphql';

const legacySelfServePlans = [
  CreditsBasedTierEnum.FreeTier,
  CreditsBasedTierEnum.PaidTier,
];

const hubspotSelfServePlans = [
  CreditsBasedTierEnum.HubspotFreeTier,
  CreditsBasedTierEnum.HubspotPaidTier,
];

export const plans = (isHubspotAccount: boolean) => {
  if (isHubspotAccount) {
    return hubspotSelfServePlans;
  } else {
    return legacySelfServePlans;
  }
};

export const growthPlan = (isHubspotAccount: boolean) => {
  if (isHubspotAccount) {
    return CreditsBasedTierEnum.HubspotPaidTier;
  } else {
    return CreditsBasedTierEnum.PaidTier;
  }
};

export const LEGACY_FREE_TIER_CREDITS = 500;
export const HUBSPOT_FREE_TIER_CREDITS = 25;

export const planLimits = {
  [CreditsBasedTierEnum.FreeTier]: [LEGACY_FREE_TIER_CREDITS],
  [CreditsBasedTierEnum.PaidTier]: [1000, 1500, 2000],
  [CreditsBasedTierEnum.HubspotFreeTier]: [HUBSPOT_FREE_TIER_CREDITS],
  [CreditsBasedTierEnum.HubspotPaidTier]: [125, 250, 500, 750, 1000],
};

export const legacyPaidTierLimitToPriceMap = {
  [LEGACY_FREE_TIER_CREDITS]: 0,
  1000: 299,
  1500: 559,
  2000: 749,
};

/**
 * @todo add new high credit plans with correct pricing
 * 2000: ???,
 * 3000: ???,
 * 5000: ???,
 */
export const hubspotPaidTierLimitToPriceMap = {
  [HUBSPOT_FREE_TIER_CREDITS]: 0,
  125: 50,
  250: 75,
  500: 150,
  750: 225,
  1000: 275,
};

export const planTierLabelMap = {
  [ProductFeatureEnum.FreeTier]: 'Free',
  [ProductFeatureEnum.PaidTier]: 'Paid',
  [ProductFeatureEnum.HubspotFreeTier]: 'Free',
  [ProductFeatureEnum.HubspotPaidTier]: 'Paid',
};

export const creditOptions = (isHubspotAccount: boolean) => {
  const pricingTiers = isHubspotAccount
    ? hubspotPaidTierLimitToPriceMap
    : legacyPaidTierLimitToPriceMap;
  return Object.keys(pricingTiers).map((keyString) => {
    const key = parseInt(keyString, 10); // parse the key back into a number

    return {
      credits: key,
      cost: pricingTiers[key as keyof typeof pricingTiers],
    };
  });
};

export const getIsFreeCreditTier = ({
  credits,
  isHubspotAccount,
}: {
  credits: number;
  isHubspotAccount: boolean;
}) => {
  return isHubspotAccount
    ? credits === HUBSPOT_FREE_TIER_CREDITS
    : credits === LEGACY_FREE_TIER_CREDITS;
};

export const getCostPerMonth = ({
  credits,
  isHubspotAccount,
}: {
  credits: number;
  isHubspotAccount: boolean;
}) => {
  return isHubspotAccount
    ? hubspotPaidTierLimitToPriceMap[
        credits as keyof typeof hubspotPaidTierLimitToPriceMap
      ]
    : legacyPaidTierLimitToPriceMap[
        credits as keyof typeof legacyPaidTierLimitToPriceMap
      ];
};

export const formatCostPerMonth = (costPerMonth: number) => {
  return costPerMonth === 0 ? 'Free' : `$${costPerMonth} per month`;
};
