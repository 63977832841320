const styles = () => ({
  groupRoot: {
    marginBottom: '10px',

    '&&': {
      border: 'none',
      height: 'auto',
      padding: '16px 0',
      boxShadow: 'none',
    },
  },

  buttonRoot: {
    '&&': {
      margin: '0 8px',
      borderRadius: 8,

      border: '1px solid rgb(225, 233, 240)',
      boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
      backgroundColor: '#fff',
      height: 48,
      minWidth: 232,
      color: '#27282a',
      fontWeight: 'normal',
    },
  },

  buttonRootSelected: {
    '&&': {
      boxShadow:
        '0px 1px 3px 0px rgba(89, 97, 104, 0.15), 0px 3px 15px 0px rgba(89, 97, 104, 0.2)',
      border: 'none',
    },
  },

  radioRoot: {
    padding: 0,
  },

  toggleButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },

  radioConatiner: {
    display: 'flex',
    alignItems: 'center',
    color: '#27282a',
    fontWeight: 'normal',
  },

  grayLogo: {
    '& path': {
      fill: '#9badbc',
    },
  },

  rootContainerSelected: {
    fontWeight: 500,
  },
});

export default styles;
