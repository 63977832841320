import { useAddHubspot } from '~/components/CRMPresence/useAddHubspot';

export const AddHubSpotError = () => {
  const { hasValidationError, error } = useAddHubspot();

  if (!hasValidationError && !error) return null;

  return (
    <div className="mt-3 text-xs text-red-700">
      There was a problem adding this company to HubSpot. Try again.
    </div>
  );
};
