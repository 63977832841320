const styles = () => ({
  root: {
    position: 'relative',
    height: '100%',
    flex: '1 0 auto',
  },

  categoryHeaderRoot: {},
  categorySeparator: {
    height: 1,
    backgroundColor: '#e1e9f0',
  },

  optionMenuItemRoot: {},
  optionMenuItemSelected: {},
  option: {},
});

export default styles;
