import { Lock } from '@clearkit/icons';
import { CKBadge, CKButton, CKTooltip } from 'clearkit';
import { Link } from 'react-router-dom';
import { Subscribe } from 'unstated';

import UserContainer from '~/containers/User';
import FacebookIcon from '~/images/services/Facebook.svg';
import GoogleIcon from '~/images/services/Google.svg';
import {
  audienceSubtypes,
  audienceSubtypeServices,
  readableAudienceSubtype,
} from '~/lib/audiences';
import {
  destinationNameForType,
  destinationSubtitleForType,
  destinationTypes,
} from '~/lib/destinations';
import paths from '~/lib/paths';
import Logo from '~/pages/destinations/components/Logo';

import styles from './styles';

const AddDestinationOrAudience = ({
  destinationType,
  classes,
  segment,
  audienceSubtype,
  name,
  subtitle,
  footer,
  className,
  services,
  isBeta,
}) => {
  let url;
  let rel;
  if (audienceSubtype) {
    rel = `add-${audienceSubtype}-audience`;
    if (audienceSubtype === audienceSubtypes.companySegmentProspects) {
      url = paths.audienceNew(audienceSubtype, {
        segmentId: segment.id,
        segmentType: segment.type,
      });
    } else {
      url = paths.audienceNewSyncStep(audienceSubtype, {
        segmentId: segment.id,
        segmentType: segment.type,
      });
    }
  } else {
    rel = `add-${destinationType}-destination`;
    url = paths.segmentDestinationNew(segment.type, segment.id, {
      type: destinationType,
    });
  }

  return (
    <Subscribe to={[UserContainer]}>
      {(userContainer) => {
        let objectType;
        let objectSubtype;
        const objectAction = 'create';

        if (audienceSubtype) {
          objectType = 'audience';
          objectSubtype = audienceSubtype;
        } else {
          objectType = 'destination';
          objectSubtype = destinationType;
        }

        let hasPermission = userContainer.hasPermissionTo({
          objectType,
          objectSubtype,
          objectAction,
        });

        return (
          <div
            className={classnames(classes.destinationColumn, className)}
            rel={rel}
          >
            <div className={classes.destinationDetailsWrapper}>
              <Logo
                audienceSubtype={audienceSubtype}
                className={classes.destinationLogo}
                destinationType={destinationType}
              />
              <div className={classes.destinationNameWrapper}>
                <span className={classes.destinationName}>
                  {name}
                  {isBeta ? (
                    <CKBadge
                      className={'bg-gray-200 text-gray-600 ml-2'}
                      variant={'rounded'}
                    >
                      Beta
                    </CKBadge>
                  ) : null}
                </span>
                <span className={classes.destinationDescription}>
                  {subtitle}{' '}
                </span>
              </div>
              {hasPermission ? (
                <CKButton
                  as={Link}
                  className={classes.destinationButton}
                  isDisabled={!segment}
                  to={url}
                >
                  Create...
                </CKButton>
              ) : (
                <CKTooltip
                  placement="bottom"
                  tooltip="You don't have permission to access this feature. Contact
                      your team Admin for access."
                >
                  <div>
                    <CKButton
                      className={classes.destinationButton}
                      isDisabled
                      leftIcon={<Lock />}
                    >
                      Unlock {name}
                    </CKButton>
                  </div>
                </CKTooltip>
              )}
            </div>
            {services && (
              <>
                <hr className={classes.separator} />
                <div className={classes.footer}>
                  {services.includes('facebook') && (
                    <FacebookIcon style={{ width: 16, height: 16 }} />
                  )}
                  {(services.includes('google') ||
                    services.includes('managed_google')) && (
                    <GoogleIcon
                      style={{ width: 16, height: 16, marginLeft: 8 }}
                    />
                  )}
                  <span className={classes.footerText}>
                    Supports sync to{' '}
                    {services.length === 2 ? (
                      <>Facebook Retargeting & Google Remarketing</>
                    ) : (
                      <>Facebook Custom Audiences</>
                    )}
                  </span>
                </div>
              </>
            )}
            {footer && (
              <div className={classes.destinationSection}>{footer}</div>
            )}
          </div>
        );
      }}
    </Subscribe>
  );
};

AddDestinationOrAudience.propTypes = {
  classes: PropTypes.object,
  destinationType: PropTypes.oneOf(destinationTypes),
  index: PropTypes.number,
  segment: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    __typename: PropTypes.string,
  }),
  audienceSubtype: PropTypes.string,
  name: PropTypes.string,
  subtitle: PropTypes.node,
  footer: PropTypes.string,
  className: PropTypes.string,
  services: PropTypes.array,
  isBeta: PropTypes.bool,
};

export const StyledAddDestinationOrAudience = withStyles(styles)(
  AddDestinationOrAudience,
);

const audienceSubtitles = {
  [audienceSubtypes.contacts]: 'Sync all matches to a contacts audience.',
  [audienceSubtypes.visitors]:
    'Create a site visitor audience from people who visit your website.',
  [audienceSubtypes.companySegmentProspects]:
    'Find and advertise to people who work at companies within this audience.',
};

export const AddAudience = (props) => {
  const subtitle = audienceSubtitles[props.subtype];

  return (
    <StyledAddDestinationOrAudience
      audienceSubtype={props.subtype}
      name={`${readableAudienceSubtype(props.subtype)} Audience`}
      services={audienceSubtypeServices(props.subtype)}
      subtitle={subtitle}
      {...props}
    />
  );
};

export const AddDestination = (props) => {
  return (
    <StyledAddDestinationOrAudience
      destinationType={props.type}
      name={destinationNameForType(props.type)}
      subtitle={destinationSubtitleForType(props.type, props.segment.type)}
      {...props}
    />
  );
};

AddAudience.propTypes = AddDestination.propTypes = {
  type: PropTypes.string,
  segment: PropTypes.shape({
    type: PropTypes.string,
  }),
};
