import classnames from 'classnames';
import { CKBox, CKCheckbox, CKCheckboxProps, CKContainerProps } from 'clearkit';
import { FC } from 'react';

type UpgradePlanItemProps = Pick<CKCheckboxProps, 'checked'> & {
  onChange: (value: boolean) => void;
};

type UpgradePlanItemComposition = {
  Title: FC<CKContainerProps<HTMLSpanElement>>;
  Description: FC<CKContainerProps<HTMLSpanElement>>;
};

const titleDisplayName = 'UpgradePlanItem.Title';
const descriptionDisplayName = 'UpgradePlanItem.Description';

export const UpgradePlanItem: FC<UpgradePlanItemProps> &
  UpgradePlanItemComposition = ({ children, onChange, checked }) => {
  return (
    <CKBox className="p-4">
      <CKCheckbox
        checked={checked}
        gap="1rem"
        onChange={(event) => onChange(event.target.checked)}
      >
        {children}
      </CKCheckbox>
    </CKBox>
  );
};

UpgradePlanItem.Title = ({ children, className, ...rest }) => (
  <span {...rest} className={classnames('block mb-1 font-medium', className)}>
    {children}
  </span>
);
UpgradePlanItem.Title.displayName = titleDisplayName;

UpgradePlanItem.Description = ({ children, className, ...rest }) => (
  <span {...rest} className={classnames('text-base leading-normal', className)}>
    {children}
  </span>
);
UpgradePlanItem.Description.displayName = descriptionDisplayName;
