import { Config } from '@clearkit/icons';
import { CKNavigationBar, CKNavigationBarSwitcher } from 'clearkit';
import { useState } from 'react';

import { useCurrentUser } from '~/components/profile/useCurrentUser';
import { WhenUsagePreview } from '~/hooks/account/Entitlements/WhenUsagePreview';

import DeveloperPanel from '../DeveloperPanel';
import UserMenu from '../UserMenu/UserMenu';
import { UsageMenu } from './UsageMenu';

export const NavBarBottomMenu = () => {
  const currentUser = useCurrentUser();

  const [showDevPanel, setShowDevPanel] = useState(false);

  return (
    <CKNavigationBar.BottomMenu>
      <CKNavigationBar.ToggleButton />
      {process.env.NODE_ENV === 'development' ? (
        <>
          <CKNavigationBar.Item
            label="Dev Tools"
            onClick={() => {
              setShowDevPanel(true);
            }}
          >
            <CKNavigationBar.ItemLeft>
              <Config />
            </CKNavigationBar.ItemLeft>
          </CKNavigationBar.Item>

          <DeveloperPanel
            onClose={() => {
              setShowDevPanel(false);
            }}
            open={showDevPanel}
          />
        </>
      ) : null}
      <WhenUsagePreview>
        <UsageMenu />
      </WhenUsagePreview>
      <CKNavigationBarSwitcher
        accountName={currentUser?.account?.name ?? ''}
        domain={currentUser?.account?.domain ?? ''}
        isLoading={currentUser?.loading ?? true}
        name={currentUser?.user?.name ?? ''}
      >
        <CKNavigationBarSwitcher.Menu>
          <UserMenu />
        </CKNavigationBarSwitcher.Menu>
      </CKNavigationBarSwitcher>
    </CKNavigationBar.BottomMenu>
  );
};
NavBarBottomMenu.displayName = 'CKNavigationBar.BottomMenu';
