/* eslint-disable jsx-a11y/no-autofocus */

import { Mutation } from '@apollo/client/react/components';
import {
  CloseOrb as ClearIcon,
  FolderNew as NewCollection,
} from '@clearkit/icons';
import {
  CKField,
  CKFieldLeftElement,
  CKFieldRightElement,
  CKTextField,
} from 'clearkit';
import { useState } from 'react';

import SegmentsMenuItem from '../SegmentsMenuItem';
import mutation from './mutation.graphql';
import styles from './styles.style';

function AddCollectionButton(props) {
  const { classes, type, className } = props;
  const [showInput, setShowInput] = useState(false);

  if (!showInput) {
    return (
      <SegmentsMenuItem
        className={classnames(
          className,
          'fill-current focus:text-gray-900 hover:text-gray-900',
        )}
        onClick={() => setShowInput(!showInput)}
        role="button"
        tabIndex="0"
      >
        <NewCollection
          className={classnames(
            classes.newCollectionIcon,
            'fill-current focus:text-gray-900',
          )}
        />
        <span>New collection</span>
      </SegmentsMenuItem>
    );
  }

  return (
    <>
      <Mutation
        mutation={mutation}
        onCompleted={() => {
          setShowInput(false);
        }}
        refetchQueries={['SegmentsMenu']}
      >
        {(mutation) => (
          <CKField className={classnames(classes.newCollectionInput)}>
            <CKTextField
              autoComplete="off"
              autoFocus
              className="h-9"
              name="search"
              onBlur={() => {
                setShowInput(false);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  const name = event.target.value.trim();
                  mutation({ variables: { input: { type, name } } });
                } else if (event.key === 'Escape') {
                  setShowInput(false);
                }
              }}
              placeholder="Name this collection"
            />
            <CKFieldLeftElement>
              <NewCollection
                className={
                  (classes.newCollectionIcon, 'fill-current text-gray-900')
                }
              />
            </CKFieldLeftElement>
            <CKFieldRightElement isInteractive>
              <ClearIcon
                className={classnames(
                  'cursor-pointer opacity-40 hover:opacity-70 transition ease-linear duration-200',
                )}
                height="16"
                onClick={() => {
                  setShowInput(false);
                }}
                width="16"
              />
            </CKFieldRightElement>
          </CKField>
        )}
      </Mutation>
    </>
  );
}

AddCollectionButton.propTypes = {
  classes: PropTypes.object,
  type: PropTypes.oneOf(['Company', 'Person']).isRequired,
};

export default withStyles(styles)(AddCollectionButton);
