import { CKLayoutGridAuto } from 'clearkit';

import { PageLayout } from '~/components/PageLayout';

import { OverviewCard } from './OverviewCard';
import { OverviewChart } from './OverviewChart';

export const UsageOverviewEnrichment = () => {
  return (
    <PageLayout.Content>
      <CKLayoutGridAuto minWidth="32rem">
        <OverviewCard />
        <OverviewChart />
      </CKLayoutGridAuto>
    </PageLayout.Content>
  );
};
