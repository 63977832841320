import { GoExternal } from '@clearkit/icons';
import { CKActionMenu } from 'clearkit';

import styles from './styles';

const TwoLineMenuItem = ({
  title,
  subtitle,
  href,
  icon,
  classes,
  externalLinkClass,
}) => (
  <CKActionMenu.Item
    as="a"
    className={classes.menuItem}
    href={href}
    icon={icon}
    rel="noopener noreferrer"
    target="blank"
  >
    <span className="flex flex-row items-center flex-grow">
      <span className="flex flex-col items-start content-start flex-grow py-1 pr-2 truncate">
        <span className="block max-w-full font-medium leading-snug text-gray-900 truncate">
          {title}
        </span>
        <span className="block max-w-full text-xs font-normal leading-snug truncate">
          {subtitle}
        </span>
      </span>
      <GoExternal
        className={classnames('ml-auto flex-shrink-0', externalLinkClass)}
      />
    </span>
  </CKActionMenu.Item>
);

TwoLineMenuItem.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
};

export default withStyles(styles)(TwoLineMenuItem);
