import { ButtonNewList } from './ButtonNewList';

export const SidebarEmptyState = () => {
  return (
    <div className="flex flex-col items-center p-6 mx-6 text-center ck-box ck-box--well gap-2">
      <h3 className="font-semibold">Welcome to Target Markets</h3>

      <p className="text-sm">
        Build a list of target companies using attributes such as industry,
        number of employees, technology used, and more.
      </p>

      <ButtonNewList />
    </div>
  );
};
