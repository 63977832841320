import { CKBadge } from 'clearkit';

import { readableObjectTypeName } from '~/lib/dataEnrichment';

const FieldMappingHeader = ({ integration, fieldMappings, objectType }) => {
  return (
    <div
      className="grid grid-cols-1fr-auto gap-4"
      data-testid="fieldMappingConfigCardHeader"
    >
      <div className="text-base">
        <h3 className="mb-2 font-medium">Field Mappings</h3>
        <p>
          Choose the fields for{' '}
          <span className="capitalize">{integration}</span>{' '}
          {readableObjectTypeName(objectType.name)} that Clearbit will enrich
          and sync, and your data overwrite preferences.
        </p>
      </div>
      {fieldMappings.length > 0 ? (
        <CKBadge
          className="self-start text-xs text-green-700 bg-green-100"
          variant="default"
        >
          {fieldMappings.length} field mappings
        </CKBadge>
      ) : (
        <CKBadge
          className="self-start text-xs text-gray-600 bg-gray-100"
          variant="default"
        >
          No field mappings
        </CKBadge>
      )}
    </div>
  );
};

export default FieldMappingHeader;
