import { CKMultiPickerChips, CKSelectableItem, CKSelectNext } from 'clearkit';
import React, { FC, useState } from 'react';

type PredicateSelectorProps = {
  options: CKSelectableItem[];
  onSelectedItemsChange?: (newValue: string[]) => void;
  selectedItems: string[];
  typeName: string;
  placeholder: string;
  icon: React.ReactNode;
  style?: React.CSSProperties;
};

const PredicateSelector: FC<PredicateSelectorProps> = ({
  options,
  onSelectedItemsChange,
  selectedItems,
  typeName,
  placeholder,
  icon,
  style,
}) => {
  const [isSelectAll, setIsSelectAll] = useState(!selectedItems?.length);

  const selectOptions = {
    any: {
      value: 'any',
      label: `Any ${typeName}`,
    },
    specific: {
      value: 'specific',
      label: `A specific ${typeName}`,
    },
  };

  const selected = selectOptions[isSelectAll ? 'any' : 'specific'];

  const selectedOptions = options.filter(
    ({ value }) => selectedItems.indexOf(value) > -1,
  );

  return (
    <div className="w-full grid grid-cols-auto-1fr gap-4" style={style}>
      <CKSelectNext
        className="min-w-80"
        items={Object.values(selectOptions)}
        onChange={({ value }) => {
          const all = value === 'any';
          setIsSelectAll(all);

          if (all && onSelectedItemsChange) {
            onSelectedItemsChange([]);
          }
        }}
        placeholder=""
        value={selected}
      >
        <CKSelectNext.IconLeft>{icon}</CKSelectNext.IconLeft>
      </CKSelectNext>
      <CKMultiPickerChips
        className="min-w-0"
        isDisabled={isSelectAll}
        items={options}
        onChange={(newValue) => {
          onSelectedItemsChange &&
            onSelectedItemsChange(newValue.map(({ value }) => value));
        }}
        placeholder={placeholder}
        value={selectedOptions}
      />
    </div>
  );
};

export default PredicateSelector;
