import get from 'lodash/get';

import { audienceSubtypes } from '~/lib/audiences';
import {
  destinationTypeCustomHtml,
  destinationTypeEmail,
  destinationTypeFacebookConversion,
  destinationTypeGoogleConversion,
  destinationTypeSalesforce,
  destinationTypeSlack,
  destinationTypeWebhook,
} from '~/lib/destinations';
import { readableSegmentType, segmentTypes } from '~/lib/segments';

import EmailDestinationDescription from '../destinationConfigs/Email/DestinationDescription';
import SlackDestinationDescription from '../destinationConfigs/Slack/DestinationDescription';

export const description = (destination, slackAccountConnector) => {
  const { config = {}, type } = destination;

  if (type === destinationTypeCustomHtml) {
    return (
      <>
        Will render your <strong>custom HTML</strong>
      </>
    );
  } else if (type === destinationTypeEmail) {
    return <EmailDestinationDescription config={config} />;
  } else if (type === destinationTypeFacebookConversion) {
    return <>Will send event to Facebook</>;
  } else if (type === destinationTypeGoogleConversion) {
    return <>Will send conversion to Google Ads</>;
  } else if (type === destinationTypeWebhook) {
    return <>Will deliver to {<strong>{config.url}</strong>}</>;
  } else if (type === destinationTypeSalesforce) {
    return (
      <>
        Will{' '}
        {get(config, 'object') === 'chatter' ? (
          <>
            post a message to <strong>Salesforce chatter</strong>
          </>
        ) : (
          <>
            <strong>create a new task</strong> in Salesforce
          </>
        )}
      </>
    );
  } else if (type === destinationTypeSlack) {
    return (
      <SlackDestinationDescription
        accountConnector={slackAccountConnector}
        config={config}
      />
    );
  } else {
    return destination.description;
  }
};

const SECONDS = {
  months: 2592000,
  weeks: 604800,
  days: 86400,
};

export function timePeriod(exclusionTimeframe, segmentType) {
  if (!exclusionTimeframe || exclusionTimeframe === 0) return '';

  let result;
  let divisor;

  for (let key in SECONDS) {
    divisor = exclusionTimeframe / SECONDS[key];

    if (divisor === 1) {
      key = key.substring(0, key.length - 1);
    }

    if (Number.isInteger(divisor) && divisor >= 1) {
      result = `${divisor} ${key}`;
      break;
    }
  }

  if (!result) return '';

  return (
    <>
      - Excludes {readableSegmentType(segmentType)} delivered in the last{' '}
      <strong>{result}</strong>
    </>
  );
}

export const audienceSubTypesToShowAsDestinations = {
  [segmentTypes.company]: [
    audienceSubtypes.companySegmentProspects,
    audienceSubtypes.contacts,
    audienceSubtypes.visitors,
  ],
  [segmentTypes.person]: [audienceSubtypes.contacts],
};
