const styles = () => ({
  question: {
    marginLeft: 9,
  },

  errorText: {
    margin: '0 0 0 9px',
  },
});

export default styles;
