import { ConnectionInterstitialContent } from '~/components/ConnectionInterstitial';
import {
  accountConnectors,
  AccountConnectorService,
} from '~/lib/configuration';

import GoogleSignInButton from '../GoogleConnectionForm/GoogleSignInButton';

const learnMoreLink =
  'https://help.clearbit.com/hc/en-us/articles/4405689303319-Connect-Google-Ads-Manager-to-Clearbit';

type GoogleConnectionFormProps = {
  onAuth: (config: AccountConnectorService) => void;
};

export default function GoogleConnectionForm({
  onAuth,
}: GoogleConnectionFormProps) {
  return (
    <ConnectionInterstitialContent
      button={
        <GoogleSignInButton onClick={() => onAuth(accountConnectors.google)} />
      }
      helpLink={learnMoreLink}
      text="Connect your Google Ads account to sync advertising audiences and conversion tracking data."
    />
  );
}
