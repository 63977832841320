import { ReactNode } from 'react';

import DefaultLayout from '~/components/layouts/DefaultLayout';
import { Configuration, configurationNameForType } from '~/lib/configuration';

import AccountConnectorAction from './AccountConnectorAction';
import ConnectInterstitial from './ConnectInterstitial';
import { useAccountConnector } from './useAccountConnector';

type ConnectorLayoutProps = {
  breadcrumb?: any[];
  children: ReactNode | ((args: { accountConnector: any }) => ReactNode);
  connectorLocation: string;
  integration: Configuration;
  tabs?: ReactNode;
  tile: ReactNode;
  title?: string;
  subtitle?: string;
};

const ConnectorLayout = ({
  integration,
  children,
  connectorLocation,
  tabs,
  title = configurationNameForType(integration),
  ...layoutProps
}: ConnectorLayoutProps) => {
  const {
    accountConnector,
    isAccountConnectorComplete,
    loading,
    refetch,
  } = useAccountConnector(integration);

  return (
    <DefaultLayout
      {...layoutProps}
      actions={
        isAccountConnectorComplete ? (
          <AccountConnectorAction accountConnector={accountConnector} />
        ) : null
      }
      background="white"
      border
      loading={loading}
      shadow={false}
      tabs={isAccountConnectorComplete ? tabs : null}
      title={title}
    >
      {isAccountConnectorComplete ? (
        <>{children}</>
      ) : (
        <ConnectInterstitial
          connectorLocation={connectorLocation}
          integration={integration}
          onSuccess={() => refetch()}
        />
      )}
    </DefaultLayout>
  );
};

export default ConnectorLayout;
