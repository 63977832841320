import capitalize from 'lodash/capitalize';

import Chevron from './Chevron.svg';
import styles from './styles';

const ObjectDebugView = ({ object, objectKey, classes }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={classes.object}>
      <button
        className={classnames(classes.button, 'flex items-center')}
        onClick={() => setOpen(!open)}
      >
        <Chevron
          className={classnames(classes.chevron, {
            [classes.openChevron]: open,
          })}
        />
        {capitalize(objectKey)}
      </button>
      {open && (
        <pre className={classes.pre}>{JSON.stringify(object, null, 2)}</pre>
      )}
    </div>
  );
};

ObjectDebugView.propTypes = {
  object: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  objectKey: PropTypes.string,
  classes: PropTypes.object,
};

const DebugView = ({ classes = {}, debug }) => {
  if (localStorage.getItem('debug') !== 'true') {
    return null;
  }

  return (
    <div className={classes.root}>
      {Object.keys(debug).map((key) => (
        <ObjectDebugView
          classes={classes}
          key={key}
          object={debug[key]}
          objectKey={key}
        />
      ))}
    </div>
  );
};

DebugView.propTypes = {
  classes: PropTypes.object,
  debug: PropTypes.object,
};

export default withStyles(styles)(DebugView);
