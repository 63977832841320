import countries from 'i18n-iso-countries';
import enIsoCountries from 'i18n-iso-countries/langs/en.json';
import Cookies from 'js-cookie';
import startCase from 'lodash/startCase';
import URLSearchParams from 'url-search-params';

countries.registerLocale(enIsoCountries);
export const countryNames = countries.getNames('en');

export function jsonEqual(a, b) {
  return JSON.stringify(a) === JSON.stringify(b);
}

export function isSegmentEnabled() {
  return window.analytics && window.analytics.user;
}

export function getSegmentAnonymousID() {
  let segmentAnonymousID;

  // Segment's anonymous ID
  if (isSegmentEnabled()) {
    segmentAnonymousID = window.analytics.user().anonymousId();
  }

  return segmentAnonymousID;
}

const FREE_EMAIL_PROVIDERS = [
  'gmail.com',
  'hotmail.com',
  'msn.com',
  'yahoo.com',
  'googlemail.com',
  'qq.com',
  'mail.ru',
  'yandex.com',
];

export function isFreeEmailProvider(email) {
  let domain = (email || '').split('@', 2)[1];

  if (!domain) return;

  return FREE_EMAIL_PROVIDERS.includes(domain);
}

export function isValidHttpUrl(url) {
  const regex = new RegExp('^(http|https)://', 'i');

  return regex.test(url);
}

export function getCookie(name) {
  return Cookies.get(name);
}

export function getParam(name) {
  let params = new URLSearchParams(window.location.search);
  return params.get(name);
}

export function paramaterize(urlParams) {
  if (!urlParams) return '';
  const joinByEquals = (pair) => pair.join('=');
  const params = Object.entries(urlParams)
    .map(joinByEquals)
    .join('&');

  return params;
}

export function formatColumnLabel(value) {
  var humanValue = value
    .replace('enrichment->', 'Enrichment: ')
    .replace('traits->', 'Trait: ')
    .replace('values->>', 'Form Value: ')
    .replace(/->/g, ' ')
    .replace(/_/g, ' ');

  humanValue = startCase(humanValue);

  return humanValue;
}

export function deepDig(object, key, fallback = null) {
  const parts = key.split('->');

  let value = object;

  for (var i = 0; i < parts.length; i++) {
    value = (value || {})[parts[i]];
  }

  return value || fallback;
}

export function flattenObjectForDisplay(object) {
  return Object.assign(
    {},
    ...(function _flatten(objectBit, path = null) {
      return [].concat(
        ...Object.keys(objectBit || {}).map((key) => {
          switch (typeof objectBit[key]) {
            case 'object':
              return _flatten(
                objectBit[key],
                `${path ? path + ':' : ''}${key}`,
              );
            case 'array':
              return {
                [`${path ? path + ':' : ''}${key}`]: objectBit[key].join(', '),
              };
            default:
              return { [`${path ? path + ':' : ''}${key}`]: objectBit[key] };
          }
        }),
      );
    })(object),
  );
}

export const compact = (obj) => {
  let newObj = {};
  Object.keys(obj).forEach((prop) => {
    if (obj[prop]) {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
};

export const flattenDeep = (array) => {
  return array.reduce((accumulator, value) => {
    if (Array.isArray(value)) {
      return accumulator.concat(flattenDeep(value));
    } else {
      return accumulator.concat(value);
    }
  }, []);
};

export const validateDomain = (domain) => {
  const regex = new RegExp(/^\w.+\.[\w.]+$/);

  return regex.test(domain);
};

export const validateEmail = (email) => {
  const regex = new RegExp(/^.+@.+\..+$/);

  return regex.test(email);
};

export const setEqual = (setA, setB) => {
  if (setA.size !== setB.size) return false;

  for (let value of setA) {
    if (!setB.has(value)) return false;
  }

  return true;
};

export const scrollToBottom = (className) => {
  const component = document.querySelector(`.${className}`);

  if (!component) return;

  component.scrollBy({
    top: component.offsetHeight,
    left: 0,
    behavior: 'smooth',
  });
};

export const stringifyBooleanValues = (obj) => {
  let newObj = {};
  Object.keys(obj).forEach((prop) => {
    if (typeof obj[prop] === 'boolean') {
      newObj[prop] = obj[prop].toString();
    } else {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
};

export const getDevicePixelRatio = () => {
  const { screen } = window;
  var pixelRatio = 1;

  if (
    typeof screen === 'object' &&
    'deviceXDPI' in screen &&
    'logicalXDPI' in screen
  ) {
    // IE mobile or IE
    pixelRatio = screen.deviceXDPI / screen.logicalXDPI;
    // eslint-disable-next-line no-prototype-builtins
  } else if (window.hasOwnProperty('devicePixelRatio')) {
    // other devices
    pixelRatio = window.devicePixelRatio;
  }

  return pixelRatio;
};
