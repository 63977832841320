import { CKBox, CKChartBar } from 'clearkit';
import { useMemo } from 'react';

import { ChartEmptyOverlay } from '~/components/ChartEmptyOverlay';
import GraphqlError from '~/components/GraphqlError';
import LoadingArea from '~/components/LoadingArea';
import {
  SubscriptionBillingTermEnum,
  UsageBreakdownGranularityEnum,
  useUsageBreakdownQuery,
} from '~/generated/graphql';

import {
  tooltipTitleFormatter,
  xAxisFormatter,
  yAxisFormatter,
} from '../utils/formatHelpers';
import { gridYLinesBuilder } from '../utils/gridLinesHelper';
import { buildMarker } from '../utils/markerHelper';
import { UsageBreakDownDatum } from './types';

export type MonthlyUsageChartProps = {
  meteredUnit: string;
  usageLimit: number | null | undefined;
  startDate: any;
  endDate: any;
  usageTerm: SubscriptionBillingTermEnum;
  title?: string | null;
  usageMetricGraph: Record<string, unknown>;
  includeLimitMarker?: boolean;
  name: string;
};

export default function MonthlyUsageChart({
  meteredUnit,
  usageLimit,
  startDate,
  endDate,
  usageTerm,
  title,
  usageMetricGraph,
  name,
  includeLimitMarker = true,
}: MonthlyUsageChartProps) {
  const { data, loading, error, refetch } = useUsageBreakdownQuery({
    variables: {
      usageTerm: usageTerm,
      startDate: startDate,
      endDate: endDate,
      usageMetricGraph: usageMetricGraph,
      granularity: UsageBreakdownGranularityEnum.Day,
    },
  });

  const usageBreakdown = data?.usageBreakdown[
    name?.toLocaleLowerCase()
  ] as UsageBreakDownDatum[];

  const maxValue = useMemo(() => {
    return Math.max(...(usageBreakdown || []).map((d) => d.uniques));
  }, [usageBreakdown]);

  if (error) {
    return <GraphqlError error={error} loading={loading} refetch={refetch} />;
  }

  if (loading || !usageBreakdown) {
    return <LoadingArea loading={loading} />;
  }

  const gridYLines = gridYLinesBuilder(maxValue);

  const formattedData = usageBreakdown?.map((usage) => {
    return {
      ...usage,
      [meteredUnit]: usage.uniques,
    };
  });

  const showEmptyState = !usageBreakdown?.filter((datum) => datum.uniques)
    .length;
  const bottomAxisValues = formattedData
    .filter((_item, index) => index % 4 === 0)
    .map((item) => item.startDate);

  return (
    <CKBox className="w-full p-6 h-85">
      <h3 className="text-base font-medium">{title || 'Usage History'}</h3>
      <ChartEmptyOverlay showEmptyState={showEmptyState}>
        <CKChartBar
          axisBottom={{
            tickValues: bottomAxisValues,
          }}
          axisLeft={{ tickValues: gridYLines }}
          axisMarker={includeLimitMarker ? buildMarker(usageLimit) : undefined}
          className="w-full h-full"
          data={formattedData}
          enableGridY
          gridYValues={gridYLines}
          indexBy="endDate"
          keys={[meteredUnit]}
          maxValue={gridYLines[gridYLines.length - 1]}
          minValue={0}
          padding={0.8}
          toolTipTitleFormat={(value) => tooltipTitleFormatter(value)}
          xValueFormat={xAxisFormatter}
          yValueFormat={yAxisFormatter}
        />
      </ChartEmptyOverlay>
    </CKBox>
  );
}
