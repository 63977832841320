import { NoResultsPeople } from '@clearkit/icons';
import { CKContainerProps } from 'clearkit';
import { Link } from 'react-router-dom';

import { EmptyState } from '../ProspectList/EmptyState';

export function ExportsPendingEmptyState(props: CKContainerProps) {
  return (
    <div className="w-full h-full">
      <EmptyState {...props}>
        <EmptyState.Icon>
          <NoResultsPeople />
        </EmptyState.Icon>
        <EmptyState.Title>No pending exports</EmptyState.Title>
        <EmptyState.Description className="max-w-[40ch]">
          In-progress exports to CSV or to your CRM will appear here, as well as
          CSV exports ready for download. For a list ot previous exports, or
          exports to your CRM, view your team's{' '}
          <Link to="/exports/history">export history</Link>.
        </EmptyState.Description>
      </EmptyState>
    </div>
  );
}
