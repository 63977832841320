import { CKButton, CKLayoutHorizontal } from 'clearkit';

import { ButtonContainer } from '~/components/unified/ButtonContainer';

import { useProspector } from '../useProspector';
import { hasAnyFilters } from '../util';
import { ListManagementMenu } from './ListManagementMenu';
import { ProspectorFilters } from './ProspectorFilters';
import { ProspectorSidebar } from './ProspectorSidebar';
import { SidebarBreadcrumbs } from './SidebarBreadcrumbs';

const NEW_LIST_TITLE = 'New Prospect List';

export const SidebarFilters = () => {
  const [state, send] = useProspector();

  const { isNewList, companyQuery, personQuery, prospectList } = state.context;
  const isLoading = state.matches('loading');
  const isEditingList = !isNewList;
  const title = isNewList
    ? NEW_LIST_TITLE
    : prospectList?.name || NEW_LIST_TITLE;

  const handleSave = () => {
    send('SAVE');
  };

  const handleReset = () => {
    send('DISCARD_CHANGES');
  };

  const hasFilters = hasAnyFilters({ companyQuery, personQuery });

  return (
    <ProspectorSidebar title="Filters">
      <ProspectorSidebar.Heading>Filters</ProspectorSidebar.Heading>
      <SidebarBreadcrumbs loading={isLoading} title={title} />
      {isLoading ? null : <ProspectorFilters />}
      <ProspectorSidebar.Footer>
        <CKLayoutHorizontal>
          <CKButton
            isDisabled={isLoading || !hasFilters}
            onClick={handleSave}
            variant="bold"
            variantColor="blue"
          >
            Save list
          </CKButton>

          <ButtonContainer>
            <CKButton isDisabled={isLoading} onClick={handleReset}>
              Discard
            </CKButton>
            {isEditingList ? <ListManagementMenu /> : null}
          </ButtonContainer>
        </CKLayoutHorizontal>
      </ProspectorSidebar.Footer>
    </ProspectorSidebar>
  );
};
