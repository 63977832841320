import { Global, Location, Tags, Team } from '@clearkit/icons';
import classnames from 'classnames';
import { CKBadge, CKContainerProps } from 'clearkit';
import { useEffect, useState } from 'react';

import { ProspectFilterOption, ProspectFilters } from '~/generated/graphql';

import { FiltersCompanies as FiltersCompaniesType } from '../prospector/Sidebar/types';
import { extractTopLevelDomain } from '../prospector/util';
import { FilterInputChips } from './FilterInputChips';
import { FilterMultiPicker } from './FilterMultiPicker';

type FiltersCompaniesProps = Omit<CKContainerProps, 'onChange'> & {
  filters: FiltersCompaniesType;
  prospectFilters: ProspectFilters;
  onChange: (value: FiltersCompaniesType) => void;
};

export const FiltersCompanies = ({
  className,
  filters,
  onChange,
  prospectFilters,
  ...rest
}: FiltersCompaniesProps) => {
  const [domains, setDomains] = useState<string[]>(filters.domains ?? []);
  const [hasDomainError, setHasDomainError] = useState(false);

  const updateFilters = (key: keyof FiltersCompaniesType) => (
    value: string[],
  ) => {
    const newFilters = { ...filters, [key]: value };
    onChange(newFilters);
  };

  const handleDomainChange = (value: string[]) => {
    const cleanedValue = value.map((url) => extractTopLevelDomain(url));
    const hasError = cleanedValue.some((domain) => !domain.includes('.'));

    if (hasError) {
      setHasDomainError(true);
    } else {
      setDomains(cleanedValue);
      updateFilters('domains')(cleanedValue);
    }
  };

  const mapCountsToAccessory = (
    filters: ProspectFilterOption[] | undefined,
  ) => {
    return (
      filters?.map((filter) => ({
        label: filter.label,
        value: filter.value,
        accessory: <CKBadge>{filter.count?.toLocaleString()}</CKBadge>,
      })) ?? []
    );
  };

  useEffect(() => {
    setDomains(filters.domains ?? []);
  }, [filters.domains]);

  return (
    <div
      {...rest}
      className={classnames('divide-y space-y-4 divide-gray-200', className)}
    >
      <FilterInputChips
        onChange={handleDomainChange}
        placeholder="Add domains"
        splitTokens={[',', ' ']}
        value={domains}
      >
        <FilterInputChips.Icon>
          <Global />
        </FilterInputChips.Icon>
        <FilterInputChips.Title>Domains</FilterInputChips.Title>
        <FilterInputChips.HelpText>
          <strong>Pro tip:</strong> paste comma separated values or rows from a
          spreadsheet to add multiple domains at once.
        </FilterInputChips.HelpText>
        <FilterInputChips.ErrorMessage isVisible={hasDomainError}>
          Enter a valid domain (e.g., example.com)
        </FilterInputChips.ErrorMessage>
      </FilterInputChips>
      <FilterMultiPicker
        className="pt-4"
        hasFilter={false}
        items={prospectFilters?.employeesRanges}
        onChange={updateFilters('employeesRanges')}
        value={filters.employeesRanges ?? []}
      >
        <FilterMultiPicker.Icon>
          <Team />
        </FilterMultiPicker.Icon>
        <FilterMultiPicker.Title>Employee Ranges</FilterMultiPicker.Title>
      </FilterMultiPicker>
      <FilterMultiPicker
        className="pt-4"
        items={prospectFilters?.industries}
        onChange={updateFilters('industries')}
        value={filters.industries ?? []}
      >
        <FilterMultiPicker.Icon>
          <Tags />
        </FilterMultiPicker.Icon>
        <FilterMultiPicker.Title>Industries</FilterMultiPicker.Title>
      </FilterMultiPicker>
      <FilterMultiPicker
        className="pt-4"
        items={prospectFilters?.companyCountries}
        onChange={updateFilters('companyCountries')}
        value={filters.companyCountries ?? []}
      >
        <FilterMultiPicker.Icon>
          <Location />
        </FilterMultiPicker.Icon>
        <FilterMultiPicker.Title>Countries</FilterMultiPicker.Title>
      </FilterMultiPicker>
      <FilterInputChips
        className="pt-4"
        onChange={updateFilters('companyStates')}
        placeholder="Add states, regions, or provinces"
        splitTokens={[',']}
        value={filters.companyStates ?? []}
      >
        <FilterMultiPicker.Icon>
          <Location />
        </FilterMultiPicker.Icon>
        <FilterMultiPicker.Title>States / Regions</FilterMultiPicker.Title>
        <FilterInputChips.HelpText>
          Enter state, region, province (e.g., California or Ontario). Also
          accepts abbreviations (e.g., CA or ON).
        </FilterInputChips.HelpText>
      </FilterInputChips>
      <FilterInputChips
        className="pt-4"
        onChange={updateFilters('companyCities')}
        placeholder="Add cities"
        splitTokens={[',']}
        value={filters.companyCities ?? []}
      >
        <FilterMultiPicker.Icon>
          <Location />
        </FilterMultiPicker.Icon>
        <FilterMultiPicker.Title>Cities</FilterMultiPicker.Title>
      </FilterInputChips>
      <FilterMultiPicker
        className="pt-4"
        items={mapCountsToAccessory(prospectFilters?.companyTags)}
        onChange={updateFilters('companyTags')}
        popoverPlacement="top-start"
        value={filters.companyTags}
      >
        <FilterMultiPicker.Icon>
          <Tags />
        </FilterMultiPicker.Icon>
        <FilterMultiPicker.Title>Tags</FilterMultiPicker.Title>
      </FilterMultiPicker>
      <FilterMultiPicker
        className="pt-4"
        items={mapCountsToAccessory(prospectFilters?.companyTech)}
        onChange={updateFilters('companyTech')}
        popoverPlacement="top-start"
        value={filters.companyTech}
      >
        <FilterMultiPicker.Icon>
          <Tags />
        </FilterMultiPicker.Icon>
        <FilterMultiPicker.Title>Technologies</FilterMultiPicker.Title>
      </FilterMultiPicker>
    </div>
  );
};
