const styles = () => ({
  // FormLabel

  formLabelRoot: {
    color: '#596168',
    fontSize: 15,
    marginBottom: '4px',
  },

  formLabelFocused: {
    color: '#85BBFF !important', // think this is a bug
  },
});

export default styles;
