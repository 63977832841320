import { uniq } from 'lodash';

import { useMainICP } from '~/components/TargetMarkets/hooks/useMainICP';
import { useTargetMarketCompaniesQuery } from '~/generated/graphql';

import { CompanyType } from '../HomeCompanies/types';

const DEFAULT_TARGET_MARKET_QUERY = {
  country: [],
  description: [],
  employees: [],
  industry: [],
  raised: [],
  tag: [],
  tech: [],
  type: [],
};

export const COMPANY_LIMIT = 12;
const randomize = true;

export const useHomeCompanies = () => {
  const {
    mainICPSearch,
    mainICPId,
    hasTargetMarketDefinedIcp,
    loadingTargetMarkets,
  } = useMainICP();

  const {
    data: targetMarketCompaniesData,
    loading: loadingTargetMarketCompanies,
  } = useTargetMarketCompaniesQuery({
    variables: {
      ...DEFAULT_TARGET_MARKET_QUERY,
      ...mainICPSearch,
      limit: COMPANY_LIMIT,
      randomize,
    },
  });
  const isLoading = loadingTargetMarkets || loadingTargetMarketCompanies;

  const companies =
    !isLoading && targetMarketCompaniesData?.targetMarketCompanies?.companies;

  const companyCount = targetMarketCompaniesData?.targetMarketCompanies.total;

  const mainICPCompanies: CompanyType[] = !isLoading
    ? companies?.map((company: any) => {
        const companyTags = company?.tags;
        const employeeRange =
          company.employeesRange && `${company.employeesRange} employees`;

        const businessModelTag =
          companyTags &&
          companyTags?.length > 0 &&
          companyTags.find((tag: string) => tag.includes('B2'));

        return {
          name: company.name,
          domain: company.domain,
          tags: uniq([
            company.country || 'Unknown country',
            company.industry || 'Unknown industry',
            employeeRange,
            businessModelTag,
            ...companyTags.slice(0, 3),
          ]).filter(Boolean),
        };
      })
    : [];

  return {
    companyCount,
    hasTargetMarketDefinedIcp,
    isLoading,
    mainICPCompanies,
    mainICPId,
  };
};
