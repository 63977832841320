import { components } from 'react-select';

import CaretIcon from '~/components/icons/CaretIcon';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretIcon />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
