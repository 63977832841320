import { CKContainerProps } from 'clearkit';

import { useEntitlements } from '../useEntitlements';
import { EntitlementWrapper } from './EntitlementWrapper';

export const WhenUsagePreview = (props: CKContainerProps) => {
  const { hasAccessUsagePreview } = useEntitlements();

  return <EntitlementWrapper hasAccess={hasAccessUsagePreview} {...props} />;
};
