import cloneDeep from 'lodash/cloneDeep';
import compact from 'lodash/compact';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import uniq from 'lodash/uniq';
import * as React from 'react';

import {
  DEFAULT_GROUP,
  DEPENDENT_HUBSPOT_KEYS,
  DEPENDENT_SALESFORCE_KEYS,
  PRIMARY_HUBSPOT_KEYS,
  PRIMARY_MARKETO_KEYS,
  PRIMARY_SALESFORCE_KEYS,
} from './constants';
import {
  getAndOrOr,
  getAttribute,
  getComparator,
  hasDependentConditions,
  hasPrimaryConditions,
  removeEmptySalesforceDependentConditions,
} from './nodeUtils';
import { isRollupNode } from './searchUtilsRollup';

export const SearchContext = React.createContext({
  invalidNodes: [],
  disabled: false,
});

// Maps the entire search, adding a unique __index to each node
export const addIndexes = (search) => {
  search = ensureDefaultGroup(search);
  search = deleteIndexes(search);
  let index = 0;
  search = mapSearch(search, (node) => {
    if (!node.__index) node.__index = index++;
    return node;
  });
  search = removeEmptyConditions(search);
  return search;
};

export const deleteIndexes = (search) => {
  return mapSearch(search, (node) => {
    delete node.__index;
    delete node.__nest;
    return node;
  });
};

// Adding, replacing and removing nodes

export const insertNode = (search, parentNode, newNode) => {
  // TODO: mapSearch only return once for new conditions
  // inside a salesforce group
  let found = false;
  return mapSearch(search, (node) => {
    if (node.__index === parentNode.__index && !found) {
      found = true;
      const conditions = node[getAndOrOr(node)];
      conditions.push(newNode);
    }

    return node;
  });
};

export const replaceNode = (search, newNode) => {
  let found = false;
  const result = mapSearch(search, (node) => {
    if (found) return node;
    if (node.__index === newNode.__index) {
      node = { ...newNode, __index: node.__index, __nest: node.__nest };
      found = true;
    }
    return node;
  });
  return result;
};

export const removeNode = (search, nodeToRemove) => {
  const { __index } = nodeToRemove;
  if (__index === undefined) return;

  // Let's find the node we want to remove
  let newSearch = mapSearch(search, (node) => {
    // Does this node have the node we're deleting as a legacy object?
    [
      ...DEPENDENT_SALESFORCE_KEYS,
      ...DEPENDENT_HUBSPOT_KEYS,
      ...PRIMARY_SALESFORCE_KEYS,
      ...PRIMARY_HUBSPOT_KEYS,
      ...PRIMARY_MARKETO_KEYS,
    ].forEach((key) => {
      if (get(node, [key, '__index']) === __index) {
        delete node[key];
      }
    });

    // What about in the match object?
    const comparator = getComparator(node);
    if (comparator && node[comparator]?.__index === __index) {
      delete node[comparator];
      if (Object.keys(node).length === 2) {
        node = undefined;
      }
    }

    // If we've deleted the node, return false - it'll be removed during the mapSearch
    if (!node) return undefined;

    // Finally, check whether this node has the offending node in an and/or:
    // Like { and: [nodeToRemove] }
    const andOrOr = getAndOrOr(node);
    if (andOrOr && node[andOrOr].filter) {
      node[andOrOr] = node[andOrOr].filter((n) => n.__index !== __index);
    }

    return node;
  });

  newSearch = ensureDefaultGroup(newSearch);
  newSearch = removeEmptyConditions(newSearch);

  return newSearch;
};

// Recursive function for mapping through a search's nodes one by one
export const mapSearch = (inputSearch, callback, nest = 0) => {
  const search = cloneDeep(inputSearch);
  const recurse = (inputNode, nest = 0) => {
    if (!inputNode) return undefined;

    let node = cloneDeep(inputNode);
    node.__nest = nest;
    node = callback(node);

    if (!node) return node;

    Object.keys(node).forEach((key) => {
      if (isArray(node[key])) {
        node[key] = compact(
          node[key].map((subNode) => recurse(subNode, nest + 1)),
        );
      } else {
        if (typeof node[key] === 'object') {
          node[key] = callback(node[key]);
        }
        // Also map through any salesforce and hubspot keys that exist on this node
        [
          ...PRIMARY_SALESFORCE_KEYS,
          ...PRIMARY_HUBSPOT_KEYS,
          ...PRIMARY_MARKETO_KEYS,
        ].forEach((thirdPartyKey) => {
          if (node[thirdPartyKey]) {
            node[thirdPartyKey] = mapSearch(
              node[thirdPartyKey],
              callback,
              nest + 1,
            );
            [...DEPENDENT_HUBSPOT_KEYS, ...DEPENDENT_SALESFORCE_KEYS].forEach(
              (subKey) => {
                if (node[thirdPartyKey][subKey]) {
                  node[thirdPartyKey][subKey] = mapSearch(
                    node[thirdPartyKey][subKey],
                    callback,
                    nest + 2,
                  );
                }
              },
            );
          }
        });
      }
    });

    return node;
  };

  return recurse(search, nest);
};

// Cleaning up a search object
export const removeEmptyConditions = (search) => {
  const nodesToRemove = [];

  // Rmeove objects that only have __index and __nest
  let newSearch = mapSearch(search, (node) => {
    if (Object.keys(node).length === 2 && node.__nest && node.__index) {
      return undefined;
    }
    return node;
  });

  // Remove keys that point to 'undefined'
  newSearch = mapSearch(newSearch, (node) => {
    Object.keys(node).forEach((key) => {
      if (node[key] === undefined) delete node[key];
    });
    return node;
  });

  // look for empty and/or groups
  newSearch = mapSearch(newSearch, (node) => {
    const andOrOr = getAndOrOr(node);
    if (!andOrOr) return node;

    const isRollup = isRollupNode(node);
    const hasEmptyCondition = node[andOrOr].length === 0;

    // If this node has an empty and/or condition,
    if (hasEmptyCondition && !isRollup) {
      // First, delete the empty and/or (we always do this for empty and/ors)
      delete node[andOrOr];

      // And now delete the node, if it doesn't have any dependent conditions
      if (!hasDependentConditions(node) && !hasPrimaryConditions(node)) {
        // mapSearch on a node will return itself to the callback and we don't want to add it
        // to the removal stack because this results in an infinite loop if we initially passed
        // it an empty node (which can happen when removing the last row in the Selective
        // Enrichment context). This happens because we end up doing the following call stack:
        //   • removeEmptyConditions(EMPTY_NODE)
        //   • nodesToRemove.push(EMPTY_NODE)
        //   • removeNode(EMPTY_NODE, EMPTY_NODE)
        //   • removeEmptyConditions(EMPTY_NODE)
        //   • ♾♾♾
        if (node.__index !== search.__index) {
          nodesToRemove.push(node);
        }
      }
    }

    return node;
  });

  nodesToRemove.forEach((node) => {
    newSearch = removeNode(search, node);
  });

  newSearch = mapSearch(newSearch, (node) =>
    removeEmptySalesforceDependentConditions(node),
  );

  newSearch = ensureDefaultGroup(newSearch);

  return newSearch;
};

const ensureDefaultGroup = (search) => {
  const andOrOr = getAndOrOr(search);
  if (!andOrOr) {
    return { and: [DEFAULT_GROUP] };
  }

  if (search[andOrOr].length === 0) {
    search[andOrOr] = [DEFAULT_GROUP];
  }

  return search;
};

// Analytics

// Getting all the attribute categories this search uses.
export function getCategoriesForSearch(search, attributes) {
  let categories = [];
  mapSearch(search, (node) => {
    const attribute = getAttribute(node, attributes);
    if (!attribute) return node;
    categories = categories.concat(attribute.category);
    return node;
  });

  return uniq(categories);
}
