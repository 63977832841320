import toNumber from 'lodash/toNumber';
import moment from 'moment';

const isNonZeroNumber = (value) => {
  if (toNumber(value) === 0) {
    return false;
  }

  if (isNaN(toNumber(value))) {
    return false;
  }

  return true;
};

export const numeric = (value) => {
  if (!isNonZeroNumber(value)) {
    return '0';
  }

  return parseInt(value).toLocaleString();
};

export const decimal = (value) => {
  if (!isNonZeroNumber(value)) {
    return '0.00';
  }

  return parseFloat(value).toFixed(2);
};

export const dollars = (value) => {
  if (!isNonZeroNumber(value)) {
    return '$0.00';
  }

  let formattedValue = decimal(value);

  // Use commas to denote thousands.
  while (/(\d+)(\d{3})/.test(formattedValue)) {
    formattedValue = formattedValue.toString().replace(/(\d+)(\d{3})/, '$1,$2');
  }

  return `$${formattedValue}`;
};

export const percentage = (value) => {
  return `${numeric(value * 100)}%`;
};

const roundOff = (value, round) => {
  return parseInt(value * 10 ** (round + 1)) -
    parseInt(value * 10 ** round) * 10 >
    4
    ? parseFloat(
        parseInt((value + parseFloat(1 / 10 ** round)) * 10 ** round),
      ) /
        10 ** round
    : parseFloat(parseInt(value * 10 ** round)) / 10 ** round;
};

export const formatNumberWithSuffix = (labelValue) => {
  let originalNumber = Math.abs(Number(labelValue));
  // Nine Zeroes for Billions
  let number =
    originalNumber >= 1.0e9
      ? originalNumber / 1.0e9
      : // Six Zeroes for Millions
      originalNumber >= 1.0e6
      ? originalNumber / 1.0e6
      : // Three Zeroes for Thousands
      originalNumber >= 1.0e3
      ? originalNumber / 1.0e3
      : originalNumber;

  number = roundOff(number, 2);

  let text =
    originalNumber >= 1.0e9
      ? number + 'B'
      : // Six Zeroes for Millions
      originalNumber >= 1.0e6
      ? number + 'M'
      : // Three Zeroes for Thousands
      originalNumber >= 1.0e3
      ? number + 'K'
      : number;

  return text.toString();
};

export const timeFromNow = (time) => moment(time).fromNow();
